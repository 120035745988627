*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Satoshi, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-top-\[5\.2rem\] {
  top: -5.2rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[-10\] {
  bottom: -10px;
}

.left-\[-10\] {
  left: -10px;
}

.right-0 {
  right: 0;
}

.z-\[-1000\] {
  z-index: -1000;
}

.z-\[-100\] {
  z-index: -100;
}

.z-\[1000\] {
  z-index: 1000;
}

.m-0 {
  margin: 0;
}

.flex {
  display: flex;
}

.h-1\/2 {
  height: 50%;
}

.h-\[20\%\] {
  height: 20%;
}

.h-\[80\%\] {
  height: 80%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-full {
  max-height: 100%;
}

.w-0 {
  width: 0;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[70\%\] {
  width: 70%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-full {
  min-width: 100%;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes wavy {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(0);
  }
}

.animate-wavy {
  animation: 30s ease-in-out infinite wavy;
}

.cursor-context-menu {
  cursor: context-menu;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.text-clip {
  text-overflow: clip;
}

.border-b {
  border-bottom-width: 1px;
}

.border-blue-200\/50 {
  border-color: #bfdbfe80;
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-amber-100\/0 {
  background-color: #fef3c700;
}

.bg-amber-100\/10 {
  background-color: #fef3c71a;
}

.bg-amber-100\/100 {
  background-color: #fef3c7;
}

.bg-amber-100\/20 {
  background-color: #fef3c733;
}

.bg-amber-100\/25 {
  background-color: #fef3c740;
}

.bg-amber-100\/30 {
  background-color: #fef3c74d;
}

.bg-amber-100\/40 {
  background-color: #fef3c766;
}

.bg-amber-100\/5 {
  background-color: #fef3c70d;
}

.bg-amber-100\/50 {
  background-color: #fef3c780;
}

.bg-amber-100\/60 {
  background-color: #fef3c799;
}

.bg-amber-100\/70 {
  background-color: #fef3c7b3;
}

.bg-amber-100\/75 {
  background-color: #fef3c7bf;
}

.bg-amber-100\/80 {
  background-color: #fef3c7cc;
}

.bg-amber-100\/90 {
  background-color: #fef3c7e6;
}

.bg-amber-100\/95 {
  background-color: #fef3c7f2;
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.bg-amber-200\/0 {
  background-color: #fde68a00;
}

.bg-amber-200\/10 {
  background-color: #fde68a1a;
}

.bg-amber-200\/100 {
  background-color: #fde68a;
}

.bg-amber-200\/20 {
  background-color: #fde68a33;
}

.bg-amber-200\/25 {
  background-color: #fde68a40;
}

.bg-amber-200\/30 {
  background-color: #fde68a4d;
}

.bg-amber-200\/40 {
  background-color: #fde68a66;
}

.bg-amber-200\/5 {
  background-color: #fde68a0d;
}

.bg-amber-200\/50 {
  background-color: #fde68a80;
}

.bg-amber-200\/60 {
  background-color: #fde68a99;
}

.bg-amber-200\/70 {
  background-color: #fde68ab3;
}

.bg-amber-200\/75 {
  background-color: #fde68abf;
}

.bg-amber-200\/80 {
  background-color: #fde68acc;
}

.bg-amber-200\/90 {
  background-color: #fde68ae6;
}

.bg-amber-200\/95 {
  background-color: #fde68af2;
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.bg-amber-300\/0 {
  background-color: #fcd34d00;
}

.bg-amber-300\/10 {
  background-color: #fcd34d1a;
}

.bg-amber-300\/100 {
  background-color: #fcd34d;
}

.bg-amber-300\/20 {
  background-color: #fcd34d33;
}

.bg-amber-300\/25 {
  background-color: #fcd34d40;
}

.bg-amber-300\/30 {
  background-color: #fcd34d4d;
}

.bg-amber-300\/40 {
  background-color: #fcd34d66;
}

.bg-amber-300\/5 {
  background-color: #fcd34d0d;
}

.bg-amber-300\/50 {
  background-color: #fcd34d80;
}

.bg-amber-300\/60 {
  background-color: #fcd34d99;
}

.bg-amber-300\/70 {
  background-color: #fcd34db3;
}

.bg-amber-300\/75 {
  background-color: #fcd34dbf;
}

.bg-amber-300\/80 {
  background-color: #fcd34dcc;
}

.bg-amber-300\/90 {
  background-color: #fcd34de6;
}

.bg-amber-300\/95 {
  background-color: #fcd34df2;
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-amber-400\/0 {
  background-color: #fbbf2400;
}

.bg-amber-400\/10 {
  background-color: #fbbf241a;
}

.bg-amber-400\/100 {
  background-color: #fbbf24;
}

.bg-amber-400\/20 {
  background-color: #fbbf2433;
}

.bg-amber-400\/25 {
  background-color: #fbbf2440;
}

.bg-amber-400\/30 {
  background-color: #fbbf244d;
}

.bg-amber-400\/40 {
  background-color: #fbbf2466;
}

.bg-amber-400\/5 {
  background-color: #fbbf240d;
}

.bg-amber-400\/50 {
  background-color: #fbbf2480;
}

.bg-amber-400\/60 {
  background-color: #fbbf2499;
}

.bg-amber-400\/70 {
  background-color: #fbbf24b3;
}

.bg-amber-400\/75 {
  background-color: #fbbf24bf;
}

.bg-amber-400\/80 {
  background-color: #fbbf24cc;
}

.bg-amber-400\/90 {
  background-color: #fbbf24e6;
}

.bg-amber-400\/95 {
  background-color: #fbbf24f2;
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-amber-50\/0 {
  background-color: #fffbeb00;
}

.bg-amber-50\/10 {
  background-color: #fffbeb1a;
}

.bg-amber-50\/100 {
  background-color: #fffbeb;
}

.bg-amber-50\/20 {
  background-color: #fffbeb33;
}

.bg-amber-50\/25 {
  background-color: #fffbeb40;
}

.bg-amber-50\/30 {
  background-color: #fffbeb4d;
}

.bg-amber-50\/40 {
  background-color: #fffbeb66;
}

.bg-amber-50\/5 {
  background-color: #fffbeb0d;
}

.bg-amber-50\/50 {
  background-color: #fffbeb80;
}

.bg-amber-50\/60 {
  background-color: #fffbeb99;
}

.bg-amber-50\/70 {
  background-color: #fffbebb3;
}

.bg-amber-50\/75 {
  background-color: #fffbebbf;
}

.bg-amber-50\/80 {
  background-color: #fffbebcc;
}

.bg-amber-50\/90 {
  background-color: #fffbebe6;
}

.bg-amber-50\/95 {
  background-color: #fffbebf2;
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-500\/0 {
  background-color: #f59e0b00;
}

.bg-amber-500\/10 {
  background-color: #f59e0b1a;
}

.bg-amber-500\/100 {
  background-color: #f59e0b;
}

.bg-amber-500\/20 {
  background-color: #f59e0b33;
}

.bg-amber-500\/25 {
  background-color: #f59e0b40;
}

.bg-amber-500\/30 {
  background-color: #f59e0b4d;
}

.bg-amber-500\/40 {
  background-color: #f59e0b66;
}

.bg-amber-500\/5 {
  background-color: #f59e0b0d;
}

.bg-amber-500\/50 {
  background-color: #f59e0b80;
}

.bg-amber-500\/60 {
  background-color: #f59e0b99;
}

.bg-amber-500\/70 {
  background-color: #f59e0bb3;
}

.bg-amber-500\/75 {
  background-color: #f59e0bbf;
}

.bg-amber-500\/80 {
  background-color: #f59e0bcc;
}

.bg-amber-500\/90 {
  background-color: #f59e0be6;
}

.bg-amber-500\/95 {
  background-color: #f59e0bf2;
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.bg-amber-600\/0 {
  background-color: #d9770600;
}

.bg-amber-600\/10 {
  background-color: #d977061a;
}

.bg-amber-600\/100 {
  background-color: #d97706;
}

.bg-amber-600\/20 {
  background-color: #d9770633;
}

.bg-amber-600\/25 {
  background-color: #d9770640;
}

.bg-amber-600\/30 {
  background-color: #d977064d;
}

.bg-amber-600\/40 {
  background-color: #d9770666;
}

.bg-amber-600\/5 {
  background-color: #d977060d;
}

.bg-amber-600\/50 {
  background-color: #d9770680;
}

.bg-amber-600\/60 {
  background-color: #d9770699;
}

.bg-amber-600\/70 {
  background-color: #d97706b3;
}

.bg-amber-600\/75 {
  background-color: #d97706bf;
}

.bg-amber-600\/80 {
  background-color: #d97706cc;
}

.bg-amber-600\/90 {
  background-color: #d97706e6;
}

.bg-amber-600\/95 {
  background-color: #d97706f2;
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.bg-amber-700\/0 {
  background-color: #b4530900;
}

.bg-amber-700\/10 {
  background-color: #b453091a;
}

.bg-amber-700\/100 {
  background-color: #b45309;
}

.bg-amber-700\/20 {
  background-color: #b4530933;
}

.bg-amber-700\/25 {
  background-color: #b4530940;
}

.bg-amber-700\/30 {
  background-color: #b453094d;
}

.bg-amber-700\/40 {
  background-color: #b4530966;
}

.bg-amber-700\/5 {
  background-color: #b453090d;
}

.bg-amber-700\/50 {
  background-color: #b4530980;
}

.bg-amber-700\/60 {
  background-color: #b4530999;
}

.bg-amber-700\/70 {
  background-color: #b45309b3;
}

.bg-amber-700\/75 {
  background-color: #b45309bf;
}

.bg-amber-700\/80 {
  background-color: #b45309cc;
}

.bg-amber-700\/90 {
  background-color: #b45309e6;
}

.bg-amber-700\/95 {
  background-color: #b45309f2;
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.bg-amber-800\/0 {
  background-color: #92400e00;
}

.bg-amber-800\/10 {
  background-color: #92400e1a;
}

.bg-amber-800\/100 {
  background-color: #92400e;
}

.bg-amber-800\/20 {
  background-color: #92400e33;
}

.bg-amber-800\/25 {
  background-color: #92400e40;
}

.bg-amber-800\/30 {
  background-color: #92400e4d;
}

.bg-amber-800\/40 {
  background-color: #92400e66;
}

.bg-amber-800\/5 {
  background-color: #92400e0d;
}

.bg-amber-800\/50 {
  background-color: #92400e80;
}

.bg-amber-800\/60 {
  background-color: #92400e99;
}

.bg-amber-800\/70 {
  background-color: #92400eb3;
}

.bg-amber-800\/75 {
  background-color: #92400ebf;
}

.bg-amber-800\/80 {
  background-color: #92400ecc;
}

.bg-amber-800\/90 {
  background-color: #92400ee6;
}

.bg-amber-800\/95 {
  background-color: #92400ef2;
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.bg-amber-900\/0 {
  background-color: #78350f00;
}

.bg-amber-900\/10 {
  background-color: #78350f1a;
}

.bg-amber-900\/100 {
  background-color: #78350f;
}

.bg-amber-900\/20 {
  background-color: #78350f33;
}

.bg-amber-900\/25 {
  background-color: #78350f40;
}

.bg-amber-900\/30 {
  background-color: #78350f4d;
}

.bg-amber-900\/40 {
  background-color: #78350f66;
}

.bg-amber-900\/5 {
  background-color: #78350f0d;
}

.bg-amber-900\/50 {
  background-color: #78350f80;
}

.bg-amber-900\/60 {
  background-color: #78350f99;
}

.bg-amber-900\/70 {
  background-color: #78350fb3;
}

.bg-amber-900\/75 {
  background-color: #78350fbf;
}

.bg-amber-900\/80 {
  background-color: #78350fcc;
}

.bg-amber-900\/90 {
  background-color: #78350fe6;
}

.bg-amber-900\/95 {
  background-color: #78350ff2;
}

.bg-amber-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.bg-amber-950\/0 {
  background-color: #451a0300;
}

.bg-amber-950\/10 {
  background-color: #451a031a;
}

.bg-amber-950\/100 {
  background-color: #451a03;
}

.bg-amber-950\/20 {
  background-color: #451a0333;
}

.bg-amber-950\/25 {
  background-color: #451a0340;
}

.bg-amber-950\/30 {
  background-color: #451a034d;
}

.bg-amber-950\/40 {
  background-color: #451a0366;
}

.bg-amber-950\/5 {
  background-color: #451a030d;
}

.bg-amber-950\/50 {
  background-color: #451a0380;
}

.bg-amber-950\/60 {
  background-color: #451a0399;
}

.bg-amber-950\/70 {
  background-color: #451a03b3;
}

.bg-amber-950\/75 {
  background-color: #451a03bf;
}

.bg-amber-950\/80 {
  background-color: #451a03cc;
}

.bg-amber-950\/90 {
  background-color: #451a03e6;
}

.bg-amber-950\/95 {
  background-color: #451a03f2;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/0 {
  background-color: #0000;
}

.bg-black\/10 {
  background-color: #0000001a;
}

.bg-black\/100 {
  background-color: #000;
}

.bg-black\/20 {
  background-color: #0003;
}

.bg-black\/25 {
  background-color: #00000040;
}

.bg-black\/30 {
  background-color: #0000004d;
}

.bg-black\/40 {
  background-color: #0006;
}

.bg-black\/5 {
  background-color: #0000000d;
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-black\/70 {
  background-color: #000000b3;
}

.bg-black\/75 {
  background-color: #000000bf;
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-black\/90 {
  background-color: #000000e6;
}

.bg-black\/95 {
  background-color: #000000f2;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-blue-100\/0 {
  background-color: #dbeafe00;
}

.bg-blue-100\/10 {
  background-color: #dbeafe1a;
}

.bg-blue-100\/100 {
  background-color: #dbeafe;
}

.bg-blue-100\/20 {
  background-color: #dbeafe33;
}

.bg-blue-100\/25 {
  background-color: #dbeafe40;
}

.bg-blue-100\/30 {
  background-color: #dbeafe4d;
}

.bg-blue-100\/40 {
  background-color: #dbeafe66;
}

.bg-blue-100\/5 {
  background-color: #dbeafe0d;
}

.bg-blue-100\/50 {
  background-color: #dbeafe80;
}

.bg-blue-100\/60 {
  background-color: #dbeafe99;
}

.bg-blue-100\/70 {
  background-color: #dbeafeb3;
}

.bg-blue-100\/75 {
  background-color: #dbeafebf;
}

.bg-blue-100\/80 {
  background-color: #dbeafecc;
}

.bg-blue-100\/90 {
  background-color: #dbeafee6;
}

.bg-blue-100\/95 {
  background-color: #dbeafef2;
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.bg-blue-200\/0 {
  background-color: #bfdbfe00;
}

.bg-blue-200\/10 {
  background-color: #bfdbfe1a;
}

.bg-blue-200\/100 {
  background-color: #bfdbfe;
}

.bg-blue-200\/20 {
  background-color: #bfdbfe33;
}

.bg-blue-200\/25 {
  background-color: #bfdbfe40;
}

.bg-blue-200\/30 {
  background-color: #bfdbfe4d;
}

.bg-blue-200\/40 {
  background-color: #bfdbfe66;
}

.bg-blue-200\/5 {
  background-color: #bfdbfe0d;
}

.bg-blue-200\/50 {
  background-color: #bfdbfe80;
}

.bg-blue-200\/60 {
  background-color: #bfdbfe99;
}

.bg-blue-200\/70 {
  background-color: #bfdbfeb3;
}

.bg-blue-200\/75 {
  background-color: #bfdbfebf;
}

.bg-blue-200\/80 {
  background-color: #bfdbfecc;
}

.bg-blue-200\/90 {
  background-color: #bfdbfee6;
}

.bg-blue-200\/95 {
  background-color: #bfdbfef2;
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.bg-blue-300\/0 {
  background-color: #93c5fd00;
}

.bg-blue-300\/10 {
  background-color: #93c5fd1a;
}

.bg-blue-300\/100 {
  background-color: #93c5fd;
}

.bg-blue-300\/20 {
  background-color: #93c5fd33;
}

.bg-blue-300\/25 {
  background-color: #93c5fd40;
}

.bg-blue-300\/30 {
  background-color: #93c5fd4d;
}

.bg-blue-300\/40 {
  background-color: #93c5fd66;
}

.bg-blue-300\/5 {
  background-color: #93c5fd0d;
}

.bg-blue-300\/50 {
  background-color: #93c5fd80;
}

.bg-blue-300\/60 {
  background-color: #93c5fd99;
}

.bg-blue-300\/70 {
  background-color: #93c5fdb3;
}

.bg-blue-300\/75 {
  background-color: #93c5fdbf;
}

.bg-blue-300\/80 {
  background-color: #93c5fdcc;
}

.bg-blue-300\/90 {
  background-color: #93c5fde6;
}

.bg-blue-300\/95 {
  background-color: #93c5fdf2;
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-blue-400\/0 {
  background-color: #60a5fa00;
}

.bg-blue-400\/10 {
  background-color: #60a5fa1a;
}

.bg-blue-400\/100 {
  background-color: #60a5fa;
}

.bg-blue-400\/20 {
  background-color: #60a5fa33;
}

.bg-blue-400\/25 {
  background-color: #60a5fa40;
}

.bg-blue-400\/30 {
  background-color: #60a5fa4d;
}

.bg-blue-400\/40 {
  background-color: #60a5fa66;
}

.bg-blue-400\/5 {
  background-color: #60a5fa0d;
}

.bg-blue-400\/50 {
  background-color: #60a5fa80;
}

.bg-blue-400\/60 {
  background-color: #60a5fa99;
}

.bg-blue-400\/70 {
  background-color: #60a5fab3;
}

.bg-blue-400\/75 {
  background-color: #60a5fabf;
}

.bg-blue-400\/80 {
  background-color: #60a5facc;
}

.bg-blue-400\/90 {
  background-color: #60a5fae6;
}

.bg-blue-400\/95 {
  background-color: #60a5faf2;
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-blue-50\/0 {
  background-color: #eff6ff00;
}

.bg-blue-50\/10 {
  background-color: #eff6ff1a;
}

.bg-blue-50\/100 {
  background-color: #eff6ff;
}

.bg-blue-50\/20 {
  background-color: #eff6ff33;
}

.bg-blue-50\/25 {
  background-color: #eff6ff40;
}

.bg-blue-50\/30 {
  background-color: #eff6ff4d;
}

.bg-blue-50\/40 {
  background-color: #eff6ff66;
}

.bg-blue-50\/5 {
  background-color: #eff6ff0d;
}

.bg-blue-50\/50 {
  background-color: #eff6ff80;
}

.bg-blue-50\/60 {
  background-color: #eff6ff99;
}

.bg-blue-50\/70 {
  background-color: #eff6ffb3;
}

.bg-blue-50\/75 {
  background-color: #eff6ffbf;
}

.bg-blue-50\/80 {
  background-color: #eff6ffcc;
}

.bg-blue-50\/90 {
  background-color: #eff6ffe6;
}

.bg-blue-50\/95 {
  background-color: #eff6fff2;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-500\/0 {
  background-color: #3b82f600;
}

.bg-blue-500\/10 {
  background-color: #3b82f61a;
}

.bg-blue-500\/100 {
  background-color: #3b82f6;
}

.bg-blue-500\/20 {
  background-color: #3b82f633;
}

.bg-blue-500\/25 {
  background-color: #3b82f640;
}

.bg-blue-500\/30 {
  background-color: #3b82f64d;
}

.bg-blue-500\/40 {
  background-color: #3b82f666;
}

.bg-blue-500\/5 {
  background-color: #3b82f60d;
}

.bg-blue-500\/50 {
  background-color: #3b82f680;
}

.bg-blue-500\/60 {
  background-color: #3b82f699;
}

.bg-blue-500\/70 {
  background-color: #3b82f6b3;
}

.bg-blue-500\/75 {
  background-color: #3b82f6bf;
}

.bg-blue-500\/80 {
  background-color: #3b82f6cc;
}

.bg-blue-500\/90 {
  background-color: #3b82f6e6;
}

.bg-blue-500\/95 {
  background-color: #3b82f6f2;
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-blue-600\/0 {
  background-color: #2563eb00;
}

.bg-blue-600\/10 {
  background-color: #2563eb1a;
}

.bg-blue-600\/100 {
  background-color: #2563eb;
}

.bg-blue-600\/20 {
  background-color: #2563eb33;
}

.bg-blue-600\/25 {
  background-color: #2563eb40;
}

.bg-blue-600\/30 {
  background-color: #2563eb4d;
}

.bg-blue-600\/40 {
  background-color: #2563eb66;
}

.bg-blue-600\/5 {
  background-color: #2563eb0d;
}

.bg-blue-600\/50 {
  background-color: #2563eb80;
}

.bg-blue-600\/60 {
  background-color: #2563eb99;
}

.bg-blue-600\/70 {
  background-color: #2563ebb3;
}

.bg-blue-600\/75 {
  background-color: #2563ebbf;
}

.bg-blue-600\/80 {
  background-color: #2563ebcc;
}

.bg-blue-600\/90 {
  background-color: #2563ebe6;
}

.bg-blue-600\/95 {
  background-color: #2563ebf2;
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-blue-700\/0 {
  background-color: #1d4ed800;
}

.bg-blue-700\/10 {
  background-color: #1d4ed81a;
}

.bg-blue-700\/100 {
  background-color: #1d4ed8;
}

.bg-blue-700\/20 {
  background-color: #1d4ed833;
}

.bg-blue-700\/25 {
  background-color: #1d4ed840;
}

.bg-blue-700\/30 {
  background-color: #1d4ed84d;
}

.bg-blue-700\/40 {
  background-color: #1d4ed866;
}

.bg-blue-700\/5 {
  background-color: #1d4ed80d;
}

.bg-blue-700\/50 {
  background-color: #1d4ed880;
}

.bg-blue-700\/60 {
  background-color: #1d4ed899;
}

.bg-blue-700\/70 {
  background-color: #1d4ed8b3;
}

.bg-blue-700\/75 {
  background-color: #1d4ed8bf;
}

.bg-blue-700\/80 {
  background-color: #1d4ed8cc;
}

.bg-blue-700\/90 {
  background-color: #1d4ed8e6;
}

.bg-blue-700\/95 {
  background-color: #1d4ed8f2;
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.bg-blue-800\/0 {
  background-color: #1e40af00;
}

.bg-blue-800\/10 {
  background-color: #1e40af1a;
}

.bg-blue-800\/100 {
  background-color: #1e40af;
}

.bg-blue-800\/20 {
  background-color: #1e40af33;
}

.bg-blue-800\/25 {
  background-color: #1e40af40;
}

.bg-blue-800\/30 {
  background-color: #1e40af4d;
}

.bg-blue-800\/40 {
  background-color: #1e40af66;
}

.bg-blue-800\/5 {
  background-color: #1e40af0d;
}

.bg-blue-800\/50 {
  background-color: #1e40af80;
}

.bg-blue-800\/60 {
  background-color: #1e40af99;
}

.bg-blue-800\/70 {
  background-color: #1e40afb3;
}

.bg-blue-800\/75 {
  background-color: #1e40afbf;
}

.bg-blue-800\/80 {
  background-color: #1e40afcc;
}

.bg-blue-800\/90 {
  background-color: #1e40afe6;
}

.bg-blue-800\/95 {
  background-color: #1e40aff2;
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

.bg-blue-900\/0 {
  background-color: #1e3a8a00;
}

.bg-blue-900\/10 {
  background-color: #1e3a8a1a;
}

.bg-blue-900\/100 {
  background-color: #1e3a8a;
}

.bg-blue-900\/20 {
  background-color: #1e3a8a33;
}

.bg-blue-900\/25 {
  background-color: #1e3a8a40;
}

.bg-blue-900\/30 {
  background-color: #1e3a8a4d;
}

.bg-blue-900\/40 {
  background-color: #1e3a8a66;
}

.bg-blue-900\/5 {
  background-color: #1e3a8a0d;
}

.bg-blue-900\/50 {
  background-color: #1e3a8a80;
}

.bg-blue-900\/60 {
  background-color: #1e3a8a99;
}

.bg-blue-900\/70 {
  background-color: #1e3a8ab3;
}

.bg-blue-900\/75 {
  background-color: #1e3a8abf;
}

.bg-blue-900\/80 {
  background-color: #1e3a8acc;
}

.bg-blue-900\/90 {
  background-color: #1e3a8ae6;
}

.bg-blue-900\/95 {
  background-color: #1e3a8af2;
}

.bg-blue-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

.bg-blue-950\/0 {
  background-color: #17255400;
}

.bg-blue-950\/10 {
  background-color: #1725541a;
}

.bg-blue-950\/100 {
  background-color: #172554;
}

.bg-blue-950\/20 {
  background-color: #17255433;
}

.bg-blue-950\/25 {
  background-color: #17255440;
}

.bg-blue-950\/30 {
  background-color: #1725544d;
}

.bg-blue-950\/40 {
  background-color: #17255466;
}

.bg-blue-950\/5 {
  background-color: #1725540d;
}

.bg-blue-950\/50 {
  background-color: #17255480;
}

.bg-blue-950\/60 {
  background-color: #17255499;
}

.bg-blue-950\/70 {
  background-color: #172554b3;
}

.bg-blue-950\/75 {
  background-color: #172554bf;
}

.bg-blue-950\/80 {
  background-color: #172554cc;
}

.bg-blue-950\/90 {
  background-color: #172554e6;
}

.bg-blue-950\/95 {
  background-color: #172554f2;
}

.bg-current {
  background-color: currentColor;
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.bg-cyan-100\/0 {
  background-color: #cffafe00;
}

.bg-cyan-100\/10 {
  background-color: #cffafe1a;
}

.bg-cyan-100\/100 {
  background-color: #cffafe;
}

.bg-cyan-100\/20 {
  background-color: #cffafe33;
}

.bg-cyan-100\/25 {
  background-color: #cffafe40;
}

.bg-cyan-100\/30 {
  background-color: #cffafe4d;
}

.bg-cyan-100\/40 {
  background-color: #cffafe66;
}

.bg-cyan-100\/5 {
  background-color: #cffafe0d;
}

.bg-cyan-100\/50 {
  background-color: #cffafe80;
}

.bg-cyan-100\/60 {
  background-color: #cffafe99;
}

.bg-cyan-100\/70 {
  background-color: #cffafeb3;
}

.bg-cyan-100\/75 {
  background-color: #cffafebf;
}

.bg-cyan-100\/80 {
  background-color: #cffafecc;
}

.bg-cyan-100\/90 {
  background-color: #cffafee6;
}

.bg-cyan-100\/95 {
  background-color: #cffafef2;
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.bg-cyan-200\/0 {
  background-color: #a5f3fc00;
}

.bg-cyan-200\/10 {
  background-color: #a5f3fc1a;
}

.bg-cyan-200\/100 {
  background-color: #a5f3fc;
}

.bg-cyan-200\/20 {
  background-color: #a5f3fc33;
}

.bg-cyan-200\/25 {
  background-color: #a5f3fc40;
}

.bg-cyan-200\/30 {
  background-color: #a5f3fc4d;
}

.bg-cyan-200\/40 {
  background-color: #a5f3fc66;
}

.bg-cyan-200\/5 {
  background-color: #a5f3fc0d;
}

.bg-cyan-200\/50 {
  background-color: #a5f3fc80;
}

.bg-cyan-200\/60 {
  background-color: #a5f3fc99;
}

.bg-cyan-200\/70 {
  background-color: #a5f3fcb3;
}

.bg-cyan-200\/75 {
  background-color: #a5f3fcbf;
}

.bg-cyan-200\/80 {
  background-color: #a5f3fccc;
}

.bg-cyan-200\/90 {
  background-color: #a5f3fce6;
}

.bg-cyan-200\/95 {
  background-color: #a5f3fcf2;
}

.bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.bg-cyan-300\/0 {
  background-color: #67e8f900;
}

.bg-cyan-300\/10 {
  background-color: #67e8f91a;
}

.bg-cyan-300\/100 {
  background-color: #67e8f9;
}

.bg-cyan-300\/20 {
  background-color: #67e8f933;
}

.bg-cyan-300\/25 {
  background-color: #67e8f940;
}

.bg-cyan-300\/30 {
  background-color: #67e8f94d;
}

.bg-cyan-300\/40 {
  background-color: #67e8f966;
}

.bg-cyan-300\/5 {
  background-color: #67e8f90d;
}

.bg-cyan-300\/50 {
  background-color: #67e8f980;
}

.bg-cyan-300\/60 {
  background-color: #67e8f999;
}

.bg-cyan-300\/70 {
  background-color: #67e8f9b3;
}

.bg-cyan-300\/75 {
  background-color: #67e8f9bf;
}

.bg-cyan-300\/80 {
  background-color: #67e8f9cc;
}

.bg-cyan-300\/90 {
  background-color: #67e8f9e6;
}

.bg-cyan-300\/95 {
  background-color: #67e8f9f2;
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.bg-cyan-400\/0 {
  background-color: #22d3ee00;
}

.bg-cyan-400\/10 {
  background-color: #22d3ee1a;
}

.bg-cyan-400\/100 {
  background-color: #22d3ee;
}

.bg-cyan-400\/20 {
  background-color: #22d3ee33;
}

.bg-cyan-400\/25 {
  background-color: #22d3ee40;
}

.bg-cyan-400\/30 {
  background-color: #22d3ee4d;
}

.bg-cyan-400\/40 {
  background-color: #22d3ee66;
}

.bg-cyan-400\/5 {
  background-color: #22d3ee0d;
}

.bg-cyan-400\/50 {
  background-color: #22d3ee80;
}

.bg-cyan-400\/60 {
  background-color: #22d3ee99;
}

.bg-cyan-400\/70 {
  background-color: #22d3eeb3;
}

.bg-cyan-400\/75 {
  background-color: #22d3eebf;
}

.bg-cyan-400\/80 {
  background-color: #22d3eecc;
}

.bg-cyan-400\/90 {
  background-color: #22d3eee6;
}

.bg-cyan-400\/95 {
  background-color: #22d3eef2;
}

.bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

.bg-cyan-50\/0 {
  background-color: #ecfeff00;
}

.bg-cyan-50\/10 {
  background-color: #ecfeff1a;
}

.bg-cyan-50\/100 {
  background-color: #ecfeff;
}

.bg-cyan-50\/20 {
  background-color: #ecfeff33;
}

.bg-cyan-50\/25 {
  background-color: #ecfeff40;
}

.bg-cyan-50\/30 {
  background-color: #ecfeff4d;
}

.bg-cyan-50\/40 {
  background-color: #ecfeff66;
}

.bg-cyan-50\/5 {
  background-color: #ecfeff0d;
}

.bg-cyan-50\/50 {
  background-color: #ecfeff80;
}

.bg-cyan-50\/60 {
  background-color: #ecfeff99;
}

.bg-cyan-50\/70 {
  background-color: #ecfeffb3;
}

.bg-cyan-50\/75 {
  background-color: #ecfeffbf;
}

.bg-cyan-50\/80 {
  background-color: #ecfeffcc;
}

.bg-cyan-50\/90 {
  background-color: #ecfeffe6;
}

.bg-cyan-50\/95 {
  background-color: #ecfefff2;
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-500\/0 {
  background-color: #06b6d400;
}

.bg-cyan-500\/10 {
  background-color: #06b6d41a;
}

.bg-cyan-500\/100 {
  background-color: #06b6d4;
}

.bg-cyan-500\/20 {
  background-color: #06b6d433;
}

.bg-cyan-500\/25 {
  background-color: #06b6d440;
}

.bg-cyan-500\/30 {
  background-color: #06b6d44d;
}

.bg-cyan-500\/40 {
  background-color: #06b6d466;
}

.bg-cyan-500\/5 {
  background-color: #06b6d40d;
}

.bg-cyan-500\/50 {
  background-color: #06b6d480;
}

.bg-cyan-500\/60 {
  background-color: #06b6d499;
}

.bg-cyan-500\/70 {
  background-color: #06b6d4b3;
}

.bg-cyan-500\/75 {
  background-color: #06b6d4bf;
}

.bg-cyan-500\/80 {
  background-color: #06b6d4cc;
}

.bg-cyan-500\/90 {
  background-color: #06b6d4e6;
}

.bg-cyan-500\/95 {
  background-color: #06b6d4f2;
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.bg-cyan-600\/0 {
  background-color: #0891b200;
}

.bg-cyan-600\/10 {
  background-color: #0891b21a;
}

.bg-cyan-600\/100 {
  background-color: #0891b2;
}

.bg-cyan-600\/20 {
  background-color: #0891b233;
}

.bg-cyan-600\/25 {
  background-color: #0891b240;
}

.bg-cyan-600\/30 {
  background-color: #0891b24d;
}

.bg-cyan-600\/40 {
  background-color: #0891b266;
}

.bg-cyan-600\/5 {
  background-color: #0891b20d;
}

.bg-cyan-600\/50 {
  background-color: #0891b280;
}

.bg-cyan-600\/60 {
  background-color: #0891b299;
}

.bg-cyan-600\/70 {
  background-color: #0891b2b3;
}

.bg-cyan-600\/75 {
  background-color: #0891b2bf;
}

.bg-cyan-600\/80 {
  background-color: #0891b2cc;
}

.bg-cyan-600\/90 {
  background-color: #0891b2e6;
}

.bg-cyan-600\/95 {
  background-color: #0891b2f2;
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.bg-cyan-700\/0 {
  background-color: #0e749000;
}

.bg-cyan-700\/10 {
  background-color: #0e74901a;
}

.bg-cyan-700\/100 {
  background-color: #0e7490;
}

.bg-cyan-700\/20 {
  background-color: #0e749033;
}

.bg-cyan-700\/25 {
  background-color: #0e749040;
}

.bg-cyan-700\/30 {
  background-color: #0e74904d;
}

.bg-cyan-700\/40 {
  background-color: #0e749066;
}

.bg-cyan-700\/5 {
  background-color: #0e74900d;
}

.bg-cyan-700\/50 {
  background-color: #0e749080;
}

.bg-cyan-700\/60 {
  background-color: #0e749099;
}

.bg-cyan-700\/70 {
  background-color: #0e7490b3;
}

.bg-cyan-700\/75 {
  background-color: #0e7490bf;
}

.bg-cyan-700\/80 {
  background-color: #0e7490cc;
}

.bg-cyan-700\/90 {
  background-color: #0e7490e6;
}

.bg-cyan-700\/95 {
  background-color: #0e7490f2;
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.bg-cyan-800\/0 {
  background-color: #155e7500;
}

.bg-cyan-800\/10 {
  background-color: #155e751a;
}

.bg-cyan-800\/100 {
  background-color: #155e75;
}

.bg-cyan-800\/20 {
  background-color: #155e7533;
}

.bg-cyan-800\/25 {
  background-color: #155e7540;
}

.bg-cyan-800\/30 {
  background-color: #155e754d;
}

.bg-cyan-800\/40 {
  background-color: #155e7566;
}

.bg-cyan-800\/5 {
  background-color: #155e750d;
}

.bg-cyan-800\/50 {
  background-color: #155e7580;
}

.bg-cyan-800\/60 {
  background-color: #155e7599;
}

.bg-cyan-800\/70 {
  background-color: #155e75b3;
}

.bg-cyan-800\/75 {
  background-color: #155e75bf;
}

.bg-cyan-800\/80 {
  background-color: #155e75cc;
}

.bg-cyan-800\/90 {
  background-color: #155e75e6;
}

.bg-cyan-800\/95 {
  background-color: #155e75f2;
}

.bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

.bg-cyan-900\/0 {
  background-color: #164e6300;
}

.bg-cyan-900\/10 {
  background-color: #164e631a;
}

.bg-cyan-900\/100 {
  background-color: #164e63;
}

.bg-cyan-900\/20 {
  background-color: #164e6333;
}

.bg-cyan-900\/25 {
  background-color: #164e6340;
}

.bg-cyan-900\/30 {
  background-color: #164e634d;
}

.bg-cyan-900\/40 {
  background-color: #164e6366;
}

.bg-cyan-900\/5 {
  background-color: #164e630d;
}

.bg-cyan-900\/50 {
  background-color: #164e6380;
}

.bg-cyan-900\/60 {
  background-color: #164e6399;
}

.bg-cyan-900\/70 {
  background-color: #164e63b3;
}

.bg-cyan-900\/75 {
  background-color: #164e63bf;
}

.bg-cyan-900\/80 {
  background-color: #164e63cc;
}

.bg-cyan-900\/90 {
  background-color: #164e63e6;
}

.bg-cyan-900\/95 {
  background-color: #164e63f2;
}

.bg-cyan-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

.bg-cyan-950\/0 {
  background-color: #08334400;
}

.bg-cyan-950\/10 {
  background-color: #0833441a;
}

.bg-cyan-950\/100 {
  background-color: #083344;
}

.bg-cyan-950\/20 {
  background-color: #08334433;
}

.bg-cyan-950\/25 {
  background-color: #08334440;
}

.bg-cyan-950\/30 {
  background-color: #0833444d;
}

.bg-cyan-950\/40 {
  background-color: #08334466;
}

.bg-cyan-950\/5 {
  background-color: #0833440d;
}

.bg-cyan-950\/50 {
  background-color: #08334480;
}

.bg-cyan-950\/60 {
  background-color: #08334499;
}

.bg-cyan-950\/70 {
  background-color: #083344b3;
}

.bg-cyan-950\/75 {
  background-color: #083344bf;
}

.bg-cyan-950\/80 {
  background-color: #083344cc;
}

.bg-cyan-950\/90 {
  background-color: #083344e6;
}

.bg-cyan-950\/95 {
  background-color: #083344f2;
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-emerald-100\/0 {
  background-color: #d1fae500;
}

.bg-emerald-100\/10 {
  background-color: #d1fae51a;
}

.bg-emerald-100\/100 {
  background-color: #d1fae5;
}

.bg-emerald-100\/20 {
  background-color: #d1fae533;
}

.bg-emerald-100\/25 {
  background-color: #d1fae540;
}

.bg-emerald-100\/30 {
  background-color: #d1fae54d;
}

.bg-emerald-100\/40 {
  background-color: #d1fae566;
}

.bg-emerald-100\/5 {
  background-color: #d1fae50d;
}

.bg-emerald-100\/50 {
  background-color: #d1fae580;
}

.bg-emerald-100\/60 {
  background-color: #d1fae599;
}

.bg-emerald-100\/70 {
  background-color: #d1fae5b3;
}

.bg-emerald-100\/75 {
  background-color: #d1fae5bf;
}

.bg-emerald-100\/80 {
  background-color: #d1fae5cc;
}

.bg-emerald-100\/90 {
  background-color: #d1fae5e6;
}

.bg-emerald-100\/95 {
  background-color: #d1fae5f2;
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.bg-emerald-200\/0 {
  background-color: #a7f3d000;
}

.bg-emerald-200\/10 {
  background-color: #a7f3d01a;
}

.bg-emerald-200\/100 {
  background-color: #a7f3d0;
}

.bg-emerald-200\/20 {
  background-color: #a7f3d033;
}

.bg-emerald-200\/25 {
  background-color: #a7f3d040;
}

.bg-emerald-200\/30 {
  background-color: #a7f3d04d;
}

.bg-emerald-200\/40 {
  background-color: #a7f3d066;
}

.bg-emerald-200\/5 {
  background-color: #a7f3d00d;
}

.bg-emerald-200\/50 {
  background-color: #a7f3d080;
}

.bg-emerald-200\/60 {
  background-color: #a7f3d099;
}

.bg-emerald-200\/70 {
  background-color: #a7f3d0b3;
}

.bg-emerald-200\/75 {
  background-color: #a7f3d0bf;
}

.bg-emerald-200\/80 {
  background-color: #a7f3d0cc;
}

.bg-emerald-200\/90 {
  background-color: #a7f3d0e6;
}

.bg-emerald-200\/95 {
  background-color: #a7f3d0f2;
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.bg-emerald-300\/0 {
  background-color: #6ee7b700;
}

.bg-emerald-300\/10 {
  background-color: #6ee7b71a;
}

.bg-emerald-300\/100 {
  background-color: #6ee7b7;
}

.bg-emerald-300\/20 {
  background-color: #6ee7b733;
}

.bg-emerald-300\/25 {
  background-color: #6ee7b740;
}

.bg-emerald-300\/30 {
  background-color: #6ee7b74d;
}

.bg-emerald-300\/40 {
  background-color: #6ee7b766;
}

.bg-emerald-300\/5 {
  background-color: #6ee7b70d;
}

.bg-emerald-300\/50 {
  background-color: #6ee7b780;
}

.bg-emerald-300\/60 {
  background-color: #6ee7b799;
}

.bg-emerald-300\/70 {
  background-color: #6ee7b7b3;
}

.bg-emerald-300\/75 {
  background-color: #6ee7b7bf;
}

.bg-emerald-300\/80 {
  background-color: #6ee7b7cc;
}

.bg-emerald-300\/90 {
  background-color: #6ee7b7e6;
}

.bg-emerald-300\/95 {
  background-color: #6ee7b7f2;
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.bg-emerald-400\/0 {
  background-color: #34d39900;
}

.bg-emerald-400\/10 {
  background-color: #34d3991a;
}

.bg-emerald-400\/100 {
  background-color: #34d399;
}

.bg-emerald-400\/20 {
  background-color: #34d39933;
}

.bg-emerald-400\/25 {
  background-color: #34d39940;
}

.bg-emerald-400\/30 {
  background-color: #34d3994d;
}

.bg-emerald-400\/40 {
  background-color: #34d39966;
}

.bg-emerald-400\/5 {
  background-color: #34d3990d;
}

.bg-emerald-400\/50 {
  background-color: #34d39980;
}

.bg-emerald-400\/60 {
  background-color: #34d39999;
}

.bg-emerald-400\/70 {
  background-color: #34d399b3;
}

.bg-emerald-400\/75 {
  background-color: #34d399bf;
}

.bg-emerald-400\/80 {
  background-color: #34d399cc;
}

.bg-emerald-400\/90 {
  background-color: #34d399e6;
}

.bg-emerald-400\/95 {
  background-color: #34d399f2;
}

.bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.bg-emerald-50\/0 {
  background-color: #ecfdf500;
}

.bg-emerald-50\/10 {
  background-color: #ecfdf51a;
}

.bg-emerald-50\/100 {
  background-color: #ecfdf5;
}

.bg-emerald-50\/20 {
  background-color: #ecfdf533;
}

.bg-emerald-50\/25 {
  background-color: #ecfdf540;
}

.bg-emerald-50\/30 {
  background-color: #ecfdf54d;
}

.bg-emerald-50\/40 {
  background-color: #ecfdf566;
}

.bg-emerald-50\/5 {
  background-color: #ecfdf50d;
}

.bg-emerald-50\/50 {
  background-color: #ecfdf580;
}

.bg-emerald-50\/60 {
  background-color: #ecfdf599;
}

.bg-emerald-50\/70 {
  background-color: #ecfdf5b3;
}

.bg-emerald-50\/75 {
  background-color: #ecfdf5bf;
}

.bg-emerald-50\/80 {
  background-color: #ecfdf5cc;
}

.bg-emerald-50\/90 {
  background-color: #ecfdf5e6;
}

.bg-emerald-50\/95 {
  background-color: #ecfdf5f2;
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-emerald-500\/0 {
  background-color: #10b98100;
}

.bg-emerald-500\/10 {
  background-color: #10b9811a;
}

.bg-emerald-500\/100 {
  background-color: #10b981;
}

.bg-emerald-500\/20 {
  background-color: #10b98133;
}

.bg-emerald-500\/25 {
  background-color: #10b98140;
}

.bg-emerald-500\/30 {
  background-color: #10b9814d;
}

.bg-emerald-500\/40 {
  background-color: #10b98166;
}

.bg-emerald-500\/5 {
  background-color: #10b9810d;
}

.bg-emerald-500\/50 {
  background-color: #10b98180;
}

.bg-emerald-500\/60 {
  background-color: #10b98199;
}

.bg-emerald-500\/70 {
  background-color: #10b981b3;
}

.bg-emerald-500\/75 {
  background-color: #10b981bf;
}

.bg-emerald-500\/80 {
  background-color: #10b981cc;
}

.bg-emerald-500\/90 {
  background-color: #10b981e6;
}

.bg-emerald-500\/95 {
  background-color: #10b981f2;
}

.bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.bg-emerald-600\/0 {
  background-color: #05966900;
}

.bg-emerald-600\/10 {
  background-color: #0596691a;
}

.bg-emerald-600\/100 {
  background-color: #059669;
}

.bg-emerald-600\/20 {
  background-color: #05966933;
}

.bg-emerald-600\/25 {
  background-color: #05966940;
}

.bg-emerald-600\/30 {
  background-color: #0596694d;
}

.bg-emerald-600\/40 {
  background-color: #05966966;
}

.bg-emerald-600\/5 {
  background-color: #0596690d;
}

.bg-emerald-600\/50 {
  background-color: #05966980;
}

.bg-emerald-600\/60 {
  background-color: #05966999;
}

.bg-emerald-600\/70 {
  background-color: #059669b3;
}

.bg-emerald-600\/75 {
  background-color: #059669bf;
}

.bg-emerald-600\/80 {
  background-color: #059669cc;
}

.bg-emerald-600\/90 {
  background-color: #059669e6;
}

.bg-emerald-600\/95 {
  background-color: #059669f2;
}

.bg-emerald-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.bg-emerald-700\/0 {
  background-color: #04785700;
}

.bg-emerald-700\/10 {
  background-color: #0478571a;
}

.bg-emerald-700\/100 {
  background-color: #047857;
}

.bg-emerald-700\/20 {
  background-color: #04785733;
}

.bg-emerald-700\/25 {
  background-color: #04785740;
}

.bg-emerald-700\/30 {
  background-color: #0478574d;
}

.bg-emerald-700\/40 {
  background-color: #04785766;
}

.bg-emerald-700\/5 {
  background-color: #0478570d;
}

.bg-emerald-700\/50 {
  background-color: #04785780;
}

.bg-emerald-700\/60 {
  background-color: #04785799;
}

.bg-emerald-700\/70 {
  background-color: #047857b3;
}

.bg-emerald-700\/75 {
  background-color: #047857bf;
}

.bg-emerald-700\/80 {
  background-color: #047857cc;
}

.bg-emerald-700\/90 {
  background-color: #047857e6;
}

.bg-emerald-700\/95 {
  background-color: #047857f2;
}

.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

.bg-emerald-800\/0 {
  background-color: #065f4600;
}

.bg-emerald-800\/10 {
  background-color: #065f461a;
}

.bg-emerald-800\/100 {
  background-color: #065f46;
}

.bg-emerald-800\/20 {
  background-color: #065f4633;
}

.bg-emerald-800\/25 {
  background-color: #065f4640;
}

.bg-emerald-800\/30 {
  background-color: #065f464d;
}

.bg-emerald-800\/40 {
  background-color: #065f4666;
}

.bg-emerald-800\/5 {
  background-color: #065f460d;
}

.bg-emerald-800\/50 {
  background-color: #065f4680;
}

.bg-emerald-800\/60 {
  background-color: #065f4699;
}

.bg-emerald-800\/70 {
  background-color: #065f46b3;
}

.bg-emerald-800\/75 {
  background-color: #065f46bf;
}

.bg-emerald-800\/80 {
  background-color: #065f46cc;
}

.bg-emerald-800\/90 {
  background-color: #065f46e6;
}

.bg-emerald-800\/95 {
  background-color: #065f46f2;
}

.bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

.bg-emerald-900\/0 {
  background-color: #064e3b00;
}

.bg-emerald-900\/10 {
  background-color: #064e3b1a;
}

.bg-emerald-900\/100 {
  background-color: #064e3b;
}

.bg-emerald-900\/20 {
  background-color: #064e3b33;
}

.bg-emerald-900\/25 {
  background-color: #064e3b40;
}

.bg-emerald-900\/30 {
  background-color: #064e3b4d;
}

.bg-emerald-900\/40 {
  background-color: #064e3b66;
}

.bg-emerald-900\/5 {
  background-color: #064e3b0d;
}

.bg-emerald-900\/50 {
  background-color: #064e3b80;
}

.bg-emerald-900\/60 {
  background-color: #064e3b99;
}

.bg-emerald-900\/70 {
  background-color: #064e3bb3;
}

.bg-emerald-900\/75 {
  background-color: #064e3bbf;
}

.bg-emerald-900\/80 {
  background-color: #064e3bcc;
}

.bg-emerald-900\/90 {
  background-color: #064e3be6;
}

.bg-emerald-900\/95 {
  background-color: #064e3bf2;
}

.bg-emerald-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

.bg-emerald-950\/0 {
  background-color: #022c2200;
}

.bg-emerald-950\/10 {
  background-color: #022c221a;
}

.bg-emerald-950\/100 {
  background-color: #022c22;
}

.bg-emerald-950\/20 {
  background-color: #022c2233;
}

.bg-emerald-950\/25 {
  background-color: #022c2240;
}

.bg-emerald-950\/30 {
  background-color: #022c224d;
}

.bg-emerald-950\/40 {
  background-color: #022c2266;
}

.bg-emerald-950\/5 {
  background-color: #022c220d;
}

.bg-emerald-950\/50 {
  background-color: #022c2280;
}

.bg-emerald-950\/60 {
  background-color: #022c2299;
}

.bg-emerald-950\/70 {
  background-color: #022c22b3;
}

.bg-emerald-950\/75 {
  background-color: #022c22bf;
}

.bg-emerald-950\/80 {
  background-color: #022c22cc;
}

.bg-emerald-950\/90 {
  background-color: #022c22e6;
}

.bg-emerald-950\/95 {
  background-color: #022c22f2;
}

.bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-100\/0 {
  background-color: #fae8ff00;
}

.bg-fuchsia-100\/10 {
  background-color: #fae8ff1a;
}

.bg-fuchsia-100\/100 {
  background-color: #fae8ff;
}

.bg-fuchsia-100\/20 {
  background-color: #fae8ff33;
}

.bg-fuchsia-100\/25 {
  background-color: #fae8ff40;
}

.bg-fuchsia-100\/30 {
  background-color: #fae8ff4d;
}

.bg-fuchsia-100\/40 {
  background-color: #fae8ff66;
}

.bg-fuchsia-100\/5 {
  background-color: #fae8ff0d;
}

.bg-fuchsia-100\/50 {
  background-color: #fae8ff80;
}

.bg-fuchsia-100\/60 {
  background-color: #fae8ff99;
}

.bg-fuchsia-100\/70 {
  background-color: #fae8ffb3;
}

.bg-fuchsia-100\/75 {
  background-color: #fae8ffbf;
}

.bg-fuchsia-100\/80 {
  background-color: #fae8ffcc;
}

.bg-fuchsia-100\/90 {
  background-color: #fae8ffe6;
}

.bg-fuchsia-100\/95 {
  background-color: #fae8fff2;
}

.bg-fuchsia-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

.bg-fuchsia-200\/0 {
  background-color: #f5d0fe00;
}

.bg-fuchsia-200\/10 {
  background-color: #f5d0fe1a;
}

.bg-fuchsia-200\/100 {
  background-color: #f5d0fe;
}

.bg-fuchsia-200\/20 {
  background-color: #f5d0fe33;
}

.bg-fuchsia-200\/25 {
  background-color: #f5d0fe40;
}

.bg-fuchsia-200\/30 {
  background-color: #f5d0fe4d;
}

.bg-fuchsia-200\/40 {
  background-color: #f5d0fe66;
}

.bg-fuchsia-200\/5 {
  background-color: #f5d0fe0d;
}

.bg-fuchsia-200\/50 {
  background-color: #f5d0fe80;
}

.bg-fuchsia-200\/60 {
  background-color: #f5d0fe99;
}

.bg-fuchsia-200\/70 {
  background-color: #f5d0feb3;
}

.bg-fuchsia-200\/75 {
  background-color: #f5d0febf;
}

.bg-fuchsia-200\/80 {
  background-color: #f5d0fecc;
}

.bg-fuchsia-200\/90 {
  background-color: #f5d0fee6;
}

.bg-fuchsia-200\/95 {
  background-color: #f5d0fef2;
}

.bg-fuchsia-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}

.bg-fuchsia-300\/0 {
  background-color: #f0abfc00;
}

.bg-fuchsia-300\/10 {
  background-color: #f0abfc1a;
}

.bg-fuchsia-300\/100 {
  background-color: #f0abfc;
}

.bg-fuchsia-300\/20 {
  background-color: #f0abfc33;
}

.bg-fuchsia-300\/25 {
  background-color: #f0abfc40;
}

.bg-fuchsia-300\/30 {
  background-color: #f0abfc4d;
}

.bg-fuchsia-300\/40 {
  background-color: #f0abfc66;
}

.bg-fuchsia-300\/5 {
  background-color: #f0abfc0d;
}

.bg-fuchsia-300\/50 {
  background-color: #f0abfc80;
}

.bg-fuchsia-300\/60 {
  background-color: #f0abfc99;
}

.bg-fuchsia-300\/70 {
  background-color: #f0abfcb3;
}

.bg-fuchsia-300\/75 {
  background-color: #f0abfcbf;
}

.bg-fuchsia-300\/80 {
  background-color: #f0abfccc;
}

.bg-fuchsia-300\/90 {
  background-color: #f0abfce6;
}

.bg-fuchsia-300\/95 {
  background-color: #f0abfcf2;
}

.bg-fuchsia-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

.bg-fuchsia-400\/0 {
  background-color: #e879f900;
}

.bg-fuchsia-400\/10 {
  background-color: #e879f91a;
}

.bg-fuchsia-400\/100 {
  background-color: #e879f9;
}

.bg-fuchsia-400\/20 {
  background-color: #e879f933;
}

.bg-fuchsia-400\/25 {
  background-color: #e879f940;
}

.bg-fuchsia-400\/30 {
  background-color: #e879f94d;
}

.bg-fuchsia-400\/40 {
  background-color: #e879f966;
}

.bg-fuchsia-400\/5 {
  background-color: #e879f90d;
}

.bg-fuchsia-400\/50 {
  background-color: #e879f980;
}

.bg-fuchsia-400\/60 {
  background-color: #e879f999;
}

.bg-fuchsia-400\/70 {
  background-color: #e879f9b3;
}

.bg-fuchsia-400\/75 {
  background-color: #e879f9bf;
}

.bg-fuchsia-400\/80 {
  background-color: #e879f9cc;
}

.bg-fuchsia-400\/90 {
  background-color: #e879f9e6;
}

.bg-fuchsia-400\/95 {
  background-color: #e879f9f2;
}

.bg-fuchsia-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-50\/0 {
  background-color: #fdf4ff00;
}

.bg-fuchsia-50\/10 {
  background-color: #fdf4ff1a;
}

.bg-fuchsia-50\/100 {
  background-color: #fdf4ff;
}

.bg-fuchsia-50\/20 {
  background-color: #fdf4ff33;
}

.bg-fuchsia-50\/25 {
  background-color: #fdf4ff40;
}

.bg-fuchsia-50\/30 {
  background-color: #fdf4ff4d;
}

.bg-fuchsia-50\/40 {
  background-color: #fdf4ff66;
}

.bg-fuchsia-50\/5 {
  background-color: #fdf4ff0d;
}

.bg-fuchsia-50\/50 {
  background-color: #fdf4ff80;
}

.bg-fuchsia-50\/60 {
  background-color: #fdf4ff99;
}

.bg-fuchsia-50\/70 {
  background-color: #fdf4ffb3;
}

.bg-fuchsia-50\/75 {
  background-color: #fdf4ffbf;
}

.bg-fuchsia-50\/80 {
  background-color: #fdf4ffcc;
}

.bg-fuchsia-50\/90 {
  background-color: #fdf4ffe6;
}

.bg-fuchsia-50\/95 {
  background-color: #fdf4fff2;
}

.bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

.bg-fuchsia-500\/0 {
  background-color: #d946ef00;
}

.bg-fuchsia-500\/10 {
  background-color: #d946ef1a;
}

.bg-fuchsia-500\/100 {
  background-color: #d946ef;
}

.bg-fuchsia-500\/20 {
  background-color: #d946ef33;
}

.bg-fuchsia-500\/25 {
  background-color: #d946ef40;
}

.bg-fuchsia-500\/30 {
  background-color: #d946ef4d;
}

.bg-fuchsia-500\/40 {
  background-color: #d946ef66;
}

.bg-fuchsia-500\/5 {
  background-color: #d946ef0d;
}

.bg-fuchsia-500\/50 {
  background-color: #d946ef80;
}

.bg-fuchsia-500\/60 {
  background-color: #d946ef99;
}

.bg-fuchsia-500\/70 {
  background-color: #d946efb3;
}

.bg-fuchsia-500\/75 {
  background-color: #d946efbf;
}

.bg-fuchsia-500\/80 {
  background-color: #d946efcc;
}

.bg-fuchsia-500\/90 {
  background-color: #d946efe6;
}

.bg-fuchsia-500\/95 {
  background-color: #d946eff2;
}

.bg-fuchsia-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

.bg-fuchsia-600\/0 {
  background-color: #c026d300;
}

.bg-fuchsia-600\/10 {
  background-color: #c026d31a;
}

.bg-fuchsia-600\/100 {
  background-color: #c026d3;
}

.bg-fuchsia-600\/20 {
  background-color: #c026d333;
}

.bg-fuchsia-600\/25 {
  background-color: #c026d340;
}

.bg-fuchsia-600\/30 {
  background-color: #c026d34d;
}

.bg-fuchsia-600\/40 {
  background-color: #c026d366;
}

.bg-fuchsia-600\/5 {
  background-color: #c026d30d;
}

.bg-fuchsia-600\/50 {
  background-color: #c026d380;
}

.bg-fuchsia-600\/60 {
  background-color: #c026d399;
}

.bg-fuchsia-600\/70 {
  background-color: #c026d3b3;
}

.bg-fuchsia-600\/75 {
  background-color: #c026d3bf;
}

.bg-fuchsia-600\/80 {
  background-color: #c026d3cc;
}

.bg-fuchsia-600\/90 {
  background-color: #c026d3e6;
}

.bg-fuchsia-600\/95 {
  background-color: #c026d3f2;
}

.bg-fuchsia-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

.bg-fuchsia-700\/0 {
  background-color: #a21caf00;
}

.bg-fuchsia-700\/10 {
  background-color: #a21caf1a;
}

.bg-fuchsia-700\/100 {
  background-color: #a21caf;
}

.bg-fuchsia-700\/20 {
  background-color: #a21caf33;
}

.bg-fuchsia-700\/25 {
  background-color: #a21caf40;
}

.bg-fuchsia-700\/30 {
  background-color: #a21caf4d;
}

.bg-fuchsia-700\/40 {
  background-color: #a21caf66;
}

.bg-fuchsia-700\/5 {
  background-color: #a21caf0d;
}

.bg-fuchsia-700\/50 {
  background-color: #a21caf80;
}

.bg-fuchsia-700\/60 {
  background-color: #a21caf99;
}

.bg-fuchsia-700\/70 {
  background-color: #a21cafb3;
}

.bg-fuchsia-700\/75 {
  background-color: #a21cafbf;
}

.bg-fuchsia-700\/80 {
  background-color: #a21cafcc;
}

.bg-fuchsia-700\/90 {
  background-color: #a21cafe6;
}

.bg-fuchsia-700\/95 {
  background-color: #a21caff2;
}

.bg-fuchsia-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}

.bg-fuchsia-800\/0 {
  background-color: #86198f00;
}

.bg-fuchsia-800\/10 {
  background-color: #86198f1a;
}

.bg-fuchsia-800\/100 {
  background-color: #86198f;
}

.bg-fuchsia-800\/20 {
  background-color: #86198f33;
}

.bg-fuchsia-800\/25 {
  background-color: #86198f40;
}

.bg-fuchsia-800\/30 {
  background-color: #86198f4d;
}

.bg-fuchsia-800\/40 {
  background-color: #86198f66;
}

.bg-fuchsia-800\/5 {
  background-color: #86198f0d;
}

.bg-fuchsia-800\/50 {
  background-color: #86198f80;
}

.bg-fuchsia-800\/60 {
  background-color: #86198f99;
}

.bg-fuchsia-800\/70 {
  background-color: #86198fb3;
}

.bg-fuchsia-800\/75 {
  background-color: #86198fbf;
}

.bg-fuchsia-800\/80 {
  background-color: #86198fcc;
}

.bg-fuchsia-800\/90 {
  background-color: #86198fe6;
}

.bg-fuchsia-800\/95 {
  background-color: #86198ff2;
}

.bg-fuchsia-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}

.bg-fuchsia-900\/0 {
  background-color: #701a7500;
}

.bg-fuchsia-900\/10 {
  background-color: #701a751a;
}

.bg-fuchsia-900\/100 {
  background-color: #701a75;
}

.bg-fuchsia-900\/20 {
  background-color: #701a7533;
}

.bg-fuchsia-900\/25 {
  background-color: #701a7540;
}

.bg-fuchsia-900\/30 {
  background-color: #701a754d;
}

.bg-fuchsia-900\/40 {
  background-color: #701a7566;
}

.bg-fuchsia-900\/5 {
  background-color: #701a750d;
}

.bg-fuchsia-900\/50 {
  background-color: #701a7580;
}

.bg-fuchsia-900\/60 {
  background-color: #701a7599;
}

.bg-fuchsia-900\/70 {
  background-color: #701a75b3;
}

.bg-fuchsia-900\/75 {
  background-color: #701a75bf;
}

.bg-fuchsia-900\/80 {
  background-color: #701a75cc;
}

.bg-fuchsia-900\/90 {
  background-color: #701a75e6;
}

.bg-fuchsia-900\/95 {
  background-color: #701a75f2;
}

.bg-fuchsia-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity));
}

.bg-fuchsia-950\/0 {
  background-color: #4a044e00;
}

.bg-fuchsia-950\/10 {
  background-color: #4a044e1a;
}

.bg-fuchsia-950\/100 {
  background-color: #4a044e;
}

.bg-fuchsia-950\/20 {
  background-color: #4a044e33;
}

.bg-fuchsia-950\/25 {
  background-color: #4a044e40;
}

.bg-fuchsia-950\/30 {
  background-color: #4a044e4d;
}

.bg-fuchsia-950\/40 {
  background-color: #4a044e66;
}

.bg-fuchsia-950\/5 {
  background-color: #4a044e0d;
}

.bg-fuchsia-950\/50 {
  background-color: #4a044e80;
}

.bg-fuchsia-950\/60 {
  background-color: #4a044e99;
}

.bg-fuchsia-950\/70 {
  background-color: #4a044eb3;
}

.bg-fuchsia-950\/75 {
  background-color: #4a044ebf;
}

.bg-fuchsia-950\/80 {
  background-color: #4a044ecc;
}

.bg-fuchsia-950\/90 {
  background-color: #4a044ee6;
}

.bg-fuchsia-950\/95 {
  background-color: #4a044ef2;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-100\/0 {
  background-color: #f3f4f600;
}

.bg-gray-100\/10 {
  background-color: #f3f4f61a;
}

.bg-gray-100\/100 {
  background-color: #f3f4f6;
}

.bg-gray-100\/20 {
  background-color: #f3f4f633;
}

.bg-gray-100\/25 {
  background-color: #f3f4f640;
}

.bg-gray-100\/30 {
  background-color: #f3f4f64d;
}

.bg-gray-100\/40 {
  background-color: #f3f4f666;
}

.bg-gray-100\/5 {
  background-color: #f3f4f60d;
}

.bg-gray-100\/50 {
  background-color: #f3f4f680;
}

.bg-gray-100\/60 {
  background-color: #f3f4f699;
}

.bg-gray-100\/70 {
  background-color: #f3f4f6b3;
}

.bg-gray-100\/75 {
  background-color: #f3f4f6bf;
}

.bg-gray-100\/80 {
  background-color: #f3f4f6cc;
}

.bg-gray-100\/90 {
  background-color: #f3f4f6e6;
}

.bg-gray-100\/95 {
  background-color: #f3f4f6f2;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-200\/0 {
  background-color: #e5e7eb00;
}

.bg-gray-200\/10 {
  background-color: #e5e7eb1a;
}

.bg-gray-200\/100 {
  background-color: #e5e7eb;
}

.bg-gray-200\/20 {
  background-color: #e5e7eb33;
}

.bg-gray-200\/25 {
  background-color: #e5e7eb40;
}

.bg-gray-200\/30 {
  background-color: #e5e7eb4d;
}

.bg-gray-200\/40 {
  background-color: #e5e7eb66;
}

.bg-gray-200\/5 {
  background-color: #e5e7eb0d;
}

.bg-gray-200\/50 {
  background-color: #e5e7eb80;
}

.bg-gray-200\/60 {
  background-color: #e5e7eb99;
}

.bg-gray-200\/70 {
  background-color: #e5e7ebb3;
}

.bg-gray-200\/75 {
  background-color: #e5e7ebbf;
}

.bg-gray-200\/80 {
  background-color: #e5e7ebcc;
}

.bg-gray-200\/90 {
  background-color: #e5e7ebe6;
}

.bg-gray-200\/95 {
  background-color: #e5e7ebf2;
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-300\/0 {
  background-color: #d1d5db00;
}

.bg-gray-300\/10 {
  background-color: #d1d5db1a;
}

.bg-gray-300\/100 {
  background-color: #d1d5db;
}

.bg-gray-300\/20 {
  background-color: #d1d5db33;
}

.bg-gray-300\/25 {
  background-color: #d1d5db40;
}

.bg-gray-300\/30 {
  background-color: #d1d5db4d;
}

.bg-gray-300\/40 {
  background-color: #d1d5db66;
}

.bg-gray-300\/5 {
  background-color: #d1d5db0d;
}

.bg-gray-300\/50 {
  background-color: #d1d5db80;
}

.bg-gray-300\/60 {
  background-color: #d1d5db99;
}

.bg-gray-300\/70 {
  background-color: #d1d5dbb3;
}

.bg-gray-300\/75 {
  background-color: #d1d5dbbf;
}

.bg-gray-300\/80 {
  background-color: #d1d5dbcc;
}

.bg-gray-300\/90 {
  background-color: #d1d5dbe6;
}

.bg-gray-300\/95 {
  background-color: #d1d5dbf2;
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-400\/0 {
  background-color: #9ca3af00;
}

.bg-gray-400\/10 {
  background-color: #9ca3af1a;
}

.bg-gray-400\/100 {
  background-color: #9ca3af;
}

.bg-gray-400\/20 {
  background-color: #9ca3af33;
}

.bg-gray-400\/25 {
  background-color: #9ca3af40;
}

.bg-gray-400\/30 {
  background-color: #9ca3af4d;
}

.bg-gray-400\/40 {
  background-color: #9ca3af66;
}

.bg-gray-400\/5 {
  background-color: #9ca3af0d;
}

.bg-gray-400\/50 {
  background-color: #9ca3af80;
}

.bg-gray-400\/60 {
  background-color: #9ca3af99;
}

.bg-gray-400\/70 {
  background-color: #9ca3afb3;
}

.bg-gray-400\/75 {
  background-color: #9ca3afbf;
}

.bg-gray-400\/80 {
  background-color: #9ca3afcc;
}

.bg-gray-400\/90 {
  background-color: #9ca3afe6;
}

.bg-gray-400\/95 {
  background-color: #9ca3aff2;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-50\/0 {
  background-color: #f9fafb00;
}

.bg-gray-50\/10 {
  background-color: #f9fafb1a;
}

.bg-gray-50\/100 {
  background-color: #f9fafb;
}

.bg-gray-50\/20 {
  background-color: #f9fafb33;
}

.bg-gray-50\/25 {
  background-color: #f9fafb40;
}

.bg-gray-50\/30 {
  background-color: #f9fafb4d;
}

.bg-gray-50\/40 {
  background-color: #f9fafb66;
}

.bg-gray-50\/5 {
  background-color: #f9fafb0d;
}

.bg-gray-50\/50 {
  background-color: #f9fafb80;
}

.bg-gray-50\/60 {
  background-color: #f9fafb99;
}

.bg-gray-50\/70 {
  background-color: #f9fafbb3;
}

.bg-gray-50\/75 {
  background-color: #f9fafbbf;
}

.bg-gray-50\/80 {
  background-color: #f9fafbcc;
}

.bg-gray-50\/90 {
  background-color: #f9fafbe6;
}

.bg-gray-50\/95 {
  background-color: #f9fafbf2;
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-500\/0 {
  background-color: #6b728000;
}

.bg-gray-500\/10 {
  background-color: #6b72801a;
}

.bg-gray-500\/100 {
  background-color: #6b7280;
}

.bg-gray-500\/20 {
  background-color: #6b728033;
}

.bg-gray-500\/25 {
  background-color: #6b728040;
}

.bg-gray-500\/30 {
  background-color: #6b72804d;
}

.bg-gray-500\/40 {
  background-color: #6b728066;
}

.bg-gray-500\/5 {
  background-color: #6b72800d;
}

.bg-gray-500\/50 {
  background-color: #6b728080;
}

.bg-gray-500\/60 {
  background-color: #6b728099;
}

.bg-gray-500\/70 {
  background-color: #6b7280b3;
}

.bg-gray-500\/75 {
  background-color: #6b7280bf;
}

.bg-gray-500\/80 {
  background-color: #6b7280cc;
}

.bg-gray-500\/90 {
  background-color: #6b7280e6;
}

.bg-gray-500\/95 {
  background-color: #6b7280f2;
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-600\/0 {
  background-color: #4b556300;
}

.bg-gray-600\/10 {
  background-color: #4b55631a;
}

.bg-gray-600\/100 {
  background-color: #4b5563;
}

.bg-gray-600\/20 {
  background-color: #4b556333;
}

.bg-gray-600\/25 {
  background-color: #4b556340;
}

.bg-gray-600\/30 {
  background-color: #4b55634d;
}

.bg-gray-600\/40 {
  background-color: #4b556366;
}

.bg-gray-600\/5 {
  background-color: #4b55630d;
}

.bg-gray-600\/50 {
  background-color: #4b556380;
}

.bg-gray-600\/60 {
  background-color: #4b556399;
}

.bg-gray-600\/70 {
  background-color: #4b5563b3;
}

.bg-gray-600\/75 {
  background-color: #4b5563bf;
}

.bg-gray-600\/80 {
  background-color: #4b5563cc;
}

.bg-gray-600\/90 {
  background-color: #4b5563e6;
}

.bg-gray-600\/95 {
  background-color: #4b5563f2;
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-700\/0 {
  background-color: #37415100;
}

.bg-gray-700\/10 {
  background-color: #3741511a;
}

.bg-gray-700\/100 {
  background-color: #374151;
}

.bg-gray-700\/20 {
  background-color: #37415133;
}

.bg-gray-700\/25 {
  background-color: #37415140;
}

.bg-gray-700\/30 {
  background-color: #3741514d;
}

.bg-gray-700\/40 {
  background-color: #37415166;
}

.bg-gray-700\/5 {
  background-color: #3741510d;
}

.bg-gray-700\/50 {
  background-color: #37415180;
}

.bg-gray-700\/60 {
  background-color: #37415199;
}

.bg-gray-700\/70 {
  background-color: #374151b3;
}

.bg-gray-700\/75 {
  background-color: #374151bf;
}

.bg-gray-700\/80 {
  background-color: #374151cc;
}

.bg-gray-700\/90 {
  background-color: #374151e6;
}

.bg-gray-700\/95 {
  background-color: #374151f2;
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-800\/0 {
  background-color: #1f293700;
}

.bg-gray-800\/10 {
  background-color: #1f29371a;
}

.bg-gray-800\/100 {
  background-color: #1f2937;
}

.bg-gray-800\/20 {
  background-color: #1f293733;
}

.bg-gray-800\/25 {
  background-color: #1f293740;
}

.bg-gray-800\/30 {
  background-color: #1f29374d;
}

.bg-gray-800\/40 {
  background-color: #1f293766;
}

.bg-gray-800\/5 {
  background-color: #1f29370d;
}

.bg-gray-800\/50 {
  background-color: #1f293780;
}

.bg-gray-800\/60 {
  background-color: #1f293799;
}

.bg-gray-800\/70 {
  background-color: #1f2937b3;
}

.bg-gray-800\/75 {
  background-color: #1f2937bf;
}

.bg-gray-800\/80 {
  background-color: #1f2937cc;
}

.bg-gray-800\/90 {
  background-color: #1f2937e6;
}

.bg-gray-800\/95 {
  background-color: #1f2937f2;
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-gray-900\/0 {
  background-color: #11182700;
}

.bg-gray-900\/10 {
  background-color: #1118271a;
}

.bg-gray-900\/100 {
  background-color: #111827;
}

.bg-gray-900\/20 {
  background-color: #11182733;
}

.bg-gray-900\/25 {
  background-color: #11182740;
}

.bg-gray-900\/30 {
  background-color: #1118274d;
}

.bg-gray-900\/40 {
  background-color: #11182766;
}

.bg-gray-900\/5 {
  background-color: #1118270d;
}

.bg-gray-900\/50 {
  background-color: #11182780;
}

.bg-gray-900\/60 {
  background-color: #11182799;
}

.bg-gray-900\/70 {
  background-color: #111827b3;
}

.bg-gray-900\/75 {
  background-color: #111827bf;
}

.bg-gray-900\/80 {
  background-color: #111827cc;
}

.bg-gray-900\/90 {
  background-color: #111827e6;
}

.bg-gray-900\/95 {
  background-color: #111827f2;
}

.bg-gray-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.bg-gray-950\/0 {
  background-color: #03071200;
}

.bg-gray-950\/10 {
  background-color: #0307121a;
}

.bg-gray-950\/100 {
  background-color: #030712;
}

.bg-gray-950\/20 {
  background-color: #03071233;
}

.bg-gray-950\/25 {
  background-color: #03071240;
}

.bg-gray-950\/30 {
  background-color: #0307124d;
}

.bg-gray-950\/40 {
  background-color: #03071266;
}

.bg-gray-950\/5 {
  background-color: #0307120d;
}

.bg-gray-950\/50 {
  background-color: #03071280;
}

.bg-gray-950\/60 {
  background-color: #03071299;
}

.bg-gray-950\/70 {
  background-color: #030712b3;
}

.bg-gray-950\/75 {
  background-color: #030712bf;
}

.bg-gray-950\/80 {
  background-color: #030712cc;
}

.bg-gray-950\/90 {
  background-color: #030712e6;
}

.bg-gray-950\/95 {
  background-color: #030712f2;
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-100\/0 {
  background-color: #dcfce700;
}

.bg-green-100\/10 {
  background-color: #dcfce71a;
}

.bg-green-100\/100 {
  background-color: #dcfce7;
}

.bg-green-100\/20 {
  background-color: #dcfce733;
}

.bg-green-100\/25 {
  background-color: #dcfce740;
}

.bg-green-100\/30 {
  background-color: #dcfce74d;
}

.bg-green-100\/40 {
  background-color: #dcfce766;
}

.bg-green-100\/5 {
  background-color: #dcfce70d;
}

.bg-green-100\/50 {
  background-color: #dcfce780;
}

.bg-green-100\/60 {
  background-color: #dcfce799;
}

.bg-green-100\/70 {
  background-color: #dcfce7b3;
}

.bg-green-100\/75 {
  background-color: #dcfce7bf;
}

.bg-green-100\/80 {
  background-color: #dcfce7cc;
}

.bg-green-100\/90 {
  background-color: #dcfce7e6;
}

.bg-green-100\/95 {
  background-color: #dcfce7f2;
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-green-200\/0 {
  background-color: #bbf7d000;
}

.bg-green-200\/10 {
  background-color: #bbf7d01a;
}

.bg-green-200\/100 {
  background-color: #bbf7d0;
}

.bg-green-200\/20 {
  background-color: #bbf7d033;
}

.bg-green-200\/25 {
  background-color: #bbf7d040;
}

.bg-green-200\/30 {
  background-color: #bbf7d04d;
}

.bg-green-200\/40 {
  background-color: #bbf7d066;
}

.bg-green-200\/5 {
  background-color: #bbf7d00d;
}

.bg-green-200\/50 {
  background-color: #bbf7d080;
}

.bg-green-200\/60 {
  background-color: #bbf7d099;
}

.bg-green-200\/70 {
  background-color: #bbf7d0b3;
}

.bg-green-200\/75 {
  background-color: #bbf7d0bf;
}

.bg-green-200\/80 {
  background-color: #bbf7d0cc;
}

.bg-green-200\/90 {
  background-color: #bbf7d0e6;
}

.bg-green-200\/95 {
  background-color: #bbf7d0f2;
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-green-300\/0 {
  background-color: #86efac00;
}

.bg-green-300\/10 {
  background-color: #86efac1a;
}

.bg-green-300\/100 {
  background-color: #86efac;
}

.bg-green-300\/20 {
  background-color: #86efac33;
}

.bg-green-300\/25 {
  background-color: #86efac40;
}

.bg-green-300\/30 {
  background-color: #86efac4d;
}

.bg-green-300\/40 {
  background-color: #86efac66;
}

.bg-green-300\/5 {
  background-color: #86efac0d;
}

.bg-green-300\/50 {
  background-color: #86efac80;
}

.bg-green-300\/60 {
  background-color: #86efac99;
}

.bg-green-300\/70 {
  background-color: #86efacb3;
}

.bg-green-300\/75 {
  background-color: #86efacbf;
}

.bg-green-300\/80 {
  background-color: #86efaccc;
}

.bg-green-300\/90 {
  background-color: #86eface6;
}

.bg-green-300\/95 {
  background-color: #86efacf2;
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-green-400\/0 {
  background-color: #4ade8000;
}

.bg-green-400\/10 {
  background-color: #4ade801a;
}

.bg-green-400\/100 {
  background-color: #4ade80;
}

.bg-green-400\/20 {
  background-color: #4ade8033;
}

.bg-green-400\/25 {
  background-color: #4ade8040;
}

.bg-green-400\/30 {
  background-color: #4ade804d;
}

.bg-green-400\/40 {
  background-color: #4ade8066;
}

.bg-green-400\/5 {
  background-color: #4ade800d;
}

.bg-green-400\/50 {
  background-color: #4ade8080;
}

.bg-green-400\/60 {
  background-color: #4ade8099;
}

.bg-green-400\/70 {
  background-color: #4ade80b3;
}

.bg-green-400\/75 {
  background-color: #4ade80bf;
}

.bg-green-400\/80 {
  background-color: #4ade80cc;
}

.bg-green-400\/90 {
  background-color: #4ade80e6;
}

.bg-green-400\/95 {
  background-color: #4ade80f2;
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.bg-green-50\/0 {
  background-color: #f0fdf400;
}

.bg-green-50\/10 {
  background-color: #f0fdf41a;
}

.bg-green-50\/100 {
  background-color: #f0fdf4;
}

.bg-green-50\/20 {
  background-color: #f0fdf433;
}

.bg-green-50\/25 {
  background-color: #f0fdf440;
}

.bg-green-50\/30 {
  background-color: #f0fdf44d;
}

.bg-green-50\/40 {
  background-color: #f0fdf466;
}

.bg-green-50\/5 {
  background-color: #f0fdf40d;
}

.bg-green-50\/50 {
  background-color: #f0fdf480;
}

.bg-green-50\/60 {
  background-color: #f0fdf499;
}

.bg-green-50\/70 {
  background-color: #f0fdf4b3;
}

.bg-green-50\/75 {
  background-color: #f0fdf4bf;
}

.bg-green-50\/80 {
  background-color: #f0fdf4cc;
}

.bg-green-50\/90 {
  background-color: #f0fdf4e6;
}

.bg-green-50\/95 {
  background-color: #f0fdf4f2;
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-500\/0 {
  background-color: #22c55e00;
}

.bg-green-500\/10 {
  background-color: #22c55e1a;
}

.bg-green-500\/100 {
  background-color: #22c55e;
}

.bg-green-500\/20 {
  background-color: #22c55e33;
}

.bg-green-500\/25 {
  background-color: #22c55e40;
}

.bg-green-500\/30 {
  background-color: #22c55e4d;
}

.bg-green-500\/40 {
  background-color: #22c55e66;
}

.bg-green-500\/5 {
  background-color: #22c55e0d;
}

.bg-green-500\/50 {
  background-color: #22c55e80;
}

.bg-green-500\/60 {
  background-color: #22c55e99;
}

.bg-green-500\/70 {
  background-color: #22c55eb3;
}

.bg-green-500\/75 {
  background-color: #22c55ebf;
}

.bg-green-500\/80 {
  background-color: #22c55ecc;
}

.bg-green-500\/90 {
  background-color: #22c55ee6;
}

.bg-green-500\/95 {
  background-color: #22c55ef2;
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-600\/0 {
  background-color: #16a34a00;
}

.bg-green-600\/10 {
  background-color: #16a34a1a;
}

.bg-green-600\/100 {
  background-color: #16a34a;
}

.bg-green-600\/20 {
  background-color: #16a34a33;
}

.bg-green-600\/25 {
  background-color: #16a34a40;
}

.bg-green-600\/30 {
  background-color: #16a34a4d;
}

.bg-green-600\/40 {
  background-color: #16a34a66;
}

.bg-green-600\/5 {
  background-color: #16a34a0d;
}

.bg-green-600\/50 {
  background-color: #16a34a80;
}

.bg-green-600\/60 {
  background-color: #16a34a99;
}

.bg-green-600\/70 {
  background-color: #16a34ab3;
}

.bg-green-600\/75 {
  background-color: #16a34abf;
}

.bg-green-600\/80 {
  background-color: #16a34acc;
}

.bg-green-600\/90 {
  background-color: #16a34ae6;
}

.bg-green-600\/95 {
  background-color: #16a34af2;
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-green-700\/0 {
  background-color: #15803d00;
}

.bg-green-700\/10 {
  background-color: #15803d1a;
}

.bg-green-700\/100 {
  background-color: #15803d;
}

.bg-green-700\/20 {
  background-color: #15803d33;
}

.bg-green-700\/25 {
  background-color: #15803d40;
}

.bg-green-700\/30 {
  background-color: #15803d4d;
}

.bg-green-700\/40 {
  background-color: #15803d66;
}

.bg-green-700\/5 {
  background-color: #15803d0d;
}

.bg-green-700\/50 {
  background-color: #15803d80;
}

.bg-green-700\/60 {
  background-color: #15803d99;
}

.bg-green-700\/70 {
  background-color: #15803db3;
}

.bg-green-700\/75 {
  background-color: #15803dbf;
}

.bg-green-700\/80 {
  background-color: #15803dcc;
}

.bg-green-700\/90 {
  background-color: #15803de6;
}

.bg-green-700\/95 {
  background-color: #15803df2;
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.bg-green-800\/0 {
  background-color: #16653400;
}

.bg-green-800\/10 {
  background-color: #1665341a;
}

.bg-green-800\/100 {
  background-color: #166534;
}

.bg-green-800\/20 {
  background-color: #16653433;
}

.bg-green-800\/25 {
  background-color: #16653440;
}

.bg-green-800\/30 {
  background-color: #1665344d;
}

.bg-green-800\/40 {
  background-color: #16653466;
}

.bg-green-800\/5 {
  background-color: #1665340d;
}

.bg-green-800\/50 {
  background-color: #16653480;
}

.bg-green-800\/60 {
  background-color: #16653499;
}

.bg-green-800\/70 {
  background-color: #166534b3;
}

.bg-green-800\/75 {
  background-color: #166534bf;
}

.bg-green-800\/80 {
  background-color: #166534cc;
}

.bg-green-800\/90 {
  background-color: #166534e6;
}

.bg-green-800\/95 {
  background-color: #166534f2;
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.bg-green-900\/0 {
  background-color: #14532d00;
}

.bg-green-900\/10 {
  background-color: #14532d1a;
}

.bg-green-900\/100 {
  background-color: #14532d;
}

.bg-green-900\/20 {
  background-color: #14532d33;
}

.bg-green-900\/25 {
  background-color: #14532d40;
}

.bg-green-900\/30 {
  background-color: #14532d4d;
}

.bg-green-900\/40 {
  background-color: #14532d66;
}

.bg-green-900\/5 {
  background-color: #14532d0d;
}

.bg-green-900\/50 {
  background-color: #14532d80;
}

.bg-green-900\/60 {
  background-color: #14532d99;
}

.bg-green-900\/70 {
  background-color: #14532db3;
}

.bg-green-900\/75 {
  background-color: #14532dbf;
}

.bg-green-900\/80 {
  background-color: #14532dcc;
}

.bg-green-900\/90 {
  background-color: #14532de6;
}

.bg-green-900\/95 {
  background-color: #14532df2;
}

.bg-green-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 46 22 / var(--tw-bg-opacity));
}

.bg-green-950\/0 {
  background-color: #052e1600;
}

.bg-green-950\/10 {
  background-color: #052e161a;
}

.bg-green-950\/100 {
  background-color: #052e16;
}

.bg-green-950\/20 {
  background-color: #052e1633;
}

.bg-green-950\/25 {
  background-color: #052e1640;
}

.bg-green-950\/30 {
  background-color: #052e164d;
}

.bg-green-950\/40 {
  background-color: #052e1666;
}

.bg-green-950\/5 {
  background-color: #052e160d;
}

.bg-green-950\/50 {
  background-color: #052e1680;
}

.bg-green-950\/60 {
  background-color: #052e1699;
}

.bg-green-950\/70 {
  background-color: #052e16b3;
}

.bg-green-950\/75 {
  background-color: #052e16bf;
}

.bg-green-950\/80 {
  background-color: #052e16cc;
}

.bg-green-950\/90 {
  background-color: #052e16e6;
}

.bg-green-950\/95 {
  background-color: #052e16f2;
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-100\/0 {
  background-color: #e0e7ff00;
}

.bg-indigo-100\/10 {
  background-color: #e0e7ff1a;
}

.bg-indigo-100\/100 {
  background-color: #e0e7ff;
}

.bg-indigo-100\/20 {
  background-color: #e0e7ff33;
}

.bg-indigo-100\/25 {
  background-color: #e0e7ff40;
}

.bg-indigo-100\/30 {
  background-color: #e0e7ff4d;
}

.bg-indigo-100\/40 {
  background-color: #e0e7ff66;
}

.bg-indigo-100\/5 {
  background-color: #e0e7ff0d;
}

.bg-indigo-100\/50 {
  background-color: #e0e7ff80;
}

.bg-indigo-100\/60 {
  background-color: #e0e7ff99;
}

.bg-indigo-100\/70 {
  background-color: #e0e7ffb3;
}

.bg-indigo-100\/75 {
  background-color: #e0e7ffbf;
}

.bg-indigo-100\/80 {
  background-color: #e0e7ffcc;
}

.bg-indigo-100\/90 {
  background-color: #e0e7ffe6;
}

.bg-indigo-100\/95 {
  background-color: #e0e7fff2;
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.bg-indigo-200\/0 {
  background-color: #c7d2fe00;
}

.bg-indigo-200\/10 {
  background-color: #c7d2fe1a;
}

.bg-indigo-200\/100 {
  background-color: #c7d2fe;
}

.bg-indigo-200\/20 {
  background-color: #c7d2fe33;
}

.bg-indigo-200\/25 {
  background-color: #c7d2fe40;
}

.bg-indigo-200\/30 {
  background-color: #c7d2fe4d;
}

.bg-indigo-200\/40 {
  background-color: #c7d2fe66;
}

.bg-indigo-200\/5 {
  background-color: #c7d2fe0d;
}

.bg-indigo-200\/50 {
  background-color: #c7d2fe80;
}

.bg-indigo-200\/60 {
  background-color: #c7d2fe99;
}

.bg-indigo-200\/70 {
  background-color: #c7d2feb3;
}

.bg-indigo-200\/75 {
  background-color: #c7d2febf;
}

.bg-indigo-200\/80 {
  background-color: #c7d2fecc;
}

.bg-indigo-200\/90 {
  background-color: #c7d2fee6;
}

.bg-indigo-200\/95 {
  background-color: #c7d2fef2;
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.bg-indigo-300\/0 {
  background-color: #a5b4fc00;
}

.bg-indigo-300\/10 {
  background-color: #a5b4fc1a;
}

.bg-indigo-300\/100 {
  background-color: #a5b4fc;
}

.bg-indigo-300\/20 {
  background-color: #a5b4fc33;
}

.bg-indigo-300\/25 {
  background-color: #a5b4fc40;
}

.bg-indigo-300\/30 {
  background-color: #a5b4fc4d;
}

.bg-indigo-300\/40 {
  background-color: #a5b4fc66;
}

.bg-indigo-300\/5 {
  background-color: #a5b4fc0d;
}

.bg-indigo-300\/50 {
  background-color: #a5b4fc80;
}

.bg-indigo-300\/60 {
  background-color: #a5b4fc99;
}

.bg-indigo-300\/70 {
  background-color: #a5b4fcb3;
}

.bg-indigo-300\/75 {
  background-color: #a5b4fcbf;
}

.bg-indigo-300\/80 {
  background-color: #a5b4fccc;
}

.bg-indigo-300\/90 {
  background-color: #a5b4fce6;
}

.bg-indigo-300\/95 {
  background-color: #a5b4fcf2;
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.bg-indigo-400\/0 {
  background-color: #818cf800;
}

.bg-indigo-400\/10 {
  background-color: #818cf81a;
}

.bg-indigo-400\/100 {
  background-color: #818cf8;
}

.bg-indigo-400\/20 {
  background-color: #818cf833;
}

.bg-indigo-400\/25 {
  background-color: #818cf840;
}

.bg-indigo-400\/30 {
  background-color: #818cf84d;
}

.bg-indigo-400\/40 {
  background-color: #818cf866;
}

.bg-indigo-400\/5 {
  background-color: #818cf80d;
}

.bg-indigo-400\/50 {
  background-color: #818cf880;
}

.bg-indigo-400\/60 {
  background-color: #818cf899;
}

.bg-indigo-400\/70 {
  background-color: #818cf8b3;
}

.bg-indigo-400\/75 {
  background-color: #818cf8bf;
}

.bg-indigo-400\/80 {
  background-color: #818cf8cc;
}

.bg-indigo-400\/90 {
  background-color: #818cf8e6;
}

.bg-indigo-400\/95 {
  background-color: #818cf8f2;
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.bg-indigo-50\/0 {
  background-color: #eef2ff00;
}

.bg-indigo-50\/10 {
  background-color: #eef2ff1a;
}

.bg-indigo-50\/100 {
  background-color: #eef2ff;
}

.bg-indigo-50\/20 {
  background-color: #eef2ff33;
}

.bg-indigo-50\/25 {
  background-color: #eef2ff40;
}

.bg-indigo-50\/30 {
  background-color: #eef2ff4d;
}

.bg-indigo-50\/40 {
  background-color: #eef2ff66;
}

.bg-indigo-50\/5 {
  background-color: #eef2ff0d;
}

.bg-indigo-50\/50 {
  background-color: #eef2ff80;
}

.bg-indigo-50\/60 {
  background-color: #eef2ff99;
}

.bg-indigo-50\/70 {
  background-color: #eef2ffb3;
}

.bg-indigo-50\/75 {
  background-color: #eef2ffbf;
}

.bg-indigo-50\/80 {
  background-color: #eef2ffcc;
}

.bg-indigo-50\/90 {
  background-color: #eef2ffe6;
}

.bg-indigo-50\/95 {
  background-color: #eef2fff2;
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-indigo-500\/0 {
  background-color: #6366f100;
}

.bg-indigo-500\/10 {
  background-color: #6366f11a;
}

.bg-indigo-500\/100 {
  background-color: #6366f1;
}

.bg-indigo-500\/20 {
  background-color: #6366f133;
}

.bg-indigo-500\/25 {
  background-color: #6366f140;
}

.bg-indigo-500\/30 {
  background-color: #6366f14d;
}

.bg-indigo-500\/40 {
  background-color: #6366f166;
}

.bg-indigo-500\/5 {
  background-color: #6366f10d;
}

.bg-indigo-500\/50 {
  background-color: #6366f180;
}

.bg-indigo-500\/60 {
  background-color: #6366f199;
}

.bg-indigo-500\/70 {
  background-color: #6366f1b3;
}

.bg-indigo-500\/75 {
  background-color: #6366f1bf;
}

.bg-indigo-500\/80 {
  background-color: #6366f1cc;
}

.bg-indigo-500\/90 {
  background-color: #6366f1e6;
}

.bg-indigo-500\/95 {
  background-color: #6366f1f2;
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-indigo-600\/0 {
  background-color: #4f46e500;
}

.bg-indigo-600\/10 {
  background-color: #4f46e51a;
}

.bg-indigo-600\/100 {
  background-color: #4f46e5;
}

.bg-indigo-600\/20 {
  background-color: #4f46e533;
}

.bg-indigo-600\/25 {
  background-color: #4f46e540;
}

.bg-indigo-600\/30 {
  background-color: #4f46e54d;
}

.bg-indigo-600\/40 {
  background-color: #4f46e566;
}

.bg-indigo-600\/5 {
  background-color: #4f46e50d;
}

.bg-indigo-600\/50 {
  background-color: #4f46e580;
}

.bg-indigo-600\/60 {
  background-color: #4f46e599;
}

.bg-indigo-600\/70 {
  background-color: #4f46e5b3;
}

.bg-indigo-600\/75 {
  background-color: #4f46e5bf;
}

.bg-indigo-600\/80 {
  background-color: #4f46e5cc;
}

.bg-indigo-600\/90 {
  background-color: #4f46e5e6;
}

.bg-indigo-600\/95 {
  background-color: #4f46e5f2;
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.bg-indigo-700\/0 {
  background-color: #4338ca00;
}

.bg-indigo-700\/10 {
  background-color: #4338ca1a;
}

.bg-indigo-700\/100 {
  background-color: #4338ca;
}

.bg-indigo-700\/20 {
  background-color: #4338ca33;
}

.bg-indigo-700\/25 {
  background-color: #4338ca40;
}

.bg-indigo-700\/30 {
  background-color: #4338ca4d;
}

.bg-indigo-700\/40 {
  background-color: #4338ca66;
}

.bg-indigo-700\/5 {
  background-color: #4338ca0d;
}

.bg-indigo-700\/50 {
  background-color: #4338ca80;
}

.bg-indigo-700\/60 {
  background-color: #4338ca99;
}

.bg-indigo-700\/70 {
  background-color: #4338cab3;
}

.bg-indigo-700\/75 {
  background-color: #4338cabf;
}

.bg-indigo-700\/80 {
  background-color: #4338cacc;
}

.bg-indigo-700\/90 {
  background-color: #4338cae6;
}

.bg-indigo-700\/95 {
  background-color: #4338caf2;
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.bg-indigo-800\/0 {
  background-color: #3730a300;
}

.bg-indigo-800\/10 {
  background-color: #3730a31a;
}

.bg-indigo-800\/100 {
  background-color: #3730a3;
}

.bg-indigo-800\/20 {
  background-color: #3730a333;
}

.bg-indigo-800\/25 {
  background-color: #3730a340;
}

.bg-indigo-800\/30 {
  background-color: #3730a34d;
}

.bg-indigo-800\/40 {
  background-color: #3730a366;
}

.bg-indigo-800\/5 {
  background-color: #3730a30d;
}

.bg-indigo-800\/50 {
  background-color: #3730a380;
}

.bg-indigo-800\/60 {
  background-color: #3730a399;
}

.bg-indigo-800\/70 {
  background-color: #3730a3b3;
}

.bg-indigo-800\/75 {
  background-color: #3730a3bf;
}

.bg-indigo-800\/80 {
  background-color: #3730a3cc;
}

.bg-indigo-800\/90 {
  background-color: #3730a3e6;
}

.bg-indigo-800\/95 {
  background-color: #3730a3f2;
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

.bg-indigo-900\/0 {
  background-color: #312e8100;
}

.bg-indigo-900\/10 {
  background-color: #312e811a;
}

.bg-indigo-900\/100 {
  background-color: #312e81;
}

.bg-indigo-900\/20 {
  background-color: #312e8133;
}

.bg-indigo-900\/25 {
  background-color: #312e8140;
}

.bg-indigo-900\/30 {
  background-color: #312e814d;
}

.bg-indigo-900\/40 {
  background-color: #312e8166;
}

.bg-indigo-900\/5 {
  background-color: #312e810d;
}

.bg-indigo-900\/50 {
  background-color: #312e8180;
}

.bg-indigo-900\/60 {
  background-color: #312e8199;
}

.bg-indigo-900\/70 {
  background-color: #312e81b3;
}

.bg-indigo-900\/75 {
  background-color: #312e81bf;
}

.bg-indigo-900\/80 {
  background-color: #312e81cc;
}

.bg-indigo-900\/90 {
  background-color: #312e81e6;
}

.bg-indigo-900\/95 {
  background-color: #312e81f2;
}

.bg-indigo-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}

.bg-indigo-950\/0 {
  background-color: #1e1b4b00;
}

.bg-indigo-950\/10 {
  background-color: #1e1b4b1a;
}

.bg-indigo-950\/100 {
  background-color: #1e1b4b;
}

.bg-indigo-950\/20 {
  background-color: #1e1b4b33;
}

.bg-indigo-950\/25 {
  background-color: #1e1b4b40;
}

.bg-indigo-950\/30 {
  background-color: #1e1b4b4d;
}

.bg-indigo-950\/40 {
  background-color: #1e1b4b66;
}

.bg-indigo-950\/5 {
  background-color: #1e1b4b0d;
}

.bg-indigo-950\/50 {
  background-color: #1e1b4b80;
}

.bg-indigo-950\/60 {
  background-color: #1e1b4b99;
}

.bg-indigo-950\/70 {
  background-color: #1e1b4bb3;
}

.bg-indigo-950\/75 {
  background-color: #1e1b4bbf;
}

.bg-indigo-950\/80 {
  background-color: #1e1b4bcc;
}

.bg-indigo-950\/90 {
  background-color: #1e1b4be6;
}

.bg-indigo-950\/95 {
  background-color: #1e1b4bf2;
}

.bg-inherit {
  background-color: inherit;
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.bg-lime-100\/0 {
  background-color: #ecfccb00;
}

.bg-lime-100\/10 {
  background-color: #ecfccb1a;
}

.bg-lime-100\/100 {
  background-color: #ecfccb;
}

.bg-lime-100\/20 {
  background-color: #ecfccb33;
}

.bg-lime-100\/25 {
  background-color: #ecfccb40;
}

.bg-lime-100\/30 {
  background-color: #ecfccb4d;
}

.bg-lime-100\/40 {
  background-color: #ecfccb66;
}

.bg-lime-100\/5 {
  background-color: #ecfccb0d;
}

.bg-lime-100\/50 {
  background-color: #ecfccb80;
}

.bg-lime-100\/60 {
  background-color: #ecfccb99;
}

.bg-lime-100\/70 {
  background-color: #ecfccbb3;
}

.bg-lime-100\/75 {
  background-color: #ecfccbbf;
}

.bg-lime-100\/80 {
  background-color: #ecfccbcc;
}

.bg-lime-100\/90 {
  background-color: #ecfccbe6;
}

.bg-lime-100\/95 {
  background-color: #ecfccbf2;
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.bg-lime-200\/0 {
  background-color: #d9f99d00;
}

.bg-lime-200\/10 {
  background-color: #d9f99d1a;
}

.bg-lime-200\/100 {
  background-color: #d9f99d;
}

.bg-lime-200\/20 {
  background-color: #d9f99d33;
}

.bg-lime-200\/25 {
  background-color: #d9f99d40;
}

.bg-lime-200\/30 {
  background-color: #d9f99d4d;
}

.bg-lime-200\/40 {
  background-color: #d9f99d66;
}

.bg-lime-200\/5 {
  background-color: #d9f99d0d;
}

.bg-lime-200\/50 {
  background-color: #d9f99d80;
}

.bg-lime-200\/60 {
  background-color: #d9f99d99;
}

.bg-lime-200\/70 {
  background-color: #d9f99db3;
}

.bg-lime-200\/75 {
  background-color: #d9f99dbf;
}

.bg-lime-200\/80 {
  background-color: #d9f99dcc;
}

.bg-lime-200\/90 {
  background-color: #d9f99de6;
}

.bg-lime-200\/95 {
  background-color: #d9f99df2;
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.bg-lime-300\/0 {
  background-color: #bef26400;
}

.bg-lime-300\/10 {
  background-color: #bef2641a;
}

.bg-lime-300\/100 {
  background-color: #bef264;
}

.bg-lime-300\/20 {
  background-color: #bef26433;
}

.bg-lime-300\/25 {
  background-color: #bef26440;
}

.bg-lime-300\/30 {
  background-color: #bef2644d;
}

.bg-lime-300\/40 {
  background-color: #bef26466;
}

.bg-lime-300\/5 {
  background-color: #bef2640d;
}

.bg-lime-300\/50 {
  background-color: #bef26480;
}

.bg-lime-300\/60 {
  background-color: #bef26499;
}

.bg-lime-300\/70 {
  background-color: #bef264b3;
}

.bg-lime-300\/75 {
  background-color: #bef264bf;
}

.bg-lime-300\/80 {
  background-color: #bef264cc;
}

.bg-lime-300\/90 {
  background-color: #bef264e6;
}

.bg-lime-300\/95 {
  background-color: #bef264f2;
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-lime-400\/0 {
  background-color: #a3e63500;
}

.bg-lime-400\/10 {
  background-color: #a3e6351a;
}

.bg-lime-400\/100 {
  background-color: #a3e635;
}

.bg-lime-400\/20 {
  background-color: #a3e63533;
}

.bg-lime-400\/25 {
  background-color: #a3e63540;
}

.bg-lime-400\/30 {
  background-color: #a3e6354d;
}

.bg-lime-400\/40 {
  background-color: #a3e63566;
}

.bg-lime-400\/5 {
  background-color: #a3e6350d;
}

.bg-lime-400\/50 {
  background-color: #a3e63580;
}

.bg-lime-400\/60 {
  background-color: #a3e63599;
}

.bg-lime-400\/70 {
  background-color: #a3e635b3;
}

.bg-lime-400\/75 {
  background-color: #a3e635bf;
}

.bg-lime-400\/80 {
  background-color: #a3e635cc;
}

.bg-lime-400\/90 {
  background-color: #a3e635e6;
}

.bg-lime-400\/95 {
  background-color: #a3e635f2;
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.bg-lime-50\/0 {
  background-color: #f7fee700;
}

.bg-lime-50\/10 {
  background-color: #f7fee71a;
}

.bg-lime-50\/100 {
  background-color: #f7fee7;
}

.bg-lime-50\/20 {
  background-color: #f7fee733;
}

.bg-lime-50\/25 {
  background-color: #f7fee740;
}

.bg-lime-50\/30 {
  background-color: #f7fee74d;
}

.bg-lime-50\/40 {
  background-color: #f7fee766;
}

.bg-lime-50\/5 {
  background-color: #f7fee70d;
}

.bg-lime-50\/50 {
  background-color: #f7fee780;
}

.bg-lime-50\/60 {
  background-color: #f7fee799;
}

.bg-lime-50\/70 {
  background-color: #f7fee7b3;
}

.bg-lime-50\/75 {
  background-color: #f7fee7bf;
}

.bg-lime-50\/80 {
  background-color: #f7fee7cc;
}

.bg-lime-50\/90 {
  background-color: #f7fee7e6;
}

.bg-lime-50\/95 {
  background-color: #f7fee7f2;
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.bg-lime-500\/0 {
  background-color: #84cc1600;
}

.bg-lime-500\/10 {
  background-color: #84cc161a;
}

.bg-lime-500\/100 {
  background-color: #84cc16;
}

.bg-lime-500\/20 {
  background-color: #84cc1633;
}

.bg-lime-500\/25 {
  background-color: #84cc1640;
}

.bg-lime-500\/30 {
  background-color: #84cc164d;
}

.bg-lime-500\/40 {
  background-color: #84cc1666;
}

.bg-lime-500\/5 {
  background-color: #84cc160d;
}

.bg-lime-500\/50 {
  background-color: #84cc1680;
}

.bg-lime-500\/60 {
  background-color: #84cc1699;
}

.bg-lime-500\/70 {
  background-color: #84cc16b3;
}

.bg-lime-500\/75 {
  background-color: #84cc16bf;
}

.bg-lime-500\/80 {
  background-color: #84cc16cc;
}

.bg-lime-500\/90 {
  background-color: #84cc16e6;
}

.bg-lime-500\/95 {
  background-color: #84cc16f2;
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.bg-lime-600\/0 {
  background-color: #65a30d00;
}

.bg-lime-600\/10 {
  background-color: #65a30d1a;
}

.bg-lime-600\/100 {
  background-color: #65a30d;
}

.bg-lime-600\/20 {
  background-color: #65a30d33;
}

.bg-lime-600\/25 {
  background-color: #65a30d40;
}

.bg-lime-600\/30 {
  background-color: #65a30d4d;
}

.bg-lime-600\/40 {
  background-color: #65a30d66;
}

.bg-lime-600\/5 {
  background-color: #65a30d0d;
}

.bg-lime-600\/50 {
  background-color: #65a30d80;
}

.bg-lime-600\/60 {
  background-color: #65a30d99;
}

.bg-lime-600\/70 {
  background-color: #65a30db3;
}

.bg-lime-600\/75 {
  background-color: #65a30dbf;
}

.bg-lime-600\/80 {
  background-color: #65a30dcc;
}

.bg-lime-600\/90 {
  background-color: #65a30de6;
}

.bg-lime-600\/95 {
  background-color: #65a30df2;
}

.bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

.bg-lime-700\/0 {
  background-color: #4d7c0f00;
}

.bg-lime-700\/10 {
  background-color: #4d7c0f1a;
}

.bg-lime-700\/100 {
  background-color: #4d7c0f;
}

.bg-lime-700\/20 {
  background-color: #4d7c0f33;
}

.bg-lime-700\/25 {
  background-color: #4d7c0f40;
}

.bg-lime-700\/30 {
  background-color: #4d7c0f4d;
}

.bg-lime-700\/40 {
  background-color: #4d7c0f66;
}

.bg-lime-700\/5 {
  background-color: #4d7c0f0d;
}

.bg-lime-700\/50 {
  background-color: #4d7c0f80;
}

.bg-lime-700\/60 {
  background-color: #4d7c0f99;
}

.bg-lime-700\/70 {
  background-color: #4d7c0fb3;
}

.bg-lime-700\/75 {
  background-color: #4d7c0fbf;
}

.bg-lime-700\/80 {
  background-color: #4d7c0fcc;
}

.bg-lime-700\/90 {
  background-color: #4d7c0fe6;
}

.bg-lime-700\/95 {
  background-color: #4d7c0ff2;
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.bg-lime-800\/0 {
  background-color: #3f621200;
}

.bg-lime-800\/10 {
  background-color: #3f62121a;
}

.bg-lime-800\/100 {
  background-color: #3f6212;
}

.bg-lime-800\/20 {
  background-color: #3f621233;
}

.bg-lime-800\/25 {
  background-color: #3f621240;
}

.bg-lime-800\/30 {
  background-color: #3f62124d;
}

.bg-lime-800\/40 {
  background-color: #3f621266;
}

.bg-lime-800\/5 {
  background-color: #3f62120d;
}

.bg-lime-800\/50 {
  background-color: #3f621280;
}

.bg-lime-800\/60 {
  background-color: #3f621299;
}

.bg-lime-800\/70 {
  background-color: #3f6212b3;
}

.bg-lime-800\/75 {
  background-color: #3f6212bf;
}

.bg-lime-800\/80 {
  background-color: #3f6212cc;
}

.bg-lime-800\/90 {
  background-color: #3f6212e6;
}

.bg-lime-800\/95 {
  background-color: #3f6212f2;
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

.bg-lime-900\/0 {
  background-color: #36531400;
}

.bg-lime-900\/10 {
  background-color: #3653141a;
}

.bg-lime-900\/100 {
  background-color: #365314;
}

.bg-lime-900\/20 {
  background-color: #36531433;
}

.bg-lime-900\/25 {
  background-color: #36531440;
}

.bg-lime-900\/30 {
  background-color: #3653144d;
}

.bg-lime-900\/40 {
  background-color: #36531466;
}

.bg-lime-900\/5 {
  background-color: #3653140d;
}

.bg-lime-900\/50 {
  background-color: #36531480;
}

.bg-lime-900\/60 {
  background-color: #36531499;
}

.bg-lime-900\/70 {
  background-color: #365314b3;
}

.bg-lime-900\/75 {
  background-color: #365314bf;
}

.bg-lime-900\/80 {
  background-color: #365314cc;
}

.bg-lime-900\/90 {
  background-color: #365314e6;
}

.bg-lime-900\/95 {
  background-color: #365314f2;
}

.bg-lime-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

.bg-lime-950\/0 {
  background-color: #1a2e0500;
}

.bg-lime-950\/10 {
  background-color: #1a2e051a;
}

.bg-lime-950\/100 {
  background-color: #1a2e05;
}

.bg-lime-950\/20 {
  background-color: #1a2e0533;
}

.bg-lime-950\/25 {
  background-color: #1a2e0540;
}

.bg-lime-950\/30 {
  background-color: #1a2e054d;
}

.bg-lime-950\/40 {
  background-color: #1a2e0566;
}

.bg-lime-950\/5 {
  background-color: #1a2e050d;
}

.bg-lime-950\/50 {
  background-color: #1a2e0580;
}

.bg-lime-950\/60 {
  background-color: #1a2e0599;
}

.bg-lime-950\/70 {
  background-color: #1a2e05b3;
}

.bg-lime-950\/75 {
  background-color: #1a2e05bf;
}

.bg-lime-950\/80 {
  background-color: #1a2e05cc;
}

.bg-lime-950\/90 {
  background-color: #1a2e05e6;
}

.bg-lime-950\/95 {
  background-color: #1a2e05f2;
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-neutral-100\/0 {
  background-color: #f5f5f500;
}

.bg-neutral-100\/10 {
  background-color: #f5f5f51a;
}

.bg-neutral-100\/100 {
  background-color: #f5f5f5;
}

.bg-neutral-100\/20 {
  background-color: #f5f5f533;
}

.bg-neutral-100\/25 {
  background-color: #f5f5f540;
}

.bg-neutral-100\/30 {
  background-color: #f5f5f54d;
}

.bg-neutral-100\/40 {
  background-color: #f5f5f566;
}

.bg-neutral-100\/5 {
  background-color: #f5f5f50d;
}

.bg-neutral-100\/50 {
  background-color: #f5f5f580;
}

.bg-neutral-100\/60 {
  background-color: #f5f5f599;
}

.bg-neutral-100\/70 {
  background-color: #f5f5f5b3;
}

.bg-neutral-100\/75 {
  background-color: #f5f5f5bf;
}

.bg-neutral-100\/80 {
  background-color: #f5f5f5cc;
}

.bg-neutral-100\/90 {
  background-color: #f5f5f5e6;
}

.bg-neutral-100\/95 {
  background-color: #f5f5f5f2;
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-neutral-200\/0 {
  background-color: #e5e5e500;
}

.bg-neutral-200\/10 {
  background-color: #e5e5e51a;
}

.bg-neutral-200\/100 {
  background-color: #e5e5e5;
}

.bg-neutral-200\/20 {
  background-color: #e5e5e533;
}

.bg-neutral-200\/25 {
  background-color: #e5e5e540;
}

.bg-neutral-200\/30 {
  background-color: #e5e5e54d;
}

.bg-neutral-200\/40 {
  background-color: #e5e5e566;
}

.bg-neutral-200\/5 {
  background-color: #e5e5e50d;
}

.bg-neutral-200\/50 {
  background-color: #e5e5e580;
}

.bg-neutral-200\/60 {
  background-color: #e5e5e599;
}

.bg-neutral-200\/70 {
  background-color: #e5e5e5b3;
}

.bg-neutral-200\/75 {
  background-color: #e5e5e5bf;
}

.bg-neutral-200\/80 {
  background-color: #e5e5e5cc;
}

.bg-neutral-200\/90 {
  background-color: #e5e5e5e6;
}

.bg-neutral-200\/95 {
  background-color: #e5e5e5f2;
}

.bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.bg-neutral-300\/0 {
  background-color: #d4d4d400;
}

.bg-neutral-300\/10 {
  background-color: #d4d4d41a;
}

.bg-neutral-300\/100 {
  background-color: #d4d4d4;
}

.bg-neutral-300\/20 {
  background-color: #d4d4d433;
}

.bg-neutral-300\/25 {
  background-color: #d4d4d440;
}

.bg-neutral-300\/30 {
  background-color: #d4d4d44d;
}

.bg-neutral-300\/40 {
  background-color: #d4d4d466;
}

.bg-neutral-300\/5 {
  background-color: #d4d4d40d;
}

.bg-neutral-300\/50 {
  background-color: #d4d4d480;
}

.bg-neutral-300\/60 {
  background-color: #d4d4d499;
}

.bg-neutral-300\/70 {
  background-color: #d4d4d4b3;
}

.bg-neutral-300\/75 {
  background-color: #d4d4d4bf;
}

.bg-neutral-300\/80 {
  background-color: #d4d4d4cc;
}

.bg-neutral-300\/90 {
  background-color: #d4d4d4e6;
}

.bg-neutral-300\/95 {
  background-color: #d4d4d4f2;
}

.bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}

.bg-neutral-400\/0 {
  background-color: #a3a3a300;
}

.bg-neutral-400\/10 {
  background-color: #a3a3a31a;
}

.bg-neutral-400\/100 {
  background-color: #a3a3a3;
}

.bg-neutral-400\/20 {
  background-color: #a3a3a333;
}

.bg-neutral-400\/25 {
  background-color: #a3a3a340;
}

.bg-neutral-400\/30 {
  background-color: #a3a3a34d;
}

.bg-neutral-400\/40 {
  background-color: #a3a3a366;
}

.bg-neutral-400\/5 {
  background-color: #a3a3a30d;
}

.bg-neutral-400\/50 {
  background-color: #a3a3a380;
}

.bg-neutral-400\/60 {
  background-color: #a3a3a399;
}

.bg-neutral-400\/70 {
  background-color: #a3a3a3b3;
}

.bg-neutral-400\/75 {
  background-color: #a3a3a3bf;
}

.bg-neutral-400\/80 {
  background-color: #a3a3a3cc;
}

.bg-neutral-400\/90 {
  background-color: #a3a3a3e6;
}

.bg-neutral-400\/95 {
  background-color: #a3a3a3f2;
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-neutral-50\/0 {
  background-color: #fafafa00;
}

.bg-neutral-50\/10 {
  background-color: #fafafa1a;
}

.bg-neutral-50\/100 {
  background-color: #fafafa;
}

.bg-neutral-50\/20 {
  background-color: #fafafa33;
}

.bg-neutral-50\/25 {
  background-color: #fafafa40;
}

.bg-neutral-50\/30 {
  background-color: #fafafa4d;
}

.bg-neutral-50\/40 {
  background-color: #fafafa66;
}

.bg-neutral-50\/5 {
  background-color: #fafafa0d;
}

.bg-neutral-50\/50 {
  background-color: #fafafa80;
}

.bg-neutral-50\/60 {
  background-color: #fafafa99;
}

.bg-neutral-50\/70 {
  background-color: #fafafab3;
}

.bg-neutral-50\/75 {
  background-color: #fafafabf;
}

.bg-neutral-50\/80 {
  background-color: #fafafacc;
}

.bg-neutral-50\/90 {
  background-color: #fafafae6;
}

.bg-neutral-50\/95 {
  background-color: #fafafaf2;
}

.bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

.bg-neutral-500\/0 {
  background-color: #73737300;
}

.bg-neutral-500\/10 {
  background-color: #7373731a;
}

.bg-neutral-500\/100 {
  background-color: #737373;
}

.bg-neutral-500\/20 {
  background-color: #73737333;
}

.bg-neutral-500\/25 {
  background-color: #73737340;
}

.bg-neutral-500\/30 {
  background-color: #7373734d;
}

.bg-neutral-500\/40 {
  background-color: #73737366;
}

.bg-neutral-500\/5 {
  background-color: #7373730d;
}

.bg-neutral-500\/50 {
  background-color: #73737380;
}

.bg-neutral-500\/60 {
  background-color: #73737399;
}

.bg-neutral-500\/70 {
  background-color: #737373b3;
}

.bg-neutral-500\/75 {
  background-color: #737373bf;
}

.bg-neutral-500\/80 {
  background-color: #737373cc;
}

.bg-neutral-500\/90 {
  background-color: #737373e6;
}

.bg-neutral-500\/95 {
  background-color: #737373f2;
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.bg-neutral-600\/0 {
  background-color: #52525200;
}

.bg-neutral-600\/10 {
  background-color: #5252521a;
}

.bg-neutral-600\/100 {
  background-color: #525252;
}

.bg-neutral-600\/20 {
  background-color: #52525233;
}

.bg-neutral-600\/25 {
  background-color: #52525240;
}

.bg-neutral-600\/30 {
  background-color: #5252524d;
}

.bg-neutral-600\/40 {
  background-color: #52525266;
}

.bg-neutral-600\/5 {
  background-color: #5252520d;
}

.bg-neutral-600\/50 {
  background-color: #52525280;
}

.bg-neutral-600\/60 {
  background-color: #52525299;
}

.bg-neutral-600\/70 {
  background-color: #525252b3;
}

.bg-neutral-600\/75 {
  background-color: #525252bf;
}

.bg-neutral-600\/80 {
  background-color: #525252cc;
}

.bg-neutral-600\/90 {
  background-color: #525252e6;
}

.bg-neutral-600\/95 {
  background-color: #525252f2;
}

.bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.bg-neutral-700\/0 {
  background-color: #40404000;
}

.bg-neutral-700\/10 {
  background-color: #4040401a;
}

.bg-neutral-700\/100 {
  background-color: #404040;
}

.bg-neutral-700\/20 {
  background-color: #40404033;
}

.bg-neutral-700\/25 {
  background-color: #40404040;
}

.bg-neutral-700\/30 {
  background-color: #4040404d;
}

.bg-neutral-700\/40 {
  background-color: #40404066;
}

.bg-neutral-700\/5 {
  background-color: #4040400d;
}

.bg-neutral-700\/50 {
  background-color: #40404080;
}

.bg-neutral-700\/60 {
  background-color: #40404099;
}

.bg-neutral-700\/70 {
  background-color: #404040b3;
}

.bg-neutral-700\/75 {
  background-color: #404040bf;
}

.bg-neutral-700\/80 {
  background-color: #404040cc;
}

.bg-neutral-700\/90 {
  background-color: #404040e6;
}

.bg-neutral-700\/95 {
  background-color: #404040f2;
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-neutral-800\/0 {
  background-color: #26262600;
}

.bg-neutral-800\/10 {
  background-color: #2626261a;
}

.bg-neutral-800\/100 {
  background-color: #262626;
}

.bg-neutral-800\/20 {
  background-color: #26262633;
}

.bg-neutral-800\/25 {
  background-color: #26262640;
}

.bg-neutral-800\/30 {
  background-color: #2626264d;
}

.bg-neutral-800\/40 {
  background-color: #26262666;
}

.bg-neutral-800\/5 {
  background-color: #2626260d;
}

.bg-neutral-800\/50 {
  background-color: #26262680;
}

.bg-neutral-800\/60 {
  background-color: #26262699;
}

.bg-neutral-800\/70 {
  background-color: #262626b3;
}

.bg-neutral-800\/75 {
  background-color: #262626bf;
}

.bg-neutral-800\/80 {
  background-color: #262626cc;
}

.bg-neutral-800\/90 {
  background-color: #262626e6;
}

.bg-neutral-800\/95 {
  background-color: #262626f2;
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-neutral-900\/0 {
  background-color: #17171700;
}

.bg-neutral-900\/10 {
  background-color: #1717171a;
}

.bg-neutral-900\/100 {
  background-color: #171717;
}

.bg-neutral-900\/20 {
  background-color: #17171733;
}

.bg-neutral-900\/25 {
  background-color: #17171740;
}

.bg-neutral-900\/30 {
  background-color: #1717174d;
}

.bg-neutral-900\/40 {
  background-color: #17171766;
}

.bg-neutral-900\/5 {
  background-color: #1717170d;
}

.bg-neutral-900\/50 {
  background-color: #17171780;
}

.bg-neutral-900\/60 {
  background-color: #17171799;
}

.bg-neutral-900\/70 {
  background-color: #171717b3;
}

.bg-neutral-900\/75 {
  background-color: #171717bf;
}

.bg-neutral-900\/80 {
  background-color: #171717cc;
}

.bg-neutral-900\/90 {
  background-color: #171717e6;
}

.bg-neutral-900\/95 {
  background-color: #171717f2;
}

.bg-neutral-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

.bg-neutral-950\/0 {
  background-color: #0a0a0a00;
}

.bg-neutral-950\/10 {
  background-color: #0a0a0a1a;
}

.bg-neutral-950\/100 {
  background-color: #0a0a0a;
}

.bg-neutral-950\/20 {
  background-color: #0a0a0a33;
}

.bg-neutral-950\/25 {
  background-color: #0a0a0a40;
}

.bg-neutral-950\/30 {
  background-color: #0a0a0a4d;
}

.bg-neutral-950\/40 {
  background-color: #0a0a0a66;
}

.bg-neutral-950\/5 {
  background-color: #0a0a0a0d;
}

.bg-neutral-950\/50 {
  background-color: #0a0a0a80;
}

.bg-neutral-950\/60 {
  background-color: #0a0a0a99;
}

.bg-neutral-950\/70 {
  background-color: #0a0a0ab3;
}

.bg-neutral-950\/75 {
  background-color: #0a0a0abf;
}

.bg-neutral-950\/80 {
  background-color: #0a0a0acc;
}

.bg-neutral-950\/90 {
  background-color: #0a0a0ae6;
}

.bg-neutral-950\/95 {
  background-color: #0a0a0af2;
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-orange-100\/0 {
  background-color: #ffedd500;
}

.bg-orange-100\/10 {
  background-color: #ffedd51a;
}

.bg-orange-100\/100 {
  background-color: #ffedd5;
}

.bg-orange-100\/20 {
  background-color: #ffedd533;
}

.bg-orange-100\/25 {
  background-color: #ffedd540;
}

.bg-orange-100\/30 {
  background-color: #ffedd54d;
}

.bg-orange-100\/40 {
  background-color: #ffedd566;
}

.bg-orange-100\/5 {
  background-color: #ffedd50d;
}

.bg-orange-100\/50 {
  background-color: #ffedd580;
}

.bg-orange-100\/60 {
  background-color: #ffedd599;
}

.bg-orange-100\/70 {
  background-color: #ffedd5b3;
}

.bg-orange-100\/75 {
  background-color: #ffedd5bf;
}

.bg-orange-100\/80 {
  background-color: #ffedd5cc;
}

.bg-orange-100\/90 {
  background-color: #ffedd5e6;
}

.bg-orange-100\/95 {
  background-color: #ffedd5f2;
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

.bg-orange-200\/0 {
  background-color: #fed7aa00;
}

.bg-orange-200\/10 {
  background-color: #fed7aa1a;
}

.bg-orange-200\/100 {
  background-color: #fed7aa;
}

.bg-orange-200\/20 {
  background-color: #fed7aa33;
}

.bg-orange-200\/25 {
  background-color: #fed7aa40;
}

.bg-orange-200\/30 {
  background-color: #fed7aa4d;
}

.bg-orange-200\/40 {
  background-color: #fed7aa66;
}

.bg-orange-200\/5 {
  background-color: #fed7aa0d;
}

.bg-orange-200\/50 {
  background-color: #fed7aa80;
}

.bg-orange-200\/60 {
  background-color: #fed7aa99;
}

.bg-orange-200\/70 {
  background-color: #fed7aab3;
}

.bg-orange-200\/75 {
  background-color: #fed7aabf;
}

.bg-orange-200\/80 {
  background-color: #fed7aacc;
}

.bg-orange-200\/90 {
  background-color: #fed7aae6;
}

.bg-orange-200\/95 {
  background-color: #fed7aaf2;
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}

.bg-orange-300\/0 {
  background-color: #fdba7400;
}

.bg-orange-300\/10 {
  background-color: #fdba741a;
}

.bg-orange-300\/100 {
  background-color: #fdba74;
}

.bg-orange-300\/20 {
  background-color: #fdba7433;
}

.bg-orange-300\/25 {
  background-color: #fdba7440;
}

.bg-orange-300\/30 {
  background-color: #fdba744d;
}

.bg-orange-300\/40 {
  background-color: #fdba7466;
}

.bg-orange-300\/5 {
  background-color: #fdba740d;
}

.bg-orange-300\/50 {
  background-color: #fdba7480;
}

.bg-orange-300\/60 {
  background-color: #fdba7499;
}

.bg-orange-300\/70 {
  background-color: #fdba74b3;
}

.bg-orange-300\/75 {
  background-color: #fdba74bf;
}

.bg-orange-300\/80 {
  background-color: #fdba74cc;
}

.bg-orange-300\/90 {
  background-color: #fdba74e6;
}

.bg-orange-300\/95 {
  background-color: #fdba74f2;
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.bg-orange-400\/0 {
  background-color: #fb923c00;
}

.bg-orange-400\/10 {
  background-color: #fb923c1a;
}

.bg-orange-400\/100 {
  background-color: #fb923c;
}

.bg-orange-400\/20 {
  background-color: #fb923c33;
}

.bg-orange-400\/25 {
  background-color: #fb923c40;
}

.bg-orange-400\/30 {
  background-color: #fb923c4d;
}

.bg-orange-400\/40 {
  background-color: #fb923c66;
}

.bg-orange-400\/5 {
  background-color: #fb923c0d;
}

.bg-orange-400\/50 {
  background-color: #fb923c80;
}

.bg-orange-400\/60 {
  background-color: #fb923c99;
}

.bg-orange-400\/70 {
  background-color: #fb923cb3;
}

.bg-orange-400\/75 {
  background-color: #fb923cbf;
}

.bg-orange-400\/80 {
  background-color: #fb923ccc;
}

.bg-orange-400\/90 {
  background-color: #fb923ce6;
}

.bg-orange-400\/95 {
  background-color: #fb923cf2;
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.bg-orange-50\/0 {
  background-color: #fff7ed00;
}

.bg-orange-50\/10 {
  background-color: #fff7ed1a;
}

.bg-orange-50\/100 {
  background-color: #fff7ed;
}

.bg-orange-50\/20 {
  background-color: #fff7ed33;
}

.bg-orange-50\/25 {
  background-color: #fff7ed40;
}

.bg-orange-50\/30 {
  background-color: #fff7ed4d;
}

.bg-orange-50\/40 {
  background-color: #fff7ed66;
}

.bg-orange-50\/5 {
  background-color: #fff7ed0d;
}

.bg-orange-50\/50 {
  background-color: #fff7ed80;
}

.bg-orange-50\/60 {
  background-color: #fff7ed99;
}

.bg-orange-50\/70 {
  background-color: #fff7edb3;
}

.bg-orange-50\/75 {
  background-color: #fff7edbf;
}

.bg-orange-50\/80 {
  background-color: #fff7edcc;
}

.bg-orange-50\/90 {
  background-color: #fff7ede6;
}

.bg-orange-50\/95 {
  background-color: #fff7edf2;
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-orange-500\/0 {
  background-color: #f9731600;
}

.bg-orange-500\/10 {
  background-color: #f973161a;
}

.bg-orange-500\/100 {
  background-color: #f97316;
}

.bg-orange-500\/20 {
  background-color: #f9731633;
}

.bg-orange-500\/25 {
  background-color: #f9731640;
}

.bg-orange-500\/30 {
  background-color: #f973164d;
}

.bg-orange-500\/40 {
  background-color: #f9731666;
}

.bg-orange-500\/5 {
  background-color: #f973160d;
}

.bg-orange-500\/50 {
  background-color: #f9731680;
}

.bg-orange-500\/60 {
  background-color: #f9731699;
}

.bg-orange-500\/70 {
  background-color: #f97316b3;
}

.bg-orange-500\/75 {
  background-color: #f97316bf;
}

.bg-orange-500\/80 {
  background-color: #f97316cc;
}

.bg-orange-500\/90 {
  background-color: #f97316e6;
}

.bg-orange-500\/95 {
  background-color: #f97316f2;
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-orange-600\/0 {
  background-color: #ea580c00;
}

.bg-orange-600\/10 {
  background-color: #ea580c1a;
}

.bg-orange-600\/100 {
  background-color: #ea580c;
}

.bg-orange-600\/20 {
  background-color: #ea580c33;
}

.bg-orange-600\/25 {
  background-color: #ea580c40;
}

.bg-orange-600\/30 {
  background-color: #ea580c4d;
}

.bg-orange-600\/40 {
  background-color: #ea580c66;
}

.bg-orange-600\/5 {
  background-color: #ea580c0d;
}

.bg-orange-600\/50 {
  background-color: #ea580c80;
}

.bg-orange-600\/60 {
  background-color: #ea580c99;
}

.bg-orange-600\/70 {
  background-color: #ea580cb3;
}

.bg-orange-600\/75 {
  background-color: #ea580cbf;
}

.bg-orange-600\/80 {
  background-color: #ea580ccc;
}

.bg-orange-600\/90 {
  background-color: #ea580ce6;
}

.bg-orange-600\/95 {
  background-color: #ea580cf2;
}

.bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.bg-orange-700\/0 {
  background-color: #c2410c00;
}

.bg-orange-700\/10 {
  background-color: #c2410c1a;
}

.bg-orange-700\/100 {
  background-color: #c2410c;
}

.bg-orange-700\/20 {
  background-color: #c2410c33;
}

.bg-orange-700\/25 {
  background-color: #c2410c40;
}

.bg-orange-700\/30 {
  background-color: #c2410c4d;
}

.bg-orange-700\/40 {
  background-color: #c2410c66;
}

.bg-orange-700\/5 {
  background-color: #c2410c0d;
}

.bg-orange-700\/50 {
  background-color: #c2410c80;
}

.bg-orange-700\/60 {
  background-color: #c2410c99;
}

.bg-orange-700\/70 {
  background-color: #c2410cb3;
}

.bg-orange-700\/75 {
  background-color: #c2410cbf;
}

.bg-orange-700\/80 {
  background-color: #c2410ccc;
}

.bg-orange-700\/90 {
  background-color: #c2410ce6;
}

.bg-orange-700\/95 {
  background-color: #c2410cf2;
}

.bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}

.bg-orange-800\/0 {
  background-color: #9a341200;
}

.bg-orange-800\/10 {
  background-color: #9a34121a;
}

.bg-orange-800\/100 {
  background-color: #9a3412;
}

.bg-orange-800\/20 {
  background-color: #9a341233;
}

.bg-orange-800\/25 {
  background-color: #9a341240;
}

.bg-orange-800\/30 {
  background-color: #9a34124d;
}

.bg-orange-800\/40 {
  background-color: #9a341266;
}

.bg-orange-800\/5 {
  background-color: #9a34120d;
}

.bg-orange-800\/50 {
  background-color: #9a341280;
}

.bg-orange-800\/60 {
  background-color: #9a341299;
}

.bg-orange-800\/70 {
  background-color: #9a3412b3;
}

.bg-orange-800\/75 {
  background-color: #9a3412bf;
}

.bg-orange-800\/80 {
  background-color: #9a3412cc;
}

.bg-orange-800\/90 {
  background-color: #9a3412e6;
}

.bg-orange-800\/95 {
  background-color: #9a3412f2;
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity));
}

.bg-orange-900\/0 {
  background-color: #7c2d1200;
}

.bg-orange-900\/10 {
  background-color: #7c2d121a;
}

.bg-orange-900\/100 {
  background-color: #7c2d12;
}

.bg-orange-900\/20 {
  background-color: #7c2d1233;
}

.bg-orange-900\/25 {
  background-color: #7c2d1240;
}

.bg-orange-900\/30 {
  background-color: #7c2d124d;
}

.bg-orange-900\/40 {
  background-color: #7c2d1266;
}

.bg-orange-900\/5 {
  background-color: #7c2d120d;
}

.bg-orange-900\/50 {
  background-color: #7c2d1280;
}

.bg-orange-900\/60 {
  background-color: #7c2d1299;
}

.bg-orange-900\/70 {
  background-color: #7c2d12b3;
}

.bg-orange-900\/75 {
  background-color: #7c2d12bf;
}

.bg-orange-900\/80 {
  background-color: #7c2d12cc;
}

.bg-orange-900\/90 {
  background-color: #7c2d12e6;
}

.bg-orange-900\/95 {
  background-color: #7c2d12f2;
}

.bg-orange-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 20 7 / var(--tw-bg-opacity));
}

.bg-orange-950\/0 {
  background-color: #43140700;
}

.bg-orange-950\/10 {
  background-color: #4314071a;
}

.bg-orange-950\/100 {
  background-color: #431407;
}

.bg-orange-950\/20 {
  background-color: #43140733;
}

.bg-orange-950\/25 {
  background-color: #43140740;
}

.bg-orange-950\/30 {
  background-color: #4314074d;
}

.bg-orange-950\/40 {
  background-color: #43140766;
}

.bg-orange-950\/5 {
  background-color: #4314070d;
}

.bg-orange-950\/50 {
  background-color: #43140780;
}

.bg-orange-950\/60 {
  background-color: #43140799;
}

.bg-orange-950\/70 {
  background-color: #431407b3;
}

.bg-orange-950\/75 {
  background-color: #431407bf;
}

.bg-orange-950\/80 {
  background-color: #431407cc;
}

.bg-orange-950\/90 {
  background-color: #431407e6;
}

.bg-orange-950\/95 {
  background-color: #431407f2;
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.bg-pink-100\/0 {
  background-color: #fce7f300;
}

.bg-pink-100\/10 {
  background-color: #fce7f31a;
}

.bg-pink-100\/100 {
  background-color: #fce7f3;
}

.bg-pink-100\/20 {
  background-color: #fce7f333;
}

.bg-pink-100\/25 {
  background-color: #fce7f340;
}

.bg-pink-100\/30 {
  background-color: #fce7f34d;
}

.bg-pink-100\/40 {
  background-color: #fce7f366;
}

.bg-pink-100\/5 {
  background-color: #fce7f30d;
}

.bg-pink-100\/50 {
  background-color: #fce7f380;
}

.bg-pink-100\/60 {
  background-color: #fce7f399;
}

.bg-pink-100\/70 {
  background-color: #fce7f3b3;
}

.bg-pink-100\/75 {
  background-color: #fce7f3bf;
}

.bg-pink-100\/80 {
  background-color: #fce7f3cc;
}

.bg-pink-100\/90 {
  background-color: #fce7f3e6;
}

.bg-pink-100\/95 {
  background-color: #fce7f3f2;
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.bg-pink-200\/0 {
  background-color: #fbcfe800;
}

.bg-pink-200\/10 {
  background-color: #fbcfe81a;
}

.bg-pink-200\/100 {
  background-color: #fbcfe8;
}

.bg-pink-200\/20 {
  background-color: #fbcfe833;
}

.bg-pink-200\/25 {
  background-color: #fbcfe840;
}

.bg-pink-200\/30 {
  background-color: #fbcfe84d;
}

.bg-pink-200\/40 {
  background-color: #fbcfe866;
}

.bg-pink-200\/5 {
  background-color: #fbcfe80d;
}

.bg-pink-200\/50 {
  background-color: #fbcfe880;
}

.bg-pink-200\/60 {
  background-color: #fbcfe899;
}

.bg-pink-200\/70 {
  background-color: #fbcfe8b3;
}

.bg-pink-200\/75 {
  background-color: #fbcfe8bf;
}

.bg-pink-200\/80 {
  background-color: #fbcfe8cc;
}

.bg-pink-200\/90 {
  background-color: #fbcfe8e6;
}

.bg-pink-200\/95 {
  background-color: #fbcfe8f2;
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

.bg-pink-300\/0 {
  background-color: #f9a8d400;
}

.bg-pink-300\/10 {
  background-color: #f9a8d41a;
}

.bg-pink-300\/100 {
  background-color: #f9a8d4;
}

.bg-pink-300\/20 {
  background-color: #f9a8d433;
}

.bg-pink-300\/25 {
  background-color: #f9a8d440;
}

.bg-pink-300\/30 {
  background-color: #f9a8d44d;
}

.bg-pink-300\/40 {
  background-color: #f9a8d466;
}

.bg-pink-300\/5 {
  background-color: #f9a8d40d;
}

.bg-pink-300\/50 {
  background-color: #f9a8d480;
}

.bg-pink-300\/60 {
  background-color: #f9a8d499;
}

.bg-pink-300\/70 {
  background-color: #f9a8d4b3;
}

.bg-pink-300\/75 {
  background-color: #f9a8d4bf;
}

.bg-pink-300\/80 {
  background-color: #f9a8d4cc;
}

.bg-pink-300\/90 {
  background-color: #f9a8d4e6;
}

.bg-pink-300\/95 {
  background-color: #f9a8d4f2;
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

.bg-pink-400\/0 {
  background-color: #f472b600;
}

.bg-pink-400\/10 {
  background-color: #f472b61a;
}

.bg-pink-400\/100 {
  background-color: #f472b6;
}

.bg-pink-400\/20 {
  background-color: #f472b633;
}

.bg-pink-400\/25 {
  background-color: #f472b640;
}

.bg-pink-400\/30 {
  background-color: #f472b64d;
}

.bg-pink-400\/40 {
  background-color: #f472b666;
}

.bg-pink-400\/5 {
  background-color: #f472b60d;
}

.bg-pink-400\/50 {
  background-color: #f472b680;
}

.bg-pink-400\/60 {
  background-color: #f472b699;
}

.bg-pink-400\/70 {
  background-color: #f472b6b3;
}

.bg-pink-400\/75 {
  background-color: #f472b6bf;
}

.bg-pink-400\/80 {
  background-color: #f472b6cc;
}

.bg-pink-400\/90 {
  background-color: #f472b6e6;
}

.bg-pink-400\/95 {
  background-color: #f472b6f2;
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

.bg-pink-50\/0 {
  background-color: #fdf2f800;
}

.bg-pink-50\/10 {
  background-color: #fdf2f81a;
}

.bg-pink-50\/100 {
  background-color: #fdf2f8;
}

.bg-pink-50\/20 {
  background-color: #fdf2f833;
}

.bg-pink-50\/25 {
  background-color: #fdf2f840;
}

.bg-pink-50\/30 {
  background-color: #fdf2f84d;
}

.bg-pink-50\/40 {
  background-color: #fdf2f866;
}

.bg-pink-50\/5 {
  background-color: #fdf2f80d;
}

.bg-pink-50\/50 {
  background-color: #fdf2f880;
}

.bg-pink-50\/60 {
  background-color: #fdf2f899;
}

.bg-pink-50\/70 {
  background-color: #fdf2f8b3;
}

.bg-pink-50\/75 {
  background-color: #fdf2f8bf;
}

.bg-pink-50\/80 {
  background-color: #fdf2f8cc;
}

.bg-pink-50\/90 {
  background-color: #fdf2f8e6;
}

.bg-pink-50\/95 {
  background-color: #fdf2f8f2;
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

.bg-pink-500\/0 {
  background-color: #ec489900;
}

.bg-pink-500\/10 {
  background-color: #ec48991a;
}

.bg-pink-500\/100 {
  background-color: #ec4899;
}

.bg-pink-500\/20 {
  background-color: #ec489933;
}

.bg-pink-500\/25 {
  background-color: #ec489940;
}

.bg-pink-500\/30 {
  background-color: #ec48994d;
}

.bg-pink-500\/40 {
  background-color: #ec489966;
}

.bg-pink-500\/5 {
  background-color: #ec48990d;
}

.bg-pink-500\/50 {
  background-color: #ec489980;
}

.bg-pink-500\/60 {
  background-color: #ec489999;
}

.bg-pink-500\/70 {
  background-color: #ec4899b3;
}

.bg-pink-500\/75 {
  background-color: #ec4899bf;
}

.bg-pink-500\/80 {
  background-color: #ec4899cc;
}

.bg-pink-500\/90 {
  background-color: #ec4899e6;
}

.bg-pink-500\/95 {
  background-color: #ec4899f2;
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-pink-600\/0 {
  background-color: #db277700;
}

.bg-pink-600\/10 {
  background-color: #db27771a;
}

.bg-pink-600\/100 {
  background-color: #db2777;
}

.bg-pink-600\/20 {
  background-color: #db277733;
}

.bg-pink-600\/25 {
  background-color: #db277740;
}

.bg-pink-600\/30 {
  background-color: #db27774d;
}

.bg-pink-600\/40 {
  background-color: #db277766;
}

.bg-pink-600\/5 {
  background-color: #db27770d;
}

.bg-pink-600\/50 {
  background-color: #db277780;
}

.bg-pink-600\/60 {
  background-color: #db277799;
}

.bg-pink-600\/70 {
  background-color: #db2777b3;
}

.bg-pink-600\/75 {
  background-color: #db2777bf;
}

.bg-pink-600\/80 {
  background-color: #db2777cc;
}

.bg-pink-600\/90 {
  background-color: #db2777e6;
}

.bg-pink-600\/95 {
  background-color: #db2777f2;
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.bg-pink-700\/0 {
  background-color: #be185d00;
}

.bg-pink-700\/10 {
  background-color: #be185d1a;
}

.bg-pink-700\/100 {
  background-color: #be185d;
}

.bg-pink-700\/20 {
  background-color: #be185d33;
}

.bg-pink-700\/25 {
  background-color: #be185d40;
}

.bg-pink-700\/30 {
  background-color: #be185d4d;
}

.bg-pink-700\/40 {
  background-color: #be185d66;
}

.bg-pink-700\/5 {
  background-color: #be185d0d;
}

.bg-pink-700\/50 {
  background-color: #be185d80;
}

.bg-pink-700\/60 {
  background-color: #be185d99;
}

.bg-pink-700\/70 {
  background-color: #be185db3;
}

.bg-pink-700\/75 {
  background-color: #be185dbf;
}

.bg-pink-700\/80 {
  background-color: #be185dcc;
}

.bg-pink-700\/90 {
  background-color: #be185de6;
}

.bg-pink-700\/95 {
  background-color: #be185df2;
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
}

.bg-pink-800\/0 {
  background-color: #9d174d00;
}

.bg-pink-800\/10 {
  background-color: #9d174d1a;
}

.bg-pink-800\/100 {
  background-color: #9d174d;
}

.bg-pink-800\/20 {
  background-color: #9d174d33;
}

.bg-pink-800\/25 {
  background-color: #9d174d40;
}

.bg-pink-800\/30 {
  background-color: #9d174d4d;
}

.bg-pink-800\/40 {
  background-color: #9d174d66;
}

.bg-pink-800\/5 {
  background-color: #9d174d0d;
}

.bg-pink-800\/50 {
  background-color: #9d174d80;
}

.bg-pink-800\/60 {
  background-color: #9d174d99;
}

.bg-pink-800\/70 {
  background-color: #9d174db3;
}

.bg-pink-800\/75 {
  background-color: #9d174dbf;
}

.bg-pink-800\/80 {
  background-color: #9d174dcc;
}

.bg-pink-800\/90 {
  background-color: #9d174de6;
}

.bg-pink-800\/95 {
  background-color: #9d174df2;
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

.bg-pink-900\/0 {
  background-color: #83184300;
}

.bg-pink-900\/10 {
  background-color: #8318431a;
}

.bg-pink-900\/100 {
  background-color: #831843;
}

.bg-pink-900\/20 {
  background-color: #83184333;
}

.bg-pink-900\/25 {
  background-color: #83184340;
}

.bg-pink-900\/30 {
  background-color: #8318434d;
}

.bg-pink-900\/40 {
  background-color: #83184366;
}

.bg-pink-900\/5 {
  background-color: #8318430d;
}

.bg-pink-900\/50 {
  background-color: #83184380;
}

.bg-pink-900\/60 {
  background-color: #83184399;
}

.bg-pink-900\/70 {
  background-color: #831843b3;
}

.bg-pink-900\/75 {
  background-color: #831843bf;
}

.bg-pink-900\/80 {
  background-color: #831843cc;
}

.bg-pink-900\/90 {
  background-color: #831843e6;
}

.bg-pink-900\/95 {
  background-color: #831843f2;
}

.bg-pink-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity));
}

.bg-pink-950\/0 {
  background-color: #50072400;
}

.bg-pink-950\/10 {
  background-color: #5007241a;
}

.bg-pink-950\/100 {
  background-color: #500724;
}

.bg-pink-950\/20 {
  background-color: #50072433;
}

.bg-pink-950\/25 {
  background-color: #50072440;
}

.bg-pink-950\/30 {
  background-color: #5007244d;
}

.bg-pink-950\/40 {
  background-color: #50072466;
}

.bg-pink-950\/5 {
  background-color: #5007240d;
}

.bg-pink-950\/50 {
  background-color: #50072480;
}

.bg-pink-950\/60 {
  background-color: #50072499;
}

.bg-pink-950\/70 {
  background-color: #500724b3;
}

.bg-pink-950\/75 {
  background-color: #500724bf;
}

.bg-pink-950\/80 {
  background-color: #500724cc;
}

.bg-pink-950\/90 {
  background-color: #500724e6;
}

.bg-pink-950\/95 {
  background-color: #500724f2;
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-purple-100\/0 {
  background-color: #f3e8ff00;
}

.bg-purple-100\/10 {
  background-color: #f3e8ff1a;
}

.bg-purple-100\/100 {
  background-color: #f3e8ff;
}

.bg-purple-100\/20 {
  background-color: #f3e8ff33;
}

.bg-purple-100\/25 {
  background-color: #f3e8ff40;
}

.bg-purple-100\/30 {
  background-color: #f3e8ff4d;
}

.bg-purple-100\/40 {
  background-color: #f3e8ff66;
}

.bg-purple-100\/5 {
  background-color: #f3e8ff0d;
}

.bg-purple-100\/50 {
  background-color: #f3e8ff80;
}

.bg-purple-100\/60 {
  background-color: #f3e8ff99;
}

.bg-purple-100\/70 {
  background-color: #f3e8ffb3;
}

.bg-purple-100\/75 {
  background-color: #f3e8ffbf;
}

.bg-purple-100\/80 {
  background-color: #f3e8ffcc;
}

.bg-purple-100\/90 {
  background-color: #f3e8ffe6;
}

.bg-purple-100\/95 {
  background-color: #f3e8fff2;
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.bg-purple-200\/0 {
  background-color: #e9d5ff00;
}

.bg-purple-200\/10 {
  background-color: #e9d5ff1a;
}

.bg-purple-200\/100 {
  background-color: #e9d5ff;
}

.bg-purple-200\/20 {
  background-color: #e9d5ff33;
}

.bg-purple-200\/25 {
  background-color: #e9d5ff40;
}

.bg-purple-200\/30 {
  background-color: #e9d5ff4d;
}

.bg-purple-200\/40 {
  background-color: #e9d5ff66;
}

.bg-purple-200\/5 {
  background-color: #e9d5ff0d;
}

.bg-purple-200\/50 {
  background-color: #e9d5ff80;
}

.bg-purple-200\/60 {
  background-color: #e9d5ff99;
}

.bg-purple-200\/70 {
  background-color: #e9d5ffb3;
}

.bg-purple-200\/75 {
  background-color: #e9d5ffbf;
}

.bg-purple-200\/80 {
  background-color: #e9d5ffcc;
}

.bg-purple-200\/90 {
  background-color: #e9d5ffe6;
}

.bg-purple-200\/95 {
  background-color: #e9d5fff2;
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.bg-purple-300\/0 {
  background-color: #d8b4fe00;
}

.bg-purple-300\/10 {
  background-color: #d8b4fe1a;
}

.bg-purple-300\/100 {
  background-color: #d8b4fe;
}

.bg-purple-300\/20 {
  background-color: #d8b4fe33;
}

.bg-purple-300\/25 {
  background-color: #d8b4fe40;
}

.bg-purple-300\/30 {
  background-color: #d8b4fe4d;
}

.bg-purple-300\/40 {
  background-color: #d8b4fe66;
}

.bg-purple-300\/5 {
  background-color: #d8b4fe0d;
}

.bg-purple-300\/50 {
  background-color: #d8b4fe80;
}

.bg-purple-300\/60 {
  background-color: #d8b4fe99;
}

.bg-purple-300\/70 {
  background-color: #d8b4feb3;
}

.bg-purple-300\/75 {
  background-color: #d8b4febf;
}

.bg-purple-300\/80 {
  background-color: #d8b4fecc;
}

.bg-purple-300\/90 {
  background-color: #d8b4fee6;
}

.bg-purple-300\/95 {
  background-color: #d8b4fef2;
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.bg-purple-400\/0 {
  background-color: #c084fc00;
}

.bg-purple-400\/10 {
  background-color: #c084fc1a;
}

.bg-purple-400\/100 {
  background-color: #c084fc;
}

.bg-purple-400\/20 {
  background-color: #c084fc33;
}

.bg-purple-400\/25 {
  background-color: #c084fc40;
}

.bg-purple-400\/30 {
  background-color: #c084fc4d;
}

.bg-purple-400\/40 {
  background-color: #c084fc66;
}

.bg-purple-400\/5 {
  background-color: #c084fc0d;
}

.bg-purple-400\/50 {
  background-color: #c084fc80;
}

.bg-purple-400\/60 {
  background-color: #c084fc99;
}

.bg-purple-400\/70 {
  background-color: #c084fcb3;
}

.bg-purple-400\/75 {
  background-color: #c084fcbf;
}

.bg-purple-400\/80 {
  background-color: #c084fccc;
}

.bg-purple-400\/90 {
  background-color: #c084fce6;
}

.bg-purple-400\/95 {
  background-color: #c084fcf2;
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.bg-purple-50\/0 {
  background-color: #faf5ff00;
}

.bg-purple-50\/10 {
  background-color: #faf5ff1a;
}

.bg-purple-50\/100 {
  background-color: #faf5ff;
}

.bg-purple-50\/20 {
  background-color: #faf5ff33;
}

.bg-purple-50\/25 {
  background-color: #faf5ff40;
}

.bg-purple-50\/30 {
  background-color: #faf5ff4d;
}

.bg-purple-50\/40 {
  background-color: #faf5ff66;
}

.bg-purple-50\/5 {
  background-color: #faf5ff0d;
}

.bg-purple-50\/50 {
  background-color: #faf5ff80;
}

.bg-purple-50\/60 {
  background-color: #faf5ff99;
}

.bg-purple-50\/70 {
  background-color: #faf5ffb3;
}

.bg-purple-50\/75 {
  background-color: #faf5ffbf;
}

.bg-purple-50\/80 {
  background-color: #faf5ffcc;
}

.bg-purple-50\/90 {
  background-color: #faf5ffe6;
}

.bg-purple-50\/95 {
  background-color: #faf5fff2;
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.bg-purple-500\/0 {
  background-color: #a855f700;
}

.bg-purple-500\/10 {
  background-color: #a855f71a;
}

.bg-purple-500\/100 {
  background-color: #a855f7;
}

.bg-purple-500\/20 {
  background-color: #a855f733;
}

.bg-purple-500\/25 {
  background-color: #a855f740;
}

.bg-purple-500\/30 {
  background-color: #a855f74d;
}

.bg-purple-500\/40 {
  background-color: #a855f766;
}

.bg-purple-500\/5 {
  background-color: #a855f70d;
}

.bg-purple-500\/50 {
  background-color: #a855f780;
}

.bg-purple-500\/60 {
  background-color: #a855f799;
}

.bg-purple-500\/70 {
  background-color: #a855f7b3;
}

.bg-purple-500\/75 {
  background-color: #a855f7bf;
}

.bg-purple-500\/80 {
  background-color: #a855f7cc;
}

.bg-purple-500\/90 {
  background-color: #a855f7e6;
}

.bg-purple-500\/95 {
  background-color: #a855f7f2;
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.bg-purple-600\/0 {
  background-color: #9333ea00;
}

.bg-purple-600\/10 {
  background-color: #9333ea1a;
}

.bg-purple-600\/100 {
  background-color: #9333ea;
}

.bg-purple-600\/20 {
  background-color: #9333ea33;
}

.bg-purple-600\/25 {
  background-color: #9333ea40;
}

.bg-purple-600\/30 {
  background-color: #9333ea4d;
}

.bg-purple-600\/40 {
  background-color: #9333ea66;
}

.bg-purple-600\/5 {
  background-color: #9333ea0d;
}

.bg-purple-600\/50 {
  background-color: #9333ea80;
}

.bg-purple-600\/60 {
  background-color: #9333ea99;
}

.bg-purple-600\/70 {
  background-color: #9333eab3;
}

.bg-purple-600\/75 {
  background-color: #9333eabf;
}

.bg-purple-600\/80 {
  background-color: #9333eacc;
}

.bg-purple-600\/90 {
  background-color: #9333eae6;
}

.bg-purple-600\/95 {
  background-color: #9333eaf2;
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-purple-700\/0 {
  background-color: #7e22ce00;
}

.bg-purple-700\/10 {
  background-color: #7e22ce1a;
}

.bg-purple-700\/100 {
  background-color: #7e22ce;
}

.bg-purple-700\/20 {
  background-color: #7e22ce33;
}

.bg-purple-700\/25 {
  background-color: #7e22ce40;
}

.bg-purple-700\/30 {
  background-color: #7e22ce4d;
}

.bg-purple-700\/40 {
  background-color: #7e22ce66;
}

.bg-purple-700\/5 {
  background-color: #7e22ce0d;
}

.bg-purple-700\/50 {
  background-color: #7e22ce80;
}

.bg-purple-700\/60 {
  background-color: #7e22ce99;
}

.bg-purple-700\/70 {
  background-color: #7e22ceb3;
}

.bg-purple-700\/75 {
  background-color: #7e22cebf;
}

.bg-purple-700\/80 {
  background-color: #7e22cecc;
}

.bg-purple-700\/90 {
  background-color: #7e22cee6;
}

.bg-purple-700\/95 {
  background-color: #7e22cef2;
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.bg-purple-800\/0 {
  background-color: #6b21a800;
}

.bg-purple-800\/10 {
  background-color: #6b21a81a;
}

.bg-purple-800\/100 {
  background-color: #6b21a8;
}

.bg-purple-800\/20 {
  background-color: #6b21a833;
}

.bg-purple-800\/25 {
  background-color: #6b21a840;
}

.bg-purple-800\/30 {
  background-color: #6b21a84d;
}

.bg-purple-800\/40 {
  background-color: #6b21a866;
}

.bg-purple-800\/5 {
  background-color: #6b21a80d;
}

.bg-purple-800\/50 {
  background-color: #6b21a880;
}

.bg-purple-800\/60 {
  background-color: #6b21a899;
}

.bg-purple-800\/70 {
  background-color: #6b21a8b3;
}

.bg-purple-800\/75 {
  background-color: #6b21a8bf;
}

.bg-purple-800\/80 {
  background-color: #6b21a8cc;
}

.bg-purple-800\/90 {
  background-color: #6b21a8e6;
}

.bg-purple-800\/95 {
  background-color: #6b21a8f2;
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.bg-purple-900\/0 {
  background-color: #581c8700;
}

.bg-purple-900\/10 {
  background-color: #581c871a;
}

.bg-purple-900\/100 {
  background-color: #581c87;
}

.bg-purple-900\/20 {
  background-color: #581c8733;
}

.bg-purple-900\/25 {
  background-color: #581c8740;
}

.bg-purple-900\/30 {
  background-color: #581c874d;
}

.bg-purple-900\/40 {
  background-color: #581c8766;
}

.bg-purple-900\/5 {
  background-color: #581c870d;
}

.bg-purple-900\/50 {
  background-color: #581c8780;
}

.bg-purple-900\/60 {
  background-color: #581c8799;
}

.bg-purple-900\/70 {
  background-color: #581c87b3;
}

.bg-purple-900\/75 {
  background-color: #581c87bf;
}

.bg-purple-900\/80 {
  background-color: #581c87cc;
}

.bg-purple-900\/90 {
  background-color: #581c87e6;
}

.bg-purple-900\/95 {
  background-color: #581c87f2;
}

.bg-purple-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity));
}

.bg-purple-950\/0 {
  background-color: #3b076400;
}

.bg-purple-950\/10 {
  background-color: #3b07641a;
}

.bg-purple-950\/100 {
  background-color: #3b0764;
}

.bg-purple-950\/20 {
  background-color: #3b076433;
}

.bg-purple-950\/25 {
  background-color: #3b076440;
}

.bg-purple-950\/30 {
  background-color: #3b07644d;
}

.bg-purple-950\/40 {
  background-color: #3b076466;
}

.bg-purple-950\/5 {
  background-color: #3b07640d;
}

.bg-purple-950\/50 {
  background-color: #3b076480;
}

.bg-purple-950\/60 {
  background-color: #3b076499;
}

.bg-purple-950\/70 {
  background-color: #3b0764b3;
}

.bg-purple-950\/75 {
  background-color: #3b0764bf;
}

.bg-purple-950\/80 {
  background-color: #3b0764cc;
}

.bg-purple-950\/90 {
  background-color: #3b0764e6;
}

.bg-purple-950\/95 {
  background-color: #3b0764f2;
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-100\/0 {
  background-color: #fee2e200;
}

.bg-red-100\/10 {
  background-color: #fee2e21a;
}

.bg-red-100\/100 {
  background-color: #fee2e2;
}

.bg-red-100\/20 {
  background-color: #fee2e233;
}

.bg-red-100\/25 {
  background-color: #fee2e240;
}

.bg-red-100\/30 {
  background-color: #fee2e24d;
}

.bg-red-100\/40 {
  background-color: #fee2e266;
}

.bg-red-100\/5 {
  background-color: #fee2e20d;
}

.bg-red-100\/50 {
  background-color: #fee2e280;
}

.bg-red-100\/60 {
  background-color: #fee2e299;
}

.bg-red-100\/70 {
  background-color: #fee2e2b3;
}

.bg-red-100\/75 {
  background-color: #fee2e2bf;
}

.bg-red-100\/80 {
  background-color: #fee2e2cc;
}

.bg-red-100\/90 {
  background-color: #fee2e2e6;
}

.bg-red-100\/95 {
  background-color: #fee2e2f2;
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.bg-red-200\/0 {
  background-color: #fecaca00;
}

.bg-red-200\/10 {
  background-color: #fecaca1a;
}

.bg-red-200\/100 {
  background-color: #fecaca;
}

.bg-red-200\/20 {
  background-color: #fecaca33;
}

.bg-red-200\/25 {
  background-color: #fecaca40;
}

.bg-red-200\/30 {
  background-color: #fecaca4d;
}

.bg-red-200\/40 {
  background-color: #fecaca66;
}

.bg-red-200\/5 {
  background-color: #fecaca0d;
}

.bg-red-200\/50 {
  background-color: #fecaca80;
}

.bg-red-200\/60 {
  background-color: #fecaca99;
}

.bg-red-200\/70 {
  background-color: #fecacab3;
}

.bg-red-200\/75 {
  background-color: #fecacabf;
}

.bg-red-200\/80 {
  background-color: #fecacacc;
}

.bg-red-200\/90 {
  background-color: #fecacae6;
}

.bg-red-200\/95 {
  background-color: #fecacaf2;
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

.bg-red-300\/0 {
  background-color: #fca5a500;
}

.bg-red-300\/10 {
  background-color: #fca5a51a;
}

.bg-red-300\/100 {
  background-color: #fca5a5;
}

.bg-red-300\/20 {
  background-color: #fca5a533;
}

.bg-red-300\/25 {
  background-color: #fca5a540;
}

.bg-red-300\/30 {
  background-color: #fca5a54d;
}

.bg-red-300\/40 {
  background-color: #fca5a566;
}

.bg-red-300\/5 {
  background-color: #fca5a50d;
}

.bg-red-300\/50 {
  background-color: #fca5a580;
}

.bg-red-300\/60 {
  background-color: #fca5a599;
}

.bg-red-300\/70 {
  background-color: #fca5a5b3;
}

.bg-red-300\/75 {
  background-color: #fca5a5bf;
}

.bg-red-300\/80 {
  background-color: #fca5a5cc;
}

.bg-red-300\/90 {
  background-color: #fca5a5e6;
}

.bg-red-300\/95 {
  background-color: #fca5a5f2;
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-red-400\/0 {
  background-color: #f8717100;
}

.bg-red-400\/10 {
  background-color: #f871711a;
}

.bg-red-400\/100 {
  background-color: #f87171;
}

.bg-red-400\/20 {
  background-color: #f8717133;
}

.bg-red-400\/25 {
  background-color: #f8717140;
}

.bg-red-400\/30 {
  background-color: #f871714d;
}

.bg-red-400\/40 {
  background-color: #f8717166;
}

.bg-red-400\/5 {
  background-color: #f871710d;
}

.bg-red-400\/50 {
  background-color: #f8717180;
}

.bg-red-400\/60 {
  background-color: #f8717199;
}

.bg-red-400\/70 {
  background-color: #f87171b3;
}

.bg-red-400\/75 {
  background-color: #f87171bf;
}

.bg-red-400\/80 {
  background-color: #f87171cc;
}

.bg-red-400\/90 {
  background-color: #f87171e6;
}

.bg-red-400\/95 {
  background-color: #f87171f2;
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-red-50\/0 {
  background-color: #fef2f200;
}

.bg-red-50\/10 {
  background-color: #fef2f21a;
}

.bg-red-50\/100 {
  background-color: #fef2f2;
}

.bg-red-50\/20 {
  background-color: #fef2f233;
}

.bg-red-50\/25 {
  background-color: #fef2f240;
}

.bg-red-50\/30 {
  background-color: #fef2f24d;
}

.bg-red-50\/40 {
  background-color: #fef2f266;
}

.bg-red-50\/5 {
  background-color: #fef2f20d;
}

.bg-red-50\/50 {
  background-color: #fef2f280;
}

.bg-red-50\/60 {
  background-color: #fef2f299;
}

.bg-red-50\/70 {
  background-color: #fef2f2b3;
}

.bg-red-50\/75 {
  background-color: #fef2f2bf;
}

.bg-red-50\/80 {
  background-color: #fef2f2cc;
}

.bg-red-50\/90 {
  background-color: #fef2f2e6;
}

.bg-red-50\/95 {
  background-color: #fef2f2f2;
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-red-500\/0 {
  background-color: #ef444400;
}

.bg-red-500\/10 {
  background-color: #ef44441a;
}

.bg-red-500\/100 {
  background-color: #ef4444;
}

.bg-red-500\/20 {
  background-color: #ef444433;
}

.bg-red-500\/25 {
  background-color: #ef444440;
}

.bg-red-500\/30 {
  background-color: #ef44444d;
}

.bg-red-500\/40 {
  background-color: #ef444466;
}

.bg-red-500\/5 {
  background-color: #ef44440d;
}

.bg-red-500\/50 {
  background-color: #ef444480;
}

.bg-red-500\/60 {
  background-color: #ef444499;
}

.bg-red-500\/70 {
  background-color: #ef4444b3;
}

.bg-red-500\/75 {
  background-color: #ef4444bf;
}

.bg-red-500\/80 {
  background-color: #ef4444cc;
}

.bg-red-500\/90 {
  background-color: #ef4444e6;
}

.bg-red-500\/95 {
  background-color: #ef4444f2;
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-red-600\/0 {
  background-color: #dc262600;
}

.bg-red-600\/10 {
  background-color: #dc26261a;
}

.bg-red-600\/100 {
  background-color: #dc2626;
}

.bg-red-600\/20 {
  background-color: #dc262633;
}

.bg-red-600\/25 {
  background-color: #dc262640;
}

.bg-red-600\/30 {
  background-color: #dc26264d;
}

.bg-red-600\/40 {
  background-color: #dc262666;
}

.bg-red-600\/5 {
  background-color: #dc26260d;
}

.bg-red-600\/50 {
  background-color: #dc262680;
}

.bg-red-600\/60 {
  background-color: #dc262699;
}

.bg-red-600\/70 {
  background-color: #dc2626b3;
}

.bg-red-600\/75 {
  background-color: #dc2626bf;
}

.bg-red-600\/80 {
  background-color: #dc2626cc;
}

.bg-red-600\/90 {
  background-color: #dc2626e6;
}

.bg-red-600\/95 {
  background-color: #dc2626f2;
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.bg-red-700\/0 {
  background-color: #b91c1c00;
}

.bg-red-700\/10 {
  background-color: #b91c1c1a;
}

.bg-red-700\/100 {
  background-color: #b91c1c;
}

.bg-red-700\/20 {
  background-color: #b91c1c33;
}

.bg-red-700\/25 {
  background-color: #b91c1c40;
}

.bg-red-700\/30 {
  background-color: #b91c1c4d;
}

.bg-red-700\/40 {
  background-color: #b91c1c66;
}

.bg-red-700\/5 {
  background-color: #b91c1c0d;
}

.bg-red-700\/50 {
  background-color: #b91c1c80;
}

.bg-red-700\/60 {
  background-color: #b91c1c99;
}

.bg-red-700\/70 {
  background-color: #b91c1cb3;
}

.bg-red-700\/75 {
  background-color: #b91c1cbf;
}

.bg-red-700\/80 {
  background-color: #b91c1ccc;
}

.bg-red-700\/90 {
  background-color: #b91c1ce6;
}

.bg-red-700\/95 {
  background-color: #b91c1cf2;
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.bg-red-800\/0 {
  background-color: #991b1b00;
}

.bg-red-800\/10 {
  background-color: #991b1b1a;
}

.bg-red-800\/100 {
  background-color: #991b1b;
}

.bg-red-800\/20 {
  background-color: #991b1b33;
}

.bg-red-800\/25 {
  background-color: #991b1b40;
}

.bg-red-800\/30 {
  background-color: #991b1b4d;
}

.bg-red-800\/40 {
  background-color: #991b1b66;
}

.bg-red-800\/5 {
  background-color: #991b1b0d;
}

.bg-red-800\/50 {
  background-color: #991b1b80;
}

.bg-red-800\/60 {
  background-color: #991b1b99;
}

.bg-red-800\/70 {
  background-color: #991b1bb3;
}

.bg-red-800\/75 {
  background-color: #991b1bbf;
}

.bg-red-800\/80 {
  background-color: #991b1bcc;
}

.bg-red-800\/90 {
  background-color: #991b1be6;
}

.bg-red-800\/95 {
  background-color: #991b1bf2;
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

.bg-red-900\/0 {
  background-color: #7f1d1d00;
}

.bg-red-900\/10 {
  background-color: #7f1d1d1a;
}

.bg-red-900\/100 {
  background-color: #7f1d1d;
}

.bg-red-900\/20 {
  background-color: #7f1d1d33;
}

.bg-red-900\/25 {
  background-color: #7f1d1d40;
}

.bg-red-900\/30 {
  background-color: #7f1d1d4d;
}

.bg-red-900\/40 {
  background-color: #7f1d1d66;
}

.bg-red-900\/5 {
  background-color: #7f1d1d0d;
}

.bg-red-900\/50 {
  background-color: #7f1d1d80;
}

.bg-red-900\/60 {
  background-color: #7f1d1d99;
}

.bg-red-900\/70 {
  background-color: #7f1d1db3;
}

.bg-red-900\/75 {
  background-color: #7f1d1dbf;
}

.bg-red-900\/80 {
  background-color: #7f1d1dcc;
}

.bg-red-900\/90 {
  background-color: #7f1d1de6;
}

.bg-red-900\/95 {
  background-color: #7f1d1df2;
}

.bg-red-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 10 10 / var(--tw-bg-opacity));
}

.bg-red-950\/0 {
  background-color: #450a0a00;
}

.bg-red-950\/10 {
  background-color: #450a0a1a;
}

.bg-red-950\/100 {
  background-color: #450a0a;
}

.bg-red-950\/20 {
  background-color: #450a0a33;
}

.bg-red-950\/25 {
  background-color: #450a0a40;
}

.bg-red-950\/30 {
  background-color: #450a0a4d;
}

.bg-red-950\/40 {
  background-color: #450a0a66;
}

.bg-red-950\/5 {
  background-color: #450a0a0d;
}

.bg-red-950\/50 {
  background-color: #450a0a80;
}

.bg-red-950\/60 {
  background-color: #450a0a99;
}

.bg-red-950\/70 {
  background-color: #450a0ab3;
}

.bg-red-950\/75 {
  background-color: #450a0abf;
}

.bg-red-950\/80 {
  background-color: #450a0acc;
}

.bg-red-950\/90 {
  background-color: #450a0ae6;
}

.bg-red-950\/95 {
  background-color: #450a0af2;
}

.bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

.bg-rose-100\/0 {
  background-color: #ffe4e600;
}

.bg-rose-100\/10 {
  background-color: #ffe4e61a;
}

.bg-rose-100\/100 {
  background-color: #ffe4e6;
}

.bg-rose-100\/20 {
  background-color: #ffe4e633;
}

.bg-rose-100\/25 {
  background-color: #ffe4e640;
}

.bg-rose-100\/30 {
  background-color: #ffe4e64d;
}

.bg-rose-100\/40 {
  background-color: #ffe4e666;
}

.bg-rose-100\/5 {
  background-color: #ffe4e60d;
}

.bg-rose-100\/50 {
  background-color: #ffe4e680;
}

.bg-rose-100\/60 {
  background-color: #ffe4e699;
}

.bg-rose-100\/70 {
  background-color: #ffe4e6b3;
}

.bg-rose-100\/75 {
  background-color: #ffe4e6bf;
}

.bg-rose-100\/80 {
  background-color: #ffe4e6cc;
}

.bg-rose-100\/90 {
  background-color: #ffe4e6e6;
}

.bg-rose-100\/95 {
  background-color: #ffe4e6f2;
}

.bg-rose-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

.bg-rose-200\/0 {
  background-color: #fecdd300;
}

.bg-rose-200\/10 {
  background-color: #fecdd31a;
}

.bg-rose-200\/100 {
  background-color: #fecdd3;
}

.bg-rose-200\/20 {
  background-color: #fecdd333;
}

.bg-rose-200\/25 {
  background-color: #fecdd340;
}

.bg-rose-200\/30 {
  background-color: #fecdd34d;
}

.bg-rose-200\/40 {
  background-color: #fecdd366;
}

.bg-rose-200\/5 {
  background-color: #fecdd30d;
}

.bg-rose-200\/50 {
  background-color: #fecdd380;
}

.bg-rose-200\/60 {
  background-color: #fecdd399;
}

.bg-rose-200\/70 {
  background-color: #fecdd3b3;
}

.bg-rose-200\/75 {
  background-color: #fecdd3bf;
}

.bg-rose-200\/80 {
  background-color: #fecdd3cc;
}

.bg-rose-200\/90 {
  background-color: #fecdd3e6;
}

.bg-rose-200\/95 {
  background-color: #fecdd3f2;
}

.bg-rose-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

.bg-rose-300\/0 {
  background-color: #fda4af00;
}

.bg-rose-300\/10 {
  background-color: #fda4af1a;
}

.bg-rose-300\/100 {
  background-color: #fda4af;
}

.bg-rose-300\/20 {
  background-color: #fda4af33;
}

.bg-rose-300\/25 {
  background-color: #fda4af40;
}

.bg-rose-300\/30 {
  background-color: #fda4af4d;
}

.bg-rose-300\/40 {
  background-color: #fda4af66;
}

.bg-rose-300\/5 {
  background-color: #fda4af0d;
}

.bg-rose-300\/50 {
  background-color: #fda4af80;
}

.bg-rose-300\/60 {
  background-color: #fda4af99;
}

.bg-rose-300\/70 {
  background-color: #fda4afb3;
}

.bg-rose-300\/75 {
  background-color: #fda4afbf;
}

.bg-rose-300\/80 {
  background-color: #fda4afcc;
}

.bg-rose-300\/90 {
  background-color: #fda4afe6;
}

.bg-rose-300\/95 {
  background-color: #fda4aff2;
}

.bg-rose-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

.bg-rose-400\/0 {
  background-color: #fb718500;
}

.bg-rose-400\/10 {
  background-color: #fb71851a;
}

.bg-rose-400\/100 {
  background-color: #fb7185;
}

.bg-rose-400\/20 {
  background-color: #fb718533;
}

.bg-rose-400\/25 {
  background-color: #fb718540;
}

.bg-rose-400\/30 {
  background-color: #fb71854d;
}

.bg-rose-400\/40 {
  background-color: #fb718566;
}

.bg-rose-400\/5 {
  background-color: #fb71850d;
}

.bg-rose-400\/50 {
  background-color: #fb718580;
}

.bg-rose-400\/60 {
  background-color: #fb718599;
}

.bg-rose-400\/70 {
  background-color: #fb7185b3;
}

.bg-rose-400\/75 {
  background-color: #fb7185bf;
}

.bg-rose-400\/80 {
  background-color: #fb7185cc;
}

.bg-rose-400\/90 {
  background-color: #fb7185e6;
}

.bg-rose-400\/95 {
  background-color: #fb7185f2;
}

.bg-rose-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

.bg-rose-50\/0 {
  background-color: #fff1f200;
}

.bg-rose-50\/10 {
  background-color: #fff1f21a;
}

.bg-rose-50\/100 {
  background-color: #fff1f2;
}

.bg-rose-50\/20 {
  background-color: #fff1f233;
}

.bg-rose-50\/25 {
  background-color: #fff1f240;
}

.bg-rose-50\/30 {
  background-color: #fff1f24d;
}

.bg-rose-50\/40 {
  background-color: #fff1f266;
}

.bg-rose-50\/5 {
  background-color: #fff1f20d;
}

.bg-rose-50\/50 {
  background-color: #fff1f280;
}

.bg-rose-50\/60 {
  background-color: #fff1f299;
}

.bg-rose-50\/70 {
  background-color: #fff1f2b3;
}

.bg-rose-50\/75 {
  background-color: #fff1f2bf;
}

.bg-rose-50\/80 {
  background-color: #fff1f2cc;
}

.bg-rose-50\/90 {
  background-color: #fff1f2e6;
}

.bg-rose-50\/95 {
  background-color: #fff1f2f2;
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.bg-rose-500\/0 {
  background-color: #f43f5e00;
}

.bg-rose-500\/10 {
  background-color: #f43f5e1a;
}

.bg-rose-500\/100 {
  background-color: #f43f5e;
}

.bg-rose-500\/20 {
  background-color: #f43f5e33;
}

.bg-rose-500\/25 {
  background-color: #f43f5e40;
}

.bg-rose-500\/30 {
  background-color: #f43f5e4d;
}

.bg-rose-500\/40 {
  background-color: #f43f5e66;
}

.bg-rose-500\/5 {
  background-color: #f43f5e0d;
}

.bg-rose-500\/50 {
  background-color: #f43f5e80;
}

.bg-rose-500\/60 {
  background-color: #f43f5e99;
}

.bg-rose-500\/70 {
  background-color: #f43f5eb3;
}

.bg-rose-500\/75 {
  background-color: #f43f5ebf;
}

.bg-rose-500\/80 {
  background-color: #f43f5ecc;
}

.bg-rose-500\/90 {
  background-color: #f43f5ee6;
}

.bg-rose-500\/95 {
  background-color: #f43f5ef2;
}

.bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.bg-rose-600\/0 {
  background-color: #e11d4800;
}

.bg-rose-600\/10 {
  background-color: #e11d481a;
}

.bg-rose-600\/100 {
  background-color: #e11d48;
}

.bg-rose-600\/20 {
  background-color: #e11d4833;
}

.bg-rose-600\/25 {
  background-color: #e11d4840;
}

.bg-rose-600\/30 {
  background-color: #e11d484d;
}

.bg-rose-600\/40 {
  background-color: #e11d4866;
}

.bg-rose-600\/5 {
  background-color: #e11d480d;
}

.bg-rose-600\/50 {
  background-color: #e11d4880;
}

.bg-rose-600\/60 {
  background-color: #e11d4899;
}

.bg-rose-600\/70 {
  background-color: #e11d48b3;
}

.bg-rose-600\/75 {
  background-color: #e11d48bf;
}

.bg-rose-600\/80 {
  background-color: #e11d48cc;
}

.bg-rose-600\/90 {
  background-color: #e11d48e6;
}

.bg-rose-600\/95 {
  background-color: #e11d48f2;
}

.bg-rose-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}

.bg-rose-700\/0 {
  background-color: #be123c00;
}

.bg-rose-700\/10 {
  background-color: #be123c1a;
}

.bg-rose-700\/100 {
  background-color: #be123c;
}

.bg-rose-700\/20 {
  background-color: #be123c33;
}

.bg-rose-700\/25 {
  background-color: #be123c40;
}

.bg-rose-700\/30 {
  background-color: #be123c4d;
}

.bg-rose-700\/40 {
  background-color: #be123c66;
}

.bg-rose-700\/5 {
  background-color: #be123c0d;
}

.bg-rose-700\/50 {
  background-color: #be123c80;
}

.bg-rose-700\/60 {
  background-color: #be123c99;
}

.bg-rose-700\/70 {
  background-color: #be123cb3;
}

.bg-rose-700\/75 {
  background-color: #be123cbf;
}

.bg-rose-700\/80 {
  background-color: #be123ccc;
}

.bg-rose-700\/90 {
  background-color: #be123ce6;
}

.bg-rose-700\/95 {
  background-color: #be123cf2;
}

.bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}

.bg-rose-800\/0 {
  background-color: #9f123900;
}

.bg-rose-800\/10 {
  background-color: #9f12391a;
}

.bg-rose-800\/100 {
  background-color: #9f1239;
}

.bg-rose-800\/20 {
  background-color: #9f123933;
}

.bg-rose-800\/25 {
  background-color: #9f123940;
}

.bg-rose-800\/30 {
  background-color: #9f12394d;
}

.bg-rose-800\/40 {
  background-color: #9f123966;
}

.bg-rose-800\/5 {
  background-color: #9f12390d;
}

.bg-rose-800\/50 {
  background-color: #9f123980;
}

.bg-rose-800\/60 {
  background-color: #9f123999;
}

.bg-rose-800\/70 {
  background-color: #9f1239b3;
}

.bg-rose-800\/75 {
  background-color: #9f1239bf;
}

.bg-rose-800\/80 {
  background-color: #9f1239cc;
}

.bg-rose-800\/90 {
  background-color: #9f1239e6;
}

.bg-rose-800\/95 {
  background-color: #9f1239f2;
}

.bg-rose-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}

.bg-rose-900\/0 {
  background-color: #88133700;
}

.bg-rose-900\/10 {
  background-color: #8813371a;
}

.bg-rose-900\/100 {
  background-color: #881337;
}

.bg-rose-900\/20 {
  background-color: #88133733;
}

.bg-rose-900\/25 {
  background-color: #88133740;
}

.bg-rose-900\/30 {
  background-color: #8813374d;
}

.bg-rose-900\/40 {
  background-color: #88133766;
}

.bg-rose-900\/5 {
  background-color: #8813370d;
}

.bg-rose-900\/50 {
  background-color: #88133780;
}

.bg-rose-900\/60 {
  background-color: #88133799;
}

.bg-rose-900\/70 {
  background-color: #881337b3;
}

.bg-rose-900\/75 {
  background-color: #881337bf;
}

.bg-rose-900\/80 {
  background-color: #881337cc;
}

.bg-rose-900\/90 {
  background-color: #881337e6;
}

.bg-rose-900\/95 {
  background-color: #881337f2;
}

.bg-rose-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity));
}

.bg-rose-950\/0 {
  background-color: #4c051900;
}

.bg-rose-950\/10 {
  background-color: #4c05191a;
}

.bg-rose-950\/100 {
  background-color: #4c0519;
}

.bg-rose-950\/20 {
  background-color: #4c051933;
}

.bg-rose-950\/25 {
  background-color: #4c051940;
}

.bg-rose-950\/30 {
  background-color: #4c05194d;
}

.bg-rose-950\/40 {
  background-color: #4c051966;
}

.bg-rose-950\/5 {
  background-color: #4c05190d;
}

.bg-rose-950\/50 {
  background-color: #4c051980;
}

.bg-rose-950\/60 {
  background-color: #4c051999;
}

.bg-rose-950\/70 {
  background-color: #4c0519b3;
}

.bg-rose-950\/75 {
  background-color: #4c0519bf;
}

.bg-rose-950\/80 {
  background-color: #4c0519cc;
}

.bg-rose-950\/90 {
  background-color: #4c0519e6;
}

.bg-rose-950\/95 {
  background-color: #4c0519f2;
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.bg-sky-100\/0 {
  background-color: #e0f2fe00;
}

.bg-sky-100\/10 {
  background-color: #e0f2fe1a;
}

.bg-sky-100\/100 {
  background-color: #e0f2fe;
}

.bg-sky-100\/20 {
  background-color: #e0f2fe33;
}

.bg-sky-100\/25 {
  background-color: #e0f2fe40;
}

.bg-sky-100\/30 {
  background-color: #e0f2fe4d;
}

.bg-sky-100\/40 {
  background-color: #e0f2fe66;
}

.bg-sky-100\/5 {
  background-color: #e0f2fe0d;
}

.bg-sky-100\/50 {
  background-color: #e0f2fe80;
}

.bg-sky-100\/60 {
  background-color: #e0f2fe99;
}

.bg-sky-100\/70 {
  background-color: #e0f2feb3;
}

.bg-sky-100\/75 {
  background-color: #e0f2febf;
}

.bg-sky-100\/80 {
  background-color: #e0f2fecc;
}

.bg-sky-100\/90 {
  background-color: #e0f2fee6;
}

.bg-sky-100\/95 {
  background-color: #e0f2fef2;
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

.bg-sky-200\/0 {
  background-color: #bae6fd00;
}

.bg-sky-200\/10 {
  background-color: #bae6fd1a;
}

.bg-sky-200\/100 {
  background-color: #bae6fd;
}

.bg-sky-200\/20 {
  background-color: #bae6fd33;
}

.bg-sky-200\/25 {
  background-color: #bae6fd40;
}

.bg-sky-200\/30 {
  background-color: #bae6fd4d;
}

.bg-sky-200\/40 {
  background-color: #bae6fd66;
}

.bg-sky-200\/5 {
  background-color: #bae6fd0d;
}

.bg-sky-200\/50 {
  background-color: #bae6fd80;
}

.bg-sky-200\/60 {
  background-color: #bae6fd99;
}

.bg-sky-200\/70 {
  background-color: #bae6fdb3;
}

.bg-sky-200\/75 {
  background-color: #bae6fdbf;
}

.bg-sky-200\/80 {
  background-color: #bae6fdcc;
}

.bg-sky-200\/90 {
  background-color: #bae6fde6;
}

.bg-sky-200\/95 {
  background-color: #bae6fdf2;
}

.bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}

.bg-sky-300\/0 {
  background-color: #7dd3fc00;
}

.bg-sky-300\/10 {
  background-color: #7dd3fc1a;
}

.bg-sky-300\/100 {
  background-color: #7dd3fc;
}

.bg-sky-300\/20 {
  background-color: #7dd3fc33;
}

.bg-sky-300\/25 {
  background-color: #7dd3fc40;
}

.bg-sky-300\/30 {
  background-color: #7dd3fc4d;
}

.bg-sky-300\/40 {
  background-color: #7dd3fc66;
}

.bg-sky-300\/5 {
  background-color: #7dd3fc0d;
}

.bg-sky-300\/50 {
  background-color: #7dd3fc80;
}

.bg-sky-300\/60 {
  background-color: #7dd3fc99;
}

.bg-sky-300\/70 {
  background-color: #7dd3fcb3;
}

.bg-sky-300\/75 {
  background-color: #7dd3fcbf;
}

.bg-sky-300\/80 {
  background-color: #7dd3fccc;
}

.bg-sky-300\/90 {
  background-color: #7dd3fce6;
}

.bg-sky-300\/95 {
  background-color: #7dd3fcf2;
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.bg-sky-400\/0 {
  background-color: #38bdf800;
}

.bg-sky-400\/10 {
  background-color: #38bdf81a;
}

.bg-sky-400\/100 {
  background-color: #38bdf8;
}

.bg-sky-400\/20 {
  background-color: #38bdf833;
}

.bg-sky-400\/25 {
  background-color: #38bdf840;
}

.bg-sky-400\/30 {
  background-color: #38bdf84d;
}

.bg-sky-400\/40 {
  background-color: #38bdf866;
}

.bg-sky-400\/5 {
  background-color: #38bdf80d;
}

.bg-sky-400\/50 {
  background-color: #38bdf880;
}

.bg-sky-400\/60 {
  background-color: #38bdf899;
}

.bg-sky-400\/70 {
  background-color: #38bdf8b3;
}

.bg-sky-400\/75 {
  background-color: #38bdf8bf;
}

.bg-sky-400\/80 {
  background-color: #38bdf8cc;
}

.bg-sky-400\/90 {
  background-color: #38bdf8e6;
}

.bg-sky-400\/95 {
  background-color: #38bdf8f2;
}

.bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.bg-sky-50\/0 {
  background-color: #f0f9ff00;
}

.bg-sky-50\/10 {
  background-color: #f0f9ff1a;
}

.bg-sky-50\/100 {
  background-color: #f0f9ff;
}

.bg-sky-50\/20 {
  background-color: #f0f9ff33;
}

.bg-sky-50\/25 {
  background-color: #f0f9ff40;
}

.bg-sky-50\/30 {
  background-color: #f0f9ff4d;
}

.bg-sky-50\/40 {
  background-color: #f0f9ff66;
}

.bg-sky-50\/5 {
  background-color: #f0f9ff0d;
}

.bg-sky-50\/50 {
  background-color: #f0f9ff80;
}

.bg-sky-50\/60 {
  background-color: #f0f9ff99;
}

.bg-sky-50\/70 {
  background-color: #f0f9ffb3;
}

.bg-sky-50\/75 {
  background-color: #f0f9ffbf;
}

.bg-sky-50\/80 {
  background-color: #f0f9ffcc;
}

.bg-sky-50\/90 {
  background-color: #f0f9ffe6;
}

.bg-sky-50\/95 {
  background-color: #f0f9fff2;
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.bg-sky-500\/0 {
  background-color: #0ea5e900;
}

.bg-sky-500\/10 {
  background-color: #0ea5e91a;
}

.bg-sky-500\/100 {
  background-color: #0ea5e9;
}

.bg-sky-500\/20 {
  background-color: #0ea5e933;
}

.bg-sky-500\/25 {
  background-color: #0ea5e940;
}

.bg-sky-500\/30 {
  background-color: #0ea5e94d;
}

.bg-sky-500\/40 {
  background-color: #0ea5e966;
}

.bg-sky-500\/5 {
  background-color: #0ea5e90d;
}

.bg-sky-500\/50 {
  background-color: #0ea5e980;
}

.bg-sky-500\/60 {
  background-color: #0ea5e999;
}

.bg-sky-500\/70 {
  background-color: #0ea5e9b3;
}

.bg-sky-500\/75 {
  background-color: #0ea5e9bf;
}

.bg-sky-500\/80 {
  background-color: #0ea5e9cc;
}

.bg-sky-500\/90 {
  background-color: #0ea5e9e6;
}

.bg-sky-500\/95 {
  background-color: #0ea5e9f2;
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.bg-sky-600\/0 {
  background-color: #0284c700;
}

.bg-sky-600\/10 {
  background-color: #0284c71a;
}

.bg-sky-600\/100 {
  background-color: #0284c7;
}

.bg-sky-600\/20 {
  background-color: #0284c733;
}

.bg-sky-600\/25 {
  background-color: #0284c740;
}

.bg-sky-600\/30 {
  background-color: #0284c74d;
}

.bg-sky-600\/40 {
  background-color: #0284c766;
}

.bg-sky-600\/5 {
  background-color: #0284c70d;
}

.bg-sky-600\/50 {
  background-color: #0284c780;
}

.bg-sky-600\/60 {
  background-color: #0284c799;
}

.bg-sky-600\/70 {
  background-color: #0284c7b3;
}

.bg-sky-600\/75 {
  background-color: #0284c7bf;
}

.bg-sky-600\/80 {
  background-color: #0284c7cc;
}

.bg-sky-600\/90 {
  background-color: #0284c7e6;
}

.bg-sky-600\/95 {
  background-color: #0284c7f2;
}

.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.bg-sky-700\/0 {
  background-color: #0369a100;
}

.bg-sky-700\/10 {
  background-color: #0369a11a;
}

.bg-sky-700\/100 {
  background-color: #0369a1;
}

.bg-sky-700\/20 {
  background-color: #0369a133;
}

.bg-sky-700\/25 {
  background-color: #0369a140;
}

.bg-sky-700\/30 {
  background-color: #0369a14d;
}

.bg-sky-700\/40 {
  background-color: #0369a166;
}

.bg-sky-700\/5 {
  background-color: #0369a10d;
}

.bg-sky-700\/50 {
  background-color: #0369a180;
}

.bg-sky-700\/60 {
  background-color: #0369a199;
}

.bg-sky-700\/70 {
  background-color: #0369a1b3;
}

.bg-sky-700\/75 {
  background-color: #0369a1bf;
}

.bg-sky-700\/80 {
  background-color: #0369a1cc;
}

.bg-sky-700\/90 {
  background-color: #0369a1e6;
}

.bg-sky-700\/95 {
  background-color: #0369a1f2;
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.bg-sky-800\/0 {
  background-color: #07598500;
}

.bg-sky-800\/10 {
  background-color: #0759851a;
}

.bg-sky-800\/100 {
  background-color: #075985;
}

.bg-sky-800\/20 {
  background-color: #07598533;
}

.bg-sky-800\/25 {
  background-color: #07598540;
}

.bg-sky-800\/30 {
  background-color: #0759854d;
}

.bg-sky-800\/40 {
  background-color: #07598566;
}

.bg-sky-800\/5 {
  background-color: #0759850d;
}

.bg-sky-800\/50 {
  background-color: #07598580;
}

.bg-sky-800\/60 {
  background-color: #07598599;
}

.bg-sky-800\/70 {
  background-color: #075985b3;
}

.bg-sky-800\/75 {
  background-color: #075985bf;
}

.bg-sky-800\/80 {
  background-color: #075985cc;
}

.bg-sky-800\/90 {
  background-color: #075985e6;
}

.bg-sky-800\/95 {
  background-color: #075985f2;
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.bg-sky-900\/0 {
  background-color: #0c4a6e00;
}

.bg-sky-900\/10 {
  background-color: #0c4a6e1a;
}

.bg-sky-900\/100 {
  background-color: #0c4a6e;
}

.bg-sky-900\/20 {
  background-color: #0c4a6e33;
}

.bg-sky-900\/25 {
  background-color: #0c4a6e40;
}

.bg-sky-900\/30 {
  background-color: #0c4a6e4d;
}

.bg-sky-900\/40 {
  background-color: #0c4a6e66;
}

.bg-sky-900\/5 {
  background-color: #0c4a6e0d;
}

.bg-sky-900\/50 {
  background-color: #0c4a6e80;
}

.bg-sky-900\/60 {
  background-color: #0c4a6e99;
}

.bg-sky-900\/70 {
  background-color: #0c4a6eb3;
}

.bg-sky-900\/75 {
  background-color: #0c4a6ebf;
}

.bg-sky-900\/80 {
  background-color: #0c4a6ecc;
}

.bg-sky-900\/90 {
  background-color: #0c4a6ee6;
}

.bg-sky-900\/95 {
  background-color: #0c4a6ef2;
}

.bg-sky-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity));
}

.bg-sky-950\/0 {
  background-color: #082f4900;
}

.bg-sky-950\/10 {
  background-color: #082f491a;
}

.bg-sky-950\/100 {
  background-color: #082f49;
}

.bg-sky-950\/20 {
  background-color: #082f4933;
}

.bg-sky-950\/25 {
  background-color: #082f4940;
}

.bg-sky-950\/30 {
  background-color: #082f494d;
}

.bg-sky-950\/40 {
  background-color: #082f4966;
}

.bg-sky-950\/5 {
  background-color: #082f490d;
}

.bg-sky-950\/50 {
  background-color: #082f4980;
}

.bg-sky-950\/60 {
  background-color: #082f4999;
}

.bg-sky-950\/70 {
  background-color: #082f49b3;
}

.bg-sky-950\/75 {
  background-color: #082f49bf;
}

.bg-sky-950\/80 {
  background-color: #082f49cc;
}

.bg-sky-950\/90 {
  background-color: #082f49e6;
}

.bg-sky-950\/95 {
  background-color: #082f49f2;
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-100\/0 {
  background-color: #f1f5f900;
}

.bg-slate-100\/10 {
  background-color: #f1f5f91a;
}

.bg-slate-100\/100 {
  background-color: #f1f5f9;
}

.bg-slate-100\/20 {
  background-color: #f1f5f933;
}

.bg-slate-100\/25 {
  background-color: #f1f5f940;
}

.bg-slate-100\/30 {
  background-color: #f1f5f94d;
}

.bg-slate-100\/40 {
  background-color: #f1f5f966;
}

.bg-slate-100\/5 {
  background-color: #f1f5f90d;
}

.bg-slate-100\/50 {
  background-color: #f1f5f980;
}

.bg-slate-100\/60 {
  background-color: #f1f5f999;
}

.bg-slate-100\/70 {
  background-color: #f1f5f9b3;
}

.bg-slate-100\/75 {
  background-color: #f1f5f9bf;
}

.bg-slate-100\/80 {
  background-color: #f1f5f9cc;
}

.bg-slate-100\/90 {
  background-color: #f1f5f9e6;
}

.bg-slate-100\/95 {
  background-color: #f1f5f9f2;
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-200\/0 {
  background-color: #e2e8f000;
}

.bg-slate-200\/10 {
  background-color: #e2e8f01a;
}

.bg-slate-200\/100 {
  background-color: #e2e8f0;
}

.bg-slate-200\/20 {
  background-color: #e2e8f033;
}

.bg-slate-200\/25 {
  background-color: #e2e8f040;
}

.bg-slate-200\/30 {
  background-color: #e2e8f04d;
}

.bg-slate-200\/40 {
  background-color: #e2e8f066;
}

.bg-slate-200\/5 {
  background-color: #e2e8f00d;
}

.bg-slate-200\/50 {
  background-color: #e2e8f080;
}

.bg-slate-200\/60 {
  background-color: #e2e8f099;
}

.bg-slate-200\/70 {
  background-color: #e2e8f0b3;
}

.bg-slate-200\/75 {
  background-color: #e2e8f0bf;
}

.bg-slate-200\/80 {
  background-color: #e2e8f0cc;
}

.bg-slate-200\/90 {
  background-color: #e2e8f0e6;
}

.bg-slate-200\/95 {
  background-color: #e2e8f0f2;
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-slate-300\/0 {
  background-color: #cbd5e100;
}

.bg-slate-300\/10 {
  background-color: #cbd5e11a;
}

.bg-slate-300\/100 {
  background-color: #cbd5e1;
}

.bg-slate-300\/20 {
  background-color: #cbd5e133;
}

.bg-slate-300\/25 {
  background-color: #cbd5e140;
}

.bg-slate-300\/30 {
  background-color: #cbd5e14d;
}

.bg-slate-300\/40 {
  background-color: #cbd5e166;
}

.bg-slate-300\/5 {
  background-color: #cbd5e10d;
}

.bg-slate-300\/50 {
  background-color: #cbd5e180;
}

.bg-slate-300\/60 {
  background-color: #cbd5e199;
}

.bg-slate-300\/70 {
  background-color: #cbd5e1b3;
}

.bg-slate-300\/75 {
  background-color: #cbd5e1bf;
}

.bg-slate-300\/80 {
  background-color: #cbd5e1cc;
}

.bg-slate-300\/90 {
  background-color: #cbd5e1e6;
}

.bg-slate-300\/95 {
  background-color: #cbd5e1f2;
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-slate-400\/0 {
  background-color: #94a3b800;
}

.bg-slate-400\/10 {
  background-color: #94a3b81a;
}

.bg-slate-400\/100 {
  background-color: #94a3b8;
}

.bg-slate-400\/20 {
  background-color: #94a3b833;
}

.bg-slate-400\/25 {
  background-color: #94a3b840;
}

.bg-slate-400\/30 {
  background-color: #94a3b84d;
}

.bg-slate-400\/40 {
  background-color: #94a3b866;
}

.bg-slate-400\/5 {
  background-color: #94a3b80d;
}

.bg-slate-400\/50 {
  background-color: #94a3b880;
}

.bg-slate-400\/60 {
  background-color: #94a3b899;
}

.bg-slate-400\/70 {
  background-color: #94a3b8b3;
}

.bg-slate-400\/75 {
  background-color: #94a3b8bf;
}

.bg-slate-400\/80 {
  background-color: #94a3b8cc;
}

.bg-slate-400\/90 {
  background-color: #94a3b8e6;
}

.bg-slate-400\/95 {
  background-color: #94a3b8f2;
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-slate-50\/0 {
  background-color: #f8fafc00;
}

.bg-slate-50\/10 {
  background-color: #f8fafc1a;
}

.bg-slate-50\/100 {
  background-color: #f8fafc;
}

.bg-slate-50\/20 {
  background-color: #f8fafc33;
}

.bg-slate-50\/25 {
  background-color: #f8fafc40;
}

.bg-slate-50\/30 {
  background-color: #f8fafc4d;
}

.bg-slate-50\/40 {
  background-color: #f8fafc66;
}

.bg-slate-50\/5 {
  background-color: #f8fafc0d;
}

.bg-slate-50\/50 {
  background-color: #f8fafc80;
}

.bg-slate-50\/60 {
  background-color: #f8fafc99;
}

.bg-slate-50\/70 {
  background-color: #f8fafcb3;
}

.bg-slate-50\/75 {
  background-color: #f8fafcbf;
}

.bg-slate-50\/80 {
  background-color: #f8fafccc;
}

.bg-slate-50\/90 {
  background-color: #f8fafce6;
}

.bg-slate-50\/95 {
  background-color: #f8fafcf2;
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.bg-slate-500\/0 {
  background-color: #64748b00;
}

.bg-slate-500\/10 {
  background-color: #64748b1a;
}

.bg-slate-500\/100 {
  background-color: #64748b;
}

.bg-slate-500\/20 {
  background-color: #64748b33;
}

.bg-slate-500\/25 {
  background-color: #64748b40;
}

.bg-slate-500\/30 {
  background-color: #64748b4d;
}

.bg-slate-500\/40 {
  background-color: #64748b66;
}

.bg-slate-500\/5 {
  background-color: #64748b0d;
}

.bg-slate-500\/50 {
  background-color: #64748b80;
}

.bg-slate-500\/60 {
  background-color: #64748b99;
}

.bg-slate-500\/70 {
  background-color: #64748bb3;
}

.bg-slate-500\/75 {
  background-color: #64748bbf;
}

.bg-slate-500\/80 {
  background-color: #64748bcc;
}

.bg-slate-500\/90 {
  background-color: #64748be6;
}

.bg-slate-500\/95 {
  background-color: #64748bf2;
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-slate-600\/0 {
  background-color: #47556900;
}

.bg-slate-600\/10 {
  background-color: #4755691a;
}

.bg-slate-600\/100 {
  background-color: #475569;
}

.bg-slate-600\/20 {
  background-color: #47556933;
}

.bg-slate-600\/25 {
  background-color: #47556940;
}

.bg-slate-600\/30 {
  background-color: #4755694d;
}

.bg-slate-600\/40 {
  background-color: #47556966;
}

.bg-slate-600\/5 {
  background-color: #4755690d;
}

.bg-slate-600\/50 {
  background-color: #47556980;
}

.bg-slate-600\/60 {
  background-color: #47556999;
}

.bg-slate-600\/70 {
  background-color: #475569b3;
}

.bg-slate-600\/75 {
  background-color: #475569bf;
}

.bg-slate-600\/80 {
  background-color: #475569cc;
}

.bg-slate-600\/90 {
  background-color: #475569e6;
}

.bg-slate-600\/95 {
  background-color: #475569f2;
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-slate-700\/0 {
  background-color: #33415500;
}

.bg-slate-700\/10 {
  background-color: #3341551a;
}

.bg-slate-700\/100 {
  background-color: #334155;
}

.bg-slate-700\/20 {
  background-color: #33415533;
}

.bg-slate-700\/25 {
  background-color: #33415540;
}

.bg-slate-700\/30 {
  background-color: #3341554d;
}

.bg-slate-700\/40 {
  background-color: #33415566;
}

.bg-slate-700\/5 {
  background-color: #3341550d;
}

.bg-slate-700\/50 {
  background-color: #33415580;
}

.bg-slate-700\/60 {
  background-color: #33415599;
}

.bg-slate-700\/70 {
  background-color: #334155b3;
}

.bg-slate-700\/75 {
  background-color: #334155bf;
}

.bg-slate-700\/80 {
  background-color: #334155cc;
}

.bg-slate-700\/90 {
  background-color: #334155e6;
}

.bg-slate-700\/95 {
  background-color: #334155f2;
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-800\/0 {
  background-color: #1e293b00;
}

.bg-slate-800\/10 {
  background-color: #1e293b1a;
}

.bg-slate-800\/100 {
  background-color: #1e293b;
}

.bg-slate-800\/20 {
  background-color: #1e293b33;
}

.bg-slate-800\/25 {
  background-color: #1e293b40;
}

.bg-slate-800\/30 {
  background-color: #1e293b4d;
}

.bg-slate-800\/40 {
  background-color: #1e293b66;
}

.bg-slate-800\/5 {
  background-color: #1e293b0d;
}

.bg-slate-800\/50 {
  background-color: #1e293b80;
}

.bg-slate-800\/60 {
  background-color: #1e293b99;
}

.bg-slate-800\/70 {
  background-color: #1e293bb3;
}

.bg-slate-800\/75 {
  background-color: #1e293bbf;
}

.bg-slate-800\/80 {
  background-color: #1e293bcc;
}

.bg-slate-800\/90 {
  background-color: #1e293be6;
}

.bg-slate-800\/95 {
  background-color: #1e293bf2;
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-slate-900\/0 {
  background-color: #0f172a00;
}

.bg-slate-900\/10 {
  background-color: #0f172a1a;
}

.bg-slate-900\/100 {
  background-color: #0f172a;
}

.bg-slate-900\/20 {
  background-color: #0f172a33;
}

.bg-slate-900\/25 {
  background-color: #0f172a40;
}

.bg-slate-900\/30 {
  background-color: #0f172a4d;
}

.bg-slate-900\/40 {
  background-color: #0f172a66;
}

.bg-slate-900\/5 {
  background-color: #0f172a0d;
}

.bg-slate-900\/50 {
  background-color: #0f172a80;
}

.bg-slate-900\/60 {
  background-color: #0f172a99;
}

.bg-slate-900\/70 {
  background-color: #0f172ab3;
}

.bg-slate-900\/75 {
  background-color: #0f172abf;
}

.bg-slate-900\/80 {
  background-color: #0f172acc;
}

.bg-slate-900\/90 {
  background-color: #0f172ae6;
}

.bg-slate-900\/95 {
  background-color: #0f172af2;
}

.bg-slate-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.bg-slate-950\/0 {
  background-color: #02061700;
}

.bg-slate-950\/10 {
  background-color: #0206171a;
}

.bg-slate-950\/100 {
  background-color: #020617;
}

.bg-slate-950\/20 {
  background-color: #02061733;
}

.bg-slate-950\/25 {
  background-color: #02061740;
}

.bg-slate-950\/30 {
  background-color: #0206174d;
}

.bg-slate-950\/40 {
  background-color: #02061766;
}

.bg-slate-950\/5 {
  background-color: #0206170d;
}

.bg-slate-950\/50 {
  background-color: #02061780;
}

.bg-slate-950\/60 {
  background-color: #02061799;
}

.bg-slate-950\/70 {
  background-color: #020617b3;
}

.bg-slate-950\/75 {
  background-color: #020617bf;
}

.bg-slate-950\/80 {
  background-color: #020617cc;
}

.bg-slate-950\/90 {
  background-color: #020617e6;
}

.bg-slate-950\/95 {
  background-color: #020617f2;
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

.bg-stone-100\/0 {
  background-color: #f5f5f400;
}

.bg-stone-100\/10 {
  background-color: #f5f5f41a;
}

.bg-stone-100\/100 {
  background-color: #f5f5f4;
}

.bg-stone-100\/20 {
  background-color: #f5f5f433;
}

.bg-stone-100\/25 {
  background-color: #f5f5f440;
}

.bg-stone-100\/30 {
  background-color: #f5f5f44d;
}

.bg-stone-100\/40 {
  background-color: #f5f5f466;
}

.bg-stone-100\/5 {
  background-color: #f5f5f40d;
}

.bg-stone-100\/50 {
  background-color: #f5f5f480;
}

.bg-stone-100\/60 {
  background-color: #f5f5f499;
}

.bg-stone-100\/70 {
  background-color: #f5f5f4b3;
}

.bg-stone-100\/75 {
  background-color: #f5f5f4bf;
}

.bg-stone-100\/80 {
  background-color: #f5f5f4cc;
}

.bg-stone-100\/90 {
  background-color: #f5f5f4e6;
}

.bg-stone-100\/95 {
  background-color: #f5f5f4f2;
}

.bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}

.bg-stone-200\/0 {
  background-color: #e7e5e400;
}

.bg-stone-200\/10 {
  background-color: #e7e5e41a;
}

.bg-stone-200\/100 {
  background-color: #e7e5e4;
}

.bg-stone-200\/20 {
  background-color: #e7e5e433;
}

.bg-stone-200\/25 {
  background-color: #e7e5e440;
}

.bg-stone-200\/30 {
  background-color: #e7e5e44d;
}

.bg-stone-200\/40 {
  background-color: #e7e5e466;
}

.bg-stone-200\/5 {
  background-color: #e7e5e40d;
}

.bg-stone-200\/50 {
  background-color: #e7e5e480;
}

.bg-stone-200\/60 {
  background-color: #e7e5e499;
}

.bg-stone-200\/70 {
  background-color: #e7e5e4b3;
}

.bg-stone-200\/75 {
  background-color: #e7e5e4bf;
}

.bg-stone-200\/80 {
  background-color: #e7e5e4cc;
}

.bg-stone-200\/90 {
  background-color: #e7e5e4e6;
}

.bg-stone-200\/95 {
  background-color: #e7e5e4f2;
}

.bg-stone-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}

.bg-stone-300\/0 {
  background-color: #d6d3d100;
}

.bg-stone-300\/10 {
  background-color: #d6d3d11a;
}

.bg-stone-300\/100 {
  background-color: #d6d3d1;
}

.bg-stone-300\/20 {
  background-color: #d6d3d133;
}

.bg-stone-300\/25 {
  background-color: #d6d3d140;
}

.bg-stone-300\/30 {
  background-color: #d6d3d14d;
}

.bg-stone-300\/40 {
  background-color: #d6d3d166;
}

.bg-stone-300\/5 {
  background-color: #d6d3d10d;
}

.bg-stone-300\/50 {
  background-color: #d6d3d180;
}

.bg-stone-300\/60 {
  background-color: #d6d3d199;
}

.bg-stone-300\/70 {
  background-color: #d6d3d1b3;
}

.bg-stone-300\/75 {
  background-color: #d6d3d1bf;
}

.bg-stone-300\/80 {
  background-color: #d6d3d1cc;
}

.bg-stone-300\/90 {
  background-color: #d6d3d1e6;
}

.bg-stone-300\/95 {
  background-color: #d6d3d1f2;
}

.bg-stone-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}

.bg-stone-400\/0 {
  background-color: #a8a29e00;
}

.bg-stone-400\/10 {
  background-color: #a8a29e1a;
}

.bg-stone-400\/100 {
  background-color: #a8a29e;
}

.bg-stone-400\/20 {
  background-color: #a8a29e33;
}

.bg-stone-400\/25 {
  background-color: #a8a29e40;
}

.bg-stone-400\/30 {
  background-color: #a8a29e4d;
}

.bg-stone-400\/40 {
  background-color: #a8a29e66;
}

.bg-stone-400\/5 {
  background-color: #a8a29e0d;
}

.bg-stone-400\/50 {
  background-color: #a8a29e80;
}

.bg-stone-400\/60 {
  background-color: #a8a29e99;
}

.bg-stone-400\/70 {
  background-color: #a8a29eb3;
}

.bg-stone-400\/75 {
  background-color: #a8a29ebf;
}

.bg-stone-400\/80 {
  background-color: #a8a29ecc;
}

.bg-stone-400\/90 {
  background-color: #a8a29ee6;
}

.bg-stone-400\/95 {
  background-color: #a8a29ef2;
}

.bg-stone-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}

.bg-stone-50\/0 {
  background-color: #fafaf900;
}

.bg-stone-50\/10 {
  background-color: #fafaf91a;
}

.bg-stone-50\/100 {
  background-color: #fafaf9;
}

.bg-stone-50\/20 {
  background-color: #fafaf933;
}

.bg-stone-50\/25 {
  background-color: #fafaf940;
}

.bg-stone-50\/30 {
  background-color: #fafaf94d;
}

.bg-stone-50\/40 {
  background-color: #fafaf966;
}

.bg-stone-50\/5 {
  background-color: #fafaf90d;
}

.bg-stone-50\/50 {
  background-color: #fafaf980;
}

.bg-stone-50\/60 {
  background-color: #fafaf999;
}

.bg-stone-50\/70 {
  background-color: #fafaf9b3;
}

.bg-stone-50\/75 {
  background-color: #fafaf9bf;
}

.bg-stone-50\/80 {
  background-color: #fafaf9cc;
}

.bg-stone-50\/90 {
  background-color: #fafaf9e6;
}

.bg-stone-50\/95 {
  background-color: #fafaf9f2;
}

.bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

.bg-stone-500\/0 {
  background-color: #78716c00;
}

.bg-stone-500\/10 {
  background-color: #78716c1a;
}

.bg-stone-500\/100 {
  background-color: #78716c;
}

.bg-stone-500\/20 {
  background-color: #78716c33;
}

.bg-stone-500\/25 {
  background-color: #78716c40;
}

.bg-stone-500\/30 {
  background-color: #78716c4d;
}

.bg-stone-500\/40 {
  background-color: #78716c66;
}

.bg-stone-500\/5 {
  background-color: #78716c0d;
}

.bg-stone-500\/50 {
  background-color: #78716c80;
}

.bg-stone-500\/60 {
  background-color: #78716c99;
}

.bg-stone-500\/70 {
  background-color: #78716cb3;
}

.bg-stone-500\/75 {
  background-color: #78716cbf;
}

.bg-stone-500\/80 {
  background-color: #78716ccc;
}

.bg-stone-500\/90 {
  background-color: #78716ce6;
}

.bg-stone-500\/95 {
  background-color: #78716cf2;
}

.bg-stone-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}

.bg-stone-600\/0 {
  background-color: #57534e00;
}

.bg-stone-600\/10 {
  background-color: #57534e1a;
}

.bg-stone-600\/100 {
  background-color: #57534e;
}

.bg-stone-600\/20 {
  background-color: #57534e33;
}

.bg-stone-600\/25 {
  background-color: #57534e40;
}

.bg-stone-600\/30 {
  background-color: #57534e4d;
}

.bg-stone-600\/40 {
  background-color: #57534e66;
}

.bg-stone-600\/5 {
  background-color: #57534e0d;
}

.bg-stone-600\/50 {
  background-color: #57534e80;
}

.bg-stone-600\/60 {
  background-color: #57534e99;
}

.bg-stone-600\/70 {
  background-color: #57534eb3;
}

.bg-stone-600\/75 {
  background-color: #57534ebf;
}

.bg-stone-600\/80 {
  background-color: #57534ecc;
}

.bg-stone-600\/90 {
  background-color: #57534ee6;
}

.bg-stone-600\/95 {
  background-color: #57534ef2;
}

.bg-stone-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

.bg-stone-700\/0 {
  background-color: #44403c00;
}

.bg-stone-700\/10 {
  background-color: #44403c1a;
}

.bg-stone-700\/100 {
  background-color: #44403c;
}

.bg-stone-700\/20 {
  background-color: #44403c33;
}

.bg-stone-700\/25 {
  background-color: #44403c40;
}

.bg-stone-700\/30 {
  background-color: #44403c4d;
}

.bg-stone-700\/40 {
  background-color: #44403c66;
}

.bg-stone-700\/5 {
  background-color: #44403c0d;
}

.bg-stone-700\/50 {
  background-color: #44403c80;
}

.bg-stone-700\/60 {
  background-color: #44403c99;
}

.bg-stone-700\/70 {
  background-color: #44403cb3;
}

.bg-stone-700\/75 {
  background-color: #44403cbf;
}

.bg-stone-700\/80 {
  background-color: #44403ccc;
}

.bg-stone-700\/90 {
  background-color: #44403ce6;
}

.bg-stone-700\/95 {
  background-color: #44403cf2;
}

.bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

.bg-stone-800\/0 {
  background-color: #29252400;
}

.bg-stone-800\/10 {
  background-color: #2925241a;
}

.bg-stone-800\/100 {
  background-color: #292524;
}

.bg-stone-800\/20 {
  background-color: #29252433;
}

.bg-stone-800\/25 {
  background-color: #29252440;
}

.bg-stone-800\/30 {
  background-color: #2925244d;
}

.bg-stone-800\/40 {
  background-color: #29252466;
}

.bg-stone-800\/5 {
  background-color: #2925240d;
}

.bg-stone-800\/50 {
  background-color: #29252480;
}

.bg-stone-800\/60 {
  background-color: #29252499;
}

.bg-stone-800\/70 {
  background-color: #292524b3;
}

.bg-stone-800\/75 {
  background-color: #292524bf;
}

.bg-stone-800\/80 {
  background-color: #292524cc;
}

.bg-stone-800\/90 {
  background-color: #292524e6;
}

.bg-stone-800\/95 {
  background-color: #292524f2;
}

.bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

.bg-stone-900\/0 {
  background-color: #1c191700;
}

.bg-stone-900\/10 {
  background-color: #1c19171a;
}

.bg-stone-900\/100 {
  background-color: #1c1917;
}

.bg-stone-900\/20 {
  background-color: #1c191733;
}

.bg-stone-900\/25 {
  background-color: #1c191740;
}

.bg-stone-900\/30 {
  background-color: #1c19174d;
}

.bg-stone-900\/40 {
  background-color: #1c191766;
}

.bg-stone-900\/5 {
  background-color: #1c19170d;
}

.bg-stone-900\/50 {
  background-color: #1c191780;
}

.bg-stone-900\/60 {
  background-color: #1c191799;
}

.bg-stone-900\/70 {
  background-color: #1c1917b3;
}

.bg-stone-900\/75 {
  background-color: #1c1917bf;
}

.bg-stone-900\/80 {
  background-color: #1c1917cc;
}

.bg-stone-900\/90 {
  background-color: #1c1917e6;
}

.bg-stone-900\/95 {
  background-color: #1c1917f2;
}

.bg-stone-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

.bg-stone-950\/0 {
  background-color: #0c0a0900;
}

.bg-stone-950\/10 {
  background-color: #0c0a091a;
}

.bg-stone-950\/100 {
  background-color: #0c0a09;
}

.bg-stone-950\/20 {
  background-color: #0c0a0933;
}

.bg-stone-950\/25 {
  background-color: #0c0a0940;
}

.bg-stone-950\/30 {
  background-color: #0c0a094d;
}

.bg-stone-950\/40 {
  background-color: #0c0a0966;
}

.bg-stone-950\/5 {
  background-color: #0c0a090d;
}

.bg-stone-950\/50 {
  background-color: #0c0a0980;
}

.bg-stone-950\/60 {
  background-color: #0c0a0999;
}

.bg-stone-950\/70 {
  background-color: #0c0a09b3;
}

.bg-stone-950\/75 {
  background-color: #0c0a09bf;
}

.bg-stone-950\/80 {
  background-color: #0c0a09cc;
}

.bg-stone-950\/90 {
  background-color: #0c0a09e6;
}

.bg-stone-950\/95 {
  background-color: #0c0a09f2;
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.bg-teal-100\/0 {
  background-color: #ccfbf100;
}

.bg-teal-100\/10 {
  background-color: #ccfbf11a;
}

.bg-teal-100\/100 {
  background-color: #ccfbf1;
}

.bg-teal-100\/20 {
  background-color: #ccfbf133;
}

.bg-teal-100\/25 {
  background-color: #ccfbf140;
}

.bg-teal-100\/30 {
  background-color: #ccfbf14d;
}

.bg-teal-100\/40 {
  background-color: #ccfbf166;
}

.bg-teal-100\/5 {
  background-color: #ccfbf10d;
}

.bg-teal-100\/50 {
  background-color: #ccfbf180;
}

.bg-teal-100\/60 {
  background-color: #ccfbf199;
}

.bg-teal-100\/70 {
  background-color: #ccfbf1b3;
}

.bg-teal-100\/75 {
  background-color: #ccfbf1bf;
}

.bg-teal-100\/80 {
  background-color: #ccfbf1cc;
}

.bg-teal-100\/90 {
  background-color: #ccfbf1e6;
}

.bg-teal-100\/95 {
  background-color: #ccfbf1f2;
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.bg-teal-200\/0 {
  background-color: #99f6e400;
}

.bg-teal-200\/10 {
  background-color: #99f6e41a;
}

.bg-teal-200\/100 {
  background-color: #99f6e4;
}

.bg-teal-200\/20 {
  background-color: #99f6e433;
}

.bg-teal-200\/25 {
  background-color: #99f6e440;
}

.bg-teal-200\/30 {
  background-color: #99f6e44d;
}

.bg-teal-200\/40 {
  background-color: #99f6e466;
}

.bg-teal-200\/5 {
  background-color: #99f6e40d;
}

.bg-teal-200\/50 {
  background-color: #99f6e480;
}

.bg-teal-200\/60 {
  background-color: #99f6e499;
}

.bg-teal-200\/70 {
  background-color: #99f6e4b3;
}

.bg-teal-200\/75 {
  background-color: #99f6e4bf;
}

.bg-teal-200\/80 {
  background-color: #99f6e4cc;
}

.bg-teal-200\/90 {
  background-color: #99f6e4e6;
}

.bg-teal-200\/95 {
  background-color: #99f6e4f2;
}

.bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

.bg-teal-300\/0 {
  background-color: #5eead400;
}

.bg-teal-300\/10 {
  background-color: #5eead41a;
}

.bg-teal-300\/100 {
  background-color: #5eead4;
}

.bg-teal-300\/20 {
  background-color: #5eead433;
}

.bg-teal-300\/25 {
  background-color: #5eead440;
}

.bg-teal-300\/30 {
  background-color: #5eead44d;
}

.bg-teal-300\/40 {
  background-color: #5eead466;
}

.bg-teal-300\/5 {
  background-color: #5eead40d;
}

.bg-teal-300\/50 {
  background-color: #5eead480;
}

.bg-teal-300\/60 {
  background-color: #5eead499;
}

.bg-teal-300\/70 {
  background-color: #5eead4b3;
}

.bg-teal-300\/75 {
  background-color: #5eead4bf;
}

.bg-teal-300\/80 {
  background-color: #5eead4cc;
}

.bg-teal-300\/90 {
  background-color: #5eead4e6;
}

.bg-teal-300\/95 {
  background-color: #5eead4f2;
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.bg-teal-400\/0 {
  background-color: #2dd4bf00;
}

.bg-teal-400\/10 {
  background-color: #2dd4bf1a;
}

.bg-teal-400\/100 {
  background-color: #2dd4bf;
}

.bg-teal-400\/20 {
  background-color: #2dd4bf33;
}

.bg-teal-400\/25 {
  background-color: #2dd4bf40;
}

.bg-teal-400\/30 {
  background-color: #2dd4bf4d;
}

.bg-teal-400\/40 {
  background-color: #2dd4bf66;
}

.bg-teal-400\/5 {
  background-color: #2dd4bf0d;
}

.bg-teal-400\/50 {
  background-color: #2dd4bf80;
}

.bg-teal-400\/60 {
  background-color: #2dd4bf99;
}

.bg-teal-400\/70 {
  background-color: #2dd4bfb3;
}

.bg-teal-400\/75 {
  background-color: #2dd4bfbf;
}

.bg-teal-400\/80 {
  background-color: #2dd4bfcc;
}

.bg-teal-400\/90 {
  background-color: #2dd4bfe6;
}

.bg-teal-400\/95 {
  background-color: #2dd4bff2;
}

.bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}

.bg-teal-50\/0 {
  background-color: #f0fdfa00;
}

.bg-teal-50\/10 {
  background-color: #f0fdfa1a;
}

.bg-teal-50\/100 {
  background-color: #f0fdfa;
}

.bg-teal-50\/20 {
  background-color: #f0fdfa33;
}

.bg-teal-50\/25 {
  background-color: #f0fdfa40;
}

.bg-teal-50\/30 {
  background-color: #f0fdfa4d;
}

.bg-teal-50\/40 {
  background-color: #f0fdfa66;
}

.bg-teal-50\/5 {
  background-color: #f0fdfa0d;
}

.bg-teal-50\/50 {
  background-color: #f0fdfa80;
}

.bg-teal-50\/60 {
  background-color: #f0fdfa99;
}

.bg-teal-50\/70 {
  background-color: #f0fdfab3;
}

.bg-teal-50\/75 {
  background-color: #f0fdfabf;
}

.bg-teal-50\/80 {
  background-color: #f0fdfacc;
}

.bg-teal-50\/90 {
  background-color: #f0fdfae6;
}

.bg-teal-50\/95 {
  background-color: #f0fdfaf2;
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.bg-teal-500\/0 {
  background-color: #14b8a600;
}

.bg-teal-500\/10 {
  background-color: #14b8a61a;
}

.bg-teal-500\/100 {
  background-color: #14b8a6;
}

.bg-teal-500\/20 {
  background-color: #14b8a633;
}

.bg-teal-500\/25 {
  background-color: #14b8a640;
}

.bg-teal-500\/30 {
  background-color: #14b8a64d;
}

.bg-teal-500\/40 {
  background-color: #14b8a666;
}

.bg-teal-500\/5 {
  background-color: #14b8a60d;
}

.bg-teal-500\/50 {
  background-color: #14b8a680;
}

.bg-teal-500\/60 {
  background-color: #14b8a699;
}

.bg-teal-500\/70 {
  background-color: #14b8a6b3;
}

.bg-teal-500\/75 {
  background-color: #14b8a6bf;
}

.bg-teal-500\/80 {
  background-color: #14b8a6cc;
}

.bg-teal-500\/90 {
  background-color: #14b8a6e6;
}

.bg-teal-500\/95 {
  background-color: #14b8a6f2;
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.bg-teal-600\/0 {
  background-color: #0d948800;
}

.bg-teal-600\/10 {
  background-color: #0d94881a;
}

.bg-teal-600\/100 {
  background-color: #0d9488;
}

.bg-teal-600\/20 {
  background-color: #0d948833;
}

.bg-teal-600\/25 {
  background-color: #0d948840;
}

.bg-teal-600\/30 {
  background-color: #0d94884d;
}

.bg-teal-600\/40 {
  background-color: #0d948866;
}

.bg-teal-600\/5 {
  background-color: #0d94880d;
}

.bg-teal-600\/50 {
  background-color: #0d948880;
}

.bg-teal-600\/60 {
  background-color: #0d948899;
}

.bg-teal-600\/70 {
  background-color: #0d9488b3;
}

.bg-teal-600\/75 {
  background-color: #0d9488bf;
}

.bg-teal-600\/80 {
  background-color: #0d9488cc;
}

.bg-teal-600\/90 {
  background-color: #0d9488e6;
}

.bg-teal-600\/95 {
  background-color: #0d9488f2;
}

.bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

.bg-teal-700\/0 {
  background-color: #0f766e00;
}

.bg-teal-700\/10 {
  background-color: #0f766e1a;
}

.bg-teal-700\/100 {
  background-color: #0f766e;
}

.bg-teal-700\/20 {
  background-color: #0f766e33;
}

.bg-teal-700\/25 {
  background-color: #0f766e40;
}

.bg-teal-700\/30 {
  background-color: #0f766e4d;
}

.bg-teal-700\/40 {
  background-color: #0f766e66;
}

.bg-teal-700\/5 {
  background-color: #0f766e0d;
}

.bg-teal-700\/50 {
  background-color: #0f766e80;
}

.bg-teal-700\/60 {
  background-color: #0f766e99;
}

.bg-teal-700\/70 {
  background-color: #0f766eb3;
}

.bg-teal-700\/75 {
  background-color: #0f766ebf;
}

.bg-teal-700\/80 {
  background-color: #0f766ecc;
}

.bg-teal-700\/90 {
  background-color: #0f766ee6;
}

.bg-teal-700\/95 {
  background-color: #0f766ef2;
}

.bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}

.bg-teal-800\/0 {
  background-color: #115e5900;
}

.bg-teal-800\/10 {
  background-color: #115e591a;
}

.bg-teal-800\/100 {
  background-color: #115e59;
}

.bg-teal-800\/20 {
  background-color: #115e5933;
}

.bg-teal-800\/25 {
  background-color: #115e5940;
}

.bg-teal-800\/30 {
  background-color: #115e594d;
}

.bg-teal-800\/40 {
  background-color: #115e5966;
}

.bg-teal-800\/5 {
  background-color: #115e590d;
}

.bg-teal-800\/50 {
  background-color: #115e5980;
}

.bg-teal-800\/60 {
  background-color: #115e5999;
}

.bg-teal-800\/70 {
  background-color: #115e59b3;
}

.bg-teal-800\/75 {
  background-color: #115e59bf;
}

.bg-teal-800\/80 {
  background-color: #115e59cc;
}

.bg-teal-800\/90 {
  background-color: #115e59e6;
}

.bg-teal-800\/95 {
  background-color: #115e59f2;
}

.bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}

.bg-teal-900\/0 {
  background-color: #134e4a00;
}

.bg-teal-900\/10 {
  background-color: #134e4a1a;
}

.bg-teal-900\/100 {
  background-color: #134e4a;
}

.bg-teal-900\/20 {
  background-color: #134e4a33;
}

.bg-teal-900\/25 {
  background-color: #134e4a40;
}

.bg-teal-900\/30 {
  background-color: #134e4a4d;
}

.bg-teal-900\/40 {
  background-color: #134e4a66;
}

.bg-teal-900\/5 {
  background-color: #134e4a0d;
}

.bg-teal-900\/50 {
  background-color: #134e4a80;
}

.bg-teal-900\/60 {
  background-color: #134e4a99;
}

.bg-teal-900\/70 {
  background-color: #134e4ab3;
}

.bg-teal-900\/75 {
  background-color: #134e4abf;
}

.bg-teal-900\/80 {
  background-color: #134e4acc;
}

.bg-teal-900\/90 {
  background-color: #134e4ae6;
}

.bg-teal-900\/95 {
  background-color: #134e4af2;
}

.bg-teal-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 47 46 / var(--tw-bg-opacity));
}

.bg-teal-950\/0 {
  background-color: #042f2e00;
}

.bg-teal-950\/10 {
  background-color: #042f2e1a;
}

.bg-teal-950\/100 {
  background-color: #042f2e;
}

.bg-teal-950\/20 {
  background-color: #042f2e33;
}

.bg-teal-950\/25 {
  background-color: #042f2e40;
}

.bg-teal-950\/30 {
  background-color: #042f2e4d;
}

.bg-teal-950\/40 {
  background-color: #042f2e66;
}

.bg-teal-950\/5 {
  background-color: #042f2e0d;
}

.bg-teal-950\/50 {
  background-color: #042f2e80;
}

.bg-teal-950\/60 {
  background-color: #042f2e99;
}

.bg-teal-950\/70 {
  background-color: #042f2eb3;
}

.bg-teal-950\/75 {
  background-color: #042f2ebf;
}

.bg-teal-950\/80 {
  background-color: #042f2ecc;
}

.bg-teal-950\/90 {
  background-color: #042f2ee6;
}

.bg-teal-950\/95 {
  background-color: #042f2ef2;
}

.bg-transparent, .bg-transparent\/0 {
  background-color: #0000;
}

.bg-transparent\/10 {
  background-color: #0000001a;
}

.bg-transparent\/100 {
  background-color: #000;
}

.bg-transparent\/20 {
  background-color: #0003;
}

.bg-transparent\/25 {
  background-color: #00000040;
}

.bg-transparent\/30 {
  background-color: #0000004d;
}

.bg-transparent\/40 {
  background-color: #0006;
}

.bg-transparent\/5 {
  background-color: #0000000d;
}

.bg-transparent\/50 {
  background-color: #00000080;
}

.bg-transparent\/60 {
  background-color: #0009;
}

.bg-transparent\/70 {
  background-color: #000000b3;
}

.bg-transparent\/75 {
  background-color: #000000bf;
}

.bg-transparent\/80 {
  background-color: #000c;
}

.bg-transparent\/90 {
  background-color: #000000e6;
}

.bg-transparent\/95 {
  background-color: #000000f2;
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-100\/0 {
  background-color: #ede9fe00;
}

.bg-violet-100\/10 {
  background-color: #ede9fe1a;
}

.bg-violet-100\/100 {
  background-color: #ede9fe;
}

.bg-violet-100\/20 {
  background-color: #ede9fe33;
}

.bg-violet-100\/25 {
  background-color: #ede9fe40;
}

.bg-violet-100\/30 {
  background-color: #ede9fe4d;
}

.bg-violet-100\/40 {
  background-color: #ede9fe66;
}

.bg-violet-100\/5 {
  background-color: #ede9fe0d;
}

.bg-violet-100\/50 {
  background-color: #ede9fe80;
}

.bg-violet-100\/60 {
  background-color: #ede9fe99;
}

.bg-violet-100\/70 {
  background-color: #ede9feb3;
}

.bg-violet-100\/75 {
  background-color: #ede9febf;
}

.bg-violet-100\/80 {
  background-color: #ede9fecc;
}

.bg-violet-100\/90 {
  background-color: #ede9fee6;
}

.bg-violet-100\/95 {
  background-color: #ede9fef2;
}

.bg-violet-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

.bg-violet-200\/0 {
  background-color: #ddd6fe00;
}

.bg-violet-200\/10 {
  background-color: #ddd6fe1a;
}

.bg-violet-200\/100 {
  background-color: #ddd6fe;
}

.bg-violet-200\/20 {
  background-color: #ddd6fe33;
}

.bg-violet-200\/25 {
  background-color: #ddd6fe40;
}

.bg-violet-200\/30 {
  background-color: #ddd6fe4d;
}

.bg-violet-200\/40 {
  background-color: #ddd6fe66;
}

.bg-violet-200\/5 {
  background-color: #ddd6fe0d;
}

.bg-violet-200\/50 {
  background-color: #ddd6fe80;
}

.bg-violet-200\/60 {
  background-color: #ddd6fe99;
}

.bg-violet-200\/70 {
  background-color: #ddd6feb3;
}

.bg-violet-200\/75 {
  background-color: #ddd6febf;
}

.bg-violet-200\/80 {
  background-color: #ddd6fecc;
}

.bg-violet-200\/90 {
  background-color: #ddd6fee6;
}

.bg-violet-200\/95 {
  background-color: #ddd6fef2;
}

.bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.bg-violet-300\/0 {
  background-color: #c4b5fd00;
}

.bg-violet-300\/10 {
  background-color: #c4b5fd1a;
}

.bg-violet-300\/100 {
  background-color: #c4b5fd;
}

.bg-violet-300\/20 {
  background-color: #c4b5fd33;
}

.bg-violet-300\/25 {
  background-color: #c4b5fd40;
}

.bg-violet-300\/30 {
  background-color: #c4b5fd4d;
}

.bg-violet-300\/40 {
  background-color: #c4b5fd66;
}

.bg-violet-300\/5 {
  background-color: #c4b5fd0d;
}

.bg-violet-300\/50 {
  background-color: #c4b5fd80;
}

.bg-violet-300\/60 {
  background-color: #c4b5fd99;
}

.bg-violet-300\/70 {
  background-color: #c4b5fdb3;
}

.bg-violet-300\/75 {
  background-color: #c4b5fdbf;
}

.bg-violet-300\/80 {
  background-color: #c4b5fdcc;
}

.bg-violet-300\/90 {
  background-color: #c4b5fde6;
}

.bg-violet-300\/95 {
  background-color: #c4b5fdf2;
}

.bg-violet-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

.bg-violet-400\/0 {
  background-color: #a78bfa00;
}

.bg-violet-400\/10 {
  background-color: #a78bfa1a;
}

.bg-violet-400\/100 {
  background-color: #a78bfa;
}

.bg-violet-400\/20 {
  background-color: #a78bfa33;
}

.bg-violet-400\/25 {
  background-color: #a78bfa40;
}

.bg-violet-400\/30 {
  background-color: #a78bfa4d;
}

.bg-violet-400\/40 {
  background-color: #a78bfa66;
}

.bg-violet-400\/5 {
  background-color: #a78bfa0d;
}

.bg-violet-400\/50 {
  background-color: #a78bfa80;
}

.bg-violet-400\/60 {
  background-color: #a78bfa99;
}

.bg-violet-400\/70 {
  background-color: #a78bfab3;
}

.bg-violet-400\/75 {
  background-color: #a78bfabf;
}

.bg-violet-400\/80 {
  background-color: #a78bfacc;
}

.bg-violet-400\/90 {
  background-color: #a78bfae6;
}

.bg-violet-400\/95 {
  background-color: #a78bfaf2;
}

.bg-violet-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}

.bg-violet-50\/0 {
  background-color: #f5f3ff00;
}

.bg-violet-50\/10 {
  background-color: #f5f3ff1a;
}

.bg-violet-50\/100 {
  background-color: #f5f3ff;
}

.bg-violet-50\/20 {
  background-color: #f5f3ff33;
}

.bg-violet-50\/25 {
  background-color: #f5f3ff40;
}

.bg-violet-50\/30 {
  background-color: #f5f3ff4d;
}

.bg-violet-50\/40 {
  background-color: #f5f3ff66;
}

.bg-violet-50\/5 {
  background-color: #f5f3ff0d;
}

.bg-violet-50\/50 {
  background-color: #f5f3ff80;
}

.bg-violet-50\/60 {
  background-color: #f5f3ff99;
}

.bg-violet-50\/70 {
  background-color: #f5f3ffb3;
}

.bg-violet-50\/75 {
  background-color: #f5f3ffbf;
}

.bg-violet-50\/80 {
  background-color: #f5f3ffcc;
}

.bg-violet-50\/90 {
  background-color: #f5f3ffe6;
}

.bg-violet-50\/95 {
  background-color: #f5f3fff2;
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.bg-violet-500\/0 {
  background-color: #8b5cf600;
}

.bg-violet-500\/10 {
  background-color: #8b5cf61a;
}

.bg-violet-500\/100 {
  background-color: #8b5cf6;
}

.bg-violet-500\/20 {
  background-color: #8b5cf633;
}

.bg-violet-500\/25 {
  background-color: #8b5cf640;
}

.bg-violet-500\/30 {
  background-color: #8b5cf64d;
}

.bg-violet-500\/40 {
  background-color: #8b5cf666;
}

.bg-violet-500\/5 {
  background-color: #8b5cf60d;
}

.bg-violet-500\/50 {
  background-color: #8b5cf680;
}

.bg-violet-500\/60 {
  background-color: #8b5cf699;
}

.bg-violet-500\/70 {
  background-color: #8b5cf6b3;
}

.bg-violet-500\/75 {
  background-color: #8b5cf6bf;
}

.bg-violet-500\/80 {
  background-color: #8b5cf6cc;
}

.bg-violet-500\/90 {
  background-color: #8b5cf6e6;
}

.bg-violet-500\/95 {
  background-color: #8b5cf6f2;
}

.bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

.bg-violet-600\/0 {
  background-color: #7c3aed00;
}

.bg-violet-600\/10 {
  background-color: #7c3aed1a;
}

.bg-violet-600\/100 {
  background-color: #7c3aed;
}

.bg-violet-600\/20 {
  background-color: #7c3aed33;
}

.bg-violet-600\/25 {
  background-color: #7c3aed40;
}

.bg-violet-600\/30 {
  background-color: #7c3aed4d;
}

.bg-violet-600\/40 {
  background-color: #7c3aed66;
}

.bg-violet-600\/5 {
  background-color: #7c3aed0d;
}

.bg-violet-600\/50 {
  background-color: #7c3aed80;
}

.bg-violet-600\/60 {
  background-color: #7c3aed99;
}

.bg-violet-600\/70 {
  background-color: #7c3aedb3;
}

.bg-violet-600\/75 {
  background-color: #7c3aedbf;
}

.bg-violet-600\/80 {
  background-color: #7c3aedcc;
}

.bg-violet-600\/90 {
  background-color: #7c3aede6;
}

.bg-violet-600\/95 {
  background-color: #7c3aedf2;
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-violet-700\/0 {
  background-color: #6d28d900;
}

.bg-violet-700\/10 {
  background-color: #6d28d91a;
}

.bg-violet-700\/100 {
  background-color: #6d28d9;
}

.bg-violet-700\/20 {
  background-color: #6d28d933;
}

.bg-violet-700\/25 {
  background-color: #6d28d940;
}

.bg-violet-700\/30 {
  background-color: #6d28d94d;
}

.bg-violet-700\/40 {
  background-color: #6d28d966;
}

.bg-violet-700\/5 {
  background-color: #6d28d90d;
}

.bg-violet-700\/50 {
  background-color: #6d28d980;
}

.bg-violet-700\/60 {
  background-color: #6d28d999;
}

.bg-violet-700\/70 {
  background-color: #6d28d9b3;
}

.bg-violet-700\/75 {
  background-color: #6d28d9bf;
}

.bg-violet-700\/80 {
  background-color: #6d28d9cc;
}

.bg-violet-700\/90 {
  background-color: #6d28d9e6;
}

.bg-violet-700\/95 {
  background-color: #6d28d9f2;
}

.bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

.bg-violet-800\/0 {
  background-color: #5b21b600;
}

.bg-violet-800\/10 {
  background-color: #5b21b61a;
}

.bg-violet-800\/100 {
  background-color: #5b21b6;
}

.bg-violet-800\/20 {
  background-color: #5b21b633;
}

.bg-violet-800\/25 {
  background-color: #5b21b640;
}

.bg-violet-800\/30 {
  background-color: #5b21b64d;
}

.bg-violet-800\/40 {
  background-color: #5b21b666;
}

.bg-violet-800\/5 {
  background-color: #5b21b60d;
}

.bg-violet-800\/50 {
  background-color: #5b21b680;
}

.bg-violet-800\/60 {
  background-color: #5b21b699;
}

.bg-violet-800\/70 {
  background-color: #5b21b6b3;
}

.bg-violet-800\/75 {
  background-color: #5b21b6bf;
}

.bg-violet-800\/80 {
  background-color: #5b21b6cc;
}

.bg-violet-800\/90 {
  background-color: #5b21b6e6;
}

.bg-violet-800\/95 {
  background-color: #5b21b6f2;
}

.bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.bg-violet-900\/0 {
  background-color: #4c1d9500;
}

.bg-violet-900\/10 {
  background-color: #4c1d951a;
}

.bg-violet-900\/100 {
  background-color: #4c1d95;
}

.bg-violet-900\/20 {
  background-color: #4c1d9533;
}

.bg-violet-900\/25 {
  background-color: #4c1d9540;
}

.bg-violet-900\/30 {
  background-color: #4c1d954d;
}

.bg-violet-900\/40 {
  background-color: #4c1d9566;
}

.bg-violet-900\/5 {
  background-color: #4c1d950d;
}

.bg-violet-900\/50 {
  background-color: #4c1d9580;
}

.bg-violet-900\/60 {
  background-color: #4c1d9599;
}

.bg-violet-900\/70 {
  background-color: #4c1d95b3;
}

.bg-violet-900\/75 {
  background-color: #4c1d95bf;
}

.bg-violet-900\/80 {
  background-color: #4c1d95cc;
}

.bg-violet-900\/90 {
  background-color: #4c1d95e6;
}

.bg-violet-900\/95 {
  background-color: #4c1d95f2;
}

.bg-violet-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

.bg-violet-950\/0 {
  background-color: #2e106500;
}

.bg-violet-950\/10 {
  background-color: #2e10651a;
}

.bg-violet-950\/100 {
  background-color: #2e1065;
}

.bg-violet-950\/20 {
  background-color: #2e106533;
}

.bg-violet-950\/25 {
  background-color: #2e106540;
}

.bg-violet-950\/30 {
  background-color: #2e10654d;
}

.bg-violet-950\/40 {
  background-color: #2e106566;
}

.bg-violet-950\/5 {
  background-color: #2e10650d;
}

.bg-violet-950\/50 {
  background-color: #2e106580;
}

.bg-violet-950\/60 {
  background-color: #2e106599;
}

.bg-violet-950\/70 {
  background-color: #2e1065b3;
}

.bg-violet-950\/75 {
  background-color: #2e1065bf;
}

.bg-violet-950\/80 {
  background-color: #2e1065cc;
}

.bg-violet-950\/90 {
  background-color: #2e1065e6;
}

.bg-violet-950\/95 {
  background-color: #2e1065f2;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/0 {
  background-color: #fff0;
}

.bg-white\/10 {
  background-color: #ffffff1a;
}

.bg-white\/100 {
  background-color: #fff;
}

.bg-white\/20 {
  background-color: #fff3;
}

.bg-white\/25 {
  background-color: #ffffff40;
}

.bg-white\/30 {
  background-color: #ffffff4d;
}

.bg-white\/40 {
  background-color: #fff6;
}

.bg-white\/5 {
  background-color: #ffffff0d;
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-white\/60 {
  background-color: #fff9;
}

.bg-white\/70 {
  background-color: #ffffffb3;
}

.bg-white\/75 {
  background-color: #ffffffbf;
}

.bg-white\/80 {
  background-color: #fffc;
}

.bg-white\/90 {
  background-color: #ffffffe6;
}

.bg-white\/95 {
  background-color: #fffffff2;
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.bg-yellow-100\/0 {
  background-color: #fef9c300;
}

.bg-yellow-100\/10 {
  background-color: #fef9c31a;
}

.bg-yellow-100\/100 {
  background-color: #fef9c3;
}

.bg-yellow-100\/20 {
  background-color: #fef9c333;
}

.bg-yellow-100\/25 {
  background-color: #fef9c340;
}

.bg-yellow-100\/30 {
  background-color: #fef9c34d;
}

.bg-yellow-100\/40 {
  background-color: #fef9c366;
}

.bg-yellow-100\/5 {
  background-color: #fef9c30d;
}

.bg-yellow-100\/50 {
  background-color: #fef9c380;
}

.bg-yellow-100\/60 {
  background-color: #fef9c399;
}

.bg-yellow-100\/70 {
  background-color: #fef9c3b3;
}

.bg-yellow-100\/75 {
  background-color: #fef9c3bf;
}

.bg-yellow-100\/80 {
  background-color: #fef9c3cc;
}

.bg-yellow-100\/90 {
  background-color: #fef9c3e6;
}

.bg-yellow-100\/95 {
  background-color: #fef9c3f2;
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}

.bg-yellow-200\/0 {
  background-color: #fef08a00;
}

.bg-yellow-200\/10 {
  background-color: #fef08a1a;
}

.bg-yellow-200\/100 {
  background-color: #fef08a;
}

.bg-yellow-200\/20 {
  background-color: #fef08a33;
}

.bg-yellow-200\/25 {
  background-color: #fef08a40;
}

.bg-yellow-200\/30 {
  background-color: #fef08a4d;
}

.bg-yellow-200\/40 {
  background-color: #fef08a66;
}

.bg-yellow-200\/5 {
  background-color: #fef08a0d;
}

.bg-yellow-200\/50 {
  background-color: #fef08a80;
}

.bg-yellow-200\/60 {
  background-color: #fef08a99;
}

.bg-yellow-200\/70 {
  background-color: #fef08ab3;
}

.bg-yellow-200\/75 {
  background-color: #fef08abf;
}

.bg-yellow-200\/80 {
  background-color: #fef08acc;
}

.bg-yellow-200\/90 {
  background-color: #fef08ae6;
}

.bg-yellow-200\/95 {
  background-color: #fef08af2;
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}

.bg-yellow-300\/0 {
  background-color: #fde04700;
}

.bg-yellow-300\/10 {
  background-color: #fde0471a;
}

.bg-yellow-300\/100 {
  background-color: #fde047;
}

.bg-yellow-300\/20 {
  background-color: #fde04733;
}

.bg-yellow-300\/25 {
  background-color: #fde04740;
}

.bg-yellow-300\/30 {
  background-color: #fde0474d;
}

.bg-yellow-300\/40 {
  background-color: #fde04766;
}

.bg-yellow-300\/5 {
  background-color: #fde0470d;
}

.bg-yellow-300\/50 {
  background-color: #fde04780;
}

.bg-yellow-300\/60 {
  background-color: #fde04799;
}

.bg-yellow-300\/70 {
  background-color: #fde047b3;
}

.bg-yellow-300\/75 {
  background-color: #fde047bf;
}

.bg-yellow-300\/80 {
  background-color: #fde047cc;
}

.bg-yellow-300\/90 {
  background-color: #fde047e6;
}

.bg-yellow-300\/95 {
  background-color: #fde047f2;
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.bg-yellow-400\/0 {
  background-color: #facc1500;
}

.bg-yellow-400\/10 {
  background-color: #facc151a;
}

.bg-yellow-400\/100 {
  background-color: #facc15;
}

.bg-yellow-400\/20 {
  background-color: #facc1533;
}

.bg-yellow-400\/25 {
  background-color: #facc1540;
}

.bg-yellow-400\/30 {
  background-color: #facc154d;
}

.bg-yellow-400\/40 {
  background-color: #facc1566;
}

.bg-yellow-400\/5 {
  background-color: #facc150d;
}

.bg-yellow-400\/50 {
  background-color: #facc1580;
}

.bg-yellow-400\/60 {
  background-color: #facc1599;
}

.bg-yellow-400\/70 {
  background-color: #facc15b3;
}

.bg-yellow-400\/75 {
  background-color: #facc15bf;
}

.bg-yellow-400\/80 {
  background-color: #facc15cc;
}

.bg-yellow-400\/90 {
  background-color: #facc15e6;
}

.bg-yellow-400\/95 {
  background-color: #facc15f2;
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.bg-yellow-50\/0 {
  background-color: #fefce800;
}

.bg-yellow-50\/10 {
  background-color: #fefce81a;
}

.bg-yellow-50\/100 {
  background-color: #fefce8;
}

.bg-yellow-50\/20 {
  background-color: #fefce833;
}

.bg-yellow-50\/25 {
  background-color: #fefce840;
}

.bg-yellow-50\/30 {
  background-color: #fefce84d;
}

.bg-yellow-50\/40 {
  background-color: #fefce866;
}

.bg-yellow-50\/5 {
  background-color: #fefce80d;
}

.bg-yellow-50\/50 {
  background-color: #fefce880;
}

.bg-yellow-50\/60 {
  background-color: #fefce899;
}

.bg-yellow-50\/70 {
  background-color: #fefce8b3;
}

.bg-yellow-50\/75 {
  background-color: #fefce8bf;
}

.bg-yellow-50\/80 {
  background-color: #fefce8cc;
}

.bg-yellow-50\/90 {
  background-color: #fefce8e6;
}

.bg-yellow-50\/95 {
  background-color: #fefce8f2;
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-yellow-500\/0 {
  background-color: #eab30800;
}

.bg-yellow-500\/10 {
  background-color: #eab3081a;
}

.bg-yellow-500\/100 {
  background-color: #eab308;
}

.bg-yellow-500\/20 {
  background-color: #eab30833;
}

.bg-yellow-500\/25 {
  background-color: #eab30840;
}

.bg-yellow-500\/30 {
  background-color: #eab3084d;
}

.bg-yellow-500\/40 {
  background-color: #eab30866;
}

.bg-yellow-500\/5 {
  background-color: #eab3080d;
}

.bg-yellow-500\/50 {
  background-color: #eab30880;
}

.bg-yellow-500\/60 {
  background-color: #eab30899;
}

.bg-yellow-500\/70 {
  background-color: #eab308b3;
}

.bg-yellow-500\/75 {
  background-color: #eab308bf;
}

.bg-yellow-500\/80 {
  background-color: #eab308cc;
}

.bg-yellow-500\/90 {
  background-color: #eab308e6;
}

.bg-yellow-500\/95 {
  background-color: #eab308f2;
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}

.bg-yellow-600\/0 {
  background-color: #ca8a0400;
}

.bg-yellow-600\/10 {
  background-color: #ca8a041a;
}

.bg-yellow-600\/100 {
  background-color: #ca8a04;
}

.bg-yellow-600\/20 {
  background-color: #ca8a0433;
}

.bg-yellow-600\/25 {
  background-color: #ca8a0440;
}

.bg-yellow-600\/30 {
  background-color: #ca8a044d;
}

.bg-yellow-600\/40 {
  background-color: #ca8a0466;
}

.bg-yellow-600\/5 {
  background-color: #ca8a040d;
}

.bg-yellow-600\/50 {
  background-color: #ca8a0480;
}

.bg-yellow-600\/60 {
  background-color: #ca8a0499;
}

.bg-yellow-600\/70 {
  background-color: #ca8a04b3;
}

.bg-yellow-600\/75 {
  background-color: #ca8a04bf;
}

.bg-yellow-600\/80 {
  background-color: #ca8a04cc;
}

.bg-yellow-600\/90 {
  background-color: #ca8a04e6;
}

.bg-yellow-600\/95 {
  background-color: #ca8a04f2;
}

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}

.bg-yellow-700\/0 {
  background-color: #a1620700;
}

.bg-yellow-700\/10 {
  background-color: #a162071a;
}

.bg-yellow-700\/100 {
  background-color: #a16207;
}

.bg-yellow-700\/20 {
  background-color: #a1620733;
}

.bg-yellow-700\/25 {
  background-color: #a1620740;
}

.bg-yellow-700\/30 {
  background-color: #a162074d;
}

.bg-yellow-700\/40 {
  background-color: #a1620766;
}

.bg-yellow-700\/5 {
  background-color: #a162070d;
}

.bg-yellow-700\/50 {
  background-color: #a1620780;
}

.bg-yellow-700\/60 {
  background-color: #a1620799;
}

.bg-yellow-700\/70 {
  background-color: #a16207b3;
}

.bg-yellow-700\/75 {
  background-color: #a16207bf;
}

.bg-yellow-700\/80 {
  background-color: #a16207cc;
}

.bg-yellow-700\/90 {
  background-color: #a16207e6;
}

.bg-yellow-700\/95 {
  background-color: #a16207f2;
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(133 77 14 / var(--tw-bg-opacity));
}

.bg-yellow-800\/0 {
  background-color: #854d0e00;
}

.bg-yellow-800\/10 {
  background-color: #854d0e1a;
}

.bg-yellow-800\/100 {
  background-color: #854d0e;
}

.bg-yellow-800\/20 {
  background-color: #854d0e33;
}

.bg-yellow-800\/25 {
  background-color: #854d0e40;
}

.bg-yellow-800\/30 {
  background-color: #854d0e4d;
}

.bg-yellow-800\/40 {
  background-color: #854d0e66;
}

.bg-yellow-800\/5 {
  background-color: #854d0e0d;
}

.bg-yellow-800\/50 {
  background-color: #854d0e80;
}

.bg-yellow-800\/60 {
  background-color: #854d0e99;
}

.bg-yellow-800\/70 {
  background-color: #854d0eb3;
}

.bg-yellow-800\/75 {
  background-color: #854d0ebf;
}

.bg-yellow-800\/80 {
  background-color: #854d0ecc;
}

.bg-yellow-800\/90 {
  background-color: #854d0ee6;
}

.bg-yellow-800\/95 {
  background-color: #854d0ef2;
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 63 18 / var(--tw-bg-opacity));
}

.bg-yellow-900\/0 {
  background-color: #713f1200;
}

.bg-yellow-900\/10 {
  background-color: #713f121a;
}

.bg-yellow-900\/100 {
  background-color: #713f12;
}

.bg-yellow-900\/20 {
  background-color: #713f1233;
}

.bg-yellow-900\/25 {
  background-color: #713f1240;
}

.bg-yellow-900\/30 {
  background-color: #713f124d;
}

.bg-yellow-900\/40 {
  background-color: #713f1266;
}

.bg-yellow-900\/5 {
  background-color: #713f120d;
}

.bg-yellow-900\/50 {
  background-color: #713f1280;
}

.bg-yellow-900\/60 {
  background-color: #713f1299;
}

.bg-yellow-900\/70 {
  background-color: #713f12b3;
}

.bg-yellow-900\/75 {
  background-color: #713f12bf;
}

.bg-yellow-900\/80 {
  background-color: #713f12cc;
}

.bg-yellow-900\/90 {
  background-color: #713f12e6;
}

.bg-yellow-900\/95 {
  background-color: #713f12f2;
}

.bg-yellow-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(66 32 6 / var(--tw-bg-opacity));
}

.bg-yellow-950\/0 {
  background-color: #42200600;
}

.bg-yellow-950\/10 {
  background-color: #4220061a;
}

.bg-yellow-950\/100 {
  background-color: #422006;
}

.bg-yellow-950\/20 {
  background-color: #42200633;
}

.bg-yellow-950\/25 {
  background-color: #42200640;
}

.bg-yellow-950\/30 {
  background-color: #4220064d;
}

.bg-yellow-950\/40 {
  background-color: #42200666;
}

.bg-yellow-950\/5 {
  background-color: #4220060d;
}

.bg-yellow-950\/50 {
  background-color: #42200680;
}

.bg-yellow-950\/60 {
  background-color: #42200699;
}

.bg-yellow-950\/70 {
  background-color: #422006b3;
}

.bg-yellow-950\/75 {
  background-color: #422006bf;
}

.bg-yellow-950\/80 {
  background-color: #422006cc;
}

.bg-yellow-950\/90 {
  background-color: #422006e6;
}

.bg-yellow-950\/95 {
  background-color: #422006f2;
}

.bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-zinc-100\/0 {
  background-color: #f4f4f500;
}

.bg-zinc-100\/10 {
  background-color: #f4f4f51a;
}

.bg-zinc-100\/100 {
  background-color: #f4f4f5;
}

.bg-zinc-100\/20 {
  background-color: #f4f4f533;
}

.bg-zinc-100\/25 {
  background-color: #f4f4f540;
}

.bg-zinc-100\/30 {
  background-color: #f4f4f54d;
}

.bg-zinc-100\/40 {
  background-color: #f4f4f566;
}

.bg-zinc-100\/5 {
  background-color: #f4f4f50d;
}

.bg-zinc-100\/50 {
  background-color: #f4f4f580;
}

.bg-zinc-100\/60 {
  background-color: #f4f4f599;
}

.bg-zinc-100\/70 {
  background-color: #f4f4f5b3;
}

.bg-zinc-100\/75 {
  background-color: #f4f4f5bf;
}

.bg-zinc-100\/80 {
  background-color: #f4f4f5cc;
}

.bg-zinc-100\/90 {
  background-color: #f4f4f5e6;
}

.bg-zinc-100\/95 {
  background-color: #f4f4f5f2;
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.bg-zinc-200\/0 {
  background-color: #e4e4e700;
}

.bg-zinc-200\/10 {
  background-color: #e4e4e71a;
}

.bg-zinc-200\/100 {
  background-color: #e4e4e7;
}

.bg-zinc-200\/20 {
  background-color: #e4e4e733;
}

.bg-zinc-200\/25 {
  background-color: #e4e4e740;
}

.bg-zinc-200\/30 {
  background-color: #e4e4e74d;
}

.bg-zinc-200\/40 {
  background-color: #e4e4e766;
}

.bg-zinc-200\/5 {
  background-color: #e4e4e70d;
}

.bg-zinc-200\/50 {
  background-color: #e4e4e780;
}

.bg-zinc-200\/60 {
  background-color: #e4e4e799;
}

.bg-zinc-200\/70 {
  background-color: #e4e4e7b3;
}

.bg-zinc-200\/75 {
  background-color: #e4e4e7bf;
}

.bg-zinc-200\/80 {
  background-color: #e4e4e7cc;
}

.bg-zinc-200\/90 {
  background-color: #e4e4e7e6;
}

.bg-zinc-200\/95 {
  background-color: #e4e4e7f2;
}

.bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

.bg-zinc-300\/0 {
  background-color: #d4d4d800;
}

.bg-zinc-300\/10 {
  background-color: #d4d4d81a;
}

.bg-zinc-300\/100 {
  background-color: #d4d4d8;
}

.bg-zinc-300\/20 {
  background-color: #d4d4d833;
}

.bg-zinc-300\/25 {
  background-color: #d4d4d840;
}

.bg-zinc-300\/30 {
  background-color: #d4d4d84d;
}

.bg-zinc-300\/40 {
  background-color: #d4d4d866;
}

.bg-zinc-300\/5 {
  background-color: #d4d4d80d;
}

.bg-zinc-300\/50 {
  background-color: #d4d4d880;
}

.bg-zinc-300\/60 {
  background-color: #d4d4d899;
}

.bg-zinc-300\/70 {
  background-color: #d4d4d8b3;
}

.bg-zinc-300\/75 {
  background-color: #d4d4d8bf;
}

.bg-zinc-300\/80 {
  background-color: #d4d4d8cc;
}

.bg-zinc-300\/90 {
  background-color: #d4d4d8e6;
}

.bg-zinc-300\/95 {
  background-color: #d4d4d8f2;
}

.bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.bg-zinc-400\/0 {
  background-color: #a1a1aa00;
}

.bg-zinc-400\/10 {
  background-color: #a1a1aa1a;
}

.bg-zinc-400\/100 {
  background-color: #a1a1aa;
}

.bg-zinc-400\/20 {
  background-color: #a1a1aa33;
}

.bg-zinc-400\/25 {
  background-color: #a1a1aa40;
}

.bg-zinc-400\/30 {
  background-color: #a1a1aa4d;
}

.bg-zinc-400\/40 {
  background-color: #a1a1aa66;
}

.bg-zinc-400\/5 {
  background-color: #a1a1aa0d;
}

.bg-zinc-400\/50 {
  background-color: #a1a1aa80;
}

.bg-zinc-400\/60 {
  background-color: #a1a1aa99;
}

.bg-zinc-400\/70 {
  background-color: #a1a1aab3;
}

.bg-zinc-400\/75 {
  background-color: #a1a1aabf;
}

.bg-zinc-400\/80 {
  background-color: #a1a1aacc;
}

.bg-zinc-400\/90 {
  background-color: #a1a1aae6;
}

.bg-zinc-400\/95 {
  background-color: #a1a1aaf2;
}

.bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-zinc-50\/0 {
  background-color: #fafafa00;
}

.bg-zinc-50\/10 {
  background-color: #fafafa1a;
}

.bg-zinc-50\/100 {
  background-color: #fafafa;
}

.bg-zinc-50\/20 {
  background-color: #fafafa33;
}

.bg-zinc-50\/25 {
  background-color: #fafafa40;
}

.bg-zinc-50\/30 {
  background-color: #fafafa4d;
}

.bg-zinc-50\/40 {
  background-color: #fafafa66;
}

.bg-zinc-50\/5 {
  background-color: #fafafa0d;
}

.bg-zinc-50\/50 {
  background-color: #fafafa80;
}

.bg-zinc-50\/60 {
  background-color: #fafafa99;
}

.bg-zinc-50\/70 {
  background-color: #fafafab3;
}

.bg-zinc-50\/75 {
  background-color: #fafafabf;
}

.bg-zinc-50\/80 {
  background-color: #fafafacc;
}

.bg-zinc-50\/90 {
  background-color: #fafafae6;
}

.bg-zinc-50\/95 {
  background-color: #fafafaf2;
}

.bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.bg-zinc-500\/0 {
  background-color: #71717a00;
}

.bg-zinc-500\/10 {
  background-color: #71717a1a;
}

.bg-zinc-500\/100 {
  background-color: #71717a;
}

.bg-zinc-500\/20 {
  background-color: #71717a33;
}

.bg-zinc-500\/25 {
  background-color: #71717a40;
}

.bg-zinc-500\/30 {
  background-color: #71717a4d;
}

.bg-zinc-500\/40 {
  background-color: #71717a66;
}

.bg-zinc-500\/5 {
  background-color: #71717a0d;
}

.bg-zinc-500\/50 {
  background-color: #71717a80;
}

.bg-zinc-500\/60 {
  background-color: #71717a99;
}

.bg-zinc-500\/70 {
  background-color: #71717ab3;
}

.bg-zinc-500\/75 {
  background-color: #71717abf;
}

.bg-zinc-500\/80 {
  background-color: #71717acc;
}

.bg-zinc-500\/90 {
  background-color: #71717ae6;
}

.bg-zinc-500\/95 {
  background-color: #71717af2;
}

.bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.bg-zinc-600\/0 {
  background-color: #52525b00;
}

.bg-zinc-600\/10 {
  background-color: #52525b1a;
}

.bg-zinc-600\/100 {
  background-color: #52525b;
}

.bg-zinc-600\/20 {
  background-color: #52525b33;
}

.bg-zinc-600\/25 {
  background-color: #52525b40;
}

.bg-zinc-600\/30 {
  background-color: #52525b4d;
}

.bg-zinc-600\/40 {
  background-color: #52525b66;
}

.bg-zinc-600\/5 {
  background-color: #52525b0d;
}

.bg-zinc-600\/50 {
  background-color: #52525b80;
}

.bg-zinc-600\/60 {
  background-color: #52525b99;
}

.bg-zinc-600\/70 {
  background-color: #52525bb3;
}

.bg-zinc-600\/75 {
  background-color: #52525bbf;
}

.bg-zinc-600\/80 {
  background-color: #52525bcc;
}

.bg-zinc-600\/90 {
  background-color: #52525be6;
}

.bg-zinc-600\/95 {
  background-color: #52525bf2;
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-zinc-700\/0 {
  background-color: #3f3f4600;
}

.bg-zinc-700\/10 {
  background-color: #3f3f461a;
}

.bg-zinc-700\/100 {
  background-color: #3f3f46;
}

.bg-zinc-700\/20 {
  background-color: #3f3f4633;
}

.bg-zinc-700\/25 {
  background-color: #3f3f4640;
}

.bg-zinc-700\/30 {
  background-color: #3f3f464d;
}

.bg-zinc-700\/40 {
  background-color: #3f3f4666;
}

.bg-zinc-700\/5 {
  background-color: #3f3f460d;
}

.bg-zinc-700\/50 {
  background-color: #3f3f4680;
}

.bg-zinc-700\/60 {
  background-color: #3f3f4699;
}

.bg-zinc-700\/70 {
  background-color: #3f3f46b3;
}

.bg-zinc-700\/75 {
  background-color: #3f3f46bf;
}

.bg-zinc-700\/80 {
  background-color: #3f3f46cc;
}

.bg-zinc-700\/90 {
  background-color: #3f3f46e6;
}

.bg-zinc-700\/95 {
  background-color: #3f3f46f2;
}

.bg-zinc-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.bg-zinc-800\/0 {
  background-color: #27272a00;
}

.bg-zinc-800\/10 {
  background-color: #27272a1a;
}

.bg-zinc-800\/100 {
  background-color: #27272a;
}

.bg-zinc-800\/20 {
  background-color: #27272a33;
}

.bg-zinc-800\/25 {
  background-color: #27272a40;
}

.bg-zinc-800\/30 {
  background-color: #27272a4d;
}

.bg-zinc-800\/40 {
  background-color: #27272a66;
}

.bg-zinc-800\/5 {
  background-color: #27272a0d;
}

.bg-zinc-800\/50 {
  background-color: #27272a80;
}

.bg-zinc-800\/60 {
  background-color: #27272a99;
}

.bg-zinc-800\/70 {
  background-color: #27272ab3;
}

.bg-zinc-800\/75 {
  background-color: #27272abf;
}

.bg-zinc-800\/80 {
  background-color: #27272acc;
}

.bg-zinc-800\/90 {
  background-color: #27272ae6;
}

.bg-zinc-800\/95 {
  background-color: #27272af2;
}

.bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.bg-zinc-900\/0 {
  background-color: #18181b00;
}

.bg-zinc-900\/10 {
  background-color: #18181b1a;
}

.bg-zinc-900\/100 {
  background-color: #18181b;
}

.bg-zinc-900\/20 {
  background-color: #18181b33;
}

.bg-zinc-900\/25 {
  background-color: #18181b40;
}

.bg-zinc-900\/30 {
  background-color: #18181b4d;
}

.bg-zinc-900\/40 {
  background-color: #18181b66;
}

.bg-zinc-900\/5 {
  background-color: #18181b0d;
}

.bg-zinc-900\/50 {
  background-color: #18181b80;
}

.bg-zinc-900\/60 {
  background-color: #18181b99;
}

.bg-zinc-900\/70 {
  background-color: #18181bb3;
}

.bg-zinc-900\/75 {
  background-color: #18181bbf;
}

.bg-zinc-900\/80 {
  background-color: #18181bcc;
}

.bg-zinc-900\/90 {
  background-color: #18181be6;
}

.bg-zinc-900\/95 {
  background-color: #18181bf2;
}

.bg-zinc-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.bg-zinc-950\/0 {
  background-color: #09090b00;
}

.bg-zinc-950\/10 {
  background-color: #09090b1a;
}

.bg-zinc-950\/100 {
  background-color: #09090b;
}

.bg-zinc-950\/20 {
  background-color: #09090b33;
}

.bg-zinc-950\/25 {
  background-color: #09090b40;
}

.bg-zinc-950\/30 {
  background-color: #09090b4d;
}

.bg-zinc-950\/40 {
  background-color: #09090b66;
}

.bg-zinc-950\/5 {
  background-color: #09090b0d;
}

.bg-zinc-950\/50 {
  background-color: #09090b80;
}

.bg-zinc-950\/60 {
  background-color: #09090b99;
}

.bg-zinc-950\/70 {
  background-color: #09090bb3;
}

.bg-zinc-950\/75 {
  background-color: #09090bbf;
}

.bg-zinc-950\/80 {
  background-color: #09090bcc;
}

.bg-zinc-950\/90 {
  background-color: #09090be6;
}

.bg-zinc-950\/95 {
  background-color: #09090bf2;
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-opacity-95 {
  --tw-bg-opacity: .95;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.bg-auto {
  background-size: auto;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: 0;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-left-top {
  background-position: 0 0;
}

.bg-right {
  background-position: 100%;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-origin-border {
  background-origin: border-box;
}

.bg-origin-padding {
  background-origin: padding-box;
}

.bg-origin-content {
  background-origin: content-box;
}

.fill-amber-100 {
  fill: #fef3c7;
}

.fill-amber-100\/0 {
  fill: #fef3c700;
}

.fill-amber-100\/10 {
  fill: #fef3c71a;
}

.fill-amber-100\/100 {
  fill: #fef3c7;
}

.fill-amber-100\/20 {
  fill: #fef3c733;
}

.fill-amber-100\/25 {
  fill: #fef3c740;
}

.fill-amber-100\/30 {
  fill: #fef3c74d;
}

.fill-amber-100\/40 {
  fill: #fef3c766;
}

.fill-amber-100\/5 {
  fill: #fef3c70d;
}

.fill-amber-100\/50 {
  fill: #fef3c780;
}

.fill-amber-100\/60 {
  fill: #fef3c799;
}

.fill-amber-100\/70 {
  fill: #fef3c7b3;
}

.fill-amber-100\/75 {
  fill: #fef3c7bf;
}

.fill-amber-100\/80 {
  fill: #fef3c7cc;
}

.fill-amber-100\/90 {
  fill: #fef3c7e6;
}

.fill-amber-100\/95 {
  fill: #fef3c7f2;
}

.fill-amber-200 {
  fill: #fde68a;
}

.fill-amber-200\/0 {
  fill: #fde68a00;
}

.fill-amber-200\/10 {
  fill: #fde68a1a;
}

.fill-amber-200\/100 {
  fill: #fde68a;
}

.fill-amber-200\/20 {
  fill: #fde68a33;
}

.fill-amber-200\/25 {
  fill: #fde68a40;
}

.fill-amber-200\/30 {
  fill: #fde68a4d;
}

.fill-amber-200\/40 {
  fill: #fde68a66;
}

.fill-amber-200\/5 {
  fill: #fde68a0d;
}

.fill-amber-200\/50 {
  fill: #fde68a80;
}

.fill-amber-200\/60 {
  fill: #fde68a99;
}

.fill-amber-200\/70 {
  fill: #fde68ab3;
}

.fill-amber-200\/75 {
  fill: #fde68abf;
}

.fill-amber-200\/80 {
  fill: #fde68acc;
}

.fill-amber-200\/90 {
  fill: #fde68ae6;
}

.fill-amber-200\/95 {
  fill: #fde68af2;
}

.fill-amber-300 {
  fill: #fcd34d;
}

.fill-amber-300\/0 {
  fill: #fcd34d00;
}

.fill-amber-300\/10 {
  fill: #fcd34d1a;
}

.fill-amber-300\/100 {
  fill: #fcd34d;
}

.fill-amber-300\/20 {
  fill: #fcd34d33;
}

.fill-amber-300\/25 {
  fill: #fcd34d40;
}

.fill-amber-300\/30 {
  fill: #fcd34d4d;
}

.fill-amber-300\/40 {
  fill: #fcd34d66;
}

.fill-amber-300\/5 {
  fill: #fcd34d0d;
}

.fill-amber-300\/50 {
  fill: #fcd34d80;
}

.fill-amber-300\/60 {
  fill: #fcd34d99;
}

.fill-amber-300\/70 {
  fill: #fcd34db3;
}

.fill-amber-300\/75 {
  fill: #fcd34dbf;
}

.fill-amber-300\/80 {
  fill: #fcd34dcc;
}

.fill-amber-300\/90 {
  fill: #fcd34de6;
}

.fill-amber-300\/95 {
  fill: #fcd34df2;
}

.fill-amber-400 {
  fill: #fbbf24;
}

.fill-amber-400\/0 {
  fill: #fbbf2400;
}

.fill-amber-400\/10 {
  fill: #fbbf241a;
}

.fill-amber-400\/100 {
  fill: #fbbf24;
}

.fill-amber-400\/20 {
  fill: #fbbf2433;
}

.fill-amber-400\/25 {
  fill: #fbbf2440;
}

.fill-amber-400\/30 {
  fill: #fbbf244d;
}

.fill-amber-400\/40 {
  fill: #fbbf2466;
}

.fill-amber-400\/5 {
  fill: #fbbf240d;
}

.fill-amber-400\/50 {
  fill: #fbbf2480;
}

.fill-amber-400\/60 {
  fill: #fbbf2499;
}

.fill-amber-400\/70 {
  fill: #fbbf24b3;
}

.fill-amber-400\/75 {
  fill: #fbbf24bf;
}

.fill-amber-400\/80 {
  fill: #fbbf24cc;
}

.fill-amber-400\/90 {
  fill: #fbbf24e6;
}

.fill-amber-400\/95 {
  fill: #fbbf24f2;
}

.fill-amber-50 {
  fill: #fffbeb;
}

.fill-amber-50\/0 {
  fill: #fffbeb00;
}

.fill-amber-50\/10 {
  fill: #fffbeb1a;
}

.fill-amber-50\/100 {
  fill: #fffbeb;
}

.fill-amber-50\/20 {
  fill: #fffbeb33;
}

.fill-amber-50\/25 {
  fill: #fffbeb40;
}

.fill-amber-50\/30 {
  fill: #fffbeb4d;
}

.fill-amber-50\/40 {
  fill: #fffbeb66;
}

.fill-amber-50\/5 {
  fill: #fffbeb0d;
}

.fill-amber-50\/50 {
  fill: #fffbeb80;
}

.fill-amber-50\/60 {
  fill: #fffbeb99;
}

.fill-amber-50\/70 {
  fill: #fffbebb3;
}

.fill-amber-50\/75 {
  fill: #fffbebbf;
}

.fill-amber-50\/80 {
  fill: #fffbebcc;
}

.fill-amber-50\/90 {
  fill: #fffbebe6;
}

.fill-amber-50\/95 {
  fill: #fffbebf2;
}

.fill-amber-500 {
  fill: #f59e0b;
}

.fill-amber-500\/0 {
  fill: #f59e0b00;
}

.fill-amber-500\/10 {
  fill: #f59e0b1a;
}

.fill-amber-500\/100 {
  fill: #f59e0b;
}

.fill-amber-500\/20 {
  fill: #f59e0b33;
}

.fill-amber-500\/25 {
  fill: #f59e0b40;
}

.fill-amber-500\/30 {
  fill: #f59e0b4d;
}

.fill-amber-500\/40 {
  fill: #f59e0b66;
}

.fill-amber-500\/5 {
  fill: #f59e0b0d;
}

.fill-amber-500\/50 {
  fill: #f59e0b80;
}

.fill-amber-500\/60 {
  fill: #f59e0b99;
}

.fill-amber-500\/70 {
  fill: #f59e0bb3;
}

.fill-amber-500\/75 {
  fill: #f59e0bbf;
}

.fill-amber-500\/80 {
  fill: #f59e0bcc;
}

.fill-amber-500\/90 {
  fill: #f59e0be6;
}

.fill-amber-500\/95 {
  fill: #f59e0bf2;
}

.fill-amber-600 {
  fill: #d97706;
}

.fill-amber-600\/0 {
  fill: #d9770600;
}

.fill-amber-600\/10 {
  fill: #d977061a;
}

.fill-amber-600\/100 {
  fill: #d97706;
}

.fill-amber-600\/20 {
  fill: #d9770633;
}

.fill-amber-600\/25 {
  fill: #d9770640;
}

.fill-amber-600\/30 {
  fill: #d977064d;
}

.fill-amber-600\/40 {
  fill: #d9770666;
}

.fill-amber-600\/5 {
  fill: #d977060d;
}

.fill-amber-600\/50 {
  fill: #d9770680;
}

.fill-amber-600\/60 {
  fill: #d9770699;
}

.fill-amber-600\/70 {
  fill: #d97706b3;
}

.fill-amber-600\/75 {
  fill: #d97706bf;
}

.fill-amber-600\/80 {
  fill: #d97706cc;
}

.fill-amber-600\/90 {
  fill: #d97706e6;
}

.fill-amber-600\/95 {
  fill: #d97706f2;
}

.fill-amber-700 {
  fill: #b45309;
}

.fill-amber-700\/0 {
  fill: #b4530900;
}

.fill-amber-700\/10 {
  fill: #b453091a;
}

.fill-amber-700\/100 {
  fill: #b45309;
}

.fill-amber-700\/20 {
  fill: #b4530933;
}

.fill-amber-700\/25 {
  fill: #b4530940;
}

.fill-amber-700\/30 {
  fill: #b453094d;
}

.fill-amber-700\/40 {
  fill: #b4530966;
}

.fill-amber-700\/5 {
  fill: #b453090d;
}

.fill-amber-700\/50 {
  fill: #b4530980;
}

.fill-amber-700\/60 {
  fill: #b4530999;
}

.fill-amber-700\/70 {
  fill: #b45309b3;
}

.fill-amber-700\/75 {
  fill: #b45309bf;
}

.fill-amber-700\/80 {
  fill: #b45309cc;
}

.fill-amber-700\/90 {
  fill: #b45309e6;
}

.fill-amber-700\/95 {
  fill: #b45309f2;
}

.fill-amber-800 {
  fill: #92400e;
}

.fill-amber-800\/0 {
  fill: #92400e00;
}

.fill-amber-800\/10 {
  fill: #92400e1a;
}

.fill-amber-800\/100 {
  fill: #92400e;
}

.fill-amber-800\/20 {
  fill: #92400e33;
}

.fill-amber-800\/25 {
  fill: #92400e40;
}

.fill-amber-800\/30 {
  fill: #92400e4d;
}

.fill-amber-800\/40 {
  fill: #92400e66;
}

.fill-amber-800\/5 {
  fill: #92400e0d;
}

.fill-amber-800\/50 {
  fill: #92400e80;
}

.fill-amber-800\/60 {
  fill: #92400e99;
}

.fill-amber-800\/70 {
  fill: #92400eb3;
}

.fill-amber-800\/75 {
  fill: #92400ebf;
}

.fill-amber-800\/80 {
  fill: #92400ecc;
}

.fill-amber-800\/90 {
  fill: #92400ee6;
}

.fill-amber-800\/95 {
  fill: #92400ef2;
}

.fill-amber-900 {
  fill: #78350f;
}

.fill-amber-900\/0 {
  fill: #78350f00;
}

.fill-amber-900\/10 {
  fill: #78350f1a;
}

.fill-amber-900\/100 {
  fill: #78350f;
}

.fill-amber-900\/20 {
  fill: #78350f33;
}

.fill-amber-900\/25 {
  fill: #78350f40;
}

.fill-amber-900\/30 {
  fill: #78350f4d;
}

.fill-amber-900\/40 {
  fill: #78350f66;
}

.fill-amber-900\/5 {
  fill: #78350f0d;
}

.fill-amber-900\/50 {
  fill: #78350f80;
}

.fill-amber-900\/60 {
  fill: #78350f99;
}

.fill-amber-900\/70 {
  fill: #78350fb3;
}

.fill-amber-900\/75 {
  fill: #78350fbf;
}

.fill-amber-900\/80 {
  fill: #78350fcc;
}

.fill-amber-900\/90 {
  fill: #78350fe6;
}

.fill-amber-900\/95 {
  fill: #78350ff2;
}

.fill-amber-950 {
  fill: #451a03;
}

.fill-amber-950\/0 {
  fill: #451a0300;
}

.fill-amber-950\/10 {
  fill: #451a031a;
}

.fill-amber-950\/100 {
  fill: #451a03;
}

.fill-amber-950\/20 {
  fill: #451a0333;
}

.fill-amber-950\/25 {
  fill: #451a0340;
}

.fill-amber-950\/30 {
  fill: #451a034d;
}

.fill-amber-950\/40 {
  fill: #451a0366;
}

.fill-amber-950\/5 {
  fill: #451a030d;
}

.fill-amber-950\/50 {
  fill: #451a0380;
}

.fill-amber-950\/60 {
  fill: #451a0399;
}

.fill-amber-950\/70 {
  fill: #451a03b3;
}

.fill-amber-950\/75 {
  fill: #451a03bf;
}

.fill-amber-950\/80 {
  fill: #451a03cc;
}

.fill-amber-950\/90 {
  fill: #451a03e6;
}

.fill-amber-950\/95 {
  fill: #451a03f2;
}

.fill-black {
  fill: #000;
}

.fill-black\/0 {
  fill: #0000;
}

.fill-black\/10 {
  fill: #0000001a;
}

.fill-black\/100 {
  fill: #000;
}

.fill-black\/20 {
  fill: #0003;
}

.fill-black\/25 {
  fill: #00000040;
}

.fill-black\/30 {
  fill: #0000004d;
}

.fill-black\/40 {
  fill: #0006;
}

.fill-black\/5 {
  fill: #0000000d;
}

.fill-black\/50 {
  fill: #00000080;
}

.fill-black\/60 {
  fill: #0009;
}

.fill-black\/70 {
  fill: #000000b3;
}

.fill-black\/75 {
  fill: #000000bf;
}

.fill-black\/80 {
  fill: #000c;
}

.fill-black\/90 {
  fill: #000000e6;
}

.fill-black\/95 {
  fill: #000000f2;
}

.fill-blue-100 {
  fill: #dbeafe;
}

.fill-blue-100\/0 {
  fill: #dbeafe00;
}

.fill-blue-100\/10 {
  fill: #dbeafe1a;
}

.fill-blue-100\/100 {
  fill: #dbeafe;
}

.fill-blue-100\/20 {
  fill: #dbeafe33;
}

.fill-blue-100\/25 {
  fill: #dbeafe40;
}

.fill-blue-100\/30 {
  fill: #dbeafe4d;
}

.fill-blue-100\/40 {
  fill: #dbeafe66;
}

.fill-blue-100\/5 {
  fill: #dbeafe0d;
}

.fill-blue-100\/50 {
  fill: #dbeafe80;
}

.fill-blue-100\/60 {
  fill: #dbeafe99;
}

.fill-blue-100\/70 {
  fill: #dbeafeb3;
}

.fill-blue-100\/75 {
  fill: #dbeafebf;
}

.fill-blue-100\/80 {
  fill: #dbeafecc;
}

.fill-blue-100\/90 {
  fill: #dbeafee6;
}

.fill-blue-100\/95 {
  fill: #dbeafef2;
}

.fill-blue-200 {
  fill: #bfdbfe;
}

.fill-blue-200\/0 {
  fill: #bfdbfe00;
}

.fill-blue-200\/10 {
  fill: #bfdbfe1a;
}

.fill-blue-200\/100 {
  fill: #bfdbfe;
}

.fill-blue-200\/20 {
  fill: #bfdbfe33;
}

.fill-blue-200\/25 {
  fill: #bfdbfe40;
}

.fill-blue-200\/30 {
  fill: #bfdbfe4d;
}

.fill-blue-200\/40 {
  fill: #bfdbfe66;
}

.fill-blue-200\/5 {
  fill: #bfdbfe0d;
}

.fill-blue-200\/50 {
  fill: #bfdbfe80;
}

.fill-blue-200\/60 {
  fill: #bfdbfe99;
}

.fill-blue-200\/70 {
  fill: #bfdbfeb3;
}

.fill-blue-200\/75 {
  fill: #bfdbfebf;
}

.fill-blue-200\/80 {
  fill: #bfdbfecc;
}

.fill-blue-200\/90 {
  fill: #bfdbfee6;
}

.fill-blue-200\/95 {
  fill: #bfdbfef2;
}

.fill-blue-300 {
  fill: #93c5fd;
}

.fill-blue-300\/0 {
  fill: #93c5fd00;
}

.fill-blue-300\/10 {
  fill: #93c5fd1a;
}

.fill-blue-300\/100 {
  fill: #93c5fd;
}

.fill-blue-300\/20 {
  fill: #93c5fd33;
}

.fill-blue-300\/25 {
  fill: #93c5fd40;
}

.fill-blue-300\/30 {
  fill: #93c5fd4d;
}

.fill-blue-300\/40 {
  fill: #93c5fd66;
}

.fill-blue-300\/5 {
  fill: #93c5fd0d;
}

.fill-blue-300\/50 {
  fill: #93c5fd80;
}

.fill-blue-300\/60 {
  fill: #93c5fd99;
}

.fill-blue-300\/70 {
  fill: #93c5fdb3;
}

.fill-blue-300\/75 {
  fill: #93c5fdbf;
}

.fill-blue-300\/80 {
  fill: #93c5fdcc;
}

.fill-blue-300\/90 {
  fill: #93c5fde6;
}

.fill-blue-300\/95 {
  fill: #93c5fdf2;
}

.fill-blue-400 {
  fill: #60a5fa;
}

.fill-blue-400\/0 {
  fill: #60a5fa00;
}

.fill-blue-400\/10 {
  fill: #60a5fa1a;
}

.fill-blue-400\/100 {
  fill: #60a5fa;
}

.fill-blue-400\/20 {
  fill: #60a5fa33;
}

.fill-blue-400\/25 {
  fill: #60a5fa40;
}

.fill-blue-400\/30 {
  fill: #60a5fa4d;
}

.fill-blue-400\/40 {
  fill: #60a5fa66;
}

.fill-blue-400\/5 {
  fill: #60a5fa0d;
}

.fill-blue-400\/50 {
  fill: #60a5fa80;
}

.fill-blue-400\/60 {
  fill: #60a5fa99;
}

.fill-blue-400\/70 {
  fill: #60a5fab3;
}

.fill-blue-400\/75 {
  fill: #60a5fabf;
}

.fill-blue-400\/80 {
  fill: #60a5facc;
}

.fill-blue-400\/90 {
  fill: #60a5fae6;
}

.fill-blue-400\/95 {
  fill: #60a5faf2;
}

.fill-blue-50 {
  fill: #eff6ff;
}

.fill-blue-50\/0 {
  fill: #eff6ff00;
}

.fill-blue-50\/10 {
  fill: #eff6ff1a;
}

.fill-blue-50\/100 {
  fill: #eff6ff;
}

.fill-blue-50\/20 {
  fill: #eff6ff33;
}

.fill-blue-50\/25 {
  fill: #eff6ff40;
}

.fill-blue-50\/30 {
  fill: #eff6ff4d;
}

.fill-blue-50\/40 {
  fill: #eff6ff66;
}

.fill-blue-50\/5 {
  fill: #eff6ff0d;
}

.fill-blue-50\/50 {
  fill: #eff6ff80;
}

.fill-blue-50\/60 {
  fill: #eff6ff99;
}

.fill-blue-50\/70 {
  fill: #eff6ffb3;
}

.fill-blue-50\/75 {
  fill: #eff6ffbf;
}

.fill-blue-50\/80 {
  fill: #eff6ffcc;
}

.fill-blue-50\/90 {
  fill: #eff6ffe6;
}

.fill-blue-50\/95 {
  fill: #eff6fff2;
}

.fill-blue-500 {
  fill: #3b82f6;
}

.fill-blue-500\/0 {
  fill: #3b82f600;
}

.fill-blue-500\/10 {
  fill: #3b82f61a;
}

.fill-blue-500\/100 {
  fill: #3b82f6;
}

.fill-blue-500\/20 {
  fill: #3b82f633;
}

.fill-blue-500\/25 {
  fill: #3b82f640;
}

.fill-blue-500\/30 {
  fill: #3b82f64d;
}

.fill-blue-500\/40 {
  fill: #3b82f666;
}

.fill-blue-500\/5 {
  fill: #3b82f60d;
}

.fill-blue-500\/50 {
  fill: #3b82f680;
}

.fill-blue-500\/60 {
  fill: #3b82f699;
}

.fill-blue-500\/70 {
  fill: #3b82f6b3;
}

.fill-blue-500\/75 {
  fill: #3b82f6bf;
}

.fill-blue-500\/80 {
  fill: #3b82f6cc;
}

.fill-blue-500\/90 {
  fill: #3b82f6e6;
}

.fill-blue-500\/95 {
  fill: #3b82f6f2;
}

.fill-blue-600 {
  fill: #2563eb;
}

.fill-blue-600\/0 {
  fill: #2563eb00;
}

.fill-blue-600\/10 {
  fill: #2563eb1a;
}

.fill-blue-600\/100 {
  fill: #2563eb;
}

.fill-blue-600\/20 {
  fill: #2563eb33;
}

.fill-blue-600\/25 {
  fill: #2563eb40;
}

.fill-blue-600\/30 {
  fill: #2563eb4d;
}

.fill-blue-600\/40 {
  fill: #2563eb66;
}

.fill-blue-600\/5 {
  fill: #2563eb0d;
}

.fill-blue-600\/50 {
  fill: #2563eb80;
}

.fill-blue-600\/60 {
  fill: #2563eb99;
}

.fill-blue-600\/70 {
  fill: #2563ebb3;
}

.fill-blue-600\/75 {
  fill: #2563ebbf;
}

.fill-blue-600\/80 {
  fill: #2563ebcc;
}

.fill-blue-600\/90 {
  fill: #2563ebe6;
}

.fill-blue-600\/95 {
  fill: #2563ebf2;
}

.fill-blue-700 {
  fill: #1d4ed8;
}

.fill-blue-700\/0 {
  fill: #1d4ed800;
}

.fill-blue-700\/10 {
  fill: #1d4ed81a;
}

.fill-blue-700\/100 {
  fill: #1d4ed8;
}

.fill-blue-700\/20 {
  fill: #1d4ed833;
}

.fill-blue-700\/25 {
  fill: #1d4ed840;
}

.fill-blue-700\/30 {
  fill: #1d4ed84d;
}

.fill-blue-700\/40 {
  fill: #1d4ed866;
}

.fill-blue-700\/5 {
  fill: #1d4ed80d;
}

.fill-blue-700\/50 {
  fill: #1d4ed880;
}

.fill-blue-700\/60 {
  fill: #1d4ed899;
}

.fill-blue-700\/70 {
  fill: #1d4ed8b3;
}

.fill-blue-700\/75 {
  fill: #1d4ed8bf;
}

.fill-blue-700\/80 {
  fill: #1d4ed8cc;
}

.fill-blue-700\/90 {
  fill: #1d4ed8e6;
}

.fill-blue-700\/95 {
  fill: #1d4ed8f2;
}

.fill-blue-800 {
  fill: #1e40af;
}

.fill-blue-800\/0 {
  fill: #1e40af00;
}

.fill-blue-800\/10 {
  fill: #1e40af1a;
}

.fill-blue-800\/100 {
  fill: #1e40af;
}

.fill-blue-800\/20 {
  fill: #1e40af33;
}

.fill-blue-800\/25 {
  fill: #1e40af40;
}

.fill-blue-800\/30 {
  fill: #1e40af4d;
}

.fill-blue-800\/40 {
  fill: #1e40af66;
}

.fill-blue-800\/5 {
  fill: #1e40af0d;
}

.fill-blue-800\/50 {
  fill: #1e40af80;
}

.fill-blue-800\/60 {
  fill: #1e40af99;
}

.fill-blue-800\/70 {
  fill: #1e40afb3;
}

.fill-blue-800\/75 {
  fill: #1e40afbf;
}

.fill-blue-800\/80 {
  fill: #1e40afcc;
}

.fill-blue-800\/90 {
  fill: #1e40afe6;
}

.fill-blue-800\/95 {
  fill: #1e40aff2;
}

.fill-blue-900 {
  fill: #1e3a8a;
}

.fill-blue-900\/0 {
  fill: #1e3a8a00;
}

.fill-blue-900\/10 {
  fill: #1e3a8a1a;
}

.fill-blue-900\/100 {
  fill: #1e3a8a;
}

.fill-blue-900\/20 {
  fill: #1e3a8a33;
}

.fill-blue-900\/25 {
  fill: #1e3a8a40;
}

.fill-blue-900\/30 {
  fill: #1e3a8a4d;
}

.fill-blue-900\/40 {
  fill: #1e3a8a66;
}

.fill-blue-900\/5 {
  fill: #1e3a8a0d;
}

.fill-blue-900\/50 {
  fill: #1e3a8a80;
}

.fill-blue-900\/60 {
  fill: #1e3a8a99;
}

.fill-blue-900\/70 {
  fill: #1e3a8ab3;
}

.fill-blue-900\/75 {
  fill: #1e3a8abf;
}

.fill-blue-900\/80 {
  fill: #1e3a8acc;
}

.fill-blue-900\/90 {
  fill: #1e3a8ae6;
}

.fill-blue-900\/95 {
  fill: #1e3a8af2;
}

.fill-blue-950 {
  fill: #172554;
}

.fill-blue-950\/0 {
  fill: #17255400;
}

.fill-blue-950\/10 {
  fill: #1725541a;
}

.fill-blue-950\/100 {
  fill: #172554;
}

.fill-blue-950\/20 {
  fill: #17255433;
}

.fill-blue-950\/25 {
  fill: #17255440;
}

.fill-blue-950\/30 {
  fill: #1725544d;
}

.fill-blue-950\/40 {
  fill: #17255466;
}

.fill-blue-950\/5 {
  fill: #1725540d;
}

.fill-blue-950\/50 {
  fill: #17255480;
}

.fill-blue-950\/60 {
  fill: #17255499;
}

.fill-blue-950\/70 {
  fill: #172554b3;
}

.fill-blue-950\/75 {
  fill: #172554bf;
}

.fill-blue-950\/80 {
  fill: #172554cc;
}

.fill-blue-950\/90 {
  fill: #172554e6;
}

.fill-blue-950\/95 {
  fill: #172554f2;
}

.fill-current {
  fill: currentColor;
}

.fill-cyan-100 {
  fill: #cffafe;
}

.fill-cyan-100\/0 {
  fill: #cffafe00;
}

.fill-cyan-100\/10 {
  fill: #cffafe1a;
}

.fill-cyan-100\/100 {
  fill: #cffafe;
}

.fill-cyan-100\/20 {
  fill: #cffafe33;
}

.fill-cyan-100\/25 {
  fill: #cffafe40;
}

.fill-cyan-100\/30 {
  fill: #cffafe4d;
}

.fill-cyan-100\/40 {
  fill: #cffafe66;
}

.fill-cyan-100\/5 {
  fill: #cffafe0d;
}

.fill-cyan-100\/50 {
  fill: #cffafe80;
}

.fill-cyan-100\/60 {
  fill: #cffafe99;
}

.fill-cyan-100\/70 {
  fill: #cffafeb3;
}

.fill-cyan-100\/75 {
  fill: #cffafebf;
}

.fill-cyan-100\/80 {
  fill: #cffafecc;
}

.fill-cyan-100\/90 {
  fill: #cffafee6;
}

.fill-cyan-100\/95 {
  fill: #cffafef2;
}

.fill-cyan-200 {
  fill: #a5f3fc;
}

.fill-cyan-200\/0 {
  fill: #a5f3fc00;
}

.fill-cyan-200\/10 {
  fill: #a5f3fc1a;
}

.fill-cyan-200\/100 {
  fill: #a5f3fc;
}

.fill-cyan-200\/20 {
  fill: #a5f3fc33;
}

.fill-cyan-200\/25 {
  fill: #a5f3fc40;
}

.fill-cyan-200\/30 {
  fill: #a5f3fc4d;
}

.fill-cyan-200\/40 {
  fill: #a5f3fc66;
}

.fill-cyan-200\/5 {
  fill: #a5f3fc0d;
}

.fill-cyan-200\/50 {
  fill: #a5f3fc80;
}

.fill-cyan-200\/60 {
  fill: #a5f3fc99;
}

.fill-cyan-200\/70 {
  fill: #a5f3fcb3;
}

.fill-cyan-200\/75 {
  fill: #a5f3fcbf;
}

.fill-cyan-200\/80 {
  fill: #a5f3fccc;
}

.fill-cyan-200\/90 {
  fill: #a5f3fce6;
}

.fill-cyan-200\/95 {
  fill: #a5f3fcf2;
}

.fill-cyan-300 {
  fill: #67e8f9;
}

.fill-cyan-300\/0 {
  fill: #67e8f900;
}

.fill-cyan-300\/10 {
  fill: #67e8f91a;
}

.fill-cyan-300\/100 {
  fill: #67e8f9;
}

.fill-cyan-300\/20 {
  fill: #67e8f933;
}

.fill-cyan-300\/25 {
  fill: #67e8f940;
}

.fill-cyan-300\/30 {
  fill: #67e8f94d;
}

.fill-cyan-300\/40 {
  fill: #67e8f966;
}

.fill-cyan-300\/5 {
  fill: #67e8f90d;
}

.fill-cyan-300\/50 {
  fill: #67e8f980;
}

.fill-cyan-300\/60 {
  fill: #67e8f999;
}

.fill-cyan-300\/70 {
  fill: #67e8f9b3;
}

.fill-cyan-300\/75 {
  fill: #67e8f9bf;
}

.fill-cyan-300\/80 {
  fill: #67e8f9cc;
}

.fill-cyan-300\/90 {
  fill: #67e8f9e6;
}

.fill-cyan-300\/95 {
  fill: #67e8f9f2;
}

.fill-cyan-400 {
  fill: #22d3ee;
}

.fill-cyan-400\/0 {
  fill: #22d3ee00;
}

.fill-cyan-400\/10 {
  fill: #22d3ee1a;
}

.fill-cyan-400\/100 {
  fill: #22d3ee;
}

.fill-cyan-400\/20 {
  fill: #22d3ee33;
}

.fill-cyan-400\/25 {
  fill: #22d3ee40;
}

.fill-cyan-400\/30 {
  fill: #22d3ee4d;
}

.fill-cyan-400\/40 {
  fill: #22d3ee66;
}

.fill-cyan-400\/5 {
  fill: #22d3ee0d;
}

.fill-cyan-400\/50 {
  fill: #22d3ee80;
}

.fill-cyan-400\/60 {
  fill: #22d3ee99;
}

.fill-cyan-400\/70 {
  fill: #22d3eeb3;
}

.fill-cyan-400\/75 {
  fill: #22d3eebf;
}

.fill-cyan-400\/80 {
  fill: #22d3eecc;
}

.fill-cyan-400\/90 {
  fill: #22d3eee6;
}

.fill-cyan-400\/95 {
  fill: #22d3eef2;
}

.fill-cyan-50 {
  fill: #ecfeff;
}

.fill-cyan-50\/0 {
  fill: #ecfeff00;
}

.fill-cyan-50\/10 {
  fill: #ecfeff1a;
}

.fill-cyan-50\/100 {
  fill: #ecfeff;
}

.fill-cyan-50\/20 {
  fill: #ecfeff33;
}

.fill-cyan-50\/25 {
  fill: #ecfeff40;
}

.fill-cyan-50\/30 {
  fill: #ecfeff4d;
}

.fill-cyan-50\/40 {
  fill: #ecfeff66;
}

.fill-cyan-50\/5 {
  fill: #ecfeff0d;
}

.fill-cyan-50\/50 {
  fill: #ecfeff80;
}

.fill-cyan-50\/60 {
  fill: #ecfeff99;
}

.fill-cyan-50\/70 {
  fill: #ecfeffb3;
}

.fill-cyan-50\/75 {
  fill: #ecfeffbf;
}

.fill-cyan-50\/80 {
  fill: #ecfeffcc;
}

.fill-cyan-50\/90 {
  fill: #ecfeffe6;
}

.fill-cyan-50\/95 {
  fill: #ecfefff2;
}

.fill-cyan-500 {
  fill: #06b6d4;
}

.fill-cyan-500\/0 {
  fill: #06b6d400;
}

.fill-cyan-500\/10 {
  fill: #06b6d41a;
}

.fill-cyan-500\/100 {
  fill: #06b6d4;
}

.fill-cyan-500\/20 {
  fill: #06b6d433;
}

.fill-cyan-500\/25 {
  fill: #06b6d440;
}

.fill-cyan-500\/30 {
  fill: #06b6d44d;
}

.fill-cyan-500\/40 {
  fill: #06b6d466;
}

.fill-cyan-500\/5 {
  fill: #06b6d40d;
}

.fill-cyan-500\/50 {
  fill: #06b6d480;
}

.fill-cyan-500\/60 {
  fill: #06b6d499;
}

.fill-cyan-500\/70 {
  fill: #06b6d4b3;
}

.fill-cyan-500\/75 {
  fill: #06b6d4bf;
}

.fill-cyan-500\/80 {
  fill: #06b6d4cc;
}

.fill-cyan-500\/90 {
  fill: #06b6d4e6;
}

.fill-cyan-500\/95 {
  fill: #06b6d4f2;
}

.fill-cyan-600 {
  fill: #0891b2;
}

.fill-cyan-600\/0 {
  fill: #0891b200;
}

.fill-cyan-600\/10 {
  fill: #0891b21a;
}

.fill-cyan-600\/100 {
  fill: #0891b2;
}

.fill-cyan-600\/20 {
  fill: #0891b233;
}

.fill-cyan-600\/25 {
  fill: #0891b240;
}

.fill-cyan-600\/30 {
  fill: #0891b24d;
}

.fill-cyan-600\/40 {
  fill: #0891b266;
}

.fill-cyan-600\/5 {
  fill: #0891b20d;
}

.fill-cyan-600\/50 {
  fill: #0891b280;
}

.fill-cyan-600\/60 {
  fill: #0891b299;
}

.fill-cyan-600\/70 {
  fill: #0891b2b3;
}

.fill-cyan-600\/75 {
  fill: #0891b2bf;
}

.fill-cyan-600\/80 {
  fill: #0891b2cc;
}

.fill-cyan-600\/90 {
  fill: #0891b2e6;
}

.fill-cyan-600\/95 {
  fill: #0891b2f2;
}

.fill-cyan-700 {
  fill: #0e7490;
}

.fill-cyan-700\/0 {
  fill: #0e749000;
}

.fill-cyan-700\/10 {
  fill: #0e74901a;
}

.fill-cyan-700\/100 {
  fill: #0e7490;
}

.fill-cyan-700\/20 {
  fill: #0e749033;
}

.fill-cyan-700\/25 {
  fill: #0e749040;
}

.fill-cyan-700\/30 {
  fill: #0e74904d;
}

.fill-cyan-700\/40 {
  fill: #0e749066;
}

.fill-cyan-700\/5 {
  fill: #0e74900d;
}

.fill-cyan-700\/50 {
  fill: #0e749080;
}

.fill-cyan-700\/60 {
  fill: #0e749099;
}

.fill-cyan-700\/70 {
  fill: #0e7490b3;
}

.fill-cyan-700\/75 {
  fill: #0e7490bf;
}

.fill-cyan-700\/80 {
  fill: #0e7490cc;
}

.fill-cyan-700\/90 {
  fill: #0e7490e6;
}

.fill-cyan-700\/95 {
  fill: #0e7490f2;
}

.fill-cyan-800 {
  fill: #155e75;
}

.fill-cyan-800\/0 {
  fill: #155e7500;
}

.fill-cyan-800\/10 {
  fill: #155e751a;
}

.fill-cyan-800\/100 {
  fill: #155e75;
}

.fill-cyan-800\/20 {
  fill: #155e7533;
}

.fill-cyan-800\/25 {
  fill: #155e7540;
}

.fill-cyan-800\/30 {
  fill: #155e754d;
}

.fill-cyan-800\/40 {
  fill: #155e7566;
}

.fill-cyan-800\/5 {
  fill: #155e750d;
}

.fill-cyan-800\/50 {
  fill: #155e7580;
}

.fill-cyan-800\/60 {
  fill: #155e7599;
}

.fill-cyan-800\/70 {
  fill: #155e75b3;
}

.fill-cyan-800\/75 {
  fill: #155e75bf;
}

.fill-cyan-800\/80 {
  fill: #155e75cc;
}

.fill-cyan-800\/90 {
  fill: #155e75e6;
}

.fill-cyan-800\/95 {
  fill: #155e75f2;
}

.fill-cyan-900 {
  fill: #164e63;
}

.fill-cyan-900\/0 {
  fill: #164e6300;
}

.fill-cyan-900\/10 {
  fill: #164e631a;
}

.fill-cyan-900\/100 {
  fill: #164e63;
}

.fill-cyan-900\/20 {
  fill: #164e6333;
}

.fill-cyan-900\/25 {
  fill: #164e6340;
}

.fill-cyan-900\/30 {
  fill: #164e634d;
}

.fill-cyan-900\/40 {
  fill: #164e6366;
}

.fill-cyan-900\/5 {
  fill: #164e630d;
}

.fill-cyan-900\/50 {
  fill: #164e6380;
}

.fill-cyan-900\/60 {
  fill: #164e6399;
}

.fill-cyan-900\/70 {
  fill: #164e63b3;
}

.fill-cyan-900\/75 {
  fill: #164e63bf;
}

.fill-cyan-900\/80 {
  fill: #164e63cc;
}

.fill-cyan-900\/90 {
  fill: #164e63e6;
}

.fill-cyan-900\/95 {
  fill: #164e63f2;
}

.fill-cyan-950 {
  fill: #083344;
}

.fill-cyan-950\/0 {
  fill: #08334400;
}

.fill-cyan-950\/10 {
  fill: #0833441a;
}

.fill-cyan-950\/100 {
  fill: #083344;
}

.fill-cyan-950\/20 {
  fill: #08334433;
}

.fill-cyan-950\/25 {
  fill: #08334440;
}

.fill-cyan-950\/30 {
  fill: #0833444d;
}

.fill-cyan-950\/40 {
  fill: #08334466;
}

.fill-cyan-950\/5 {
  fill: #0833440d;
}

.fill-cyan-950\/50 {
  fill: #08334480;
}

.fill-cyan-950\/60 {
  fill: #08334499;
}

.fill-cyan-950\/70 {
  fill: #083344b3;
}

.fill-cyan-950\/75 {
  fill: #083344bf;
}

.fill-cyan-950\/80 {
  fill: #083344cc;
}

.fill-cyan-950\/90 {
  fill: #083344e6;
}

.fill-cyan-950\/95 {
  fill: #083344f2;
}

.fill-emerald-100 {
  fill: #d1fae5;
}

.fill-emerald-100\/0 {
  fill: #d1fae500;
}

.fill-emerald-100\/10 {
  fill: #d1fae51a;
}

.fill-emerald-100\/100 {
  fill: #d1fae5;
}

.fill-emerald-100\/20 {
  fill: #d1fae533;
}

.fill-emerald-100\/25 {
  fill: #d1fae540;
}

.fill-emerald-100\/30 {
  fill: #d1fae54d;
}

.fill-emerald-100\/40 {
  fill: #d1fae566;
}

.fill-emerald-100\/5 {
  fill: #d1fae50d;
}

.fill-emerald-100\/50 {
  fill: #d1fae580;
}

.fill-emerald-100\/60 {
  fill: #d1fae599;
}

.fill-emerald-100\/70 {
  fill: #d1fae5b3;
}

.fill-emerald-100\/75 {
  fill: #d1fae5bf;
}

.fill-emerald-100\/80 {
  fill: #d1fae5cc;
}

.fill-emerald-100\/90 {
  fill: #d1fae5e6;
}

.fill-emerald-100\/95 {
  fill: #d1fae5f2;
}

.fill-emerald-200 {
  fill: #a7f3d0;
}

.fill-emerald-200\/0 {
  fill: #a7f3d000;
}

.fill-emerald-200\/10 {
  fill: #a7f3d01a;
}

.fill-emerald-200\/100 {
  fill: #a7f3d0;
}

.fill-emerald-200\/20 {
  fill: #a7f3d033;
}

.fill-emerald-200\/25 {
  fill: #a7f3d040;
}

.fill-emerald-200\/30 {
  fill: #a7f3d04d;
}

.fill-emerald-200\/40 {
  fill: #a7f3d066;
}

.fill-emerald-200\/5 {
  fill: #a7f3d00d;
}

.fill-emerald-200\/50 {
  fill: #a7f3d080;
}

.fill-emerald-200\/60 {
  fill: #a7f3d099;
}

.fill-emerald-200\/70 {
  fill: #a7f3d0b3;
}

.fill-emerald-200\/75 {
  fill: #a7f3d0bf;
}

.fill-emerald-200\/80 {
  fill: #a7f3d0cc;
}

.fill-emerald-200\/90 {
  fill: #a7f3d0e6;
}

.fill-emerald-200\/95 {
  fill: #a7f3d0f2;
}

.fill-emerald-300 {
  fill: #6ee7b7;
}

.fill-emerald-300\/0 {
  fill: #6ee7b700;
}

.fill-emerald-300\/10 {
  fill: #6ee7b71a;
}

.fill-emerald-300\/100 {
  fill: #6ee7b7;
}

.fill-emerald-300\/20 {
  fill: #6ee7b733;
}

.fill-emerald-300\/25 {
  fill: #6ee7b740;
}

.fill-emerald-300\/30 {
  fill: #6ee7b74d;
}

.fill-emerald-300\/40 {
  fill: #6ee7b766;
}

.fill-emerald-300\/5 {
  fill: #6ee7b70d;
}

.fill-emerald-300\/50 {
  fill: #6ee7b780;
}

.fill-emerald-300\/60 {
  fill: #6ee7b799;
}

.fill-emerald-300\/70 {
  fill: #6ee7b7b3;
}

.fill-emerald-300\/75 {
  fill: #6ee7b7bf;
}

.fill-emerald-300\/80 {
  fill: #6ee7b7cc;
}

.fill-emerald-300\/90 {
  fill: #6ee7b7e6;
}

.fill-emerald-300\/95 {
  fill: #6ee7b7f2;
}

.fill-emerald-400 {
  fill: #34d399;
}

.fill-emerald-400\/0 {
  fill: #34d39900;
}

.fill-emerald-400\/10 {
  fill: #34d3991a;
}

.fill-emerald-400\/100 {
  fill: #34d399;
}

.fill-emerald-400\/20 {
  fill: #34d39933;
}

.fill-emerald-400\/25 {
  fill: #34d39940;
}

.fill-emerald-400\/30 {
  fill: #34d3994d;
}

.fill-emerald-400\/40 {
  fill: #34d39966;
}

.fill-emerald-400\/5 {
  fill: #34d3990d;
}

.fill-emerald-400\/50 {
  fill: #34d39980;
}

.fill-emerald-400\/60 {
  fill: #34d39999;
}

.fill-emerald-400\/70 {
  fill: #34d399b3;
}

.fill-emerald-400\/75 {
  fill: #34d399bf;
}

.fill-emerald-400\/80 {
  fill: #34d399cc;
}

.fill-emerald-400\/90 {
  fill: #34d399e6;
}

.fill-emerald-400\/95 {
  fill: #34d399f2;
}

.fill-emerald-50 {
  fill: #ecfdf5;
}

.fill-emerald-50\/0 {
  fill: #ecfdf500;
}

.fill-emerald-50\/10 {
  fill: #ecfdf51a;
}

.fill-emerald-50\/100 {
  fill: #ecfdf5;
}

.fill-emerald-50\/20 {
  fill: #ecfdf533;
}

.fill-emerald-50\/25 {
  fill: #ecfdf540;
}

.fill-emerald-50\/30 {
  fill: #ecfdf54d;
}

.fill-emerald-50\/40 {
  fill: #ecfdf566;
}

.fill-emerald-50\/5 {
  fill: #ecfdf50d;
}

.fill-emerald-50\/50 {
  fill: #ecfdf580;
}

.fill-emerald-50\/60 {
  fill: #ecfdf599;
}

.fill-emerald-50\/70 {
  fill: #ecfdf5b3;
}

.fill-emerald-50\/75 {
  fill: #ecfdf5bf;
}

.fill-emerald-50\/80 {
  fill: #ecfdf5cc;
}

.fill-emerald-50\/90 {
  fill: #ecfdf5e6;
}

.fill-emerald-50\/95 {
  fill: #ecfdf5f2;
}

.fill-emerald-500 {
  fill: #10b981;
}

.fill-emerald-500\/0 {
  fill: #10b98100;
}

.fill-emerald-500\/10 {
  fill: #10b9811a;
}

.fill-emerald-500\/100 {
  fill: #10b981;
}

.fill-emerald-500\/20 {
  fill: #10b98133;
}

.fill-emerald-500\/25 {
  fill: #10b98140;
}

.fill-emerald-500\/30 {
  fill: #10b9814d;
}

.fill-emerald-500\/40 {
  fill: #10b98166;
}

.fill-emerald-500\/5 {
  fill: #10b9810d;
}

.fill-emerald-500\/50 {
  fill: #10b98180;
}

.fill-emerald-500\/60 {
  fill: #10b98199;
}

.fill-emerald-500\/70 {
  fill: #10b981b3;
}

.fill-emerald-500\/75 {
  fill: #10b981bf;
}

.fill-emerald-500\/80 {
  fill: #10b981cc;
}

.fill-emerald-500\/90 {
  fill: #10b981e6;
}

.fill-emerald-500\/95 {
  fill: #10b981f2;
}

.fill-emerald-600 {
  fill: #059669;
}

.fill-emerald-600\/0 {
  fill: #05966900;
}

.fill-emerald-600\/10 {
  fill: #0596691a;
}

.fill-emerald-600\/100 {
  fill: #059669;
}

.fill-emerald-600\/20 {
  fill: #05966933;
}

.fill-emerald-600\/25 {
  fill: #05966940;
}

.fill-emerald-600\/30 {
  fill: #0596694d;
}

.fill-emerald-600\/40 {
  fill: #05966966;
}

.fill-emerald-600\/5 {
  fill: #0596690d;
}

.fill-emerald-600\/50 {
  fill: #05966980;
}

.fill-emerald-600\/60 {
  fill: #05966999;
}

.fill-emerald-600\/70 {
  fill: #059669b3;
}

.fill-emerald-600\/75 {
  fill: #059669bf;
}

.fill-emerald-600\/80 {
  fill: #059669cc;
}

.fill-emerald-600\/90 {
  fill: #059669e6;
}

.fill-emerald-600\/95 {
  fill: #059669f2;
}

.fill-emerald-700 {
  fill: #047857;
}

.fill-emerald-700\/0 {
  fill: #04785700;
}

.fill-emerald-700\/10 {
  fill: #0478571a;
}

.fill-emerald-700\/100 {
  fill: #047857;
}

.fill-emerald-700\/20 {
  fill: #04785733;
}

.fill-emerald-700\/25 {
  fill: #04785740;
}

.fill-emerald-700\/30 {
  fill: #0478574d;
}

.fill-emerald-700\/40 {
  fill: #04785766;
}

.fill-emerald-700\/5 {
  fill: #0478570d;
}

.fill-emerald-700\/50 {
  fill: #04785780;
}

.fill-emerald-700\/60 {
  fill: #04785799;
}

.fill-emerald-700\/70 {
  fill: #047857b3;
}

.fill-emerald-700\/75 {
  fill: #047857bf;
}

.fill-emerald-700\/80 {
  fill: #047857cc;
}

.fill-emerald-700\/90 {
  fill: #047857e6;
}

.fill-emerald-700\/95 {
  fill: #047857f2;
}

.fill-emerald-800 {
  fill: #065f46;
}

.fill-emerald-800\/0 {
  fill: #065f4600;
}

.fill-emerald-800\/10 {
  fill: #065f461a;
}

.fill-emerald-800\/100 {
  fill: #065f46;
}

.fill-emerald-800\/20 {
  fill: #065f4633;
}

.fill-emerald-800\/25 {
  fill: #065f4640;
}

.fill-emerald-800\/30 {
  fill: #065f464d;
}

.fill-emerald-800\/40 {
  fill: #065f4666;
}

.fill-emerald-800\/5 {
  fill: #065f460d;
}

.fill-emerald-800\/50 {
  fill: #065f4680;
}

.fill-emerald-800\/60 {
  fill: #065f4699;
}

.fill-emerald-800\/70 {
  fill: #065f46b3;
}

.fill-emerald-800\/75 {
  fill: #065f46bf;
}

.fill-emerald-800\/80 {
  fill: #065f46cc;
}

.fill-emerald-800\/90 {
  fill: #065f46e6;
}

.fill-emerald-800\/95 {
  fill: #065f46f2;
}

.fill-emerald-900 {
  fill: #064e3b;
}

.fill-emerald-900\/0 {
  fill: #064e3b00;
}

.fill-emerald-900\/10 {
  fill: #064e3b1a;
}

.fill-emerald-900\/100 {
  fill: #064e3b;
}

.fill-emerald-900\/20 {
  fill: #064e3b33;
}

.fill-emerald-900\/25 {
  fill: #064e3b40;
}

.fill-emerald-900\/30 {
  fill: #064e3b4d;
}

.fill-emerald-900\/40 {
  fill: #064e3b66;
}

.fill-emerald-900\/5 {
  fill: #064e3b0d;
}

.fill-emerald-900\/50 {
  fill: #064e3b80;
}

.fill-emerald-900\/60 {
  fill: #064e3b99;
}

.fill-emerald-900\/70 {
  fill: #064e3bb3;
}

.fill-emerald-900\/75 {
  fill: #064e3bbf;
}

.fill-emerald-900\/80 {
  fill: #064e3bcc;
}

.fill-emerald-900\/90 {
  fill: #064e3be6;
}

.fill-emerald-900\/95 {
  fill: #064e3bf2;
}

.fill-emerald-950 {
  fill: #022c22;
}

.fill-emerald-950\/0 {
  fill: #022c2200;
}

.fill-emerald-950\/10 {
  fill: #022c221a;
}

.fill-emerald-950\/100 {
  fill: #022c22;
}

.fill-emerald-950\/20 {
  fill: #022c2233;
}

.fill-emerald-950\/25 {
  fill: #022c2240;
}

.fill-emerald-950\/30 {
  fill: #022c224d;
}

.fill-emerald-950\/40 {
  fill: #022c2266;
}

.fill-emerald-950\/5 {
  fill: #022c220d;
}

.fill-emerald-950\/50 {
  fill: #022c2280;
}

.fill-emerald-950\/60 {
  fill: #022c2299;
}

.fill-emerald-950\/70 {
  fill: #022c22b3;
}

.fill-emerald-950\/75 {
  fill: #022c22bf;
}

.fill-emerald-950\/80 {
  fill: #022c22cc;
}

.fill-emerald-950\/90 {
  fill: #022c22e6;
}

.fill-emerald-950\/95 {
  fill: #022c22f2;
}

.fill-fuchsia-100 {
  fill: #fae8ff;
}

.fill-fuchsia-100\/0 {
  fill: #fae8ff00;
}

.fill-fuchsia-100\/10 {
  fill: #fae8ff1a;
}

.fill-fuchsia-100\/100 {
  fill: #fae8ff;
}

.fill-fuchsia-100\/20 {
  fill: #fae8ff33;
}

.fill-fuchsia-100\/25 {
  fill: #fae8ff40;
}

.fill-fuchsia-100\/30 {
  fill: #fae8ff4d;
}

.fill-fuchsia-100\/40 {
  fill: #fae8ff66;
}

.fill-fuchsia-100\/5 {
  fill: #fae8ff0d;
}

.fill-fuchsia-100\/50 {
  fill: #fae8ff80;
}

.fill-fuchsia-100\/60 {
  fill: #fae8ff99;
}

.fill-fuchsia-100\/70 {
  fill: #fae8ffb3;
}

.fill-fuchsia-100\/75 {
  fill: #fae8ffbf;
}

.fill-fuchsia-100\/80 {
  fill: #fae8ffcc;
}

.fill-fuchsia-100\/90 {
  fill: #fae8ffe6;
}

.fill-fuchsia-100\/95 {
  fill: #fae8fff2;
}

.fill-fuchsia-200 {
  fill: #f5d0fe;
}

.fill-fuchsia-200\/0 {
  fill: #f5d0fe00;
}

.fill-fuchsia-200\/10 {
  fill: #f5d0fe1a;
}

.fill-fuchsia-200\/100 {
  fill: #f5d0fe;
}

.fill-fuchsia-200\/20 {
  fill: #f5d0fe33;
}

.fill-fuchsia-200\/25 {
  fill: #f5d0fe40;
}

.fill-fuchsia-200\/30 {
  fill: #f5d0fe4d;
}

.fill-fuchsia-200\/40 {
  fill: #f5d0fe66;
}

.fill-fuchsia-200\/5 {
  fill: #f5d0fe0d;
}

.fill-fuchsia-200\/50 {
  fill: #f5d0fe80;
}

.fill-fuchsia-200\/60 {
  fill: #f5d0fe99;
}

.fill-fuchsia-200\/70 {
  fill: #f5d0feb3;
}

.fill-fuchsia-200\/75 {
  fill: #f5d0febf;
}

.fill-fuchsia-200\/80 {
  fill: #f5d0fecc;
}

.fill-fuchsia-200\/90 {
  fill: #f5d0fee6;
}

.fill-fuchsia-200\/95 {
  fill: #f5d0fef2;
}

.fill-fuchsia-300 {
  fill: #f0abfc;
}

.fill-fuchsia-300\/0 {
  fill: #f0abfc00;
}

.fill-fuchsia-300\/10 {
  fill: #f0abfc1a;
}

.fill-fuchsia-300\/100 {
  fill: #f0abfc;
}

.fill-fuchsia-300\/20 {
  fill: #f0abfc33;
}

.fill-fuchsia-300\/25 {
  fill: #f0abfc40;
}

.fill-fuchsia-300\/30 {
  fill: #f0abfc4d;
}

.fill-fuchsia-300\/40 {
  fill: #f0abfc66;
}

.fill-fuchsia-300\/5 {
  fill: #f0abfc0d;
}

.fill-fuchsia-300\/50 {
  fill: #f0abfc80;
}

.fill-fuchsia-300\/60 {
  fill: #f0abfc99;
}

.fill-fuchsia-300\/70 {
  fill: #f0abfcb3;
}

.fill-fuchsia-300\/75 {
  fill: #f0abfcbf;
}

.fill-fuchsia-300\/80 {
  fill: #f0abfccc;
}

.fill-fuchsia-300\/90 {
  fill: #f0abfce6;
}

.fill-fuchsia-300\/95 {
  fill: #f0abfcf2;
}

.fill-fuchsia-400 {
  fill: #e879f9;
}

.fill-fuchsia-400\/0 {
  fill: #e879f900;
}

.fill-fuchsia-400\/10 {
  fill: #e879f91a;
}

.fill-fuchsia-400\/100 {
  fill: #e879f9;
}

.fill-fuchsia-400\/20 {
  fill: #e879f933;
}

.fill-fuchsia-400\/25 {
  fill: #e879f940;
}

.fill-fuchsia-400\/30 {
  fill: #e879f94d;
}

.fill-fuchsia-400\/40 {
  fill: #e879f966;
}

.fill-fuchsia-400\/5 {
  fill: #e879f90d;
}

.fill-fuchsia-400\/50 {
  fill: #e879f980;
}

.fill-fuchsia-400\/60 {
  fill: #e879f999;
}

.fill-fuchsia-400\/70 {
  fill: #e879f9b3;
}

.fill-fuchsia-400\/75 {
  fill: #e879f9bf;
}

.fill-fuchsia-400\/80 {
  fill: #e879f9cc;
}

.fill-fuchsia-400\/90 {
  fill: #e879f9e6;
}

.fill-fuchsia-400\/95 {
  fill: #e879f9f2;
}

.fill-fuchsia-50 {
  fill: #fdf4ff;
}

.fill-fuchsia-50\/0 {
  fill: #fdf4ff00;
}

.fill-fuchsia-50\/10 {
  fill: #fdf4ff1a;
}

.fill-fuchsia-50\/100 {
  fill: #fdf4ff;
}

.fill-fuchsia-50\/20 {
  fill: #fdf4ff33;
}

.fill-fuchsia-50\/25 {
  fill: #fdf4ff40;
}

.fill-fuchsia-50\/30 {
  fill: #fdf4ff4d;
}

.fill-fuchsia-50\/40 {
  fill: #fdf4ff66;
}

.fill-fuchsia-50\/5 {
  fill: #fdf4ff0d;
}

.fill-fuchsia-50\/50 {
  fill: #fdf4ff80;
}

.fill-fuchsia-50\/60 {
  fill: #fdf4ff99;
}

.fill-fuchsia-50\/70 {
  fill: #fdf4ffb3;
}

.fill-fuchsia-50\/75 {
  fill: #fdf4ffbf;
}

.fill-fuchsia-50\/80 {
  fill: #fdf4ffcc;
}

.fill-fuchsia-50\/90 {
  fill: #fdf4ffe6;
}

.fill-fuchsia-50\/95 {
  fill: #fdf4fff2;
}

.fill-fuchsia-500 {
  fill: #d946ef;
}

.fill-fuchsia-500\/0 {
  fill: #d946ef00;
}

.fill-fuchsia-500\/10 {
  fill: #d946ef1a;
}

.fill-fuchsia-500\/100 {
  fill: #d946ef;
}

.fill-fuchsia-500\/20 {
  fill: #d946ef33;
}

.fill-fuchsia-500\/25 {
  fill: #d946ef40;
}

.fill-fuchsia-500\/30 {
  fill: #d946ef4d;
}

.fill-fuchsia-500\/40 {
  fill: #d946ef66;
}

.fill-fuchsia-500\/5 {
  fill: #d946ef0d;
}

.fill-fuchsia-500\/50 {
  fill: #d946ef80;
}

.fill-fuchsia-500\/60 {
  fill: #d946ef99;
}

.fill-fuchsia-500\/70 {
  fill: #d946efb3;
}

.fill-fuchsia-500\/75 {
  fill: #d946efbf;
}

.fill-fuchsia-500\/80 {
  fill: #d946efcc;
}

.fill-fuchsia-500\/90 {
  fill: #d946efe6;
}

.fill-fuchsia-500\/95 {
  fill: #d946eff2;
}

.fill-fuchsia-600 {
  fill: #c026d3;
}

.fill-fuchsia-600\/0 {
  fill: #c026d300;
}

.fill-fuchsia-600\/10 {
  fill: #c026d31a;
}

.fill-fuchsia-600\/100 {
  fill: #c026d3;
}

.fill-fuchsia-600\/20 {
  fill: #c026d333;
}

.fill-fuchsia-600\/25 {
  fill: #c026d340;
}

.fill-fuchsia-600\/30 {
  fill: #c026d34d;
}

.fill-fuchsia-600\/40 {
  fill: #c026d366;
}

.fill-fuchsia-600\/5 {
  fill: #c026d30d;
}

.fill-fuchsia-600\/50 {
  fill: #c026d380;
}

.fill-fuchsia-600\/60 {
  fill: #c026d399;
}

.fill-fuchsia-600\/70 {
  fill: #c026d3b3;
}

.fill-fuchsia-600\/75 {
  fill: #c026d3bf;
}

.fill-fuchsia-600\/80 {
  fill: #c026d3cc;
}

.fill-fuchsia-600\/90 {
  fill: #c026d3e6;
}

.fill-fuchsia-600\/95 {
  fill: #c026d3f2;
}

.fill-fuchsia-700 {
  fill: #a21caf;
}

.fill-fuchsia-700\/0 {
  fill: #a21caf00;
}

.fill-fuchsia-700\/10 {
  fill: #a21caf1a;
}

.fill-fuchsia-700\/100 {
  fill: #a21caf;
}

.fill-fuchsia-700\/20 {
  fill: #a21caf33;
}

.fill-fuchsia-700\/25 {
  fill: #a21caf40;
}

.fill-fuchsia-700\/30 {
  fill: #a21caf4d;
}

.fill-fuchsia-700\/40 {
  fill: #a21caf66;
}

.fill-fuchsia-700\/5 {
  fill: #a21caf0d;
}

.fill-fuchsia-700\/50 {
  fill: #a21caf80;
}

.fill-fuchsia-700\/60 {
  fill: #a21caf99;
}

.fill-fuchsia-700\/70 {
  fill: #a21cafb3;
}

.fill-fuchsia-700\/75 {
  fill: #a21cafbf;
}

.fill-fuchsia-700\/80 {
  fill: #a21cafcc;
}

.fill-fuchsia-700\/90 {
  fill: #a21cafe6;
}

.fill-fuchsia-700\/95 {
  fill: #a21caff2;
}

.fill-fuchsia-800 {
  fill: #86198f;
}

.fill-fuchsia-800\/0 {
  fill: #86198f00;
}

.fill-fuchsia-800\/10 {
  fill: #86198f1a;
}

.fill-fuchsia-800\/100 {
  fill: #86198f;
}

.fill-fuchsia-800\/20 {
  fill: #86198f33;
}

.fill-fuchsia-800\/25 {
  fill: #86198f40;
}

.fill-fuchsia-800\/30 {
  fill: #86198f4d;
}

.fill-fuchsia-800\/40 {
  fill: #86198f66;
}

.fill-fuchsia-800\/5 {
  fill: #86198f0d;
}

.fill-fuchsia-800\/50 {
  fill: #86198f80;
}

.fill-fuchsia-800\/60 {
  fill: #86198f99;
}

.fill-fuchsia-800\/70 {
  fill: #86198fb3;
}

.fill-fuchsia-800\/75 {
  fill: #86198fbf;
}

.fill-fuchsia-800\/80 {
  fill: #86198fcc;
}

.fill-fuchsia-800\/90 {
  fill: #86198fe6;
}

.fill-fuchsia-800\/95 {
  fill: #86198ff2;
}

.fill-fuchsia-900 {
  fill: #701a75;
}

.fill-fuchsia-900\/0 {
  fill: #701a7500;
}

.fill-fuchsia-900\/10 {
  fill: #701a751a;
}

.fill-fuchsia-900\/100 {
  fill: #701a75;
}

.fill-fuchsia-900\/20 {
  fill: #701a7533;
}

.fill-fuchsia-900\/25 {
  fill: #701a7540;
}

.fill-fuchsia-900\/30 {
  fill: #701a754d;
}

.fill-fuchsia-900\/40 {
  fill: #701a7566;
}

.fill-fuchsia-900\/5 {
  fill: #701a750d;
}

.fill-fuchsia-900\/50 {
  fill: #701a7580;
}

.fill-fuchsia-900\/60 {
  fill: #701a7599;
}

.fill-fuchsia-900\/70 {
  fill: #701a75b3;
}

.fill-fuchsia-900\/75 {
  fill: #701a75bf;
}

.fill-fuchsia-900\/80 {
  fill: #701a75cc;
}

.fill-fuchsia-900\/90 {
  fill: #701a75e6;
}

.fill-fuchsia-900\/95 {
  fill: #701a75f2;
}

.fill-fuchsia-950 {
  fill: #4a044e;
}

.fill-fuchsia-950\/0 {
  fill: #4a044e00;
}

.fill-fuchsia-950\/10 {
  fill: #4a044e1a;
}

.fill-fuchsia-950\/100 {
  fill: #4a044e;
}

.fill-fuchsia-950\/20 {
  fill: #4a044e33;
}

.fill-fuchsia-950\/25 {
  fill: #4a044e40;
}

.fill-fuchsia-950\/30 {
  fill: #4a044e4d;
}

.fill-fuchsia-950\/40 {
  fill: #4a044e66;
}

.fill-fuchsia-950\/5 {
  fill: #4a044e0d;
}

.fill-fuchsia-950\/50 {
  fill: #4a044e80;
}

.fill-fuchsia-950\/60 {
  fill: #4a044e99;
}

.fill-fuchsia-950\/70 {
  fill: #4a044eb3;
}

.fill-fuchsia-950\/75 {
  fill: #4a044ebf;
}

.fill-fuchsia-950\/80 {
  fill: #4a044ecc;
}

.fill-fuchsia-950\/90 {
  fill: #4a044ee6;
}

.fill-fuchsia-950\/95 {
  fill: #4a044ef2;
}

.fill-gray-100 {
  fill: #f3f4f6;
}

.fill-gray-100\/0 {
  fill: #f3f4f600;
}

.fill-gray-100\/10 {
  fill: #f3f4f61a;
}

.fill-gray-100\/100 {
  fill: #f3f4f6;
}

.fill-gray-100\/20 {
  fill: #f3f4f633;
}

.fill-gray-100\/25 {
  fill: #f3f4f640;
}

.fill-gray-100\/30 {
  fill: #f3f4f64d;
}

.fill-gray-100\/40 {
  fill: #f3f4f666;
}

.fill-gray-100\/5 {
  fill: #f3f4f60d;
}

.fill-gray-100\/50 {
  fill: #f3f4f680;
}

.fill-gray-100\/60 {
  fill: #f3f4f699;
}

.fill-gray-100\/70 {
  fill: #f3f4f6b3;
}

.fill-gray-100\/75 {
  fill: #f3f4f6bf;
}

.fill-gray-100\/80 {
  fill: #f3f4f6cc;
}

.fill-gray-100\/90 {
  fill: #f3f4f6e6;
}

.fill-gray-100\/95 {
  fill: #f3f4f6f2;
}

.fill-gray-200 {
  fill: #e5e7eb;
}

.fill-gray-200\/0 {
  fill: #e5e7eb00;
}

.fill-gray-200\/10 {
  fill: #e5e7eb1a;
}

.fill-gray-200\/100 {
  fill: #e5e7eb;
}

.fill-gray-200\/20 {
  fill: #e5e7eb33;
}

.fill-gray-200\/25 {
  fill: #e5e7eb40;
}

.fill-gray-200\/30 {
  fill: #e5e7eb4d;
}

.fill-gray-200\/40 {
  fill: #e5e7eb66;
}

.fill-gray-200\/5 {
  fill: #e5e7eb0d;
}

.fill-gray-200\/50 {
  fill: #e5e7eb80;
}

.fill-gray-200\/60 {
  fill: #e5e7eb99;
}

.fill-gray-200\/70 {
  fill: #e5e7ebb3;
}

.fill-gray-200\/75 {
  fill: #e5e7ebbf;
}

.fill-gray-200\/80 {
  fill: #e5e7ebcc;
}

.fill-gray-200\/90 {
  fill: #e5e7ebe6;
}

.fill-gray-200\/95 {
  fill: #e5e7ebf2;
}

.fill-gray-300 {
  fill: #d1d5db;
}

.fill-gray-300\/0 {
  fill: #d1d5db00;
}

.fill-gray-300\/10 {
  fill: #d1d5db1a;
}

.fill-gray-300\/100 {
  fill: #d1d5db;
}

.fill-gray-300\/20 {
  fill: #d1d5db33;
}

.fill-gray-300\/25 {
  fill: #d1d5db40;
}

.fill-gray-300\/30 {
  fill: #d1d5db4d;
}

.fill-gray-300\/40 {
  fill: #d1d5db66;
}

.fill-gray-300\/5 {
  fill: #d1d5db0d;
}

.fill-gray-300\/50 {
  fill: #d1d5db80;
}

.fill-gray-300\/60 {
  fill: #d1d5db99;
}

.fill-gray-300\/70 {
  fill: #d1d5dbb3;
}

.fill-gray-300\/75 {
  fill: #d1d5dbbf;
}

.fill-gray-300\/80 {
  fill: #d1d5dbcc;
}

.fill-gray-300\/90 {
  fill: #d1d5dbe6;
}

.fill-gray-300\/95 {
  fill: #d1d5dbf2;
}

.fill-gray-400 {
  fill: #9ca3af;
}

.fill-gray-400\/0 {
  fill: #9ca3af00;
}

.fill-gray-400\/10 {
  fill: #9ca3af1a;
}

.fill-gray-400\/100 {
  fill: #9ca3af;
}

.fill-gray-400\/20 {
  fill: #9ca3af33;
}

.fill-gray-400\/25 {
  fill: #9ca3af40;
}

.fill-gray-400\/30 {
  fill: #9ca3af4d;
}

.fill-gray-400\/40 {
  fill: #9ca3af66;
}

.fill-gray-400\/5 {
  fill: #9ca3af0d;
}

.fill-gray-400\/50 {
  fill: #9ca3af80;
}

.fill-gray-400\/60 {
  fill: #9ca3af99;
}

.fill-gray-400\/70 {
  fill: #9ca3afb3;
}

.fill-gray-400\/75 {
  fill: #9ca3afbf;
}

.fill-gray-400\/80 {
  fill: #9ca3afcc;
}

.fill-gray-400\/90 {
  fill: #9ca3afe6;
}

.fill-gray-400\/95 {
  fill: #9ca3aff2;
}

.fill-gray-50 {
  fill: #f9fafb;
}

.fill-gray-50\/0 {
  fill: #f9fafb00;
}

.fill-gray-50\/10 {
  fill: #f9fafb1a;
}

.fill-gray-50\/100 {
  fill: #f9fafb;
}

.fill-gray-50\/20 {
  fill: #f9fafb33;
}

.fill-gray-50\/25 {
  fill: #f9fafb40;
}

.fill-gray-50\/30 {
  fill: #f9fafb4d;
}

.fill-gray-50\/40 {
  fill: #f9fafb66;
}

.fill-gray-50\/5 {
  fill: #f9fafb0d;
}

.fill-gray-50\/50 {
  fill: #f9fafb80;
}

.fill-gray-50\/60 {
  fill: #f9fafb99;
}

.fill-gray-50\/70 {
  fill: #f9fafbb3;
}

.fill-gray-50\/75 {
  fill: #f9fafbbf;
}

.fill-gray-50\/80 {
  fill: #f9fafbcc;
}

.fill-gray-50\/90 {
  fill: #f9fafbe6;
}

.fill-gray-50\/95 {
  fill: #f9fafbf2;
}

.fill-gray-500 {
  fill: #6b7280;
}

.fill-gray-500\/0 {
  fill: #6b728000;
}

.fill-gray-500\/10 {
  fill: #6b72801a;
}

.fill-gray-500\/100 {
  fill: #6b7280;
}

.fill-gray-500\/20 {
  fill: #6b728033;
}

.fill-gray-500\/25 {
  fill: #6b728040;
}

.fill-gray-500\/30 {
  fill: #6b72804d;
}

.fill-gray-500\/40 {
  fill: #6b728066;
}

.fill-gray-500\/5 {
  fill: #6b72800d;
}

.fill-gray-500\/50 {
  fill: #6b728080;
}

.fill-gray-500\/60 {
  fill: #6b728099;
}

.fill-gray-500\/70 {
  fill: #6b7280b3;
}

.fill-gray-500\/75 {
  fill: #6b7280bf;
}

.fill-gray-500\/80 {
  fill: #6b7280cc;
}

.fill-gray-500\/90 {
  fill: #6b7280e6;
}

.fill-gray-500\/95 {
  fill: #6b7280f2;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-gray-600\/0 {
  fill: #4b556300;
}

.fill-gray-600\/10 {
  fill: #4b55631a;
}

.fill-gray-600\/100 {
  fill: #4b5563;
}

.fill-gray-600\/20 {
  fill: #4b556333;
}

.fill-gray-600\/25 {
  fill: #4b556340;
}

.fill-gray-600\/30 {
  fill: #4b55634d;
}

.fill-gray-600\/40 {
  fill: #4b556366;
}

.fill-gray-600\/5 {
  fill: #4b55630d;
}

.fill-gray-600\/50 {
  fill: #4b556380;
}

.fill-gray-600\/60 {
  fill: #4b556399;
}

.fill-gray-600\/70 {
  fill: #4b5563b3;
}

.fill-gray-600\/75 {
  fill: #4b5563bf;
}

.fill-gray-600\/80 {
  fill: #4b5563cc;
}

.fill-gray-600\/90 {
  fill: #4b5563e6;
}

.fill-gray-600\/95 {
  fill: #4b5563f2;
}

.fill-gray-700 {
  fill: #374151;
}

.fill-gray-700\/0 {
  fill: #37415100;
}

.fill-gray-700\/10 {
  fill: #3741511a;
}

.fill-gray-700\/100 {
  fill: #374151;
}

.fill-gray-700\/20 {
  fill: #37415133;
}

.fill-gray-700\/25 {
  fill: #37415140;
}

.fill-gray-700\/30 {
  fill: #3741514d;
}

.fill-gray-700\/40 {
  fill: #37415166;
}

.fill-gray-700\/5 {
  fill: #3741510d;
}

.fill-gray-700\/50 {
  fill: #37415180;
}

.fill-gray-700\/60 {
  fill: #37415199;
}

.fill-gray-700\/70 {
  fill: #374151b3;
}

.fill-gray-700\/75 {
  fill: #374151bf;
}

.fill-gray-700\/80 {
  fill: #374151cc;
}

.fill-gray-700\/90 {
  fill: #374151e6;
}

.fill-gray-700\/95 {
  fill: #374151f2;
}

.fill-gray-800 {
  fill: #1f2937;
}

.fill-gray-800\/0 {
  fill: #1f293700;
}

.fill-gray-800\/10 {
  fill: #1f29371a;
}

.fill-gray-800\/100 {
  fill: #1f2937;
}

.fill-gray-800\/20 {
  fill: #1f293733;
}

.fill-gray-800\/25 {
  fill: #1f293740;
}

.fill-gray-800\/30 {
  fill: #1f29374d;
}

.fill-gray-800\/40 {
  fill: #1f293766;
}

.fill-gray-800\/5 {
  fill: #1f29370d;
}

.fill-gray-800\/50 {
  fill: #1f293780;
}

.fill-gray-800\/60 {
  fill: #1f293799;
}

.fill-gray-800\/70 {
  fill: #1f2937b3;
}

.fill-gray-800\/75 {
  fill: #1f2937bf;
}

.fill-gray-800\/80 {
  fill: #1f2937cc;
}

.fill-gray-800\/90 {
  fill: #1f2937e6;
}

.fill-gray-800\/95 {
  fill: #1f2937f2;
}

.fill-gray-900 {
  fill: #111827;
}

.fill-gray-900\/0 {
  fill: #11182700;
}

.fill-gray-900\/10 {
  fill: #1118271a;
}

.fill-gray-900\/100 {
  fill: #111827;
}

.fill-gray-900\/20 {
  fill: #11182733;
}

.fill-gray-900\/25 {
  fill: #11182740;
}

.fill-gray-900\/30 {
  fill: #1118274d;
}

.fill-gray-900\/40 {
  fill: #11182766;
}

.fill-gray-900\/5 {
  fill: #1118270d;
}

.fill-gray-900\/50 {
  fill: #11182780;
}

.fill-gray-900\/60 {
  fill: #11182799;
}

.fill-gray-900\/70 {
  fill: #111827b3;
}

.fill-gray-900\/75 {
  fill: #111827bf;
}

.fill-gray-900\/80 {
  fill: #111827cc;
}

.fill-gray-900\/90 {
  fill: #111827e6;
}

.fill-gray-900\/95 {
  fill: #111827f2;
}

.fill-gray-950 {
  fill: #030712;
}

.fill-gray-950\/0 {
  fill: #03071200;
}

.fill-gray-950\/10 {
  fill: #0307121a;
}

.fill-gray-950\/100 {
  fill: #030712;
}

.fill-gray-950\/20 {
  fill: #03071233;
}

.fill-gray-950\/25 {
  fill: #03071240;
}

.fill-gray-950\/30 {
  fill: #0307124d;
}

.fill-gray-950\/40 {
  fill: #03071266;
}

.fill-gray-950\/5 {
  fill: #0307120d;
}

.fill-gray-950\/50 {
  fill: #03071280;
}

.fill-gray-950\/60 {
  fill: #03071299;
}

.fill-gray-950\/70 {
  fill: #030712b3;
}

.fill-gray-950\/75 {
  fill: #030712bf;
}

.fill-gray-950\/80 {
  fill: #030712cc;
}

.fill-gray-950\/90 {
  fill: #030712e6;
}

.fill-gray-950\/95 {
  fill: #030712f2;
}

.fill-green-100 {
  fill: #dcfce7;
}

.fill-green-100\/0 {
  fill: #dcfce700;
}

.fill-green-100\/10 {
  fill: #dcfce71a;
}

.fill-green-100\/100 {
  fill: #dcfce7;
}

.fill-green-100\/20 {
  fill: #dcfce733;
}

.fill-green-100\/25 {
  fill: #dcfce740;
}

.fill-green-100\/30 {
  fill: #dcfce74d;
}

.fill-green-100\/40 {
  fill: #dcfce766;
}

.fill-green-100\/5 {
  fill: #dcfce70d;
}

.fill-green-100\/50 {
  fill: #dcfce780;
}

.fill-green-100\/60 {
  fill: #dcfce799;
}

.fill-green-100\/70 {
  fill: #dcfce7b3;
}

.fill-green-100\/75 {
  fill: #dcfce7bf;
}

.fill-green-100\/80 {
  fill: #dcfce7cc;
}

.fill-green-100\/90 {
  fill: #dcfce7e6;
}

.fill-green-100\/95 {
  fill: #dcfce7f2;
}

.fill-green-200 {
  fill: #bbf7d0;
}

.fill-green-200\/0 {
  fill: #bbf7d000;
}

.fill-green-200\/10 {
  fill: #bbf7d01a;
}

.fill-green-200\/100 {
  fill: #bbf7d0;
}

.fill-green-200\/20 {
  fill: #bbf7d033;
}

.fill-green-200\/25 {
  fill: #bbf7d040;
}

.fill-green-200\/30 {
  fill: #bbf7d04d;
}

.fill-green-200\/40 {
  fill: #bbf7d066;
}

.fill-green-200\/5 {
  fill: #bbf7d00d;
}

.fill-green-200\/50 {
  fill: #bbf7d080;
}

.fill-green-200\/60 {
  fill: #bbf7d099;
}

.fill-green-200\/70 {
  fill: #bbf7d0b3;
}

.fill-green-200\/75 {
  fill: #bbf7d0bf;
}

.fill-green-200\/80 {
  fill: #bbf7d0cc;
}

.fill-green-200\/90 {
  fill: #bbf7d0e6;
}

.fill-green-200\/95 {
  fill: #bbf7d0f2;
}

.fill-green-300 {
  fill: #86efac;
}

.fill-green-300\/0 {
  fill: #86efac00;
}

.fill-green-300\/10 {
  fill: #86efac1a;
}

.fill-green-300\/100 {
  fill: #86efac;
}

.fill-green-300\/20 {
  fill: #86efac33;
}

.fill-green-300\/25 {
  fill: #86efac40;
}

.fill-green-300\/30 {
  fill: #86efac4d;
}

.fill-green-300\/40 {
  fill: #86efac66;
}

.fill-green-300\/5 {
  fill: #86efac0d;
}

.fill-green-300\/50 {
  fill: #86efac80;
}

.fill-green-300\/60 {
  fill: #86efac99;
}

.fill-green-300\/70 {
  fill: #86efacb3;
}

.fill-green-300\/75 {
  fill: #86efacbf;
}

.fill-green-300\/80 {
  fill: #86efaccc;
}

.fill-green-300\/90 {
  fill: #86eface6;
}

.fill-green-300\/95 {
  fill: #86efacf2;
}

.fill-green-400 {
  fill: #4ade80;
}

.fill-green-400\/0 {
  fill: #4ade8000;
}

.fill-green-400\/10 {
  fill: #4ade801a;
}

.fill-green-400\/100 {
  fill: #4ade80;
}

.fill-green-400\/20 {
  fill: #4ade8033;
}

.fill-green-400\/25 {
  fill: #4ade8040;
}

.fill-green-400\/30 {
  fill: #4ade804d;
}

.fill-green-400\/40 {
  fill: #4ade8066;
}

.fill-green-400\/5 {
  fill: #4ade800d;
}

.fill-green-400\/50 {
  fill: #4ade8080;
}

.fill-green-400\/60 {
  fill: #4ade8099;
}

.fill-green-400\/70 {
  fill: #4ade80b3;
}

.fill-green-400\/75 {
  fill: #4ade80bf;
}

.fill-green-400\/80 {
  fill: #4ade80cc;
}

.fill-green-400\/90 {
  fill: #4ade80e6;
}

.fill-green-400\/95 {
  fill: #4ade80f2;
}

.fill-green-50 {
  fill: #f0fdf4;
}

.fill-green-50\/0 {
  fill: #f0fdf400;
}

.fill-green-50\/10 {
  fill: #f0fdf41a;
}

.fill-green-50\/100 {
  fill: #f0fdf4;
}

.fill-green-50\/20 {
  fill: #f0fdf433;
}

.fill-green-50\/25 {
  fill: #f0fdf440;
}

.fill-green-50\/30 {
  fill: #f0fdf44d;
}

.fill-green-50\/40 {
  fill: #f0fdf466;
}

.fill-green-50\/5 {
  fill: #f0fdf40d;
}

.fill-green-50\/50 {
  fill: #f0fdf480;
}

.fill-green-50\/60 {
  fill: #f0fdf499;
}

.fill-green-50\/70 {
  fill: #f0fdf4b3;
}

.fill-green-50\/75 {
  fill: #f0fdf4bf;
}

.fill-green-50\/80 {
  fill: #f0fdf4cc;
}

.fill-green-50\/90 {
  fill: #f0fdf4e6;
}

.fill-green-50\/95 {
  fill: #f0fdf4f2;
}

.fill-green-500 {
  fill: #22c55e;
}

.fill-green-500\/0 {
  fill: #22c55e00;
}

.fill-green-500\/10 {
  fill: #22c55e1a;
}

.fill-green-500\/100 {
  fill: #22c55e;
}

.fill-green-500\/20 {
  fill: #22c55e33;
}

.fill-green-500\/25 {
  fill: #22c55e40;
}

.fill-green-500\/30 {
  fill: #22c55e4d;
}

.fill-green-500\/40 {
  fill: #22c55e66;
}

.fill-green-500\/5 {
  fill: #22c55e0d;
}

.fill-green-500\/50 {
  fill: #22c55e80;
}

.fill-green-500\/60 {
  fill: #22c55e99;
}

.fill-green-500\/70 {
  fill: #22c55eb3;
}

.fill-green-500\/75 {
  fill: #22c55ebf;
}

.fill-green-500\/80 {
  fill: #22c55ecc;
}

.fill-green-500\/90 {
  fill: #22c55ee6;
}

.fill-green-500\/95 {
  fill: #22c55ef2;
}

.fill-green-600 {
  fill: #16a34a;
}

.fill-green-600\/0 {
  fill: #16a34a00;
}

.fill-green-600\/10 {
  fill: #16a34a1a;
}

.fill-green-600\/100 {
  fill: #16a34a;
}

.fill-green-600\/20 {
  fill: #16a34a33;
}

.fill-green-600\/25 {
  fill: #16a34a40;
}

.fill-green-600\/30 {
  fill: #16a34a4d;
}

.fill-green-600\/40 {
  fill: #16a34a66;
}

.fill-green-600\/5 {
  fill: #16a34a0d;
}

.fill-green-600\/50 {
  fill: #16a34a80;
}

.fill-green-600\/60 {
  fill: #16a34a99;
}

.fill-green-600\/70 {
  fill: #16a34ab3;
}

.fill-green-600\/75 {
  fill: #16a34abf;
}

.fill-green-600\/80 {
  fill: #16a34acc;
}

.fill-green-600\/90 {
  fill: #16a34ae6;
}

.fill-green-600\/95 {
  fill: #16a34af2;
}

.fill-green-700 {
  fill: #15803d;
}

.fill-green-700\/0 {
  fill: #15803d00;
}

.fill-green-700\/10 {
  fill: #15803d1a;
}

.fill-green-700\/100 {
  fill: #15803d;
}

.fill-green-700\/20 {
  fill: #15803d33;
}

.fill-green-700\/25 {
  fill: #15803d40;
}

.fill-green-700\/30 {
  fill: #15803d4d;
}

.fill-green-700\/40 {
  fill: #15803d66;
}

.fill-green-700\/5 {
  fill: #15803d0d;
}

.fill-green-700\/50 {
  fill: #15803d80;
}

.fill-green-700\/60 {
  fill: #15803d99;
}

.fill-green-700\/70 {
  fill: #15803db3;
}

.fill-green-700\/75 {
  fill: #15803dbf;
}

.fill-green-700\/80 {
  fill: #15803dcc;
}

.fill-green-700\/90 {
  fill: #15803de6;
}

.fill-green-700\/95 {
  fill: #15803df2;
}

.fill-green-800 {
  fill: #166534;
}

.fill-green-800\/0 {
  fill: #16653400;
}

.fill-green-800\/10 {
  fill: #1665341a;
}

.fill-green-800\/100 {
  fill: #166534;
}

.fill-green-800\/20 {
  fill: #16653433;
}

.fill-green-800\/25 {
  fill: #16653440;
}

.fill-green-800\/30 {
  fill: #1665344d;
}

.fill-green-800\/40 {
  fill: #16653466;
}

.fill-green-800\/5 {
  fill: #1665340d;
}

.fill-green-800\/50 {
  fill: #16653480;
}

.fill-green-800\/60 {
  fill: #16653499;
}

.fill-green-800\/70 {
  fill: #166534b3;
}

.fill-green-800\/75 {
  fill: #166534bf;
}

.fill-green-800\/80 {
  fill: #166534cc;
}

.fill-green-800\/90 {
  fill: #166534e6;
}

.fill-green-800\/95 {
  fill: #166534f2;
}

.fill-green-900 {
  fill: #14532d;
}

.fill-green-900\/0 {
  fill: #14532d00;
}

.fill-green-900\/10 {
  fill: #14532d1a;
}

.fill-green-900\/100 {
  fill: #14532d;
}

.fill-green-900\/20 {
  fill: #14532d33;
}

.fill-green-900\/25 {
  fill: #14532d40;
}

.fill-green-900\/30 {
  fill: #14532d4d;
}

.fill-green-900\/40 {
  fill: #14532d66;
}

.fill-green-900\/5 {
  fill: #14532d0d;
}

.fill-green-900\/50 {
  fill: #14532d80;
}

.fill-green-900\/60 {
  fill: #14532d99;
}

.fill-green-900\/70 {
  fill: #14532db3;
}

.fill-green-900\/75 {
  fill: #14532dbf;
}

.fill-green-900\/80 {
  fill: #14532dcc;
}

.fill-green-900\/90 {
  fill: #14532de6;
}

.fill-green-900\/95 {
  fill: #14532df2;
}

.fill-green-950 {
  fill: #052e16;
}

.fill-green-950\/0 {
  fill: #052e1600;
}

.fill-green-950\/10 {
  fill: #052e161a;
}

.fill-green-950\/100 {
  fill: #052e16;
}

.fill-green-950\/20 {
  fill: #052e1633;
}

.fill-green-950\/25 {
  fill: #052e1640;
}

.fill-green-950\/30 {
  fill: #052e164d;
}

.fill-green-950\/40 {
  fill: #052e1666;
}

.fill-green-950\/5 {
  fill: #052e160d;
}

.fill-green-950\/50 {
  fill: #052e1680;
}

.fill-green-950\/60 {
  fill: #052e1699;
}

.fill-green-950\/70 {
  fill: #052e16b3;
}

.fill-green-950\/75 {
  fill: #052e16bf;
}

.fill-green-950\/80 {
  fill: #052e16cc;
}

.fill-green-950\/90 {
  fill: #052e16e6;
}

.fill-green-950\/95 {
  fill: #052e16f2;
}

.fill-indigo-100 {
  fill: #e0e7ff;
}

.fill-indigo-100\/0 {
  fill: #e0e7ff00;
}

.fill-indigo-100\/10 {
  fill: #e0e7ff1a;
}

.fill-indigo-100\/100 {
  fill: #e0e7ff;
}

.fill-indigo-100\/20 {
  fill: #e0e7ff33;
}

.fill-indigo-100\/25 {
  fill: #e0e7ff40;
}

.fill-indigo-100\/30 {
  fill: #e0e7ff4d;
}

.fill-indigo-100\/40 {
  fill: #e0e7ff66;
}

.fill-indigo-100\/5 {
  fill: #e0e7ff0d;
}

.fill-indigo-100\/50 {
  fill: #e0e7ff80;
}

.fill-indigo-100\/60 {
  fill: #e0e7ff99;
}

.fill-indigo-100\/70 {
  fill: #e0e7ffb3;
}

.fill-indigo-100\/75 {
  fill: #e0e7ffbf;
}

.fill-indigo-100\/80 {
  fill: #e0e7ffcc;
}

.fill-indigo-100\/90 {
  fill: #e0e7ffe6;
}

.fill-indigo-100\/95 {
  fill: #e0e7fff2;
}

.fill-indigo-200 {
  fill: #c7d2fe;
}

.fill-indigo-200\/0 {
  fill: #c7d2fe00;
}

.fill-indigo-200\/10 {
  fill: #c7d2fe1a;
}

.fill-indigo-200\/100 {
  fill: #c7d2fe;
}

.fill-indigo-200\/20 {
  fill: #c7d2fe33;
}

.fill-indigo-200\/25 {
  fill: #c7d2fe40;
}

.fill-indigo-200\/30 {
  fill: #c7d2fe4d;
}

.fill-indigo-200\/40 {
  fill: #c7d2fe66;
}

.fill-indigo-200\/5 {
  fill: #c7d2fe0d;
}

.fill-indigo-200\/50 {
  fill: #c7d2fe80;
}

.fill-indigo-200\/60 {
  fill: #c7d2fe99;
}

.fill-indigo-200\/70 {
  fill: #c7d2feb3;
}

.fill-indigo-200\/75 {
  fill: #c7d2febf;
}

.fill-indigo-200\/80 {
  fill: #c7d2fecc;
}

.fill-indigo-200\/90 {
  fill: #c7d2fee6;
}

.fill-indigo-200\/95 {
  fill: #c7d2fef2;
}

.fill-indigo-300 {
  fill: #a5b4fc;
}

.fill-indigo-300\/0 {
  fill: #a5b4fc00;
}

.fill-indigo-300\/10 {
  fill: #a5b4fc1a;
}

.fill-indigo-300\/100 {
  fill: #a5b4fc;
}

.fill-indigo-300\/20 {
  fill: #a5b4fc33;
}

.fill-indigo-300\/25 {
  fill: #a5b4fc40;
}

.fill-indigo-300\/30 {
  fill: #a5b4fc4d;
}

.fill-indigo-300\/40 {
  fill: #a5b4fc66;
}

.fill-indigo-300\/5 {
  fill: #a5b4fc0d;
}

.fill-indigo-300\/50 {
  fill: #a5b4fc80;
}

.fill-indigo-300\/60 {
  fill: #a5b4fc99;
}

.fill-indigo-300\/70 {
  fill: #a5b4fcb3;
}

.fill-indigo-300\/75 {
  fill: #a5b4fcbf;
}

.fill-indigo-300\/80 {
  fill: #a5b4fccc;
}

.fill-indigo-300\/90 {
  fill: #a5b4fce6;
}

.fill-indigo-300\/95 {
  fill: #a5b4fcf2;
}

.fill-indigo-400 {
  fill: #818cf8;
}

.fill-indigo-400\/0 {
  fill: #818cf800;
}

.fill-indigo-400\/10 {
  fill: #818cf81a;
}

.fill-indigo-400\/100 {
  fill: #818cf8;
}

.fill-indigo-400\/20 {
  fill: #818cf833;
}

.fill-indigo-400\/25 {
  fill: #818cf840;
}

.fill-indigo-400\/30 {
  fill: #818cf84d;
}

.fill-indigo-400\/40 {
  fill: #818cf866;
}

.fill-indigo-400\/5 {
  fill: #818cf80d;
}

.fill-indigo-400\/50 {
  fill: #818cf880;
}

.fill-indigo-400\/60 {
  fill: #818cf899;
}

.fill-indigo-400\/70 {
  fill: #818cf8b3;
}

.fill-indigo-400\/75 {
  fill: #818cf8bf;
}

.fill-indigo-400\/80 {
  fill: #818cf8cc;
}

.fill-indigo-400\/90 {
  fill: #818cf8e6;
}

.fill-indigo-400\/95 {
  fill: #818cf8f2;
}

.fill-indigo-50 {
  fill: #eef2ff;
}

.fill-indigo-50\/0 {
  fill: #eef2ff00;
}

.fill-indigo-50\/10 {
  fill: #eef2ff1a;
}

.fill-indigo-50\/100 {
  fill: #eef2ff;
}

.fill-indigo-50\/20 {
  fill: #eef2ff33;
}

.fill-indigo-50\/25 {
  fill: #eef2ff40;
}

.fill-indigo-50\/30 {
  fill: #eef2ff4d;
}

.fill-indigo-50\/40 {
  fill: #eef2ff66;
}

.fill-indigo-50\/5 {
  fill: #eef2ff0d;
}

.fill-indigo-50\/50 {
  fill: #eef2ff80;
}

.fill-indigo-50\/60 {
  fill: #eef2ff99;
}

.fill-indigo-50\/70 {
  fill: #eef2ffb3;
}

.fill-indigo-50\/75 {
  fill: #eef2ffbf;
}

.fill-indigo-50\/80 {
  fill: #eef2ffcc;
}

.fill-indigo-50\/90 {
  fill: #eef2ffe6;
}

.fill-indigo-50\/95 {
  fill: #eef2fff2;
}

.fill-indigo-500 {
  fill: #6366f1;
}

.fill-indigo-500\/0 {
  fill: #6366f100;
}

.fill-indigo-500\/10 {
  fill: #6366f11a;
}

.fill-indigo-500\/100 {
  fill: #6366f1;
}

.fill-indigo-500\/20 {
  fill: #6366f133;
}

.fill-indigo-500\/25 {
  fill: #6366f140;
}

.fill-indigo-500\/30 {
  fill: #6366f14d;
}

.fill-indigo-500\/40 {
  fill: #6366f166;
}

.fill-indigo-500\/5 {
  fill: #6366f10d;
}

.fill-indigo-500\/50 {
  fill: #6366f180;
}

.fill-indigo-500\/60 {
  fill: #6366f199;
}

.fill-indigo-500\/70 {
  fill: #6366f1b3;
}

.fill-indigo-500\/75 {
  fill: #6366f1bf;
}

.fill-indigo-500\/80 {
  fill: #6366f1cc;
}

.fill-indigo-500\/90 {
  fill: #6366f1e6;
}

.fill-indigo-500\/95 {
  fill: #6366f1f2;
}

.fill-indigo-600 {
  fill: #4f46e5;
}

.fill-indigo-600\/0 {
  fill: #4f46e500;
}

.fill-indigo-600\/10 {
  fill: #4f46e51a;
}

.fill-indigo-600\/100 {
  fill: #4f46e5;
}

.fill-indigo-600\/20 {
  fill: #4f46e533;
}

.fill-indigo-600\/25 {
  fill: #4f46e540;
}

.fill-indigo-600\/30 {
  fill: #4f46e54d;
}

.fill-indigo-600\/40 {
  fill: #4f46e566;
}

.fill-indigo-600\/5 {
  fill: #4f46e50d;
}

.fill-indigo-600\/50 {
  fill: #4f46e580;
}

.fill-indigo-600\/60 {
  fill: #4f46e599;
}

.fill-indigo-600\/70 {
  fill: #4f46e5b3;
}

.fill-indigo-600\/75 {
  fill: #4f46e5bf;
}

.fill-indigo-600\/80 {
  fill: #4f46e5cc;
}

.fill-indigo-600\/90 {
  fill: #4f46e5e6;
}

.fill-indigo-600\/95 {
  fill: #4f46e5f2;
}

.fill-indigo-700 {
  fill: #4338ca;
}

.fill-indigo-700\/0 {
  fill: #4338ca00;
}

.fill-indigo-700\/10 {
  fill: #4338ca1a;
}

.fill-indigo-700\/100 {
  fill: #4338ca;
}

.fill-indigo-700\/20 {
  fill: #4338ca33;
}

.fill-indigo-700\/25 {
  fill: #4338ca40;
}

.fill-indigo-700\/30 {
  fill: #4338ca4d;
}

.fill-indigo-700\/40 {
  fill: #4338ca66;
}

.fill-indigo-700\/5 {
  fill: #4338ca0d;
}

.fill-indigo-700\/50 {
  fill: #4338ca80;
}

.fill-indigo-700\/60 {
  fill: #4338ca99;
}

.fill-indigo-700\/70 {
  fill: #4338cab3;
}

.fill-indigo-700\/75 {
  fill: #4338cabf;
}

.fill-indigo-700\/80 {
  fill: #4338cacc;
}

.fill-indigo-700\/90 {
  fill: #4338cae6;
}

.fill-indigo-700\/95 {
  fill: #4338caf2;
}

.fill-indigo-800 {
  fill: #3730a3;
}

.fill-indigo-800\/0 {
  fill: #3730a300;
}

.fill-indigo-800\/10 {
  fill: #3730a31a;
}

.fill-indigo-800\/100 {
  fill: #3730a3;
}

.fill-indigo-800\/20 {
  fill: #3730a333;
}

.fill-indigo-800\/25 {
  fill: #3730a340;
}

.fill-indigo-800\/30 {
  fill: #3730a34d;
}

.fill-indigo-800\/40 {
  fill: #3730a366;
}

.fill-indigo-800\/5 {
  fill: #3730a30d;
}

.fill-indigo-800\/50 {
  fill: #3730a380;
}

.fill-indigo-800\/60 {
  fill: #3730a399;
}

.fill-indigo-800\/70 {
  fill: #3730a3b3;
}

.fill-indigo-800\/75 {
  fill: #3730a3bf;
}

.fill-indigo-800\/80 {
  fill: #3730a3cc;
}

.fill-indigo-800\/90 {
  fill: #3730a3e6;
}

.fill-indigo-800\/95 {
  fill: #3730a3f2;
}

.fill-indigo-900 {
  fill: #312e81;
}

.fill-indigo-900\/0 {
  fill: #312e8100;
}

.fill-indigo-900\/10 {
  fill: #312e811a;
}

.fill-indigo-900\/100 {
  fill: #312e81;
}

.fill-indigo-900\/20 {
  fill: #312e8133;
}

.fill-indigo-900\/25 {
  fill: #312e8140;
}

.fill-indigo-900\/30 {
  fill: #312e814d;
}

.fill-indigo-900\/40 {
  fill: #312e8166;
}

.fill-indigo-900\/5 {
  fill: #312e810d;
}

.fill-indigo-900\/50 {
  fill: #312e8180;
}

.fill-indigo-900\/60 {
  fill: #312e8199;
}

.fill-indigo-900\/70 {
  fill: #312e81b3;
}

.fill-indigo-900\/75 {
  fill: #312e81bf;
}

.fill-indigo-900\/80 {
  fill: #312e81cc;
}

.fill-indigo-900\/90 {
  fill: #312e81e6;
}

.fill-indigo-900\/95 {
  fill: #312e81f2;
}

.fill-indigo-950 {
  fill: #1e1b4b;
}

.fill-indigo-950\/0 {
  fill: #1e1b4b00;
}

.fill-indigo-950\/10 {
  fill: #1e1b4b1a;
}

.fill-indigo-950\/100 {
  fill: #1e1b4b;
}

.fill-indigo-950\/20 {
  fill: #1e1b4b33;
}

.fill-indigo-950\/25 {
  fill: #1e1b4b40;
}

.fill-indigo-950\/30 {
  fill: #1e1b4b4d;
}

.fill-indigo-950\/40 {
  fill: #1e1b4b66;
}

.fill-indigo-950\/5 {
  fill: #1e1b4b0d;
}

.fill-indigo-950\/50 {
  fill: #1e1b4b80;
}

.fill-indigo-950\/60 {
  fill: #1e1b4b99;
}

.fill-indigo-950\/70 {
  fill: #1e1b4bb3;
}

.fill-indigo-950\/75 {
  fill: #1e1b4bbf;
}

.fill-indigo-950\/80 {
  fill: #1e1b4bcc;
}

.fill-indigo-950\/90 {
  fill: #1e1b4be6;
}

.fill-indigo-950\/95 {
  fill: #1e1b4bf2;
}

.fill-inherit {
  fill: inherit;
}

.fill-lime-100 {
  fill: #ecfccb;
}

.fill-lime-100\/0 {
  fill: #ecfccb00;
}

.fill-lime-100\/10 {
  fill: #ecfccb1a;
}

.fill-lime-100\/100 {
  fill: #ecfccb;
}

.fill-lime-100\/20 {
  fill: #ecfccb33;
}

.fill-lime-100\/25 {
  fill: #ecfccb40;
}

.fill-lime-100\/30 {
  fill: #ecfccb4d;
}

.fill-lime-100\/40 {
  fill: #ecfccb66;
}

.fill-lime-100\/5 {
  fill: #ecfccb0d;
}

.fill-lime-100\/50 {
  fill: #ecfccb80;
}

.fill-lime-100\/60 {
  fill: #ecfccb99;
}

.fill-lime-100\/70 {
  fill: #ecfccbb3;
}

.fill-lime-100\/75 {
  fill: #ecfccbbf;
}

.fill-lime-100\/80 {
  fill: #ecfccbcc;
}

.fill-lime-100\/90 {
  fill: #ecfccbe6;
}

.fill-lime-100\/95 {
  fill: #ecfccbf2;
}

.fill-lime-200 {
  fill: #d9f99d;
}

.fill-lime-200\/0 {
  fill: #d9f99d00;
}

.fill-lime-200\/10 {
  fill: #d9f99d1a;
}

.fill-lime-200\/100 {
  fill: #d9f99d;
}

.fill-lime-200\/20 {
  fill: #d9f99d33;
}

.fill-lime-200\/25 {
  fill: #d9f99d40;
}

.fill-lime-200\/30 {
  fill: #d9f99d4d;
}

.fill-lime-200\/40 {
  fill: #d9f99d66;
}

.fill-lime-200\/5 {
  fill: #d9f99d0d;
}

.fill-lime-200\/50 {
  fill: #d9f99d80;
}

.fill-lime-200\/60 {
  fill: #d9f99d99;
}

.fill-lime-200\/70 {
  fill: #d9f99db3;
}

.fill-lime-200\/75 {
  fill: #d9f99dbf;
}

.fill-lime-200\/80 {
  fill: #d9f99dcc;
}

.fill-lime-200\/90 {
  fill: #d9f99de6;
}

.fill-lime-200\/95 {
  fill: #d9f99df2;
}

.fill-lime-300 {
  fill: #bef264;
}

.fill-lime-300\/0 {
  fill: #bef26400;
}

.fill-lime-300\/10 {
  fill: #bef2641a;
}

.fill-lime-300\/100 {
  fill: #bef264;
}

.fill-lime-300\/20 {
  fill: #bef26433;
}

.fill-lime-300\/25 {
  fill: #bef26440;
}

.fill-lime-300\/30 {
  fill: #bef2644d;
}

.fill-lime-300\/40 {
  fill: #bef26466;
}

.fill-lime-300\/5 {
  fill: #bef2640d;
}

.fill-lime-300\/50 {
  fill: #bef26480;
}

.fill-lime-300\/60 {
  fill: #bef26499;
}

.fill-lime-300\/70 {
  fill: #bef264b3;
}

.fill-lime-300\/75 {
  fill: #bef264bf;
}

.fill-lime-300\/80 {
  fill: #bef264cc;
}

.fill-lime-300\/90 {
  fill: #bef264e6;
}

.fill-lime-300\/95 {
  fill: #bef264f2;
}

.fill-lime-400 {
  fill: #a3e635;
}

.fill-lime-400\/0 {
  fill: #a3e63500;
}

.fill-lime-400\/10 {
  fill: #a3e6351a;
}

.fill-lime-400\/100 {
  fill: #a3e635;
}

.fill-lime-400\/20 {
  fill: #a3e63533;
}

.fill-lime-400\/25 {
  fill: #a3e63540;
}

.fill-lime-400\/30 {
  fill: #a3e6354d;
}

.fill-lime-400\/40 {
  fill: #a3e63566;
}

.fill-lime-400\/5 {
  fill: #a3e6350d;
}

.fill-lime-400\/50 {
  fill: #a3e63580;
}

.fill-lime-400\/60 {
  fill: #a3e63599;
}

.fill-lime-400\/70 {
  fill: #a3e635b3;
}

.fill-lime-400\/75 {
  fill: #a3e635bf;
}

.fill-lime-400\/80 {
  fill: #a3e635cc;
}

.fill-lime-400\/90 {
  fill: #a3e635e6;
}

.fill-lime-400\/95 {
  fill: #a3e635f2;
}

.fill-lime-50 {
  fill: #f7fee7;
}

.fill-lime-50\/0 {
  fill: #f7fee700;
}

.fill-lime-50\/10 {
  fill: #f7fee71a;
}

.fill-lime-50\/100 {
  fill: #f7fee7;
}

.fill-lime-50\/20 {
  fill: #f7fee733;
}

.fill-lime-50\/25 {
  fill: #f7fee740;
}

.fill-lime-50\/30 {
  fill: #f7fee74d;
}

.fill-lime-50\/40 {
  fill: #f7fee766;
}

.fill-lime-50\/5 {
  fill: #f7fee70d;
}

.fill-lime-50\/50 {
  fill: #f7fee780;
}

.fill-lime-50\/60 {
  fill: #f7fee799;
}

.fill-lime-50\/70 {
  fill: #f7fee7b3;
}

.fill-lime-50\/75 {
  fill: #f7fee7bf;
}

.fill-lime-50\/80 {
  fill: #f7fee7cc;
}

.fill-lime-50\/90 {
  fill: #f7fee7e6;
}

.fill-lime-50\/95 {
  fill: #f7fee7f2;
}

.fill-lime-500 {
  fill: #84cc16;
}

.fill-lime-500\/0 {
  fill: #84cc1600;
}

.fill-lime-500\/10 {
  fill: #84cc161a;
}

.fill-lime-500\/100 {
  fill: #84cc16;
}

.fill-lime-500\/20 {
  fill: #84cc1633;
}

.fill-lime-500\/25 {
  fill: #84cc1640;
}

.fill-lime-500\/30 {
  fill: #84cc164d;
}

.fill-lime-500\/40 {
  fill: #84cc1666;
}

.fill-lime-500\/5 {
  fill: #84cc160d;
}

.fill-lime-500\/50 {
  fill: #84cc1680;
}

.fill-lime-500\/60 {
  fill: #84cc1699;
}

.fill-lime-500\/70 {
  fill: #84cc16b3;
}

.fill-lime-500\/75 {
  fill: #84cc16bf;
}

.fill-lime-500\/80 {
  fill: #84cc16cc;
}

.fill-lime-500\/90 {
  fill: #84cc16e6;
}

.fill-lime-500\/95 {
  fill: #84cc16f2;
}

.fill-lime-600 {
  fill: #65a30d;
}

.fill-lime-600\/0 {
  fill: #65a30d00;
}

.fill-lime-600\/10 {
  fill: #65a30d1a;
}

.fill-lime-600\/100 {
  fill: #65a30d;
}

.fill-lime-600\/20 {
  fill: #65a30d33;
}

.fill-lime-600\/25 {
  fill: #65a30d40;
}

.fill-lime-600\/30 {
  fill: #65a30d4d;
}

.fill-lime-600\/40 {
  fill: #65a30d66;
}

.fill-lime-600\/5 {
  fill: #65a30d0d;
}

.fill-lime-600\/50 {
  fill: #65a30d80;
}

.fill-lime-600\/60 {
  fill: #65a30d99;
}

.fill-lime-600\/70 {
  fill: #65a30db3;
}

.fill-lime-600\/75 {
  fill: #65a30dbf;
}

.fill-lime-600\/80 {
  fill: #65a30dcc;
}

.fill-lime-600\/90 {
  fill: #65a30de6;
}

.fill-lime-600\/95 {
  fill: #65a30df2;
}

.fill-lime-700 {
  fill: #4d7c0f;
}

.fill-lime-700\/0 {
  fill: #4d7c0f00;
}

.fill-lime-700\/10 {
  fill: #4d7c0f1a;
}

.fill-lime-700\/100 {
  fill: #4d7c0f;
}

.fill-lime-700\/20 {
  fill: #4d7c0f33;
}

.fill-lime-700\/25 {
  fill: #4d7c0f40;
}

.fill-lime-700\/30 {
  fill: #4d7c0f4d;
}

.fill-lime-700\/40 {
  fill: #4d7c0f66;
}

.fill-lime-700\/5 {
  fill: #4d7c0f0d;
}

.fill-lime-700\/50 {
  fill: #4d7c0f80;
}

.fill-lime-700\/60 {
  fill: #4d7c0f99;
}

.fill-lime-700\/70 {
  fill: #4d7c0fb3;
}

.fill-lime-700\/75 {
  fill: #4d7c0fbf;
}

.fill-lime-700\/80 {
  fill: #4d7c0fcc;
}

.fill-lime-700\/90 {
  fill: #4d7c0fe6;
}

.fill-lime-700\/95 {
  fill: #4d7c0ff2;
}

.fill-lime-800 {
  fill: #3f6212;
}

.fill-lime-800\/0 {
  fill: #3f621200;
}

.fill-lime-800\/10 {
  fill: #3f62121a;
}

.fill-lime-800\/100 {
  fill: #3f6212;
}

.fill-lime-800\/20 {
  fill: #3f621233;
}

.fill-lime-800\/25 {
  fill: #3f621240;
}

.fill-lime-800\/30 {
  fill: #3f62124d;
}

.fill-lime-800\/40 {
  fill: #3f621266;
}

.fill-lime-800\/5 {
  fill: #3f62120d;
}

.fill-lime-800\/50 {
  fill: #3f621280;
}

.fill-lime-800\/60 {
  fill: #3f621299;
}

.fill-lime-800\/70 {
  fill: #3f6212b3;
}

.fill-lime-800\/75 {
  fill: #3f6212bf;
}

.fill-lime-800\/80 {
  fill: #3f6212cc;
}

.fill-lime-800\/90 {
  fill: #3f6212e6;
}

.fill-lime-800\/95 {
  fill: #3f6212f2;
}

.fill-lime-900 {
  fill: #365314;
}

.fill-lime-900\/0 {
  fill: #36531400;
}

.fill-lime-900\/10 {
  fill: #3653141a;
}

.fill-lime-900\/100 {
  fill: #365314;
}

.fill-lime-900\/20 {
  fill: #36531433;
}

.fill-lime-900\/25 {
  fill: #36531440;
}

.fill-lime-900\/30 {
  fill: #3653144d;
}

.fill-lime-900\/40 {
  fill: #36531466;
}

.fill-lime-900\/5 {
  fill: #3653140d;
}

.fill-lime-900\/50 {
  fill: #36531480;
}

.fill-lime-900\/60 {
  fill: #36531499;
}

.fill-lime-900\/70 {
  fill: #365314b3;
}

.fill-lime-900\/75 {
  fill: #365314bf;
}

.fill-lime-900\/80 {
  fill: #365314cc;
}

.fill-lime-900\/90 {
  fill: #365314e6;
}

.fill-lime-900\/95 {
  fill: #365314f2;
}

.fill-lime-950 {
  fill: #1a2e05;
}

.fill-lime-950\/0 {
  fill: #1a2e0500;
}

.fill-lime-950\/10 {
  fill: #1a2e051a;
}

.fill-lime-950\/100 {
  fill: #1a2e05;
}

.fill-lime-950\/20 {
  fill: #1a2e0533;
}

.fill-lime-950\/25 {
  fill: #1a2e0540;
}

.fill-lime-950\/30 {
  fill: #1a2e054d;
}

.fill-lime-950\/40 {
  fill: #1a2e0566;
}

.fill-lime-950\/5 {
  fill: #1a2e050d;
}

.fill-lime-950\/50 {
  fill: #1a2e0580;
}

.fill-lime-950\/60 {
  fill: #1a2e0599;
}

.fill-lime-950\/70 {
  fill: #1a2e05b3;
}

.fill-lime-950\/75 {
  fill: #1a2e05bf;
}

.fill-lime-950\/80 {
  fill: #1a2e05cc;
}

.fill-lime-950\/90 {
  fill: #1a2e05e6;
}

.fill-lime-950\/95 {
  fill: #1a2e05f2;
}

.fill-neutral-100 {
  fill: #f5f5f5;
}

.fill-neutral-100\/0 {
  fill: #f5f5f500;
}

.fill-neutral-100\/10 {
  fill: #f5f5f51a;
}

.fill-neutral-100\/100 {
  fill: #f5f5f5;
}

.fill-neutral-100\/20 {
  fill: #f5f5f533;
}

.fill-neutral-100\/25 {
  fill: #f5f5f540;
}

.fill-neutral-100\/30 {
  fill: #f5f5f54d;
}

.fill-neutral-100\/40 {
  fill: #f5f5f566;
}

.fill-neutral-100\/5 {
  fill: #f5f5f50d;
}

.fill-neutral-100\/50 {
  fill: #f5f5f580;
}

.fill-neutral-100\/60 {
  fill: #f5f5f599;
}

.fill-neutral-100\/70 {
  fill: #f5f5f5b3;
}

.fill-neutral-100\/75 {
  fill: #f5f5f5bf;
}

.fill-neutral-100\/80 {
  fill: #f5f5f5cc;
}

.fill-neutral-100\/90 {
  fill: #f5f5f5e6;
}

.fill-neutral-100\/95 {
  fill: #f5f5f5f2;
}

.fill-neutral-200 {
  fill: #e5e5e5;
}

.fill-neutral-200\/0 {
  fill: #e5e5e500;
}

.fill-neutral-200\/10 {
  fill: #e5e5e51a;
}

.fill-neutral-200\/100 {
  fill: #e5e5e5;
}

.fill-neutral-200\/20 {
  fill: #e5e5e533;
}

.fill-neutral-200\/25 {
  fill: #e5e5e540;
}

.fill-neutral-200\/30 {
  fill: #e5e5e54d;
}

.fill-neutral-200\/40 {
  fill: #e5e5e566;
}

.fill-neutral-200\/5 {
  fill: #e5e5e50d;
}

.fill-neutral-200\/50 {
  fill: #e5e5e580;
}

.fill-neutral-200\/60 {
  fill: #e5e5e599;
}

.fill-neutral-200\/70 {
  fill: #e5e5e5b3;
}

.fill-neutral-200\/75 {
  fill: #e5e5e5bf;
}

.fill-neutral-200\/80 {
  fill: #e5e5e5cc;
}

.fill-neutral-200\/90 {
  fill: #e5e5e5e6;
}

.fill-neutral-200\/95 {
  fill: #e5e5e5f2;
}

.fill-neutral-300 {
  fill: #d4d4d4;
}

.fill-neutral-300\/0 {
  fill: #d4d4d400;
}

.fill-neutral-300\/10 {
  fill: #d4d4d41a;
}

.fill-neutral-300\/100 {
  fill: #d4d4d4;
}

.fill-neutral-300\/20 {
  fill: #d4d4d433;
}

.fill-neutral-300\/25 {
  fill: #d4d4d440;
}

.fill-neutral-300\/30 {
  fill: #d4d4d44d;
}

.fill-neutral-300\/40 {
  fill: #d4d4d466;
}

.fill-neutral-300\/5 {
  fill: #d4d4d40d;
}

.fill-neutral-300\/50 {
  fill: #d4d4d480;
}

.fill-neutral-300\/60 {
  fill: #d4d4d499;
}

.fill-neutral-300\/70 {
  fill: #d4d4d4b3;
}

.fill-neutral-300\/75 {
  fill: #d4d4d4bf;
}

.fill-neutral-300\/80 {
  fill: #d4d4d4cc;
}

.fill-neutral-300\/90 {
  fill: #d4d4d4e6;
}

.fill-neutral-300\/95 {
  fill: #d4d4d4f2;
}

.fill-neutral-400 {
  fill: #a3a3a3;
}

.fill-neutral-400\/0 {
  fill: #a3a3a300;
}

.fill-neutral-400\/10 {
  fill: #a3a3a31a;
}

.fill-neutral-400\/100 {
  fill: #a3a3a3;
}

.fill-neutral-400\/20 {
  fill: #a3a3a333;
}

.fill-neutral-400\/25 {
  fill: #a3a3a340;
}

.fill-neutral-400\/30 {
  fill: #a3a3a34d;
}

.fill-neutral-400\/40 {
  fill: #a3a3a366;
}

.fill-neutral-400\/5 {
  fill: #a3a3a30d;
}

.fill-neutral-400\/50 {
  fill: #a3a3a380;
}

.fill-neutral-400\/60 {
  fill: #a3a3a399;
}

.fill-neutral-400\/70 {
  fill: #a3a3a3b3;
}

.fill-neutral-400\/75 {
  fill: #a3a3a3bf;
}

.fill-neutral-400\/80 {
  fill: #a3a3a3cc;
}

.fill-neutral-400\/90 {
  fill: #a3a3a3e6;
}

.fill-neutral-400\/95 {
  fill: #a3a3a3f2;
}

.fill-neutral-50 {
  fill: #fafafa;
}

.fill-neutral-50\/0 {
  fill: #fafafa00;
}

.fill-neutral-50\/10 {
  fill: #fafafa1a;
}

.fill-neutral-50\/100 {
  fill: #fafafa;
}

.fill-neutral-50\/20 {
  fill: #fafafa33;
}

.fill-neutral-50\/25 {
  fill: #fafafa40;
}

.fill-neutral-50\/30 {
  fill: #fafafa4d;
}

.fill-neutral-50\/40 {
  fill: #fafafa66;
}

.fill-neutral-50\/5 {
  fill: #fafafa0d;
}

.fill-neutral-50\/50 {
  fill: #fafafa80;
}

.fill-neutral-50\/60 {
  fill: #fafafa99;
}

.fill-neutral-50\/70 {
  fill: #fafafab3;
}

.fill-neutral-50\/75 {
  fill: #fafafabf;
}

.fill-neutral-50\/80 {
  fill: #fafafacc;
}

.fill-neutral-50\/90 {
  fill: #fafafae6;
}

.fill-neutral-50\/95 {
  fill: #fafafaf2;
}

.fill-neutral-500 {
  fill: #737373;
}

.fill-neutral-500\/0 {
  fill: #73737300;
}

.fill-neutral-500\/10 {
  fill: #7373731a;
}

.fill-neutral-500\/100 {
  fill: #737373;
}

.fill-neutral-500\/20 {
  fill: #73737333;
}

.fill-neutral-500\/25 {
  fill: #73737340;
}

.fill-neutral-500\/30 {
  fill: #7373734d;
}

.fill-neutral-500\/40 {
  fill: #73737366;
}

.fill-neutral-500\/5 {
  fill: #7373730d;
}

.fill-neutral-500\/50 {
  fill: #73737380;
}

.fill-neutral-500\/60 {
  fill: #73737399;
}

.fill-neutral-500\/70 {
  fill: #737373b3;
}

.fill-neutral-500\/75 {
  fill: #737373bf;
}

.fill-neutral-500\/80 {
  fill: #737373cc;
}

.fill-neutral-500\/90 {
  fill: #737373e6;
}

.fill-neutral-500\/95 {
  fill: #737373f2;
}

.fill-neutral-600 {
  fill: #525252;
}

.fill-neutral-600\/0 {
  fill: #52525200;
}

.fill-neutral-600\/10 {
  fill: #5252521a;
}

.fill-neutral-600\/100 {
  fill: #525252;
}

.fill-neutral-600\/20 {
  fill: #52525233;
}

.fill-neutral-600\/25 {
  fill: #52525240;
}

.fill-neutral-600\/30 {
  fill: #5252524d;
}

.fill-neutral-600\/40 {
  fill: #52525266;
}

.fill-neutral-600\/5 {
  fill: #5252520d;
}

.fill-neutral-600\/50 {
  fill: #52525280;
}

.fill-neutral-600\/60 {
  fill: #52525299;
}

.fill-neutral-600\/70 {
  fill: #525252b3;
}

.fill-neutral-600\/75 {
  fill: #525252bf;
}

.fill-neutral-600\/80 {
  fill: #525252cc;
}

.fill-neutral-600\/90 {
  fill: #525252e6;
}

.fill-neutral-600\/95 {
  fill: #525252f2;
}

.fill-neutral-700 {
  fill: #404040;
}

.fill-neutral-700\/0 {
  fill: #40404000;
}

.fill-neutral-700\/10 {
  fill: #4040401a;
}

.fill-neutral-700\/100 {
  fill: #404040;
}

.fill-neutral-700\/20 {
  fill: #40404033;
}

.fill-neutral-700\/25 {
  fill: #40404040;
}

.fill-neutral-700\/30 {
  fill: #4040404d;
}

.fill-neutral-700\/40 {
  fill: #40404066;
}

.fill-neutral-700\/5 {
  fill: #4040400d;
}

.fill-neutral-700\/50 {
  fill: #40404080;
}

.fill-neutral-700\/60 {
  fill: #40404099;
}

.fill-neutral-700\/70 {
  fill: #404040b3;
}

.fill-neutral-700\/75 {
  fill: #404040bf;
}

.fill-neutral-700\/80 {
  fill: #404040cc;
}

.fill-neutral-700\/90 {
  fill: #404040e6;
}

.fill-neutral-700\/95 {
  fill: #404040f2;
}

.fill-neutral-800 {
  fill: #262626;
}

.fill-neutral-800\/0 {
  fill: #26262600;
}

.fill-neutral-800\/10 {
  fill: #2626261a;
}

.fill-neutral-800\/100 {
  fill: #262626;
}

.fill-neutral-800\/20 {
  fill: #26262633;
}

.fill-neutral-800\/25 {
  fill: #26262640;
}

.fill-neutral-800\/30 {
  fill: #2626264d;
}

.fill-neutral-800\/40 {
  fill: #26262666;
}

.fill-neutral-800\/5 {
  fill: #2626260d;
}

.fill-neutral-800\/50 {
  fill: #26262680;
}

.fill-neutral-800\/60 {
  fill: #26262699;
}

.fill-neutral-800\/70 {
  fill: #262626b3;
}

.fill-neutral-800\/75 {
  fill: #262626bf;
}

.fill-neutral-800\/80 {
  fill: #262626cc;
}

.fill-neutral-800\/90 {
  fill: #262626e6;
}

.fill-neutral-800\/95 {
  fill: #262626f2;
}

.fill-neutral-900 {
  fill: #171717;
}

.fill-neutral-900\/0 {
  fill: #17171700;
}

.fill-neutral-900\/10 {
  fill: #1717171a;
}

.fill-neutral-900\/100 {
  fill: #171717;
}

.fill-neutral-900\/20 {
  fill: #17171733;
}

.fill-neutral-900\/25 {
  fill: #17171740;
}

.fill-neutral-900\/30 {
  fill: #1717174d;
}

.fill-neutral-900\/40 {
  fill: #17171766;
}

.fill-neutral-900\/5 {
  fill: #1717170d;
}

.fill-neutral-900\/50 {
  fill: #17171780;
}

.fill-neutral-900\/60 {
  fill: #17171799;
}

.fill-neutral-900\/70 {
  fill: #171717b3;
}

.fill-neutral-900\/75 {
  fill: #171717bf;
}

.fill-neutral-900\/80 {
  fill: #171717cc;
}

.fill-neutral-900\/90 {
  fill: #171717e6;
}

.fill-neutral-900\/95 {
  fill: #171717f2;
}

.fill-neutral-950 {
  fill: #0a0a0a;
}

.fill-neutral-950\/0 {
  fill: #0a0a0a00;
}

.fill-neutral-950\/10 {
  fill: #0a0a0a1a;
}

.fill-neutral-950\/100 {
  fill: #0a0a0a;
}

.fill-neutral-950\/20 {
  fill: #0a0a0a33;
}

.fill-neutral-950\/25 {
  fill: #0a0a0a40;
}

.fill-neutral-950\/30 {
  fill: #0a0a0a4d;
}

.fill-neutral-950\/40 {
  fill: #0a0a0a66;
}

.fill-neutral-950\/5 {
  fill: #0a0a0a0d;
}

.fill-neutral-950\/50 {
  fill: #0a0a0a80;
}

.fill-neutral-950\/60 {
  fill: #0a0a0a99;
}

.fill-neutral-950\/70 {
  fill: #0a0a0ab3;
}

.fill-neutral-950\/75 {
  fill: #0a0a0abf;
}

.fill-neutral-950\/80 {
  fill: #0a0a0acc;
}

.fill-neutral-950\/90 {
  fill: #0a0a0ae6;
}

.fill-neutral-950\/95 {
  fill: #0a0a0af2;
}

.fill-none {
  fill: none;
}

.fill-orange-100 {
  fill: #ffedd5;
}

.fill-orange-100\/0 {
  fill: #ffedd500;
}

.fill-orange-100\/10 {
  fill: #ffedd51a;
}

.fill-orange-100\/100 {
  fill: #ffedd5;
}

.fill-orange-100\/20 {
  fill: #ffedd533;
}

.fill-orange-100\/25 {
  fill: #ffedd540;
}

.fill-orange-100\/30 {
  fill: #ffedd54d;
}

.fill-orange-100\/40 {
  fill: #ffedd566;
}

.fill-orange-100\/5 {
  fill: #ffedd50d;
}

.fill-orange-100\/50 {
  fill: #ffedd580;
}

.fill-orange-100\/60 {
  fill: #ffedd599;
}

.fill-orange-100\/70 {
  fill: #ffedd5b3;
}

.fill-orange-100\/75 {
  fill: #ffedd5bf;
}

.fill-orange-100\/80 {
  fill: #ffedd5cc;
}

.fill-orange-100\/90 {
  fill: #ffedd5e6;
}

.fill-orange-100\/95 {
  fill: #ffedd5f2;
}

.fill-orange-200 {
  fill: #fed7aa;
}

.fill-orange-200\/0 {
  fill: #fed7aa00;
}

.fill-orange-200\/10 {
  fill: #fed7aa1a;
}

.fill-orange-200\/100 {
  fill: #fed7aa;
}

.fill-orange-200\/20 {
  fill: #fed7aa33;
}

.fill-orange-200\/25 {
  fill: #fed7aa40;
}

.fill-orange-200\/30 {
  fill: #fed7aa4d;
}

.fill-orange-200\/40 {
  fill: #fed7aa66;
}

.fill-orange-200\/5 {
  fill: #fed7aa0d;
}

.fill-orange-200\/50 {
  fill: #fed7aa80;
}

.fill-orange-200\/60 {
  fill: #fed7aa99;
}

.fill-orange-200\/70 {
  fill: #fed7aab3;
}

.fill-orange-200\/75 {
  fill: #fed7aabf;
}

.fill-orange-200\/80 {
  fill: #fed7aacc;
}

.fill-orange-200\/90 {
  fill: #fed7aae6;
}

.fill-orange-200\/95 {
  fill: #fed7aaf2;
}

.fill-orange-300 {
  fill: #fdba74;
}

.fill-orange-300\/0 {
  fill: #fdba7400;
}

.fill-orange-300\/10 {
  fill: #fdba741a;
}

.fill-orange-300\/100 {
  fill: #fdba74;
}

.fill-orange-300\/20 {
  fill: #fdba7433;
}

.fill-orange-300\/25 {
  fill: #fdba7440;
}

.fill-orange-300\/30 {
  fill: #fdba744d;
}

.fill-orange-300\/40 {
  fill: #fdba7466;
}

.fill-orange-300\/5 {
  fill: #fdba740d;
}

.fill-orange-300\/50 {
  fill: #fdba7480;
}

.fill-orange-300\/60 {
  fill: #fdba7499;
}

.fill-orange-300\/70 {
  fill: #fdba74b3;
}

.fill-orange-300\/75 {
  fill: #fdba74bf;
}

.fill-orange-300\/80 {
  fill: #fdba74cc;
}

.fill-orange-300\/90 {
  fill: #fdba74e6;
}

.fill-orange-300\/95 {
  fill: #fdba74f2;
}

.fill-orange-400 {
  fill: #fb923c;
}

.fill-orange-400\/0 {
  fill: #fb923c00;
}

.fill-orange-400\/10 {
  fill: #fb923c1a;
}

.fill-orange-400\/100 {
  fill: #fb923c;
}

.fill-orange-400\/20 {
  fill: #fb923c33;
}

.fill-orange-400\/25 {
  fill: #fb923c40;
}

.fill-orange-400\/30 {
  fill: #fb923c4d;
}

.fill-orange-400\/40 {
  fill: #fb923c66;
}

.fill-orange-400\/5 {
  fill: #fb923c0d;
}

.fill-orange-400\/50 {
  fill: #fb923c80;
}

.fill-orange-400\/60 {
  fill: #fb923c99;
}

.fill-orange-400\/70 {
  fill: #fb923cb3;
}

.fill-orange-400\/75 {
  fill: #fb923cbf;
}

.fill-orange-400\/80 {
  fill: #fb923ccc;
}

.fill-orange-400\/90 {
  fill: #fb923ce6;
}

.fill-orange-400\/95 {
  fill: #fb923cf2;
}

.fill-orange-50 {
  fill: #fff7ed;
}

.fill-orange-50\/0 {
  fill: #fff7ed00;
}

.fill-orange-50\/10 {
  fill: #fff7ed1a;
}

.fill-orange-50\/100 {
  fill: #fff7ed;
}

.fill-orange-50\/20 {
  fill: #fff7ed33;
}

.fill-orange-50\/25 {
  fill: #fff7ed40;
}

.fill-orange-50\/30 {
  fill: #fff7ed4d;
}

.fill-orange-50\/40 {
  fill: #fff7ed66;
}

.fill-orange-50\/5 {
  fill: #fff7ed0d;
}

.fill-orange-50\/50 {
  fill: #fff7ed80;
}

.fill-orange-50\/60 {
  fill: #fff7ed99;
}

.fill-orange-50\/70 {
  fill: #fff7edb3;
}

.fill-orange-50\/75 {
  fill: #fff7edbf;
}

.fill-orange-50\/80 {
  fill: #fff7edcc;
}

.fill-orange-50\/90 {
  fill: #fff7ede6;
}

.fill-orange-50\/95 {
  fill: #fff7edf2;
}

.fill-orange-500 {
  fill: #f97316;
}

.fill-orange-500\/0 {
  fill: #f9731600;
}

.fill-orange-500\/10 {
  fill: #f973161a;
}

.fill-orange-500\/100 {
  fill: #f97316;
}

.fill-orange-500\/20 {
  fill: #f9731633;
}

.fill-orange-500\/25 {
  fill: #f9731640;
}

.fill-orange-500\/30 {
  fill: #f973164d;
}

.fill-orange-500\/40 {
  fill: #f9731666;
}

.fill-orange-500\/5 {
  fill: #f973160d;
}

.fill-orange-500\/50 {
  fill: #f9731680;
}

.fill-orange-500\/60 {
  fill: #f9731699;
}

.fill-orange-500\/70 {
  fill: #f97316b3;
}

.fill-orange-500\/75 {
  fill: #f97316bf;
}

.fill-orange-500\/80 {
  fill: #f97316cc;
}

.fill-orange-500\/90 {
  fill: #f97316e6;
}

.fill-orange-500\/95 {
  fill: #f97316f2;
}

.fill-orange-600 {
  fill: #ea580c;
}

.fill-orange-600\/0 {
  fill: #ea580c00;
}

.fill-orange-600\/10 {
  fill: #ea580c1a;
}

.fill-orange-600\/100 {
  fill: #ea580c;
}

.fill-orange-600\/20 {
  fill: #ea580c33;
}

.fill-orange-600\/25 {
  fill: #ea580c40;
}

.fill-orange-600\/30 {
  fill: #ea580c4d;
}

.fill-orange-600\/40 {
  fill: #ea580c66;
}

.fill-orange-600\/5 {
  fill: #ea580c0d;
}

.fill-orange-600\/50 {
  fill: #ea580c80;
}

.fill-orange-600\/60 {
  fill: #ea580c99;
}

.fill-orange-600\/70 {
  fill: #ea580cb3;
}

.fill-orange-600\/75 {
  fill: #ea580cbf;
}

.fill-orange-600\/80 {
  fill: #ea580ccc;
}

.fill-orange-600\/90 {
  fill: #ea580ce6;
}

.fill-orange-600\/95 {
  fill: #ea580cf2;
}

.fill-orange-700 {
  fill: #c2410c;
}

.fill-orange-700\/0 {
  fill: #c2410c00;
}

.fill-orange-700\/10 {
  fill: #c2410c1a;
}

.fill-orange-700\/100 {
  fill: #c2410c;
}

.fill-orange-700\/20 {
  fill: #c2410c33;
}

.fill-orange-700\/25 {
  fill: #c2410c40;
}

.fill-orange-700\/30 {
  fill: #c2410c4d;
}

.fill-orange-700\/40 {
  fill: #c2410c66;
}

.fill-orange-700\/5 {
  fill: #c2410c0d;
}

.fill-orange-700\/50 {
  fill: #c2410c80;
}

.fill-orange-700\/60 {
  fill: #c2410c99;
}

.fill-orange-700\/70 {
  fill: #c2410cb3;
}

.fill-orange-700\/75 {
  fill: #c2410cbf;
}

.fill-orange-700\/80 {
  fill: #c2410ccc;
}

.fill-orange-700\/90 {
  fill: #c2410ce6;
}

.fill-orange-700\/95 {
  fill: #c2410cf2;
}

.fill-orange-800 {
  fill: #9a3412;
}

.fill-orange-800\/0 {
  fill: #9a341200;
}

.fill-orange-800\/10 {
  fill: #9a34121a;
}

.fill-orange-800\/100 {
  fill: #9a3412;
}

.fill-orange-800\/20 {
  fill: #9a341233;
}

.fill-orange-800\/25 {
  fill: #9a341240;
}

.fill-orange-800\/30 {
  fill: #9a34124d;
}

.fill-orange-800\/40 {
  fill: #9a341266;
}

.fill-orange-800\/5 {
  fill: #9a34120d;
}

.fill-orange-800\/50 {
  fill: #9a341280;
}

.fill-orange-800\/60 {
  fill: #9a341299;
}

.fill-orange-800\/70 {
  fill: #9a3412b3;
}

.fill-orange-800\/75 {
  fill: #9a3412bf;
}

.fill-orange-800\/80 {
  fill: #9a3412cc;
}

.fill-orange-800\/90 {
  fill: #9a3412e6;
}

.fill-orange-800\/95 {
  fill: #9a3412f2;
}

.fill-orange-900 {
  fill: #7c2d12;
}

.fill-orange-900\/0 {
  fill: #7c2d1200;
}

.fill-orange-900\/10 {
  fill: #7c2d121a;
}

.fill-orange-900\/100 {
  fill: #7c2d12;
}

.fill-orange-900\/20 {
  fill: #7c2d1233;
}

.fill-orange-900\/25 {
  fill: #7c2d1240;
}

.fill-orange-900\/30 {
  fill: #7c2d124d;
}

.fill-orange-900\/40 {
  fill: #7c2d1266;
}

.fill-orange-900\/5 {
  fill: #7c2d120d;
}

.fill-orange-900\/50 {
  fill: #7c2d1280;
}

.fill-orange-900\/60 {
  fill: #7c2d1299;
}

.fill-orange-900\/70 {
  fill: #7c2d12b3;
}

.fill-orange-900\/75 {
  fill: #7c2d12bf;
}

.fill-orange-900\/80 {
  fill: #7c2d12cc;
}

.fill-orange-900\/90 {
  fill: #7c2d12e6;
}

.fill-orange-900\/95 {
  fill: #7c2d12f2;
}

.fill-orange-950 {
  fill: #431407;
}

.fill-orange-950\/0 {
  fill: #43140700;
}

.fill-orange-950\/10 {
  fill: #4314071a;
}

.fill-orange-950\/100 {
  fill: #431407;
}

.fill-orange-950\/20 {
  fill: #43140733;
}

.fill-orange-950\/25 {
  fill: #43140740;
}

.fill-orange-950\/30 {
  fill: #4314074d;
}

.fill-orange-950\/40 {
  fill: #43140766;
}

.fill-orange-950\/5 {
  fill: #4314070d;
}

.fill-orange-950\/50 {
  fill: #43140780;
}

.fill-orange-950\/60 {
  fill: #43140799;
}

.fill-orange-950\/70 {
  fill: #431407b3;
}

.fill-orange-950\/75 {
  fill: #431407bf;
}

.fill-orange-950\/80 {
  fill: #431407cc;
}

.fill-orange-950\/90 {
  fill: #431407e6;
}

.fill-orange-950\/95 {
  fill: #431407f2;
}

.fill-pink-100 {
  fill: #fce7f3;
}

.fill-pink-100\/0 {
  fill: #fce7f300;
}

.fill-pink-100\/10 {
  fill: #fce7f31a;
}

.fill-pink-100\/100 {
  fill: #fce7f3;
}

.fill-pink-100\/20 {
  fill: #fce7f333;
}

.fill-pink-100\/25 {
  fill: #fce7f340;
}

.fill-pink-100\/30 {
  fill: #fce7f34d;
}

.fill-pink-100\/40 {
  fill: #fce7f366;
}

.fill-pink-100\/5 {
  fill: #fce7f30d;
}

.fill-pink-100\/50 {
  fill: #fce7f380;
}

.fill-pink-100\/60 {
  fill: #fce7f399;
}

.fill-pink-100\/70 {
  fill: #fce7f3b3;
}

.fill-pink-100\/75 {
  fill: #fce7f3bf;
}

.fill-pink-100\/80 {
  fill: #fce7f3cc;
}

.fill-pink-100\/90 {
  fill: #fce7f3e6;
}

.fill-pink-100\/95 {
  fill: #fce7f3f2;
}

.fill-pink-200 {
  fill: #fbcfe8;
}

.fill-pink-200\/0 {
  fill: #fbcfe800;
}

.fill-pink-200\/10 {
  fill: #fbcfe81a;
}

.fill-pink-200\/100 {
  fill: #fbcfe8;
}

.fill-pink-200\/20 {
  fill: #fbcfe833;
}

.fill-pink-200\/25 {
  fill: #fbcfe840;
}

.fill-pink-200\/30 {
  fill: #fbcfe84d;
}

.fill-pink-200\/40 {
  fill: #fbcfe866;
}

.fill-pink-200\/5 {
  fill: #fbcfe80d;
}

.fill-pink-200\/50 {
  fill: #fbcfe880;
}

.fill-pink-200\/60 {
  fill: #fbcfe899;
}

.fill-pink-200\/70 {
  fill: #fbcfe8b3;
}

.fill-pink-200\/75 {
  fill: #fbcfe8bf;
}

.fill-pink-200\/80 {
  fill: #fbcfe8cc;
}

.fill-pink-200\/90 {
  fill: #fbcfe8e6;
}

.fill-pink-200\/95 {
  fill: #fbcfe8f2;
}

.fill-pink-300 {
  fill: #f9a8d4;
}

.fill-pink-300\/0 {
  fill: #f9a8d400;
}

.fill-pink-300\/10 {
  fill: #f9a8d41a;
}

.fill-pink-300\/100 {
  fill: #f9a8d4;
}

.fill-pink-300\/20 {
  fill: #f9a8d433;
}

.fill-pink-300\/25 {
  fill: #f9a8d440;
}

.fill-pink-300\/30 {
  fill: #f9a8d44d;
}

.fill-pink-300\/40 {
  fill: #f9a8d466;
}

.fill-pink-300\/5 {
  fill: #f9a8d40d;
}

.fill-pink-300\/50 {
  fill: #f9a8d480;
}

.fill-pink-300\/60 {
  fill: #f9a8d499;
}

.fill-pink-300\/70 {
  fill: #f9a8d4b3;
}

.fill-pink-300\/75 {
  fill: #f9a8d4bf;
}

.fill-pink-300\/80 {
  fill: #f9a8d4cc;
}

.fill-pink-300\/90 {
  fill: #f9a8d4e6;
}

.fill-pink-300\/95 {
  fill: #f9a8d4f2;
}

.fill-pink-400 {
  fill: #f472b6;
}

.fill-pink-400\/0 {
  fill: #f472b600;
}

.fill-pink-400\/10 {
  fill: #f472b61a;
}

.fill-pink-400\/100 {
  fill: #f472b6;
}

.fill-pink-400\/20 {
  fill: #f472b633;
}

.fill-pink-400\/25 {
  fill: #f472b640;
}

.fill-pink-400\/30 {
  fill: #f472b64d;
}

.fill-pink-400\/40 {
  fill: #f472b666;
}

.fill-pink-400\/5 {
  fill: #f472b60d;
}

.fill-pink-400\/50 {
  fill: #f472b680;
}

.fill-pink-400\/60 {
  fill: #f472b699;
}

.fill-pink-400\/70 {
  fill: #f472b6b3;
}

.fill-pink-400\/75 {
  fill: #f472b6bf;
}

.fill-pink-400\/80 {
  fill: #f472b6cc;
}

.fill-pink-400\/90 {
  fill: #f472b6e6;
}

.fill-pink-400\/95 {
  fill: #f472b6f2;
}

.fill-pink-50 {
  fill: #fdf2f8;
}

.fill-pink-50\/0 {
  fill: #fdf2f800;
}

.fill-pink-50\/10 {
  fill: #fdf2f81a;
}

.fill-pink-50\/100 {
  fill: #fdf2f8;
}

.fill-pink-50\/20 {
  fill: #fdf2f833;
}

.fill-pink-50\/25 {
  fill: #fdf2f840;
}

.fill-pink-50\/30 {
  fill: #fdf2f84d;
}

.fill-pink-50\/40 {
  fill: #fdf2f866;
}

.fill-pink-50\/5 {
  fill: #fdf2f80d;
}

.fill-pink-50\/50 {
  fill: #fdf2f880;
}

.fill-pink-50\/60 {
  fill: #fdf2f899;
}

.fill-pink-50\/70 {
  fill: #fdf2f8b3;
}

.fill-pink-50\/75 {
  fill: #fdf2f8bf;
}

.fill-pink-50\/80 {
  fill: #fdf2f8cc;
}

.fill-pink-50\/90 {
  fill: #fdf2f8e6;
}

.fill-pink-50\/95 {
  fill: #fdf2f8f2;
}

.fill-pink-500 {
  fill: #ec4899;
}

.fill-pink-500\/0 {
  fill: #ec489900;
}

.fill-pink-500\/10 {
  fill: #ec48991a;
}

.fill-pink-500\/100 {
  fill: #ec4899;
}

.fill-pink-500\/20 {
  fill: #ec489933;
}

.fill-pink-500\/25 {
  fill: #ec489940;
}

.fill-pink-500\/30 {
  fill: #ec48994d;
}

.fill-pink-500\/40 {
  fill: #ec489966;
}

.fill-pink-500\/5 {
  fill: #ec48990d;
}

.fill-pink-500\/50 {
  fill: #ec489980;
}

.fill-pink-500\/60 {
  fill: #ec489999;
}

.fill-pink-500\/70 {
  fill: #ec4899b3;
}

.fill-pink-500\/75 {
  fill: #ec4899bf;
}

.fill-pink-500\/80 {
  fill: #ec4899cc;
}

.fill-pink-500\/90 {
  fill: #ec4899e6;
}

.fill-pink-500\/95 {
  fill: #ec4899f2;
}

.fill-pink-600 {
  fill: #db2777;
}

.fill-pink-600\/0 {
  fill: #db277700;
}

.fill-pink-600\/10 {
  fill: #db27771a;
}

.fill-pink-600\/100 {
  fill: #db2777;
}

.fill-pink-600\/20 {
  fill: #db277733;
}

.fill-pink-600\/25 {
  fill: #db277740;
}

.fill-pink-600\/30 {
  fill: #db27774d;
}

.fill-pink-600\/40 {
  fill: #db277766;
}

.fill-pink-600\/5 {
  fill: #db27770d;
}

.fill-pink-600\/50 {
  fill: #db277780;
}

.fill-pink-600\/60 {
  fill: #db277799;
}

.fill-pink-600\/70 {
  fill: #db2777b3;
}

.fill-pink-600\/75 {
  fill: #db2777bf;
}

.fill-pink-600\/80 {
  fill: #db2777cc;
}

.fill-pink-600\/90 {
  fill: #db2777e6;
}

.fill-pink-600\/95 {
  fill: #db2777f2;
}

.fill-pink-700 {
  fill: #be185d;
}

.fill-pink-700\/0 {
  fill: #be185d00;
}

.fill-pink-700\/10 {
  fill: #be185d1a;
}

.fill-pink-700\/100 {
  fill: #be185d;
}

.fill-pink-700\/20 {
  fill: #be185d33;
}

.fill-pink-700\/25 {
  fill: #be185d40;
}

.fill-pink-700\/30 {
  fill: #be185d4d;
}

.fill-pink-700\/40 {
  fill: #be185d66;
}

.fill-pink-700\/5 {
  fill: #be185d0d;
}

.fill-pink-700\/50 {
  fill: #be185d80;
}

.fill-pink-700\/60 {
  fill: #be185d99;
}

.fill-pink-700\/70 {
  fill: #be185db3;
}

.fill-pink-700\/75 {
  fill: #be185dbf;
}

.fill-pink-700\/80 {
  fill: #be185dcc;
}

.fill-pink-700\/90 {
  fill: #be185de6;
}

.fill-pink-700\/95 {
  fill: #be185df2;
}

.fill-pink-800 {
  fill: #9d174d;
}

.fill-pink-800\/0 {
  fill: #9d174d00;
}

.fill-pink-800\/10 {
  fill: #9d174d1a;
}

.fill-pink-800\/100 {
  fill: #9d174d;
}

.fill-pink-800\/20 {
  fill: #9d174d33;
}

.fill-pink-800\/25 {
  fill: #9d174d40;
}

.fill-pink-800\/30 {
  fill: #9d174d4d;
}

.fill-pink-800\/40 {
  fill: #9d174d66;
}

.fill-pink-800\/5 {
  fill: #9d174d0d;
}

.fill-pink-800\/50 {
  fill: #9d174d80;
}

.fill-pink-800\/60 {
  fill: #9d174d99;
}

.fill-pink-800\/70 {
  fill: #9d174db3;
}

.fill-pink-800\/75 {
  fill: #9d174dbf;
}

.fill-pink-800\/80 {
  fill: #9d174dcc;
}

.fill-pink-800\/90 {
  fill: #9d174de6;
}

.fill-pink-800\/95 {
  fill: #9d174df2;
}

.fill-pink-900 {
  fill: #831843;
}

.fill-pink-900\/0 {
  fill: #83184300;
}

.fill-pink-900\/10 {
  fill: #8318431a;
}

.fill-pink-900\/100 {
  fill: #831843;
}

.fill-pink-900\/20 {
  fill: #83184333;
}

.fill-pink-900\/25 {
  fill: #83184340;
}

.fill-pink-900\/30 {
  fill: #8318434d;
}

.fill-pink-900\/40 {
  fill: #83184366;
}

.fill-pink-900\/5 {
  fill: #8318430d;
}

.fill-pink-900\/50 {
  fill: #83184380;
}

.fill-pink-900\/60 {
  fill: #83184399;
}

.fill-pink-900\/70 {
  fill: #831843b3;
}

.fill-pink-900\/75 {
  fill: #831843bf;
}

.fill-pink-900\/80 {
  fill: #831843cc;
}

.fill-pink-900\/90 {
  fill: #831843e6;
}

.fill-pink-900\/95 {
  fill: #831843f2;
}

.fill-pink-950 {
  fill: #500724;
}

.fill-pink-950\/0 {
  fill: #50072400;
}

.fill-pink-950\/10 {
  fill: #5007241a;
}

.fill-pink-950\/100 {
  fill: #500724;
}

.fill-pink-950\/20 {
  fill: #50072433;
}

.fill-pink-950\/25 {
  fill: #50072440;
}

.fill-pink-950\/30 {
  fill: #5007244d;
}

.fill-pink-950\/40 {
  fill: #50072466;
}

.fill-pink-950\/5 {
  fill: #5007240d;
}

.fill-pink-950\/50 {
  fill: #50072480;
}

.fill-pink-950\/60 {
  fill: #50072499;
}

.fill-pink-950\/70 {
  fill: #500724b3;
}

.fill-pink-950\/75 {
  fill: #500724bf;
}

.fill-pink-950\/80 {
  fill: #500724cc;
}

.fill-pink-950\/90 {
  fill: #500724e6;
}

.fill-pink-950\/95 {
  fill: #500724f2;
}

.fill-purple-100 {
  fill: #f3e8ff;
}

.fill-purple-100\/0 {
  fill: #f3e8ff00;
}

.fill-purple-100\/10 {
  fill: #f3e8ff1a;
}

.fill-purple-100\/100 {
  fill: #f3e8ff;
}

.fill-purple-100\/20 {
  fill: #f3e8ff33;
}

.fill-purple-100\/25 {
  fill: #f3e8ff40;
}

.fill-purple-100\/30 {
  fill: #f3e8ff4d;
}

.fill-purple-100\/40 {
  fill: #f3e8ff66;
}

.fill-purple-100\/5 {
  fill: #f3e8ff0d;
}

.fill-purple-100\/50 {
  fill: #f3e8ff80;
}

.fill-purple-100\/60 {
  fill: #f3e8ff99;
}

.fill-purple-100\/70 {
  fill: #f3e8ffb3;
}

.fill-purple-100\/75 {
  fill: #f3e8ffbf;
}

.fill-purple-100\/80 {
  fill: #f3e8ffcc;
}

.fill-purple-100\/90 {
  fill: #f3e8ffe6;
}

.fill-purple-100\/95 {
  fill: #f3e8fff2;
}

.fill-purple-200 {
  fill: #e9d5ff;
}

.fill-purple-200\/0 {
  fill: #e9d5ff00;
}

.fill-purple-200\/10 {
  fill: #e9d5ff1a;
}

.fill-purple-200\/100 {
  fill: #e9d5ff;
}

.fill-purple-200\/20 {
  fill: #e9d5ff33;
}

.fill-purple-200\/25 {
  fill: #e9d5ff40;
}

.fill-purple-200\/30 {
  fill: #e9d5ff4d;
}

.fill-purple-200\/40 {
  fill: #e9d5ff66;
}

.fill-purple-200\/5 {
  fill: #e9d5ff0d;
}

.fill-purple-200\/50 {
  fill: #e9d5ff80;
}

.fill-purple-200\/60 {
  fill: #e9d5ff99;
}

.fill-purple-200\/70 {
  fill: #e9d5ffb3;
}

.fill-purple-200\/75 {
  fill: #e9d5ffbf;
}

.fill-purple-200\/80 {
  fill: #e9d5ffcc;
}

.fill-purple-200\/90 {
  fill: #e9d5ffe6;
}

.fill-purple-200\/95 {
  fill: #e9d5fff2;
}

.fill-purple-300 {
  fill: #d8b4fe;
}

.fill-purple-300\/0 {
  fill: #d8b4fe00;
}

.fill-purple-300\/10 {
  fill: #d8b4fe1a;
}

.fill-purple-300\/100 {
  fill: #d8b4fe;
}

.fill-purple-300\/20 {
  fill: #d8b4fe33;
}

.fill-purple-300\/25 {
  fill: #d8b4fe40;
}

.fill-purple-300\/30 {
  fill: #d8b4fe4d;
}

.fill-purple-300\/40 {
  fill: #d8b4fe66;
}

.fill-purple-300\/5 {
  fill: #d8b4fe0d;
}

.fill-purple-300\/50 {
  fill: #d8b4fe80;
}

.fill-purple-300\/60 {
  fill: #d8b4fe99;
}

.fill-purple-300\/70 {
  fill: #d8b4feb3;
}

.fill-purple-300\/75 {
  fill: #d8b4febf;
}

.fill-purple-300\/80 {
  fill: #d8b4fecc;
}

.fill-purple-300\/90 {
  fill: #d8b4fee6;
}

.fill-purple-300\/95 {
  fill: #d8b4fef2;
}

.fill-purple-400 {
  fill: #c084fc;
}

.fill-purple-400\/0 {
  fill: #c084fc00;
}

.fill-purple-400\/10 {
  fill: #c084fc1a;
}

.fill-purple-400\/100 {
  fill: #c084fc;
}

.fill-purple-400\/20 {
  fill: #c084fc33;
}

.fill-purple-400\/25 {
  fill: #c084fc40;
}

.fill-purple-400\/30 {
  fill: #c084fc4d;
}

.fill-purple-400\/40 {
  fill: #c084fc66;
}

.fill-purple-400\/5 {
  fill: #c084fc0d;
}

.fill-purple-400\/50 {
  fill: #c084fc80;
}

.fill-purple-400\/60 {
  fill: #c084fc99;
}

.fill-purple-400\/70 {
  fill: #c084fcb3;
}

.fill-purple-400\/75 {
  fill: #c084fcbf;
}

.fill-purple-400\/80 {
  fill: #c084fccc;
}

.fill-purple-400\/90 {
  fill: #c084fce6;
}

.fill-purple-400\/95 {
  fill: #c084fcf2;
}

.fill-purple-50 {
  fill: #faf5ff;
}

.fill-purple-50\/0 {
  fill: #faf5ff00;
}

.fill-purple-50\/10 {
  fill: #faf5ff1a;
}

.fill-purple-50\/100 {
  fill: #faf5ff;
}

.fill-purple-50\/20 {
  fill: #faf5ff33;
}

.fill-purple-50\/25 {
  fill: #faf5ff40;
}

.fill-purple-50\/30 {
  fill: #faf5ff4d;
}

.fill-purple-50\/40 {
  fill: #faf5ff66;
}

.fill-purple-50\/5 {
  fill: #faf5ff0d;
}

.fill-purple-50\/50 {
  fill: #faf5ff80;
}

.fill-purple-50\/60 {
  fill: #faf5ff99;
}

.fill-purple-50\/70 {
  fill: #faf5ffb3;
}

.fill-purple-50\/75 {
  fill: #faf5ffbf;
}

.fill-purple-50\/80 {
  fill: #faf5ffcc;
}

.fill-purple-50\/90 {
  fill: #faf5ffe6;
}

.fill-purple-50\/95 {
  fill: #faf5fff2;
}

.fill-purple-500 {
  fill: #a855f7;
}

.fill-purple-500\/0 {
  fill: #a855f700;
}

.fill-purple-500\/10 {
  fill: #a855f71a;
}

.fill-purple-500\/100 {
  fill: #a855f7;
}

.fill-purple-500\/20 {
  fill: #a855f733;
}

.fill-purple-500\/25 {
  fill: #a855f740;
}

.fill-purple-500\/30 {
  fill: #a855f74d;
}

.fill-purple-500\/40 {
  fill: #a855f766;
}

.fill-purple-500\/5 {
  fill: #a855f70d;
}

.fill-purple-500\/50 {
  fill: #a855f780;
}

.fill-purple-500\/60 {
  fill: #a855f799;
}

.fill-purple-500\/70 {
  fill: #a855f7b3;
}

.fill-purple-500\/75 {
  fill: #a855f7bf;
}

.fill-purple-500\/80 {
  fill: #a855f7cc;
}

.fill-purple-500\/90 {
  fill: #a855f7e6;
}

.fill-purple-500\/95 {
  fill: #a855f7f2;
}

.fill-purple-600 {
  fill: #9333ea;
}

.fill-purple-600\/0 {
  fill: #9333ea00;
}

.fill-purple-600\/10 {
  fill: #9333ea1a;
}

.fill-purple-600\/100 {
  fill: #9333ea;
}

.fill-purple-600\/20 {
  fill: #9333ea33;
}

.fill-purple-600\/25 {
  fill: #9333ea40;
}

.fill-purple-600\/30 {
  fill: #9333ea4d;
}

.fill-purple-600\/40 {
  fill: #9333ea66;
}

.fill-purple-600\/5 {
  fill: #9333ea0d;
}

.fill-purple-600\/50 {
  fill: #9333ea80;
}

.fill-purple-600\/60 {
  fill: #9333ea99;
}

.fill-purple-600\/70 {
  fill: #9333eab3;
}

.fill-purple-600\/75 {
  fill: #9333eabf;
}

.fill-purple-600\/80 {
  fill: #9333eacc;
}

.fill-purple-600\/90 {
  fill: #9333eae6;
}

.fill-purple-600\/95 {
  fill: #9333eaf2;
}

.fill-purple-700 {
  fill: #7e22ce;
}

.fill-purple-700\/0 {
  fill: #7e22ce00;
}

.fill-purple-700\/10 {
  fill: #7e22ce1a;
}

.fill-purple-700\/100 {
  fill: #7e22ce;
}

.fill-purple-700\/20 {
  fill: #7e22ce33;
}

.fill-purple-700\/25 {
  fill: #7e22ce40;
}

.fill-purple-700\/30 {
  fill: #7e22ce4d;
}

.fill-purple-700\/40 {
  fill: #7e22ce66;
}

.fill-purple-700\/5 {
  fill: #7e22ce0d;
}

.fill-purple-700\/50 {
  fill: #7e22ce80;
}

.fill-purple-700\/60 {
  fill: #7e22ce99;
}

.fill-purple-700\/70 {
  fill: #7e22ceb3;
}

.fill-purple-700\/75 {
  fill: #7e22cebf;
}

.fill-purple-700\/80 {
  fill: #7e22cecc;
}

.fill-purple-700\/90 {
  fill: #7e22cee6;
}

.fill-purple-700\/95 {
  fill: #7e22cef2;
}

.fill-purple-800 {
  fill: #6b21a8;
}

.fill-purple-800\/0 {
  fill: #6b21a800;
}

.fill-purple-800\/10 {
  fill: #6b21a81a;
}

.fill-purple-800\/100 {
  fill: #6b21a8;
}

.fill-purple-800\/20 {
  fill: #6b21a833;
}

.fill-purple-800\/25 {
  fill: #6b21a840;
}

.fill-purple-800\/30 {
  fill: #6b21a84d;
}

.fill-purple-800\/40 {
  fill: #6b21a866;
}

.fill-purple-800\/5 {
  fill: #6b21a80d;
}

.fill-purple-800\/50 {
  fill: #6b21a880;
}

.fill-purple-800\/60 {
  fill: #6b21a899;
}

.fill-purple-800\/70 {
  fill: #6b21a8b3;
}

.fill-purple-800\/75 {
  fill: #6b21a8bf;
}

.fill-purple-800\/80 {
  fill: #6b21a8cc;
}

.fill-purple-800\/90 {
  fill: #6b21a8e6;
}

.fill-purple-800\/95 {
  fill: #6b21a8f2;
}

.fill-purple-900 {
  fill: #581c87;
}

.fill-purple-900\/0 {
  fill: #581c8700;
}

.fill-purple-900\/10 {
  fill: #581c871a;
}

.fill-purple-900\/100 {
  fill: #581c87;
}

.fill-purple-900\/20 {
  fill: #581c8733;
}

.fill-purple-900\/25 {
  fill: #581c8740;
}

.fill-purple-900\/30 {
  fill: #581c874d;
}

.fill-purple-900\/40 {
  fill: #581c8766;
}

.fill-purple-900\/5 {
  fill: #581c870d;
}

.fill-purple-900\/50 {
  fill: #581c8780;
}

.fill-purple-900\/60 {
  fill: #581c8799;
}

.fill-purple-900\/70 {
  fill: #581c87b3;
}

.fill-purple-900\/75 {
  fill: #581c87bf;
}

.fill-purple-900\/80 {
  fill: #581c87cc;
}

.fill-purple-900\/90 {
  fill: #581c87e6;
}

.fill-purple-900\/95 {
  fill: #581c87f2;
}

.fill-purple-950 {
  fill: #3b0764;
}

.fill-purple-950\/0 {
  fill: #3b076400;
}

.fill-purple-950\/10 {
  fill: #3b07641a;
}

.fill-purple-950\/100 {
  fill: #3b0764;
}

.fill-purple-950\/20 {
  fill: #3b076433;
}

.fill-purple-950\/25 {
  fill: #3b076440;
}

.fill-purple-950\/30 {
  fill: #3b07644d;
}

.fill-purple-950\/40 {
  fill: #3b076466;
}

.fill-purple-950\/5 {
  fill: #3b07640d;
}

.fill-purple-950\/50 {
  fill: #3b076480;
}

.fill-purple-950\/60 {
  fill: #3b076499;
}

.fill-purple-950\/70 {
  fill: #3b0764b3;
}

.fill-purple-950\/75 {
  fill: #3b0764bf;
}

.fill-purple-950\/80 {
  fill: #3b0764cc;
}

.fill-purple-950\/90 {
  fill: #3b0764e6;
}

.fill-purple-950\/95 {
  fill: #3b0764f2;
}

.fill-red-100 {
  fill: #fee2e2;
}

.fill-red-100\/0 {
  fill: #fee2e200;
}

.fill-red-100\/10 {
  fill: #fee2e21a;
}

.fill-red-100\/100 {
  fill: #fee2e2;
}

.fill-red-100\/20 {
  fill: #fee2e233;
}

.fill-red-100\/25 {
  fill: #fee2e240;
}

.fill-red-100\/30 {
  fill: #fee2e24d;
}

.fill-red-100\/40 {
  fill: #fee2e266;
}

.fill-red-100\/5 {
  fill: #fee2e20d;
}

.fill-red-100\/50 {
  fill: #fee2e280;
}

.fill-red-100\/60 {
  fill: #fee2e299;
}

.fill-red-100\/70 {
  fill: #fee2e2b3;
}

.fill-red-100\/75 {
  fill: #fee2e2bf;
}

.fill-red-100\/80 {
  fill: #fee2e2cc;
}

.fill-red-100\/90 {
  fill: #fee2e2e6;
}

.fill-red-100\/95 {
  fill: #fee2e2f2;
}

.fill-red-200 {
  fill: #fecaca;
}

.fill-red-200\/0 {
  fill: #fecaca00;
}

.fill-red-200\/10 {
  fill: #fecaca1a;
}

.fill-red-200\/100 {
  fill: #fecaca;
}

.fill-red-200\/20 {
  fill: #fecaca33;
}

.fill-red-200\/25 {
  fill: #fecaca40;
}

.fill-red-200\/30 {
  fill: #fecaca4d;
}

.fill-red-200\/40 {
  fill: #fecaca66;
}

.fill-red-200\/5 {
  fill: #fecaca0d;
}

.fill-red-200\/50 {
  fill: #fecaca80;
}

.fill-red-200\/60 {
  fill: #fecaca99;
}

.fill-red-200\/70 {
  fill: #fecacab3;
}

.fill-red-200\/75 {
  fill: #fecacabf;
}

.fill-red-200\/80 {
  fill: #fecacacc;
}

.fill-red-200\/90 {
  fill: #fecacae6;
}

.fill-red-200\/95 {
  fill: #fecacaf2;
}

.fill-red-300 {
  fill: #fca5a5;
}

.fill-red-300\/0 {
  fill: #fca5a500;
}

.fill-red-300\/10 {
  fill: #fca5a51a;
}

.fill-red-300\/100 {
  fill: #fca5a5;
}

.fill-red-300\/20 {
  fill: #fca5a533;
}

.fill-red-300\/25 {
  fill: #fca5a540;
}

.fill-red-300\/30 {
  fill: #fca5a54d;
}

.fill-red-300\/40 {
  fill: #fca5a566;
}

.fill-red-300\/5 {
  fill: #fca5a50d;
}

.fill-red-300\/50 {
  fill: #fca5a580;
}

.fill-red-300\/60 {
  fill: #fca5a599;
}

.fill-red-300\/70 {
  fill: #fca5a5b3;
}

.fill-red-300\/75 {
  fill: #fca5a5bf;
}

.fill-red-300\/80 {
  fill: #fca5a5cc;
}

.fill-red-300\/90 {
  fill: #fca5a5e6;
}

.fill-red-300\/95 {
  fill: #fca5a5f2;
}

.fill-red-400 {
  fill: #f87171;
}

.fill-red-400\/0 {
  fill: #f8717100;
}

.fill-red-400\/10 {
  fill: #f871711a;
}

.fill-red-400\/100 {
  fill: #f87171;
}

.fill-red-400\/20 {
  fill: #f8717133;
}

.fill-red-400\/25 {
  fill: #f8717140;
}

.fill-red-400\/30 {
  fill: #f871714d;
}

.fill-red-400\/40 {
  fill: #f8717166;
}

.fill-red-400\/5 {
  fill: #f871710d;
}

.fill-red-400\/50 {
  fill: #f8717180;
}

.fill-red-400\/60 {
  fill: #f8717199;
}

.fill-red-400\/70 {
  fill: #f87171b3;
}

.fill-red-400\/75 {
  fill: #f87171bf;
}

.fill-red-400\/80 {
  fill: #f87171cc;
}

.fill-red-400\/90 {
  fill: #f87171e6;
}

.fill-red-400\/95 {
  fill: #f87171f2;
}

.fill-red-50 {
  fill: #fef2f2;
}

.fill-red-50\/0 {
  fill: #fef2f200;
}

.fill-red-50\/10 {
  fill: #fef2f21a;
}

.fill-red-50\/100 {
  fill: #fef2f2;
}

.fill-red-50\/20 {
  fill: #fef2f233;
}

.fill-red-50\/25 {
  fill: #fef2f240;
}

.fill-red-50\/30 {
  fill: #fef2f24d;
}

.fill-red-50\/40 {
  fill: #fef2f266;
}

.fill-red-50\/5 {
  fill: #fef2f20d;
}

.fill-red-50\/50 {
  fill: #fef2f280;
}

.fill-red-50\/60 {
  fill: #fef2f299;
}

.fill-red-50\/70 {
  fill: #fef2f2b3;
}

.fill-red-50\/75 {
  fill: #fef2f2bf;
}

.fill-red-50\/80 {
  fill: #fef2f2cc;
}

.fill-red-50\/90 {
  fill: #fef2f2e6;
}

.fill-red-50\/95 {
  fill: #fef2f2f2;
}

.fill-red-500 {
  fill: #ef4444;
}

.fill-red-500\/0 {
  fill: #ef444400;
}

.fill-red-500\/10 {
  fill: #ef44441a;
}

.fill-red-500\/100 {
  fill: #ef4444;
}

.fill-red-500\/20 {
  fill: #ef444433;
}

.fill-red-500\/25 {
  fill: #ef444440;
}

.fill-red-500\/30 {
  fill: #ef44444d;
}

.fill-red-500\/40 {
  fill: #ef444466;
}

.fill-red-500\/5 {
  fill: #ef44440d;
}

.fill-red-500\/50 {
  fill: #ef444480;
}

.fill-red-500\/60 {
  fill: #ef444499;
}

.fill-red-500\/70 {
  fill: #ef4444b3;
}

.fill-red-500\/75 {
  fill: #ef4444bf;
}

.fill-red-500\/80 {
  fill: #ef4444cc;
}

.fill-red-500\/90 {
  fill: #ef4444e6;
}

.fill-red-500\/95 {
  fill: #ef4444f2;
}

.fill-red-600 {
  fill: #dc2626;
}

.fill-red-600\/0 {
  fill: #dc262600;
}

.fill-red-600\/10 {
  fill: #dc26261a;
}

.fill-red-600\/100 {
  fill: #dc2626;
}

.fill-red-600\/20 {
  fill: #dc262633;
}

.fill-red-600\/25 {
  fill: #dc262640;
}

.fill-red-600\/30 {
  fill: #dc26264d;
}

.fill-red-600\/40 {
  fill: #dc262666;
}

.fill-red-600\/5 {
  fill: #dc26260d;
}

.fill-red-600\/50 {
  fill: #dc262680;
}

.fill-red-600\/60 {
  fill: #dc262699;
}

.fill-red-600\/70 {
  fill: #dc2626b3;
}

.fill-red-600\/75 {
  fill: #dc2626bf;
}

.fill-red-600\/80 {
  fill: #dc2626cc;
}

.fill-red-600\/90 {
  fill: #dc2626e6;
}

.fill-red-600\/95 {
  fill: #dc2626f2;
}

.fill-red-700 {
  fill: #b91c1c;
}

.fill-red-700\/0 {
  fill: #b91c1c00;
}

.fill-red-700\/10 {
  fill: #b91c1c1a;
}

.fill-red-700\/100 {
  fill: #b91c1c;
}

.fill-red-700\/20 {
  fill: #b91c1c33;
}

.fill-red-700\/25 {
  fill: #b91c1c40;
}

.fill-red-700\/30 {
  fill: #b91c1c4d;
}

.fill-red-700\/40 {
  fill: #b91c1c66;
}

.fill-red-700\/5 {
  fill: #b91c1c0d;
}

.fill-red-700\/50 {
  fill: #b91c1c80;
}

.fill-red-700\/60 {
  fill: #b91c1c99;
}

.fill-red-700\/70 {
  fill: #b91c1cb3;
}

.fill-red-700\/75 {
  fill: #b91c1cbf;
}

.fill-red-700\/80 {
  fill: #b91c1ccc;
}

.fill-red-700\/90 {
  fill: #b91c1ce6;
}

.fill-red-700\/95 {
  fill: #b91c1cf2;
}

.fill-red-800 {
  fill: #991b1b;
}

.fill-red-800\/0 {
  fill: #991b1b00;
}

.fill-red-800\/10 {
  fill: #991b1b1a;
}

.fill-red-800\/100 {
  fill: #991b1b;
}

.fill-red-800\/20 {
  fill: #991b1b33;
}

.fill-red-800\/25 {
  fill: #991b1b40;
}

.fill-red-800\/30 {
  fill: #991b1b4d;
}

.fill-red-800\/40 {
  fill: #991b1b66;
}

.fill-red-800\/5 {
  fill: #991b1b0d;
}

.fill-red-800\/50 {
  fill: #991b1b80;
}

.fill-red-800\/60 {
  fill: #991b1b99;
}

.fill-red-800\/70 {
  fill: #991b1bb3;
}

.fill-red-800\/75 {
  fill: #991b1bbf;
}

.fill-red-800\/80 {
  fill: #991b1bcc;
}

.fill-red-800\/90 {
  fill: #991b1be6;
}

.fill-red-800\/95 {
  fill: #991b1bf2;
}

.fill-red-900 {
  fill: #7f1d1d;
}

.fill-red-900\/0 {
  fill: #7f1d1d00;
}

.fill-red-900\/10 {
  fill: #7f1d1d1a;
}

.fill-red-900\/100 {
  fill: #7f1d1d;
}

.fill-red-900\/20 {
  fill: #7f1d1d33;
}

.fill-red-900\/25 {
  fill: #7f1d1d40;
}

.fill-red-900\/30 {
  fill: #7f1d1d4d;
}

.fill-red-900\/40 {
  fill: #7f1d1d66;
}

.fill-red-900\/5 {
  fill: #7f1d1d0d;
}

.fill-red-900\/50 {
  fill: #7f1d1d80;
}

.fill-red-900\/60 {
  fill: #7f1d1d99;
}

.fill-red-900\/70 {
  fill: #7f1d1db3;
}

.fill-red-900\/75 {
  fill: #7f1d1dbf;
}

.fill-red-900\/80 {
  fill: #7f1d1dcc;
}

.fill-red-900\/90 {
  fill: #7f1d1de6;
}

.fill-red-900\/95 {
  fill: #7f1d1df2;
}

.fill-red-950 {
  fill: #450a0a;
}

.fill-red-950\/0 {
  fill: #450a0a00;
}

.fill-red-950\/10 {
  fill: #450a0a1a;
}

.fill-red-950\/100 {
  fill: #450a0a;
}

.fill-red-950\/20 {
  fill: #450a0a33;
}

.fill-red-950\/25 {
  fill: #450a0a40;
}

.fill-red-950\/30 {
  fill: #450a0a4d;
}

.fill-red-950\/40 {
  fill: #450a0a66;
}

.fill-red-950\/5 {
  fill: #450a0a0d;
}

.fill-red-950\/50 {
  fill: #450a0a80;
}

.fill-red-950\/60 {
  fill: #450a0a99;
}

.fill-red-950\/70 {
  fill: #450a0ab3;
}

.fill-red-950\/75 {
  fill: #450a0abf;
}

.fill-red-950\/80 {
  fill: #450a0acc;
}

.fill-red-950\/90 {
  fill: #450a0ae6;
}

.fill-red-950\/95 {
  fill: #450a0af2;
}

.fill-rose-100 {
  fill: #ffe4e6;
}

.fill-rose-100\/0 {
  fill: #ffe4e600;
}

.fill-rose-100\/10 {
  fill: #ffe4e61a;
}

.fill-rose-100\/100 {
  fill: #ffe4e6;
}

.fill-rose-100\/20 {
  fill: #ffe4e633;
}

.fill-rose-100\/25 {
  fill: #ffe4e640;
}

.fill-rose-100\/30 {
  fill: #ffe4e64d;
}

.fill-rose-100\/40 {
  fill: #ffe4e666;
}

.fill-rose-100\/5 {
  fill: #ffe4e60d;
}

.fill-rose-100\/50 {
  fill: #ffe4e680;
}

.fill-rose-100\/60 {
  fill: #ffe4e699;
}

.fill-rose-100\/70 {
  fill: #ffe4e6b3;
}

.fill-rose-100\/75 {
  fill: #ffe4e6bf;
}

.fill-rose-100\/80 {
  fill: #ffe4e6cc;
}

.fill-rose-100\/90 {
  fill: #ffe4e6e6;
}

.fill-rose-100\/95 {
  fill: #ffe4e6f2;
}

.fill-rose-200 {
  fill: #fecdd3;
}

.fill-rose-200\/0 {
  fill: #fecdd300;
}

.fill-rose-200\/10 {
  fill: #fecdd31a;
}

.fill-rose-200\/100 {
  fill: #fecdd3;
}

.fill-rose-200\/20 {
  fill: #fecdd333;
}

.fill-rose-200\/25 {
  fill: #fecdd340;
}

.fill-rose-200\/30 {
  fill: #fecdd34d;
}

.fill-rose-200\/40 {
  fill: #fecdd366;
}

.fill-rose-200\/5 {
  fill: #fecdd30d;
}

.fill-rose-200\/50 {
  fill: #fecdd380;
}

.fill-rose-200\/60 {
  fill: #fecdd399;
}

.fill-rose-200\/70 {
  fill: #fecdd3b3;
}

.fill-rose-200\/75 {
  fill: #fecdd3bf;
}

.fill-rose-200\/80 {
  fill: #fecdd3cc;
}

.fill-rose-200\/90 {
  fill: #fecdd3e6;
}

.fill-rose-200\/95 {
  fill: #fecdd3f2;
}

.fill-rose-300 {
  fill: #fda4af;
}

.fill-rose-300\/0 {
  fill: #fda4af00;
}

.fill-rose-300\/10 {
  fill: #fda4af1a;
}

.fill-rose-300\/100 {
  fill: #fda4af;
}

.fill-rose-300\/20 {
  fill: #fda4af33;
}

.fill-rose-300\/25 {
  fill: #fda4af40;
}

.fill-rose-300\/30 {
  fill: #fda4af4d;
}

.fill-rose-300\/40 {
  fill: #fda4af66;
}

.fill-rose-300\/5 {
  fill: #fda4af0d;
}

.fill-rose-300\/50 {
  fill: #fda4af80;
}

.fill-rose-300\/60 {
  fill: #fda4af99;
}

.fill-rose-300\/70 {
  fill: #fda4afb3;
}

.fill-rose-300\/75 {
  fill: #fda4afbf;
}

.fill-rose-300\/80 {
  fill: #fda4afcc;
}

.fill-rose-300\/90 {
  fill: #fda4afe6;
}

.fill-rose-300\/95 {
  fill: #fda4aff2;
}

.fill-rose-400 {
  fill: #fb7185;
}

.fill-rose-400\/0 {
  fill: #fb718500;
}

.fill-rose-400\/10 {
  fill: #fb71851a;
}

.fill-rose-400\/100 {
  fill: #fb7185;
}

.fill-rose-400\/20 {
  fill: #fb718533;
}

.fill-rose-400\/25 {
  fill: #fb718540;
}

.fill-rose-400\/30 {
  fill: #fb71854d;
}

.fill-rose-400\/40 {
  fill: #fb718566;
}

.fill-rose-400\/5 {
  fill: #fb71850d;
}

.fill-rose-400\/50 {
  fill: #fb718580;
}

.fill-rose-400\/60 {
  fill: #fb718599;
}

.fill-rose-400\/70 {
  fill: #fb7185b3;
}

.fill-rose-400\/75 {
  fill: #fb7185bf;
}

.fill-rose-400\/80 {
  fill: #fb7185cc;
}

.fill-rose-400\/90 {
  fill: #fb7185e6;
}

.fill-rose-400\/95 {
  fill: #fb7185f2;
}

.fill-rose-50 {
  fill: #fff1f2;
}

.fill-rose-50\/0 {
  fill: #fff1f200;
}

.fill-rose-50\/10 {
  fill: #fff1f21a;
}

.fill-rose-50\/100 {
  fill: #fff1f2;
}

.fill-rose-50\/20 {
  fill: #fff1f233;
}

.fill-rose-50\/25 {
  fill: #fff1f240;
}

.fill-rose-50\/30 {
  fill: #fff1f24d;
}

.fill-rose-50\/40 {
  fill: #fff1f266;
}

.fill-rose-50\/5 {
  fill: #fff1f20d;
}

.fill-rose-50\/50 {
  fill: #fff1f280;
}

.fill-rose-50\/60 {
  fill: #fff1f299;
}

.fill-rose-50\/70 {
  fill: #fff1f2b3;
}

.fill-rose-50\/75 {
  fill: #fff1f2bf;
}

.fill-rose-50\/80 {
  fill: #fff1f2cc;
}

.fill-rose-50\/90 {
  fill: #fff1f2e6;
}

.fill-rose-50\/95 {
  fill: #fff1f2f2;
}

.fill-rose-500 {
  fill: #f43f5e;
}

.fill-rose-500\/0 {
  fill: #f43f5e00;
}

.fill-rose-500\/10 {
  fill: #f43f5e1a;
}

.fill-rose-500\/100 {
  fill: #f43f5e;
}

.fill-rose-500\/20 {
  fill: #f43f5e33;
}

.fill-rose-500\/25 {
  fill: #f43f5e40;
}

.fill-rose-500\/30 {
  fill: #f43f5e4d;
}

.fill-rose-500\/40 {
  fill: #f43f5e66;
}

.fill-rose-500\/5 {
  fill: #f43f5e0d;
}

.fill-rose-500\/50 {
  fill: #f43f5e80;
}

.fill-rose-500\/60 {
  fill: #f43f5e99;
}

.fill-rose-500\/70 {
  fill: #f43f5eb3;
}

.fill-rose-500\/75 {
  fill: #f43f5ebf;
}

.fill-rose-500\/80 {
  fill: #f43f5ecc;
}

.fill-rose-500\/90 {
  fill: #f43f5ee6;
}

.fill-rose-500\/95 {
  fill: #f43f5ef2;
}

.fill-rose-600 {
  fill: #e11d48;
}

.fill-rose-600\/0 {
  fill: #e11d4800;
}

.fill-rose-600\/10 {
  fill: #e11d481a;
}

.fill-rose-600\/100 {
  fill: #e11d48;
}

.fill-rose-600\/20 {
  fill: #e11d4833;
}

.fill-rose-600\/25 {
  fill: #e11d4840;
}

.fill-rose-600\/30 {
  fill: #e11d484d;
}

.fill-rose-600\/40 {
  fill: #e11d4866;
}

.fill-rose-600\/5 {
  fill: #e11d480d;
}

.fill-rose-600\/50 {
  fill: #e11d4880;
}

.fill-rose-600\/60 {
  fill: #e11d4899;
}

.fill-rose-600\/70 {
  fill: #e11d48b3;
}

.fill-rose-600\/75 {
  fill: #e11d48bf;
}

.fill-rose-600\/80 {
  fill: #e11d48cc;
}

.fill-rose-600\/90 {
  fill: #e11d48e6;
}

.fill-rose-600\/95 {
  fill: #e11d48f2;
}

.fill-rose-700 {
  fill: #be123c;
}

.fill-rose-700\/0 {
  fill: #be123c00;
}

.fill-rose-700\/10 {
  fill: #be123c1a;
}

.fill-rose-700\/100 {
  fill: #be123c;
}

.fill-rose-700\/20 {
  fill: #be123c33;
}

.fill-rose-700\/25 {
  fill: #be123c40;
}

.fill-rose-700\/30 {
  fill: #be123c4d;
}

.fill-rose-700\/40 {
  fill: #be123c66;
}

.fill-rose-700\/5 {
  fill: #be123c0d;
}

.fill-rose-700\/50 {
  fill: #be123c80;
}

.fill-rose-700\/60 {
  fill: #be123c99;
}

.fill-rose-700\/70 {
  fill: #be123cb3;
}

.fill-rose-700\/75 {
  fill: #be123cbf;
}

.fill-rose-700\/80 {
  fill: #be123ccc;
}

.fill-rose-700\/90 {
  fill: #be123ce6;
}

.fill-rose-700\/95 {
  fill: #be123cf2;
}

.fill-rose-800 {
  fill: #9f1239;
}

.fill-rose-800\/0 {
  fill: #9f123900;
}

.fill-rose-800\/10 {
  fill: #9f12391a;
}

.fill-rose-800\/100 {
  fill: #9f1239;
}

.fill-rose-800\/20 {
  fill: #9f123933;
}

.fill-rose-800\/25 {
  fill: #9f123940;
}

.fill-rose-800\/30 {
  fill: #9f12394d;
}

.fill-rose-800\/40 {
  fill: #9f123966;
}

.fill-rose-800\/5 {
  fill: #9f12390d;
}

.fill-rose-800\/50 {
  fill: #9f123980;
}

.fill-rose-800\/60 {
  fill: #9f123999;
}

.fill-rose-800\/70 {
  fill: #9f1239b3;
}

.fill-rose-800\/75 {
  fill: #9f1239bf;
}

.fill-rose-800\/80 {
  fill: #9f1239cc;
}

.fill-rose-800\/90 {
  fill: #9f1239e6;
}

.fill-rose-800\/95 {
  fill: #9f1239f2;
}

.fill-rose-900 {
  fill: #881337;
}

.fill-rose-900\/0 {
  fill: #88133700;
}

.fill-rose-900\/10 {
  fill: #8813371a;
}

.fill-rose-900\/100 {
  fill: #881337;
}

.fill-rose-900\/20 {
  fill: #88133733;
}

.fill-rose-900\/25 {
  fill: #88133740;
}

.fill-rose-900\/30 {
  fill: #8813374d;
}

.fill-rose-900\/40 {
  fill: #88133766;
}

.fill-rose-900\/5 {
  fill: #8813370d;
}

.fill-rose-900\/50 {
  fill: #88133780;
}

.fill-rose-900\/60 {
  fill: #88133799;
}

.fill-rose-900\/70 {
  fill: #881337b3;
}

.fill-rose-900\/75 {
  fill: #881337bf;
}

.fill-rose-900\/80 {
  fill: #881337cc;
}

.fill-rose-900\/90 {
  fill: #881337e6;
}

.fill-rose-900\/95 {
  fill: #881337f2;
}

.fill-rose-950 {
  fill: #4c0519;
}

.fill-rose-950\/0 {
  fill: #4c051900;
}

.fill-rose-950\/10 {
  fill: #4c05191a;
}

.fill-rose-950\/100 {
  fill: #4c0519;
}

.fill-rose-950\/20 {
  fill: #4c051933;
}

.fill-rose-950\/25 {
  fill: #4c051940;
}

.fill-rose-950\/30 {
  fill: #4c05194d;
}

.fill-rose-950\/40 {
  fill: #4c051966;
}

.fill-rose-950\/5 {
  fill: #4c05190d;
}

.fill-rose-950\/50 {
  fill: #4c051980;
}

.fill-rose-950\/60 {
  fill: #4c051999;
}

.fill-rose-950\/70 {
  fill: #4c0519b3;
}

.fill-rose-950\/75 {
  fill: #4c0519bf;
}

.fill-rose-950\/80 {
  fill: #4c0519cc;
}

.fill-rose-950\/90 {
  fill: #4c0519e6;
}

.fill-rose-950\/95 {
  fill: #4c0519f2;
}

.fill-sky-100 {
  fill: #e0f2fe;
}

.fill-sky-100\/0 {
  fill: #e0f2fe00;
}

.fill-sky-100\/10 {
  fill: #e0f2fe1a;
}

.fill-sky-100\/100 {
  fill: #e0f2fe;
}

.fill-sky-100\/20 {
  fill: #e0f2fe33;
}

.fill-sky-100\/25 {
  fill: #e0f2fe40;
}

.fill-sky-100\/30 {
  fill: #e0f2fe4d;
}

.fill-sky-100\/40 {
  fill: #e0f2fe66;
}

.fill-sky-100\/5 {
  fill: #e0f2fe0d;
}

.fill-sky-100\/50 {
  fill: #e0f2fe80;
}

.fill-sky-100\/60 {
  fill: #e0f2fe99;
}

.fill-sky-100\/70 {
  fill: #e0f2feb3;
}

.fill-sky-100\/75 {
  fill: #e0f2febf;
}

.fill-sky-100\/80 {
  fill: #e0f2fecc;
}

.fill-sky-100\/90 {
  fill: #e0f2fee6;
}

.fill-sky-100\/95 {
  fill: #e0f2fef2;
}

.fill-sky-200 {
  fill: #bae6fd;
}

.fill-sky-200\/0 {
  fill: #bae6fd00;
}

.fill-sky-200\/10 {
  fill: #bae6fd1a;
}

.fill-sky-200\/100 {
  fill: #bae6fd;
}

.fill-sky-200\/20 {
  fill: #bae6fd33;
}

.fill-sky-200\/25 {
  fill: #bae6fd40;
}

.fill-sky-200\/30 {
  fill: #bae6fd4d;
}

.fill-sky-200\/40 {
  fill: #bae6fd66;
}

.fill-sky-200\/5 {
  fill: #bae6fd0d;
}

.fill-sky-200\/50 {
  fill: #bae6fd80;
}

.fill-sky-200\/60 {
  fill: #bae6fd99;
}

.fill-sky-200\/70 {
  fill: #bae6fdb3;
}

.fill-sky-200\/75 {
  fill: #bae6fdbf;
}

.fill-sky-200\/80 {
  fill: #bae6fdcc;
}

.fill-sky-200\/90 {
  fill: #bae6fde6;
}

.fill-sky-200\/95 {
  fill: #bae6fdf2;
}

.fill-sky-300 {
  fill: #7dd3fc;
}

.fill-sky-300\/0 {
  fill: #7dd3fc00;
}

.fill-sky-300\/10 {
  fill: #7dd3fc1a;
}

.fill-sky-300\/100 {
  fill: #7dd3fc;
}

.fill-sky-300\/20 {
  fill: #7dd3fc33;
}

.fill-sky-300\/25 {
  fill: #7dd3fc40;
}

.fill-sky-300\/30 {
  fill: #7dd3fc4d;
}

.fill-sky-300\/40 {
  fill: #7dd3fc66;
}

.fill-sky-300\/5 {
  fill: #7dd3fc0d;
}

.fill-sky-300\/50 {
  fill: #7dd3fc80;
}

.fill-sky-300\/60 {
  fill: #7dd3fc99;
}

.fill-sky-300\/70 {
  fill: #7dd3fcb3;
}

.fill-sky-300\/75 {
  fill: #7dd3fcbf;
}

.fill-sky-300\/80 {
  fill: #7dd3fccc;
}

.fill-sky-300\/90 {
  fill: #7dd3fce6;
}

.fill-sky-300\/95 {
  fill: #7dd3fcf2;
}

.fill-sky-400 {
  fill: #38bdf8;
}

.fill-sky-400\/0 {
  fill: #38bdf800;
}

.fill-sky-400\/10 {
  fill: #38bdf81a;
}

.fill-sky-400\/100 {
  fill: #38bdf8;
}

.fill-sky-400\/20 {
  fill: #38bdf833;
}

.fill-sky-400\/25 {
  fill: #38bdf840;
}

.fill-sky-400\/30 {
  fill: #38bdf84d;
}

.fill-sky-400\/40 {
  fill: #38bdf866;
}

.fill-sky-400\/5 {
  fill: #38bdf80d;
}

.fill-sky-400\/50 {
  fill: #38bdf880;
}

.fill-sky-400\/60 {
  fill: #38bdf899;
}

.fill-sky-400\/70 {
  fill: #38bdf8b3;
}

.fill-sky-400\/75 {
  fill: #38bdf8bf;
}

.fill-sky-400\/80 {
  fill: #38bdf8cc;
}

.fill-sky-400\/90 {
  fill: #38bdf8e6;
}

.fill-sky-400\/95 {
  fill: #38bdf8f2;
}

.fill-sky-50 {
  fill: #f0f9ff;
}

.fill-sky-50\/0 {
  fill: #f0f9ff00;
}

.fill-sky-50\/10 {
  fill: #f0f9ff1a;
}

.fill-sky-50\/100 {
  fill: #f0f9ff;
}

.fill-sky-50\/20 {
  fill: #f0f9ff33;
}

.fill-sky-50\/25 {
  fill: #f0f9ff40;
}

.fill-sky-50\/30 {
  fill: #f0f9ff4d;
}

.fill-sky-50\/40 {
  fill: #f0f9ff66;
}

.fill-sky-50\/5 {
  fill: #f0f9ff0d;
}

.fill-sky-50\/50 {
  fill: #f0f9ff80;
}

.fill-sky-50\/60 {
  fill: #f0f9ff99;
}

.fill-sky-50\/70 {
  fill: #f0f9ffb3;
}

.fill-sky-50\/75 {
  fill: #f0f9ffbf;
}

.fill-sky-50\/80 {
  fill: #f0f9ffcc;
}

.fill-sky-50\/90 {
  fill: #f0f9ffe6;
}

.fill-sky-50\/95 {
  fill: #f0f9fff2;
}

.fill-sky-500 {
  fill: #0ea5e9;
}

.fill-sky-500\/0 {
  fill: #0ea5e900;
}

.fill-sky-500\/10 {
  fill: #0ea5e91a;
}

.fill-sky-500\/100 {
  fill: #0ea5e9;
}

.fill-sky-500\/20 {
  fill: #0ea5e933;
}

.fill-sky-500\/25 {
  fill: #0ea5e940;
}

.fill-sky-500\/30 {
  fill: #0ea5e94d;
}

.fill-sky-500\/40 {
  fill: #0ea5e966;
}

.fill-sky-500\/5 {
  fill: #0ea5e90d;
}

.fill-sky-500\/50 {
  fill: #0ea5e980;
}

.fill-sky-500\/60 {
  fill: #0ea5e999;
}

.fill-sky-500\/70 {
  fill: #0ea5e9b3;
}

.fill-sky-500\/75 {
  fill: #0ea5e9bf;
}

.fill-sky-500\/80 {
  fill: #0ea5e9cc;
}

.fill-sky-500\/90 {
  fill: #0ea5e9e6;
}

.fill-sky-500\/95 {
  fill: #0ea5e9f2;
}

.fill-sky-600 {
  fill: #0284c7;
}

.fill-sky-600\/0 {
  fill: #0284c700;
}

.fill-sky-600\/10 {
  fill: #0284c71a;
}

.fill-sky-600\/100 {
  fill: #0284c7;
}

.fill-sky-600\/20 {
  fill: #0284c733;
}

.fill-sky-600\/25 {
  fill: #0284c740;
}

.fill-sky-600\/30 {
  fill: #0284c74d;
}

.fill-sky-600\/40 {
  fill: #0284c766;
}

.fill-sky-600\/5 {
  fill: #0284c70d;
}

.fill-sky-600\/50 {
  fill: #0284c780;
}

.fill-sky-600\/60 {
  fill: #0284c799;
}

.fill-sky-600\/70 {
  fill: #0284c7b3;
}

.fill-sky-600\/75 {
  fill: #0284c7bf;
}

.fill-sky-600\/80 {
  fill: #0284c7cc;
}

.fill-sky-600\/90 {
  fill: #0284c7e6;
}

.fill-sky-600\/95 {
  fill: #0284c7f2;
}

.fill-sky-700 {
  fill: #0369a1;
}

.fill-sky-700\/0 {
  fill: #0369a100;
}

.fill-sky-700\/10 {
  fill: #0369a11a;
}

.fill-sky-700\/100 {
  fill: #0369a1;
}

.fill-sky-700\/20 {
  fill: #0369a133;
}

.fill-sky-700\/25 {
  fill: #0369a140;
}

.fill-sky-700\/30 {
  fill: #0369a14d;
}

.fill-sky-700\/40 {
  fill: #0369a166;
}

.fill-sky-700\/5 {
  fill: #0369a10d;
}

.fill-sky-700\/50 {
  fill: #0369a180;
}

.fill-sky-700\/60 {
  fill: #0369a199;
}

.fill-sky-700\/70 {
  fill: #0369a1b3;
}

.fill-sky-700\/75 {
  fill: #0369a1bf;
}

.fill-sky-700\/80 {
  fill: #0369a1cc;
}

.fill-sky-700\/90 {
  fill: #0369a1e6;
}

.fill-sky-700\/95 {
  fill: #0369a1f2;
}

.fill-sky-800 {
  fill: #075985;
}

.fill-sky-800\/0 {
  fill: #07598500;
}

.fill-sky-800\/10 {
  fill: #0759851a;
}

.fill-sky-800\/100 {
  fill: #075985;
}

.fill-sky-800\/20 {
  fill: #07598533;
}

.fill-sky-800\/25 {
  fill: #07598540;
}

.fill-sky-800\/30 {
  fill: #0759854d;
}

.fill-sky-800\/40 {
  fill: #07598566;
}

.fill-sky-800\/5 {
  fill: #0759850d;
}

.fill-sky-800\/50 {
  fill: #07598580;
}

.fill-sky-800\/60 {
  fill: #07598599;
}

.fill-sky-800\/70 {
  fill: #075985b3;
}

.fill-sky-800\/75 {
  fill: #075985bf;
}

.fill-sky-800\/80 {
  fill: #075985cc;
}

.fill-sky-800\/90 {
  fill: #075985e6;
}

.fill-sky-800\/95 {
  fill: #075985f2;
}

.fill-sky-900 {
  fill: #0c4a6e;
}

.fill-sky-900\/0 {
  fill: #0c4a6e00;
}

.fill-sky-900\/10 {
  fill: #0c4a6e1a;
}

.fill-sky-900\/100 {
  fill: #0c4a6e;
}

.fill-sky-900\/20 {
  fill: #0c4a6e33;
}

.fill-sky-900\/25 {
  fill: #0c4a6e40;
}

.fill-sky-900\/30 {
  fill: #0c4a6e4d;
}

.fill-sky-900\/40 {
  fill: #0c4a6e66;
}

.fill-sky-900\/5 {
  fill: #0c4a6e0d;
}

.fill-sky-900\/50 {
  fill: #0c4a6e80;
}

.fill-sky-900\/60 {
  fill: #0c4a6e99;
}

.fill-sky-900\/70 {
  fill: #0c4a6eb3;
}

.fill-sky-900\/75 {
  fill: #0c4a6ebf;
}

.fill-sky-900\/80 {
  fill: #0c4a6ecc;
}

.fill-sky-900\/90 {
  fill: #0c4a6ee6;
}

.fill-sky-900\/95 {
  fill: #0c4a6ef2;
}

.fill-sky-950 {
  fill: #082f49;
}

.fill-sky-950\/0 {
  fill: #082f4900;
}

.fill-sky-950\/10 {
  fill: #082f491a;
}

.fill-sky-950\/100 {
  fill: #082f49;
}

.fill-sky-950\/20 {
  fill: #082f4933;
}

.fill-sky-950\/25 {
  fill: #082f4940;
}

.fill-sky-950\/30 {
  fill: #082f494d;
}

.fill-sky-950\/40 {
  fill: #082f4966;
}

.fill-sky-950\/5 {
  fill: #082f490d;
}

.fill-sky-950\/50 {
  fill: #082f4980;
}

.fill-sky-950\/60 {
  fill: #082f4999;
}

.fill-sky-950\/70 {
  fill: #082f49b3;
}

.fill-sky-950\/75 {
  fill: #082f49bf;
}

.fill-sky-950\/80 {
  fill: #082f49cc;
}

.fill-sky-950\/90 {
  fill: #082f49e6;
}

.fill-sky-950\/95 {
  fill: #082f49f2;
}

.fill-slate-100 {
  fill: #f1f5f9;
}

.fill-slate-100\/0 {
  fill: #f1f5f900;
}

.fill-slate-100\/10 {
  fill: #f1f5f91a;
}

.fill-slate-100\/100 {
  fill: #f1f5f9;
}

.fill-slate-100\/20 {
  fill: #f1f5f933;
}

.fill-slate-100\/25 {
  fill: #f1f5f940;
}

.fill-slate-100\/30 {
  fill: #f1f5f94d;
}

.fill-slate-100\/40 {
  fill: #f1f5f966;
}

.fill-slate-100\/5 {
  fill: #f1f5f90d;
}

.fill-slate-100\/50 {
  fill: #f1f5f980;
}

.fill-slate-100\/60 {
  fill: #f1f5f999;
}

.fill-slate-100\/70 {
  fill: #f1f5f9b3;
}

.fill-slate-100\/75 {
  fill: #f1f5f9bf;
}

.fill-slate-100\/80 {
  fill: #f1f5f9cc;
}

.fill-slate-100\/90 {
  fill: #f1f5f9e6;
}

.fill-slate-100\/95 {
  fill: #f1f5f9f2;
}

.fill-slate-200 {
  fill: #e2e8f0;
}

.fill-slate-200\/0 {
  fill: #e2e8f000;
}

.fill-slate-200\/10 {
  fill: #e2e8f01a;
}

.fill-slate-200\/100 {
  fill: #e2e8f0;
}

.fill-slate-200\/20 {
  fill: #e2e8f033;
}

.fill-slate-200\/25 {
  fill: #e2e8f040;
}

.fill-slate-200\/30 {
  fill: #e2e8f04d;
}

.fill-slate-200\/40 {
  fill: #e2e8f066;
}

.fill-slate-200\/5 {
  fill: #e2e8f00d;
}

.fill-slate-200\/50 {
  fill: #e2e8f080;
}

.fill-slate-200\/60 {
  fill: #e2e8f099;
}

.fill-slate-200\/70 {
  fill: #e2e8f0b3;
}

.fill-slate-200\/75 {
  fill: #e2e8f0bf;
}

.fill-slate-200\/80 {
  fill: #e2e8f0cc;
}

.fill-slate-200\/90 {
  fill: #e2e8f0e6;
}

.fill-slate-200\/95 {
  fill: #e2e8f0f2;
}

.fill-slate-300 {
  fill: #cbd5e1;
}

.fill-slate-300\/0 {
  fill: #cbd5e100;
}

.fill-slate-300\/10 {
  fill: #cbd5e11a;
}

.fill-slate-300\/100 {
  fill: #cbd5e1;
}

.fill-slate-300\/20 {
  fill: #cbd5e133;
}

.fill-slate-300\/25 {
  fill: #cbd5e140;
}

.fill-slate-300\/30 {
  fill: #cbd5e14d;
}

.fill-slate-300\/40 {
  fill: #cbd5e166;
}

.fill-slate-300\/5 {
  fill: #cbd5e10d;
}

.fill-slate-300\/50 {
  fill: #cbd5e180;
}

.fill-slate-300\/60 {
  fill: #cbd5e199;
}

.fill-slate-300\/70 {
  fill: #cbd5e1b3;
}

.fill-slate-300\/75 {
  fill: #cbd5e1bf;
}

.fill-slate-300\/80 {
  fill: #cbd5e1cc;
}

.fill-slate-300\/90 {
  fill: #cbd5e1e6;
}

.fill-slate-300\/95 {
  fill: #cbd5e1f2;
}

.fill-slate-400 {
  fill: #94a3b8;
}

.fill-slate-400\/0 {
  fill: #94a3b800;
}

.fill-slate-400\/10 {
  fill: #94a3b81a;
}

.fill-slate-400\/100 {
  fill: #94a3b8;
}

.fill-slate-400\/20 {
  fill: #94a3b833;
}

.fill-slate-400\/25 {
  fill: #94a3b840;
}

.fill-slate-400\/30 {
  fill: #94a3b84d;
}

.fill-slate-400\/40 {
  fill: #94a3b866;
}

.fill-slate-400\/5 {
  fill: #94a3b80d;
}

.fill-slate-400\/50 {
  fill: #94a3b880;
}

.fill-slate-400\/60 {
  fill: #94a3b899;
}

.fill-slate-400\/70 {
  fill: #94a3b8b3;
}

.fill-slate-400\/75 {
  fill: #94a3b8bf;
}

.fill-slate-400\/80 {
  fill: #94a3b8cc;
}

.fill-slate-400\/90 {
  fill: #94a3b8e6;
}

.fill-slate-400\/95 {
  fill: #94a3b8f2;
}

.fill-slate-50 {
  fill: #f8fafc;
}

.fill-slate-50\/0 {
  fill: #f8fafc00;
}

.fill-slate-50\/10 {
  fill: #f8fafc1a;
}

.fill-slate-50\/100 {
  fill: #f8fafc;
}

.fill-slate-50\/20 {
  fill: #f8fafc33;
}

.fill-slate-50\/25 {
  fill: #f8fafc40;
}

.fill-slate-50\/30 {
  fill: #f8fafc4d;
}

.fill-slate-50\/40 {
  fill: #f8fafc66;
}

.fill-slate-50\/5 {
  fill: #f8fafc0d;
}

.fill-slate-50\/50 {
  fill: #f8fafc80;
}

.fill-slate-50\/60 {
  fill: #f8fafc99;
}

.fill-slate-50\/70 {
  fill: #f8fafcb3;
}

.fill-slate-50\/75 {
  fill: #f8fafcbf;
}

.fill-slate-50\/80 {
  fill: #f8fafccc;
}

.fill-slate-50\/90 {
  fill: #f8fafce6;
}

.fill-slate-50\/95 {
  fill: #f8fafcf2;
}

.fill-slate-500 {
  fill: #64748b;
}

.fill-slate-500\/0 {
  fill: #64748b00;
}

.fill-slate-500\/10 {
  fill: #64748b1a;
}

.fill-slate-500\/100 {
  fill: #64748b;
}

.fill-slate-500\/20 {
  fill: #64748b33;
}

.fill-slate-500\/25 {
  fill: #64748b40;
}

.fill-slate-500\/30 {
  fill: #64748b4d;
}

.fill-slate-500\/40 {
  fill: #64748b66;
}

.fill-slate-500\/5 {
  fill: #64748b0d;
}

.fill-slate-500\/50 {
  fill: #64748b80;
}

.fill-slate-500\/60 {
  fill: #64748b99;
}

.fill-slate-500\/70 {
  fill: #64748bb3;
}

.fill-slate-500\/75 {
  fill: #64748bbf;
}

.fill-slate-500\/80 {
  fill: #64748bcc;
}

.fill-slate-500\/90 {
  fill: #64748be6;
}

.fill-slate-500\/95 {
  fill: #64748bf2;
}

.fill-slate-600 {
  fill: #475569;
}

.fill-slate-600\/0 {
  fill: #47556900;
}

.fill-slate-600\/10 {
  fill: #4755691a;
}

.fill-slate-600\/100 {
  fill: #475569;
}

.fill-slate-600\/20 {
  fill: #47556933;
}

.fill-slate-600\/25 {
  fill: #47556940;
}

.fill-slate-600\/30 {
  fill: #4755694d;
}

.fill-slate-600\/40 {
  fill: #47556966;
}

.fill-slate-600\/5 {
  fill: #4755690d;
}

.fill-slate-600\/50 {
  fill: #47556980;
}

.fill-slate-600\/60 {
  fill: #47556999;
}

.fill-slate-600\/70 {
  fill: #475569b3;
}

.fill-slate-600\/75 {
  fill: #475569bf;
}

.fill-slate-600\/80 {
  fill: #475569cc;
}

.fill-slate-600\/90 {
  fill: #475569e6;
}

.fill-slate-600\/95 {
  fill: #475569f2;
}

.fill-slate-700 {
  fill: #334155;
}

.fill-slate-700\/0 {
  fill: #33415500;
}

.fill-slate-700\/10 {
  fill: #3341551a;
}

.fill-slate-700\/100 {
  fill: #334155;
}

.fill-slate-700\/20 {
  fill: #33415533;
}

.fill-slate-700\/25 {
  fill: #33415540;
}

.fill-slate-700\/30 {
  fill: #3341554d;
}

.fill-slate-700\/40 {
  fill: #33415566;
}

.fill-slate-700\/5 {
  fill: #3341550d;
}

.fill-slate-700\/50 {
  fill: #33415580;
}

.fill-slate-700\/60 {
  fill: #33415599;
}

.fill-slate-700\/70 {
  fill: #334155b3;
}

.fill-slate-700\/75 {
  fill: #334155bf;
}

.fill-slate-700\/80 {
  fill: #334155cc;
}

.fill-slate-700\/90 {
  fill: #334155e6;
}

.fill-slate-700\/95 {
  fill: #334155f2;
}

.fill-slate-800 {
  fill: #1e293b;
}

.fill-slate-800\/0 {
  fill: #1e293b00;
}

.fill-slate-800\/10 {
  fill: #1e293b1a;
}

.fill-slate-800\/100 {
  fill: #1e293b;
}

.fill-slate-800\/20 {
  fill: #1e293b33;
}

.fill-slate-800\/25 {
  fill: #1e293b40;
}

.fill-slate-800\/30 {
  fill: #1e293b4d;
}

.fill-slate-800\/40 {
  fill: #1e293b66;
}

.fill-slate-800\/5 {
  fill: #1e293b0d;
}

.fill-slate-800\/50 {
  fill: #1e293b80;
}

.fill-slate-800\/60 {
  fill: #1e293b99;
}

.fill-slate-800\/70 {
  fill: #1e293bb3;
}

.fill-slate-800\/75 {
  fill: #1e293bbf;
}

.fill-slate-800\/80 {
  fill: #1e293bcc;
}

.fill-slate-800\/90 {
  fill: #1e293be6;
}

.fill-slate-800\/95 {
  fill: #1e293bf2;
}

.fill-slate-900 {
  fill: #0f172a;
}

.fill-slate-900\/0 {
  fill: #0f172a00;
}

.fill-slate-900\/10 {
  fill: #0f172a1a;
}

.fill-slate-900\/100 {
  fill: #0f172a;
}

.fill-slate-900\/20 {
  fill: #0f172a33;
}

.fill-slate-900\/25 {
  fill: #0f172a40;
}

.fill-slate-900\/30 {
  fill: #0f172a4d;
}

.fill-slate-900\/40 {
  fill: #0f172a66;
}

.fill-slate-900\/5 {
  fill: #0f172a0d;
}

.fill-slate-900\/50 {
  fill: #0f172a80;
}

.fill-slate-900\/60 {
  fill: #0f172a99;
}

.fill-slate-900\/70 {
  fill: #0f172ab3;
}

.fill-slate-900\/75 {
  fill: #0f172abf;
}

.fill-slate-900\/80 {
  fill: #0f172acc;
}

.fill-slate-900\/90 {
  fill: #0f172ae6;
}

.fill-slate-900\/95 {
  fill: #0f172af2;
}

.fill-slate-950 {
  fill: #020617;
}

.fill-slate-950\/0 {
  fill: #02061700;
}

.fill-slate-950\/10 {
  fill: #0206171a;
}

.fill-slate-950\/100 {
  fill: #020617;
}

.fill-slate-950\/20 {
  fill: #02061733;
}

.fill-slate-950\/25 {
  fill: #02061740;
}

.fill-slate-950\/30 {
  fill: #0206174d;
}

.fill-slate-950\/40 {
  fill: #02061766;
}

.fill-slate-950\/5 {
  fill: #0206170d;
}

.fill-slate-950\/50 {
  fill: #02061780;
}

.fill-slate-950\/60 {
  fill: #02061799;
}

.fill-slate-950\/70 {
  fill: #020617b3;
}

.fill-slate-950\/75 {
  fill: #020617bf;
}

.fill-slate-950\/80 {
  fill: #020617cc;
}

.fill-slate-950\/90 {
  fill: #020617e6;
}

.fill-slate-950\/95 {
  fill: #020617f2;
}

.fill-stone-100 {
  fill: #f5f5f4;
}

.fill-stone-100\/0 {
  fill: #f5f5f400;
}

.fill-stone-100\/10 {
  fill: #f5f5f41a;
}

.fill-stone-100\/100 {
  fill: #f5f5f4;
}

.fill-stone-100\/20 {
  fill: #f5f5f433;
}

.fill-stone-100\/25 {
  fill: #f5f5f440;
}

.fill-stone-100\/30 {
  fill: #f5f5f44d;
}

.fill-stone-100\/40 {
  fill: #f5f5f466;
}

.fill-stone-100\/5 {
  fill: #f5f5f40d;
}

.fill-stone-100\/50 {
  fill: #f5f5f480;
}

.fill-stone-100\/60 {
  fill: #f5f5f499;
}

.fill-stone-100\/70 {
  fill: #f5f5f4b3;
}

.fill-stone-100\/75 {
  fill: #f5f5f4bf;
}

.fill-stone-100\/80 {
  fill: #f5f5f4cc;
}

.fill-stone-100\/90 {
  fill: #f5f5f4e6;
}

.fill-stone-100\/95 {
  fill: #f5f5f4f2;
}

.fill-stone-200 {
  fill: #e7e5e4;
}

.fill-stone-200\/0 {
  fill: #e7e5e400;
}

.fill-stone-200\/10 {
  fill: #e7e5e41a;
}

.fill-stone-200\/100 {
  fill: #e7e5e4;
}

.fill-stone-200\/20 {
  fill: #e7e5e433;
}

.fill-stone-200\/25 {
  fill: #e7e5e440;
}

.fill-stone-200\/30 {
  fill: #e7e5e44d;
}

.fill-stone-200\/40 {
  fill: #e7e5e466;
}

.fill-stone-200\/5 {
  fill: #e7e5e40d;
}

.fill-stone-200\/50 {
  fill: #e7e5e480;
}

.fill-stone-200\/60 {
  fill: #e7e5e499;
}

.fill-stone-200\/70 {
  fill: #e7e5e4b3;
}

.fill-stone-200\/75 {
  fill: #e7e5e4bf;
}

.fill-stone-200\/80 {
  fill: #e7e5e4cc;
}

.fill-stone-200\/90 {
  fill: #e7e5e4e6;
}

.fill-stone-200\/95 {
  fill: #e7e5e4f2;
}

.fill-stone-300 {
  fill: #d6d3d1;
}

.fill-stone-300\/0 {
  fill: #d6d3d100;
}

.fill-stone-300\/10 {
  fill: #d6d3d11a;
}

.fill-stone-300\/100 {
  fill: #d6d3d1;
}

.fill-stone-300\/20 {
  fill: #d6d3d133;
}

.fill-stone-300\/25 {
  fill: #d6d3d140;
}

.fill-stone-300\/30 {
  fill: #d6d3d14d;
}

.fill-stone-300\/40 {
  fill: #d6d3d166;
}

.fill-stone-300\/5 {
  fill: #d6d3d10d;
}

.fill-stone-300\/50 {
  fill: #d6d3d180;
}

.fill-stone-300\/60 {
  fill: #d6d3d199;
}

.fill-stone-300\/70 {
  fill: #d6d3d1b3;
}

.fill-stone-300\/75 {
  fill: #d6d3d1bf;
}

.fill-stone-300\/80 {
  fill: #d6d3d1cc;
}

.fill-stone-300\/90 {
  fill: #d6d3d1e6;
}

.fill-stone-300\/95 {
  fill: #d6d3d1f2;
}

.fill-stone-400 {
  fill: #a8a29e;
}

.fill-stone-400\/0 {
  fill: #a8a29e00;
}

.fill-stone-400\/10 {
  fill: #a8a29e1a;
}

.fill-stone-400\/100 {
  fill: #a8a29e;
}

.fill-stone-400\/20 {
  fill: #a8a29e33;
}

.fill-stone-400\/25 {
  fill: #a8a29e40;
}

.fill-stone-400\/30 {
  fill: #a8a29e4d;
}

.fill-stone-400\/40 {
  fill: #a8a29e66;
}

.fill-stone-400\/5 {
  fill: #a8a29e0d;
}

.fill-stone-400\/50 {
  fill: #a8a29e80;
}

.fill-stone-400\/60 {
  fill: #a8a29e99;
}

.fill-stone-400\/70 {
  fill: #a8a29eb3;
}

.fill-stone-400\/75 {
  fill: #a8a29ebf;
}

.fill-stone-400\/80 {
  fill: #a8a29ecc;
}

.fill-stone-400\/90 {
  fill: #a8a29ee6;
}

.fill-stone-400\/95 {
  fill: #a8a29ef2;
}

.fill-stone-50 {
  fill: #fafaf9;
}

.fill-stone-50\/0 {
  fill: #fafaf900;
}

.fill-stone-50\/10 {
  fill: #fafaf91a;
}

.fill-stone-50\/100 {
  fill: #fafaf9;
}

.fill-stone-50\/20 {
  fill: #fafaf933;
}

.fill-stone-50\/25 {
  fill: #fafaf940;
}

.fill-stone-50\/30 {
  fill: #fafaf94d;
}

.fill-stone-50\/40 {
  fill: #fafaf966;
}

.fill-stone-50\/5 {
  fill: #fafaf90d;
}

.fill-stone-50\/50 {
  fill: #fafaf980;
}

.fill-stone-50\/60 {
  fill: #fafaf999;
}

.fill-stone-50\/70 {
  fill: #fafaf9b3;
}

.fill-stone-50\/75 {
  fill: #fafaf9bf;
}

.fill-stone-50\/80 {
  fill: #fafaf9cc;
}

.fill-stone-50\/90 {
  fill: #fafaf9e6;
}

.fill-stone-50\/95 {
  fill: #fafaf9f2;
}

.fill-stone-500 {
  fill: #78716c;
}

.fill-stone-500\/0 {
  fill: #78716c00;
}

.fill-stone-500\/10 {
  fill: #78716c1a;
}

.fill-stone-500\/100 {
  fill: #78716c;
}

.fill-stone-500\/20 {
  fill: #78716c33;
}

.fill-stone-500\/25 {
  fill: #78716c40;
}

.fill-stone-500\/30 {
  fill: #78716c4d;
}

.fill-stone-500\/40 {
  fill: #78716c66;
}

.fill-stone-500\/5 {
  fill: #78716c0d;
}

.fill-stone-500\/50 {
  fill: #78716c80;
}

.fill-stone-500\/60 {
  fill: #78716c99;
}

.fill-stone-500\/70 {
  fill: #78716cb3;
}

.fill-stone-500\/75 {
  fill: #78716cbf;
}

.fill-stone-500\/80 {
  fill: #78716ccc;
}

.fill-stone-500\/90 {
  fill: #78716ce6;
}

.fill-stone-500\/95 {
  fill: #78716cf2;
}

.fill-stone-600 {
  fill: #57534e;
}

.fill-stone-600\/0 {
  fill: #57534e00;
}

.fill-stone-600\/10 {
  fill: #57534e1a;
}

.fill-stone-600\/100 {
  fill: #57534e;
}

.fill-stone-600\/20 {
  fill: #57534e33;
}

.fill-stone-600\/25 {
  fill: #57534e40;
}

.fill-stone-600\/30 {
  fill: #57534e4d;
}

.fill-stone-600\/40 {
  fill: #57534e66;
}

.fill-stone-600\/5 {
  fill: #57534e0d;
}

.fill-stone-600\/50 {
  fill: #57534e80;
}

.fill-stone-600\/60 {
  fill: #57534e99;
}

.fill-stone-600\/70 {
  fill: #57534eb3;
}

.fill-stone-600\/75 {
  fill: #57534ebf;
}

.fill-stone-600\/80 {
  fill: #57534ecc;
}

.fill-stone-600\/90 {
  fill: #57534ee6;
}

.fill-stone-600\/95 {
  fill: #57534ef2;
}

.fill-stone-700 {
  fill: #44403c;
}

.fill-stone-700\/0 {
  fill: #44403c00;
}

.fill-stone-700\/10 {
  fill: #44403c1a;
}

.fill-stone-700\/100 {
  fill: #44403c;
}

.fill-stone-700\/20 {
  fill: #44403c33;
}

.fill-stone-700\/25 {
  fill: #44403c40;
}

.fill-stone-700\/30 {
  fill: #44403c4d;
}

.fill-stone-700\/40 {
  fill: #44403c66;
}

.fill-stone-700\/5 {
  fill: #44403c0d;
}

.fill-stone-700\/50 {
  fill: #44403c80;
}

.fill-stone-700\/60 {
  fill: #44403c99;
}

.fill-stone-700\/70 {
  fill: #44403cb3;
}

.fill-stone-700\/75 {
  fill: #44403cbf;
}

.fill-stone-700\/80 {
  fill: #44403ccc;
}

.fill-stone-700\/90 {
  fill: #44403ce6;
}

.fill-stone-700\/95 {
  fill: #44403cf2;
}

.fill-stone-800 {
  fill: #292524;
}

.fill-stone-800\/0 {
  fill: #29252400;
}

.fill-stone-800\/10 {
  fill: #2925241a;
}

.fill-stone-800\/100 {
  fill: #292524;
}

.fill-stone-800\/20 {
  fill: #29252433;
}

.fill-stone-800\/25 {
  fill: #29252440;
}

.fill-stone-800\/30 {
  fill: #2925244d;
}

.fill-stone-800\/40 {
  fill: #29252466;
}

.fill-stone-800\/5 {
  fill: #2925240d;
}

.fill-stone-800\/50 {
  fill: #29252480;
}

.fill-stone-800\/60 {
  fill: #29252499;
}

.fill-stone-800\/70 {
  fill: #292524b3;
}

.fill-stone-800\/75 {
  fill: #292524bf;
}

.fill-stone-800\/80 {
  fill: #292524cc;
}

.fill-stone-800\/90 {
  fill: #292524e6;
}

.fill-stone-800\/95 {
  fill: #292524f2;
}

.fill-stone-900 {
  fill: #1c1917;
}

.fill-stone-900\/0 {
  fill: #1c191700;
}

.fill-stone-900\/10 {
  fill: #1c19171a;
}

.fill-stone-900\/100 {
  fill: #1c1917;
}

.fill-stone-900\/20 {
  fill: #1c191733;
}

.fill-stone-900\/25 {
  fill: #1c191740;
}

.fill-stone-900\/30 {
  fill: #1c19174d;
}

.fill-stone-900\/40 {
  fill: #1c191766;
}

.fill-stone-900\/5 {
  fill: #1c19170d;
}

.fill-stone-900\/50 {
  fill: #1c191780;
}

.fill-stone-900\/60 {
  fill: #1c191799;
}

.fill-stone-900\/70 {
  fill: #1c1917b3;
}

.fill-stone-900\/75 {
  fill: #1c1917bf;
}

.fill-stone-900\/80 {
  fill: #1c1917cc;
}

.fill-stone-900\/90 {
  fill: #1c1917e6;
}

.fill-stone-900\/95 {
  fill: #1c1917f2;
}

.fill-stone-950 {
  fill: #0c0a09;
}

.fill-stone-950\/0 {
  fill: #0c0a0900;
}

.fill-stone-950\/10 {
  fill: #0c0a091a;
}

.fill-stone-950\/100 {
  fill: #0c0a09;
}

.fill-stone-950\/20 {
  fill: #0c0a0933;
}

.fill-stone-950\/25 {
  fill: #0c0a0940;
}

.fill-stone-950\/30 {
  fill: #0c0a094d;
}

.fill-stone-950\/40 {
  fill: #0c0a0966;
}

.fill-stone-950\/5 {
  fill: #0c0a090d;
}

.fill-stone-950\/50 {
  fill: #0c0a0980;
}

.fill-stone-950\/60 {
  fill: #0c0a0999;
}

.fill-stone-950\/70 {
  fill: #0c0a09b3;
}

.fill-stone-950\/75 {
  fill: #0c0a09bf;
}

.fill-stone-950\/80 {
  fill: #0c0a09cc;
}

.fill-stone-950\/90 {
  fill: #0c0a09e6;
}

.fill-stone-950\/95 {
  fill: #0c0a09f2;
}

.fill-teal-100 {
  fill: #ccfbf1;
}

.fill-teal-100\/0 {
  fill: #ccfbf100;
}

.fill-teal-100\/10 {
  fill: #ccfbf11a;
}

.fill-teal-100\/100 {
  fill: #ccfbf1;
}

.fill-teal-100\/20 {
  fill: #ccfbf133;
}

.fill-teal-100\/25 {
  fill: #ccfbf140;
}

.fill-teal-100\/30 {
  fill: #ccfbf14d;
}

.fill-teal-100\/40 {
  fill: #ccfbf166;
}

.fill-teal-100\/5 {
  fill: #ccfbf10d;
}

.fill-teal-100\/50 {
  fill: #ccfbf180;
}

.fill-teal-100\/60 {
  fill: #ccfbf199;
}

.fill-teal-100\/70 {
  fill: #ccfbf1b3;
}

.fill-teal-100\/75 {
  fill: #ccfbf1bf;
}

.fill-teal-100\/80 {
  fill: #ccfbf1cc;
}

.fill-teal-100\/90 {
  fill: #ccfbf1e6;
}

.fill-teal-100\/95 {
  fill: #ccfbf1f2;
}

.fill-teal-200 {
  fill: #99f6e4;
}

.fill-teal-200\/0 {
  fill: #99f6e400;
}

.fill-teal-200\/10 {
  fill: #99f6e41a;
}

.fill-teal-200\/100 {
  fill: #99f6e4;
}

.fill-teal-200\/20 {
  fill: #99f6e433;
}

.fill-teal-200\/25 {
  fill: #99f6e440;
}

.fill-teal-200\/30 {
  fill: #99f6e44d;
}

.fill-teal-200\/40 {
  fill: #99f6e466;
}

.fill-teal-200\/5 {
  fill: #99f6e40d;
}

.fill-teal-200\/50 {
  fill: #99f6e480;
}

.fill-teal-200\/60 {
  fill: #99f6e499;
}

.fill-teal-200\/70 {
  fill: #99f6e4b3;
}

.fill-teal-200\/75 {
  fill: #99f6e4bf;
}

.fill-teal-200\/80 {
  fill: #99f6e4cc;
}

.fill-teal-200\/90 {
  fill: #99f6e4e6;
}

.fill-teal-200\/95 {
  fill: #99f6e4f2;
}

.fill-teal-300 {
  fill: #5eead4;
}

.fill-teal-300\/0 {
  fill: #5eead400;
}

.fill-teal-300\/10 {
  fill: #5eead41a;
}

.fill-teal-300\/100 {
  fill: #5eead4;
}

.fill-teal-300\/20 {
  fill: #5eead433;
}

.fill-teal-300\/25 {
  fill: #5eead440;
}

.fill-teal-300\/30 {
  fill: #5eead44d;
}

.fill-teal-300\/40 {
  fill: #5eead466;
}

.fill-teal-300\/5 {
  fill: #5eead40d;
}

.fill-teal-300\/50 {
  fill: #5eead480;
}

.fill-teal-300\/60 {
  fill: #5eead499;
}

.fill-teal-300\/70 {
  fill: #5eead4b3;
}

.fill-teal-300\/75 {
  fill: #5eead4bf;
}

.fill-teal-300\/80 {
  fill: #5eead4cc;
}

.fill-teal-300\/90 {
  fill: #5eead4e6;
}

.fill-teal-300\/95 {
  fill: #5eead4f2;
}

.fill-teal-400 {
  fill: #2dd4bf;
}

.fill-teal-400\/0 {
  fill: #2dd4bf00;
}

.fill-teal-400\/10 {
  fill: #2dd4bf1a;
}

.fill-teal-400\/100 {
  fill: #2dd4bf;
}

.fill-teal-400\/20 {
  fill: #2dd4bf33;
}

.fill-teal-400\/25 {
  fill: #2dd4bf40;
}

.fill-teal-400\/30 {
  fill: #2dd4bf4d;
}

.fill-teal-400\/40 {
  fill: #2dd4bf66;
}

.fill-teal-400\/5 {
  fill: #2dd4bf0d;
}

.fill-teal-400\/50 {
  fill: #2dd4bf80;
}

.fill-teal-400\/60 {
  fill: #2dd4bf99;
}

.fill-teal-400\/70 {
  fill: #2dd4bfb3;
}

.fill-teal-400\/75 {
  fill: #2dd4bfbf;
}

.fill-teal-400\/80 {
  fill: #2dd4bfcc;
}

.fill-teal-400\/90 {
  fill: #2dd4bfe6;
}

.fill-teal-400\/95 {
  fill: #2dd4bff2;
}

.fill-teal-50 {
  fill: #f0fdfa;
}

.fill-teal-50\/0 {
  fill: #f0fdfa00;
}

.fill-teal-50\/10 {
  fill: #f0fdfa1a;
}

.fill-teal-50\/100 {
  fill: #f0fdfa;
}

.fill-teal-50\/20 {
  fill: #f0fdfa33;
}

.fill-teal-50\/25 {
  fill: #f0fdfa40;
}

.fill-teal-50\/30 {
  fill: #f0fdfa4d;
}

.fill-teal-50\/40 {
  fill: #f0fdfa66;
}

.fill-teal-50\/5 {
  fill: #f0fdfa0d;
}

.fill-teal-50\/50 {
  fill: #f0fdfa80;
}

.fill-teal-50\/60 {
  fill: #f0fdfa99;
}

.fill-teal-50\/70 {
  fill: #f0fdfab3;
}

.fill-teal-50\/75 {
  fill: #f0fdfabf;
}

.fill-teal-50\/80 {
  fill: #f0fdfacc;
}

.fill-teal-50\/90 {
  fill: #f0fdfae6;
}

.fill-teal-50\/95 {
  fill: #f0fdfaf2;
}

.fill-teal-500 {
  fill: #14b8a6;
}

.fill-teal-500\/0 {
  fill: #14b8a600;
}

.fill-teal-500\/10 {
  fill: #14b8a61a;
}

.fill-teal-500\/100 {
  fill: #14b8a6;
}

.fill-teal-500\/20 {
  fill: #14b8a633;
}

.fill-teal-500\/25 {
  fill: #14b8a640;
}

.fill-teal-500\/30 {
  fill: #14b8a64d;
}

.fill-teal-500\/40 {
  fill: #14b8a666;
}

.fill-teal-500\/5 {
  fill: #14b8a60d;
}

.fill-teal-500\/50 {
  fill: #14b8a680;
}

.fill-teal-500\/60 {
  fill: #14b8a699;
}

.fill-teal-500\/70 {
  fill: #14b8a6b3;
}

.fill-teal-500\/75 {
  fill: #14b8a6bf;
}

.fill-teal-500\/80 {
  fill: #14b8a6cc;
}

.fill-teal-500\/90 {
  fill: #14b8a6e6;
}

.fill-teal-500\/95 {
  fill: #14b8a6f2;
}

.fill-teal-600 {
  fill: #0d9488;
}

.fill-teal-600\/0 {
  fill: #0d948800;
}

.fill-teal-600\/10 {
  fill: #0d94881a;
}

.fill-teal-600\/100 {
  fill: #0d9488;
}

.fill-teal-600\/20 {
  fill: #0d948833;
}

.fill-teal-600\/25 {
  fill: #0d948840;
}

.fill-teal-600\/30 {
  fill: #0d94884d;
}

.fill-teal-600\/40 {
  fill: #0d948866;
}

.fill-teal-600\/5 {
  fill: #0d94880d;
}

.fill-teal-600\/50 {
  fill: #0d948880;
}

.fill-teal-600\/60 {
  fill: #0d948899;
}

.fill-teal-600\/70 {
  fill: #0d9488b3;
}

.fill-teal-600\/75 {
  fill: #0d9488bf;
}

.fill-teal-600\/80 {
  fill: #0d9488cc;
}

.fill-teal-600\/90 {
  fill: #0d9488e6;
}

.fill-teal-600\/95 {
  fill: #0d9488f2;
}

.fill-teal-700 {
  fill: #0f766e;
}

.fill-teal-700\/0 {
  fill: #0f766e00;
}

.fill-teal-700\/10 {
  fill: #0f766e1a;
}

.fill-teal-700\/100 {
  fill: #0f766e;
}

.fill-teal-700\/20 {
  fill: #0f766e33;
}

.fill-teal-700\/25 {
  fill: #0f766e40;
}

.fill-teal-700\/30 {
  fill: #0f766e4d;
}

.fill-teal-700\/40 {
  fill: #0f766e66;
}

.fill-teal-700\/5 {
  fill: #0f766e0d;
}

.fill-teal-700\/50 {
  fill: #0f766e80;
}

.fill-teal-700\/60 {
  fill: #0f766e99;
}

.fill-teal-700\/70 {
  fill: #0f766eb3;
}

.fill-teal-700\/75 {
  fill: #0f766ebf;
}

.fill-teal-700\/80 {
  fill: #0f766ecc;
}

.fill-teal-700\/90 {
  fill: #0f766ee6;
}

.fill-teal-700\/95 {
  fill: #0f766ef2;
}

.fill-teal-800 {
  fill: #115e59;
}

.fill-teal-800\/0 {
  fill: #115e5900;
}

.fill-teal-800\/10 {
  fill: #115e591a;
}

.fill-teal-800\/100 {
  fill: #115e59;
}

.fill-teal-800\/20 {
  fill: #115e5933;
}

.fill-teal-800\/25 {
  fill: #115e5940;
}

.fill-teal-800\/30 {
  fill: #115e594d;
}

.fill-teal-800\/40 {
  fill: #115e5966;
}

.fill-teal-800\/5 {
  fill: #115e590d;
}

.fill-teal-800\/50 {
  fill: #115e5980;
}

.fill-teal-800\/60 {
  fill: #115e5999;
}

.fill-teal-800\/70 {
  fill: #115e59b3;
}

.fill-teal-800\/75 {
  fill: #115e59bf;
}

.fill-teal-800\/80 {
  fill: #115e59cc;
}

.fill-teal-800\/90 {
  fill: #115e59e6;
}

.fill-teal-800\/95 {
  fill: #115e59f2;
}

.fill-teal-900 {
  fill: #134e4a;
}

.fill-teal-900\/0 {
  fill: #134e4a00;
}

.fill-teal-900\/10 {
  fill: #134e4a1a;
}

.fill-teal-900\/100 {
  fill: #134e4a;
}

.fill-teal-900\/20 {
  fill: #134e4a33;
}

.fill-teal-900\/25 {
  fill: #134e4a40;
}

.fill-teal-900\/30 {
  fill: #134e4a4d;
}

.fill-teal-900\/40 {
  fill: #134e4a66;
}

.fill-teal-900\/5 {
  fill: #134e4a0d;
}

.fill-teal-900\/50 {
  fill: #134e4a80;
}

.fill-teal-900\/60 {
  fill: #134e4a99;
}

.fill-teal-900\/70 {
  fill: #134e4ab3;
}

.fill-teal-900\/75 {
  fill: #134e4abf;
}

.fill-teal-900\/80 {
  fill: #134e4acc;
}

.fill-teal-900\/90 {
  fill: #134e4ae6;
}

.fill-teal-900\/95 {
  fill: #134e4af2;
}

.fill-teal-950 {
  fill: #042f2e;
}

.fill-teal-950\/0 {
  fill: #042f2e00;
}

.fill-teal-950\/10 {
  fill: #042f2e1a;
}

.fill-teal-950\/100 {
  fill: #042f2e;
}

.fill-teal-950\/20 {
  fill: #042f2e33;
}

.fill-teal-950\/25 {
  fill: #042f2e40;
}

.fill-teal-950\/30 {
  fill: #042f2e4d;
}

.fill-teal-950\/40 {
  fill: #042f2e66;
}

.fill-teal-950\/5 {
  fill: #042f2e0d;
}

.fill-teal-950\/50 {
  fill: #042f2e80;
}

.fill-teal-950\/60 {
  fill: #042f2e99;
}

.fill-teal-950\/70 {
  fill: #042f2eb3;
}

.fill-teal-950\/75 {
  fill: #042f2ebf;
}

.fill-teal-950\/80 {
  fill: #042f2ecc;
}

.fill-teal-950\/90 {
  fill: #042f2ee6;
}

.fill-teal-950\/95 {
  fill: #042f2ef2;
}

.fill-transparent, .fill-transparent\/0 {
  fill: #0000;
}

.fill-transparent\/10 {
  fill: #0000001a;
}

.fill-transparent\/100 {
  fill: #000;
}

.fill-transparent\/20 {
  fill: #0003;
}

.fill-transparent\/25 {
  fill: #00000040;
}

.fill-transparent\/30 {
  fill: #0000004d;
}

.fill-transparent\/40 {
  fill: #0006;
}

.fill-transparent\/5 {
  fill: #0000000d;
}

.fill-transparent\/50 {
  fill: #00000080;
}

.fill-transparent\/60 {
  fill: #0009;
}

.fill-transparent\/70 {
  fill: #000000b3;
}

.fill-transparent\/75 {
  fill: #000000bf;
}

.fill-transparent\/80 {
  fill: #000c;
}

.fill-transparent\/90 {
  fill: #000000e6;
}

.fill-transparent\/95 {
  fill: #000000f2;
}

.fill-violet-100 {
  fill: #ede9fe;
}

.fill-violet-100\/0 {
  fill: #ede9fe00;
}

.fill-violet-100\/10 {
  fill: #ede9fe1a;
}

.fill-violet-100\/100 {
  fill: #ede9fe;
}

.fill-violet-100\/20 {
  fill: #ede9fe33;
}

.fill-violet-100\/25 {
  fill: #ede9fe40;
}

.fill-violet-100\/30 {
  fill: #ede9fe4d;
}

.fill-violet-100\/40 {
  fill: #ede9fe66;
}

.fill-violet-100\/5 {
  fill: #ede9fe0d;
}

.fill-violet-100\/50 {
  fill: #ede9fe80;
}

.fill-violet-100\/60 {
  fill: #ede9fe99;
}

.fill-violet-100\/70 {
  fill: #ede9feb3;
}

.fill-violet-100\/75 {
  fill: #ede9febf;
}

.fill-violet-100\/80 {
  fill: #ede9fecc;
}

.fill-violet-100\/90 {
  fill: #ede9fee6;
}

.fill-violet-100\/95 {
  fill: #ede9fef2;
}

.fill-violet-200 {
  fill: #ddd6fe;
}

.fill-violet-200\/0 {
  fill: #ddd6fe00;
}

.fill-violet-200\/10 {
  fill: #ddd6fe1a;
}

.fill-violet-200\/100 {
  fill: #ddd6fe;
}

.fill-violet-200\/20 {
  fill: #ddd6fe33;
}

.fill-violet-200\/25 {
  fill: #ddd6fe40;
}

.fill-violet-200\/30 {
  fill: #ddd6fe4d;
}

.fill-violet-200\/40 {
  fill: #ddd6fe66;
}

.fill-violet-200\/5 {
  fill: #ddd6fe0d;
}

.fill-violet-200\/50 {
  fill: #ddd6fe80;
}

.fill-violet-200\/60 {
  fill: #ddd6fe99;
}

.fill-violet-200\/70 {
  fill: #ddd6feb3;
}

.fill-violet-200\/75 {
  fill: #ddd6febf;
}

.fill-violet-200\/80 {
  fill: #ddd6fecc;
}

.fill-violet-200\/90 {
  fill: #ddd6fee6;
}

.fill-violet-200\/95 {
  fill: #ddd6fef2;
}

.fill-violet-300 {
  fill: #c4b5fd;
}

.fill-violet-300\/0 {
  fill: #c4b5fd00;
}

.fill-violet-300\/10 {
  fill: #c4b5fd1a;
}

.fill-violet-300\/100 {
  fill: #c4b5fd;
}

.fill-violet-300\/20 {
  fill: #c4b5fd33;
}

.fill-violet-300\/25 {
  fill: #c4b5fd40;
}

.fill-violet-300\/30 {
  fill: #c4b5fd4d;
}

.fill-violet-300\/40 {
  fill: #c4b5fd66;
}

.fill-violet-300\/5 {
  fill: #c4b5fd0d;
}

.fill-violet-300\/50 {
  fill: #c4b5fd80;
}

.fill-violet-300\/60 {
  fill: #c4b5fd99;
}

.fill-violet-300\/70 {
  fill: #c4b5fdb3;
}

.fill-violet-300\/75 {
  fill: #c4b5fdbf;
}

.fill-violet-300\/80 {
  fill: #c4b5fdcc;
}

.fill-violet-300\/90 {
  fill: #c4b5fde6;
}

.fill-violet-300\/95 {
  fill: #c4b5fdf2;
}

.fill-violet-400 {
  fill: #a78bfa;
}

.fill-violet-400\/0 {
  fill: #a78bfa00;
}

.fill-violet-400\/10 {
  fill: #a78bfa1a;
}

.fill-violet-400\/100 {
  fill: #a78bfa;
}

.fill-violet-400\/20 {
  fill: #a78bfa33;
}

.fill-violet-400\/25 {
  fill: #a78bfa40;
}

.fill-violet-400\/30 {
  fill: #a78bfa4d;
}

.fill-violet-400\/40 {
  fill: #a78bfa66;
}

.fill-violet-400\/5 {
  fill: #a78bfa0d;
}

.fill-violet-400\/50 {
  fill: #a78bfa80;
}

.fill-violet-400\/60 {
  fill: #a78bfa99;
}

.fill-violet-400\/70 {
  fill: #a78bfab3;
}

.fill-violet-400\/75 {
  fill: #a78bfabf;
}

.fill-violet-400\/80 {
  fill: #a78bfacc;
}

.fill-violet-400\/90 {
  fill: #a78bfae6;
}

.fill-violet-400\/95 {
  fill: #a78bfaf2;
}

.fill-violet-50 {
  fill: #f5f3ff;
}

.fill-violet-50\/0 {
  fill: #f5f3ff00;
}

.fill-violet-50\/10 {
  fill: #f5f3ff1a;
}

.fill-violet-50\/100 {
  fill: #f5f3ff;
}

.fill-violet-50\/20 {
  fill: #f5f3ff33;
}

.fill-violet-50\/25 {
  fill: #f5f3ff40;
}

.fill-violet-50\/30 {
  fill: #f5f3ff4d;
}

.fill-violet-50\/40 {
  fill: #f5f3ff66;
}

.fill-violet-50\/5 {
  fill: #f5f3ff0d;
}

.fill-violet-50\/50 {
  fill: #f5f3ff80;
}

.fill-violet-50\/60 {
  fill: #f5f3ff99;
}

.fill-violet-50\/70 {
  fill: #f5f3ffb3;
}

.fill-violet-50\/75 {
  fill: #f5f3ffbf;
}

.fill-violet-50\/80 {
  fill: #f5f3ffcc;
}

.fill-violet-50\/90 {
  fill: #f5f3ffe6;
}

.fill-violet-50\/95 {
  fill: #f5f3fff2;
}

.fill-violet-500 {
  fill: #8b5cf6;
}

.fill-violet-500\/0 {
  fill: #8b5cf600;
}

.fill-violet-500\/10 {
  fill: #8b5cf61a;
}

.fill-violet-500\/100 {
  fill: #8b5cf6;
}

.fill-violet-500\/20 {
  fill: #8b5cf633;
}

.fill-violet-500\/25 {
  fill: #8b5cf640;
}

.fill-violet-500\/30 {
  fill: #8b5cf64d;
}

.fill-violet-500\/40 {
  fill: #8b5cf666;
}

.fill-violet-500\/5 {
  fill: #8b5cf60d;
}

.fill-violet-500\/50 {
  fill: #8b5cf680;
}

.fill-violet-500\/60 {
  fill: #8b5cf699;
}

.fill-violet-500\/70 {
  fill: #8b5cf6b3;
}

.fill-violet-500\/75 {
  fill: #8b5cf6bf;
}

.fill-violet-500\/80 {
  fill: #8b5cf6cc;
}

.fill-violet-500\/90 {
  fill: #8b5cf6e6;
}

.fill-violet-500\/95 {
  fill: #8b5cf6f2;
}

.fill-violet-600 {
  fill: #7c3aed;
}

.fill-violet-600\/0 {
  fill: #7c3aed00;
}

.fill-violet-600\/10 {
  fill: #7c3aed1a;
}

.fill-violet-600\/100 {
  fill: #7c3aed;
}

.fill-violet-600\/20 {
  fill: #7c3aed33;
}

.fill-violet-600\/25 {
  fill: #7c3aed40;
}

.fill-violet-600\/30 {
  fill: #7c3aed4d;
}

.fill-violet-600\/40 {
  fill: #7c3aed66;
}

.fill-violet-600\/5 {
  fill: #7c3aed0d;
}

.fill-violet-600\/50 {
  fill: #7c3aed80;
}

.fill-violet-600\/60 {
  fill: #7c3aed99;
}

.fill-violet-600\/70 {
  fill: #7c3aedb3;
}

.fill-violet-600\/75 {
  fill: #7c3aedbf;
}

.fill-violet-600\/80 {
  fill: #7c3aedcc;
}

.fill-violet-600\/90 {
  fill: #7c3aede6;
}

.fill-violet-600\/95 {
  fill: #7c3aedf2;
}

.fill-violet-700 {
  fill: #6d28d9;
}

.fill-violet-700\/0 {
  fill: #6d28d900;
}

.fill-violet-700\/10 {
  fill: #6d28d91a;
}

.fill-violet-700\/100 {
  fill: #6d28d9;
}

.fill-violet-700\/20 {
  fill: #6d28d933;
}

.fill-violet-700\/25 {
  fill: #6d28d940;
}

.fill-violet-700\/30 {
  fill: #6d28d94d;
}

.fill-violet-700\/40 {
  fill: #6d28d966;
}

.fill-violet-700\/5 {
  fill: #6d28d90d;
}

.fill-violet-700\/50 {
  fill: #6d28d980;
}

.fill-violet-700\/60 {
  fill: #6d28d999;
}

.fill-violet-700\/70 {
  fill: #6d28d9b3;
}

.fill-violet-700\/75 {
  fill: #6d28d9bf;
}

.fill-violet-700\/80 {
  fill: #6d28d9cc;
}

.fill-violet-700\/90 {
  fill: #6d28d9e6;
}

.fill-violet-700\/95 {
  fill: #6d28d9f2;
}

.fill-violet-800 {
  fill: #5b21b6;
}

.fill-violet-800\/0 {
  fill: #5b21b600;
}

.fill-violet-800\/10 {
  fill: #5b21b61a;
}

.fill-violet-800\/100 {
  fill: #5b21b6;
}

.fill-violet-800\/20 {
  fill: #5b21b633;
}

.fill-violet-800\/25 {
  fill: #5b21b640;
}

.fill-violet-800\/30 {
  fill: #5b21b64d;
}

.fill-violet-800\/40 {
  fill: #5b21b666;
}

.fill-violet-800\/5 {
  fill: #5b21b60d;
}

.fill-violet-800\/50 {
  fill: #5b21b680;
}

.fill-violet-800\/60 {
  fill: #5b21b699;
}

.fill-violet-800\/70 {
  fill: #5b21b6b3;
}

.fill-violet-800\/75 {
  fill: #5b21b6bf;
}

.fill-violet-800\/80 {
  fill: #5b21b6cc;
}

.fill-violet-800\/90 {
  fill: #5b21b6e6;
}

.fill-violet-800\/95 {
  fill: #5b21b6f2;
}

.fill-violet-900 {
  fill: #4c1d95;
}

.fill-violet-900\/0 {
  fill: #4c1d9500;
}

.fill-violet-900\/10 {
  fill: #4c1d951a;
}

.fill-violet-900\/100 {
  fill: #4c1d95;
}

.fill-violet-900\/20 {
  fill: #4c1d9533;
}

.fill-violet-900\/25 {
  fill: #4c1d9540;
}

.fill-violet-900\/30 {
  fill: #4c1d954d;
}

.fill-violet-900\/40 {
  fill: #4c1d9566;
}

.fill-violet-900\/5 {
  fill: #4c1d950d;
}

.fill-violet-900\/50 {
  fill: #4c1d9580;
}

.fill-violet-900\/60 {
  fill: #4c1d9599;
}

.fill-violet-900\/70 {
  fill: #4c1d95b3;
}

.fill-violet-900\/75 {
  fill: #4c1d95bf;
}

.fill-violet-900\/80 {
  fill: #4c1d95cc;
}

.fill-violet-900\/90 {
  fill: #4c1d95e6;
}

.fill-violet-900\/95 {
  fill: #4c1d95f2;
}

.fill-violet-950 {
  fill: #2e1065;
}

.fill-violet-950\/0 {
  fill: #2e106500;
}

.fill-violet-950\/10 {
  fill: #2e10651a;
}

.fill-violet-950\/100 {
  fill: #2e1065;
}

.fill-violet-950\/20 {
  fill: #2e106533;
}

.fill-violet-950\/25 {
  fill: #2e106540;
}

.fill-violet-950\/30 {
  fill: #2e10654d;
}

.fill-violet-950\/40 {
  fill: #2e106566;
}

.fill-violet-950\/5 {
  fill: #2e10650d;
}

.fill-violet-950\/50 {
  fill: #2e106580;
}

.fill-violet-950\/60 {
  fill: #2e106599;
}

.fill-violet-950\/70 {
  fill: #2e1065b3;
}

.fill-violet-950\/75 {
  fill: #2e1065bf;
}

.fill-violet-950\/80 {
  fill: #2e1065cc;
}

.fill-violet-950\/90 {
  fill: #2e1065e6;
}

.fill-violet-950\/95 {
  fill: #2e1065f2;
}

.fill-white {
  fill: #fff;
}

.fill-white\/0 {
  fill: #fff0;
}

.fill-white\/10 {
  fill: #ffffff1a;
}

.fill-white\/100 {
  fill: #fff;
}

.fill-white\/20 {
  fill: #fff3;
}

.fill-white\/25 {
  fill: #ffffff40;
}

.fill-white\/30 {
  fill: #ffffff4d;
}

.fill-white\/40 {
  fill: #fff6;
}

.fill-white\/5 {
  fill: #ffffff0d;
}

.fill-white\/50 {
  fill: #ffffff80;
}

.fill-white\/60 {
  fill: #fff9;
}

.fill-white\/70 {
  fill: #ffffffb3;
}

.fill-white\/75 {
  fill: #ffffffbf;
}

.fill-white\/80 {
  fill: #fffc;
}

.fill-white\/90 {
  fill: #ffffffe6;
}

.fill-white\/95 {
  fill: #fffffff2;
}

.fill-yellow-100 {
  fill: #fef9c3;
}

.fill-yellow-100\/0 {
  fill: #fef9c300;
}

.fill-yellow-100\/10 {
  fill: #fef9c31a;
}

.fill-yellow-100\/100 {
  fill: #fef9c3;
}

.fill-yellow-100\/20 {
  fill: #fef9c333;
}

.fill-yellow-100\/25 {
  fill: #fef9c340;
}

.fill-yellow-100\/30 {
  fill: #fef9c34d;
}

.fill-yellow-100\/40 {
  fill: #fef9c366;
}

.fill-yellow-100\/5 {
  fill: #fef9c30d;
}

.fill-yellow-100\/50 {
  fill: #fef9c380;
}

.fill-yellow-100\/60 {
  fill: #fef9c399;
}

.fill-yellow-100\/70 {
  fill: #fef9c3b3;
}

.fill-yellow-100\/75 {
  fill: #fef9c3bf;
}

.fill-yellow-100\/80 {
  fill: #fef9c3cc;
}

.fill-yellow-100\/90 {
  fill: #fef9c3e6;
}

.fill-yellow-100\/95 {
  fill: #fef9c3f2;
}

.fill-yellow-200 {
  fill: #fef08a;
}

.fill-yellow-200\/0 {
  fill: #fef08a00;
}

.fill-yellow-200\/10 {
  fill: #fef08a1a;
}

.fill-yellow-200\/100 {
  fill: #fef08a;
}

.fill-yellow-200\/20 {
  fill: #fef08a33;
}

.fill-yellow-200\/25 {
  fill: #fef08a40;
}

.fill-yellow-200\/30 {
  fill: #fef08a4d;
}

.fill-yellow-200\/40 {
  fill: #fef08a66;
}

.fill-yellow-200\/5 {
  fill: #fef08a0d;
}

.fill-yellow-200\/50 {
  fill: #fef08a80;
}

.fill-yellow-200\/60 {
  fill: #fef08a99;
}

.fill-yellow-200\/70 {
  fill: #fef08ab3;
}

.fill-yellow-200\/75 {
  fill: #fef08abf;
}

.fill-yellow-200\/80 {
  fill: #fef08acc;
}

.fill-yellow-200\/90 {
  fill: #fef08ae6;
}

.fill-yellow-200\/95 {
  fill: #fef08af2;
}

.fill-yellow-300 {
  fill: #fde047;
}

.fill-yellow-300\/0 {
  fill: #fde04700;
}

.fill-yellow-300\/10 {
  fill: #fde0471a;
}

.fill-yellow-300\/100 {
  fill: #fde047;
}

.fill-yellow-300\/20 {
  fill: #fde04733;
}

.fill-yellow-300\/25 {
  fill: #fde04740;
}

.fill-yellow-300\/30 {
  fill: #fde0474d;
}

.fill-yellow-300\/40 {
  fill: #fde04766;
}

.fill-yellow-300\/5 {
  fill: #fde0470d;
}

.fill-yellow-300\/50 {
  fill: #fde04780;
}

.fill-yellow-300\/60 {
  fill: #fde04799;
}

.fill-yellow-300\/70 {
  fill: #fde047b3;
}

.fill-yellow-300\/75 {
  fill: #fde047bf;
}

.fill-yellow-300\/80 {
  fill: #fde047cc;
}

.fill-yellow-300\/90 {
  fill: #fde047e6;
}

.fill-yellow-300\/95 {
  fill: #fde047f2;
}

.fill-yellow-400 {
  fill: #facc15;
}

.fill-yellow-400\/0 {
  fill: #facc1500;
}

.fill-yellow-400\/10 {
  fill: #facc151a;
}

.fill-yellow-400\/100 {
  fill: #facc15;
}

.fill-yellow-400\/20 {
  fill: #facc1533;
}

.fill-yellow-400\/25 {
  fill: #facc1540;
}

.fill-yellow-400\/30 {
  fill: #facc154d;
}

.fill-yellow-400\/40 {
  fill: #facc1566;
}

.fill-yellow-400\/5 {
  fill: #facc150d;
}

.fill-yellow-400\/50 {
  fill: #facc1580;
}

.fill-yellow-400\/60 {
  fill: #facc1599;
}

.fill-yellow-400\/70 {
  fill: #facc15b3;
}

.fill-yellow-400\/75 {
  fill: #facc15bf;
}

.fill-yellow-400\/80 {
  fill: #facc15cc;
}

.fill-yellow-400\/90 {
  fill: #facc15e6;
}

.fill-yellow-400\/95 {
  fill: #facc15f2;
}

.fill-yellow-50 {
  fill: #fefce8;
}

.fill-yellow-50\/0 {
  fill: #fefce800;
}

.fill-yellow-50\/10 {
  fill: #fefce81a;
}

.fill-yellow-50\/100 {
  fill: #fefce8;
}

.fill-yellow-50\/20 {
  fill: #fefce833;
}

.fill-yellow-50\/25 {
  fill: #fefce840;
}

.fill-yellow-50\/30 {
  fill: #fefce84d;
}

.fill-yellow-50\/40 {
  fill: #fefce866;
}

.fill-yellow-50\/5 {
  fill: #fefce80d;
}

.fill-yellow-50\/50 {
  fill: #fefce880;
}

.fill-yellow-50\/60 {
  fill: #fefce899;
}

.fill-yellow-50\/70 {
  fill: #fefce8b3;
}

.fill-yellow-50\/75 {
  fill: #fefce8bf;
}

.fill-yellow-50\/80 {
  fill: #fefce8cc;
}

.fill-yellow-50\/90 {
  fill: #fefce8e6;
}

.fill-yellow-50\/95 {
  fill: #fefce8f2;
}

.fill-yellow-500 {
  fill: #eab308;
}

.fill-yellow-500\/0 {
  fill: #eab30800;
}

.fill-yellow-500\/10 {
  fill: #eab3081a;
}

.fill-yellow-500\/100 {
  fill: #eab308;
}

.fill-yellow-500\/20 {
  fill: #eab30833;
}

.fill-yellow-500\/25 {
  fill: #eab30840;
}

.fill-yellow-500\/30 {
  fill: #eab3084d;
}

.fill-yellow-500\/40 {
  fill: #eab30866;
}

.fill-yellow-500\/5 {
  fill: #eab3080d;
}

.fill-yellow-500\/50 {
  fill: #eab30880;
}

.fill-yellow-500\/60 {
  fill: #eab30899;
}

.fill-yellow-500\/70 {
  fill: #eab308b3;
}

.fill-yellow-500\/75 {
  fill: #eab308bf;
}

.fill-yellow-500\/80 {
  fill: #eab308cc;
}

.fill-yellow-500\/90 {
  fill: #eab308e6;
}

.fill-yellow-500\/95 {
  fill: #eab308f2;
}

.fill-yellow-600 {
  fill: #ca8a04;
}

.fill-yellow-600\/0 {
  fill: #ca8a0400;
}

.fill-yellow-600\/10 {
  fill: #ca8a041a;
}

.fill-yellow-600\/100 {
  fill: #ca8a04;
}

.fill-yellow-600\/20 {
  fill: #ca8a0433;
}

.fill-yellow-600\/25 {
  fill: #ca8a0440;
}

.fill-yellow-600\/30 {
  fill: #ca8a044d;
}

.fill-yellow-600\/40 {
  fill: #ca8a0466;
}

.fill-yellow-600\/5 {
  fill: #ca8a040d;
}

.fill-yellow-600\/50 {
  fill: #ca8a0480;
}

.fill-yellow-600\/60 {
  fill: #ca8a0499;
}

.fill-yellow-600\/70 {
  fill: #ca8a04b3;
}

.fill-yellow-600\/75 {
  fill: #ca8a04bf;
}

.fill-yellow-600\/80 {
  fill: #ca8a04cc;
}

.fill-yellow-600\/90 {
  fill: #ca8a04e6;
}

.fill-yellow-600\/95 {
  fill: #ca8a04f2;
}

.fill-yellow-700 {
  fill: #a16207;
}

.fill-yellow-700\/0 {
  fill: #a1620700;
}

.fill-yellow-700\/10 {
  fill: #a162071a;
}

.fill-yellow-700\/100 {
  fill: #a16207;
}

.fill-yellow-700\/20 {
  fill: #a1620733;
}

.fill-yellow-700\/25 {
  fill: #a1620740;
}

.fill-yellow-700\/30 {
  fill: #a162074d;
}

.fill-yellow-700\/40 {
  fill: #a1620766;
}

.fill-yellow-700\/5 {
  fill: #a162070d;
}

.fill-yellow-700\/50 {
  fill: #a1620780;
}

.fill-yellow-700\/60 {
  fill: #a1620799;
}

.fill-yellow-700\/70 {
  fill: #a16207b3;
}

.fill-yellow-700\/75 {
  fill: #a16207bf;
}

.fill-yellow-700\/80 {
  fill: #a16207cc;
}

.fill-yellow-700\/90 {
  fill: #a16207e6;
}

.fill-yellow-700\/95 {
  fill: #a16207f2;
}

.fill-yellow-800 {
  fill: #854d0e;
}

.fill-yellow-800\/0 {
  fill: #854d0e00;
}

.fill-yellow-800\/10 {
  fill: #854d0e1a;
}

.fill-yellow-800\/100 {
  fill: #854d0e;
}

.fill-yellow-800\/20 {
  fill: #854d0e33;
}

.fill-yellow-800\/25 {
  fill: #854d0e40;
}

.fill-yellow-800\/30 {
  fill: #854d0e4d;
}

.fill-yellow-800\/40 {
  fill: #854d0e66;
}

.fill-yellow-800\/5 {
  fill: #854d0e0d;
}

.fill-yellow-800\/50 {
  fill: #854d0e80;
}

.fill-yellow-800\/60 {
  fill: #854d0e99;
}

.fill-yellow-800\/70 {
  fill: #854d0eb3;
}

.fill-yellow-800\/75 {
  fill: #854d0ebf;
}

.fill-yellow-800\/80 {
  fill: #854d0ecc;
}

.fill-yellow-800\/90 {
  fill: #854d0ee6;
}

.fill-yellow-800\/95 {
  fill: #854d0ef2;
}

.fill-yellow-900 {
  fill: #713f12;
}

.fill-yellow-900\/0 {
  fill: #713f1200;
}

.fill-yellow-900\/10 {
  fill: #713f121a;
}

.fill-yellow-900\/100 {
  fill: #713f12;
}

.fill-yellow-900\/20 {
  fill: #713f1233;
}

.fill-yellow-900\/25 {
  fill: #713f1240;
}

.fill-yellow-900\/30 {
  fill: #713f124d;
}

.fill-yellow-900\/40 {
  fill: #713f1266;
}

.fill-yellow-900\/5 {
  fill: #713f120d;
}

.fill-yellow-900\/50 {
  fill: #713f1280;
}

.fill-yellow-900\/60 {
  fill: #713f1299;
}

.fill-yellow-900\/70 {
  fill: #713f12b3;
}

.fill-yellow-900\/75 {
  fill: #713f12bf;
}

.fill-yellow-900\/80 {
  fill: #713f12cc;
}

.fill-yellow-900\/90 {
  fill: #713f12e6;
}

.fill-yellow-900\/95 {
  fill: #713f12f2;
}

.fill-yellow-950 {
  fill: #422006;
}

.fill-yellow-950\/0 {
  fill: #42200600;
}

.fill-yellow-950\/10 {
  fill: #4220061a;
}

.fill-yellow-950\/100 {
  fill: #422006;
}

.fill-yellow-950\/20 {
  fill: #42200633;
}

.fill-yellow-950\/25 {
  fill: #42200640;
}

.fill-yellow-950\/30 {
  fill: #4220064d;
}

.fill-yellow-950\/40 {
  fill: #42200666;
}

.fill-yellow-950\/5 {
  fill: #4220060d;
}

.fill-yellow-950\/50 {
  fill: #42200680;
}

.fill-yellow-950\/60 {
  fill: #42200699;
}

.fill-yellow-950\/70 {
  fill: #422006b3;
}

.fill-yellow-950\/75 {
  fill: #422006bf;
}

.fill-yellow-950\/80 {
  fill: #422006cc;
}

.fill-yellow-950\/90 {
  fill: #422006e6;
}

.fill-yellow-950\/95 {
  fill: #422006f2;
}

.fill-zinc-100 {
  fill: #f4f4f5;
}

.fill-zinc-100\/0 {
  fill: #f4f4f500;
}

.fill-zinc-100\/10 {
  fill: #f4f4f51a;
}

.fill-zinc-100\/100 {
  fill: #f4f4f5;
}

.fill-zinc-100\/20 {
  fill: #f4f4f533;
}

.fill-zinc-100\/25 {
  fill: #f4f4f540;
}

.fill-zinc-100\/30 {
  fill: #f4f4f54d;
}

.fill-zinc-100\/40 {
  fill: #f4f4f566;
}

.fill-zinc-100\/5 {
  fill: #f4f4f50d;
}

.fill-zinc-100\/50 {
  fill: #f4f4f580;
}

.fill-zinc-100\/60 {
  fill: #f4f4f599;
}

.fill-zinc-100\/70 {
  fill: #f4f4f5b3;
}

.fill-zinc-100\/75 {
  fill: #f4f4f5bf;
}

.fill-zinc-100\/80 {
  fill: #f4f4f5cc;
}

.fill-zinc-100\/90 {
  fill: #f4f4f5e6;
}

.fill-zinc-100\/95 {
  fill: #f4f4f5f2;
}

.fill-zinc-200 {
  fill: #e4e4e7;
}

.fill-zinc-200\/0 {
  fill: #e4e4e700;
}

.fill-zinc-200\/10 {
  fill: #e4e4e71a;
}

.fill-zinc-200\/100 {
  fill: #e4e4e7;
}

.fill-zinc-200\/20 {
  fill: #e4e4e733;
}

.fill-zinc-200\/25 {
  fill: #e4e4e740;
}

.fill-zinc-200\/30 {
  fill: #e4e4e74d;
}

.fill-zinc-200\/40 {
  fill: #e4e4e766;
}

.fill-zinc-200\/5 {
  fill: #e4e4e70d;
}

.fill-zinc-200\/50 {
  fill: #e4e4e780;
}

.fill-zinc-200\/60 {
  fill: #e4e4e799;
}

.fill-zinc-200\/70 {
  fill: #e4e4e7b3;
}

.fill-zinc-200\/75 {
  fill: #e4e4e7bf;
}

.fill-zinc-200\/80 {
  fill: #e4e4e7cc;
}

.fill-zinc-200\/90 {
  fill: #e4e4e7e6;
}

.fill-zinc-200\/95 {
  fill: #e4e4e7f2;
}

.fill-zinc-300 {
  fill: #d4d4d8;
}

.fill-zinc-300\/0 {
  fill: #d4d4d800;
}

.fill-zinc-300\/10 {
  fill: #d4d4d81a;
}

.fill-zinc-300\/100 {
  fill: #d4d4d8;
}

.fill-zinc-300\/20 {
  fill: #d4d4d833;
}

.fill-zinc-300\/25 {
  fill: #d4d4d840;
}

.fill-zinc-300\/30 {
  fill: #d4d4d84d;
}

.fill-zinc-300\/40 {
  fill: #d4d4d866;
}

.fill-zinc-300\/5 {
  fill: #d4d4d80d;
}

.fill-zinc-300\/50 {
  fill: #d4d4d880;
}

.fill-zinc-300\/60 {
  fill: #d4d4d899;
}

.fill-zinc-300\/70 {
  fill: #d4d4d8b3;
}

.fill-zinc-300\/75 {
  fill: #d4d4d8bf;
}

.fill-zinc-300\/80 {
  fill: #d4d4d8cc;
}

.fill-zinc-300\/90 {
  fill: #d4d4d8e6;
}

.fill-zinc-300\/95 {
  fill: #d4d4d8f2;
}

.fill-zinc-400 {
  fill: #a1a1aa;
}

.fill-zinc-400\/0 {
  fill: #a1a1aa00;
}

.fill-zinc-400\/10 {
  fill: #a1a1aa1a;
}

.fill-zinc-400\/100 {
  fill: #a1a1aa;
}

.fill-zinc-400\/20 {
  fill: #a1a1aa33;
}

.fill-zinc-400\/25 {
  fill: #a1a1aa40;
}

.fill-zinc-400\/30 {
  fill: #a1a1aa4d;
}

.fill-zinc-400\/40 {
  fill: #a1a1aa66;
}

.fill-zinc-400\/5 {
  fill: #a1a1aa0d;
}

.fill-zinc-400\/50 {
  fill: #a1a1aa80;
}

.fill-zinc-400\/60 {
  fill: #a1a1aa99;
}

.fill-zinc-400\/70 {
  fill: #a1a1aab3;
}

.fill-zinc-400\/75 {
  fill: #a1a1aabf;
}

.fill-zinc-400\/80 {
  fill: #a1a1aacc;
}

.fill-zinc-400\/90 {
  fill: #a1a1aae6;
}

.fill-zinc-400\/95 {
  fill: #a1a1aaf2;
}

.fill-zinc-50 {
  fill: #fafafa;
}

.fill-zinc-50\/0 {
  fill: #fafafa00;
}

.fill-zinc-50\/10 {
  fill: #fafafa1a;
}

.fill-zinc-50\/100 {
  fill: #fafafa;
}

.fill-zinc-50\/20 {
  fill: #fafafa33;
}

.fill-zinc-50\/25 {
  fill: #fafafa40;
}

.fill-zinc-50\/30 {
  fill: #fafafa4d;
}

.fill-zinc-50\/40 {
  fill: #fafafa66;
}

.fill-zinc-50\/5 {
  fill: #fafafa0d;
}

.fill-zinc-50\/50 {
  fill: #fafafa80;
}

.fill-zinc-50\/60 {
  fill: #fafafa99;
}

.fill-zinc-50\/70 {
  fill: #fafafab3;
}

.fill-zinc-50\/75 {
  fill: #fafafabf;
}

.fill-zinc-50\/80 {
  fill: #fafafacc;
}

.fill-zinc-50\/90 {
  fill: #fafafae6;
}

.fill-zinc-50\/95 {
  fill: #fafafaf2;
}

.fill-zinc-500 {
  fill: #71717a;
}

.fill-zinc-500\/0 {
  fill: #71717a00;
}

.fill-zinc-500\/10 {
  fill: #71717a1a;
}

.fill-zinc-500\/100 {
  fill: #71717a;
}

.fill-zinc-500\/20 {
  fill: #71717a33;
}

.fill-zinc-500\/25 {
  fill: #71717a40;
}

.fill-zinc-500\/30 {
  fill: #71717a4d;
}

.fill-zinc-500\/40 {
  fill: #71717a66;
}

.fill-zinc-500\/5 {
  fill: #71717a0d;
}

.fill-zinc-500\/50 {
  fill: #71717a80;
}

.fill-zinc-500\/60 {
  fill: #71717a99;
}

.fill-zinc-500\/70 {
  fill: #71717ab3;
}

.fill-zinc-500\/75 {
  fill: #71717abf;
}

.fill-zinc-500\/80 {
  fill: #71717acc;
}

.fill-zinc-500\/90 {
  fill: #71717ae6;
}

.fill-zinc-500\/95 {
  fill: #71717af2;
}

.fill-zinc-600 {
  fill: #52525b;
}

.fill-zinc-600\/0 {
  fill: #52525b00;
}

.fill-zinc-600\/10 {
  fill: #52525b1a;
}

.fill-zinc-600\/100 {
  fill: #52525b;
}

.fill-zinc-600\/20 {
  fill: #52525b33;
}

.fill-zinc-600\/25 {
  fill: #52525b40;
}

.fill-zinc-600\/30 {
  fill: #52525b4d;
}

.fill-zinc-600\/40 {
  fill: #52525b66;
}

.fill-zinc-600\/5 {
  fill: #52525b0d;
}

.fill-zinc-600\/50 {
  fill: #52525b80;
}

.fill-zinc-600\/60 {
  fill: #52525b99;
}

.fill-zinc-600\/70 {
  fill: #52525bb3;
}

.fill-zinc-600\/75 {
  fill: #52525bbf;
}

.fill-zinc-600\/80 {
  fill: #52525bcc;
}

.fill-zinc-600\/90 {
  fill: #52525be6;
}

.fill-zinc-600\/95 {
  fill: #52525bf2;
}

.fill-zinc-700 {
  fill: #3f3f46;
}

.fill-zinc-700\/0 {
  fill: #3f3f4600;
}

.fill-zinc-700\/10 {
  fill: #3f3f461a;
}

.fill-zinc-700\/100 {
  fill: #3f3f46;
}

.fill-zinc-700\/20 {
  fill: #3f3f4633;
}

.fill-zinc-700\/25 {
  fill: #3f3f4640;
}

.fill-zinc-700\/30 {
  fill: #3f3f464d;
}

.fill-zinc-700\/40 {
  fill: #3f3f4666;
}

.fill-zinc-700\/5 {
  fill: #3f3f460d;
}

.fill-zinc-700\/50 {
  fill: #3f3f4680;
}

.fill-zinc-700\/60 {
  fill: #3f3f4699;
}

.fill-zinc-700\/70 {
  fill: #3f3f46b3;
}

.fill-zinc-700\/75 {
  fill: #3f3f46bf;
}

.fill-zinc-700\/80 {
  fill: #3f3f46cc;
}

.fill-zinc-700\/90 {
  fill: #3f3f46e6;
}

.fill-zinc-700\/95 {
  fill: #3f3f46f2;
}

.fill-zinc-800 {
  fill: #27272a;
}

.fill-zinc-800\/0 {
  fill: #27272a00;
}

.fill-zinc-800\/10 {
  fill: #27272a1a;
}

.fill-zinc-800\/100 {
  fill: #27272a;
}

.fill-zinc-800\/20 {
  fill: #27272a33;
}

.fill-zinc-800\/25 {
  fill: #27272a40;
}

.fill-zinc-800\/30 {
  fill: #27272a4d;
}

.fill-zinc-800\/40 {
  fill: #27272a66;
}

.fill-zinc-800\/5 {
  fill: #27272a0d;
}

.fill-zinc-800\/50 {
  fill: #27272a80;
}

.fill-zinc-800\/60 {
  fill: #27272a99;
}

.fill-zinc-800\/70 {
  fill: #27272ab3;
}

.fill-zinc-800\/75 {
  fill: #27272abf;
}

.fill-zinc-800\/80 {
  fill: #27272acc;
}

.fill-zinc-800\/90 {
  fill: #27272ae6;
}

.fill-zinc-800\/95 {
  fill: #27272af2;
}

.fill-zinc-900 {
  fill: #18181b;
}

.fill-zinc-900\/0 {
  fill: #18181b00;
}

.fill-zinc-900\/10 {
  fill: #18181b1a;
}

.fill-zinc-900\/100 {
  fill: #18181b;
}

.fill-zinc-900\/20 {
  fill: #18181b33;
}

.fill-zinc-900\/25 {
  fill: #18181b40;
}

.fill-zinc-900\/30 {
  fill: #18181b4d;
}

.fill-zinc-900\/40 {
  fill: #18181b66;
}

.fill-zinc-900\/5 {
  fill: #18181b0d;
}

.fill-zinc-900\/50 {
  fill: #18181b80;
}

.fill-zinc-900\/60 {
  fill: #18181b99;
}

.fill-zinc-900\/70 {
  fill: #18181bb3;
}

.fill-zinc-900\/75 {
  fill: #18181bbf;
}

.fill-zinc-900\/80 {
  fill: #18181bcc;
}

.fill-zinc-900\/90 {
  fill: #18181be6;
}

.fill-zinc-900\/95 {
  fill: #18181bf2;
}

.fill-zinc-950 {
  fill: #09090b;
}

.fill-zinc-950\/0 {
  fill: #09090b00;
}

.fill-zinc-950\/10 {
  fill: #09090b1a;
}

.fill-zinc-950\/100 {
  fill: #09090b;
}

.fill-zinc-950\/20 {
  fill: #09090b33;
}

.fill-zinc-950\/25 {
  fill: #09090b40;
}

.fill-zinc-950\/30 {
  fill: #09090b4d;
}

.fill-zinc-950\/40 {
  fill: #09090b66;
}

.fill-zinc-950\/5 {
  fill: #09090b0d;
}

.fill-zinc-950\/50 {
  fill: #09090b80;
}

.fill-zinc-950\/60 {
  fill: #09090b99;
}

.fill-zinc-950\/70 {
  fill: #09090bb3;
}

.fill-zinc-950\/75 {
  fill: #09090bbf;
}

.fill-zinc-950\/80 {
  fill: #09090bcc;
}

.fill-zinc-950\/90 {
  fill: #09090be6;
}

.fill-zinc-950\/95 {
  fill: #09090bf2;
}

.object-fill {
  object-fit: fill;
}

.p-0 {
  padding: 0;
}

.p-3 {
  padding: .75rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.align-baseline {
  vertical-align: baseline;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.font-cursive {
  font-family: Smooch, cursive;
}

.font-sans {
  font-family: Satoshi, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-\[6\.5rem\] {
  font-size: 6.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.lowercase {
  text-transform: lowercase;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.text-amber-100\/0 {
  color: #fef3c700;
}

.text-amber-100\/10 {
  color: #fef3c71a;
}

.text-amber-100\/100 {
  color: #fef3c7;
}

.text-amber-100\/20 {
  color: #fef3c733;
}

.text-amber-100\/25 {
  color: #fef3c740;
}

.text-amber-100\/30 {
  color: #fef3c74d;
}

.text-amber-100\/40 {
  color: #fef3c766;
}

.text-amber-100\/5 {
  color: #fef3c70d;
}

.text-amber-100\/50 {
  color: #fef3c780;
}

.text-amber-100\/60 {
  color: #fef3c799;
}

.text-amber-100\/70 {
  color: #fef3c7b3;
}

.text-amber-100\/75 {
  color: #fef3c7bf;
}

.text-amber-100\/80 {
  color: #fef3c7cc;
}

.text-amber-100\/90 {
  color: #fef3c7e6;
}

.text-amber-100\/95 {
  color: #fef3c7f2;
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.text-amber-200\/0 {
  color: #fde68a00;
}

.text-amber-200\/10 {
  color: #fde68a1a;
}

.text-amber-200\/100 {
  color: #fde68a;
}

.text-amber-200\/20 {
  color: #fde68a33;
}

.text-amber-200\/25 {
  color: #fde68a40;
}

.text-amber-200\/30 {
  color: #fde68a4d;
}

.text-amber-200\/40 {
  color: #fde68a66;
}

.text-amber-200\/5 {
  color: #fde68a0d;
}

.text-amber-200\/50 {
  color: #fde68a80;
}

.text-amber-200\/60 {
  color: #fde68a99;
}

.text-amber-200\/70 {
  color: #fde68ab3;
}

.text-amber-200\/75 {
  color: #fde68abf;
}

.text-amber-200\/80 {
  color: #fde68acc;
}

.text-amber-200\/90 {
  color: #fde68ae6;
}

.text-amber-200\/95 {
  color: #fde68af2;
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.text-amber-300\/0 {
  color: #fcd34d00;
}

.text-amber-300\/10 {
  color: #fcd34d1a;
}

.text-amber-300\/100 {
  color: #fcd34d;
}

.text-amber-300\/20 {
  color: #fcd34d33;
}

.text-amber-300\/25 {
  color: #fcd34d40;
}

.text-amber-300\/30 {
  color: #fcd34d4d;
}

.text-amber-300\/40 {
  color: #fcd34d66;
}

.text-amber-300\/5 {
  color: #fcd34d0d;
}

.text-amber-300\/50 {
  color: #fcd34d80;
}

.text-amber-300\/60 {
  color: #fcd34d99;
}

.text-amber-300\/70 {
  color: #fcd34db3;
}

.text-amber-300\/75 {
  color: #fcd34dbf;
}

.text-amber-300\/80 {
  color: #fcd34dcc;
}

.text-amber-300\/90 {
  color: #fcd34de6;
}

.text-amber-300\/95 {
  color: #fcd34df2;
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-amber-400\/0 {
  color: #fbbf2400;
}

.text-amber-400\/10 {
  color: #fbbf241a;
}

.text-amber-400\/100 {
  color: #fbbf24;
}

.text-amber-400\/20 {
  color: #fbbf2433;
}

.text-amber-400\/25 {
  color: #fbbf2440;
}

.text-amber-400\/30 {
  color: #fbbf244d;
}

.text-amber-400\/40 {
  color: #fbbf2466;
}

.text-amber-400\/5 {
  color: #fbbf240d;
}

.text-amber-400\/50 {
  color: #fbbf2480;
}

.text-amber-400\/60 {
  color: #fbbf2499;
}

.text-amber-400\/70 {
  color: #fbbf24b3;
}

.text-amber-400\/75 {
  color: #fbbf24bf;
}

.text-amber-400\/80 {
  color: #fbbf24cc;
}

.text-amber-400\/90 {
  color: #fbbf24e6;
}

.text-amber-400\/95 {
  color: #fbbf24f2;
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

.text-amber-50\/0 {
  color: #fffbeb00;
}

.text-amber-50\/10 {
  color: #fffbeb1a;
}

.text-amber-50\/100 {
  color: #fffbeb;
}

.text-amber-50\/20 {
  color: #fffbeb33;
}

.text-amber-50\/25 {
  color: #fffbeb40;
}

.text-amber-50\/30 {
  color: #fffbeb4d;
}

.text-amber-50\/40 {
  color: #fffbeb66;
}

.text-amber-50\/5 {
  color: #fffbeb0d;
}

.text-amber-50\/50 {
  color: #fffbeb80;
}

.text-amber-50\/60 {
  color: #fffbeb99;
}

.text-amber-50\/70 {
  color: #fffbebb3;
}

.text-amber-50\/75 {
  color: #fffbebbf;
}

.text-amber-50\/80 {
  color: #fffbebcc;
}

.text-amber-50\/90 {
  color: #fffbebe6;
}

.text-amber-50\/95 {
  color: #fffbebf2;
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-amber-500\/0 {
  color: #f59e0b00;
}

.text-amber-500\/10 {
  color: #f59e0b1a;
}

.text-amber-500\/100 {
  color: #f59e0b;
}

.text-amber-500\/20 {
  color: #f59e0b33;
}

.text-amber-500\/25 {
  color: #f59e0b40;
}

.text-amber-500\/30 {
  color: #f59e0b4d;
}

.text-amber-500\/40 {
  color: #f59e0b66;
}

.text-amber-500\/5 {
  color: #f59e0b0d;
}

.text-amber-500\/50 {
  color: #f59e0b80;
}

.text-amber-500\/60 {
  color: #f59e0b99;
}

.text-amber-500\/70 {
  color: #f59e0bb3;
}

.text-amber-500\/75 {
  color: #f59e0bbf;
}

.text-amber-500\/80 {
  color: #f59e0bcc;
}

.text-amber-500\/90 {
  color: #f59e0be6;
}

.text-amber-500\/95 {
  color: #f59e0bf2;
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-amber-600\/0 {
  color: #d9770600;
}

.text-amber-600\/10 {
  color: #d977061a;
}

.text-amber-600\/100 {
  color: #d97706;
}

.text-amber-600\/20 {
  color: #d9770633;
}

.text-amber-600\/25 {
  color: #d9770640;
}

.text-amber-600\/30 {
  color: #d977064d;
}

.text-amber-600\/40 {
  color: #d9770666;
}

.text-amber-600\/5 {
  color: #d977060d;
}

.text-amber-600\/50 {
  color: #d9770680;
}

.text-amber-600\/60 {
  color: #d9770699;
}

.text-amber-600\/70 {
  color: #d97706b3;
}

.text-amber-600\/75 {
  color: #d97706bf;
}

.text-amber-600\/80 {
  color: #d97706cc;
}

.text-amber-600\/90 {
  color: #d97706e6;
}

.text-amber-600\/95 {
  color: #d97706f2;
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-amber-700\/0 {
  color: #b4530900;
}

.text-amber-700\/10 {
  color: #b453091a;
}

.text-amber-700\/100 {
  color: #b45309;
}

.text-amber-700\/20 {
  color: #b4530933;
}

.text-amber-700\/25 {
  color: #b4530940;
}

.text-amber-700\/30 {
  color: #b453094d;
}

.text-amber-700\/40 {
  color: #b4530966;
}

.text-amber-700\/5 {
  color: #b453090d;
}

.text-amber-700\/50 {
  color: #b4530980;
}

.text-amber-700\/60 {
  color: #b4530999;
}

.text-amber-700\/70 {
  color: #b45309b3;
}

.text-amber-700\/75 {
  color: #b45309bf;
}

.text-amber-700\/80 {
  color: #b45309cc;
}

.text-amber-700\/90 {
  color: #b45309e6;
}

.text-amber-700\/95 {
  color: #b45309f2;
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.text-amber-800\/0 {
  color: #92400e00;
}

.text-amber-800\/10 {
  color: #92400e1a;
}

.text-amber-800\/100 {
  color: #92400e;
}

.text-amber-800\/20 {
  color: #92400e33;
}

.text-amber-800\/25 {
  color: #92400e40;
}

.text-amber-800\/30 {
  color: #92400e4d;
}

.text-amber-800\/40 {
  color: #92400e66;
}

.text-amber-800\/5 {
  color: #92400e0d;
}

.text-amber-800\/50 {
  color: #92400e80;
}

.text-amber-800\/60 {
  color: #92400e99;
}

.text-amber-800\/70 {
  color: #92400eb3;
}

.text-amber-800\/75 {
  color: #92400ebf;
}

.text-amber-800\/80 {
  color: #92400ecc;
}

.text-amber-800\/90 {
  color: #92400ee6;
}

.text-amber-800\/95 {
  color: #92400ef2;
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

.text-amber-900\/0 {
  color: #78350f00;
}

.text-amber-900\/10 {
  color: #78350f1a;
}

.text-amber-900\/100 {
  color: #78350f;
}

.text-amber-900\/20 {
  color: #78350f33;
}

.text-amber-900\/25 {
  color: #78350f40;
}

.text-amber-900\/30 {
  color: #78350f4d;
}

.text-amber-900\/40 {
  color: #78350f66;
}

.text-amber-900\/5 {
  color: #78350f0d;
}

.text-amber-900\/50 {
  color: #78350f80;
}

.text-amber-900\/60 {
  color: #78350f99;
}

.text-amber-900\/70 {
  color: #78350fb3;
}

.text-amber-900\/75 {
  color: #78350fbf;
}

.text-amber-900\/80 {
  color: #78350fcc;
}

.text-amber-900\/90 {
  color: #78350fe6;
}

.text-amber-900\/95 {
  color: #78350ff2;
}

.text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.text-amber-950\/0 {
  color: #451a0300;
}

.text-amber-950\/10 {
  color: #451a031a;
}

.text-amber-950\/100 {
  color: #451a03;
}

.text-amber-950\/20 {
  color: #451a0333;
}

.text-amber-950\/25 {
  color: #451a0340;
}

.text-amber-950\/30 {
  color: #451a034d;
}

.text-amber-950\/40 {
  color: #451a0366;
}

.text-amber-950\/5 {
  color: #451a030d;
}

.text-amber-950\/50 {
  color: #451a0380;
}

.text-amber-950\/60 {
  color: #451a0399;
}

.text-amber-950\/70 {
  color: #451a03b3;
}

.text-amber-950\/75 {
  color: #451a03bf;
}

.text-amber-950\/80 {
  color: #451a03cc;
}

.text-amber-950\/90 {
  color: #451a03e6;
}

.text-amber-950\/95 {
  color: #451a03f2;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/0 {
  color: #0000;
}

.text-black\/10 {
  color: #0000001a;
}

.text-black\/100 {
  color: #000;
}

.text-black\/20 {
  color: #0003;
}

.text-black\/25 {
  color: #00000040;
}

.text-black\/30 {
  color: #0000004d;
}

.text-black\/40 {
  color: #0006;
}

.text-black\/5 {
  color: #0000000d;
}

.text-black\/50 {
  color: #00000080;
}

.text-black\/60 {
  color: #0009;
}

.text-black\/70 {
  color: #000000b3;
}

.text-black\/75 {
  color: #000000bf;
}

.text-black\/80 {
  color: #000c;
}

.text-black\/90 {
  color: #000000e6;
}

.text-black\/95 {
  color: #000000f2;
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.text-blue-100\/0 {
  color: #dbeafe00;
}

.text-blue-100\/10 {
  color: #dbeafe1a;
}

.text-blue-100\/100 {
  color: #dbeafe;
}

.text-blue-100\/20 {
  color: #dbeafe33;
}

.text-blue-100\/25 {
  color: #dbeafe40;
}

.text-blue-100\/30 {
  color: #dbeafe4d;
}

.text-blue-100\/40 {
  color: #dbeafe66;
}

.text-blue-100\/5 {
  color: #dbeafe0d;
}

.text-blue-100\/50 {
  color: #dbeafe80;
}

.text-blue-100\/60 {
  color: #dbeafe99;
}

.text-blue-100\/70 {
  color: #dbeafeb3;
}

.text-blue-100\/75 {
  color: #dbeafebf;
}

.text-blue-100\/80 {
  color: #dbeafecc;
}

.text-blue-100\/90 {
  color: #dbeafee6;
}

.text-blue-100\/95 {
  color: #dbeafef2;
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.text-blue-200\/0 {
  color: #bfdbfe00;
}

.text-blue-200\/10 {
  color: #bfdbfe1a;
}

.text-blue-200\/100 {
  color: #bfdbfe;
}

.text-blue-200\/20 {
  color: #bfdbfe33;
}

.text-blue-200\/25 {
  color: #bfdbfe40;
}

.text-blue-200\/30 {
  color: #bfdbfe4d;
}

.text-blue-200\/40 {
  color: #bfdbfe66;
}

.text-blue-200\/5 {
  color: #bfdbfe0d;
}

.text-blue-200\/50 {
  color: #bfdbfe80;
}

.text-blue-200\/60 {
  color: #bfdbfe99;
}

.text-blue-200\/70 {
  color: #bfdbfeb3;
}

.text-blue-200\/75 {
  color: #bfdbfebf;
}

.text-blue-200\/80 {
  color: #bfdbfecc;
}

.text-blue-200\/90 {
  color: #bfdbfee6;
}

.text-blue-200\/95 {
  color: #bfdbfef2;
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-blue-300\/0 {
  color: #93c5fd00;
}

.text-blue-300\/10 {
  color: #93c5fd1a;
}

.text-blue-300\/100 {
  color: #93c5fd;
}

.text-blue-300\/20 {
  color: #93c5fd33;
}

.text-blue-300\/25 {
  color: #93c5fd40;
}

.text-blue-300\/30 {
  color: #93c5fd4d;
}

.text-blue-300\/40 {
  color: #93c5fd66;
}

.text-blue-300\/5 {
  color: #93c5fd0d;
}

.text-blue-300\/50 {
  color: #93c5fd80;
}

.text-blue-300\/60 {
  color: #93c5fd99;
}

.text-blue-300\/70 {
  color: #93c5fdb3;
}

.text-blue-300\/75 {
  color: #93c5fdbf;
}

.text-blue-300\/80 {
  color: #93c5fdcc;
}

.text-blue-300\/90 {
  color: #93c5fde6;
}

.text-blue-300\/95 {
  color: #93c5fdf2;
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-400\/0 {
  color: #60a5fa00;
}

.text-blue-400\/10 {
  color: #60a5fa1a;
}

.text-blue-400\/100 {
  color: #60a5fa;
}

.text-blue-400\/20 {
  color: #60a5fa33;
}

.text-blue-400\/25 {
  color: #60a5fa40;
}

.text-blue-400\/30 {
  color: #60a5fa4d;
}

.text-blue-400\/40 {
  color: #60a5fa66;
}

.text-blue-400\/5 {
  color: #60a5fa0d;
}

.text-blue-400\/50 {
  color: #60a5fa80;
}

.text-blue-400\/60 {
  color: #60a5fa99;
}

.text-blue-400\/70 {
  color: #60a5fab3;
}

.text-blue-400\/75 {
  color: #60a5fabf;
}

.text-blue-400\/80 {
  color: #60a5facc;
}

.text-blue-400\/90 {
  color: #60a5fae6;
}

.text-blue-400\/95 {
  color: #60a5faf2;
}

.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

.text-blue-50\/0 {
  color: #eff6ff00;
}

.text-blue-50\/10 {
  color: #eff6ff1a;
}

.text-blue-50\/100 {
  color: #eff6ff;
}

.text-blue-50\/20 {
  color: #eff6ff33;
}

.text-blue-50\/25 {
  color: #eff6ff40;
}

.text-blue-50\/30 {
  color: #eff6ff4d;
}

.text-blue-50\/40 {
  color: #eff6ff66;
}

.text-blue-50\/5 {
  color: #eff6ff0d;
}

.text-blue-50\/50 {
  color: #eff6ff80;
}

.text-blue-50\/60 {
  color: #eff6ff99;
}

.text-blue-50\/70 {
  color: #eff6ffb3;
}

.text-blue-50\/75 {
  color: #eff6ffbf;
}

.text-blue-50\/80 {
  color: #eff6ffcc;
}

.text-blue-50\/90 {
  color: #eff6ffe6;
}

.text-blue-50\/95 {
  color: #eff6fff2;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-500\/0 {
  color: #3b82f600;
}

.text-blue-500\/10 {
  color: #3b82f61a;
}

.text-blue-500\/100 {
  color: #3b82f6;
}

.text-blue-500\/20 {
  color: #3b82f633;
}

.text-blue-500\/25 {
  color: #3b82f640;
}

.text-blue-500\/30 {
  color: #3b82f64d;
}

.text-blue-500\/40 {
  color: #3b82f666;
}

.text-blue-500\/5 {
  color: #3b82f60d;
}

.text-blue-500\/50 {
  color: #3b82f680;
}

.text-blue-500\/60 {
  color: #3b82f699;
}

.text-blue-500\/70 {
  color: #3b82f6b3;
}

.text-blue-500\/75 {
  color: #3b82f6bf;
}

.text-blue-500\/80 {
  color: #3b82f6cc;
}

.text-blue-500\/90 {
  color: #3b82f6e6;
}

.text-blue-500\/95 {
  color: #3b82f6f2;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-blue-600\/0 {
  color: #2563eb00;
}

.text-blue-600\/10 {
  color: #2563eb1a;
}

.text-blue-600\/100 {
  color: #2563eb;
}

.text-blue-600\/20 {
  color: #2563eb33;
}

.text-blue-600\/25 {
  color: #2563eb40;
}

.text-blue-600\/30 {
  color: #2563eb4d;
}

.text-blue-600\/40 {
  color: #2563eb66;
}

.text-blue-600\/5 {
  color: #2563eb0d;
}

.text-blue-600\/50 {
  color: #2563eb80;
}

.text-blue-600\/60 {
  color: #2563eb99;
}

.text-blue-600\/70 {
  color: #2563ebb3;
}

.text-blue-600\/75 {
  color: #2563ebbf;
}

.text-blue-600\/80 {
  color: #2563ebcc;
}

.text-blue-600\/90 {
  color: #2563ebe6;
}

.text-blue-600\/95 {
  color: #2563ebf2;
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-blue-700\/0 {
  color: #1d4ed800;
}

.text-blue-700\/10 {
  color: #1d4ed81a;
}

.text-blue-700\/100 {
  color: #1d4ed8;
}

.text-blue-700\/20 {
  color: #1d4ed833;
}

.text-blue-700\/25 {
  color: #1d4ed840;
}

.text-blue-700\/30 {
  color: #1d4ed84d;
}

.text-blue-700\/40 {
  color: #1d4ed866;
}

.text-blue-700\/5 {
  color: #1d4ed80d;
}

.text-blue-700\/50 {
  color: #1d4ed880;
}

.text-blue-700\/60 {
  color: #1d4ed899;
}

.text-blue-700\/70 {
  color: #1d4ed8b3;
}

.text-blue-700\/75 {
  color: #1d4ed8bf;
}

.text-blue-700\/80 {
  color: #1d4ed8cc;
}

.text-blue-700\/90 {
  color: #1d4ed8e6;
}

.text-blue-700\/95 {
  color: #1d4ed8f2;
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.text-blue-800\/0 {
  color: #1e40af00;
}

.text-blue-800\/10 {
  color: #1e40af1a;
}

.text-blue-800\/100 {
  color: #1e40af;
}

.text-blue-800\/20 {
  color: #1e40af33;
}

.text-blue-800\/25 {
  color: #1e40af40;
}

.text-blue-800\/30 {
  color: #1e40af4d;
}

.text-blue-800\/40 {
  color: #1e40af66;
}

.text-blue-800\/5 {
  color: #1e40af0d;
}

.text-blue-800\/50 {
  color: #1e40af80;
}

.text-blue-800\/60 {
  color: #1e40af99;
}

.text-blue-800\/70 {
  color: #1e40afb3;
}

.text-blue-800\/75 {
  color: #1e40afbf;
}

.text-blue-800\/80 {
  color: #1e40afcc;
}

.text-blue-800\/90 {
  color: #1e40afe6;
}

.text-blue-800\/95 {
  color: #1e40aff2;
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.text-blue-900\/0 {
  color: #1e3a8a00;
}

.text-blue-900\/10 {
  color: #1e3a8a1a;
}

.text-blue-900\/100 {
  color: #1e3a8a;
}

.text-blue-900\/20 {
  color: #1e3a8a33;
}

.text-blue-900\/25 {
  color: #1e3a8a40;
}

.text-blue-900\/30 {
  color: #1e3a8a4d;
}

.text-blue-900\/40 {
  color: #1e3a8a66;
}

.text-blue-900\/5 {
  color: #1e3a8a0d;
}

.text-blue-900\/50 {
  color: #1e3a8a80;
}

.text-blue-900\/60 {
  color: #1e3a8a99;
}

.text-blue-900\/70 {
  color: #1e3a8ab3;
}

.text-blue-900\/75 {
  color: #1e3a8abf;
}

.text-blue-900\/80 {
  color: #1e3a8acc;
}

.text-blue-900\/90 {
  color: #1e3a8ae6;
}

.text-blue-900\/95 {
  color: #1e3a8af2;
}

.text-blue-950 {
  --tw-text-opacity: 1;
  color: rgb(23 37 84 / var(--tw-text-opacity));
}

.text-blue-950\/0 {
  color: #17255400;
}

.text-blue-950\/10 {
  color: #1725541a;
}

.text-blue-950\/100 {
  color: #172554;
}

.text-blue-950\/20 {
  color: #17255433;
}

.text-blue-950\/25 {
  color: #17255440;
}

.text-blue-950\/30 {
  color: #1725544d;
}

.text-blue-950\/40 {
  color: #17255466;
}

.text-blue-950\/5 {
  color: #1725540d;
}

.text-blue-950\/50 {
  color: #17255480;
}

.text-blue-950\/60 {
  color: #17255499;
}

.text-blue-950\/70 {
  color: #172554b3;
}

.text-blue-950\/75 {
  color: #172554bf;
}

.text-blue-950\/80 {
  color: #172554cc;
}

.text-blue-950\/90 {
  color: #172554e6;
}

.text-blue-950\/95 {
  color: #172554f2;
}

.text-current {
  color: currentColor;
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

.text-cyan-100\/0 {
  color: #cffafe00;
}

.text-cyan-100\/10 {
  color: #cffafe1a;
}

.text-cyan-100\/100 {
  color: #cffafe;
}

.text-cyan-100\/20 {
  color: #cffafe33;
}

.text-cyan-100\/25 {
  color: #cffafe40;
}

.text-cyan-100\/30 {
  color: #cffafe4d;
}

.text-cyan-100\/40 {
  color: #cffafe66;
}

.text-cyan-100\/5 {
  color: #cffafe0d;
}

.text-cyan-100\/50 {
  color: #cffafe80;
}

.text-cyan-100\/60 {
  color: #cffafe99;
}

.text-cyan-100\/70 {
  color: #cffafeb3;
}

.text-cyan-100\/75 {
  color: #cffafebf;
}

.text-cyan-100\/80 {
  color: #cffafecc;
}

.text-cyan-100\/90 {
  color: #cffafee6;
}

.text-cyan-100\/95 {
  color: #cffafef2;
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-cyan-200\/0 {
  color: #a5f3fc00;
}

.text-cyan-200\/10 {
  color: #a5f3fc1a;
}

.text-cyan-200\/100 {
  color: #a5f3fc;
}

.text-cyan-200\/20 {
  color: #a5f3fc33;
}

.text-cyan-200\/25 {
  color: #a5f3fc40;
}

.text-cyan-200\/30 {
  color: #a5f3fc4d;
}

.text-cyan-200\/40 {
  color: #a5f3fc66;
}

.text-cyan-200\/5 {
  color: #a5f3fc0d;
}

.text-cyan-200\/50 {
  color: #a5f3fc80;
}

.text-cyan-200\/60 {
  color: #a5f3fc99;
}

.text-cyan-200\/70 {
  color: #a5f3fcb3;
}

.text-cyan-200\/75 {
  color: #a5f3fcbf;
}

.text-cyan-200\/80 {
  color: #a5f3fccc;
}

.text-cyan-200\/90 {
  color: #a5f3fce6;
}

.text-cyan-200\/95 {
  color: #a5f3fcf2;
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-cyan-300\/0 {
  color: #67e8f900;
}

.text-cyan-300\/10 {
  color: #67e8f91a;
}

.text-cyan-300\/100 {
  color: #67e8f9;
}

.text-cyan-300\/20 {
  color: #67e8f933;
}

.text-cyan-300\/25 {
  color: #67e8f940;
}

.text-cyan-300\/30 {
  color: #67e8f94d;
}

.text-cyan-300\/40 {
  color: #67e8f966;
}

.text-cyan-300\/5 {
  color: #67e8f90d;
}

.text-cyan-300\/50 {
  color: #67e8f980;
}

.text-cyan-300\/60 {
  color: #67e8f999;
}

.text-cyan-300\/70 {
  color: #67e8f9b3;
}

.text-cyan-300\/75 {
  color: #67e8f9bf;
}

.text-cyan-300\/80 {
  color: #67e8f9cc;
}

.text-cyan-300\/90 {
  color: #67e8f9e6;
}

.text-cyan-300\/95 {
  color: #67e8f9f2;
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.text-cyan-400\/0 {
  color: #22d3ee00;
}

.text-cyan-400\/10 {
  color: #22d3ee1a;
}

.text-cyan-400\/100 {
  color: #22d3ee;
}

.text-cyan-400\/20 {
  color: #22d3ee33;
}

.text-cyan-400\/25 {
  color: #22d3ee40;
}

.text-cyan-400\/30 {
  color: #22d3ee4d;
}

.text-cyan-400\/40 {
  color: #22d3ee66;
}

.text-cyan-400\/5 {
  color: #22d3ee0d;
}

.text-cyan-400\/50 {
  color: #22d3ee80;
}

.text-cyan-400\/60 {
  color: #22d3ee99;
}

.text-cyan-400\/70 {
  color: #22d3eeb3;
}

.text-cyan-400\/75 {
  color: #22d3eebf;
}

.text-cyan-400\/80 {
  color: #22d3eecc;
}

.text-cyan-400\/90 {
  color: #22d3eee6;
}

.text-cyan-400\/95 {
  color: #22d3eef2;
}

.text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgb(236 254 255 / var(--tw-text-opacity));
}

.text-cyan-50\/0 {
  color: #ecfeff00;
}

.text-cyan-50\/10 {
  color: #ecfeff1a;
}

.text-cyan-50\/100 {
  color: #ecfeff;
}

.text-cyan-50\/20 {
  color: #ecfeff33;
}

.text-cyan-50\/25 {
  color: #ecfeff40;
}

.text-cyan-50\/30 {
  color: #ecfeff4d;
}

.text-cyan-50\/40 {
  color: #ecfeff66;
}

.text-cyan-50\/5 {
  color: #ecfeff0d;
}

.text-cyan-50\/50 {
  color: #ecfeff80;
}

.text-cyan-50\/60 {
  color: #ecfeff99;
}

.text-cyan-50\/70 {
  color: #ecfeffb3;
}

.text-cyan-50\/75 {
  color: #ecfeffbf;
}

.text-cyan-50\/80 {
  color: #ecfeffcc;
}

.text-cyan-50\/90 {
  color: #ecfeffe6;
}

.text-cyan-50\/95 {
  color: #ecfefff2;
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.text-cyan-500\/0 {
  color: #06b6d400;
}

.text-cyan-500\/10 {
  color: #06b6d41a;
}

.text-cyan-500\/100 {
  color: #06b6d4;
}

.text-cyan-500\/20 {
  color: #06b6d433;
}

.text-cyan-500\/25 {
  color: #06b6d440;
}

.text-cyan-500\/30 {
  color: #06b6d44d;
}

.text-cyan-500\/40 {
  color: #06b6d466;
}

.text-cyan-500\/5 {
  color: #06b6d40d;
}

.text-cyan-500\/50 {
  color: #06b6d480;
}

.text-cyan-500\/60 {
  color: #06b6d499;
}

.text-cyan-500\/70 {
  color: #06b6d4b3;
}

.text-cyan-500\/75 {
  color: #06b6d4bf;
}

.text-cyan-500\/80 {
  color: #06b6d4cc;
}

.text-cyan-500\/90 {
  color: #06b6d4e6;
}

.text-cyan-500\/95 {
  color: #06b6d4f2;
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-cyan-600\/0 {
  color: #0891b200;
}

.text-cyan-600\/10 {
  color: #0891b21a;
}

.text-cyan-600\/100 {
  color: #0891b2;
}

.text-cyan-600\/20 {
  color: #0891b233;
}

.text-cyan-600\/25 {
  color: #0891b240;
}

.text-cyan-600\/30 {
  color: #0891b24d;
}

.text-cyan-600\/40 {
  color: #0891b266;
}

.text-cyan-600\/5 {
  color: #0891b20d;
}

.text-cyan-600\/50 {
  color: #0891b280;
}

.text-cyan-600\/60 {
  color: #0891b299;
}

.text-cyan-600\/70 {
  color: #0891b2b3;
}

.text-cyan-600\/75 {
  color: #0891b2bf;
}

.text-cyan-600\/80 {
  color: #0891b2cc;
}

.text-cyan-600\/90 {
  color: #0891b2e6;
}

.text-cyan-600\/95 {
  color: #0891b2f2;
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.text-cyan-700\/0 {
  color: #0e749000;
}

.text-cyan-700\/10 {
  color: #0e74901a;
}

.text-cyan-700\/100 {
  color: #0e7490;
}

.text-cyan-700\/20 {
  color: #0e749033;
}

.text-cyan-700\/25 {
  color: #0e749040;
}

.text-cyan-700\/30 {
  color: #0e74904d;
}

.text-cyan-700\/40 {
  color: #0e749066;
}

.text-cyan-700\/5 {
  color: #0e74900d;
}

.text-cyan-700\/50 {
  color: #0e749080;
}

.text-cyan-700\/60 {
  color: #0e749099;
}

.text-cyan-700\/70 {
  color: #0e7490b3;
}

.text-cyan-700\/75 {
  color: #0e7490bf;
}

.text-cyan-700\/80 {
  color: #0e7490cc;
}

.text-cyan-700\/90 {
  color: #0e7490e6;
}

.text-cyan-700\/95 {
  color: #0e7490f2;
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.text-cyan-800\/0 {
  color: #155e7500;
}

.text-cyan-800\/10 {
  color: #155e751a;
}

.text-cyan-800\/100 {
  color: #155e75;
}

.text-cyan-800\/20 {
  color: #155e7533;
}

.text-cyan-800\/25 {
  color: #155e7540;
}

.text-cyan-800\/30 {
  color: #155e754d;
}

.text-cyan-800\/40 {
  color: #155e7566;
}

.text-cyan-800\/5 {
  color: #155e750d;
}

.text-cyan-800\/50 {
  color: #155e7580;
}

.text-cyan-800\/60 {
  color: #155e7599;
}

.text-cyan-800\/70 {
  color: #155e75b3;
}

.text-cyan-800\/75 {
  color: #155e75bf;
}

.text-cyan-800\/80 {
  color: #155e75cc;
}

.text-cyan-800\/90 {
  color: #155e75e6;
}

.text-cyan-800\/95 {
  color: #155e75f2;
}

.text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

.text-cyan-900\/0 {
  color: #164e6300;
}

.text-cyan-900\/10 {
  color: #164e631a;
}

.text-cyan-900\/100 {
  color: #164e63;
}

.text-cyan-900\/20 {
  color: #164e6333;
}

.text-cyan-900\/25 {
  color: #164e6340;
}

.text-cyan-900\/30 {
  color: #164e634d;
}

.text-cyan-900\/40 {
  color: #164e6366;
}

.text-cyan-900\/5 {
  color: #164e630d;
}

.text-cyan-900\/50 {
  color: #164e6380;
}

.text-cyan-900\/60 {
  color: #164e6399;
}

.text-cyan-900\/70 {
  color: #164e63b3;
}

.text-cyan-900\/75 {
  color: #164e63bf;
}

.text-cyan-900\/80 {
  color: #164e63cc;
}

.text-cyan-900\/90 {
  color: #164e63e6;
}

.text-cyan-900\/95 {
  color: #164e63f2;
}

.text-cyan-950 {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity));
}

.text-cyan-950\/0 {
  color: #08334400;
}

.text-cyan-950\/10 {
  color: #0833441a;
}

.text-cyan-950\/100 {
  color: #083344;
}

.text-cyan-950\/20 {
  color: #08334433;
}

.text-cyan-950\/25 {
  color: #08334440;
}

.text-cyan-950\/30 {
  color: #0833444d;
}

.text-cyan-950\/40 {
  color: #08334466;
}

.text-cyan-950\/5 {
  color: #0833440d;
}

.text-cyan-950\/50 {
  color: #08334480;
}

.text-cyan-950\/60 {
  color: #08334499;
}

.text-cyan-950\/70 {
  color: #083344b3;
}

.text-cyan-950\/75 {
  color: #083344bf;
}

.text-cyan-950\/80 {
  color: #083344cc;
}

.text-cyan-950\/90 {
  color: #083344e6;
}

.text-cyan-950\/95 {
  color: #083344f2;
}

.text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

.text-emerald-100\/0 {
  color: #d1fae500;
}

.text-emerald-100\/10 {
  color: #d1fae51a;
}

.text-emerald-100\/100 {
  color: #d1fae5;
}

.text-emerald-100\/20 {
  color: #d1fae533;
}

.text-emerald-100\/25 {
  color: #d1fae540;
}

.text-emerald-100\/30 {
  color: #d1fae54d;
}

.text-emerald-100\/40 {
  color: #d1fae566;
}

.text-emerald-100\/5 {
  color: #d1fae50d;
}

.text-emerald-100\/50 {
  color: #d1fae580;
}

.text-emerald-100\/60 {
  color: #d1fae599;
}

.text-emerald-100\/70 {
  color: #d1fae5b3;
}

.text-emerald-100\/75 {
  color: #d1fae5bf;
}

.text-emerald-100\/80 {
  color: #d1fae5cc;
}

.text-emerald-100\/90 {
  color: #d1fae5e6;
}

.text-emerald-100\/95 {
  color: #d1fae5f2;
}

.text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

.text-emerald-200\/0 {
  color: #a7f3d000;
}

.text-emerald-200\/10 {
  color: #a7f3d01a;
}

.text-emerald-200\/100 {
  color: #a7f3d0;
}

.text-emerald-200\/20 {
  color: #a7f3d033;
}

.text-emerald-200\/25 {
  color: #a7f3d040;
}

.text-emerald-200\/30 {
  color: #a7f3d04d;
}

.text-emerald-200\/40 {
  color: #a7f3d066;
}

.text-emerald-200\/5 {
  color: #a7f3d00d;
}

.text-emerald-200\/50 {
  color: #a7f3d080;
}

.text-emerald-200\/60 {
  color: #a7f3d099;
}

.text-emerald-200\/70 {
  color: #a7f3d0b3;
}

.text-emerald-200\/75 {
  color: #a7f3d0bf;
}

.text-emerald-200\/80 {
  color: #a7f3d0cc;
}

.text-emerald-200\/90 {
  color: #a7f3d0e6;
}

.text-emerald-200\/95 {
  color: #a7f3d0f2;
}

.text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.text-emerald-300\/0 {
  color: #6ee7b700;
}

.text-emerald-300\/10 {
  color: #6ee7b71a;
}

.text-emerald-300\/100 {
  color: #6ee7b7;
}

.text-emerald-300\/20 {
  color: #6ee7b733;
}

.text-emerald-300\/25 {
  color: #6ee7b740;
}

.text-emerald-300\/30 {
  color: #6ee7b74d;
}

.text-emerald-300\/40 {
  color: #6ee7b766;
}

.text-emerald-300\/5 {
  color: #6ee7b70d;
}

.text-emerald-300\/50 {
  color: #6ee7b780;
}

.text-emerald-300\/60 {
  color: #6ee7b799;
}

.text-emerald-300\/70 {
  color: #6ee7b7b3;
}

.text-emerald-300\/75 {
  color: #6ee7b7bf;
}

.text-emerald-300\/80 {
  color: #6ee7b7cc;
}

.text-emerald-300\/90 {
  color: #6ee7b7e6;
}

.text-emerald-300\/95 {
  color: #6ee7b7f2;
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-emerald-400\/0 {
  color: #34d39900;
}

.text-emerald-400\/10 {
  color: #34d3991a;
}

.text-emerald-400\/100 {
  color: #34d399;
}

.text-emerald-400\/20 {
  color: #34d39933;
}

.text-emerald-400\/25 {
  color: #34d39940;
}

.text-emerald-400\/30 {
  color: #34d3994d;
}

.text-emerald-400\/40 {
  color: #34d39966;
}

.text-emerald-400\/5 {
  color: #34d3990d;
}

.text-emerald-400\/50 {
  color: #34d39980;
}

.text-emerald-400\/60 {
  color: #34d39999;
}

.text-emerald-400\/70 {
  color: #34d399b3;
}

.text-emerald-400\/75 {
  color: #34d399bf;
}

.text-emerald-400\/80 {
  color: #34d399cc;
}

.text-emerald-400\/90 {
  color: #34d399e6;
}

.text-emerald-400\/95 {
  color: #34d399f2;
}

.text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

.text-emerald-50\/0 {
  color: #ecfdf500;
}

.text-emerald-50\/10 {
  color: #ecfdf51a;
}

.text-emerald-50\/100 {
  color: #ecfdf5;
}

.text-emerald-50\/20 {
  color: #ecfdf533;
}

.text-emerald-50\/25 {
  color: #ecfdf540;
}

.text-emerald-50\/30 {
  color: #ecfdf54d;
}

.text-emerald-50\/40 {
  color: #ecfdf566;
}

.text-emerald-50\/5 {
  color: #ecfdf50d;
}

.text-emerald-50\/50 {
  color: #ecfdf580;
}

.text-emerald-50\/60 {
  color: #ecfdf599;
}

.text-emerald-50\/70 {
  color: #ecfdf5b3;
}

.text-emerald-50\/75 {
  color: #ecfdf5bf;
}

.text-emerald-50\/80 {
  color: #ecfdf5cc;
}

.text-emerald-50\/90 {
  color: #ecfdf5e6;
}

.text-emerald-50\/95 {
  color: #ecfdf5f2;
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.text-emerald-500\/0 {
  color: #10b98100;
}

.text-emerald-500\/10 {
  color: #10b9811a;
}

.text-emerald-500\/100 {
  color: #10b981;
}

.text-emerald-500\/20 {
  color: #10b98133;
}

.text-emerald-500\/25 {
  color: #10b98140;
}

.text-emerald-500\/30 {
  color: #10b9814d;
}

.text-emerald-500\/40 {
  color: #10b98166;
}

.text-emerald-500\/5 {
  color: #10b9810d;
}

.text-emerald-500\/50 {
  color: #10b98180;
}

.text-emerald-500\/60 {
  color: #10b98199;
}

.text-emerald-500\/70 {
  color: #10b981b3;
}

.text-emerald-500\/75 {
  color: #10b981bf;
}

.text-emerald-500\/80 {
  color: #10b981cc;
}

.text-emerald-500\/90 {
  color: #10b981e6;
}

.text-emerald-500\/95 {
  color: #10b981f2;
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.text-emerald-600\/0 {
  color: #05966900;
}

.text-emerald-600\/10 {
  color: #0596691a;
}

.text-emerald-600\/100 {
  color: #059669;
}

.text-emerald-600\/20 {
  color: #05966933;
}

.text-emerald-600\/25 {
  color: #05966940;
}

.text-emerald-600\/30 {
  color: #0596694d;
}

.text-emerald-600\/40 {
  color: #05966966;
}

.text-emerald-600\/5 {
  color: #0596690d;
}

.text-emerald-600\/50 {
  color: #05966980;
}

.text-emerald-600\/60 {
  color: #05966999;
}

.text-emerald-600\/70 {
  color: #059669b3;
}

.text-emerald-600\/75 {
  color: #059669bf;
}

.text-emerald-600\/80 {
  color: #059669cc;
}

.text-emerald-600\/90 {
  color: #059669e6;
}

.text-emerald-600\/95 {
  color: #059669f2;
}

.text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.text-emerald-700\/0 {
  color: #04785700;
}

.text-emerald-700\/10 {
  color: #0478571a;
}

.text-emerald-700\/100 {
  color: #047857;
}

.text-emerald-700\/20 {
  color: #04785733;
}

.text-emerald-700\/25 {
  color: #04785740;
}

.text-emerald-700\/30 {
  color: #0478574d;
}

.text-emerald-700\/40 {
  color: #04785766;
}

.text-emerald-700\/5 {
  color: #0478570d;
}

.text-emerald-700\/50 {
  color: #04785780;
}

.text-emerald-700\/60 {
  color: #04785799;
}

.text-emerald-700\/70 {
  color: #047857b3;
}

.text-emerald-700\/75 {
  color: #047857bf;
}

.text-emerald-700\/80 {
  color: #047857cc;
}

.text-emerald-700\/90 {
  color: #047857e6;
}

.text-emerald-700\/95 {
  color: #047857f2;
}

.text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.text-emerald-800\/0 {
  color: #065f4600;
}

.text-emerald-800\/10 {
  color: #065f461a;
}

.text-emerald-800\/100 {
  color: #065f46;
}

.text-emerald-800\/20 {
  color: #065f4633;
}

.text-emerald-800\/25 {
  color: #065f4640;
}

.text-emerald-800\/30 {
  color: #065f464d;
}

.text-emerald-800\/40 {
  color: #065f4666;
}

.text-emerald-800\/5 {
  color: #065f460d;
}

.text-emerald-800\/50 {
  color: #065f4680;
}

.text-emerald-800\/60 {
  color: #065f4699;
}

.text-emerald-800\/70 {
  color: #065f46b3;
}

.text-emerald-800\/75 {
  color: #065f46bf;
}

.text-emerald-800\/80 {
  color: #065f46cc;
}

.text-emerald-800\/90 {
  color: #065f46e6;
}

.text-emerald-800\/95 {
  color: #065f46f2;
}

.text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.text-emerald-900\/0 {
  color: #064e3b00;
}

.text-emerald-900\/10 {
  color: #064e3b1a;
}

.text-emerald-900\/100 {
  color: #064e3b;
}

.text-emerald-900\/20 {
  color: #064e3b33;
}

.text-emerald-900\/25 {
  color: #064e3b40;
}

.text-emerald-900\/30 {
  color: #064e3b4d;
}

.text-emerald-900\/40 {
  color: #064e3b66;
}

.text-emerald-900\/5 {
  color: #064e3b0d;
}

.text-emerald-900\/50 {
  color: #064e3b80;
}

.text-emerald-900\/60 {
  color: #064e3b99;
}

.text-emerald-900\/70 {
  color: #064e3bb3;
}

.text-emerald-900\/75 {
  color: #064e3bbf;
}

.text-emerald-900\/80 {
  color: #064e3bcc;
}

.text-emerald-900\/90 {
  color: #064e3be6;
}

.text-emerald-900\/95 {
  color: #064e3bf2;
}

.text-emerald-950 {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

.text-emerald-950\/0 {
  color: #022c2200;
}

.text-emerald-950\/10 {
  color: #022c221a;
}

.text-emerald-950\/100 {
  color: #022c22;
}

.text-emerald-950\/20 {
  color: #022c2233;
}

.text-emerald-950\/25 {
  color: #022c2240;
}

.text-emerald-950\/30 {
  color: #022c224d;
}

.text-emerald-950\/40 {
  color: #022c2266;
}

.text-emerald-950\/5 {
  color: #022c220d;
}

.text-emerald-950\/50 {
  color: #022c2280;
}

.text-emerald-950\/60 {
  color: #022c2299;
}

.text-emerald-950\/70 {
  color: #022c22b3;
}

.text-emerald-950\/75 {
  color: #022c22bf;
}

.text-emerald-950\/80 {
  color: #022c22cc;
}

.text-emerald-950\/90 {
  color: #022c22e6;
}

.text-emerald-950\/95 {
  color: #022c22f2;
}

.text-fuchsia-100 {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity));
}

.text-fuchsia-100\/0 {
  color: #fae8ff00;
}

.text-fuchsia-100\/10 {
  color: #fae8ff1a;
}

.text-fuchsia-100\/100 {
  color: #fae8ff;
}

.text-fuchsia-100\/20 {
  color: #fae8ff33;
}

.text-fuchsia-100\/25 {
  color: #fae8ff40;
}

.text-fuchsia-100\/30 {
  color: #fae8ff4d;
}

.text-fuchsia-100\/40 {
  color: #fae8ff66;
}

.text-fuchsia-100\/5 {
  color: #fae8ff0d;
}

.text-fuchsia-100\/50 {
  color: #fae8ff80;
}

.text-fuchsia-100\/60 {
  color: #fae8ff99;
}

.text-fuchsia-100\/70 {
  color: #fae8ffb3;
}

.text-fuchsia-100\/75 {
  color: #fae8ffbf;
}

.text-fuchsia-100\/80 {
  color: #fae8ffcc;
}

.text-fuchsia-100\/90 {
  color: #fae8ffe6;
}

.text-fuchsia-100\/95 {
  color: #fae8fff2;
}

.text-fuchsia-200 {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity));
}

.text-fuchsia-200\/0 {
  color: #f5d0fe00;
}

.text-fuchsia-200\/10 {
  color: #f5d0fe1a;
}

.text-fuchsia-200\/100 {
  color: #f5d0fe;
}

.text-fuchsia-200\/20 {
  color: #f5d0fe33;
}

.text-fuchsia-200\/25 {
  color: #f5d0fe40;
}

.text-fuchsia-200\/30 {
  color: #f5d0fe4d;
}

.text-fuchsia-200\/40 {
  color: #f5d0fe66;
}

.text-fuchsia-200\/5 {
  color: #f5d0fe0d;
}

.text-fuchsia-200\/50 {
  color: #f5d0fe80;
}

.text-fuchsia-200\/60 {
  color: #f5d0fe99;
}

.text-fuchsia-200\/70 {
  color: #f5d0feb3;
}

.text-fuchsia-200\/75 {
  color: #f5d0febf;
}

.text-fuchsia-200\/80 {
  color: #f5d0fecc;
}

.text-fuchsia-200\/90 {
  color: #f5d0fee6;
}

.text-fuchsia-200\/95 {
  color: #f5d0fef2;
}

.text-fuchsia-300 {
  --tw-text-opacity: 1;
  color: rgb(240 171 252 / var(--tw-text-opacity));
}

.text-fuchsia-300\/0 {
  color: #f0abfc00;
}

.text-fuchsia-300\/10 {
  color: #f0abfc1a;
}

.text-fuchsia-300\/100 {
  color: #f0abfc;
}

.text-fuchsia-300\/20 {
  color: #f0abfc33;
}

.text-fuchsia-300\/25 {
  color: #f0abfc40;
}

.text-fuchsia-300\/30 {
  color: #f0abfc4d;
}

.text-fuchsia-300\/40 {
  color: #f0abfc66;
}

.text-fuchsia-300\/5 {
  color: #f0abfc0d;
}

.text-fuchsia-300\/50 {
  color: #f0abfc80;
}

.text-fuchsia-300\/60 {
  color: #f0abfc99;
}

.text-fuchsia-300\/70 {
  color: #f0abfcb3;
}

.text-fuchsia-300\/75 {
  color: #f0abfcbf;
}

.text-fuchsia-300\/80 {
  color: #f0abfccc;
}

.text-fuchsia-300\/90 {
  color: #f0abfce6;
}

.text-fuchsia-300\/95 {
  color: #f0abfcf2;
}

.text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.text-fuchsia-400\/0 {
  color: #e879f900;
}

.text-fuchsia-400\/10 {
  color: #e879f91a;
}

.text-fuchsia-400\/100 {
  color: #e879f9;
}

.text-fuchsia-400\/20 {
  color: #e879f933;
}

.text-fuchsia-400\/25 {
  color: #e879f940;
}

.text-fuchsia-400\/30 {
  color: #e879f94d;
}

.text-fuchsia-400\/40 {
  color: #e879f966;
}

.text-fuchsia-400\/5 {
  color: #e879f90d;
}

.text-fuchsia-400\/50 {
  color: #e879f980;
}

.text-fuchsia-400\/60 {
  color: #e879f999;
}

.text-fuchsia-400\/70 {
  color: #e879f9b3;
}

.text-fuchsia-400\/75 {
  color: #e879f9bf;
}

.text-fuchsia-400\/80 {
  color: #e879f9cc;
}

.text-fuchsia-400\/90 {
  color: #e879f9e6;
}

.text-fuchsia-400\/95 {
  color: #e879f9f2;
}

.text-fuchsia-50 {
  --tw-text-opacity: 1;
  color: rgb(253 244 255 / var(--tw-text-opacity));
}

.text-fuchsia-50\/0 {
  color: #fdf4ff00;
}

.text-fuchsia-50\/10 {
  color: #fdf4ff1a;
}

.text-fuchsia-50\/100 {
  color: #fdf4ff;
}

.text-fuchsia-50\/20 {
  color: #fdf4ff33;
}

.text-fuchsia-50\/25 {
  color: #fdf4ff40;
}

.text-fuchsia-50\/30 {
  color: #fdf4ff4d;
}

.text-fuchsia-50\/40 {
  color: #fdf4ff66;
}

.text-fuchsia-50\/5 {
  color: #fdf4ff0d;
}

.text-fuchsia-50\/50 {
  color: #fdf4ff80;
}

.text-fuchsia-50\/60 {
  color: #fdf4ff99;
}

.text-fuchsia-50\/70 {
  color: #fdf4ffb3;
}

.text-fuchsia-50\/75 {
  color: #fdf4ffbf;
}

.text-fuchsia-50\/80 {
  color: #fdf4ffcc;
}

.text-fuchsia-50\/90 {
  color: #fdf4ffe6;
}

.text-fuchsia-50\/95 {
  color: #fdf4fff2;
}

.text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

.text-fuchsia-500\/0 {
  color: #d946ef00;
}

.text-fuchsia-500\/10 {
  color: #d946ef1a;
}

.text-fuchsia-500\/100 {
  color: #d946ef;
}

.text-fuchsia-500\/20 {
  color: #d946ef33;
}

.text-fuchsia-500\/25 {
  color: #d946ef40;
}

.text-fuchsia-500\/30 {
  color: #d946ef4d;
}

.text-fuchsia-500\/40 {
  color: #d946ef66;
}

.text-fuchsia-500\/5 {
  color: #d946ef0d;
}

.text-fuchsia-500\/50 {
  color: #d946ef80;
}

.text-fuchsia-500\/60 {
  color: #d946ef99;
}

.text-fuchsia-500\/70 {
  color: #d946efb3;
}

.text-fuchsia-500\/75 {
  color: #d946efbf;
}

.text-fuchsia-500\/80 {
  color: #d946efcc;
}

.text-fuchsia-500\/90 {
  color: #d946efe6;
}

.text-fuchsia-500\/95 {
  color: #d946eff2;
}

.text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

.text-fuchsia-600\/0 {
  color: #c026d300;
}

.text-fuchsia-600\/10 {
  color: #c026d31a;
}

.text-fuchsia-600\/100 {
  color: #c026d3;
}

.text-fuchsia-600\/20 {
  color: #c026d333;
}

.text-fuchsia-600\/25 {
  color: #c026d340;
}

.text-fuchsia-600\/30 {
  color: #c026d34d;
}

.text-fuchsia-600\/40 {
  color: #c026d366;
}

.text-fuchsia-600\/5 {
  color: #c026d30d;
}

.text-fuchsia-600\/50 {
  color: #c026d380;
}

.text-fuchsia-600\/60 {
  color: #c026d399;
}

.text-fuchsia-600\/70 {
  color: #c026d3b3;
}

.text-fuchsia-600\/75 {
  color: #c026d3bf;
}

.text-fuchsia-600\/80 {
  color: #c026d3cc;
}

.text-fuchsia-600\/90 {
  color: #c026d3e6;
}

.text-fuchsia-600\/95 {
  color: #c026d3f2;
}

.text-fuchsia-700 {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

.text-fuchsia-700\/0 {
  color: #a21caf00;
}

.text-fuchsia-700\/10 {
  color: #a21caf1a;
}

.text-fuchsia-700\/100 {
  color: #a21caf;
}

.text-fuchsia-700\/20 {
  color: #a21caf33;
}

.text-fuchsia-700\/25 {
  color: #a21caf40;
}

.text-fuchsia-700\/30 {
  color: #a21caf4d;
}

.text-fuchsia-700\/40 {
  color: #a21caf66;
}

.text-fuchsia-700\/5 {
  color: #a21caf0d;
}

.text-fuchsia-700\/50 {
  color: #a21caf80;
}

.text-fuchsia-700\/60 {
  color: #a21caf99;
}

.text-fuchsia-700\/70 {
  color: #a21cafb3;
}

.text-fuchsia-700\/75 {
  color: #a21cafbf;
}

.text-fuchsia-700\/80 {
  color: #a21cafcc;
}

.text-fuchsia-700\/90 {
  color: #a21cafe6;
}

.text-fuchsia-700\/95 {
  color: #a21caff2;
}

.text-fuchsia-800 {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity));
}

.text-fuchsia-800\/0 {
  color: #86198f00;
}

.text-fuchsia-800\/10 {
  color: #86198f1a;
}

.text-fuchsia-800\/100 {
  color: #86198f;
}

.text-fuchsia-800\/20 {
  color: #86198f33;
}

.text-fuchsia-800\/25 {
  color: #86198f40;
}

.text-fuchsia-800\/30 {
  color: #86198f4d;
}

.text-fuchsia-800\/40 {
  color: #86198f66;
}

.text-fuchsia-800\/5 {
  color: #86198f0d;
}

.text-fuchsia-800\/50 {
  color: #86198f80;
}

.text-fuchsia-800\/60 {
  color: #86198f99;
}

.text-fuchsia-800\/70 {
  color: #86198fb3;
}

.text-fuchsia-800\/75 {
  color: #86198fbf;
}

.text-fuchsia-800\/80 {
  color: #86198fcc;
}

.text-fuchsia-800\/90 {
  color: #86198fe6;
}

.text-fuchsia-800\/95 {
  color: #86198ff2;
}

.text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.text-fuchsia-900\/0 {
  color: #701a7500;
}

.text-fuchsia-900\/10 {
  color: #701a751a;
}

.text-fuchsia-900\/100 {
  color: #701a75;
}

.text-fuchsia-900\/20 {
  color: #701a7533;
}

.text-fuchsia-900\/25 {
  color: #701a7540;
}

.text-fuchsia-900\/30 {
  color: #701a754d;
}

.text-fuchsia-900\/40 {
  color: #701a7566;
}

.text-fuchsia-900\/5 {
  color: #701a750d;
}

.text-fuchsia-900\/50 {
  color: #701a7580;
}

.text-fuchsia-900\/60 {
  color: #701a7599;
}

.text-fuchsia-900\/70 {
  color: #701a75b3;
}

.text-fuchsia-900\/75 {
  color: #701a75bf;
}

.text-fuchsia-900\/80 {
  color: #701a75cc;
}

.text-fuchsia-900\/90 {
  color: #701a75e6;
}

.text-fuchsia-900\/95 {
  color: #701a75f2;
}

.text-fuchsia-950 {
  --tw-text-opacity: 1;
  color: rgb(74 4 78 / var(--tw-text-opacity));
}

.text-fuchsia-950\/0 {
  color: #4a044e00;
}

.text-fuchsia-950\/10 {
  color: #4a044e1a;
}

.text-fuchsia-950\/100 {
  color: #4a044e;
}

.text-fuchsia-950\/20 {
  color: #4a044e33;
}

.text-fuchsia-950\/25 {
  color: #4a044e40;
}

.text-fuchsia-950\/30 {
  color: #4a044e4d;
}

.text-fuchsia-950\/40 {
  color: #4a044e66;
}

.text-fuchsia-950\/5 {
  color: #4a044e0d;
}

.text-fuchsia-950\/50 {
  color: #4a044e80;
}

.text-fuchsia-950\/60 {
  color: #4a044e99;
}

.text-fuchsia-950\/70 {
  color: #4a044eb3;
}

.text-fuchsia-950\/75 {
  color: #4a044ebf;
}

.text-fuchsia-950\/80 {
  color: #4a044ecc;
}

.text-fuchsia-950\/90 {
  color: #4a044ee6;
}

.text-fuchsia-950\/95 {
  color: #4a044ef2;
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-100\/0 {
  color: #f3f4f600;
}

.text-gray-100\/10 {
  color: #f3f4f61a;
}

.text-gray-100\/100 {
  color: #f3f4f6;
}

.text-gray-100\/20 {
  color: #f3f4f633;
}

.text-gray-100\/25 {
  color: #f3f4f640;
}

.text-gray-100\/30 {
  color: #f3f4f64d;
}

.text-gray-100\/40 {
  color: #f3f4f666;
}

.text-gray-100\/5 {
  color: #f3f4f60d;
}

.text-gray-100\/50 {
  color: #f3f4f680;
}

.text-gray-100\/60 {
  color: #f3f4f699;
}

.text-gray-100\/70 {
  color: #f3f4f6b3;
}

.text-gray-100\/75 {
  color: #f3f4f6bf;
}

.text-gray-100\/80 {
  color: #f3f4f6cc;
}

.text-gray-100\/90 {
  color: #f3f4f6e6;
}

.text-gray-100\/95 {
  color: #f3f4f6f2;
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-200\/0 {
  color: #e5e7eb00;
}

.text-gray-200\/10 {
  color: #e5e7eb1a;
}

.text-gray-200\/100 {
  color: #e5e7eb;
}

.text-gray-200\/20 {
  color: #e5e7eb33;
}

.text-gray-200\/25 {
  color: #e5e7eb40;
}

.text-gray-200\/30 {
  color: #e5e7eb4d;
}

.text-gray-200\/40 {
  color: #e5e7eb66;
}

.text-gray-200\/5 {
  color: #e5e7eb0d;
}

.text-gray-200\/50 {
  color: #e5e7eb80;
}

.text-gray-200\/60 {
  color: #e5e7eb99;
}

.text-gray-200\/70 {
  color: #e5e7ebb3;
}

.text-gray-200\/75 {
  color: #e5e7ebbf;
}

.text-gray-200\/80 {
  color: #e5e7ebcc;
}

.text-gray-200\/90 {
  color: #e5e7ebe6;
}

.text-gray-200\/95 {
  color: #e5e7ebf2;
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-300\/0 {
  color: #d1d5db00;
}

.text-gray-300\/10 {
  color: #d1d5db1a;
}

.text-gray-300\/100 {
  color: #d1d5db;
}

.text-gray-300\/20 {
  color: #d1d5db33;
}

.text-gray-300\/25 {
  color: #d1d5db40;
}

.text-gray-300\/30 {
  color: #d1d5db4d;
}

.text-gray-300\/40 {
  color: #d1d5db66;
}

.text-gray-300\/5 {
  color: #d1d5db0d;
}

.text-gray-300\/50 {
  color: #d1d5db80;
}

.text-gray-300\/60 {
  color: #d1d5db99;
}

.text-gray-300\/70 {
  color: #d1d5dbb3;
}

.text-gray-300\/75 {
  color: #d1d5dbbf;
}

.text-gray-300\/80 {
  color: #d1d5dbcc;
}

.text-gray-300\/90 {
  color: #d1d5dbe6;
}

.text-gray-300\/95 {
  color: #d1d5dbf2;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-400\/0 {
  color: #9ca3af00;
}

.text-gray-400\/10 {
  color: #9ca3af1a;
}

.text-gray-400\/100 {
  color: #9ca3af;
}

.text-gray-400\/20 {
  color: #9ca3af33;
}

.text-gray-400\/25 {
  color: #9ca3af40;
}

.text-gray-400\/30 {
  color: #9ca3af4d;
}

.text-gray-400\/40 {
  color: #9ca3af66;
}

.text-gray-400\/5 {
  color: #9ca3af0d;
}

.text-gray-400\/50 {
  color: #9ca3af80;
}

.text-gray-400\/60 {
  color: #9ca3af99;
}

.text-gray-400\/70 {
  color: #9ca3afb3;
}

.text-gray-400\/75 {
  color: #9ca3afbf;
}

.text-gray-400\/80 {
  color: #9ca3afcc;
}

.text-gray-400\/90 {
  color: #9ca3afe6;
}

.text-gray-400\/95 {
  color: #9ca3aff2;
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-50\/0 {
  color: #f9fafb00;
}

.text-gray-50\/10 {
  color: #f9fafb1a;
}

.text-gray-50\/100 {
  color: #f9fafb;
}

.text-gray-50\/20 {
  color: #f9fafb33;
}

.text-gray-50\/25 {
  color: #f9fafb40;
}

.text-gray-50\/30 {
  color: #f9fafb4d;
}

.text-gray-50\/40 {
  color: #f9fafb66;
}

.text-gray-50\/5 {
  color: #f9fafb0d;
}

.text-gray-50\/50 {
  color: #f9fafb80;
}

.text-gray-50\/60 {
  color: #f9fafb99;
}

.text-gray-50\/70 {
  color: #f9fafbb3;
}

.text-gray-50\/75 {
  color: #f9fafbbf;
}

.text-gray-50\/80 {
  color: #f9fafbcc;
}

.text-gray-50\/90 {
  color: #f9fafbe6;
}

.text-gray-50\/95 {
  color: #f9fafbf2;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-500\/0 {
  color: #6b728000;
}

.text-gray-500\/10 {
  color: #6b72801a;
}

.text-gray-500\/100 {
  color: #6b7280;
}

.text-gray-500\/20 {
  color: #6b728033;
}

.text-gray-500\/25 {
  color: #6b728040;
}

.text-gray-500\/30 {
  color: #6b72804d;
}

.text-gray-500\/40 {
  color: #6b728066;
}

.text-gray-500\/5 {
  color: #6b72800d;
}

.text-gray-500\/50 {
  color: #6b728080;
}

.text-gray-500\/60 {
  color: #6b728099;
}

.text-gray-500\/70 {
  color: #6b7280b3;
}

.text-gray-500\/75 {
  color: #6b7280bf;
}

.text-gray-500\/80 {
  color: #6b7280cc;
}

.text-gray-500\/90 {
  color: #6b7280e6;
}

.text-gray-500\/95 {
  color: #6b7280f2;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-600\/0 {
  color: #4b556300;
}

.text-gray-600\/10 {
  color: #4b55631a;
}

.text-gray-600\/100 {
  color: #4b5563;
}

.text-gray-600\/20 {
  color: #4b556333;
}

.text-gray-600\/25 {
  color: #4b556340;
}

.text-gray-600\/30 {
  color: #4b55634d;
}

.text-gray-600\/40 {
  color: #4b556366;
}

.text-gray-600\/5 {
  color: #4b55630d;
}

.text-gray-600\/50 {
  color: #4b556380;
}

.text-gray-600\/60 {
  color: #4b556399;
}

.text-gray-600\/70 {
  color: #4b5563b3;
}

.text-gray-600\/75 {
  color: #4b5563bf;
}

.text-gray-600\/80 {
  color: #4b5563cc;
}

.text-gray-600\/90 {
  color: #4b5563e6;
}

.text-gray-600\/95 {
  color: #4b5563f2;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-700\/0 {
  color: #37415100;
}

.text-gray-700\/10 {
  color: #3741511a;
}

.text-gray-700\/100 {
  color: #374151;
}

.text-gray-700\/20 {
  color: #37415133;
}

.text-gray-700\/25 {
  color: #37415140;
}

.text-gray-700\/30 {
  color: #3741514d;
}

.text-gray-700\/40 {
  color: #37415166;
}

.text-gray-700\/5 {
  color: #3741510d;
}

.text-gray-700\/50 {
  color: #37415180;
}

.text-gray-700\/60 {
  color: #37415199;
}

.text-gray-700\/70 {
  color: #374151b3;
}

.text-gray-700\/75 {
  color: #374151bf;
}

.text-gray-700\/80 {
  color: #374151cc;
}

.text-gray-700\/90 {
  color: #374151e6;
}

.text-gray-700\/95 {
  color: #374151f2;
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-800\/0 {
  color: #1f293700;
}

.text-gray-800\/10 {
  color: #1f29371a;
}

.text-gray-800\/100 {
  color: #1f2937;
}

.text-gray-800\/20 {
  color: #1f293733;
}

.text-gray-800\/25 {
  color: #1f293740;
}

.text-gray-800\/30 {
  color: #1f29374d;
}

.text-gray-800\/40 {
  color: #1f293766;
}

.text-gray-800\/5 {
  color: #1f29370d;
}

.text-gray-800\/50 {
  color: #1f293780;
}

.text-gray-800\/60 {
  color: #1f293799;
}

.text-gray-800\/70 {
  color: #1f2937b3;
}

.text-gray-800\/75 {
  color: #1f2937bf;
}

.text-gray-800\/80 {
  color: #1f2937cc;
}

.text-gray-800\/90 {
  color: #1f2937e6;
}

.text-gray-800\/95 {
  color: #1f2937f2;
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-900\/0 {
  color: #11182700;
}

.text-gray-900\/10 {
  color: #1118271a;
}

.text-gray-900\/100 {
  color: #111827;
}

.text-gray-900\/20 {
  color: #11182733;
}

.text-gray-900\/25 {
  color: #11182740;
}

.text-gray-900\/30 {
  color: #1118274d;
}

.text-gray-900\/40 {
  color: #11182766;
}

.text-gray-900\/5 {
  color: #1118270d;
}

.text-gray-900\/50 {
  color: #11182780;
}

.text-gray-900\/60 {
  color: #11182799;
}

.text-gray-900\/70 {
  color: #111827b3;
}

.text-gray-900\/75 {
  color: #111827bf;
}

.text-gray-900\/80 {
  color: #111827cc;
}

.text-gray-900\/90 {
  color: #111827e6;
}

.text-gray-900\/95 {
  color: #111827f2;
}

.text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.text-gray-950\/0 {
  color: #03071200;
}

.text-gray-950\/10 {
  color: #0307121a;
}

.text-gray-950\/100 {
  color: #030712;
}

.text-gray-950\/20 {
  color: #03071233;
}

.text-gray-950\/25 {
  color: #03071240;
}

.text-gray-950\/30 {
  color: #0307124d;
}

.text-gray-950\/40 {
  color: #03071266;
}

.text-gray-950\/5 {
  color: #0307120d;
}

.text-gray-950\/50 {
  color: #03071280;
}

.text-gray-950\/60 {
  color: #03071299;
}

.text-gray-950\/70 {
  color: #030712b3;
}

.text-gray-950\/75 {
  color: #030712bf;
}

.text-gray-950\/80 {
  color: #030712cc;
}

.text-gray-950\/90 {
  color: #030712e6;
}

.text-gray-950\/95 {
  color: #030712f2;
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity));
}

.text-green-100\/0 {
  color: #dcfce700;
}

.text-green-100\/10 {
  color: #dcfce71a;
}

.text-green-100\/100 {
  color: #dcfce7;
}

.text-green-100\/20 {
  color: #dcfce733;
}

.text-green-100\/25 {
  color: #dcfce740;
}

.text-green-100\/30 {
  color: #dcfce74d;
}

.text-green-100\/40 {
  color: #dcfce766;
}

.text-green-100\/5 {
  color: #dcfce70d;
}

.text-green-100\/50 {
  color: #dcfce780;
}

.text-green-100\/60 {
  color: #dcfce799;
}

.text-green-100\/70 {
  color: #dcfce7b3;
}

.text-green-100\/75 {
  color: #dcfce7bf;
}

.text-green-100\/80 {
  color: #dcfce7cc;
}

.text-green-100\/90 {
  color: #dcfce7e6;
}

.text-green-100\/95 {
  color: #dcfce7f2;
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity));
}

.text-green-200\/0 {
  color: #bbf7d000;
}

.text-green-200\/10 {
  color: #bbf7d01a;
}

.text-green-200\/100 {
  color: #bbf7d0;
}

.text-green-200\/20 {
  color: #bbf7d033;
}

.text-green-200\/25 {
  color: #bbf7d040;
}

.text-green-200\/30 {
  color: #bbf7d04d;
}

.text-green-200\/40 {
  color: #bbf7d066;
}

.text-green-200\/5 {
  color: #bbf7d00d;
}

.text-green-200\/50 {
  color: #bbf7d080;
}

.text-green-200\/60 {
  color: #bbf7d099;
}

.text-green-200\/70 {
  color: #bbf7d0b3;
}

.text-green-200\/75 {
  color: #bbf7d0bf;
}

.text-green-200\/80 {
  color: #bbf7d0cc;
}

.text-green-200\/90 {
  color: #bbf7d0e6;
}

.text-green-200\/95 {
  color: #bbf7d0f2;
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.text-green-300\/0 {
  color: #86efac00;
}

.text-green-300\/10 {
  color: #86efac1a;
}

.text-green-300\/100 {
  color: #86efac;
}

.text-green-300\/20 {
  color: #86efac33;
}

.text-green-300\/25 {
  color: #86efac40;
}

.text-green-300\/30 {
  color: #86efac4d;
}

.text-green-300\/40 {
  color: #86efac66;
}

.text-green-300\/5 {
  color: #86efac0d;
}

.text-green-300\/50 {
  color: #86efac80;
}

.text-green-300\/60 {
  color: #86efac99;
}

.text-green-300\/70 {
  color: #86efacb3;
}

.text-green-300\/75 {
  color: #86efacbf;
}

.text-green-300\/80 {
  color: #86efaccc;
}

.text-green-300\/90 {
  color: #86eface6;
}

.text-green-300\/95 {
  color: #86efacf2;
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-400\/0 {
  color: #4ade8000;
}

.text-green-400\/10 {
  color: #4ade801a;
}

.text-green-400\/100 {
  color: #4ade80;
}

.text-green-400\/20 {
  color: #4ade8033;
}

.text-green-400\/25 {
  color: #4ade8040;
}

.text-green-400\/30 {
  color: #4ade804d;
}

.text-green-400\/40 {
  color: #4ade8066;
}

.text-green-400\/5 {
  color: #4ade800d;
}

.text-green-400\/50 {
  color: #4ade8080;
}

.text-green-400\/60 {
  color: #4ade8099;
}

.text-green-400\/70 {
  color: #4ade80b3;
}

.text-green-400\/75 {
  color: #4ade80bf;
}

.text-green-400\/80 {
  color: #4ade80cc;
}

.text-green-400\/90 {
  color: #4ade80e6;
}

.text-green-400\/95 {
  color: #4ade80f2;
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 244 / var(--tw-text-opacity));
}

.text-green-50\/0 {
  color: #f0fdf400;
}

.text-green-50\/10 {
  color: #f0fdf41a;
}

.text-green-50\/100 {
  color: #f0fdf4;
}

.text-green-50\/20 {
  color: #f0fdf433;
}

.text-green-50\/25 {
  color: #f0fdf440;
}

.text-green-50\/30 {
  color: #f0fdf44d;
}

.text-green-50\/40 {
  color: #f0fdf466;
}

.text-green-50\/5 {
  color: #f0fdf40d;
}

.text-green-50\/50 {
  color: #f0fdf480;
}

.text-green-50\/60 {
  color: #f0fdf499;
}

.text-green-50\/70 {
  color: #f0fdf4b3;
}

.text-green-50\/75 {
  color: #f0fdf4bf;
}

.text-green-50\/80 {
  color: #f0fdf4cc;
}

.text-green-50\/90 {
  color: #f0fdf4e6;
}

.text-green-50\/95 {
  color: #f0fdf4f2;
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-500\/0 {
  color: #22c55e00;
}

.text-green-500\/10 {
  color: #22c55e1a;
}

.text-green-500\/100 {
  color: #22c55e;
}

.text-green-500\/20 {
  color: #22c55e33;
}

.text-green-500\/25 {
  color: #22c55e40;
}

.text-green-500\/30 {
  color: #22c55e4d;
}

.text-green-500\/40 {
  color: #22c55e66;
}

.text-green-500\/5 {
  color: #22c55e0d;
}

.text-green-500\/50 {
  color: #22c55e80;
}

.text-green-500\/60 {
  color: #22c55e99;
}

.text-green-500\/70 {
  color: #22c55eb3;
}

.text-green-500\/75 {
  color: #22c55ebf;
}

.text-green-500\/80 {
  color: #22c55ecc;
}

.text-green-500\/90 {
  color: #22c55ee6;
}

.text-green-500\/95 {
  color: #22c55ef2;
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-600\/0 {
  color: #16a34a00;
}

.text-green-600\/10 {
  color: #16a34a1a;
}

.text-green-600\/100 {
  color: #16a34a;
}

.text-green-600\/20 {
  color: #16a34a33;
}

.text-green-600\/25 {
  color: #16a34a40;
}

.text-green-600\/30 {
  color: #16a34a4d;
}

.text-green-600\/40 {
  color: #16a34a66;
}

.text-green-600\/5 {
  color: #16a34a0d;
}

.text-green-600\/50 {
  color: #16a34a80;
}

.text-green-600\/60 {
  color: #16a34a99;
}

.text-green-600\/70 {
  color: #16a34ab3;
}

.text-green-600\/75 {
  color: #16a34abf;
}

.text-green-600\/80 {
  color: #16a34acc;
}

.text-green-600\/90 {
  color: #16a34ae6;
}

.text-green-600\/95 {
  color: #16a34af2;
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-green-700\/0 {
  color: #15803d00;
}

.text-green-700\/10 {
  color: #15803d1a;
}

.text-green-700\/100 {
  color: #15803d;
}

.text-green-700\/20 {
  color: #15803d33;
}

.text-green-700\/25 {
  color: #15803d40;
}

.text-green-700\/30 {
  color: #15803d4d;
}

.text-green-700\/40 {
  color: #15803d66;
}

.text-green-700\/5 {
  color: #15803d0d;
}

.text-green-700\/50 {
  color: #15803d80;
}

.text-green-700\/60 {
  color: #15803d99;
}

.text-green-700\/70 {
  color: #15803db3;
}

.text-green-700\/75 {
  color: #15803dbf;
}

.text-green-700\/80 {
  color: #15803dcc;
}

.text-green-700\/90 {
  color: #15803de6;
}

.text-green-700\/95 {
  color: #15803df2;
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-green-800\/0 {
  color: #16653400;
}

.text-green-800\/10 {
  color: #1665341a;
}

.text-green-800\/100 {
  color: #166534;
}

.text-green-800\/20 {
  color: #16653433;
}

.text-green-800\/25 {
  color: #16653440;
}

.text-green-800\/30 {
  color: #1665344d;
}

.text-green-800\/40 {
  color: #16653466;
}

.text-green-800\/5 {
  color: #1665340d;
}

.text-green-800\/50 {
  color: #16653480;
}

.text-green-800\/60 {
  color: #16653499;
}

.text-green-800\/70 {
  color: #166534b3;
}

.text-green-800\/75 {
  color: #166534bf;
}

.text-green-800\/80 {
  color: #166534cc;
}

.text-green-800\/90 {
  color: #166534e6;
}

.text-green-800\/95 {
  color: #166534f2;
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-green-900\/0 {
  color: #14532d00;
}

.text-green-900\/10 {
  color: #14532d1a;
}

.text-green-900\/100 {
  color: #14532d;
}

.text-green-900\/20 {
  color: #14532d33;
}

.text-green-900\/25 {
  color: #14532d40;
}

.text-green-900\/30 {
  color: #14532d4d;
}

.text-green-900\/40 {
  color: #14532d66;
}

.text-green-900\/5 {
  color: #14532d0d;
}

.text-green-900\/50 {
  color: #14532d80;
}

.text-green-900\/60 {
  color: #14532d99;
}

.text-green-900\/70 {
  color: #14532db3;
}

.text-green-900\/75 {
  color: #14532dbf;
}

.text-green-900\/80 {
  color: #14532dcc;
}

.text-green-900\/90 {
  color: #14532de6;
}

.text-green-900\/95 {
  color: #14532df2;
}

.text-green-950 {
  --tw-text-opacity: 1;
  color: rgb(5 46 22 / var(--tw-text-opacity));
}

.text-green-950\/0 {
  color: #052e1600;
}

.text-green-950\/10 {
  color: #052e161a;
}

.text-green-950\/100 {
  color: #052e16;
}

.text-green-950\/20 {
  color: #052e1633;
}

.text-green-950\/25 {
  color: #052e1640;
}

.text-green-950\/30 {
  color: #052e164d;
}

.text-green-950\/40 {
  color: #052e1666;
}

.text-green-950\/5 {
  color: #052e160d;
}

.text-green-950\/50 {
  color: #052e1680;
}

.text-green-950\/60 {
  color: #052e1699;
}

.text-green-950\/70 {
  color: #052e16b3;
}

.text-green-950\/75 {
  color: #052e16bf;
}

.text-green-950\/80 {
  color: #052e16cc;
}

.text-green-950\/90 {
  color: #052e16e6;
}

.text-green-950\/95 {
  color: #052e16f2;
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-indigo-100\/0 {
  color: #e0e7ff00;
}

.text-indigo-100\/10 {
  color: #e0e7ff1a;
}

.text-indigo-100\/100 {
  color: #e0e7ff;
}

.text-indigo-100\/20 {
  color: #e0e7ff33;
}

.text-indigo-100\/25 {
  color: #e0e7ff40;
}

.text-indigo-100\/30 {
  color: #e0e7ff4d;
}

.text-indigo-100\/40 {
  color: #e0e7ff66;
}

.text-indigo-100\/5 {
  color: #e0e7ff0d;
}

.text-indigo-100\/50 {
  color: #e0e7ff80;
}

.text-indigo-100\/60 {
  color: #e0e7ff99;
}

.text-indigo-100\/70 {
  color: #e0e7ffb3;
}

.text-indigo-100\/75 {
  color: #e0e7ffbf;
}

.text-indigo-100\/80 {
  color: #e0e7ffcc;
}

.text-indigo-100\/90 {
  color: #e0e7ffe6;
}

.text-indigo-100\/95 {
  color: #e0e7fff2;
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-indigo-200\/0 {
  color: #c7d2fe00;
}

.text-indigo-200\/10 {
  color: #c7d2fe1a;
}

.text-indigo-200\/100 {
  color: #c7d2fe;
}

.text-indigo-200\/20 {
  color: #c7d2fe33;
}

.text-indigo-200\/25 {
  color: #c7d2fe40;
}

.text-indigo-200\/30 {
  color: #c7d2fe4d;
}

.text-indigo-200\/40 {
  color: #c7d2fe66;
}

.text-indigo-200\/5 {
  color: #c7d2fe0d;
}

.text-indigo-200\/50 {
  color: #c7d2fe80;
}

.text-indigo-200\/60 {
  color: #c7d2fe99;
}

.text-indigo-200\/70 {
  color: #c7d2feb3;
}

.text-indigo-200\/75 {
  color: #c7d2febf;
}

.text-indigo-200\/80 {
  color: #c7d2fecc;
}

.text-indigo-200\/90 {
  color: #c7d2fee6;
}

.text-indigo-200\/95 {
  color: #c7d2fef2;
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.text-indigo-300\/0 {
  color: #a5b4fc00;
}

.text-indigo-300\/10 {
  color: #a5b4fc1a;
}

.text-indigo-300\/100 {
  color: #a5b4fc;
}

.text-indigo-300\/20 {
  color: #a5b4fc33;
}

.text-indigo-300\/25 {
  color: #a5b4fc40;
}

.text-indigo-300\/30 {
  color: #a5b4fc4d;
}

.text-indigo-300\/40 {
  color: #a5b4fc66;
}

.text-indigo-300\/5 {
  color: #a5b4fc0d;
}

.text-indigo-300\/50 {
  color: #a5b4fc80;
}

.text-indigo-300\/60 {
  color: #a5b4fc99;
}

.text-indigo-300\/70 {
  color: #a5b4fcb3;
}

.text-indigo-300\/75 {
  color: #a5b4fcbf;
}

.text-indigo-300\/80 {
  color: #a5b4fccc;
}

.text-indigo-300\/90 {
  color: #a5b4fce6;
}

.text-indigo-300\/95 {
  color: #a5b4fcf2;
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.text-indigo-400\/0 {
  color: #818cf800;
}

.text-indigo-400\/10 {
  color: #818cf81a;
}

.text-indigo-400\/100 {
  color: #818cf8;
}

.text-indigo-400\/20 {
  color: #818cf833;
}

.text-indigo-400\/25 {
  color: #818cf840;
}

.text-indigo-400\/30 {
  color: #818cf84d;
}

.text-indigo-400\/40 {
  color: #818cf866;
}

.text-indigo-400\/5 {
  color: #818cf80d;
}

.text-indigo-400\/50 {
  color: #818cf880;
}

.text-indigo-400\/60 {
  color: #818cf899;
}

.text-indigo-400\/70 {
  color: #818cf8b3;
}

.text-indigo-400\/75 {
  color: #818cf8bf;
}

.text-indigo-400\/80 {
  color: #818cf8cc;
}

.text-indigo-400\/90 {
  color: #818cf8e6;
}

.text-indigo-400\/95 {
  color: #818cf8f2;
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.text-indigo-50\/0 {
  color: #eef2ff00;
}

.text-indigo-50\/10 {
  color: #eef2ff1a;
}

.text-indigo-50\/100 {
  color: #eef2ff;
}

.text-indigo-50\/20 {
  color: #eef2ff33;
}

.text-indigo-50\/25 {
  color: #eef2ff40;
}

.text-indigo-50\/30 {
  color: #eef2ff4d;
}

.text-indigo-50\/40 {
  color: #eef2ff66;
}

.text-indigo-50\/5 {
  color: #eef2ff0d;
}

.text-indigo-50\/50 {
  color: #eef2ff80;
}

.text-indigo-50\/60 {
  color: #eef2ff99;
}

.text-indigo-50\/70 {
  color: #eef2ffb3;
}

.text-indigo-50\/75 {
  color: #eef2ffbf;
}

.text-indigo-50\/80 {
  color: #eef2ffcc;
}

.text-indigo-50\/90 {
  color: #eef2ffe6;
}

.text-indigo-50\/95 {
  color: #eef2fff2;
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-indigo-500\/0 {
  color: #6366f100;
}

.text-indigo-500\/10 {
  color: #6366f11a;
}

.text-indigo-500\/100 {
  color: #6366f1;
}

.text-indigo-500\/20 {
  color: #6366f133;
}

.text-indigo-500\/25 {
  color: #6366f140;
}

.text-indigo-500\/30 {
  color: #6366f14d;
}

.text-indigo-500\/40 {
  color: #6366f166;
}

.text-indigo-500\/5 {
  color: #6366f10d;
}

.text-indigo-500\/50 {
  color: #6366f180;
}

.text-indigo-500\/60 {
  color: #6366f199;
}

.text-indigo-500\/70 {
  color: #6366f1b3;
}

.text-indigo-500\/75 {
  color: #6366f1bf;
}

.text-indigo-500\/80 {
  color: #6366f1cc;
}

.text-indigo-500\/90 {
  color: #6366f1e6;
}

.text-indigo-500\/95 {
  color: #6366f1f2;
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-indigo-600\/0 {
  color: #4f46e500;
}

.text-indigo-600\/10 {
  color: #4f46e51a;
}

.text-indigo-600\/100 {
  color: #4f46e5;
}

.text-indigo-600\/20 {
  color: #4f46e533;
}

.text-indigo-600\/25 {
  color: #4f46e540;
}

.text-indigo-600\/30 {
  color: #4f46e54d;
}

.text-indigo-600\/40 {
  color: #4f46e566;
}

.text-indigo-600\/5 {
  color: #4f46e50d;
}

.text-indigo-600\/50 {
  color: #4f46e580;
}

.text-indigo-600\/60 {
  color: #4f46e599;
}

.text-indigo-600\/70 {
  color: #4f46e5b3;
}

.text-indigo-600\/75 {
  color: #4f46e5bf;
}

.text-indigo-600\/80 {
  color: #4f46e5cc;
}

.text-indigo-600\/90 {
  color: #4f46e5e6;
}

.text-indigo-600\/95 {
  color: #4f46e5f2;
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.text-indigo-700\/0 {
  color: #4338ca00;
}

.text-indigo-700\/10 {
  color: #4338ca1a;
}

.text-indigo-700\/100 {
  color: #4338ca;
}

.text-indigo-700\/20 {
  color: #4338ca33;
}

.text-indigo-700\/25 {
  color: #4338ca40;
}

.text-indigo-700\/30 {
  color: #4338ca4d;
}

.text-indigo-700\/40 {
  color: #4338ca66;
}

.text-indigo-700\/5 {
  color: #4338ca0d;
}

.text-indigo-700\/50 {
  color: #4338ca80;
}

.text-indigo-700\/60 {
  color: #4338ca99;
}

.text-indigo-700\/70 {
  color: #4338cab3;
}

.text-indigo-700\/75 {
  color: #4338cabf;
}

.text-indigo-700\/80 {
  color: #4338cacc;
}

.text-indigo-700\/90 {
  color: #4338cae6;
}

.text-indigo-700\/95 {
  color: #4338caf2;
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.text-indigo-800\/0 {
  color: #3730a300;
}

.text-indigo-800\/10 {
  color: #3730a31a;
}

.text-indigo-800\/100 {
  color: #3730a3;
}

.text-indigo-800\/20 {
  color: #3730a333;
}

.text-indigo-800\/25 {
  color: #3730a340;
}

.text-indigo-800\/30 {
  color: #3730a34d;
}

.text-indigo-800\/40 {
  color: #3730a366;
}

.text-indigo-800\/5 {
  color: #3730a30d;
}

.text-indigo-800\/50 {
  color: #3730a380;
}

.text-indigo-800\/60 {
  color: #3730a399;
}

.text-indigo-800\/70 {
  color: #3730a3b3;
}

.text-indigo-800\/75 {
  color: #3730a3bf;
}

.text-indigo-800\/80 {
  color: #3730a3cc;
}

.text-indigo-800\/90 {
  color: #3730a3e6;
}

.text-indigo-800\/95 {
  color: #3730a3f2;
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.text-indigo-900\/0 {
  color: #312e8100;
}

.text-indigo-900\/10 {
  color: #312e811a;
}

.text-indigo-900\/100 {
  color: #312e81;
}

.text-indigo-900\/20 {
  color: #312e8133;
}

.text-indigo-900\/25 {
  color: #312e8140;
}

.text-indigo-900\/30 {
  color: #312e814d;
}

.text-indigo-900\/40 {
  color: #312e8166;
}

.text-indigo-900\/5 {
  color: #312e810d;
}

.text-indigo-900\/50 {
  color: #312e8180;
}

.text-indigo-900\/60 {
  color: #312e8199;
}

.text-indigo-900\/70 {
  color: #312e81b3;
}

.text-indigo-900\/75 {
  color: #312e81bf;
}

.text-indigo-900\/80 {
  color: #312e81cc;
}

.text-indigo-900\/90 {
  color: #312e81e6;
}

.text-indigo-900\/95 {
  color: #312e81f2;
}

.text-indigo-950 {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}

.text-indigo-950\/0 {
  color: #1e1b4b00;
}

.text-indigo-950\/10 {
  color: #1e1b4b1a;
}

.text-indigo-950\/100 {
  color: #1e1b4b;
}

.text-indigo-950\/20 {
  color: #1e1b4b33;
}

.text-indigo-950\/25 {
  color: #1e1b4b40;
}

.text-indigo-950\/30 {
  color: #1e1b4b4d;
}

.text-indigo-950\/40 {
  color: #1e1b4b66;
}

.text-indigo-950\/5 {
  color: #1e1b4b0d;
}

.text-indigo-950\/50 {
  color: #1e1b4b80;
}

.text-indigo-950\/60 {
  color: #1e1b4b99;
}

.text-indigo-950\/70 {
  color: #1e1b4bb3;
}

.text-indigo-950\/75 {
  color: #1e1b4bbf;
}

.text-indigo-950\/80 {
  color: #1e1b4bcc;
}

.text-indigo-950\/90 {
  color: #1e1b4be6;
}

.text-indigo-950\/95 {
  color: #1e1b4bf2;
}

.text-inherit {
  color: inherit;
}

.text-lime-100 {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

.text-lime-100\/0 {
  color: #ecfccb00;
}

.text-lime-100\/10 {
  color: #ecfccb1a;
}

.text-lime-100\/100 {
  color: #ecfccb;
}

.text-lime-100\/20 {
  color: #ecfccb33;
}

.text-lime-100\/25 {
  color: #ecfccb40;
}

.text-lime-100\/30 {
  color: #ecfccb4d;
}

.text-lime-100\/40 {
  color: #ecfccb66;
}

.text-lime-100\/5 {
  color: #ecfccb0d;
}

.text-lime-100\/50 {
  color: #ecfccb80;
}

.text-lime-100\/60 {
  color: #ecfccb99;
}

.text-lime-100\/70 {
  color: #ecfccbb3;
}

.text-lime-100\/75 {
  color: #ecfccbbf;
}

.text-lime-100\/80 {
  color: #ecfccbcc;
}

.text-lime-100\/90 {
  color: #ecfccbe6;
}

.text-lime-100\/95 {
  color: #ecfccbf2;
}

.text-lime-200 {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

.text-lime-200\/0 {
  color: #d9f99d00;
}

.text-lime-200\/10 {
  color: #d9f99d1a;
}

.text-lime-200\/100 {
  color: #d9f99d;
}

.text-lime-200\/20 {
  color: #d9f99d33;
}

.text-lime-200\/25 {
  color: #d9f99d40;
}

.text-lime-200\/30 {
  color: #d9f99d4d;
}

.text-lime-200\/40 {
  color: #d9f99d66;
}

.text-lime-200\/5 {
  color: #d9f99d0d;
}

.text-lime-200\/50 {
  color: #d9f99d80;
}

.text-lime-200\/60 {
  color: #d9f99d99;
}

.text-lime-200\/70 {
  color: #d9f99db3;
}

.text-lime-200\/75 {
  color: #d9f99dbf;
}

.text-lime-200\/80 {
  color: #d9f99dcc;
}

.text-lime-200\/90 {
  color: #d9f99de6;
}

.text-lime-200\/95 {
  color: #d9f99df2;
}

.text-lime-300 {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.text-lime-300\/0 {
  color: #bef26400;
}

.text-lime-300\/10 {
  color: #bef2641a;
}

.text-lime-300\/100 {
  color: #bef264;
}

.text-lime-300\/20 {
  color: #bef26433;
}

.text-lime-300\/25 {
  color: #bef26440;
}

.text-lime-300\/30 {
  color: #bef2644d;
}

.text-lime-300\/40 {
  color: #bef26466;
}

.text-lime-300\/5 {
  color: #bef2640d;
}

.text-lime-300\/50 {
  color: #bef26480;
}

.text-lime-300\/60 {
  color: #bef26499;
}

.text-lime-300\/70 {
  color: #bef264b3;
}

.text-lime-300\/75 {
  color: #bef264bf;
}

.text-lime-300\/80 {
  color: #bef264cc;
}

.text-lime-300\/90 {
  color: #bef264e6;
}

.text-lime-300\/95 {
  color: #bef264f2;
}

.text-lime-400 {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.text-lime-400\/0 {
  color: #a3e63500;
}

.text-lime-400\/10 {
  color: #a3e6351a;
}

.text-lime-400\/100 {
  color: #a3e635;
}

.text-lime-400\/20 {
  color: #a3e63533;
}

.text-lime-400\/25 {
  color: #a3e63540;
}

.text-lime-400\/30 {
  color: #a3e6354d;
}

.text-lime-400\/40 {
  color: #a3e63566;
}

.text-lime-400\/5 {
  color: #a3e6350d;
}

.text-lime-400\/50 {
  color: #a3e63580;
}

.text-lime-400\/60 {
  color: #a3e63599;
}

.text-lime-400\/70 {
  color: #a3e635b3;
}

.text-lime-400\/75 {
  color: #a3e635bf;
}

.text-lime-400\/80 {
  color: #a3e635cc;
}

.text-lime-400\/90 {
  color: #a3e635e6;
}

.text-lime-400\/95 {
  color: #a3e635f2;
}

.text-lime-50 {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.text-lime-50\/0 {
  color: #f7fee700;
}

.text-lime-50\/10 {
  color: #f7fee71a;
}

.text-lime-50\/100 {
  color: #f7fee7;
}

.text-lime-50\/20 {
  color: #f7fee733;
}

.text-lime-50\/25 {
  color: #f7fee740;
}

.text-lime-50\/30 {
  color: #f7fee74d;
}

.text-lime-50\/40 {
  color: #f7fee766;
}

.text-lime-50\/5 {
  color: #f7fee70d;
}

.text-lime-50\/50 {
  color: #f7fee780;
}

.text-lime-50\/60 {
  color: #f7fee799;
}

.text-lime-50\/70 {
  color: #f7fee7b3;
}

.text-lime-50\/75 {
  color: #f7fee7bf;
}

.text-lime-50\/80 {
  color: #f7fee7cc;
}

.text-lime-50\/90 {
  color: #f7fee7e6;
}

.text-lime-50\/95 {
  color: #f7fee7f2;
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-lime-500\/0 {
  color: #84cc1600;
}

.text-lime-500\/10 {
  color: #84cc161a;
}

.text-lime-500\/100 {
  color: #84cc16;
}

.text-lime-500\/20 {
  color: #84cc1633;
}

.text-lime-500\/25 {
  color: #84cc1640;
}

.text-lime-500\/30 {
  color: #84cc164d;
}

.text-lime-500\/40 {
  color: #84cc1666;
}

.text-lime-500\/5 {
  color: #84cc160d;
}

.text-lime-500\/50 {
  color: #84cc1680;
}

.text-lime-500\/60 {
  color: #84cc1699;
}

.text-lime-500\/70 {
  color: #84cc16b3;
}

.text-lime-500\/75 {
  color: #84cc16bf;
}

.text-lime-500\/80 {
  color: #84cc16cc;
}

.text-lime-500\/90 {
  color: #84cc16e6;
}

.text-lime-500\/95 {
  color: #84cc16f2;
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.text-lime-600\/0 {
  color: #65a30d00;
}

.text-lime-600\/10 {
  color: #65a30d1a;
}

.text-lime-600\/100 {
  color: #65a30d;
}

.text-lime-600\/20 {
  color: #65a30d33;
}

.text-lime-600\/25 {
  color: #65a30d40;
}

.text-lime-600\/30 {
  color: #65a30d4d;
}

.text-lime-600\/40 {
  color: #65a30d66;
}

.text-lime-600\/5 {
  color: #65a30d0d;
}

.text-lime-600\/50 {
  color: #65a30d80;
}

.text-lime-600\/60 {
  color: #65a30d99;
}

.text-lime-600\/70 {
  color: #65a30db3;
}

.text-lime-600\/75 {
  color: #65a30dbf;
}

.text-lime-600\/80 {
  color: #65a30dcc;
}

.text-lime-600\/90 {
  color: #65a30de6;
}

.text-lime-600\/95 {
  color: #65a30df2;
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.text-lime-700\/0 {
  color: #4d7c0f00;
}

.text-lime-700\/10 {
  color: #4d7c0f1a;
}

.text-lime-700\/100 {
  color: #4d7c0f;
}

.text-lime-700\/20 {
  color: #4d7c0f33;
}

.text-lime-700\/25 {
  color: #4d7c0f40;
}

.text-lime-700\/30 {
  color: #4d7c0f4d;
}

.text-lime-700\/40 {
  color: #4d7c0f66;
}

.text-lime-700\/5 {
  color: #4d7c0f0d;
}

.text-lime-700\/50 {
  color: #4d7c0f80;
}

.text-lime-700\/60 {
  color: #4d7c0f99;
}

.text-lime-700\/70 {
  color: #4d7c0fb3;
}

.text-lime-700\/75 {
  color: #4d7c0fbf;
}

.text-lime-700\/80 {
  color: #4d7c0fcc;
}

.text-lime-700\/90 {
  color: #4d7c0fe6;
}

.text-lime-700\/95 {
  color: #4d7c0ff2;
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.text-lime-800\/0 {
  color: #3f621200;
}

.text-lime-800\/10 {
  color: #3f62121a;
}

.text-lime-800\/100 {
  color: #3f6212;
}

.text-lime-800\/20 {
  color: #3f621233;
}

.text-lime-800\/25 {
  color: #3f621240;
}

.text-lime-800\/30 {
  color: #3f62124d;
}

.text-lime-800\/40 {
  color: #3f621266;
}

.text-lime-800\/5 {
  color: #3f62120d;
}

.text-lime-800\/50 {
  color: #3f621280;
}

.text-lime-800\/60 {
  color: #3f621299;
}

.text-lime-800\/70 {
  color: #3f6212b3;
}

.text-lime-800\/75 {
  color: #3f6212bf;
}

.text-lime-800\/80 {
  color: #3f6212cc;
}

.text-lime-800\/90 {
  color: #3f6212e6;
}

.text-lime-800\/95 {
  color: #3f6212f2;
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.text-lime-900\/0 {
  color: #36531400;
}

.text-lime-900\/10 {
  color: #3653141a;
}

.text-lime-900\/100 {
  color: #365314;
}

.text-lime-900\/20 {
  color: #36531433;
}

.text-lime-900\/25 {
  color: #36531440;
}

.text-lime-900\/30 {
  color: #3653144d;
}

.text-lime-900\/40 {
  color: #36531466;
}

.text-lime-900\/5 {
  color: #3653140d;
}

.text-lime-900\/50 {
  color: #36531480;
}

.text-lime-900\/60 {
  color: #36531499;
}

.text-lime-900\/70 {
  color: #365314b3;
}

.text-lime-900\/75 {
  color: #365314bf;
}

.text-lime-900\/80 {
  color: #365314cc;
}

.text-lime-900\/90 {
  color: #365314e6;
}

.text-lime-900\/95 {
  color: #365314f2;
}

.text-lime-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.text-lime-950\/0 {
  color: #1a2e0500;
}

.text-lime-950\/10 {
  color: #1a2e051a;
}

.text-lime-950\/100 {
  color: #1a2e05;
}

.text-lime-950\/20 {
  color: #1a2e0533;
}

.text-lime-950\/25 {
  color: #1a2e0540;
}

.text-lime-950\/30 {
  color: #1a2e054d;
}

.text-lime-950\/40 {
  color: #1a2e0566;
}

.text-lime-950\/5 {
  color: #1a2e050d;
}

.text-lime-950\/50 {
  color: #1a2e0580;
}

.text-lime-950\/60 {
  color: #1a2e0599;
}

.text-lime-950\/70 {
  color: #1a2e05b3;
}

.text-lime-950\/75 {
  color: #1a2e05bf;
}

.text-lime-950\/80 {
  color: #1a2e05cc;
}

.text-lime-950\/90 {
  color: #1a2e05e6;
}

.text-lime-950\/95 {
  color: #1a2e05f2;
}

.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-neutral-100\/0 {
  color: #f5f5f500;
}

.text-neutral-100\/10 {
  color: #f5f5f51a;
}

.text-neutral-100\/100 {
  color: #f5f5f5;
}

.text-neutral-100\/20 {
  color: #f5f5f533;
}

.text-neutral-100\/25 {
  color: #f5f5f540;
}

.text-neutral-100\/30 {
  color: #f5f5f54d;
}

.text-neutral-100\/40 {
  color: #f5f5f566;
}

.text-neutral-100\/5 {
  color: #f5f5f50d;
}

.text-neutral-100\/50 {
  color: #f5f5f580;
}

.text-neutral-100\/60 {
  color: #f5f5f599;
}

.text-neutral-100\/70 {
  color: #f5f5f5b3;
}

.text-neutral-100\/75 {
  color: #f5f5f5bf;
}

.text-neutral-100\/80 {
  color: #f5f5f5cc;
}

.text-neutral-100\/90 {
  color: #f5f5f5e6;
}

.text-neutral-100\/95 {
  color: #f5f5f5f2;
}

.text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-neutral-200\/0 {
  color: #e5e5e500;
}

.text-neutral-200\/10 {
  color: #e5e5e51a;
}

.text-neutral-200\/100 {
  color: #e5e5e5;
}

.text-neutral-200\/20 {
  color: #e5e5e533;
}

.text-neutral-200\/25 {
  color: #e5e5e540;
}

.text-neutral-200\/30 {
  color: #e5e5e54d;
}

.text-neutral-200\/40 {
  color: #e5e5e566;
}

.text-neutral-200\/5 {
  color: #e5e5e50d;
}

.text-neutral-200\/50 {
  color: #e5e5e580;
}

.text-neutral-200\/60 {
  color: #e5e5e599;
}

.text-neutral-200\/70 {
  color: #e5e5e5b3;
}

.text-neutral-200\/75 {
  color: #e5e5e5bf;
}

.text-neutral-200\/80 {
  color: #e5e5e5cc;
}

.text-neutral-200\/90 {
  color: #e5e5e5e6;
}

.text-neutral-200\/95 {
  color: #e5e5e5f2;
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.text-neutral-300\/0 {
  color: #d4d4d400;
}

.text-neutral-300\/10 {
  color: #d4d4d41a;
}

.text-neutral-300\/100 {
  color: #d4d4d4;
}

.text-neutral-300\/20 {
  color: #d4d4d433;
}

.text-neutral-300\/25 {
  color: #d4d4d440;
}

.text-neutral-300\/30 {
  color: #d4d4d44d;
}

.text-neutral-300\/40 {
  color: #d4d4d466;
}

.text-neutral-300\/5 {
  color: #d4d4d40d;
}

.text-neutral-300\/50 {
  color: #d4d4d480;
}

.text-neutral-300\/60 {
  color: #d4d4d499;
}

.text-neutral-300\/70 {
  color: #d4d4d4b3;
}

.text-neutral-300\/75 {
  color: #d4d4d4bf;
}

.text-neutral-300\/80 {
  color: #d4d4d4cc;
}

.text-neutral-300\/90 {
  color: #d4d4d4e6;
}

.text-neutral-300\/95 {
  color: #d4d4d4f2;
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-neutral-400\/0 {
  color: #a3a3a300;
}

.text-neutral-400\/10 {
  color: #a3a3a31a;
}

.text-neutral-400\/100 {
  color: #a3a3a3;
}

.text-neutral-400\/20 {
  color: #a3a3a333;
}

.text-neutral-400\/25 {
  color: #a3a3a340;
}

.text-neutral-400\/30 {
  color: #a3a3a34d;
}

.text-neutral-400\/40 {
  color: #a3a3a366;
}

.text-neutral-400\/5 {
  color: #a3a3a30d;
}

.text-neutral-400\/50 {
  color: #a3a3a380;
}

.text-neutral-400\/60 {
  color: #a3a3a399;
}

.text-neutral-400\/70 {
  color: #a3a3a3b3;
}

.text-neutral-400\/75 {
  color: #a3a3a3bf;
}

.text-neutral-400\/80 {
  color: #a3a3a3cc;
}

.text-neutral-400\/90 {
  color: #a3a3a3e6;
}

.text-neutral-400\/95 {
  color: #a3a3a3f2;
}

.text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-neutral-50\/0 {
  color: #fafafa00;
}

.text-neutral-50\/10 {
  color: #fafafa1a;
}

.text-neutral-50\/100 {
  color: #fafafa;
}

.text-neutral-50\/20 {
  color: #fafafa33;
}

.text-neutral-50\/25 {
  color: #fafafa40;
}

.text-neutral-50\/30 {
  color: #fafafa4d;
}

.text-neutral-50\/40 {
  color: #fafafa66;
}

.text-neutral-50\/5 {
  color: #fafafa0d;
}

.text-neutral-50\/50 {
  color: #fafafa80;
}

.text-neutral-50\/60 {
  color: #fafafa99;
}

.text-neutral-50\/70 {
  color: #fafafab3;
}

.text-neutral-50\/75 {
  color: #fafafabf;
}

.text-neutral-50\/80 {
  color: #fafafacc;
}

.text-neutral-50\/90 {
  color: #fafafae6;
}

.text-neutral-50\/95 {
  color: #fafafaf2;
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-500\/0 {
  color: #73737300;
}

.text-neutral-500\/10 {
  color: #7373731a;
}

.text-neutral-500\/100 {
  color: #737373;
}

.text-neutral-500\/20 {
  color: #73737333;
}

.text-neutral-500\/25 {
  color: #73737340;
}

.text-neutral-500\/30 {
  color: #7373734d;
}

.text-neutral-500\/40 {
  color: #73737366;
}

.text-neutral-500\/5 {
  color: #7373730d;
}

.text-neutral-500\/50 {
  color: #73737380;
}

.text-neutral-500\/60 {
  color: #73737399;
}

.text-neutral-500\/70 {
  color: #737373b3;
}

.text-neutral-500\/75 {
  color: #737373bf;
}

.text-neutral-500\/80 {
  color: #737373cc;
}

.text-neutral-500\/90 {
  color: #737373e6;
}

.text-neutral-500\/95 {
  color: #737373f2;
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-600\/0 {
  color: #52525200;
}

.text-neutral-600\/10 {
  color: #5252521a;
}

.text-neutral-600\/100 {
  color: #525252;
}

.text-neutral-600\/20 {
  color: #52525233;
}

.text-neutral-600\/25 {
  color: #52525240;
}

.text-neutral-600\/30 {
  color: #5252524d;
}

.text-neutral-600\/40 {
  color: #52525266;
}

.text-neutral-600\/5 {
  color: #5252520d;
}

.text-neutral-600\/50 {
  color: #52525280;
}

.text-neutral-600\/60 {
  color: #52525299;
}

.text-neutral-600\/70 {
  color: #525252b3;
}

.text-neutral-600\/75 {
  color: #525252bf;
}

.text-neutral-600\/80 {
  color: #525252cc;
}

.text-neutral-600\/90 {
  color: #525252e6;
}

.text-neutral-600\/95 {
  color: #525252f2;
}

.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.text-neutral-700\/0 {
  color: #40404000;
}

.text-neutral-700\/10 {
  color: #4040401a;
}

.text-neutral-700\/100 {
  color: #404040;
}

.text-neutral-700\/20 {
  color: #40404033;
}

.text-neutral-700\/25 {
  color: #40404040;
}

.text-neutral-700\/30 {
  color: #4040404d;
}

.text-neutral-700\/40 {
  color: #40404066;
}

.text-neutral-700\/5 {
  color: #4040400d;
}

.text-neutral-700\/50 {
  color: #40404080;
}

.text-neutral-700\/60 {
  color: #40404099;
}

.text-neutral-700\/70 {
  color: #404040b3;
}

.text-neutral-700\/75 {
  color: #404040bf;
}

.text-neutral-700\/80 {
  color: #404040cc;
}

.text-neutral-700\/90 {
  color: #404040e6;
}

.text-neutral-700\/95 {
  color: #404040f2;
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-neutral-800\/0 {
  color: #26262600;
}

.text-neutral-800\/10 {
  color: #2626261a;
}

.text-neutral-800\/100 {
  color: #262626;
}

.text-neutral-800\/20 {
  color: #26262633;
}

.text-neutral-800\/25 {
  color: #26262640;
}

.text-neutral-800\/30 {
  color: #2626264d;
}

.text-neutral-800\/40 {
  color: #26262666;
}

.text-neutral-800\/5 {
  color: #2626260d;
}

.text-neutral-800\/50 {
  color: #26262680;
}

.text-neutral-800\/60 {
  color: #26262699;
}

.text-neutral-800\/70 {
  color: #262626b3;
}

.text-neutral-800\/75 {
  color: #262626bf;
}

.text-neutral-800\/80 {
  color: #262626cc;
}

.text-neutral-800\/90 {
  color: #262626e6;
}

.text-neutral-800\/95 {
  color: #262626f2;
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-neutral-900\/0 {
  color: #17171700;
}

.text-neutral-900\/10 {
  color: #1717171a;
}

.text-neutral-900\/100 {
  color: #171717;
}

.text-neutral-900\/20 {
  color: #17171733;
}

.text-neutral-900\/25 {
  color: #17171740;
}

.text-neutral-900\/30 {
  color: #1717174d;
}

.text-neutral-900\/40 {
  color: #17171766;
}

.text-neutral-900\/5 {
  color: #1717170d;
}

.text-neutral-900\/50 {
  color: #17171780;
}

.text-neutral-900\/60 {
  color: #17171799;
}

.text-neutral-900\/70 {
  color: #171717b3;
}

.text-neutral-900\/75 {
  color: #171717bf;
}

.text-neutral-900\/80 {
  color: #171717cc;
}

.text-neutral-900\/90 {
  color: #171717e6;
}

.text-neutral-900\/95 {
  color: #171717f2;
}

.text-neutral-950 {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.text-neutral-950\/0 {
  color: #0a0a0a00;
}

.text-neutral-950\/10 {
  color: #0a0a0a1a;
}

.text-neutral-950\/100 {
  color: #0a0a0a;
}

.text-neutral-950\/20 {
  color: #0a0a0a33;
}

.text-neutral-950\/25 {
  color: #0a0a0a40;
}

.text-neutral-950\/30 {
  color: #0a0a0a4d;
}

.text-neutral-950\/40 {
  color: #0a0a0a66;
}

.text-neutral-950\/5 {
  color: #0a0a0a0d;
}

.text-neutral-950\/50 {
  color: #0a0a0a80;
}

.text-neutral-950\/60 {
  color: #0a0a0a99;
}

.text-neutral-950\/70 {
  color: #0a0a0ab3;
}

.text-neutral-950\/75 {
  color: #0a0a0abf;
}

.text-neutral-950\/80 {
  color: #0a0a0acc;
}

.text-neutral-950\/90 {
  color: #0a0a0ae6;
}

.text-neutral-950\/95 {
  color: #0a0a0af2;
}

.text-orange-100 {
  --tw-text-opacity: 1;
  color: rgb(255 237 213 / var(--tw-text-opacity));
}

.text-orange-100\/0 {
  color: #ffedd500;
}

.text-orange-100\/10 {
  color: #ffedd51a;
}

.text-orange-100\/100 {
  color: #ffedd5;
}

.text-orange-100\/20 {
  color: #ffedd533;
}

.text-orange-100\/25 {
  color: #ffedd540;
}

.text-orange-100\/30 {
  color: #ffedd54d;
}

.text-orange-100\/40 {
  color: #ffedd566;
}

.text-orange-100\/5 {
  color: #ffedd50d;
}

.text-orange-100\/50 {
  color: #ffedd580;
}

.text-orange-100\/60 {
  color: #ffedd599;
}

.text-orange-100\/70 {
  color: #ffedd5b3;
}

.text-orange-100\/75 {
  color: #ffedd5bf;
}

.text-orange-100\/80 {
  color: #ffedd5cc;
}

.text-orange-100\/90 {
  color: #ffedd5e6;
}

.text-orange-100\/95 {
  color: #ffedd5f2;
}

.text-orange-200 {
  --tw-text-opacity: 1;
  color: rgb(254 215 170 / var(--tw-text-opacity));
}

.text-orange-200\/0 {
  color: #fed7aa00;
}

.text-orange-200\/10 {
  color: #fed7aa1a;
}

.text-orange-200\/100 {
  color: #fed7aa;
}

.text-orange-200\/20 {
  color: #fed7aa33;
}

.text-orange-200\/25 {
  color: #fed7aa40;
}

.text-orange-200\/30 {
  color: #fed7aa4d;
}

.text-orange-200\/40 {
  color: #fed7aa66;
}

.text-orange-200\/5 {
  color: #fed7aa0d;
}

.text-orange-200\/50 {
  color: #fed7aa80;
}

.text-orange-200\/60 {
  color: #fed7aa99;
}

.text-orange-200\/70 {
  color: #fed7aab3;
}

.text-orange-200\/75 {
  color: #fed7aabf;
}

.text-orange-200\/80 {
  color: #fed7aacc;
}

.text-orange-200\/90 {
  color: #fed7aae6;
}

.text-orange-200\/95 {
  color: #fed7aaf2;
}

.text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.text-orange-300\/0 {
  color: #fdba7400;
}

.text-orange-300\/10 {
  color: #fdba741a;
}

.text-orange-300\/100 {
  color: #fdba74;
}

.text-orange-300\/20 {
  color: #fdba7433;
}

.text-orange-300\/25 {
  color: #fdba7440;
}

.text-orange-300\/30 {
  color: #fdba744d;
}

.text-orange-300\/40 {
  color: #fdba7466;
}

.text-orange-300\/5 {
  color: #fdba740d;
}

.text-orange-300\/50 {
  color: #fdba7480;
}

.text-orange-300\/60 {
  color: #fdba7499;
}

.text-orange-300\/70 {
  color: #fdba74b3;
}

.text-orange-300\/75 {
  color: #fdba74bf;
}

.text-orange-300\/80 {
  color: #fdba74cc;
}

.text-orange-300\/90 {
  color: #fdba74e6;
}

.text-orange-300\/95 {
  color: #fdba74f2;
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-orange-400\/0 {
  color: #fb923c00;
}

.text-orange-400\/10 {
  color: #fb923c1a;
}

.text-orange-400\/100 {
  color: #fb923c;
}

.text-orange-400\/20 {
  color: #fb923c33;
}

.text-orange-400\/25 {
  color: #fb923c40;
}

.text-orange-400\/30 {
  color: #fb923c4d;
}

.text-orange-400\/40 {
  color: #fb923c66;
}

.text-orange-400\/5 {
  color: #fb923c0d;
}

.text-orange-400\/50 {
  color: #fb923c80;
}

.text-orange-400\/60 {
  color: #fb923c99;
}

.text-orange-400\/70 {
  color: #fb923cb3;
}

.text-orange-400\/75 {
  color: #fb923cbf;
}

.text-orange-400\/80 {
  color: #fb923ccc;
}

.text-orange-400\/90 {
  color: #fb923ce6;
}

.text-orange-400\/95 {
  color: #fb923cf2;
}

.text-orange-50 {
  --tw-text-opacity: 1;
  color: rgb(255 247 237 / var(--tw-text-opacity));
}

.text-orange-50\/0 {
  color: #fff7ed00;
}

.text-orange-50\/10 {
  color: #fff7ed1a;
}

.text-orange-50\/100 {
  color: #fff7ed;
}

.text-orange-50\/20 {
  color: #fff7ed33;
}

.text-orange-50\/25 {
  color: #fff7ed40;
}

.text-orange-50\/30 {
  color: #fff7ed4d;
}

.text-orange-50\/40 {
  color: #fff7ed66;
}

.text-orange-50\/5 {
  color: #fff7ed0d;
}

.text-orange-50\/50 {
  color: #fff7ed80;
}

.text-orange-50\/60 {
  color: #fff7ed99;
}

.text-orange-50\/70 {
  color: #fff7edb3;
}

.text-orange-50\/75 {
  color: #fff7edbf;
}

.text-orange-50\/80 {
  color: #fff7edcc;
}

.text-orange-50\/90 {
  color: #fff7ede6;
}

.text-orange-50\/95 {
  color: #fff7edf2;
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-orange-500\/0 {
  color: #f9731600;
}

.text-orange-500\/10 {
  color: #f973161a;
}

.text-orange-500\/100 {
  color: #f97316;
}

.text-orange-500\/20 {
  color: #f9731633;
}

.text-orange-500\/25 {
  color: #f9731640;
}

.text-orange-500\/30 {
  color: #f973164d;
}

.text-orange-500\/40 {
  color: #f9731666;
}

.text-orange-500\/5 {
  color: #f973160d;
}

.text-orange-500\/50 {
  color: #f9731680;
}

.text-orange-500\/60 {
  color: #f9731699;
}

.text-orange-500\/70 {
  color: #f97316b3;
}

.text-orange-500\/75 {
  color: #f97316bf;
}

.text-orange-500\/80 {
  color: #f97316cc;
}

.text-orange-500\/90 {
  color: #f97316e6;
}

.text-orange-500\/95 {
  color: #f97316f2;
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-orange-600\/0 {
  color: #ea580c00;
}

.text-orange-600\/10 {
  color: #ea580c1a;
}

.text-orange-600\/100 {
  color: #ea580c;
}

.text-orange-600\/20 {
  color: #ea580c33;
}

.text-orange-600\/25 {
  color: #ea580c40;
}

.text-orange-600\/30 {
  color: #ea580c4d;
}

.text-orange-600\/40 {
  color: #ea580c66;
}

.text-orange-600\/5 {
  color: #ea580c0d;
}

.text-orange-600\/50 {
  color: #ea580c80;
}

.text-orange-600\/60 {
  color: #ea580c99;
}

.text-orange-600\/70 {
  color: #ea580cb3;
}

.text-orange-600\/75 {
  color: #ea580cbf;
}

.text-orange-600\/80 {
  color: #ea580ccc;
}

.text-orange-600\/90 {
  color: #ea580ce6;
}

.text-orange-600\/95 {
  color: #ea580cf2;
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.text-orange-700\/0 {
  color: #c2410c00;
}

.text-orange-700\/10 {
  color: #c2410c1a;
}

.text-orange-700\/100 {
  color: #c2410c;
}

.text-orange-700\/20 {
  color: #c2410c33;
}

.text-orange-700\/25 {
  color: #c2410c40;
}

.text-orange-700\/30 {
  color: #c2410c4d;
}

.text-orange-700\/40 {
  color: #c2410c66;
}

.text-orange-700\/5 {
  color: #c2410c0d;
}

.text-orange-700\/50 {
  color: #c2410c80;
}

.text-orange-700\/60 {
  color: #c2410c99;
}

.text-orange-700\/70 {
  color: #c2410cb3;
}

.text-orange-700\/75 {
  color: #c2410cbf;
}

.text-orange-700\/80 {
  color: #c2410ccc;
}

.text-orange-700\/90 {
  color: #c2410ce6;
}

.text-orange-700\/95 {
  color: #c2410cf2;
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

.text-orange-800\/0 {
  color: #9a341200;
}

.text-orange-800\/10 {
  color: #9a34121a;
}

.text-orange-800\/100 {
  color: #9a3412;
}

.text-orange-800\/20 {
  color: #9a341233;
}

.text-orange-800\/25 {
  color: #9a341240;
}

.text-orange-800\/30 {
  color: #9a34124d;
}

.text-orange-800\/40 {
  color: #9a341266;
}

.text-orange-800\/5 {
  color: #9a34120d;
}

.text-orange-800\/50 {
  color: #9a341280;
}

.text-orange-800\/60 {
  color: #9a341299;
}

.text-orange-800\/70 {
  color: #9a3412b3;
}

.text-orange-800\/75 {
  color: #9a3412bf;
}

.text-orange-800\/80 {
  color: #9a3412cc;
}

.text-orange-800\/90 {
  color: #9a3412e6;
}

.text-orange-800\/95 {
  color: #9a3412f2;
}

.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}

.text-orange-900\/0 {
  color: #7c2d1200;
}

.text-orange-900\/10 {
  color: #7c2d121a;
}

.text-orange-900\/100 {
  color: #7c2d12;
}

.text-orange-900\/20 {
  color: #7c2d1233;
}

.text-orange-900\/25 {
  color: #7c2d1240;
}

.text-orange-900\/30 {
  color: #7c2d124d;
}

.text-orange-900\/40 {
  color: #7c2d1266;
}

.text-orange-900\/5 {
  color: #7c2d120d;
}

.text-orange-900\/50 {
  color: #7c2d1280;
}

.text-orange-900\/60 {
  color: #7c2d1299;
}

.text-orange-900\/70 {
  color: #7c2d12b3;
}

.text-orange-900\/75 {
  color: #7c2d12bf;
}

.text-orange-900\/80 {
  color: #7c2d12cc;
}

.text-orange-900\/90 {
  color: #7c2d12e6;
}

.text-orange-900\/95 {
  color: #7c2d12f2;
}

.text-orange-950 {
  --tw-text-opacity: 1;
  color: rgb(67 20 7 / var(--tw-text-opacity));
}

.text-orange-950\/0 {
  color: #43140700;
}

.text-orange-950\/10 {
  color: #4314071a;
}

.text-orange-950\/100 {
  color: #431407;
}

.text-orange-950\/20 {
  color: #43140733;
}

.text-orange-950\/25 {
  color: #43140740;
}

.text-orange-950\/30 {
  color: #4314074d;
}

.text-orange-950\/40 {
  color: #43140766;
}

.text-orange-950\/5 {
  color: #4314070d;
}

.text-orange-950\/50 {
  color: #43140780;
}

.text-orange-950\/60 {
  color: #43140799;
}

.text-orange-950\/70 {
  color: #431407b3;
}

.text-orange-950\/75 {
  color: #431407bf;
}

.text-orange-950\/80 {
  color: #431407cc;
}

.text-orange-950\/90 {
  color: #431407e6;
}

.text-orange-950\/95 {
  color: #431407f2;
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

.text-pink-100\/0 {
  color: #fce7f300;
}

.text-pink-100\/10 {
  color: #fce7f31a;
}

.text-pink-100\/100 {
  color: #fce7f3;
}

.text-pink-100\/20 {
  color: #fce7f333;
}

.text-pink-100\/25 {
  color: #fce7f340;
}

.text-pink-100\/30 {
  color: #fce7f34d;
}

.text-pink-100\/40 {
  color: #fce7f366;
}

.text-pink-100\/5 {
  color: #fce7f30d;
}

.text-pink-100\/50 {
  color: #fce7f380;
}

.text-pink-100\/60 {
  color: #fce7f399;
}

.text-pink-100\/70 {
  color: #fce7f3b3;
}

.text-pink-100\/75 {
  color: #fce7f3bf;
}

.text-pink-100\/80 {
  color: #fce7f3cc;
}

.text-pink-100\/90 {
  color: #fce7f3e6;
}

.text-pink-100\/95 {
  color: #fce7f3f2;
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

.text-pink-200\/0 {
  color: #fbcfe800;
}

.text-pink-200\/10 {
  color: #fbcfe81a;
}

.text-pink-200\/100 {
  color: #fbcfe8;
}

.text-pink-200\/20 {
  color: #fbcfe833;
}

.text-pink-200\/25 {
  color: #fbcfe840;
}

.text-pink-200\/30 {
  color: #fbcfe84d;
}

.text-pink-200\/40 {
  color: #fbcfe866;
}

.text-pink-200\/5 {
  color: #fbcfe80d;
}

.text-pink-200\/50 {
  color: #fbcfe880;
}

.text-pink-200\/60 {
  color: #fbcfe899;
}

.text-pink-200\/70 {
  color: #fbcfe8b3;
}

.text-pink-200\/75 {
  color: #fbcfe8bf;
}

.text-pink-200\/80 {
  color: #fbcfe8cc;
}

.text-pink-200\/90 {
  color: #fbcfe8e6;
}

.text-pink-200\/95 {
  color: #fbcfe8f2;
}

.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgb(249 168 212 / var(--tw-text-opacity));
}

.text-pink-300\/0 {
  color: #f9a8d400;
}

.text-pink-300\/10 {
  color: #f9a8d41a;
}

.text-pink-300\/100 {
  color: #f9a8d4;
}

.text-pink-300\/20 {
  color: #f9a8d433;
}

.text-pink-300\/25 {
  color: #f9a8d440;
}

.text-pink-300\/30 {
  color: #f9a8d44d;
}

.text-pink-300\/40 {
  color: #f9a8d466;
}

.text-pink-300\/5 {
  color: #f9a8d40d;
}

.text-pink-300\/50 {
  color: #f9a8d480;
}

.text-pink-300\/60 {
  color: #f9a8d499;
}

.text-pink-300\/70 {
  color: #f9a8d4b3;
}

.text-pink-300\/75 {
  color: #f9a8d4bf;
}

.text-pink-300\/80 {
  color: #f9a8d4cc;
}

.text-pink-300\/90 {
  color: #f9a8d4e6;
}

.text-pink-300\/95 {
  color: #f9a8d4f2;
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

.text-pink-400\/0 {
  color: #f472b600;
}

.text-pink-400\/10 {
  color: #f472b61a;
}

.text-pink-400\/100 {
  color: #f472b6;
}

.text-pink-400\/20 {
  color: #f472b633;
}

.text-pink-400\/25 {
  color: #f472b640;
}

.text-pink-400\/30 {
  color: #f472b64d;
}

.text-pink-400\/40 {
  color: #f472b666;
}

.text-pink-400\/5 {
  color: #f472b60d;
}

.text-pink-400\/50 {
  color: #f472b680;
}

.text-pink-400\/60 {
  color: #f472b699;
}

.text-pink-400\/70 {
  color: #f472b6b3;
}

.text-pink-400\/75 {
  color: #f472b6bf;
}

.text-pink-400\/80 {
  color: #f472b6cc;
}

.text-pink-400\/90 {
  color: #f472b6e6;
}

.text-pink-400\/95 {
  color: #f472b6f2;
}

.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity));
}

.text-pink-50\/0 {
  color: #fdf2f800;
}

.text-pink-50\/10 {
  color: #fdf2f81a;
}

.text-pink-50\/100 {
  color: #fdf2f8;
}

.text-pink-50\/20 {
  color: #fdf2f833;
}

.text-pink-50\/25 {
  color: #fdf2f840;
}

.text-pink-50\/30 {
  color: #fdf2f84d;
}

.text-pink-50\/40 {
  color: #fdf2f866;
}

.text-pink-50\/5 {
  color: #fdf2f80d;
}

.text-pink-50\/50 {
  color: #fdf2f880;
}

.text-pink-50\/60 {
  color: #fdf2f899;
}

.text-pink-50\/70 {
  color: #fdf2f8b3;
}

.text-pink-50\/75 {
  color: #fdf2f8bf;
}

.text-pink-50\/80 {
  color: #fdf2f8cc;
}

.text-pink-50\/90 {
  color: #fdf2f8e6;
}

.text-pink-50\/95 {
  color: #fdf2f8f2;
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-pink-500\/0 {
  color: #ec489900;
}

.text-pink-500\/10 {
  color: #ec48991a;
}

.text-pink-500\/100 {
  color: #ec4899;
}

.text-pink-500\/20 {
  color: #ec489933;
}

.text-pink-500\/25 {
  color: #ec489940;
}

.text-pink-500\/30 {
  color: #ec48994d;
}

.text-pink-500\/40 {
  color: #ec489966;
}

.text-pink-500\/5 {
  color: #ec48990d;
}

.text-pink-500\/50 {
  color: #ec489980;
}

.text-pink-500\/60 {
  color: #ec489999;
}

.text-pink-500\/70 {
  color: #ec4899b3;
}

.text-pink-500\/75 {
  color: #ec4899bf;
}

.text-pink-500\/80 {
  color: #ec4899cc;
}

.text-pink-500\/90 {
  color: #ec4899e6;
}

.text-pink-500\/95 {
  color: #ec4899f2;
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.text-pink-600\/0 {
  color: #db277700;
}

.text-pink-600\/10 {
  color: #db27771a;
}

.text-pink-600\/100 {
  color: #db2777;
}

.text-pink-600\/20 {
  color: #db277733;
}

.text-pink-600\/25 {
  color: #db277740;
}

.text-pink-600\/30 {
  color: #db27774d;
}

.text-pink-600\/40 {
  color: #db277766;
}

.text-pink-600\/5 {
  color: #db27770d;
}

.text-pink-600\/50 {
  color: #db277780;
}

.text-pink-600\/60 {
  color: #db277799;
}

.text-pink-600\/70 {
  color: #db2777b3;
}

.text-pink-600\/75 {
  color: #db2777bf;
}

.text-pink-600\/80 {
  color: #db2777cc;
}

.text-pink-600\/90 {
  color: #db2777e6;
}

.text-pink-600\/95 {
  color: #db2777f2;
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.text-pink-700\/0 {
  color: #be185d00;
}

.text-pink-700\/10 {
  color: #be185d1a;
}

.text-pink-700\/100 {
  color: #be185d;
}

.text-pink-700\/20 {
  color: #be185d33;
}

.text-pink-700\/25 {
  color: #be185d40;
}

.text-pink-700\/30 {
  color: #be185d4d;
}

.text-pink-700\/40 {
  color: #be185d66;
}

.text-pink-700\/5 {
  color: #be185d0d;
}

.text-pink-700\/50 {
  color: #be185d80;
}

.text-pink-700\/60 {
  color: #be185d99;
}

.text-pink-700\/70 {
  color: #be185db3;
}

.text-pink-700\/75 {
  color: #be185dbf;
}

.text-pink-700\/80 {
  color: #be185dcc;
}

.text-pink-700\/90 {
  color: #be185de6;
}

.text-pink-700\/95 {
  color: #be185df2;
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.text-pink-800\/0 {
  color: #9d174d00;
}

.text-pink-800\/10 {
  color: #9d174d1a;
}

.text-pink-800\/100 {
  color: #9d174d;
}

.text-pink-800\/20 {
  color: #9d174d33;
}

.text-pink-800\/25 {
  color: #9d174d40;
}

.text-pink-800\/30 {
  color: #9d174d4d;
}

.text-pink-800\/40 {
  color: #9d174d66;
}

.text-pink-800\/5 {
  color: #9d174d0d;
}

.text-pink-800\/50 {
  color: #9d174d80;
}

.text-pink-800\/60 {
  color: #9d174d99;
}

.text-pink-800\/70 {
  color: #9d174db3;
}

.text-pink-800\/75 {
  color: #9d174dbf;
}

.text-pink-800\/80 {
  color: #9d174dcc;
}

.text-pink-800\/90 {
  color: #9d174de6;
}

.text-pink-800\/95 {
  color: #9d174df2;
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

.text-pink-900\/0 {
  color: #83184300;
}

.text-pink-900\/10 {
  color: #8318431a;
}

.text-pink-900\/100 {
  color: #831843;
}

.text-pink-900\/20 {
  color: #83184333;
}

.text-pink-900\/25 {
  color: #83184340;
}

.text-pink-900\/30 {
  color: #8318434d;
}

.text-pink-900\/40 {
  color: #83184366;
}

.text-pink-900\/5 {
  color: #8318430d;
}

.text-pink-900\/50 {
  color: #83184380;
}

.text-pink-900\/60 {
  color: #83184399;
}

.text-pink-900\/70 {
  color: #831843b3;
}

.text-pink-900\/75 {
  color: #831843bf;
}

.text-pink-900\/80 {
  color: #831843cc;
}

.text-pink-900\/90 {
  color: #831843e6;
}

.text-pink-900\/95 {
  color: #831843f2;
}

.text-pink-950 {
  --tw-text-opacity: 1;
  color: rgb(80 7 36 / var(--tw-text-opacity));
}

.text-pink-950\/0 {
  color: #50072400;
}

.text-pink-950\/10 {
  color: #5007241a;
}

.text-pink-950\/100 {
  color: #500724;
}

.text-pink-950\/20 {
  color: #50072433;
}

.text-pink-950\/25 {
  color: #50072440;
}

.text-pink-950\/30 {
  color: #5007244d;
}

.text-pink-950\/40 {
  color: #50072466;
}

.text-pink-950\/5 {
  color: #5007240d;
}

.text-pink-950\/50 {
  color: #50072480;
}

.text-pink-950\/60 {
  color: #50072499;
}

.text-pink-950\/70 {
  color: #500724b3;
}

.text-pink-950\/75 {
  color: #500724bf;
}

.text-pink-950\/80 {
  color: #500724cc;
}

.text-pink-950\/90 {
  color: #500724e6;
}

.text-pink-950\/95 {
  color: #500724f2;
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

.text-purple-100\/0 {
  color: #f3e8ff00;
}

.text-purple-100\/10 {
  color: #f3e8ff1a;
}

.text-purple-100\/100 {
  color: #f3e8ff;
}

.text-purple-100\/20 {
  color: #f3e8ff33;
}

.text-purple-100\/25 {
  color: #f3e8ff40;
}

.text-purple-100\/30 {
  color: #f3e8ff4d;
}

.text-purple-100\/40 {
  color: #f3e8ff66;
}

.text-purple-100\/5 {
  color: #f3e8ff0d;
}

.text-purple-100\/50 {
  color: #f3e8ff80;
}

.text-purple-100\/60 {
  color: #f3e8ff99;
}

.text-purple-100\/70 {
  color: #f3e8ffb3;
}

.text-purple-100\/75 {
  color: #f3e8ffbf;
}

.text-purple-100\/80 {
  color: #f3e8ffcc;
}

.text-purple-100\/90 {
  color: #f3e8ffe6;
}

.text-purple-100\/95 {
  color: #f3e8fff2;
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}

.text-purple-200\/0 {
  color: #e9d5ff00;
}

.text-purple-200\/10 {
  color: #e9d5ff1a;
}

.text-purple-200\/100 {
  color: #e9d5ff;
}

.text-purple-200\/20 {
  color: #e9d5ff33;
}

.text-purple-200\/25 {
  color: #e9d5ff40;
}

.text-purple-200\/30 {
  color: #e9d5ff4d;
}

.text-purple-200\/40 {
  color: #e9d5ff66;
}

.text-purple-200\/5 {
  color: #e9d5ff0d;
}

.text-purple-200\/50 {
  color: #e9d5ff80;
}

.text-purple-200\/60 {
  color: #e9d5ff99;
}

.text-purple-200\/70 {
  color: #e9d5ffb3;
}

.text-purple-200\/75 {
  color: #e9d5ffbf;
}

.text-purple-200\/80 {
  color: #e9d5ffcc;
}

.text-purple-200\/90 {
  color: #e9d5ffe6;
}

.text-purple-200\/95 {
  color: #e9d5fff2;
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.text-purple-300\/0 {
  color: #d8b4fe00;
}

.text-purple-300\/10 {
  color: #d8b4fe1a;
}

.text-purple-300\/100 {
  color: #d8b4fe;
}

.text-purple-300\/20 {
  color: #d8b4fe33;
}

.text-purple-300\/25 {
  color: #d8b4fe40;
}

.text-purple-300\/30 {
  color: #d8b4fe4d;
}

.text-purple-300\/40 {
  color: #d8b4fe66;
}

.text-purple-300\/5 {
  color: #d8b4fe0d;
}

.text-purple-300\/50 {
  color: #d8b4fe80;
}

.text-purple-300\/60 {
  color: #d8b4fe99;
}

.text-purple-300\/70 {
  color: #d8b4feb3;
}

.text-purple-300\/75 {
  color: #d8b4febf;
}

.text-purple-300\/80 {
  color: #d8b4fecc;
}

.text-purple-300\/90 {
  color: #d8b4fee6;
}

.text-purple-300\/95 {
  color: #d8b4fef2;
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-purple-400\/0 {
  color: #c084fc00;
}

.text-purple-400\/10 {
  color: #c084fc1a;
}

.text-purple-400\/100 {
  color: #c084fc;
}

.text-purple-400\/20 {
  color: #c084fc33;
}

.text-purple-400\/25 {
  color: #c084fc40;
}

.text-purple-400\/30 {
  color: #c084fc4d;
}

.text-purple-400\/40 {
  color: #c084fc66;
}

.text-purple-400\/5 {
  color: #c084fc0d;
}

.text-purple-400\/50 {
  color: #c084fc80;
}

.text-purple-400\/60 {
  color: #c084fc99;
}

.text-purple-400\/70 {
  color: #c084fcb3;
}

.text-purple-400\/75 {
  color: #c084fcbf;
}

.text-purple-400\/80 {
  color: #c084fccc;
}

.text-purple-400\/90 {
  color: #c084fce6;
}

.text-purple-400\/95 {
  color: #c084fcf2;
}

.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity));
}

.text-purple-50\/0 {
  color: #faf5ff00;
}

.text-purple-50\/10 {
  color: #faf5ff1a;
}

.text-purple-50\/100 {
  color: #faf5ff;
}

.text-purple-50\/20 {
  color: #faf5ff33;
}

.text-purple-50\/25 {
  color: #faf5ff40;
}

.text-purple-50\/30 {
  color: #faf5ff4d;
}

.text-purple-50\/40 {
  color: #faf5ff66;
}

.text-purple-50\/5 {
  color: #faf5ff0d;
}

.text-purple-50\/50 {
  color: #faf5ff80;
}

.text-purple-50\/60 {
  color: #faf5ff99;
}

.text-purple-50\/70 {
  color: #faf5ffb3;
}

.text-purple-50\/75 {
  color: #faf5ffbf;
}

.text-purple-50\/80 {
  color: #faf5ffcc;
}

.text-purple-50\/90 {
  color: #faf5ffe6;
}

.text-purple-50\/95 {
  color: #faf5fff2;
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-purple-500\/0 {
  color: #a855f700;
}

.text-purple-500\/10 {
  color: #a855f71a;
}

.text-purple-500\/100 {
  color: #a855f7;
}

.text-purple-500\/20 {
  color: #a855f733;
}

.text-purple-500\/25 {
  color: #a855f740;
}

.text-purple-500\/30 {
  color: #a855f74d;
}

.text-purple-500\/40 {
  color: #a855f766;
}

.text-purple-500\/5 {
  color: #a855f70d;
}

.text-purple-500\/50 {
  color: #a855f780;
}

.text-purple-500\/60 {
  color: #a855f799;
}

.text-purple-500\/70 {
  color: #a855f7b3;
}

.text-purple-500\/75 {
  color: #a855f7bf;
}

.text-purple-500\/80 {
  color: #a855f7cc;
}

.text-purple-500\/90 {
  color: #a855f7e6;
}

.text-purple-500\/95 {
  color: #a855f7f2;
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.text-purple-600\/0 {
  color: #9333ea00;
}

.text-purple-600\/10 {
  color: #9333ea1a;
}

.text-purple-600\/100 {
  color: #9333ea;
}

.text-purple-600\/20 {
  color: #9333ea33;
}

.text-purple-600\/25 {
  color: #9333ea40;
}

.text-purple-600\/30 {
  color: #9333ea4d;
}

.text-purple-600\/40 {
  color: #9333ea66;
}

.text-purple-600\/5 {
  color: #9333ea0d;
}

.text-purple-600\/50 {
  color: #9333ea80;
}

.text-purple-600\/60 {
  color: #9333ea99;
}

.text-purple-600\/70 {
  color: #9333eab3;
}

.text-purple-600\/75 {
  color: #9333eabf;
}

.text-purple-600\/80 {
  color: #9333eacc;
}

.text-purple-600\/90 {
  color: #9333eae6;
}

.text-purple-600\/95 {
  color: #9333eaf2;
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.text-purple-700\/0 {
  color: #7e22ce00;
}

.text-purple-700\/10 {
  color: #7e22ce1a;
}

.text-purple-700\/100 {
  color: #7e22ce;
}

.text-purple-700\/20 {
  color: #7e22ce33;
}

.text-purple-700\/25 {
  color: #7e22ce40;
}

.text-purple-700\/30 {
  color: #7e22ce4d;
}

.text-purple-700\/40 {
  color: #7e22ce66;
}

.text-purple-700\/5 {
  color: #7e22ce0d;
}

.text-purple-700\/50 {
  color: #7e22ce80;
}

.text-purple-700\/60 {
  color: #7e22ce99;
}

.text-purple-700\/70 {
  color: #7e22ceb3;
}

.text-purple-700\/75 {
  color: #7e22cebf;
}

.text-purple-700\/80 {
  color: #7e22cecc;
}

.text-purple-700\/90 {
  color: #7e22cee6;
}

.text-purple-700\/95 {
  color: #7e22cef2;
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.text-purple-800\/0 {
  color: #6b21a800;
}

.text-purple-800\/10 {
  color: #6b21a81a;
}

.text-purple-800\/100 {
  color: #6b21a8;
}

.text-purple-800\/20 {
  color: #6b21a833;
}

.text-purple-800\/25 {
  color: #6b21a840;
}

.text-purple-800\/30 {
  color: #6b21a84d;
}

.text-purple-800\/40 {
  color: #6b21a866;
}

.text-purple-800\/5 {
  color: #6b21a80d;
}

.text-purple-800\/50 {
  color: #6b21a880;
}

.text-purple-800\/60 {
  color: #6b21a899;
}

.text-purple-800\/70 {
  color: #6b21a8b3;
}

.text-purple-800\/75 {
  color: #6b21a8bf;
}

.text-purple-800\/80 {
  color: #6b21a8cc;
}

.text-purple-800\/90 {
  color: #6b21a8e6;
}

.text-purple-800\/95 {
  color: #6b21a8f2;
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

.text-purple-900\/0 {
  color: #581c8700;
}

.text-purple-900\/10 {
  color: #581c871a;
}

.text-purple-900\/100 {
  color: #581c87;
}

.text-purple-900\/20 {
  color: #581c8733;
}

.text-purple-900\/25 {
  color: #581c8740;
}

.text-purple-900\/30 {
  color: #581c874d;
}

.text-purple-900\/40 {
  color: #581c8766;
}

.text-purple-900\/5 {
  color: #581c870d;
}

.text-purple-900\/50 {
  color: #581c8780;
}

.text-purple-900\/60 {
  color: #581c8799;
}

.text-purple-900\/70 {
  color: #581c87b3;
}

.text-purple-900\/75 {
  color: #581c87bf;
}

.text-purple-900\/80 {
  color: #581c87cc;
}

.text-purple-900\/90 {
  color: #581c87e6;
}

.text-purple-900\/95 {
  color: #581c87f2;
}

.text-purple-950 {
  --tw-text-opacity: 1;
  color: rgb(59 7 100 / var(--tw-text-opacity));
}

.text-purple-950\/0 {
  color: #3b076400;
}

.text-purple-950\/10 {
  color: #3b07641a;
}

.text-purple-950\/100 {
  color: #3b0764;
}

.text-purple-950\/20 {
  color: #3b076433;
}

.text-purple-950\/25 {
  color: #3b076440;
}

.text-purple-950\/30 {
  color: #3b07644d;
}

.text-purple-950\/40 {
  color: #3b076466;
}

.text-purple-950\/5 {
  color: #3b07640d;
}

.text-purple-950\/50 {
  color: #3b076480;
}

.text-purple-950\/60 {
  color: #3b076499;
}

.text-purple-950\/70 {
  color: #3b0764b3;
}

.text-purple-950\/75 {
  color: #3b0764bf;
}

.text-purple-950\/80 {
  color: #3b0764cc;
}

.text-purple-950\/90 {
  color: #3b0764e6;
}

.text-purple-950\/95 {
  color: #3b0764f2;
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.text-red-100\/0 {
  color: #fee2e200;
}

.text-red-100\/10 {
  color: #fee2e21a;
}

.text-red-100\/100 {
  color: #fee2e2;
}

.text-red-100\/20 {
  color: #fee2e233;
}

.text-red-100\/25 {
  color: #fee2e240;
}

.text-red-100\/30 {
  color: #fee2e24d;
}

.text-red-100\/40 {
  color: #fee2e266;
}

.text-red-100\/5 {
  color: #fee2e20d;
}

.text-red-100\/50 {
  color: #fee2e280;
}

.text-red-100\/60 {
  color: #fee2e299;
}

.text-red-100\/70 {
  color: #fee2e2b3;
}

.text-red-100\/75 {
  color: #fee2e2bf;
}

.text-red-100\/80 {
  color: #fee2e2cc;
}

.text-red-100\/90 {
  color: #fee2e2e6;
}

.text-red-100\/95 {
  color: #fee2e2f2;
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.text-red-200\/0 {
  color: #fecaca00;
}

.text-red-200\/10 {
  color: #fecaca1a;
}

.text-red-200\/100 {
  color: #fecaca;
}

.text-red-200\/20 {
  color: #fecaca33;
}

.text-red-200\/25 {
  color: #fecaca40;
}

.text-red-200\/30 {
  color: #fecaca4d;
}

.text-red-200\/40 {
  color: #fecaca66;
}

.text-red-200\/5 {
  color: #fecaca0d;
}

.text-red-200\/50 {
  color: #fecaca80;
}

.text-red-200\/60 {
  color: #fecaca99;
}

.text-red-200\/70 {
  color: #fecacab3;
}

.text-red-200\/75 {
  color: #fecacabf;
}

.text-red-200\/80 {
  color: #fecacacc;
}

.text-red-200\/90 {
  color: #fecacae6;
}

.text-red-200\/95 {
  color: #fecacaf2;
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.text-red-300\/0 {
  color: #fca5a500;
}

.text-red-300\/10 {
  color: #fca5a51a;
}

.text-red-300\/100 {
  color: #fca5a5;
}

.text-red-300\/20 {
  color: #fca5a533;
}

.text-red-300\/25 {
  color: #fca5a540;
}

.text-red-300\/30 {
  color: #fca5a54d;
}

.text-red-300\/40 {
  color: #fca5a566;
}

.text-red-300\/5 {
  color: #fca5a50d;
}

.text-red-300\/50 {
  color: #fca5a580;
}

.text-red-300\/60 {
  color: #fca5a599;
}

.text-red-300\/70 {
  color: #fca5a5b3;
}

.text-red-300\/75 {
  color: #fca5a5bf;
}

.text-red-300\/80 {
  color: #fca5a5cc;
}

.text-red-300\/90 {
  color: #fca5a5e6;
}

.text-red-300\/95 {
  color: #fca5a5f2;
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-400\/0 {
  color: #f8717100;
}

.text-red-400\/10 {
  color: #f871711a;
}

.text-red-400\/100 {
  color: #f87171;
}

.text-red-400\/20 {
  color: #f8717133;
}

.text-red-400\/25 {
  color: #f8717140;
}

.text-red-400\/30 {
  color: #f871714d;
}

.text-red-400\/40 {
  color: #f8717166;
}

.text-red-400\/5 {
  color: #f871710d;
}

.text-red-400\/50 {
  color: #f8717180;
}

.text-red-400\/60 {
  color: #f8717199;
}

.text-red-400\/70 {
  color: #f87171b3;
}

.text-red-400\/75 {
  color: #f87171bf;
}

.text-red-400\/80 {
  color: #f87171cc;
}

.text-red-400\/90 {
  color: #f87171e6;
}

.text-red-400\/95 {
  color: #f87171f2;
}

.text-red-50 {
  --tw-text-opacity: 1;
  color: rgb(254 242 242 / var(--tw-text-opacity));
}

.text-red-50\/0 {
  color: #fef2f200;
}

.text-red-50\/10 {
  color: #fef2f21a;
}

.text-red-50\/100 {
  color: #fef2f2;
}

.text-red-50\/20 {
  color: #fef2f233;
}

.text-red-50\/25 {
  color: #fef2f240;
}

.text-red-50\/30 {
  color: #fef2f24d;
}

.text-red-50\/40 {
  color: #fef2f266;
}

.text-red-50\/5 {
  color: #fef2f20d;
}

.text-red-50\/50 {
  color: #fef2f280;
}

.text-red-50\/60 {
  color: #fef2f299;
}

.text-red-50\/70 {
  color: #fef2f2b3;
}

.text-red-50\/75 {
  color: #fef2f2bf;
}

.text-red-50\/80 {
  color: #fef2f2cc;
}

.text-red-50\/90 {
  color: #fef2f2e6;
}

.text-red-50\/95 {
  color: #fef2f2f2;
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-500\/0 {
  color: #ef444400;
}

.text-red-500\/10 {
  color: #ef44441a;
}

.text-red-500\/100 {
  color: #ef4444;
}

.text-red-500\/20 {
  color: #ef444433;
}

.text-red-500\/25 {
  color: #ef444440;
}

.text-red-500\/30 {
  color: #ef44444d;
}

.text-red-500\/40 {
  color: #ef444466;
}

.text-red-500\/5 {
  color: #ef44440d;
}

.text-red-500\/50 {
  color: #ef444480;
}

.text-red-500\/60 {
  color: #ef444499;
}

.text-red-500\/70 {
  color: #ef4444b3;
}

.text-red-500\/75 {
  color: #ef4444bf;
}

.text-red-500\/80 {
  color: #ef4444cc;
}

.text-red-500\/90 {
  color: #ef4444e6;
}

.text-red-500\/95 {
  color: #ef4444f2;
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-600\/0 {
  color: #dc262600;
}

.text-red-600\/10 {
  color: #dc26261a;
}

.text-red-600\/100 {
  color: #dc2626;
}

.text-red-600\/20 {
  color: #dc262633;
}

.text-red-600\/25 {
  color: #dc262640;
}

.text-red-600\/30 {
  color: #dc26264d;
}

.text-red-600\/40 {
  color: #dc262666;
}

.text-red-600\/5 {
  color: #dc26260d;
}

.text-red-600\/50 {
  color: #dc262680;
}

.text-red-600\/60 {
  color: #dc262699;
}

.text-red-600\/70 {
  color: #dc2626b3;
}

.text-red-600\/75 {
  color: #dc2626bf;
}

.text-red-600\/80 {
  color: #dc2626cc;
}

.text-red-600\/90 {
  color: #dc2626e6;
}

.text-red-600\/95 {
  color: #dc2626f2;
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-red-700\/0 {
  color: #b91c1c00;
}

.text-red-700\/10 {
  color: #b91c1c1a;
}

.text-red-700\/100 {
  color: #b91c1c;
}

.text-red-700\/20 {
  color: #b91c1c33;
}

.text-red-700\/25 {
  color: #b91c1c40;
}

.text-red-700\/30 {
  color: #b91c1c4d;
}

.text-red-700\/40 {
  color: #b91c1c66;
}

.text-red-700\/5 {
  color: #b91c1c0d;
}

.text-red-700\/50 {
  color: #b91c1c80;
}

.text-red-700\/60 {
  color: #b91c1c99;
}

.text-red-700\/70 {
  color: #b91c1cb3;
}

.text-red-700\/75 {
  color: #b91c1cbf;
}

.text-red-700\/80 {
  color: #b91c1ccc;
}

.text-red-700\/90 {
  color: #b91c1ce6;
}

.text-red-700\/95 {
  color: #b91c1cf2;
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-red-800\/0 {
  color: #991b1b00;
}

.text-red-800\/10 {
  color: #991b1b1a;
}

.text-red-800\/100 {
  color: #991b1b;
}

.text-red-800\/20 {
  color: #991b1b33;
}

.text-red-800\/25 {
  color: #991b1b40;
}

.text-red-800\/30 {
  color: #991b1b4d;
}

.text-red-800\/40 {
  color: #991b1b66;
}

.text-red-800\/5 {
  color: #991b1b0d;
}

.text-red-800\/50 {
  color: #991b1b80;
}

.text-red-800\/60 {
  color: #991b1b99;
}

.text-red-800\/70 {
  color: #991b1bb3;
}

.text-red-800\/75 {
  color: #991b1bbf;
}

.text-red-800\/80 {
  color: #991b1bcc;
}

.text-red-800\/90 {
  color: #991b1be6;
}

.text-red-800\/95 {
  color: #991b1bf2;
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-red-900\/0 {
  color: #7f1d1d00;
}

.text-red-900\/10 {
  color: #7f1d1d1a;
}

.text-red-900\/100 {
  color: #7f1d1d;
}

.text-red-900\/20 {
  color: #7f1d1d33;
}

.text-red-900\/25 {
  color: #7f1d1d40;
}

.text-red-900\/30 {
  color: #7f1d1d4d;
}

.text-red-900\/40 {
  color: #7f1d1d66;
}

.text-red-900\/5 {
  color: #7f1d1d0d;
}

.text-red-900\/50 {
  color: #7f1d1d80;
}

.text-red-900\/60 {
  color: #7f1d1d99;
}

.text-red-900\/70 {
  color: #7f1d1db3;
}

.text-red-900\/75 {
  color: #7f1d1dbf;
}

.text-red-900\/80 {
  color: #7f1d1dcc;
}

.text-red-900\/90 {
  color: #7f1d1de6;
}

.text-red-900\/95 {
  color: #7f1d1df2;
}

.text-red-950 {
  --tw-text-opacity: 1;
  color: rgb(69 10 10 / var(--tw-text-opacity));
}

.text-red-950\/0 {
  color: #450a0a00;
}

.text-red-950\/10 {
  color: #450a0a1a;
}

.text-red-950\/100 {
  color: #450a0a;
}

.text-red-950\/20 {
  color: #450a0a33;
}

.text-red-950\/25 {
  color: #450a0a40;
}

.text-red-950\/30 {
  color: #450a0a4d;
}

.text-red-950\/40 {
  color: #450a0a66;
}

.text-red-950\/5 {
  color: #450a0a0d;
}

.text-red-950\/50 {
  color: #450a0a80;
}

.text-red-950\/60 {
  color: #450a0a99;
}

.text-red-950\/70 {
  color: #450a0ab3;
}

.text-red-950\/75 {
  color: #450a0abf;
}

.text-red-950\/80 {
  color: #450a0acc;
}

.text-red-950\/90 {
  color: #450a0ae6;
}

.text-red-950\/95 {
  color: #450a0af2;
}

.text-rose-100 {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

.text-rose-100\/0 {
  color: #ffe4e600;
}

.text-rose-100\/10 {
  color: #ffe4e61a;
}

.text-rose-100\/100 {
  color: #ffe4e6;
}

.text-rose-100\/20 {
  color: #ffe4e633;
}

.text-rose-100\/25 {
  color: #ffe4e640;
}

.text-rose-100\/30 {
  color: #ffe4e64d;
}

.text-rose-100\/40 {
  color: #ffe4e666;
}

.text-rose-100\/5 {
  color: #ffe4e60d;
}

.text-rose-100\/50 {
  color: #ffe4e680;
}

.text-rose-100\/60 {
  color: #ffe4e699;
}

.text-rose-100\/70 {
  color: #ffe4e6b3;
}

.text-rose-100\/75 {
  color: #ffe4e6bf;
}

.text-rose-100\/80 {
  color: #ffe4e6cc;
}

.text-rose-100\/90 {
  color: #ffe4e6e6;
}

.text-rose-100\/95 {
  color: #ffe4e6f2;
}

.text-rose-200 {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

.text-rose-200\/0 {
  color: #fecdd300;
}

.text-rose-200\/10 {
  color: #fecdd31a;
}

.text-rose-200\/100 {
  color: #fecdd3;
}

.text-rose-200\/20 {
  color: #fecdd333;
}

.text-rose-200\/25 {
  color: #fecdd340;
}

.text-rose-200\/30 {
  color: #fecdd34d;
}

.text-rose-200\/40 {
  color: #fecdd366;
}

.text-rose-200\/5 {
  color: #fecdd30d;
}

.text-rose-200\/50 {
  color: #fecdd380;
}

.text-rose-200\/60 {
  color: #fecdd399;
}

.text-rose-200\/70 {
  color: #fecdd3b3;
}

.text-rose-200\/75 {
  color: #fecdd3bf;
}

.text-rose-200\/80 {
  color: #fecdd3cc;
}

.text-rose-200\/90 {
  color: #fecdd3e6;
}

.text-rose-200\/95 {
  color: #fecdd3f2;
}

.text-rose-300 {
  --tw-text-opacity: 1;
  color: rgb(253 164 175 / var(--tw-text-opacity));
}

.text-rose-300\/0 {
  color: #fda4af00;
}

.text-rose-300\/10 {
  color: #fda4af1a;
}

.text-rose-300\/100 {
  color: #fda4af;
}

.text-rose-300\/20 {
  color: #fda4af33;
}

.text-rose-300\/25 {
  color: #fda4af40;
}

.text-rose-300\/30 {
  color: #fda4af4d;
}

.text-rose-300\/40 {
  color: #fda4af66;
}

.text-rose-300\/5 {
  color: #fda4af0d;
}

.text-rose-300\/50 {
  color: #fda4af80;
}

.text-rose-300\/60 {
  color: #fda4af99;
}

.text-rose-300\/70 {
  color: #fda4afb3;
}

.text-rose-300\/75 {
  color: #fda4afbf;
}

.text-rose-300\/80 {
  color: #fda4afcc;
}

.text-rose-300\/90 {
  color: #fda4afe6;
}

.text-rose-300\/95 {
  color: #fda4aff2;
}

.text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.text-rose-400\/0 {
  color: #fb718500;
}

.text-rose-400\/10 {
  color: #fb71851a;
}

.text-rose-400\/100 {
  color: #fb7185;
}

.text-rose-400\/20 {
  color: #fb718533;
}

.text-rose-400\/25 {
  color: #fb718540;
}

.text-rose-400\/30 {
  color: #fb71854d;
}

.text-rose-400\/40 {
  color: #fb718566;
}

.text-rose-400\/5 {
  color: #fb71850d;
}

.text-rose-400\/50 {
  color: #fb718580;
}

.text-rose-400\/60 {
  color: #fb718599;
}

.text-rose-400\/70 {
  color: #fb7185b3;
}

.text-rose-400\/75 {
  color: #fb7185bf;
}

.text-rose-400\/80 {
  color: #fb7185cc;
}

.text-rose-400\/90 {
  color: #fb7185e6;
}

.text-rose-400\/95 {
  color: #fb7185f2;
}

.text-rose-50 {
  --tw-text-opacity: 1;
  color: rgb(255 241 242 / var(--tw-text-opacity));
}

.text-rose-50\/0 {
  color: #fff1f200;
}

.text-rose-50\/10 {
  color: #fff1f21a;
}

.text-rose-50\/100 {
  color: #fff1f2;
}

.text-rose-50\/20 {
  color: #fff1f233;
}

.text-rose-50\/25 {
  color: #fff1f240;
}

.text-rose-50\/30 {
  color: #fff1f24d;
}

.text-rose-50\/40 {
  color: #fff1f266;
}

.text-rose-50\/5 {
  color: #fff1f20d;
}

.text-rose-50\/50 {
  color: #fff1f280;
}

.text-rose-50\/60 {
  color: #fff1f299;
}

.text-rose-50\/70 {
  color: #fff1f2b3;
}

.text-rose-50\/75 {
  color: #fff1f2bf;
}

.text-rose-50\/80 {
  color: #fff1f2cc;
}

.text-rose-50\/90 {
  color: #fff1f2e6;
}

.text-rose-50\/95 {
  color: #fff1f2f2;
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.text-rose-500\/0 {
  color: #f43f5e00;
}

.text-rose-500\/10 {
  color: #f43f5e1a;
}

.text-rose-500\/100 {
  color: #f43f5e;
}

.text-rose-500\/20 {
  color: #f43f5e33;
}

.text-rose-500\/25 {
  color: #f43f5e40;
}

.text-rose-500\/30 {
  color: #f43f5e4d;
}

.text-rose-500\/40 {
  color: #f43f5e66;
}

.text-rose-500\/5 {
  color: #f43f5e0d;
}

.text-rose-500\/50 {
  color: #f43f5e80;
}

.text-rose-500\/60 {
  color: #f43f5e99;
}

.text-rose-500\/70 {
  color: #f43f5eb3;
}

.text-rose-500\/75 {
  color: #f43f5ebf;
}

.text-rose-500\/80 {
  color: #f43f5ecc;
}

.text-rose-500\/90 {
  color: #f43f5ee6;
}

.text-rose-500\/95 {
  color: #f43f5ef2;
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.text-rose-600\/0 {
  color: #e11d4800;
}

.text-rose-600\/10 {
  color: #e11d481a;
}

.text-rose-600\/100 {
  color: #e11d48;
}

.text-rose-600\/20 {
  color: #e11d4833;
}

.text-rose-600\/25 {
  color: #e11d4840;
}

.text-rose-600\/30 {
  color: #e11d484d;
}

.text-rose-600\/40 {
  color: #e11d4866;
}

.text-rose-600\/5 {
  color: #e11d480d;
}

.text-rose-600\/50 {
  color: #e11d4880;
}

.text-rose-600\/60 {
  color: #e11d4899;
}

.text-rose-600\/70 {
  color: #e11d48b3;
}

.text-rose-600\/75 {
  color: #e11d48bf;
}

.text-rose-600\/80 {
  color: #e11d48cc;
}

.text-rose-600\/90 {
  color: #e11d48e6;
}

.text-rose-600\/95 {
  color: #e11d48f2;
}

.text-rose-700 {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.text-rose-700\/0 {
  color: #be123c00;
}

.text-rose-700\/10 {
  color: #be123c1a;
}

.text-rose-700\/100 {
  color: #be123c;
}

.text-rose-700\/20 {
  color: #be123c33;
}

.text-rose-700\/25 {
  color: #be123c40;
}

.text-rose-700\/30 {
  color: #be123c4d;
}

.text-rose-700\/40 {
  color: #be123c66;
}

.text-rose-700\/5 {
  color: #be123c0d;
}

.text-rose-700\/50 {
  color: #be123c80;
}

.text-rose-700\/60 {
  color: #be123c99;
}

.text-rose-700\/70 {
  color: #be123cb3;
}

.text-rose-700\/75 {
  color: #be123cbf;
}

.text-rose-700\/80 {
  color: #be123ccc;
}

.text-rose-700\/90 {
  color: #be123ce6;
}

.text-rose-700\/95 {
  color: #be123cf2;
}

.text-rose-800 {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity));
}

.text-rose-800\/0 {
  color: #9f123900;
}

.text-rose-800\/10 {
  color: #9f12391a;
}

.text-rose-800\/100 {
  color: #9f1239;
}

.text-rose-800\/20 {
  color: #9f123933;
}

.text-rose-800\/25 {
  color: #9f123940;
}

.text-rose-800\/30 {
  color: #9f12394d;
}

.text-rose-800\/40 {
  color: #9f123966;
}

.text-rose-800\/5 {
  color: #9f12390d;
}

.text-rose-800\/50 {
  color: #9f123980;
}

.text-rose-800\/60 {
  color: #9f123999;
}

.text-rose-800\/70 {
  color: #9f1239b3;
}

.text-rose-800\/75 {
  color: #9f1239bf;
}

.text-rose-800\/80 {
  color: #9f1239cc;
}

.text-rose-800\/90 {
  color: #9f1239e6;
}

.text-rose-800\/95 {
  color: #9f1239f2;
}

.text-rose-900 {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity));
}

.text-rose-900\/0 {
  color: #88133700;
}

.text-rose-900\/10 {
  color: #8813371a;
}

.text-rose-900\/100 {
  color: #881337;
}

.text-rose-900\/20 {
  color: #88133733;
}

.text-rose-900\/25 {
  color: #88133740;
}

.text-rose-900\/30 {
  color: #8813374d;
}

.text-rose-900\/40 {
  color: #88133766;
}

.text-rose-900\/5 {
  color: #8813370d;
}

.text-rose-900\/50 {
  color: #88133780;
}

.text-rose-900\/60 {
  color: #88133799;
}

.text-rose-900\/70 {
  color: #881337b3;
}

.text-rose-900\/75 {
  color: #881337bf;
}

.text-rose-900\/80 {
  color: #881337cc;
}

.text-rose-900\/90 {
  color: #881337e6;
}

.text-rose-900\/95 {
  color: #881337f2;
}

.text-rose-950 {
  --tw-text-opacity: 1;
  color: rgb(76 5 25 / var(--tw-text-opacity));
}

.text-rose-950\/0 {
  color: #4c051900;
}

.text-rose-950\/10 {
  color: #4c05191a;
}

.text-rose-950\/100 {
  color: #4c0519;
}

.text-rose-950\/20 {
  color: #4c051933;
}

.text-rose-950\/25 {
  color: #4c051940;
}

.text-rose-950\/30 {
  color: #4c05194d;
}

.text-rose-950\/40 {
  color: #4c051966;
}

.text-rose-950\/5 {
  color: #4c05190d;
}

.text-rose-950\/50 {
  color: #4c051980;
}

.text-rose-950\/60 {
  color: #4c051999;
}

.text-rose-950\/70 {
  color: #4c0519b3;
}

.text-rose-950\/75 {
  color: #4c0519bf;
}

.text-rose-950\/80 {
  color: #4c0519cc;
}

.text-rose-950\/90 {
  color: #4c0519e6;
}

.text-rose-950\/95 {
  color: #4c0519f2;
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.text-sky-100\/0 {
  color: #e0f2fe00;
}

.text-sky-100\/10 {
  color: #e0f2fe1a;
}

.text-sky-100\/100 {
  color: #e0f2fe;
}

.text-sky-100\/20 {
  color: #e0f2fe33;
}

.text-sky-100\/25 {
  color: #e0f2fe40;
}

.text-sky-100\/30 {
  color: #e0f2fe4d;
}

.text-sky-100\/40 {
  color: #e0f2fe66;
}

.text-sky-100\/5 {
  color: #e0f2fe0d;
}

.text-sky-100\/50 {
  color: #e0f2fe80;
}

.text-sky-100\/60 {
  color: #e0f2fe99;
}

.text-sky-100\/70 {
  color: #e0f2feb3;
}

.text-sky-100\/75 {
  color: #e0f2febf;
}

.text-sky-100\/80 {
  color: #e0f2fecc;
}

.text-sky-100\/90 {
  color: #e0f2fee6;
}

.text-sky-100\/95 {
  color: #e0f2fef2;
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.text-sky-200\/0 {
  color: #bae6fd00;
}

.text-sky-200\/10 {
  color: #bae6fd1a;
}

.text-sky-200\/100 {
  color: #bae6fd;
}

.text-sky-200\/20 {
  color: #bae6fd33;
}

.text-sky-200\/25 {
  color: #bae6fd40;
}

.text-sky-200\/30 {
  color: #bae6fd4d;
}

.text-sky-200\/40 {
  color: #bae6fd66;
}

.text-sky-200\/5 {
  color: #bae6fd0d;
}

.text-sky-200\/50 {
  color: #bae6fd80;
}

.text-sky-200\/60 {
  color: #bae6fd99;
}

.text-sky-200\/70 {
  color: #bae6fdb3;
}

.text-sky-200\/75 {
  color: #bae6fdbf;
}

.text-sky-200\/80 {
  color: #bae6fdcc;
}

.text-sky-200\/90 {
  color: #bae6fde6;
}

.text-sky-200\/95 {
  color: #bae6fdf2;
}

.text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.text-sky-300\/0 {
  color: #7dd3fc00;
}

.text-sky-300\/10 {
  color: #7dd3fc1a;
}

.text-sky-300\/100 {
  color: #7dd3fc;
}

.text-sky-300\/20 {
  color: #7dd3fc33;
}

.text-sky-300\/25 {
  color: #7dd3fc40;
}

.text-sky-300\/30 {
  color: #7dd3fc4d;
}

.text-sky-300\/40 {
  color: #7dd3fc66;
}

.text-sky-300\/5 {
  color: #7dd3fc0d;
}

.text-sky-300\/50 {
  color: #7dd3fc80;
}

.text-sky-300\/60 {
  color: #7dd3fc99;
}

.text-sky-300\/70 {
  color: #7dd3fcb3;
}

.text-sky-300\/75 {
  color: #7dd3fcbf;
}

.text-sky-300\/80 {
  color: #7dd3fccc;
}

.text-sky-300\/90 {
  color: #7dd3fce6;
}

.text-sky-300\/95 {
  color: #7dd3fcf2;
}

.text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.text-sky-400\/0 {
  color: #38bdf800;
}

.text-sky-400\/10 {
  color: #38bdf81a;
}

.text-sky-400\/100 {
  color: #38bdf8;
}

.text-sky-400\/20 {
  color: #38bdf833;
}

.text-sky-400\/25 {
  color: #38bdf840;
}

.text-sky-400\/30 {
  color: #38bdf84d;
}

.text-sky-400\/40 {
  color: #38bdf866;
}

.text-sky-400\/5 {
  color: #38bdf80d;
}

.text-sky-400\/50 {
  color: #38bdf880;
}

.text-sky-400\/60 {
  color: #38bdf899;
}

.text-sky-400\/70 {
  color: #38bdf8b3;
}

.text-sky-400\/75 {
  color: #38bdf8bf;
}

.text-sky-400\/80 {
  color: #38bdf8cc;
}

.text-sky-400\/90 {
  color: #38bdf8e6;
}

.text-sky-400\/95 {
  color: #38bdf8f2;
}

.text-sky-50 {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity));
}

.text-sky-50\/0 {
  color: #f0f9ff00;
}

.text-sky-50\/10 {
  color: #f0f9ff1a;
}

.text-sky-50\/100 {
  color: #f0f9ff;
}

.text-sky-50\/20 {
  color: #f0f9ff33;
}

.text-sky-50\/25 {
  color: #f0f9ff40;
}

.text-sky-50\/30 {
  color: #f0f9ff4d;
}

.text-sky-50\/40 {
  color: #f0f9ff66;
}

.text-sky-50\/5 {
  color: #f0f9ff0d;
}

.text-sky-50\/50 {
  color: #f0f9ff80;
}

.text-sky-50\/60 {
  color: #f0f9ff99;
}

.text-sky-50\/70 {
  color: #f0f9ffb3;
}

.text-sky-50\/75 {
  color: #f0f9ffbf;
}

.text-sky-50\/80 {
  color: #f0f9ffcc;
}

.text-sky-50\/90 {
  color: #f0f9ffe6;
}

.text-sky-50\/95 {
  color: #f0f9fff2;
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.text-sky-500\/0 {
  color: #0ea5e900;
}

.text-sky-500\/10 {
  color: #0ea5e91a;
}

.text-sky-500\/100 {
  color: #0ea5e9;
}

.text-sky-500\/20 {
  color: #0ea5e933;
}

.text-sky-500\/25 {
  color: #0ea5e940;
}

.text-sky-500\/30 {
  color: #0ea5e94d;
}

.text-sky-500\/40 {
  color: #0ea5e966;
}

.text-sky-500\/5 {
  color: #0ea5e90d;
}

.text-sky-500\/50 {
  color: #0ea5e980;
}

.text-sky-500\/60 {
  color: #0ea5e999;
}

.text-sky-500\/70 {
  color: #0ea5e9b3;
}

.text-sky-500\/75 {
  color: #0ea5e9bf;
}

.text-sky-500\/80 {
  color: #0ea5e9cc;
}

.text-sky-500\/90 {
  color: #0ea5e9e6;
}

.text-sky-500\/95 {
  color: #0ea5e9f2;
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.text-sky-600\/0 {
  color: #0284c700;
}

.text-sky-600\/10 {
  color: #0284c71a;
}

.text-sky-600\/100 {
  color: #0284c7;
}

.text-sky-600\/20 {
  color: #0284c733;
}

.text-sky-600\/25 {
  color: #0284c740;
}

.text-sky-600\/30 {
  color: #0284c74d;
}

.text-sky-600\/40 {
  color: #0284c766;
}

.text-sky-600\/5 {
  color: #0284c70d;
}

.text-sky-600\/50 {
  color: #0284c780;
}

.text-sky-600\/60 {
  color: #0284c799;
}

.text-sky-600\/70 {
  color: #0284c7b3;
}

.text-sky-600\/75 {
  color: #0284c7bf;
}

.text-sky-600\/80 {
  color: #0284c7cc;
}

.text-sky-600\/90 {
  color: #0284c7e6;
}

.text-sky-600\/95 {
  color: #0284c7f2;
}

.text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.text-sky-700\/0 {
  color: #0369a100;
}

.text-sky-700\/10 {
  color: #0369a11a;
}

.text-sky-700\/100 {
  color: #0369a1;
}

.text-sky-700\/20 {
  color: #0369a133;
}

.text-sky-700\/25 {
  color: #0369a140;
}

.text-sky-700\/30 {
  color: #0369a14d;
}

.text-sky-700\/40 {
  color: #0369a166;
}

.text-sky-700\/5 {
  color: #0369a10d;
}

.text-sky-700\/50 {
  color: #0369a180;
}

.text-sky-700\/60 {
  color: #0369a199;
}

.text-sky-700\/70 {
  color: #0369a1b3;
}

.text-sky-700\/75 {
  color: #0369a1bf;
}

.text-sky-700\/80 {
  color: #0369a1cc;
}

.text-sky-700\/90 {
  color: #0369a1e6;
}

.text-sky-700\/95 {
  color: #0369a1f2;
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.text-sky-800\/0 {
  color: #07598500;
}

.text-sky-800\/10 {
  color: #0759851a;
}

.text-sky-800\/100 {
  color: #075985;
}

.text-sky-800\/20 {
  color: #07598533;
}

.text-sky-800\/25 {
  color: #07598540;
}

.text-sky-800\/30 {
  color: #0759854d;
}

.text-sky-800\/40 {
  color: #07598566;
}

.text-sky-800\/5 {
  color: #0759850d;
}

.text-sky-800\/50 {
  color: #07598580;
}

.text-sky-800\/60 {
  color: #07598599;
}

.text-sky-800\/70 {
  color: #075985b3;
}

.text-sky-800\/75 {
  color: #075985bf;
}

.text-sky-800\/80 {
  color: #075985cc;
}

.text-sky-800\/90 {
  color: #075985e6;
}

.text-sky-800\/95 {
  color: #075985f2;
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.text-sky-900\/0 {
  color: #0c4a6e00;
}

.text-sky-900\/10 {
  color: #0c4a6e1a;
}

.text-sky-900\/100 {
  color: #0c4a6e;
}

.text-sky-900\/20 {
  color: #0c4a6e33;
}

.text-sky-900\/25 {
  color: #0c4a6e40;
}

.text-sky-900\/30 {
  color: #0c4a6e4d;
}

.text-sky-900\/40 {
  color: #0c4a6e66;
}

.text-sky-900\/5 {
  color: #0c4a6e0d;
}

.text-sky-900\/50 {
  color: #0c4a6e80;
}

.text-sky-900\/60 {
  color: #0c4a6e99;
}

.text-sky-900\/70 {
  color: #0c4a6eb3;
}

.text-sky-900\/75 {
  color: #0c4a6ebf;
}

.text-sky-900\/80 {
  color: #0c4a6ecc;
}

.text-sky-900\/90 {
  color: #0c4a6ee6;
}

.text-sky-900\/95 {
  color: #0c4a6ef2;
}

.text-sky-950 {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity));
}

.text-sky-950\/0 {
  color: #082f4900;
}

.text-sky-950\/10 {
  color: #082f491a;
}

.text-sky-950\/100 {
  color: #082f49;
}

.text-sky-950\/20 {
  color: #082f4933;
}

.text-sky-950\/25 {
  color: #082f4940;
}

.text-sky-950\/30 {
  color: #082f494d;
}

.text-sky-950\/40 {
  color: #082f4966;
}

.text-sky-950\/5 {
  color: #082f490d;
}

.text-sky-950\/50 {
  color: #082f4980;
}

.text-sky-950\/60 {
  color: #082f4999;
}

.text-sky-950\/70 {
  color: #082f49b3;
}

.text-sky-950\/75 {
  color: #082f49bf;
}

.text-sky-950\/80 {
  color: #082f49cc;
}

.text-sky-950\/90 {
  color: #082f49e6;
}

.text-sky-950\/95 {
  color: #082f49f2;
}

.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.text-slate-100\/0 {
  color: #f1f5f900;
}

.text-slate-100\/10 {
  color: #f1f5f91a;
}

.text-slate-100\/100 {
  color: #f1f5f9;
}

.text-slate-100\/20 {
  color: #f1f5f933;
}

.text-slate-100\/25 {
  color: #f1f5f940;
}

.text-slate-100\/30 {
  color: #f1f5f94d;
}

.text-slate-100\/40 {
  color: #f1f5f966;
}

.text-slate-100\/5 {
  color: #f1f5f90d;
}

.text-slate-100\/50 {
  color: #f1f5f980;
}

.text-slate-100\/60 {
  color: #f1f5f999;
}

.text-slate-100\/70 {
  color: #f1f5f9b3;
}

.text-slate-100\/75 {
  color: #f1f5f9bf;
}

.text-slate-100\/80 {
  color: #f1f5f9cc;
}

.text-slate-100\/90 {
  color: #f1f5f9e6;
}

.text-slate-100\/95 {
  color: #f1f5f9f2;
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.text-slate-200\/0 {
  color: #e2e8f000;
}

.text-slate-200\/10 {
  color: #e2e8f01a;
}

.text-slate-200\/100 {
  color: #e2e8f0;
}

.text-slate-200\/20 {
  color: #e2e8f033;
}

.text-slate-200\/25 {
  color: #e2e8f040;
}

.text-slate-200\/30 {
  color: #e2e8f04d;
}

.text-slate-200\/40 {
  color: #e2e8f066;
}

.text-slate-200\/5 {
  color: #e2e8f00d;
}

.text-slate-200\/50 {
  color: #e2e8f080;
}

.text-slate-200\/60 {
  color: #e2e8f099;
}

.text-slate-200\/70 {
  color: #e2e8f0b3;
}

.text-slate-200\/75 {
  color: #e2e8f0bf;
}

.text-slate-200\/80 {
  color: #e2e8f0cc;
}

.text-slate-200\/90 {
  color: #e2e8f0e6;
}

.text-slate-200\/95 {
  color: #e2e8f0f2;
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-300\/0 {
  color: #cbd5e100;
}

.text-slate-300\/10 {
  color: #cbd5e11a;
}

.text-slate-300\/100 {
  color: #cbd5e1;
}

.text-slate-300\/20 {
  color: #cbd5e133;
}

.text-slate-300\/25 {
  color: #cbd5e140;
}

.text-slate-300\/30 {
  color: #cbd5e14d;
}

.text-slate-300\/40 {
  color: #cbd5e166;
}

.text-slate-300\/5 {
  color: #cbd5e10d;
}

.text-slate-300\/50 {
  color: #cbd5e180;
}

.text-slate-300\/60 {
  color: #cbd5e199;
}

.text-slate-300\/70 {
  color: #cbd5e1b3;
}

.text-slate-300\/75 {
  color: #cbd5e1bf;
}

.text-slate-300\/80 {
  color: #cbd5e1cc;
}

.text-slate-300\/90 {
  color: #cbd5e1e6;
}

.text-slate-300\/95 {
  color: #cbd5e1f2;
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-400\/0 {
  color: #94a3b800;
}

.text-slate-400\/10 {
  color: #94a3b81a;
}

.text-slate-400\/100 {
  color: #94a3b8;
}

.text-slate-400\/20 {
  color: #94a3b833;
}

.text-slate-400\/25 {
  color: #94a3b840;
}

.text-slate-400\/30 {
  color: #94a3b84d;
}

.text-slate-400\/40 {
  color: #94a3b866;
}

.text-slate-400\/5 {
  color: #94a3b80d;
}

.text-slate-400\/50 {
  color: #94a3b880;
}

.text-slate-400\/60 {
  color: #94a3b899;
}

.text-slate-400\/70 {
  color: #94a3b8b3;
}

.text-slate-400\/75 {
  color: #94a3b8bf;
}

.text-slate-400\/80 {
  color: #94a3b8cc;
}

.text-slate-400\/90 {
  color: #94a3b8e6;
}

.text-slate-400\/95 {
  color: #94a3b8f2;
}

.text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.text-slate-50\/0 {
  color: #f8fafc00;
}

.text-slate-50\/10 {
  color: #f8fafc1a;
}

.text-slate-50\/100 {
  color: #f8fafc;
}

.text-slate-50\/20 {
  color: #f8fafc33;
}

.text-slate-50\/25 {
  color: #f8fafc40;
}

.text-slate-50\/30 {
  color: #f8fafc4d;
}

.text-slate-50\/40 {
  color: #f8fafc66;
}

.text-slate-50\/5 {
  color: #f8fafc0d;
}

.text-slate-50\/50 {
  color: #f8fafc80;
}

.text-slate-50\/60 {
  color: #f8fafc99;
}

.text-slate-50\/70 {
  color: #f8fafcb3;
}

.text-slate-50\/75 {
  color: #f8fafcbf;
}

.text-slate-50\/80 {
  color: #f8fafccc;
}

.text-slate-50\/90 {
  color: #f8fafce6;
}

.text-slate-50\/95 {
  color: #f8fafcf2;
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-500\/0 {
  color: #64748b00;
}

.text-slate-500\/10 {
  color: #64748b1a;
}

.text-slate-500\/100 {
  color: #64748b;
}

.text-slate-500\/20 {
  color: #64748b33;
}

.text-slate-500\/25 {
  color: #64748b40;
}

.text-slate-500\/30 {
  color: #64748b4d;
}

.text-slate-500\/40 {
  color: #64748b66;
}

.text-slate-500\/5 {
  color: #64748b0d;
}

.text-slate-500\/50 {
  color: #64748b80;
}

.text-slate-500\/60 {
  color: #64748b99;
}

.text-slate-500\/70 {
  color: #64748bb3;
}

.text-slate-500\/75 {
  color: #64748bbf;
}

.text-slate-500\/80 {
  color: #64748bcc;
}

.text-slate-500\/90 {
  color: #64748be6;
}

.text-slate-500\/95 {
  color: #64748bf2;
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-600\/0 {
  color: #47556900;
}

.text-slate-600\/10 {
  color: #4755691a;
}

.text-slate-600\/100 {
  color: #475569;
}

.text-slate-600\/20 {
  color: #47556933;
}

.text-slate-600\/25 {
  color: #47556940;
}

.text-slate-600\/30 {
  color: #4755694d;
}

.text-slate-600\/40 {
  color: #47556966;
}

.text-slate-600\/5 {
  color: #4755690d;
}

.text-slate-600\/50 {
  color: #47556980;
}

.text-slate-600\/60 {
  color: #47556999;
}

.text-slate-600\/70 {
  color: #475569b3;
}

.text-slate-600\/75 {
  color: #475569bf;
}

.text-slate-600\/80 {
  color: #475569cc;
}

.text-slate-600\/90 {
  color: #475569e6;
}

.text-slate-600\/95 {
  color: #475569f2;
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-slate-700\/0 {
  color: #33415500;
}

.text-slate-700\/10 {
  color: #3341551a;
}

.text-slate-700\/100 {
  color: #334155;
}

.text-slate-700\/20 {
  color: #33415533;
}

.text-slate-700\/25 {
  color: #33415540;
}

.text-slate-700\/30 {
  color: #3341554d;
}

.text-slate-700\/40 {
  color: #33415566;
}

.text-slate-700\/5 {
  color: #3341550d;
}

.text-slate-700\/50 {
  color: #33415580;
}

.text-slate-700\/60 {
  color: #33415599;
}

.text-slate-700\/70 {
  color: #334155b3;
}

.text-slate-700\/75 {
  color: #334155bf;
}

.text-slate-700\/80 {
  color: #334155cc;
}

.text-slate-700\/90 {
  color: #334155e6;
}

.text-slate-700\/95 {
  color: #334155f2;
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-slate-800\/0 {
  color: #1e293b00;
}

.text-slate-800\/10 {
  color: #1e293b1a;
}

.text-slate-800\/100 {
  color: #1e293b;
}

.text-slate-800\/20 {
  color: #1e293b33;
}

.text-slate-800\/25 {
  color: #1e293b40;
}

.text-slate-800\/30 {
  color: #1e293b4d;
}

.text-slate-800\/40 {
  color: #1e293b66;
}

.text-slate-800\/5 {
  color: #1e293b0d;
}

.text-slate-800\/50 {
  color: #1e293b80;
}

.text-slate-800\/60 {
  color: #1e293b99;
}

.text-slate-800\/70 {
  color: #1e293bb3;
}

.text-slate-800\/75 {
  color: #1e293bbf;
}

.text-slate-800\/80 {
  color: #1e293bcc;
}

.text-slate-800\/90 {
  color: #1e293be6;
}

.text-slate-800\/95 {
  color: #1e293bf2;
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-slate-900\/0 {
  color: #0f172a00;
}

.text-slate-900\/10 {
  color: #0f172a1a;
}

.text-slate-900\/100 {
  color: #0f172a;
}

.text-slate-900\/20 {
  color: #0f172a33;
}

.text-slate-900\/25 {
  color: #0f172a40;
}

.text-slate-900\/30 {
  color: #0f172a4d;
}

.text-slate-900\/40 {
  color: #0f172a66;
}

.text-slate-900\/5 {
  color: #0f172a0d;
}

.text-slate-900\/50 {
  color: #0f172a80;
}

.text-slate-900\/60 {
  color: #0f172a99;
}

.text-slate-900\/70 {
  color: #0f172ab3;
}

.text-slate-900\/75 {
  color: #0f172abf;
}

.text-slate-900\/80 {
  color: #0f172acc;
}

.text-slate-900\/90 {
  color: #0f172ae6;
}

.text-slate-900\/95 {
  color: #0f172af2;
}

.text-slate-950 {
  --tw-text-opacity: 1;
  color: rgb(2 6 23 / var(--tw-text-opacity));
}

.text-slate-950\/0 {
  color: #02061700;
}

.text-slate-950\/10 {
  color: #0206171a;
}

.text-slate-950\/100 {
  color: #020617;
}

.text-slate-950\/20 {
  color: #02061733;
}

.text-slate-950\/25 {
  color: #02061740;
}

.text-slate-950\/30 {
  color: #0206174d;
}

.text-slate-950\/40 {
  color: #02061766;
}

.text-slate-950\/5 {
  color: #0206170d;
}

.text-slate-950\/50 {
  color: #02061780;
}

.text-slate-950\/60 {
  color: #02061799;
}

.text-slate-950\/70 {
  color: #020617b3;
}

.text-slate-950\/75 {
  color: #020617bf;
}

.text-slate-950\/80 {
  color: #020617cc;
}

.text-slate-950\/90 {
  color: #020617e6;
}

.text-slate-950\/95 {
  color: #020617f2;
}

.text-stone-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

.text-stone-100\/0 {
  color: #f5f5f400;
}

.text-stone-100\/10 {
  color: #f5f5f41a;
}

.text-stone-100\/100 {
  color: #f5f5f4;
}

.text-stone-100\/20 {
  color: #f5f5f433;
}

.text-stone-100\/25 {
  color: #f5f5f440;
}

.text-stone-100\/30 {
  color: #f5f5f44d;
}

.text-stone-100\/40 {
  color: #f5f5f466;
}

.text-stone-100\/5 {
  color: #f5f5f40d;
}

.text-stone-100\/50 {
  color: #f5f5f480;
}

.text-stone-100\/60 {
  color: #f5f5f499;
}

.text-stone-100\/70 {
  color: #f5f5f4b3;
}

.text-stone-100\/75 {
  color: #f5f5f4bf;
}

.text-stone-100\/80 {
  color: #f5f5f4cc;
}

.text-stone-100\/90 {
  color: #f5f5f4e6;
}

.text-stone-100\/95 {
  color: #f5f5f4f2;
}

.text-stone-200 {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

.text-stone-200\/0 {
  color: #e7e5e400;
}

.text-stone-200\/10 {
  color: #e7e5e41a;
}

.text-stone-200\/100 {
  color: #e7e5e4;
}

.text-stone-200\/20 {
  color: #e7e5e433;
}

.text-stone-200\/25 {
  color: #e7e5e440;
}

.text-stone-200\/30 {
  color: #e7e5e44d;
}

.text-stone-200\/40 {
  color: #e7e5e466;
}

.text-stone-200\/5 {
  color: #e7e5e40d;
}

.text-stone-200\/50 {
  color: #e7e5e480;
}

.text-stone-200\/60 {
  color: #e7e5e499;
}

.text-stone-200\/70 {
  color: #e7e5e4b3;
}

.text-stone-200\/75 {
  color: #e7e5e4bf;
}

.text-stone-200\/80 {
  color: #e7e5e4cc;
}

.text-stone-200\/90 {
  color: #e7e5e4e6;
}

.text-stone-200\/95 {
  color: #e7e5e4f2;
}

.text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}

.text-stone-300\/0 {
  color: #d6d3d100;
}

.text-stone-300\/10 {
  color: #d6d3d11a;
}

.text-stone-300\/100 {
  color: #d6d3d1;
}

.text-stone-300\/20 {
  color: #d6d3d133;
}

.text-stone-300\/25 {
  color: #d6d3d140;
}

.text-stone-300\/30 {
  color: #d6d3d14d;
}

.text-stone-300\/40 {
  color: #d6d3d166;
}

.text-stone-300\/5 {
  color: #d6d3d10d;
}

.text-stone-300\/50 {
  color: #d6d3d180;
}

.text-stone-300\/60 {
  color: #d6d3d199;
}

.text-stone-300\/70 {
  color: #d6d3d1b3;
}

.text-stone-300\/75 {
  color: #d6d3d1bf;
}

.text-stone-300\/80 {
  color: #d6d3d1cc;
}

.text-stone-300\/90 {
  color: #d6d3d1e6;
}

.text-stone-300\/95 {
  color: #d6d3d1f2;
}

.text-stone-400 {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

.text-stone-400\/0 {
  color: #a8a29e00;
}

.text-stone-400\/10 {
  color: #a8a29e1a;
}

.text-stone-400\/100 {
  color: #a8a29e;
}

.text-stone-400\/20 {
  color: #a8a29e33;
}

.text-stone-400\/25 {
  color: #a8a29e40;
}

.text-stone-400\/30 {
  color: #a8a29e4d;
}

.text-stone-400\/40 {
  color: #a8a29e66;
}

.text-stone-400\/5 {
  color: #a8a29e0d;
}

.text-stone-400\/50 {
  color: #a8a29e80;
}

.text-stone-400\/60 {
  color: #a8a29e99;
}

.text-stone-400\/70 {
  color: #a8a29eb3;
}

.text-stone-400\/75 {
  color: #a8a29ebf;
}

.text-stone-400\/80 {
  color: #a8a29ecc;
}

.text-stone-400\/90 {
  color: #a8a29ee6;
}

.text-stone-400\/95 {
  color: #a8a29ef2;
}

.text-stone-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.text-stone-50\/0 {
  color: #fafaf900;
}

.text-stone-50\/10 {
  color: #fafaf91a;
}

.text-stone-50\/100 {
  color: #fafaf9;
}

.text-stone-50\/20 {
  color: #fafaf933;
}

.text-stone-50\/25 {
  color: #fafaf940;
}

.text-stone-50\/30 {
  color: #fafaf94d;
}

.text-stone-50\/40 {
  color: #fafaf966;
}

.text-stone-50\/5 {
  color: #fafaf90d;
}

.text-stone-50\/50 {
  color: #fafaf980;
}

.text-stone-50\/60 {
  color: #fafaf999;
}

.text-stone-50\/70 {
  color: #fafaf9b3;
}

.text-stone-50\/75 {
  color: #fafaf9bf;
}

.text-stone-50\/80 {
  color: #fafaf9cc;
}

.text-stone-50\/90 {
  color: #fafaf9e6;
}

.text-stone-50\/95 {
  color: #fafaf9f2;
}

.text-stone-500 {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

.text-stone-500\/0 {
  color: #78716c00;
}

.text-stone-500\/10 {
  color: #78716c1a;
}

.text-stone-500\/100 {
  color: #78716c;
}

.text-stone-500\/20 {
  color: #78716c33;
}

.text-stone-500\/25 {
  color: #78716c40;
}

.text-stone-500\/30 {
  color: #78716c4d;
}

.text-stone-500\/40 {
  color: #78716c66;
}

.text-stone-500\/5 {
  color: #78716c0d;
}

.text-stone-500\/50 {
  color: #78716c80;
}

.text-stone-500\/60 {
  color: #78716c99;
}

.text-stone-500\/70 {
  color: #78716cb3;
}

.text-stone-500\/75 {
  color: #78716cbf;
}

.text-stone-500\/80 {
  color: #78716ccc;
}

.text-stone-500\/90 {
  color: #78716ce6;
}

.text-stone-500\/95 {
  color: #78716cf2;
}

.text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity));
}

.text-stone-600\/0 {
  color: #57534e00;
}

.text-stone-600\/10 {
  color: #57534e1a;
}

.text-stone-600\/100 {
  color: #57534e;
}

.text-stone-600\/20 {
  color: #57534e33;
}

.text-stone-600\/25 {
  color: #57534e40;
}

.text-stone-600\/30 {
  color: #57534e4d;
}

.text-stone-600\/40 {
  color: #57534e66;
}

.text-stone-600\/5 {
  color: #57534e0d;
}

.text-stone-600\/50 {
  color: #57534e80;
}

.text-stone-600\/60 {
  color: #57534e99;
}

.text-stone-600\/70 {
  color: #57534eb3;
}

.text-stone-600\/75 {
  color: #57534ebf;
}

.text-stone-600\/80 {
  color: #57534ecc;
}

.text-stone-600\/90 {
  color: #57534ee6;
}

.text-stone-600\/95 {
  color: #57534ef2;
}

.text-stone-700 {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

.text-stone-700\/0 {
  color: #44403c00;
}

.text-stone-700\/10 {
  color: #44403c1a;
}

.text-stone-700\/100 {
  color: #44403c;
}

.text-stone-700\/20 {
  color: #44403c33;
}

.text-stone-700\/25 {
  color: #44403c40;
}

.text-stone-700\/30 {
  color: #44403c4d;
}

.text-stone-700\/40 {
  color: #44403c66;
}

.text-stone-700\/5 {
  color: #44403c0d;
}

.text-stone-700\/50 {
  color: #44403c80;
}

.text-stone-700\/60 {
  color: #44403c99;
}

.text-stone-700\/70 {
  color: #44403cb3;
}

.text-stone-700\/75 {
  color: #44403cbf;
}

.text-stone-700\/80 {
  color: #44403ccc;
}

.text-stone-700\/90 {
  color: #44403ce6;
}

.text-stone-700\/95 {
  color: #44403cf2;
}

.text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

.text-stone-800\/0 {
  color: #29252400;
}

.text-stone-800\/10 {
  color: #2925241a;
}

.text-stone-800\/100 {
  color: #292524;
}

.text-stone-800\/20 {
  color: #29252433;
}

.text-stone-800\/25 {
  color: #29252440;
}

.text-stone-800\/30 {
  color: #2925244d;
}

.text-stone-800\/40 {
  color: #29252466;
}

.text-stone-800\/5 {
  color: #2925240d;
}

.text-stone-800\/50 {
  color: #29252480;
}

.text-stone-800\/60 {
  color: #29252499;
}

.text-stone-800\/70 {
  color: #292524b3;
}

.text-stone-800\/75 {
  color: #292524bf;
}

.text-stone-800\/80 {
  color: #292524cc;
}

.text-stone-800\/90 {
  color: #292524e6;
}

.text-stone-800\/95 {
  color: #292524f2;
}

.text-stone-900 {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}

.text-stone-900\/0 {
  color: #1c191700;
}

.text-stone-900\/10 {
  color: #1c19171a;
}

.text-stone-900\/100 {
  color: #1c1917;
}

.text-stone-900\/20 {
  color: #1c191733;
}

.text-stone-900\/25 {
  color: #1c191740;
}

.text-stone-900\/30 {
  color: #1c19174d;
}

.text-stone-900\/40 {
  color: #1c191766;
}

.text-stone-900\/5 {
  color: #1c19170d;
}

.text-stone-900\/50 {
  color: #1c191780;
}

.text-stone-900\/60 {
  color: #1c191799;
}

.text-stone-900\/70 {
  color: #1c1917b3;
}

.text-stone-900\/75 {
  color: #1c1917bf;
}

.text-stone-900\/80 {
  color: #1c1917cc;
}

.text-stone-900\/90 {
  color: #1c1917e6;
}

.text-stone-900\/95 {
  color: #1c1917f2;
}

.text-stone-950 {
  --tw-text-opacity: 1;
  color: rgb(12 10 9 / var(--tw-text-opacity));
}

.text-stone-950\/0 {
  color: #0c0a0900;
}

.text-stone-950\/10 {
  color: #0c0a091a;
}

.text-stone-950\/100 {
  color: #0c0a09;
}

.text-stone-950\/20 {
  color: #0c0a0933;
}

.text-stone-950\/25 {
  color: #0c0a0940;
}

.text-stone-950\/30 {
  color: #0c0a094d;
}

.text-stone-950\/40 {
  color: #0c0a0966;
}

.text-stone-950\/5 {
  color: #0c0a090d;
}

.text-stone-950\/50 {
  color: #0c0a0980;
}

.text-stone-950\/60 {
  color: #0c0a0999;
}

.text-stone-950\/70 {
  color: #0c0a09b3;
}

.text-stone-950\/75 {
  color: #0c0a09bf;
}

.text-stone-950\/80 {
  color: #0c0a09cc;
}

.text-stone-950\/90 {
  color: #0c0a09e6;
}

.text-stone-950\/95 {
  color: #0c0a09f2;
}

.text-teal-100 {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity));
}

.text-teal-100\/0 {
  color: #ccfbf100;
}

.text-teal-100\/10 {
  color: #ccfbf11a;
}

.text-teal-100\/100 {
  color: #ccfbf1;
}

.text-teal-100\/20 {
  color: #ccfbf133;
}

.text-teal-100\/25 {
  color: #ccfbf140;
}

.text-teal-100\/30 {
  color: #ccfbf14d;
}

.text-teal-100\/40 {
  color: #ccfbf166;
}

.text-teal-100\/5 {
  color: #ccfbf10d;
}

.text-teal-100\/50 {
  color: #ccfbf180;
}

.text-teal-100\/60 {
  color: #ccfbf199;
}

.text-teal-100\/70 {
  color: #ccfbf1b3;
}

.text-teal-100\/75 {
  color: #ccfbf1bf;
}

.text-teal-100\/80 {
  color: #ccfbf1cc;
}

.text-teal-100\/90 {
  color: #ccfbf1e6;
}

.text-teal-100\/95 {
  color: #ccfbf1f2;
}

.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity));
}

.text-teal-200\/0 {
  color: #99f6e400;
}

.text-teal-200\/10 {
  color: #99f6e41a;
}

.text-teal-200\/100 {
  color: #99f6e4;
}

.text-teal-200\/20 {
  color: #99f6e433;
}

.text-teal-200\/25 {
  color: #99f6e440;
}

.text-teal-200\/30 {
  color: #99f6e44d;
}

.text-teal-200\/40 {
  color: #99f6e466;
}

.text-teal-200\/5 {
  color: #99f6e40d;
}

.text-teal-200\/50 {
  color: #99f6e480;
}

.text-teal-200\/60 {
  color: #99f6e499;
}

.text-teal-200\/70 {
  color: #99f6e4b3;
}

.text-teal-200\/75 {
  color: #99f6e4bf;
}

.text-teal-200\/80 {
  color: #99f6e4cc;
}

.text-teal-200\/90 {
  color: #99f6e4e6;
}

.text-teal-200\/95 {
  color: #99f6e4f2;
}

.text-teal-300 {
  --tw-text-opacity: 1;
  color: rgb(94 234 212 / var(--tw-text-opacity));
}

.text-teal-300\/0 {
  color: #5eead400;
}

.text-teal-300\/10 {
  color: #5eead41a;
}

.text-teal-300\/100 {
  color: #5eead4;
}

.text-teal-300\/20 {
  color: #5eead433;
}

.text-teal-300\/25 {
  color: #5eead440;
}

.text-teal-300\/30 {
  color: #5eead44d;
}

.text-teal-300\/40 {
  color: #5eead466;
}

.text-teal-300\/5 {
  color: #5eead40d;
}

.text-teal-300\/50 {
  color: #5eead480;
}

.text-teal-300\/60 {
  color: #5eead499;
}

.text-teal-300\/70 {
  color: #5eead4b3;
}

.text-teal-300\/75 {
  color: #5eead4bf;
}

.text-teal-300\/80 {
  color: #5eead4cc;
}

.text-teal-300\/90 {
  color: #5eead4e6;
}

.text-teal-300\/95 {
  color: #5eead4f2;
}

.text-teal-400 {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity));
}

.text-teal-400\/0 {
  color: #2dd4bf00;
}

.text-teal-400\/10 {
  color: #2dd4bf1a;
}

.text-teal-400\/100 {
  color: #2dd4bf;
}

.text-teal-400\/20 {
  color: #2dd4bf33;
}

.text-teal-400\/25 {
  color: #2dd4bf40;
}

.text-teal-400\/30 {
  color: #2dd4bf4d;
}

.text-teal-400\/40 {
  color: #2dd4bf66;
}

.text-teal-400\/5 {
  color: #2dd4bf0d;
}

.text-teal-400\/50 {
  color: #2dd4bf80;
}

.text-teal-400\/60 {
  color: #2dd4bf99;
}

.text-teal-400\/70 {
  color: #2dd4bfb3;
}

.text-teal-400\/75 {
  color: #2dd4bfbf;
}

.text-teal-400\/80 {
  color: #2dd4bfcc;
}

.text-teal-400\/90 {
  color: #2dd4bfe6;
}

.text-teal-400\/95 {
  color: #2dd4bff2;
}

.text-teal-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 250 / var(--tw-text-opacity));
}

.text-teal-50\/0 {
  color: #f0fdfa00;
}

.text-teal-50\/10 {
  color: #f0fdfa1a;
}

.text-teal-50\/100 {
  color: #f0fdfa;
}

.text-teal-50\/20 {
  color: #f0fdfa33;
}

.text-teal-50\/25 {
  color: #f0fdfa40;
}

.text-teal-50\/30 {
  color: #f0fdfa4d;
}

.text-teal-50\/40 {
  color: #f0fdfa66;
}

.text-teal-50\/5 {
  color: #f0fdfa0d;
}

.text-teal-50\/50 {
  color: #f0fdfa80;
}

.text-teal-50\/60 {
  color: #f0fdfa99;
}

.text-teal-50\/70 {
  color: #f0fdfab3;
}

.text-teal-50\/75 {
  color: #f0fdfabf;
}

.text-teal-50\/80 {
  color: #f0fdfacc;
}

.text-teal-50\/90 {
  color: #f0fdfae6;
}

.text-teal-50\/95 {
  color: #f0fdfaf2;
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-teal-500\/0 {
  color: #14b8a600;
}

.text-teal-500\/10 {
  color: #14b8a61a;
}

.text-teal-500\/100 {
  color: #14b8a6;
}

.text-teal-500\/20 {
  color: #14b8a633;
}

.text-teal-500\/25 {
  color: #14b8a640;
}

.text-teal-500\/30 {
  color: #14b8a64d;
}

.text-teal-500\/40 {
  color: #14b8a666;
}

.text-teal-500\/5 {
  color: #14b8a60d;
}

.text-teal-500\/50 {
  color: #14b8a680;
}

.text-teal-500\/60 {
  color: #14b8a699;
}

.text-teal-500\/70 {
  color: #14b8a6b3;
}

.text-teal-500\/75 {
  color: #14b8a6bf;
}

.text-teal-500\/80 {
  color: #14b8a6cc;
}

.text-teal-500\/90 {
  color: #14b8a6e6;
}

.text-teal-500\/95 {
  color: #14b8a6f2;
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

.text-teal-600\/0 {
  color: #0d948800;
}

.text-teal-600\/10 {
  color: #0d94881a;
}

.text-teal-600\/100 {
  color: #0d9488;
}

.text-teal-600\/20 {
  color: #0d948833;
}

.text-teal-600\/25 {
  color: #0d948840;
}

.text-teal-600\/30 {
  color: #0d94884d;
}

.text-teal-600\/40 {
  color: #0d948866;
}

.text-teal-600\/5 {
  color: #0d94880d;
}

.text-teal-600\/50 {
  color: #0d948880;
}

.text-teal-600\/60 {
  color: #0d948899;
}

.text-teal-600\/70 {
  color: #0d9488b3;
}

.text-teal-600\/75 {
  color: #0d9488bf;
}

.text-teal-600\/80 {
  color: #0d9488cc;
}

.text-teal-600\/90 {
  color: #0d9488e6;
}

.text-teal-600\/95 {
  color: #0d9488f2;
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

.text-teal-700\/0 {
  color: #0f766e00;
}

.text-teal-700\/10 {
  color: #0f766e1a;
}

.text-teal-700\/100 {
  color: #0f766e;
}

.text-teal-700\/20 {
  color: #0f766e33;
}

.text-teal-700\/25 {
  color: #0f766e40;
}

.text-teal-700\/30 {
  color: #0f766e4d;
}

.text-teal-700\/40 {
  color: #0f766e66;
}

.text-teal-700\/5 {
  color: #0f766e0d;
}

.text-teal-700\/50 {
  color: #0f766e80;
}

.text-teal-700\/60 {
  color: #0f766e99;
}

.text-teal-700\/70 {
  color: #0f766eb3;
}

.text-teal-700\/75 {
  color: #0f766ebf;
}

.text-teal-700\/80 {
  color: #0f766ecc;
}

.text-teal-700\/90 {
  color: #0f766ee6;
}

.text-teal-700\/95 {
  color: #0f766ef2;
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

.text-teal-800\/0 {
  color: #115e5900;
}

.text-teal-800\/10 {
  color: #115e591a;
}

.text-teal-800\/100 {
  color: #115e59;
}

.text-teal-800\/20 {
  color: #115e5933;
}

.text-teal-800\/25 {
  color: #115e5940;
}

.text-teal-800\/30 {
  color: #115e594d;
}

.text-teal-800\/40 {
  color: #115e5966;
}

.text-teal-800\/5 {
  color: #115e590d;
}

.text-teal-800\/50 {
  color: #115e5980;
}

.text-teal-800\/60 {
  color: #115e5999;
}

.text-teal-800\/70 {
  color: #115e59b3;
}

.text-teal-800\/75 {
  color: #115e59bf;
}

.text-teal-800\/80 {
  color: #115e59cc;
}

.text-teal-800\/90 {
  color: #115e59e6;
}

.text-teal-800\/95 {
  color: #115e59f2;
}

.text-teal-900 {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

.text-teal-900\/0 {
  color: #134e4a00;
}

.text-teal-900\/10 {
  color: #134e4a1a;
}

.text-teal-900\/100 {
  color: #134e4a;
}

.text-teal-900\/20 {
  color: #134e4a33;
}

.text-teal-900\/25 {
  color: #134e4a40;
}

.text-teal-900\/30 {
  color: #134e4a4d;
}

.text-teal-900\/40 {
  color: #134e4a66;
}

.text-teal-900\/5 {
  color: #134e4a0d;
}

.text-teal-900\/50 {
  color: #134e4a80;
}

.text-teal-900\/60 {
  color: #134e4a99;
}

.text-teal-900\/70 {
  color: #134e4ab3;
}

.text-teal-900\/75 {
  color: #134e4abf;
}

.text-teal-900\/80 {
  color: #134e4acc;
}

.text-teal-900\/90 {
  color: #134e4ae6;
}

.text-teal-900\/95 {
  color: #134e4af2;
}

.text-teal-950 {
  --tw-text-opacity: 1;
  color: rgb(4 47 46 / var(--tw-text-opacity));
}

.text-teal-950\/0 {
  color: #042f2e00;
}

.text-teal-950\/10 {
  color: #042f2e1a;
}

.text-teal-950\/100 {
  color: #042f2e;
}

.text-teal-950\/20 {
  color: #042f2e33;
}

.text-teal-950\/25 {
  color: #042f2e40;
}

.text-teal-950\/30 {
  color: #042f2e4d;
}

.text-teal-950\/40 {
  color: #042f2e66;
}

.text-teal-950\/5 {
  color: #042f2e0d;
}

.text-teal-950\/50 {
  color: #042f2e80;
}

.text-teal-950\/60 {
  color: #042f2e99;
}

.text-teal-950\/70 {
  color: #042f2eb3;
}

.text-teal-950\/75 {
  color: #042f2ebf;
}

.text-teal-950\/80 {
  color: #042f2ecc;
}

.text-teal-950\/90 {
  color: #042f2ee6;
}

.text-teal-950\/95 {
  color: #042f2ef2;
}

.text-transparent, .text-transparent\/0 {
  color: #0000;
}

.text-transparent\/10 {
  color: #0000001a;
}

.text-transparent\/100 {
  color: #000;
}

.text-transparent\/20 {
  color: #0003;
}

.text-transparent\/25 {
  color: #00000040;
}

.text-transparent\/30 {
  color: #0000004d;
}

.text-transparent\/40 {
  color: #0006;
}

.text-transparent\/5 {
  color: #0000000d;
}

.text-transparent\/50 {
  color: #00000080;
}

.text-transparent\/60 {
  color: #0009;
}

.text-transparent\/70 {
  color: #000000b3;
}

.text-transparent\/75 {
  color: #000000bf;
}

.text-transparent\/80 {
  color: #000c;
}

.text-transparent\/90 {
  color: #000000e6;
}

.text-transparent\/95 {
  color: #000000f2;
}

.text-violet-100 {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity));
}

.text-violet-100\/0 {
  color: #ede9fe00;
}

.text-violet-100\/10 {
  color: #ede9fe1a;
}

.text-violet-100\/100 {
  color: #ede9fe;
}

.text-violet-100\/20 {
  color: #ede9fe33;
}

.text-violet-100\/25 {
  color: #ede9fe40;
}

.text-violet-100\/30 {
  color: #ede9fe4d;
}

.text-violet-100\/40 {
  color: #ede9fe66;
}

.text-violet-100\/5 {
  color: #ede9fe0d;
}

.text-violet-100\/50 {
  color: #ede9fe80;
}

.text-violet-100\/60 {
  color: #ede9fe99;
}

.text-violet-100\/70 {
  color: #ede9feb3;
}

.text-violet-100\/75 {
  color: #ede9febf;
}

.text-violet-100\/80 {
  color: #ede9fecc;
}

.text-violet-100\/90 {
  color: #ede9fee6;
}

.text-violet-100\/95 {
  color: #ede9fef2;
}

.text-violet-200 {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

.text-violet-200\/0 {
  color: #ddd6fe00;
}

.text-violet-200\/10 {
  color: #ddd6fe1a;
}

.text-violet-200\/100 {
  color: #ddd6fe;
}

.text-violet-200\/20 {
  color: #ddd6fe33;
}

.text-violet-200\/25 {
  color: #ddd6fe40;
}

.text-violet-200\/30 {
  color: #ddd6fe4d;
}

.text-violet-200\/40 {
  color: #ddd6fe66;
}

.text-violet-200\/5 {
  color: #ddd6fe0d;
}

.text-violet-200\/50 {
  color: #ddd6fe80;
}

.text-violet-200\/60 {
  color: #ddd6fe99;
}

.text-violet-200\/70 {
  color: #ddd6feb3;
}

.text-violet-200\/75 {
  color: #ddd6febf;
}

.text-violet-200\/80 {
  color: #ddd6fecc;
}

.text-violet-200\/90 {
  color: #ddd6fee6;
}

.text-violet-200\/95 {
  color: #ddd6fef2;
}

.text-violet-300 {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

.text-violet-300\/0 {
  color: #c4b5fd00;
}

.text-violet-300\/10 {
  color: #c4b5fd1a;
}

.text-violet-300\/100 {
  color: #c4b5fd;
}

.text-violet-300\/20 {
  color: #c4b5fd33;
}

.text-violet-300\/25 {
  color: #c4b5fd40;
}

.text-violet-300\/30 {
  color: #c4b5fd4d;
}

.text-violet-300\/40 {
  color: #c4b5fd66;
}

.text-violet-300\/5 {
  color: #c4b5fd0d;
}

.text-violet-300\/50 {
  color: #c4b5fd80;
}

.text-violet-300\/60 {
  color: #c4b5fd99;
}

.text-violet-300\/70 {
  color: #c4b5fdb3;
}

.text-violet-300\/75 {
  color: #c4b5fdbf;
}

.text-violet-300\/80 {
  color: #c4b5fdcc;
}

.text-violet-300\/90 {
  color: #c4b5fde6;
}

.text-violet-300\/95 {
  color: #c4b5fdf2;
}

.text-violet-400 {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

.text-violet-400\/0 {
  color: #a78bfa00;
}

.text-violet-400\/10 {
  color: #a78bfa1a;
}

.text-violet-400\/100 {
  color: #a78bfa;
}

.text-violet-400\/20 {
  color: #a78bfa33;
}

.text-violet-400\/25 {
  color: #a78bfa40;
}

.text-violet-400\/30 {
  color: #a78bfa4d;
}

.text-violet-400\/40 {
  color: #a78bfa66;
}

.text-violet-400\/5 {
  color: #a78bfa0d;
}

.text-violet-400\/50 {
  color: #a78bfa80;
}

.text-violet-400\/60 {
  color: #a78bfa99;
}

.text-violet-400\/70 {
  color: #a78bfab3;
}

.text-violet-400\/75 {
  color: #a78bfabf;
}

.text-violet-400\/80 {
  color: #a78bfacc;
}

.text-violet-400\/90 {
  color: #a78bfae6;
}

.text-violet-400\/95 {
  color: #a78bfaf2;
}

.text-violet-50 {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

.text-violet-50\/0 {
  color: #f5f3ff00;
}

.text-violet-50\/10 {
  color: #f5f3ff1a;
}

.text-violet-50\/100 {
  color: #f5f3ff;
}

.text-violet-50\/20 {
  color: #f5f3ff33;
}

.text-violet-50\/25 {
  color: #f5f3ff40;
}

.text-violet-50\/30 {
  color: #f5f3ff4d;
}

.text-violet-50\/40 {
  color: #f5f3ff66;
}

.text-violet-50\/5 {
  color: #f5f3ff0d;
}

.text-violet-50\/50 {
  color: #f5f3ff80;
}

.text-violet-50\/60 {
  color: #f5f3ff99;
}

.text-violet-50\/70 {
  color: #f5f3ffb3;
}

.text-violet-50\/75 {
  color: #f5f3ffbf;
}

.text-violet-50\/80 {
  color: #f5f3ffcc;
}

.text-violet-50\/90 {
  color: #f5f3ffe6;
}

.text-violet-50\/95 {
  color: #f5f3fff2;
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.text-violet-500\/0 {
  color: #8b5cf600;
}

.text-violet-500\/10 {
  color: #8b5cf61a;
}

.text-violet-500\/100 {
  color: #8b5cf6;
}

.text-violet-500\/20 {
  color: #8b5cf633;
}

.text-violet-500\/25 {
  color: #8b5cf640;
}

.text-violet-500\/30 {
  color: #8b5cf64d;
}

.text-violet-500\/40 {
  color: #8b5cf666;
}

.text-violet-500\/5 {
  color: #8b5cf60d;
}

.text-violet-500\/50 {
  color: #8b5cf680;
}

.text-violet-500\/60 {
  color: #8b5cf699;
}

.text-violet-500\/70 {
  color: #8b5cf6b3;
}

.text-violet-500\/75 {
  color: #8b5cf6bf;
}

.text-violet-500\/80 {
  color: #8b5cf6cc;
}

.text-violet-500\/90 {
  color: #8b5cf6e6;
}

.text-violet-500\/95 {
  color: #8b5cf6f2;
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.text-violet-600\/0 {
  color: #7c3aed00;
}

.text-violet-600\/10 {
  color: #7c3aed1a;
}

.text-violet-600\/100 {
  color: #7c3aed;
}

.text-violet-600\/20 {
  color: #7c3aed33;
}

.text-violet-600\/25 {
  color: #7c3aed40;
}

.text-violet-600\/30 {
  color: #7c3aed4d;
}

.text-violet-600\/40 {
  color: #7c3aed66;
}

.text-violet-600\/5 {
  color: #7c3aed0d;
}

.text-violet-600\/50 {
  color: #7c3aed80;
}

.text-violet-600\/60 {
  color: #7c3aed99;
}

.text-violet-600\/70 {
  color: #7c3aedb3;
}

.text-violet-600\/75 {
  color: #7c3aedbf;
}

.text-violet-600\/80 {
  color: #7c3aedcc;
}

.text-violet-600\/90 {
  color: #7c3aede6;
}

.text-violet-600\/95 {
  color: #7c3aedf2;
}

.text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.text-violet-700\/0 {
  color: #6d28d900;
}

.text-violet-700\/10 {
  color: #6d28d91a;
}

.text-violet-700\/100 {
  color: #6d28d9;
}

.text-violet-700\/20 {
  color: #6d28d933;
}

.text-violet-700\/25 {
  color: #6d28d940;
}

.text-violet-700\/30 {
  color: #6d28d94d;
}

.text-violet-700\/40 {
  color: #6d28d966;
}

.text-violet-700\/5 {
  color: #6d28d90d;
}

.text-violet-700\/50 {
  color: #6d28d980;
}

.text-violet-700\/60 {
  color: #6d28d999;
}

.text-violet-700\/70 {
  color: #6d28d9b3;
}

.text-violet-700\/75 {
  color: #6d28d9bf;
}

.text-violet-700\/80 {
  color: #6d28d9cc;
}

.text-violet-700\/90 {
  color: #6d28d9e6;
}

.text-violet-700\/95 {
  color: #6d28d9f2;
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.text-violet-800\/0 {
  color: #5b21b600;
}

.text-violet-800\/10 {
  color: #5b21b61a;
}

.text-violet-800\/100 {
  color: #5b21b6;
}

.text-violet-800\/20 {
  color: #5b21b633;
}

.text-violet-800\/25 {
  color: #5b21b640;
}

.text-violet-800\/30 {
  color: #5b21b64d;
}

.text-violet-800\/40 {
  color: #5b21b666;
}

.text-violet-800\/5 {
  color: #5b21b60d;
}

.text-violet-800\/50 {
  color: #5b21b680;
}

.text-violet-800\/60 {
  color: #5b21b699;
}

.text-violet-800\/70 {
  color: #5b21b6b3;
}

.text-violet-800\/75 {
  color: #5b21b6bf;
}

.text-violet-800\/80 {
  color: #5b21b6cc;
}

.text-violet-800\/90 {
  color: #5b21b6e6;
}

.text-violet-800\/95 {
  color: #5b21b6f2;
}

.text-violet-900 {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.text-violet-900\/0 {
  color: #4c1d9500;
}

.text-violet-900\/10 {
  color: #4c1d951a;
}

.text-violet-900\/100 {
  color: #4c1d95;
}

.text-violet-900\/20 {
  color: #4c1d9533;
}

.text-violet-900\/25 {
  color: #4c1d9540;
}

.text-violet-900\/30 {
  color: #4c1d954d;
}

.text-violet-900\/40 {
  color: #4c1d9566;
}

.text-violet-900\/5 {
  color: #4c1d950d;
}

.text-violet-900\/50 {
  color: #4c1d9580;
}

.text-violet-900\/60 {
  color: #4c1d9599;
}

.text-violet-900\/70 {
  color: #4c1d95b3;
}

.text-violet-900\/75 {
  color: #4c1d95bf;
}

.text-violet-900\/80 {
  color: #4c1d95cc;
}

.text-violet-900\/90 {
  color: #4c1d95e6;
}

.text-violet-900\/95 {
  color: #4c1d95f2;
}

.text-violet-950 {
  --tw-text-opacity: 1;
  color: rgb(46 16 101 / var(--tw-text-opacity));
}

.text-violet-950\/0 {
  color: #2e106500;
}

.text-violet-950\/10 {
  color: #2e10651a;
}

.text-violet-950\/100 {
  color: #2e1065;
}

.text-violet-950\/20 {
  color: #2e106533;
}

.text-violet-950\/25 {
  color: #2e106540;
}

.text-violet-950\/30 {
  color: #2e10654d;
}

.text-violet-950\/40 {
  color: #2e106566;
}

.text-violet-950\/5 {
  color: #2e10650d;
}

.text-violet-950\/50 {
  color: #2e106580;
}

.text-violet-950\/60 {
  color: #2e106599;
}

.text-violet-950\/70 {
  color: #2e1065b3;
}

.text-violet-950\/75 {
  color: #2e1065bf;
}

.text-violet-950\/80 {
  color: #2e1065cc;
}

.text-violet-950\/90 {
  color: #2e1065e6;
}

.text-violet-950\/95 {
  color: #2e1065f2;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/0 {
  color: #fff0;
}

.text-white\/10 {
  color: #ffffff1a;
}

.text-white\/100 {
  color: #fff;
}

.text-white\/20 {
  color: #fff3;
}

.text-white\/25 {
  color: #ffffff40;
}

.text-white\/30 {
  color: #ffffff4d;
}

.text-white\/40 {
  color: #fff6;
}

.text-white\/5 {
  color: #ffffff0d;
}

.text-white\/50 {
  color: #ffffff80;
}

.text-white\/60 {
  color: #fff9;
}

.text-white\/70 {
  color: #ffffffb3;
}

.text-white\/75 {
  color: #ffffffbf;
}

.text-white\/80 {
  color: #fffc;
}

.text-white\/90 {
  color: #ffffffe6;
}

.text-white\/95 {
  color: #fffffff2;
}

.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgb(254 249 195 / var(--tw-text-opacity));
}

.text-yellow-100\/0 {
  color: #fef9c300;
}

.text-yellow-100\/10 {
  color: #fef9c31a;
}

.text-yellow-100\/100 {
  color: #fef9c3;
}

.text-yellow-100\/20 {
  color: #fef9c333;
}

.text-yellow-100\/25 {
  color: #fef9c340;
}

.text-yellow-100\/30 {
  color: #fef9c34d;
}

.text-yellow-100\/40 {
  color: #fef9c366;
}

.text-yellow-100\/5 {
  color: #fef9c30d;
}

.text-yellow-100\/50 {
  color: #fef9c380;
}

.text-yellow-100\/60 {
  color: #fef9c399;
}

.text-yellow-100\/70 {
  color: #fef9c3b3;
}

.text-yellow-100\/75 {
  color: #fef9c3bf;
}

.text-yellow-100\/80 {
  color: #fef9c3cc;
}

.text-yellow-100\/90 {
  color: #fef9c3e6;
}

.text-yellow-100\/95 {
  color: #fef9c3f2;
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(254 240 138 / var(--tw-text-opacity));
}

.text-yellow-200\/0 {
  color: #fef08a00;
}

.text-yellow-200\/10 {
  color: #fef08a1a;
}

.text-yellow-200\/100 {
  color: #fef08a;
}

.text-yellow-200\/20 {
  color: #fef08a33;
}

.text-yellow-200\/25 {
  color: #fef08a40;
}

.text-yellow-200\/30 {
  color: #fef08a4d;
}

.text-yellow-200\/40 {
  color: #fef08a66;
}

.text-yellow-200\/5 {
  color: #fef08a0d;
}

.text-yellow-200\/50 {
  color: #fef08a80;
}

.text-yellow-200\/60 {
  color: #fef08a99;
}

.text-yellow-200\/70 {
  color: #fef08ab3;
}

.text-yellow-200\/75 {
  color: #fef08abf;
}

.text-yellow-200\/80 {
  color: #fef08acc;
}

.text-yellow-200\/90 {
  color: #fef08ae6;
}

.text-yellow-200\/95 {
  color: #fef08af2;
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.text-yellow-300\/0 {
  color: #fde04700;
}

.text-yellow-300\/10 {
  color: #fde0471a;
}

.text-yellow-300\/100 {
  color: #fde047;
}

.text-yellow-300\/20 {
  color: #fde04733;
}

.text-yellow-300\/25 {
  color: #fde04740;
}

.text-yellow-300\/30 {
  color: #fde0474d;
}

.text-yellow-300\/40 {
  color: #fde04766;
}

.text-yellow-300\/5 {
  color: #fde0470d;
}

.text-yellow-300\/50 {
  color: #fde04780;
}

.text-yellow-300\/60 {
  color: #fde04799;
}

.text-yellow-300\/70 {
  color: #fde047b3;
}

.text-yellow-300\/75 {
  color: #fde047bf;
}

.text-yellow-300\/80 {
  color: #fde047cc;
}

.text-yellow-300\/90 {
  color: #fde047e6;
}

.text-yellow-300\/95 {
  color: #fde047f2;
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}

.text-yellow-400\/0 {
  color: #facc1500;
}

.text-yellow-400\/10 {
  color: #facc151a;
}

.text-yellow-400\/100 {
  color: #facc15;
}

.text-yellow-400\/20 {
  color: #facc1533;
}

.text-yellow-400\/25 {
  color: #facc1540;
}

.text-yellow-400\/30 {
  color: #facc154d;
}

.text-yellow-400\/40 {
  color: #facc1566;
}

.text-yellow-400\/5 {
  color: #facc150d;
}

.text-yellow-400\/50 {
  color: #facc1580;
}

.text-yellow-400\/60 {
  color: #facc1599;
}

.text-yellow-400\/70 {
  color: #facc15b3;
}

.text-yellow-400\/75 {
  color: #facc15bf;
}

.text-yellow-400\/80 {
  color: #facc15cc;
}

.text-yellow-400\/90 {
  color: #facc15e6;
}

.text-yellow-400\/95 {
  color: #facc15f2;
}

.text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgb(254 252 232 / var(--tw-text-opacity));
}

.text-yellow-50\/0 {
  color: #fefce800;
}

.text-yellow-50\/10 {
  color: #fefce81a;
}

.text-yellow-50\/100 {
  color: #fefce8;
}

.text-yellow-50\/20 {
  color: #fefce833;
}

.text-yellow-50\/25 {
  color: #fefce840;
}

.text-yellow-50\/30 {
  color: #fefce84d;
}

.text-yellow-50\/40 {
  color: #fefce866;
}

.text-yellow-50\/5 {
  color: #fefce80d;
}

.text-yellow-50\/50 {
  color: #fefce880;
}

.text-yellow-50\/60 {
  color: #fefce899;
}

.text-yellow-50\/70 {
  color: #fefce8b3;
}

.text-yellow-50\/75 {
  color: #fefce8bf;
}

.text-yellow-50\/80 {
  color: #fefce8cc;
}

.text-yellow-50\/90 {
  color: #fefce8e6;
}

.text-yellow-50\/95 {
  color: #fefce8f2;
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.text-yellow-500\/0 {
  color: #eab30800;
}

.text-yellow-500\/10 {
  color: #eab3081a;
}

.text-yellow-500\/100 {
  color: #eab308;
}

.text-yellow-500\/20 {
  color: #eab30833;
}

.text-yellow-500\/25 {
  color: #eab30840;
}

.text-yellow-500\/30 {
  color: #eab3084d;
}

.text-yellow-500\/40 {
  color: #eab30866;
}

.text-yellow-500\/5 {
  color: #eab3080d;
}

.text-yellow-500\/50 {
  color: #eab30880;
}

.text-yellow-500\/60 {
  color: #eab30899;
}

.text-yellow-500\/70 {
  color: #eab308b3;
}

.text-yellow-500\/75 {
  color: #eab308bf;
}

.text-yellow-500\/80 {
  color: #eab308cc;
}

.text-yellow-500\/90 {
  color: #eab308e6;
}

.text-yellow-500\/95 {
  color: #eab308f2;
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity));
}

.text-yellow-600\/0 {
  color: #ca8a0400;
}

.text-yellow-600\/10 {
  color: #ca8a041a;
}

.text-yellow-600\/100 {
  color: #ca8a04;
}

.text-yellow-600\/20 {
  color: #ca8a0433;
}

.text-yellow-600\/25 {
  color: #ca8a0440;
}

.text-yellow-600\/30 {
  color: #ca8a044d;
}

.text-yellow-600\/40 {
  color: #ca8a0466;
}

.text-yellow-600\/5 {
  color: #ca8a040d;
}

.text-yellow-600\/50 {
  color: #ca8a0480;
}

.text-yellow-600\/60 {
  color: #ca8a0499;
}

.text-yellow-600\/70 {
  color: #ca8a04b3;
}

.text-yellow-600\/75 {
  color: #ca8a04bf;
}

.text-yellow-600\/80 {
  color: #ca8a04cc;
}

.text-yellow-600\/90 {
  color: #ca8a04e6;
}

.text-yellow-600\/95 {
  color: #ca8a04f2;
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity));
}

.text-yellow-700\/0 {
  color: #a1620700;
}

.text-yellow-700\/10 {
  color: #a162071a;
}

.text-yellow-700\/100 {
  color: #a16207;
}

.text-yellow-700\/20 {
  color: #a1620733;
}

.text-yellow-700\/25 {
  color: #a1620740;
}

.text-yellow-700\/30 {
  color: #a162074d;
}

.text-yellow-700\/40 {
  color: #a1620766;
}

.text-yellow-700\/5 {
  color: #a162070d;
}

.text-yellow-700\/50 {
  color: #a1620780;
}

.text-yellow-700\/60 {
  color: #a1620799;
}

.text-yellow-700\/70 {
  color: #a16207b3;
}

.text-yellow-700\/75 {
  color: #a16207bf;
}

.text-yellow-700\/80 {
  color: #a16207cc;
}

.text-yellow-700\/90 {
  color: #a16207e6;
}

.text-yellow-700\/95 {
  color: #a16207f2;
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.text-yellow-800\/0 {
  color: #854d0e00;
}

.text-yellow-800\/10 {
  color: #854d0e1a;
}

.text-yellow-800\/100 {
  color: #854d0e;
}

.text-yellow-800\/20 {
  color: #854d0e33;
}

.text-yellow-800\/25 {
  color: #854d0e40;
}

.text-yellow-800\/30 {
  color: #854d0e4d;
}

.text-yellow-800\/40 {
  color: #854d0e66;
}

.text-yellow-800\/5 {
  color: #854d0e0d;
}

.text-yellow-800\/50 {
  color: #854d0e80;
}

.text-yellow-800\/60 {
  color: #854d0e99;
}

.text-yellow-800\/70 {
  color: #854d0eb3;
}

.text-yellow-800\/75 {
  color: #854d0ebf;
}

.text-yellow-800\/80 {
  color: #854d0ecc;
}

.text-yellow-800\/90 {
  color: #854d0ee6;
}

.text-yellow-800\/95 {
  color: #854d0ef2;
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(113 63 18 / var(--tw-text-opacity));
}

.text-yellow-900\/0 {
  color: #713f1200;
}

.text-yellow-900\/10 {
  color: #713f121a;
}

.text-yellow-900\/100 {
  color: #713f12;
}

.text-yellow-900\/20 {
  color: #713f1233;
}

.text-yellow-900\/25 {
  color: #713f1240;
}

.text-yellow-900\/30 {
  color: #713f124d;
}

.text-yellow-900\/40 {
  color: #713f1266;
}

.text-yellow-900\/5 {
  color: #713f120d;
}

.text-yellow-900\/50 {
  color: #713f1280;
}

.text-yellow-900\/60 {
  color: #713f1299;
}

.text-yellow-900\/70 {
  color: #713f12b3;
}

.text-yellow-900\/75 {
  color: #713f12bf;
}

.text-yellow-900\/80 {
  color: #713f12cc;
}

.text-yellow-900\/90 {
  color: #713f12e6;
}

.text-yellow-900\/95 {
  color: #713f12f2;
}

.text-yellow-950 {
  --tw-text-opacity: 1;
  color: rgb(66 32 6 / var(--tw-text-opacity));
}

.text-yellow-950\/0 {
  color: #42200600;
}

.text-yellow-950\/10 {
  color: #4220061a;
}

.text-yellow-950\/100 {
  color: #422006;
}

.text-yellow-950\/20 {
  color: #42200633;
}

.text-yellow-950\/25 {
  color: #42200640;
}

.text-yellow-950\/30 {
  color: #4220064d;
}

.text-yellow-950\/40 {
  color: #42200666;
}

.text-yellow-950\/5 {
  color: #4220060d;
}

.text-yellow-950\/50 {
  color: #42200680;
}

.text-yellow-950\/60 {
  color: #42200699;
}

.text-yellow-950\/70 {
  color: #422006b3;
}

.text-yellow-950\/75 {
  color: #422006bf;
}

.text-yellow-950\/80 {
  color: #422006cc;
}

.text-yellow-950\/90 {
  color: #422006e6;
}

.text-yellow-950\/95 {
  color: #422006f2;
}

.text-zinc-100 {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}

.text-zinc-100\/0 {
  color: #f4f4f500;
}

.text-zinc-100\/10 {
  color: #f4f4f51a;
}

.text-zinc-100\/100 {
  color: #f4f4f5;
}

.text-zinc-100\/20 {
  color: #f4f4f533;
}

.text-zinc-100\/25 {
  color: #f4f4f540;
}

.text-zinc-100\/30 {
  color: #f4f4f54d;
}

.text-zinc-100\/40 {
  color: #f4f4f566;
}

.text-zinc-100\/5 {
  color: #f4f4f50d;
}

.text-zinc-100\/50 {
  color: #f4f4f580;
}

.text-zinc-100\/60 {
  color: #f4f4f599;
}

.text-zinc-100\/70 {
  color: #f4f4f5b3;
}

.text-zinc-100\/75 {
  color: #f4f4f5bf;
}

.text-zinc-100\/80 {
  color: #f4f4f5cc;
}

.text-zinc-100\/90 {
  color: #f4f4f5e6;
}

.text-zinc-100\/95 {
  color: #f4f4f5f2;
}

.text-zinc-200 {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}

.text-zinc-200\/0 {
  color: #e4e4e700;
}

.text-zinc-200\/10 {
  color: #e4e4e71a;
}

.text-zinc-200\/100 {
  color: #e4e4e7;
}

.text-zinc-200\/20 {
  color: #e4e4e733;
}

.text-zinc-200\/25 {
  color: #e4e4e740;
}

.text-zinc-200\/30 {
  color: #e4e4e74d;
}

.text-zinc-200\/40 {
  color: #e4e4e766;
}

.text-zinc-200\/5 {
  color: #e4e4e70d;
}

.text-zinc-200\/50 {
  color: #e4e4e780;
}

.text-zinc-200\/60 {
  color: #e4e4e799;
}

.text-zinc-200\/70 {
  color: #e4e4e7b3;
}

.text-zinc-200\/75 {
  color: #e4e4e7bf;
}

.text-zinc-200\/80 {
  color: #e4e4e7cc;
}

.text-zinc-200\/90 {
  color: #e4e4e7e6;
}

.text-zinc-200\/95 {
  color: #e4e4e7f2;
}

.text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.text-zinc-300\/0 {
  color: #d4d4d800;
}

.text-zinc-300\/10 {
  color: #d4d4d81a;
}

.text-zinc-300\/100 {
  color: #d4d4d8;
}

.text-zinc-300\/20 {
  color: #d4d4d833;
}

.text-zinc-300\/25 {
  color: #d4d4d840;
}

.text-zinc-300\/30 {
  color: #d4d4d84d;
}

.text-zinc-300\/40 {
  color: #d4d4d866;
}

.text-zinc-300\/5 {
  color: #d4d4d80d;
}

.text-zinc-300\/50 {
  color: #d4d4d880;
}

.text-zinc-300\/60 {
  color: #d4d4d899;
}

.text-zinc-300\/70 {
  color: #d4d4d8b3;
}

.text-zinc-300\/75 {
  color: #d4d4d8bf;
}

.text-zinc-300\/80 {
  color: #d4d4d8cc;
}

.text-zinc-300\/90 {
  color: #d4d4d8e6;
}

.text-zinc-300\/95 {
  color: #d4d4d8f2;
}

.text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.text-zinc-400\/0 {
  color: #a1a1aa00;
}

.text-zinc-400\/10 {
  color: #a1a1aa1a;
}

.text-zinc-400\/100 {
  color: #a1a1aa;
}

.text-zinc-400\/20 {
  color: #a1a1aa33;
}

.text-zinc-400\/25 {
  color: #a1a1aa40;
}

.text-zinc-400\/30 {
  color: #a1a1aa4d;
}

.text-zinc-400\/40 {
  color: #a1a1aa66;
}

.text-zinc-400\/5 {
  color: #a1a1aa0d;
}

.text-zinc-400\/50 {
  color: #a1a1aa80;
}

.text-zinc-400\/60 {
  color: #a1a1aa99;
}

.text-zinc-400\/70 {
  color: #a1a1aab3;
}

.text-zinc-400\/75 {
  color: #a1a1aabf;
}

.text-zinc-400\/80 {
  color: #a1a1aacc;
}

.text-zinc-400\/90 {
  color: #a1a1aae6;
}

.text-zinc-400\/95 {
  color: #a1a1aaf2;
}

.text-zinc-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-zinc-50\/0 {
  color: #fafafa00;
}

.text-zinc-50\/10 {
  color: #fafafa1a;
}

.text-zinc-50\/100 {
  color: #fafafa;
}

.text-zinc-50\/20 {
  color: #fafafa33;
}

.text-zinc-50\/25 {
  color: #fafafa40;
}

.text-zinc-50\/30 {
  color: #fafafa4d;
}

.text-zinc-50\/40 {
  color: #fafafa66;
}

.text-zinc-50\/5 {
  color: #fafafa0d;
}

.text-zinc-50\/50 {
  color: #fafafa80;
}

.text-zinc-50\/60 {
  color: #fafafa99;
}

.text-zinc-50\/70 {
  color: #fafafab3;
}

.text-zinc-50\/75 {
  color: #fafafabf;
}

.text-zinc-50\/80 {
  color: #fafafacc;
}

.text-zinc-50\/90 {
  color: #fafafae6;
}

.text-zinc-50\/95 {
  color: #fafafaf2;
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.text-zinc-500\/0 {
  color: #71717a00;
}

.text-zinc-500\/10 {
  color: #71717a1a;
}

.text-zinc-500\/100 {
  color: #71717a;
}

.text-zinc-500\/20 {
  color: #71717a33;
}

.text-zinc-500\/25 {
  color: #71717a40;
}

.text-zinc-500\/30 {
  color: #71717a4d;
}

.text-zinc-500\/40 {
  color: #71717a66;
}

.text-zinc-500\/5 {
  color: #71717a0d;
}

.text-zinc-500\/50 {
  color: #71717a80;
}

.text-zinc-500\/60 {
  color: #71717a99;
}

.text-zinc-500\/70 {
  color: #71717ab3;
}

.text-zinc-500\/75 {
  color: #71717abf;
}

.text-zinc-500\/80 {
  color: #71717acc;
}

.text-zinc-500\/90 {
  color: #71717ae6;
}

.text-zinc-500\/95 {
  color: #71717af2;
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.text-zinc-600\/0 {
  color: #52525b00;
}

.text-zinc-600\/10 {
  color: #52525b1a;
}

.text-zinc-600\/100 {
  color: #52525b;
}

.text-zinc-600\/20 {
  color: #52525b33;
}

.text-zinc-600\/25 {
  color: #52525b40;
}

.text-zinc-600\/30 {
  color: #52525b4d;
}

.text-zinc-600\/40 {
  color: #52525b66;
}

.text-zinc-600\/5 {
  color: #52525b0d;
}

.text-zinc-600\/50 {
  color: #52525b80;
}

.text-zinc-600\/60 {
  color: #52525b99;
}

.text-zinc-600\/70 {
  color: #52525bb3;
}

.text-zinc-600\/75 {
  color: #52525bbf;
}

.text-zinc-600\/80 {
  color: #52525bcc;
}

.text-zinc-600\/90 {
  color: #52525be6;
}

.text-zinc-600\/95 {
  color: #52525bf2;
}

.text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-zinc-700\/0 {
  color: #3f3f4600;
}

.text-zinc-700\/10 {
  color: #3f3f461a;
}

.text-zinc-700\/100 {
  color: #3f3f46;
}

.text-zinc-700\/20 {
  color: #3f3f4633;
}

.text-zinc-700\/25 {
  color: #3f3f4640;
}

.text-zinc-700\/30 {
  color: #3f3f464d;
}

.text-zinc-700\/40 {
  color: #3f3f4666;
}

.text-zinc-700\/5 {
  color: #3f3f460d;
}

.text-zinc-700\/50 {
  color: #3f3f4680;
}

.text-zinc-700\/60 {
  color: #3f3f4699;
}

.text-zinc-700\/70 {
  color: #3f3f46b3;
}

.text-zinc-700\/75 {
  color: #3f3f46bf;
}

.text-zinc-700\/80 {
  color: #3f3f46cc;
}

.text-zinc-700\/90 {
  color: #3f3f46e6;
}

.text-zinc-700\/95 {
  color: #3f3f46f2;
}

.text-zinc-800 {
  --tw-text-opacity: 1;
  color: rgb(39 39 42 / var(--tw-text-opacity));
}

.text-zinc-800\/0 {
  color: #27272a00;
}

.text-zinc-800\/10 {
  color: #27272a1a;
}

.text-zinc-800\/100 {
  color: #27272a;
}

.text-zinc-800\/20 {
  color: #27272a33;
}

.text-zinc-800\/25 {
  color: #27272a40;
}

.text-zinc-800\/30 {
  color: #27272a4d;
}

.text-zinc-800\/40 {
  color: #27272a66;
}

.text-zinc-800\/5 {
  color: #27272a0d;
}

.text-zinc-800\/50 {
  color: #27272a80;
}

.text-zinc-800\/60 {
  color: #27272a99;
}

.text-zinc-800\/70 {
  color: #27272ab3;
}

.text-zinc-800\/75 {
  color: #27272abf;
}

.text-zinc-800\/80 {
  color: #27272acc;
}

.text-zinc-800\/90 {
  color: #27272ae6;
}

.text-zinc-800\/95 {
  color: #27272af2;
}

.text-zinc-900 {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.text-zinc-900\/0 {
  color: #18181b00;
}

.text-zinc-900\/10 {
  color: #18181b1a;
}

.text-zinc-900\/100 {
  color: #18181b;
}

.text-zinc-900\/20 {
  color: #18181b33;
}

.text-zinc-900\/25 {
  color: #18181b40;
}

.text-zinc-900\/30 {
  color: #18181b4d;
}

.text-zinc-900\/40 {
  color: #18181b66;
}

.text-zinc-900\/5 {
  color: #18181b0d;
}

.text-zinc-900\/50 {
  color: #18181b80;
}

.text-zinc-900\/60 {
  color: #18181b99;
}

.text-zinc-900\/70 {
  color: #18181bb3;
}

.text-zinc-900\/75 {
  color: #18181bbf;
}

.text-zinc-900\/80 {
  color: #18181bcc;
}

.text-zinc-900\/90 {
  color: #18181be6;
}

.text-zinc-900\/95 {
  color: #18181bf2;
}

.text-zinc-950 {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity));
}

.text-zinc-950\/0 {
  color: #09090b00;
}

.text-zinc-950\/10 {
  color: #09090b1a;
}

.text-zinc-950\/100 {
  color: #09090b;
}

.text-zinc-950\/20 {
  color: #09090b33;
}

.text-zinc-950\/25 {
  color: #09090b40;
}

.text-zinc-950\/30 {
  color: #09090b4d;
}

.text-zinc-950\/40 {
  color: #09090b66;
}

.text-zinc-950\/5 {
  color: #09090b0d;
}

.text-zinc-950\/50 {
  color: #09090b80;
}

.text-zinc-950\/60 {
  color: #09090b99;
}

.text-zinc-950\/70 {
  color: #09090bb3;
}

.text-zinc-950\/75 {
  color: #09090bbf;
}

.text-zinc-950\/80 {
  color: #09090bcc;
}

.text-zinc-950\/90 {
  color: #09090be6;
}

.text-zinc-950\/95 {
  color: #09090bf2;
}

.text-opacity-0 {
  --tw-text-opacity: 0;
}

.text-opacity-10 {
  --tw-text-opacity: .1;
}

.text-opacity-100 {
  --tw-text-opacity: 1;
}

.text-opacity-20 {
  --tw-text-opacity: .2;
}

.text-opacity-25 {
  --tw-text-opacity: .25;
}

.text-opacity-30 {
  --tw-text-opacity: .3;
}

.text-opacity-40 {
  --tw-text-opacity: .4;
}

.text-opacity-5 {
  --tw-text-opacity: .05;
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.text-opacity-60 {
  --tw-text-opacity: .6;
}

.text-opacity-70 {
  --tw-text-opacity: .7;
}

.text-opacity-75 {
  --tw-text-opacity: .75;
}

.text-opacity-80 {
  --tw-text-opacity: .8;
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.text-opacity-95 {
  --tw-text-opacity: .95;
}

.bg-blend-normal {
  background-blend-mode: normal;
}

.bg-blend-multiply {
  background-blend-mode: multiply;
}

.bg-blend-screen {
  background-blend-mode: screen;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.bg-blend-darken {
  background-blend-mode: darken;
}

.bg-blend-lighten {
  background-blend-mode: lighten;
}

.bg-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.bg-blend-color-burn {
  background-blend-mode: color-burn;
}

.bg-blend-hard-light {
  background-blend-mode: hard-light;
}

.bg-blend-soft-light {
  background-blend-mode: soft-light;
}

.bg-blend-difference {
  background-blend-mode: difference;
}

.bg-blend-exclusion {
  background-blend-mode: exclusion;
}

.bg-blend-hue {
  background-blend-mode: hue;
}

.bg-blend-saturation {
  background-blend-mode: saturation;
}

.bg-blend-color {
  background-blend-mode: color;
}

.bg-blend-luminosity {
  background-blend-mode: luminosity;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-amber-100 {
  --tw-shadow-color: #fef3c7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/0 {
  --tw-shadow-color: #fef3c700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/10 {
  --tw-shadow-color: #fef3c71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/100 {
  --tw-shadow-color: #fef3c7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/20 {
  --tw-shadow-color: #fef3c733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/25 {
  --tw-shadow-color: #fef3c740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/30 {
  --tw-shadow-color: #fef3c74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/40 {
  --tw-shadow-color: #fef3c766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/5 {
  --tw-shadow-color: #fef3c70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/50 {
  --tw-shadow-color: #fef3c780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/60 {
  --tw-shadow-color: #fef3c799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/70 {
  --tw-shadow-color: #fef3c7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/75 {
  --tw-shadow-color: #fef3c7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/80 {
  --tw-shadow-color: #fef3c7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/90 {
  --tw-shadow-color: #fef3c7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/95 {
  --tw-shadow-color: #fef3c7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200 {
  --tw-shadow-color: #fde68a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/0 {
  --tw-shadow-color: #fde68a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/10 {
  --tw-shadow-color: #fde68a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/100 {
  --tw-shadow-color: #fde68a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/20 {
  --tw-shadow-color: #fde68a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/25 {
  --tw-shadow-color: #fde68a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/30 {
  --tw-shadow-color: #fde68a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/40 {
  --tw-shadow-color: #fde68a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/5 {
  --tw-shadow-color: #fde68a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/50 {
  --tw-shadow-color: #fde68a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/60 {
  --tw-shadow-color: #fde68a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/70 {
  --tw-shadow-color: #fde68ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/75 {
  --tw-shadow-color: #fde68abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/80 {
  --tw-shadow-color: #fde68acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/90 {
  --tw-shadow-color: #fde68ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/95 {
  --tw-shadow-color: #fde68af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300 {
  --tw-shadow-color: #fcd34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/0 {
  --tw-shadow-color: #fcd34d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/10 {
  --tw-shadow-color: #fcd34d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/100 {
  --tw-shadow-color: #fcd34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/20 {
  --tw-shadow-color: #fcd34d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/25 {
  --tw-shadow-color: #fcd34d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/30 {
  --tw-shadow-color: #fcd34d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/40 {
  --tw-shadow-color: #fcd34d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/5 {
  --tw-shadow-color: #fcd34d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/50 {
  --tw-shadow-color: #fcd34d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/60 {
  --tw-shadow-color: #fcd34d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/70 {
  --tw-shadow-color: #fcd34db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/75 {
  --tw-shadow-color: #fcd34dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/80 {
  --tw-shadow-color: #fcd34dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/90 {
  --tw-shadow-color: #fcd34de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/95 {
  --tw-shadow-color: #fcd34df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400 {
  --tw-shadow-color: #fbbf24;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/0 {
  --tw-shadow-color: #fbbf2400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/10 {
  --tw-shadow-color: #fbbf241a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/100 {
  --tw-shadow-color: #fbbf24;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/20 {
  --tw-shadow-color: #fbbf2433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/25 {
  --tw-shadow-color: #fbbf2440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/30 {
  --tw-shadow-color: #fbbf244d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/40 {
  --tw-shadow-color: #fbbf2466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/5 {
  --tw-shadow-color: #fbbf240d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/50 {
  --tw-shadow-color: #fbbf2480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/60 {
  --tw-shadow-color: #fbbf2499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/70 {
  --tw-shadow-color: #fbbf24b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/75 {
  --tw-shadow-color: #fbbf24bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/80 {
  --tw-shadow-color: #fbbf24cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/90 {
  --tw-shadow-color: #fbbf24e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/95 {
  --tw-shadow-color: #fbbf24f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50 {
  --tw-shadow-color: #fffbeb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/0 {
  --tw-shadow-color: #fffbeb00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/10 {
  --tw-shadow-color: #fffbeb1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/100 {
  --tw-shadow-color: #fffbeb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/20 {
  --tw-shadow-color: #fffbeb33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/25 {
  --tw-shadow-color: #fffbeb40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/30 {
  --tw-shadow-color: #fffbeb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/40 {
  --tw-shadow-color: #fffbeb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/5 {
  --tw-shadow-color: #fffbeb0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/50 {
  --tw-shadow-color: #fffbeb80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/60 {
  --tw-shadow-color: #fffbeb99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/70 {
  --tw-shadow-color: #fffbebb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/75 {
  --tw-shadow-color: #fffbebbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/80 {
  --tw-shadow-color: #fffbebcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/90 {
  --tw-shadow-color: #fffbebe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/95 {
  --tw-shadow-color: #fffbebf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500 {
  --tw-shadow-color: #f59e0b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/0 {
  --tw-shadow-color: #f59e0b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/10 {
  --tw-shadow-color: #f59e0b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/100 {
  --tw-shadow-color: #f59e0b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/20 {
  --tw-shadow-color: #f59e0b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/25 {
  --tw-shadow-color: #f59e0b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/30 {
  --tw-shadow-color: #f59e0b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/40 {
  --tw-shadow-color: #f59e0b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/5 {
  --tw-shadow-color: #f59e0b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/50 {
  --tw-shadow-color: #f59e0b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/60 {
  --tw-shadow-color: #f59e0b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/70 {
  --tw-shadow-color: #f59e0bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/75 {
  --tw-shadow-color: #f59e0bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/80 {
  --tw-shadow-color: #f59e0bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/90 {
  --tw-shadow-color: #f59e0be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/95 {
  --tw-shadow-color: #f59e0bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600 {
  --tw-shadow-color: #d97706;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/0 {
  --tw-shadow-color: #d9770600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/10 {
  --tw-shadow-color: #d977061a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/100 {
  --tw-shadow-color: #d97706;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/20 {
  --tw-shadow-color: #d9770633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/25 {
  --tw-shadow-color: #d9770640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/30 {
  --tw-shadow-color: #d977064d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/40 {
  --tw-shadow-color: #d9770666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/5 {
  --tw-shadow-color: #d977060d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/50 {
  --tw-shadow-color: #d9770680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/60 {
  --tw-shadow-color: #d9770699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/70 {
  --tw-shadow-color: #d97706b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/75 {
  --tw-shadow-color: #d97706bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/80 {
  --tw-shadow-color: #d97706cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/90 {
  --tw-shadow-color: #d97706e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/95 {
  --tw-shadow-color: #d97706f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700 {
  --tw-shadow-color: #b45309;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/0 {
  --tw-shadow-color: #b4530900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/10 {
  --tw-shadow-color: #b453091a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/100 {
  --tw-shadow-color: #b45309;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/20 {
  --tw-shadow-color: #b4530933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/25 {
  --tw-shadow-color: #b4530940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/30 {
  --tw-shadow-color: #b453094d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/40 {
  --tw-shadow-color: #b4530966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/5 {
  --tw-shadow-color: #b453090d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/50 {
  --tw-shadow-color: #b4530980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/60 {
  --tw-shadow-color: #b4530999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/70 {
  --tw-shadow-color: #b45309b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/75 {
  --tw-shadow-color: #b45309bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/80 {
  --tw-shadow-color: #b45309cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/90 {
  --tw-shadow-color: #b45309e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/95 {
  --tw-shadow-color: #b45309f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800 {
  --tw-shadow-color: #92400e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/0 {
  --tw-shadow-color: #92400e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/10 {
  --tw-shadow-color: #92400e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/100 {
  --tw-shadow-color: #92400e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/20 {
  --tw-shadow-color: #92400e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/25 {
  --tw-shadow-color: #92400e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/30 {
  --tw-shadow-color: #92400e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/40 {
  --tw-shadow-color: #92400e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/5 {
  --tw-shadow-color: #92400e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/50 {
  --tw-shadow-color: #92400e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/60 {
  --tw-shadow-color: #92400e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/70 {
  --tw-shadow-color: #92400eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/75 {
  --tw-shadow-color: #92400ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/80 {
  --tw-shadow-color: #92400ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/90 {
  --tw-shadow-color: #92400ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/95 {
  --tw-shadow-color: #92400ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900 {
  --tw-shadow-color: #78350f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/0 {
  --tw-shadow-color: #78350f00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/10 {
  --tw-shadow-color: #78350f1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/100 {
  --tw-shadow-color: #78350f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/20 {
  --tw-shadow-color: #78350f33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/25 {
  --tw-shadow-color: #78350f40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/30 {
  --tw-shadow-color: #78350f4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/40 {
  --tw-shadow-color: #78350f66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/5 {
  --tw-shadow-color: #78350f0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/50 {
  --tw-shadow-color: #78350f80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/60 {
  --tw-shadow-color: #78350f99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/70 {
  --tw-shadow-color: #78350fb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/75 {
  --tw-shadow-color: #78350fbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/80 {
  --tw-shadow-color: #78350fcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/90 {
  --tw-shadow-color: #78350fe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/95 {
  --tw-shadow-color: #78350ff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950 {
  --tw-shadow-color: #451a03;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/0 {
  --tw-shadow-color: #451a0300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/10 {
  --tw-shadow-color: #451a031a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/100 {
  --tw-shadow-color: #451a03;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/20 {
  --tw-shadow-color: #451a0333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/25 {
  --tw-shadow-color: #451a0340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/30 {
  --tw-shadow-color: #451a034d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/40 {
  --tw-shadow-color: #451a0366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/5 {
  --tw-shadow-color: #451a030d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/50 {
  --tw-shadow-color: #451a0380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/60 {
  --tw-shadow-color: #451a0399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/70 {
  --tw-shadow-color: #451a03b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/75 {
  --tw-shadow-color: #451a03bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/80 {
  --tw-shadow-color: #451a03cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/90 {
  --tw-shadow-color: #451a03e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/95 {
  --tw-shadow-color: #451a03f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100 {
  --tw-shadow-color: #dbeafe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/0 {
  --tw-shadow-color: #dbeafe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/10 {
  --tw-shadow-color: #dbeafe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/100 {
  --tw-shadow-color: #dbeafe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/20 {
  --tw-shadow-color: #dbeafe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/25 {
  --tw-shadow-color: #dbeafe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/30 {
  --tw-shadow-color: #dbeafe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/40 {
  --tw-shadow-color: #dbeafe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/5 {
  --tw-shadow-color: #dbeafe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/50 {
  --tw-shadow-color: #dbeafe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/60 {
  --tw-shadow-color: #dbeafe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/70 {
  --tw-shadow-color: #dbeafeb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/75 {
  --tw-shadow-color: #dbeafebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/80 {
  --tw-shadow-color: #dbeafecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/90 {
  --tw-shadow-color: #dbeafee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-100\/95 {
  --tw-shadow-color: #dbeafef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200 {
  --tw-shadow-color: #bfdbfe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/0 {
  --tw-shadow-color: #bfdbfe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/10 {
  --tw-shadow-color: #bfdbfe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/100 {
  --tw-shadow-color: #bfdbfe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/20 {
  --tw-shadow-color: #bfdbfe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/25 {
  --tw-shadow-color: #bfdbfe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/30 {
  --tw-shadow-color: #bfdbfe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/40 {
  --tw-shadow-color: #bfdbfe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/5 {
  --tw-shadow-color: #bfdbfe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/50 {
  --tw-shadow-color: #bfdbfe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/60 {
  --tw-shadow-color: #bfdbfe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/70 {
  --tw-shadow-color: #bfdbfeb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/75 {
  --tw-shadow-color: #bfdbfebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/80 {
  --tw-shadow-color: #bfdbfecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/90 {
  --tw-shadow-color: #bfdbfee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-200\/95 {
  --tw-shadow-color: #bfdbfef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300 {
  --tw-shadow-color: #93c5fd;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/0 {
  --tw-shadow-color: #93c5fd00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/10 {
  --tw-shadow-color: #93c5fd1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/100 {
  --tw-shadow-color: #93c5fd;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/20 {
  --tw-shadow-color: #93c5fd33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/25 {
  --tw-shadow-color: #93c5fd40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/30 {
  --tw-shadow-color: #93c5fd4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/40 {
  --tw-shadow-color: #93c5fd66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/5 {
  --tw-shadow-color: #93c5fd0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/50 {
  --tw-shadow-color: #93c5fd80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/60 {
  --tw-shadow-color: #93c5fd99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/70 {
  --tw-shadow-color: #93c5fdb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/75 {
  --tw-shadow-color: #93c5fdbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/80 {
  --tw-shadow-color: #93c5fdcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/90 {
  --tw-shadow-color: #93c5fde6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-300\/95 {
  --tw-shadow-color: #93c5fdf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400 {
  --tw-shadow-color: #60a5fa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/0 {
  --tw-shadow-color: #60a5fa00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/10 {
  --tw-shadow-color: #60a5fa1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/100 {
  --tw-shadow-color: #60a5fa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/20 {
  --tw-shadow-color: #60a5fa33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/25 {
  --tw-shadow-color: #60a5fa40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/30 {
  --tw-shadow-color: #60a5fa4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/40 {
  --tw-shadow-color: #60a5fa66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/5 {
  --tw-shadow-color: #60a5fa0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/50 {
  --tw-shadow-color: #60a5fa80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/60 {
  --tw-shadow-color: #60a5fa99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/70 {
  --tw-shadow-color: #60a5fab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/75 {
  --tw-shadow-color: #60a5fabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/80 {
  --tw-shadow-color: #60a5facc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/90 {
  --tw-shadow-color: #60a5fae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-400\/95 {
  --tw-shadow-color: #60a5faf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50 {
  --tw-shadow-color: #eff6ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/0 {
  --tw-shadow-color: #eff6ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/10 {
  --tw-shadow-color: #eff6ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/100 {
  --tw-shadow-color: #eff6ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/20 {
  --tw-shadow-color: #eff6ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/25 {
  --tw-shadow-color: #eff6ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/30 {
  --tw-shadow-color: #eff6ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/40 {
  --tw-shadow-color: #eff6ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/5 {
  --tw-shadow-color: #eff6ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/50 {
  --tw-shadow-color: #eff6ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/60 {
  --tw-shadow-color: #eff6ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/70 {
  --tw-shadow-color: #eff6ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/75 {
  --tw-shadow-color: #eff6ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/80 {
  --tw-shadow-color: #eff6ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/90 {
  --tw-shadow-color: #eff6ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-50\/95 {
  --tw-shadow-color: #eff6fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500 {
  --tw-shadow-color: #3b82f6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/0 {
  --tw-shadow-color: #3b82f600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/10 {
  --tw-shadow-color: #3b82f61a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/100 {
  --tw-shadow-color: #3b82f6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/20 {
  --tw-shadow-color: #3b82f633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/25 {
  --tw-shadow-color: #3b82f640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/30 {
  --tw-shadow-color: #3b82f64d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/40 {
  --tw-shadow-color: #3b82f666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/5 {
  --tw-shadow-color: #3b82f60d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/50 {
  --tw-shadow-color: #3b82f680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/60 {
  --tw-shadow-color: #3b82f699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/70 {
  --tw-shadow-color: #3b82f6b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/75 {
  --tw-shadow-color: #3b82f6bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/80 {
  --tw-shadow-color: #3b82f6cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/90 {
  --tw-shadow-color: #3b82f6e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/95 {
  --tw-shadow-color: #3b82f6f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600 {
  --tw-shadow-color: #2563eb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/0 {
  --tw-shadow-color: #2563eb00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/10 {
  --tw-shadow-color: #2563eb1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/100 {
  --tw-shadow-color: #2563eb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/20 {
  --tw-shadow-color: #2563eb33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/25 {
  --tw-shadow-color: #2563eb40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/30 {
  --tw-shadow-color: #2563eb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/40 {
  --tw-shadow-color: #2563eb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/5 {
  --tw-shadow-color: #2563eb0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/50 {
  --tw-shadow-color: #2563eb80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/60 {
  --tw-shadow-color: #2563eb99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/70 {
  --tw-shadow-color: #2563ebb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/75 {
  --tw-shadow-color: #2563ebbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/80 {
  --tw-shadow-color: #2563ebcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/90 {
  --tw-shadow-color: #2563ebe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-600\/95 {
  --tw-shadow-color: #2563ebf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700 {
  --tw-shadow-color: #1d4ed8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/0 {
  --tw-shadow-color: #1d4ed800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/10 {
  --tw-shadow-color: #1d4ed81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/100 {
  --tw-shadow-color: #1d4ed8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/20 {
  --tw-shadow-color: #1d4ed833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/25 {
  --tw-shadow-color: #1d4ed840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/30 {
  --tw-shadow-color: #1d4ed84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/40 {
  --tw-shadow-color: #1d4ed866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/5 {
  --tw-shadow-color: #1d4ed80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/50 {
  --tw-shadow-color: #1d4ed880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/60 {
  --tw-shadow-color: #1d4ed899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/70 {
  --tw-shadow-color: #1d4ed8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/75 {
  --tw-shadow-color: #1d4ed8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/80 {
  --tw-shadow-color: #1d4ed8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/90 {
  --tw-shadow-color: #1d4ed8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-700\/95 {
  --tw-shadow-color: #1d4ed8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800 {
  --tw-shadow-color: #1e40af;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/0 {
  --tw-shadow-color: #1e40af00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/10 {
  --tw-shadow-color: #1e40af1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/100 {
  --tw-shadow-color: #1e40af;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/20 {
  --tw-shadow-color: #1e40af33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/25 {
  --tw-shadow-color: #1e40af40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/30 {
  --tw-shadow-color: #1e40af4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/40 {
  --tw-shadow-color: #1e40af66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/5 {
  --tw-shadow-color: #1e40af0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/50 {
  --tw-shadow-color: #1e40af80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/60 {
  --tw-shadow-color: #1e40af99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/70 {
  --tw-shadow-color: #1e40afb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/75 {
  --tw-shadow-color: #1e40afbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/80 {
  --tw-shadow-color: #1e40afcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/90 {
  --tw-shadow-color: #1e40afe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-800\/95 {
  --tw-shadow-color: #1e40aff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900 {
  --tw-shadow-color: #1e3a8a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/0 {
  --tw-shadow-color: #1e3a8a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/10 {
  --tw-shadow-color: #1e3a8a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/100 {
  --tw-shadow-color: #1e3a8a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/20 {
  --tw-shadow-color: #1e3a8a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/25 {
  --tw-shadow-color: #1e3a8a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/30 {
  --tw-shadow-color: #1e3a8a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/40 {
  --tw-shadow-color: #1e3a8a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/5 {
  --tw-shadow-color: #1e3a8a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/50 {
  --tw-shadow-color: #1e3a8a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/60 {
  --tw-shadow-color: #1e3a8a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/70 {
  --tw-shadow-color: #1e3a8ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/75 {
  --tw-shadow-color: #1e3a8abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/80 {
  --tw-shadow-color: #1e3a8acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/90 {
  --tw-shadow-color: #1e3a8ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-900\/95 {
  --tw-shadow-color: #1e3a8af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950 {
  --tw-shadow-color: #172554;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/0 {
  --tw-shadow-color: #17255400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/10 {
  --tw-shadow-color: #1725541a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/100 {
  --tw-shadow-color: #172554;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/20 {
  --tw-shadow-color: #17255433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/25 {
  --tw-shadow-color: #17255440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/30 {
  --tw-shadow-color: #1725544d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/40 {
  --tw-shadow-color: #17255466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/5 {
  --tw-shadow-color: #1725540d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/50 {
  --tw-shadow-color: #17255480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/60 {
  --tw-shadow-color: #17255499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/70 {
  --tw-shadow-color: #172554b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/75 {
  --tw-shadow-color: #172554bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/80 {
  --tw-shadow-color: #172554cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/90 {
  --tw-shadow-color: #172554e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-950\/95 {
  --tw-shadow-color: #172554f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-current {
  --tw-shadow-color: currentColor;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100 {
  --tw-shadow-color: #cffafe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/0 {
  --tw-shadow-color: #cffafe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/10 {
  --tw-shadow-color: #cffafe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/100 {
  --tw-shadow-color: #cffafe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/20 {
  --tw-shadow-color: #cffafe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/25 {
  --tw-shadow-color: #cffafe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/30 {
  --tw-shadow-color: #cffafe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/40 {
  --tw-shadow-color: #cffafe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/5 {
  --tw-shadow-color: #cffafe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/50 {
  --tw-shadow-color: #cffafe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/60 {
  --tw-shadow-color: #cffafe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/70 {
  --tw-shadow-color: #cffafeb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/75 {
  --tw-shadow-color: #cffafebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/80 {
  --tw-shadow-color: #cffafecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/90 {
  --tw-shadow-color: #cffafee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-100\/95 {
  --tw-shadow-color: #cffafef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200 {
  --tw-shadow-color: #a5f3fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/0 {
  --tw-shadow-color: #a5f3fc00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/10 {
  --tw-shadow-color: #a5f3fc1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/100 {
  --tw-shadow-color: #a5f3fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/20 {
  --tw-shadow-color: #a5f3fc33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/25 {
  --tw-shadow-color: #a5f3fc40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/30 {
  --tw-shadow-color: #a5f3fc4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/40 {
  --tw-shadow-color: #a5f3fc66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/5 {
  --tw-shadow-color: #a5f3fc0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/50 {
  --tw-shadow-color: #a5f3fc80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/60 {
  --tw-shadow-color: #a5f3fc99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/70 {
  --tw-shadow-color: #a5f3fcb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/75 {
  --tw-shadow-color: #a5f3fcbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/80 {
  --tw-shadow-color: #a5f3fccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/90 {
  --tw-shadow-color: #a5f3fce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-200\/95 {
  --tw-shadow-color: #a5f3fcf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300 {
  --tw-shadow-color: #67e8f9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/0 {
  --tw-shadow-color: #67e8f900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/10 {
  --tw-shadow-color: #67e8f91a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/100 {
  --tw-shadow-color: #67e8f9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/20 {
  --tw-shadow-color: #67e8f933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/25 {
  --tw-shadow-color: #67e8f940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/30 {
  --tw-shadow-color: #67e8f94d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/40 {
  --tw-shadow-color: #67e8f966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/5 {
  --tw-shadow-color: #67e8f90d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/50 {
  --tw-shadow-color: #67e8f980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/60 {
  --tw-shadow-color: #67e8f999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/70 {
  --tw-shadow-color: #67e8f9b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/75 {
  --tw-shadow-color: #67e8f9bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/80 {
  --tw-shadow-color: #67e8f9cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/90 {
  --tw-shadow-color: #67e8f9e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-300\/95 {
  --tw-shadow-color: #67e8f9f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400 {
  --tw-shadow-color: #22d3ee;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/0 {
  --tw-shadow-color: #22d3ee00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/10 {
  --tw-shadow-color: #22d3ee1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/100 {
  --tw-shadow-color: #22d3ee;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/20 {
  --tw-shadow-color: #22d3ee33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/25 {
  --tw-shadow-color: #22d3ee40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/30 {
  --tw-shadow-color: #22d3ee4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/40 {
  --tw-shadow-color: #22d3ee66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/5 {
  --tw-shadow-color: #22d3ee0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/50 {
  --tw-shadow-color: #22d3ee80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/60 {
  --tw-shadow-color: #22d3ee99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/70 {
  --tw-shadow-color: #22d3eeb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/75 {
  --tw-shadow-color: #22d3eebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/80 {
  --tw-shadow-color: #22d3eecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/90 {
  --tw-shadow-color: #22d3eee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-400\/95 {
  --tw-shadow-color: #22d3eef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50 {
  --tw-shadow-color: #ecfeff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/0 {
  --tw-shadow-color: #ecfeff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/10 {
  --tw-shadow-color: #ecfeff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/100 {
  --tw-shadow-color: #ecfeff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/20 {
  --tw-shadow-color: #ecfeff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/25 {
  --tw-shadow-color: #ecfeff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/30 {
  --tw-shadow-color: #ecfeff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/40 {
  --tw-shadow-color: #ecfeff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/5 {
  --tw-shadow-color: #ecfeff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/50 {
  --tw-shadow-color: #ecfeff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/60 {
  --tw-shadow-color: #ecfeff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/70 {
  --tw-shadow-color: #ecfeffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/75 {
  --tw-shadow-color: #ecfeffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/80 {
  --tw-shadow-color: #ecfeffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/90 {
  --tw-shadow-color: #ecfeffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-50\/95 {
  --tw-shadow-color: #ecfefff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500 {
  --tw-shadow-color: #06b6d4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/0 {
  --tw-shadow-color: #06b6d400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/10 {
  --tw-shadow-color: #06b6d41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/100 {
  --tw-shadow-color: #06b6d4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/20 {
  --tw-shadow-color: #06b6d433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/25 {
  --tw-shadow-color: #06b6d440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/30 {
  --tw-shadow-color: #06b6d44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/40 {
  --tw-shadow-color: #06b6d466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/5 {
  --tw-shadow-color: #06b6d40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/50 {
  --tw-shadow-color: #06b6d480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/60 {
  --tw-shadow-color: #06b6d499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/70 {
  --tw-shadow-color: #06b6d4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/75 {
  --tw-shadow-color: #06b6d4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/80 {
  --tw-shadow-color: #06b6d4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/90 {
  --tw-shadow-color: #06b6d4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/95 {
  --tw-shadow-color: #06b6d4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600 {
  --tw-shadow-color: #0891b2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/0 {
  --tw-shadow-color: #0891b200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/10 {
  --tw-shadow-color: #0891b21a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/100 {
  --tw-shadow-color: #0891b2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/20 {
  --tw-shadow-color: #0891b233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/25 {
  --tw-shadow-color: #0891b240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/30 {
  --tw-shadow-color: #0891b24d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/40 {
  --tw-shadow-color: #0891b266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/5 {
  --tw-shadow-color: #0891b20d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/50 {
  --tw-shadow-color: #0891b280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/60 {
  --tw-shadow-color: #0891b299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/70 {
  --tw-shadow-color: #0891b2b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/75 {
  --tw-shadow-color: #0891b2bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/80 {
  --tw-shadow-color: #0891b2cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/90 {
  --tw-shadow-color: #0891b2e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-600\/95 {
  --tw-shadow-color: #0891b2f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700 {
  --tw-shadow-color: #0e7490;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/0 {
  --tw-shadow-color: #0e749000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/10 {
  --tw-shadow-color: #0e74901a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/100 {
  --tw-shadow-color: #0e7490;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/20 {
  --tw-shadow-color: #0e749033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/25 {
  --tw-shadow-color: #0e749040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/30 {
  --tw-shadow-color: #0e74904d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/40 {
  --tw-shadow-color: #0e749066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/5 {
  --tw-shadow-color: #0e74900d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/50 {
  --tw-shadow-color: #0e749080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/60 {
  --tw-shadow-color: #0e749099;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/70 {
  --tw-shadow-color: #0e7490b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/75 {
  --tw-shadow-color: #0e7490bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/80 {
  --tw-shadow-color: #0e7490cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/90 {
  --tw-shadow-color: #0e7490e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-700\/95 {
  --tw-shadow-color: #0e7490f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800 {
  --tw-shadow-color: #155e75;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/0 {
  --tw-shadow-color: #155e7500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/10 {
  --tw-shadow-color: #155e751a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/100 {
  --tw-shadow-color: #155e75;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/20 {
  --tw-shadow-color: #155e7533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/25 {
  --tw-shadow-color: #155e7540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/30 {
  --tw-shadow-color: #155e754d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/40 {
  --tw-shadow-color: #155e7566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/5 {
  --tw-shadow-color: #155e750d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/50 {
  --tw-shadow-color: #155e7580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/60 {
  --tw-shadow-color: #155e7599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/70 {
  --tw-shadow-color: #155e75b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/75 {
  --tw-shadow-color: #155e75bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/80 {
  --tw-shadow-color: #155e75cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/90 {
  --tw-shadow-color: #155e75e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-800\/95 {
  --tw-shadow-color: #155e75f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900 {
  --tw-shadow-color: #164e63;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/0 {
  --tw-shadow-color: #164e6300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/10 {
  --tw-shadow-color: #164e631a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/100 {
  --tw-shadow-color: #164e63;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/20 {
  --tw-shadow-color: #164e6333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/25 {
  --tw-shadow-color: #164e6340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/30 {
  --tw-shadow-color: #164e634d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/40 {
  --tw-shadow-color: #164e6366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/5 {
  --tw-shadow-color: #164e630d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/50 {
  --tw-shadow-color: #164e6380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/60 {
  --tw-shadow-color: #164e6399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/70 {
  --tw-shadow-color: #164e63b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/75 {
  --tw-shadow-color: #164e63bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/80 {
  --tw-shadow-color: #164e63cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/90 {
  --tw-shadow-color: #164e63e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-900\/95 {
  --tw-shadow-color: #164e63f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950 {
  --tw-shadow-color: #083344;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/0 {
  --tw-shadow-color: #08334400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/10 {
  --tw-shadow-color: #0833441a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/100 {
  --tw-shadow-color: #083344;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/20 {
  --tw-shadow-color: #08334433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/25 {
  --tw-shadow-color: #08334440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/30 {
  --tw-shadow-color: #0833444d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/40 {
  --tw-shadow-color: #08334466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/5 {
  --tw-shadow-color: #0833440d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/50 {
  --tw-shadow-color: #08334480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/60 {
  --tw-shadow-color: #08334499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/70 {
  --tw-shadow-color: #083344b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/75 {
  --tw-shadow-color: #083344bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/80 {
  --tw-shadow-color: #083344cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/90 {
  --tw-shadow-color: #083344e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-950\/95 {
  --tw-shadow-color: #083344f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100 {
  --tw-shadow-color: #d1fae5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/0 {
  --tw-shadow-color: #d1fae500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/10 {
  --tw-shadow-color: #d1fae51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/100 {
  --tw-shadow-color: #d1fae5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/20 {
  --tw-shadow-color: #d1fae533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/25 {
  --tw-shadow-color: #d1fae540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/30 {
  --tw-shadow-color: #d1fae54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/40 {
  --tw-shadow-color: #d1fae566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/5 {
  --tw-shadow-color: #d1fae50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/50 {
  --tw-shadow-color: #d1fae580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/60 {
  --tw-shadow-color: #d1fae599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/70 {
  --tw-shadow-color: #d1fae5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/75 {
  --tw-shadow-color: #d1fae5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/80 {
  --tw-shadow-color: #d1fae5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/90 {
  --tw-shadow-color: #d1fae5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/95 {
  --tw-shadow-color: #d1fae5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200 {
  --tw-shadow-color: #a7f3d0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/0 {
  --tw-shadow-color: #a7f3d000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/10 {
  --tw-shadow-color: #a7f3d01a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/100 {
  --tw-shadow-color: #a7f3d0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/20 {
  --tw-shadow-color: #a7f3d033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/25 {
  --tw-shadow-color: #a7f3d040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/30 {
  --tw-shadow-color: #a7f3d04d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/40 {
  --tw-shadow-color: #a7f3d066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/5 {
  --tw-shadow-color: #a7f3d00d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/50 {
  --tw-shadow-color: #a7f3d080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/60 {
  --tw-shadow-color: #a7f3d099;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/70 {
  --tw-shadow-color: #a7f3d0b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/75 {
  --tw-shadow-color: #a7f3d0bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/80 {
  --tw-shadow-color: #a7f3d0cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/90 {
  --tw-shadow-color: #a7f3d0e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/95 {
  --tw-shadow-color: #a7f3d0f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300 {
  --tw-shadow-color: #6ee7b7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/0 {
  --tw-shadow-color: #6ee7b700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/10 {
  --tw-shadow-color: #6ee7b71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/100 {
  --tw-shadow-color: #6ee7b7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/20 {
  --tw-shadow-color: #6ee7b733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/25 {
  --tw-shadow-color: #6ee7b740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/30 {
  --tw-shadow-color: #6ee7b74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/40 {
  --tw-shadow-color: #6ee7b766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/5 {
  --tw-shadow-color: #6ee7b70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/50 {
  --tw-shadow-color: #6ee7b780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/60 {
  --tw-shadow-color: #6ee7b799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/70 {
  --tw-shadow-color: #6ee7b7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/75 {
  --tw-shadow-color: #6ee7b7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/80 {
  --tw-shadow-color: #6ee7b7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/90 {
  --tw-shadow-color: #6ee7b7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/95 {
  --tw-shadow-color: #6ee7b7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400 {
  --tw-shadow-color: #34d399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/0 {
  --tw-shadow-color: #34d39900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/10 {
  --tw-shadow-color: #34d3991a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/100 {
  --tw-shadow-color: #34d399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/20 {
  --tw-shadow-color: #34d39933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/25 {
  --tw-shadow-color: #34d39940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/30 {
  --tw-shadow-color: #34d3994d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/40 {
  --tw-shadow-color: #34d39966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/5 {
  --tw-shadow-color: #34d3990d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/50 {
  --tw-shadow-color: #34d39980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/60 {
  --tw-shadow-color: #34d39999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/70 {
  --tw-shadow-color: #34d399b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/75 {
  --tw-shadow-color: #34d399bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/80 {
  --tw-shadow-color: #34d399cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/90 {
  --tw-shadow-color: #34d399e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/95 {
  --tw-shadow-color: #34d399f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50 {
  --tw-shadow-color: #ecfdf5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/0 {
  --tw-shadow-color: #ecfdf500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/10 {
  --tw-shadow-color: #ecfdf51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/100 {
  --tw-shadow-color: #ecfdf5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/20 {
  --tw-shadow-color: #ecfdf533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/25 {
  --tw-shadow-color: #ecfdf540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/30 {
  --tw-shadow-color: #ecfdf54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/40 {
  --tw-shadow-color: #ecfdf566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/5 {
  --tw-shadow-color: #ecfdf50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/50 {
  --tw-shadow-color: #ecfdf580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/60 {
  --tw-shadow-color: #ecfdf599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/70 {
  --tw-shadow-color: #ecfdf5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/75 {
  --tw-shadow-color: #ecfdf5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/80 {
  --tw-shadow-color: #ecfdf5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/90 {
  --tw-shadow-color: #ecfdf5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/95 {
  --tw-shadow-color: #ecfdf5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500 {
  --tw-shadow-color: #10b981;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/0 {
  --tw-shadow-color: #10b98100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/10 {
  --tw-shadow-color: #10b9811a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/100 {
  --tw-shadow-color: #10b981;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/20 {
  --tw-shadow-color: #10b98133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/25 {
  --tw-shadow-color: #10b98140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/30 {
  --tw-shadow-color: #10b9814d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/40 {
  --tw-shadow-color: #10b98166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/5 {
  --tw-shadow-color: #10b9810d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/50 {
  --tw-shadow-color: #10b98180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/60 {
  --tw-shadow-color: #10b98199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/70 {
  --tw-shadow-color: #10b981b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/75 {
  --tw-shadow-color: #10b981bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/80 {
  --tw-shadow-color: #10b981cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/90 {
  --tw-shadow-color: #10b981e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/95 {
  --tw-shadow-color: #10b981f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600 {
  --tw-shadow-color: #059669;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/0 {
  --tw-shadow-color: #05966900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/10 {
  --tw-shadow-color: #0596691a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/100 {
  --tw-shadow-color: #059669;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/20 {
  --tw-shadow-color: #05966933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/25 {
  --tw-shadow-color: #05966940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/30 {
  --tw-shadow-color: #0596694d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/40 {
  --tw-shadow-color: #05966966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/5 {
  --tw-shadow-color: #0596690d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/50 {
  --tw-shadow-color: #05966980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/60 {
  --tw-shadow-color: #05966999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/70 {
  --tw-shadow-color: #059669b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/75 {
  --tw-shadow-color: #059669bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/80 {
  --tw-shadow-color: #059669cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/90 {
  --tw-shadow-color: #059669e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/95 {
  --tw-shadow-color: #059669f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700 {
  --tw-shadow-color: #047857;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/0 {
  --tw-shadow-color: #04785700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/10 {
  --tw-shadow-color: #0478571a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/100 {
  --tw-shadow-color: #047857;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/20 {
  --tw-shadow-color: #04785733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/25 {
  --tw-shadow-color: #04785740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/30 {
  --tw-shadow-color: #0478574d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/40 {
  --tw-shadow-color: #04785766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/5 {
  --tw-shadow-color: #0478570d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/50 {
  --tw-shadow-color: #04785780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/60 {
  --tw-shadow-color: #04785799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/70 {
  --tw-shadow-color: #047857b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/75 {
  --tw-shadow-color: #047857bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/80 {
  --tw-shadow-color: #047857cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/90 {
  --tw-shadow-color: #047857e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/95 {
  --tw-shadow-color: #047857f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800 {
  --tw-shadow-color: #065f46;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/0 {
  --tw-shadow-color: #065f4600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/10 {
  --tw-shadow-color: #065f461a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/100 {
  --tw-shadow-color: #065f46;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/20 {
  --tw-shadow-color: #065f4633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/25 {
  --tw-shadow-color: #065f4640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/30 {
  --tw-shadow-color: #065f464d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/40 {
  --tw-shadow-color: #065f4666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/5 {
  --tw-shadow-color: #065f460d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/50 {
  --tw-shadow-color: #065f4680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/60 {
  --tw-shadow-color: #065f4699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/70 {
  --tw-shadow-color: #065f46b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/75 {
  --tw-shadow-color: #065f46bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/80 {
  --tw-shadow-color: #065f46cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/90 {
  --tw-shadow-color: #065f46e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/95 {
  --tw-shadow-color: #065f46f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900 {
  --tw-shadow-color: #064e3b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/0 {
  --tw-shadow-color: #064e3b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/10 {
  --tw-shadow-color: #064e3b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/100 {
  --tw-shadow-color: #064e3b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/20 {
  --tw-shadow-color: #064e3b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/25 {
  --tw-shadow-color: #064e3b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/30 {
  --tw-shadow-color: #064e3b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/40 {
  --tw-shadow-color: #064e3b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/5 {
  --tw-shadow-color: #064e3b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/50 {
  --tw-shadow-color: #064e3b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/60 {
  --tw-shadow-color: #064e3b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/70 {
  --tw-shadow-color: #064e3bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/75 {
  --tw-shadow-color: #064e3bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/80 {
  --tw-shadow-color: #064e3bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/90 {
  --tw-shadow-color: #064e3be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/95 {
  --tw-shadow-color: #064e3bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950 {
  --tw-shadow-color: #022c22;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/0 {
  --tw-shadow-color: #022c2200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/10 {
  --tw-shadow-color: #022c221a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/100 {
  --tw-shadow-color: #022c22;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/20 {
  --tw-shadow-color: #022c2233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/25 {
  --tw-shadow-color: #022c2240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/30 {
  --tw-shadow-color: #022c224d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/40 {
  --tw-shadow-color: #022c2266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/5 {
  --tw-shadow-color: #022c220d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/50 {
  --tw-shadow-color: #022c2280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/60 {
  --tw-shadow-color: #022c2299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/70 {
  --tw-shadow-color: #022c22b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/75 {
  --tw-shadow-color: #022c22bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/80 {
  --tw-shadow-color: #022c22cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/90 {
  --tw-shadow-color: #022c22e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/95 {
  --tw-shadow-color: #022c22f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100 {
  --tw-shadow-color: #fae8ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/0 {
  --tw-shadow-color: #fae8ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/10 {
  --tw-shadow-color: #fae8ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/100 {
  --tw-shadow-color: #fae8ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/20 {
  --tw-shadow-color: #fae8ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/25 {
  --tw-shadow-color: #fae8ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/30 {
  --tw-shadow-color: #fae8ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/40 {
  --tw-shadow-color: #fae8ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/5 {
  --tw-shadow-color: #fae8ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/50 {
  --tw-shadow-color: #fae8ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/60 {
  --tw-shadow-color: #fae8ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/70 {
  --tw-shadow-color: #fae8ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/75 {
  --tw-shadow-color: #fae8ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/80 {
  --tw-shadow-color: #fae8ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/90 {
  --tw-shadow-color: #fae8ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-100\/95 {
  --tw-shadow-color: #fae8fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200 {
  --tw-shadow-color: #f5d0fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/0 {
  --tw-shadow-color: #f5d0fe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/10 {
  --tw-shadow-color: #f5d0fe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/100 {
  --tw-shadow-color: #f5d0fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/20 {
  --tw-shadow-color: #f5d0fe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/25 {
  --tw-shadow-color: #f5d0fe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/30 {
  --tw-shadow-color: #f5d0fe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/40 {
  --tw-shadow-color: #f5d0fe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/5 {
  --tw-shadow-color: #f5d0fe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/50 {
  --tw-shadow-color: #f5d0fe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/60 {
  --tw-shadow-color: #f5d0fe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/70 {
  --tw-shadow-color: #f5d0feb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/75 {
  --tw-shadow-color: #f5d0febf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/80 {
  --tw-shadow-color: #f5d0fecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/90 {
  --tw-shadow-color: #f5d0fee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-200\/95 {
  --tw-shadow-color: #f5d0fef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300 {
  --tw-shadow-color: #f0abfc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/0 {
  --tw-shadow-color: #f0abfc00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/10 {
  --tw-shadow-color: #f0abfc1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/100 {
  --tw-shadow-color: #f0abfc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/20 {
  --tw-shadow-color: #f0abfc33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/25 {
  --tw-shadow-color: #f0abfc40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/30 {
  --tw-shadow-color: #f0abfc4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/40 {
  --tw-shadow-color: #f0abfc66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/5 {
  --tw-shadow-color: #f0abfc0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/50 {
  --tw-shadow-color: #f0abfc80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/60 {
  --tw-shadow-color: #f0abfc99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/70 {
  --tw-shadow-color: #f0abfcb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/75 {
  --tw-shadow-color: #f0abfcbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/80 {
  --tw-shadow-color: #f0abfccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/90 {
  --tw-shadow-color: #f0abfce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-300\/95 {
  --tw-shadow-color: #f0abfcf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400 {
  --tw-shadow-color: #e879f9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/0 {
  --tw-shadow-color: #e879f900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/10 {
  --tw-shadow-color: #e879f91a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/100 {
  --tw-shadow-color: #e879f9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/20 {
  --tw-shadow-color: #e879f933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/25 {
  --tw-shadow-color: #e879f940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/30 {
  --tw-shadow-color: #e879f94d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/40 {
  --tw-shadow-color: #e879f966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/5 {
  --tw-shadow-color: #e879f90d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/50 {
  --tw-shadow-color: #e879f980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/60 {
  --tw-shadow-color: #e879f999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/70 {
  --tw-shadow-color: #e879f9b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/75 {
  --tw-shadow-color: #e879f9bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/80 {
  --tw-shadow-color: #e879f9cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/90 {
  --tw-shadow-color: #e879f9e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-400\/95 {
  --tw-shadow-color: #e879f9f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50 {
  --tw-shadow-color: #fdf4ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/0 {
  --tw-shadow-color: #fdf4ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/10 {
  --tw-shadow-color: #fdf4ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/100 {
  --tw-shadow-color: #fdf4ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/20 {
  --tw-shadow-color: #fdf4ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/25 {
  --tw-shadow-color: #fdf4ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/30 {
  --tw-shadow-color: #fdf4ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/40 {
  --tw-shadow-color: #fdf4ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/5 {
  --tw-shadow-color: #fdf4ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/50 {
  --tw-shadow-color: #fdf4ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/60 {
  --tw-shadow-color: #fdf4ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/70 {
  --tw-shadow-color: #fdf4ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/75 {
  --tw-shadow-color: #fdf4ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/80 {
  --tw-shadow-color: #fdf4ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/90 {
  --tw-shadow-color: #fdf4ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-50\/95 {
  --tw-shadow-color: #fdf4fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500 {
  --tw-shadow-color: #d946ef;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/0 {
  --tw-shadow-color: #d946ef00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/10 {
  --tw-shadow-color: #d946ef1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/100 {
  --tw-shadow-color: #d946ef;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/20 {
  --tw-shadow-color: #d946ef33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/25 {
  --tw-shadow-color: #d946ef40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/30 {
  --tw-shadow-color: #d946ef4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/40 {
  --tw-shadow-color: #d946ef66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/5 {
  --tw-shadow-color: #d946ef0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/50 {
  --tw-shadow-color: #d946ef80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/60 {
  --tw-shadow-color: #d946ef99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/70 {
  --tw-shadow-color: #d946efb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/75 {
  --tw-shadow-color: #d946efbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/80 {
  --tw-shadow-color: #d946efcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/90 {
  --tw-shadow-color: #d946efe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-500\/95 {
  --tw-shadow-color: #d946eff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600 {
  --tw-shadow-color: #c026d3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/0 {
  --tw-shadow-color: #c026d300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/10 {
  --tw-shadow-color: #c026d31a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/100 {
  --tw-shadow-color: #c026d3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/20 {
  --tw-shadow-color: #c026d333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/25 {
  --tw-shadow-color: #c026d340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/30 {
  --tw-shadow-color: #c026d34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/40 {
  --tw-shadow-color: #c026d366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/5 {
  --tw-shadow-color: #c026d30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/50 {
  --tw-shadow-color: #c026d380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/60 {
  --tw-shadow-color: #c026d399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/70 {
  --tw-shadow-color: #c026d3b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/75 {
  --tw-shadow-color: #c026d3bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/80 {
  --tw-shadow-color: #c026d3cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/90 {
  --tw-shadow-color: #c026d3e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-600\/95 {
  --tw-shadow-color: #c026d3f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700 {
  --tw-shadow-color: #a21caf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/0 {
  --tw-shadow-color: #a21caf00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/10 {
  --tw-shadow-color: #a21caf1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/100 {
  --tw-shadow-color: #a21caf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/20 {
  --tw-shadow-color: #a21caf33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/25 {
  --tw-shadow-color: #a21caf40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/30 {
  --tw-shadow-color: #a21caf4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/40 {
  --tw-shadow-color: #a21caf66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/5 {
  --tw-shadow-color: #a21caf0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/50 {
  --tw-shadow-color: #a21caf80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/60 {
  --tw-shadow-color: #a21caf99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/70 {
  --tw-shadow-color: #a21cafb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/75 {
  --tw-shadow-color: #a21cafbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/80 {
  --tw-shadow-color: #a21cafcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/90 {
  --tw-shadow-color: #a21cafe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-700\/95 {
  --tw-shadow-color: #a21caff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800 {
  --tw-shadow-color: #86198f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/0 {
  --tw-shadow-color: #86198f00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/10 {
  --tw-shadow-color: #86198f1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/100 {
  --tw-shadow-color: #86198f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/20 {
  --tw-shadow-color: #86198f33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/25 {
  --tw-shadow-color: #86198f40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/30 {
  --tw-shadow-color: #86198f4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/40 {
  --tw-shadow-color: #86198f66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/5 {
  --tw-shadow-color: #86198f0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/50 {
  --tw-shadow-color: #86198f80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/60 {
  --tw-shadow-color: #86198f99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/70 {
  --tw-shadow-color: #86198fb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/75 {
  --tw-shadow-color: #86198fbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/80 {
  --tw-shadow-color: #86198fcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/90 {
  --tw-shadow-color: #86198fe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-800\/95 {
  --tw-shadow-color: #86198ff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900 {
  --tw-shadow-color: #701a75;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/0 {
  --tw-shadow-color: #701a7500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/10 {
  --tw-shadow-color: #701a751a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/100 {
  --tw-shadow-color: #701a75;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/20 {
  --tw-shadow-color: #701a7533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/25 {
  --tw-shadow-color: #701a7540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/30 {
  --tw-shadow-color: #701a754d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/40 {
  --tw-shadow-color: #701a7566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/5 {
  --tw-shadow-color: #701a750d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/50 {
  --tw-shadow-color: #701a7580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/60 {
  --tw-shadow-color: #701a7599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/70 {
  --tw-shadow-color: #701a75b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/75 {
  --tw-shadow-color: #701a75bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/80 {
  --tw-shadow-color: #701a75cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/90 {
  --tw-shadow-color: #701a75e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-900\/95 {
  --tw-shadow-color: #701a75f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950 {
  --tw-shadow-color: #4a044e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/0 {
  --tw-shadow-color: #4a044e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/10 {
  --tw-shadow-color: #4a044e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/100 {
  --tw-shadow-color: #4a044e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/20 {
  --tw-shadow-color: #4a044e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/25 {
  --tw-shadow-color: #4a044e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/30 {
  --tw-shadow-color: #4a044e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/40 {
  --tw-shadow-color: #4a044e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/5 {
  --tw-shadow-color: #4a044e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/50 {
  --tw-shadow-color: #4a044e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/60 {
  --tw-shadow-color: #4a044e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/70 {
  --tw-shadow-color: #4a044eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/75 {
  --tw-shadow-color: #4a044ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/80 {
  --tw-shadow-color: #4a044ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/90 {
  --tw-shadow-color: #4a044ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-fuchsia-950\/95 {
  --tw-shadow-color: #4a044ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100 {
  --tw-shadow-color: #f3f4f6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/0 {
  --tw-shadow-color: #f3f4f600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/10 {
  --tw-shadow-color: #f3f4f61a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/100 {
  --tw-shadow-color: #f3f4f6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/20 {
  --tw-shadow-color: #f3f4f633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/25 {
  --tw-shadow-color: #f3f4f640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/30 {
  --tw-shadow-color: #f3f4f64d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/40 {
  --tw-shadow-color: #f3f4f666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/5 {
  --tw-shadow-color: #f3f4f60d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/50 {
  --tw-shadow-color: #f3f4f680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/60 {
  --tw-shadow-color: #f3f4f699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/70 {
  --tw-shadow-color: #f3f4f6b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/75 {
  --tw-shadow-color: #f3f4f6bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/80 {
  --tw-shadow-color: #f3f4f6cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/90 {
  --tw-shadow-color: #f3f4f6e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-100\/95 {
  --tw-shadow-color: #f3f4f6f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200 {
  --tw-shadow-color: #e5e7eb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/0 {
  --tw-shadow-color: #e5e7eb00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/10 {
  --tw-shadow-color: #e5e7eb1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/100 {
  --tw-shadow-color: #e5e7eb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/20 {
  --tw-shadow-color: #e5e7eb33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/25 {
  --tw-shadow-color: #e5e7eb40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/30 {
  --tw-shadow-color: #e5e7eb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/40 {
  --tw-shadow-color: #e5e7eb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/5 {
  --tw-shadow-color: #e5e7eb0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/50 {
  --tw-shadow-color: #e5e7eb80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/60 {
  --tw-shadow-color: #e5e7eb99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/70 {
  --tw-shadow-color: #e5e7ebb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/75 {
  --tw-shadow-color: #e5e7ebbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/80 {
  --tw-shadow-color: #e5e7ebcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/90 {
  --tw-shadow-color: #e5e7ebe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-200\/95 {
  --tw-shadow-color: #e5e7ebf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300 {
  --tw-shadow-color: #d1d5db;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/0 {
  --tw-shadow-color: #d1d5db00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/10 {
  --tw-shadow-color: #d1d5db1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/100 {
  --tw-shadow-color: #d1d5db;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/20 {
  --tw-shadow-color: #d1d5db33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/25 {
  --tw-shadow-color: #d1d5db40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/30 {
  --tw-shadow-color: #d1d5db4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/40 {
  --tw-shadow-color: #d1d5db66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/5 {
  --tw-shadow-color: #d1d5db0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/50 {
  --tw-shadow-color: #d1d5db80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/60 {
  --tw-shadow-color: #d1d5db99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/70 {
  --tw-shadow-color: #d1d5dbb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/75 {
  --tw-shadow-color: #d1d5dbbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/80 {
  --tw-shadow-color: #d1d5dbcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/90 {
  --tw-shadow-color: #d1d5dbe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-300\/95 {
  --tw-shadow-color: #d1d5dbf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400 {
  --tw-shadow-color: #9ca3af;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/0 {
  --tw-shadow-color: #9ca3af00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/10 {
  --tw-shadow-color: #9ca3af1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/100 {
  --tw-shadow-color: #9ca3af;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/20 {
  --tw-shadow-color: #9ca3af33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/25 {
  --tw-shadow-color: #9ca3af40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/30 {
  --tw-shadow-color: #9ca3af4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/40 {
  --tw-shadow-color: #9ca3af66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/5 {
  --tw-shadow-color: #9ca3af0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/50 {
  --tw-shadow-color: #9ca3af80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/60 {
  --tw-shadow-color: #9ca3af99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/70 {
  --tw-shadow-color: #9ca3afb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/75 {
  --tw-shadow-color: #9ca3afbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/80 {
  --tw-shadow-color: #9ca3afcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/90 {
  --tw-shadow-color: #9ca3afe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-400\/95 {
  --tw-shadow-color: #9ca3aff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50 {
  --tw-shadow-color: #f9fafb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/0 {
  --tw-shadow-color: #f9fafb00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/10 {
  --tw-shadow-color: #f9fafb1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/100 {
  --tw-shadow-color: #f9fafb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/20 {
  --tw-shadow-color: #f9fafb33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/25 {
  --tw-shadow-color: #f9fafb40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/30 {
  --tw-shadow-color: #f9fafb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/40 {
  --tw-shadow-color: #f9fafb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/5 {
  --tw-shadow-color: #f9fafb0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/50 {
  --tw-shadow-color: #f9fafb80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/60 {
  --tw-shadow-color: #f9fafb99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/70 {
  --tw-shadow-color: #f9fafbb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/75 {
  --tw-shadow-color: #f9fafbbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/80 {
  --tw-shadow-color: #f9fafbcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/90 {
  --tw-shadow-color: #f9fafbe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-50\/95 {
  --tw-shadow-color: #f9fafbf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500 {
  --tw-shadow-color: #6b7280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/0 {
  --tw-shadow-color: #6b728000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/10 {
  --tw-shadow-color: #6b72801a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/100 {
  --tw-shadow-color: #6b7280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/20 {
  --tw-shadow-color: #6b728033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/25 {
  --tw-shadow-color: #6b728040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/30 {
  --tw-shadow-color: #6b72804d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/40 {
  --tw-shadow-color: #6b728066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/5 {
  --tw-shadow-color: #6b72800d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/50 {
  --tw-shadow-color: #6b728080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/60 {
  --tw-shadow-color: #6b728099;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/70 {
  --tw-shadow-color: #6b7280b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/75 {
  --tw-shadow-color: #6b7280bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/80 {
  --tw-shadow-color: #6b7280cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/90 {
  --tw-shadow-color: #6b7280e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/95 {
  --tw-shadow-color: #6b7280f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600 {
  --tw-shadow-color: #4b5563;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/0 {
  --tw-shadow-color: #4b556300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/10 {
  --tw-shadow-color: #4b55631a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/100 {
  --tw-shadow-color: #4b5563;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/20 {
  --tw-shadow-color: #4b556333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/25 {
  --tw-shadow-color: #4b556340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/30 {
  --tw-shadow-color: #4b55634d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/40 {
  --tw-shadow-color: #4b556366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/5 {
  --tw-shadow-color: #4b55630d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/50 {
  --tw-shadow-color: #4b556380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/60 {
  --tw-shadow-color: #4b556399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/70 {
  --tw-shadow-color: #4b5563b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/75 {
  --tw-shadow-color: #4b5563bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/80 {
  --tw-shadow-color: #4b5563cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/90 {
  --tw-shadow-color: #4b5563e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-600\/95 {
  --tw-shadow-color: #4b5563f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700 {
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/0 {
  --tw-shadow-color: #37415100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/10 {
  --tw-shadow-color: #3741511a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/100 {
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/20 {
  --tw-shadow-color: #37415133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/25 {
  --tw-shadow-color: #37415140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/30 {
  --tw-shadow-color: #3741514d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/40 {
  --tw-shadow-color: #37415166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/5 {
  --tw-shadow-color: #3741510d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/50 {
  --tw-shadow-color: #37415180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/60 {
  --tw-shadow-color: #37415199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/70 {
  --tw-shadow-color: #374151b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/75 {
  --tw-shadow-color: #374151bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/80 {
  --tw-shadow-color: #374151cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/90 {
  --tw-shadow-color: #374151e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700\/95 {
  --tw-shadow-color: #374151f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800 {
  --tw-shadow-color: #1f2937;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/0 {
  --tw-shadow-color: #1f293700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/10 {
  --tw-shadow-color: #1f29371a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/100 {
  --tw-shadow-color: #1f2937;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/20 {
  --tw-shadow-color: #1f293733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/25 {
  --tw-shadow-color: #1f293740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/30 {
  --tw-shadow-color: #1f29374d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/40 {
  --tw-shadow-color: #1f293766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/5 {
  --tw-shadow-color: #1f29370d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/50 {
  --tw-shadow-color: #1f293780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/60 {
  --tw-shadow-color: #1f293799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/70 {
  --tw-shadow-color: #1f2937b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/75 {
  --tw-shadow-color: #1f2937bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/80 {
  --tw-shadow-color: #1f2937cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/90 {
  --tw-shadow-color: #1f2937e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-800\/95 {
  --tw-shadow-color: #1f2937f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900 {
  --tw-shadow-color: #111827;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/0 {
  --tw-shadow-color: #11182700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/10 {
  --tw-shadow-color: #1118271a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/100 {
  --tw-shadow-color: #111827;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/20 {
  --tw-shadow-color: #11182733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/25 {
  --tw-shadow-color: #11182740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/30 {
  --tw-shadow-color: #1118274d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/40 {
  --tw-shadow-color: #11182766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/5 {
  --tw-shadow-color: #1118270d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/50 {
  --tw-shadow-color: #11182780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/60 {
  --tw-shadow-color: #11182799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/70 {
  --tw-shadow-color: #111827b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/75 {
  --tw-shadow-color: #111827bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/80 {
  --tw-shadow-color: #111827cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/90 {
  --tw-shadow-color: #111827e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900\/95 {
  --tw-shadow-color: #111827f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950 {
  --tw-shadow-color: #030712;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/0 {
  --tw-shadow-color: #03071200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/10 {
  --tw-shadow-color: #0307121a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/100 {
  --tw-shadow-color: #030712;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/20 {
  --tw-shadow-color: #03071233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/25 {
  --tw-shadow-color: #03071240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/30 {
  --tw-shadow-color: #0307124d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/40 {
  --tw-shadow-color: #03071266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/5 {
  --tw-shadow-color: #0307120d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/50 {
  --tw-shadow-color: #03071280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/60 {
  --tw-shadow-color: #03071299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/70 {
  --tw-shadow-color: #030712b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/75 {
  --tw-shadow-color: #030712bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/80 {
  --tw-shadow-color: #030712cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/90 {
  --tw-shadow-color: #030712e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-950\/95 {
  --tw-shadow-color: #030712f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100 {
  --tw-shadow-color: #dcfce7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/0 {
  --tw-shadow-color: #dcfce700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/10 {
  --tw-shadow-color: #dcfce71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/100 {
  --tw-shadow-color: #dcfce7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/20 {
  --tw-shadow-color: #dcfce733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/25 {
  --tw-shadow-color: #dcfce740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/30 {
  --tw-shadow-color: #dcfce74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/40 {
  --tw-shadow-color: #dcfce766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/5 {
  --tw-shadow-color: #dcfce70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/50 {
  --tw-shadow-color: #dcfce780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/60 {
  --tw-shadow-color: #dcfce799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/70 {
  --tw-shadow-color: #dcfce7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/75 {
  --tw-shadow-color: #dcfce7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/80 {
  --tw-shadow-color: #dcfce7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/90 {
  --tw-shadow-color: #dcfce7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-100\/95 {
  --tw-shadow-color: #dcfce7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200 {
  --tw-shadow-color: #bbf7d0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/0 {
  --tw-shadow-color: #bbf7d000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/10 {
  --tw-shadow-color: #bbf7d01a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/100 {
  --tw-shadow-color: #bbf7d0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/20 {
  --tw-shadow-color: #bbf7d033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/25 {
  --tw-shadow-color: #bbf7d040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/30 {
  --tw-shadow-color: #bbf7d04d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/40 {
  --tw-shadow-color: #bbf7d066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/5 {
  --tw-shadow-color: #bbf7d00d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/50 {
  --tw-shadow-color: #bbf7d080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/60 {
  --tw-shadow-color: #bbf7d099;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/70 {
  --tw-shadow-color: #bbf7d0b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/75 {
  --tw-shadow-color: #bbf7d0bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/80 {
  --tw-shadow-color: #bbf7d0cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/90 {
  --tw-shadow-color: #bbf7d0e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-200\/95 {
  --tw-shadow-color: #bbf7d0f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300 {
  --tw-shadow-color: #86efac;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/0 {
  --tw-shadow-color: #86efac00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/10 {
  --tw-shadow-color: #86efac1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/100 {
  --tw-shadow-color: #86efac;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/20 {
  --tw-shadow-color: #86efac33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/25 {
  --tw-shadow-color: #86efac40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/30 {
  --tw-shadow-color: #86efac4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/40 {
  --tw-shadow-color: #86efac66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/5 {
  --tw-shadow-color: #86efac0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/50 {
  --tw-shadow-color: #86efac80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/60 {
  --tw-shadow-color: #86efac99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/70 {
  --tw-shadow-color: #86efacb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/75 {
  --tw-shadow-color: #86efacbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/80 {
  --tw-shadow-color: #86efaccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/90 {
  --tw-shadow-color: #86eface6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-300\/95 {
  --tw-shadow-color: #86efacf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400 {
  --tw-shadow-color: #4ade80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/0 {
  --tw-shadow-color: #4ade8000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/10 {
  --tw-shadow-color: #4ade801a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/100 {
  --tw-shadow-color: #4ade80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/20 {
  --tw-shadow-color: #4ade8033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/25 {
  --tw-shadow-color: #4ade8040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/30 {
  --tw-shadow-color: #4ade804d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/40 {
  --tw-shadow-color: #4ade8066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/5 {
  --tw-shadow-color: #4ade800d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/50 {
  --tw-shadow-color: #4ade8080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/60 {
  --tw-shadow-color: #4ade8099;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/70 {
  --tw-shadow-color: #4ade80b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/75 {
  --tw-shadow-color: #4ade80bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/80 {
  --tw-shadow-color: #4ade80cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/90 {
  --tw-shadow-color: #4ade80e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-400\/95 {
  --tw-shadow-color: #4ade80f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50 {
  --tw-shadow-color: #f0fdf4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/0 {
  --tw-shadow-color: #f0fdf400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/10 {
  --tw-shadow-color: #f0fdf41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/100 {
  --tw-shadow-color: #f0fdf4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/20 {
  --tw-shadow-color: #f0fdf433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/25 {
  --tw-shadow-color: #f0fdf440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/30 {
  --tw-shadow-color: #f0fdf44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/40 {
  --tw-shadow-color: #f0fdf466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/5 {
  --tw-shadow-color: #f0fdf40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/50 {
  --tw-shadow-color: #f0fdf480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/60 {
  --tw-shadow-color: #f0fdf499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/70 {
  --tw-shadow-color: #f0fdf4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/75 {
  --tw-shadow-color: #f0fdf4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/80 {
  --tw-shadow-color: #f0fdf4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/90 {
  --tw-shadow-color: #f0fdf4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-50\/95 {
  --tw-shadow-color: #f0fdf4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500 {
  --tw-shadow-color: #22c55e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/0 {
  --tw-shadow-color: #22c55e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/10 {
  --tw-shadow-color: #22c55e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/100 {
  --tw-shadow-color: #22c55e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/20 {
  --tw-shadow-color: #22c55e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/25 {
  --tw-shadow-color: #22c55e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/30 {
  --tw-shadow-color: #22c55e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/40 {
  --tw-shadow-color: #22c55e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/5 {
  --tw-shadow-color: #22c55e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/50 {
  --tw-shadow-color: #22c55e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/60 {
  --tw-shadow-color: #22c55e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/70 {
  --tw-shadow-color: #22c55eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/75 {
  --tw-shadow-color: #22c55ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/80 {
  --tw-shadow-color: #22c55ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/90 {
  --tw-shadow-color: #22c55ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/95 {
  --tw-shadow-color: #22c55ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600 {
  --tw-shadow-color: #16a34a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/0 {
  --tw-shadow-color: #16a34a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/10 {
  --tw-shadow-color: #16a34a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/100 {
  --tw-shadow-color: #16a34a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/20 {
  --tw-shadow-color: #16a34a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/25 {
  --tw-shadow-color: #16a34a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/30 {
  --tw-shadow-color: #16a34a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/40 {
  --tw-shadow-color: #16a34a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/5 {
  --tw-shadow-color: #16a34a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/50 {
  --tw-shadow-color: #16a34a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/60 {
  --tw-shadow-color: #16a34a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/70 {
  --tw-shadow-color: #16a34ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/75 {
  --tw-shadow-color: #16a34abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/80 {
  --tw-shadow-color: #16a34acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/90 {
  --tw-shadow-color: #16a34ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-600\/95 {
  --tw-shadow-color: #16a34af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700 {
  --tw-shadow-color: #15803d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/0 {
  --tw-shadow-color: #15803d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/10 {
  --tw-shadow-color: #15803d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/100 {
  --tw-shadow-color: #15803d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/20 {
  --tw-shadow-color: #15803d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/25 {
  --tw-shadow-color: #15803d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/30 {
  --tw-shadow-color: #15803d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/40 {
  --tw-shadow-color: #15803d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/5 {
  --tw-shadow-color: #15803d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/50 {
  --tw-shadow-color: #15803d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/60 {
  --tw-shadow-color: #15803d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/70 {
  --tw-shadow-color: #15803db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/75 {
  --tw-shadow-color: #15803dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/80 {
  --tw-shadow-color: #15803dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/90 {
  --tw-shadow-color: #15803de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-700\/95 {
  --tw-shadow-color: #15803df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800 {
  --tw-shadow-color: #166534;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/0 {
  --tw-shadow-color: #16653400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/10 {
  --tw-shadow-color: #1665341a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/100 {
  --tw-shadow-color: #166534;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/20 {
  --tw-shadow-color: #16653433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/25 {
  --tw-shadow-color: #16653440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/30 {
  --tw-shadow-color: #1665344d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/40 {
  --tw-shadow-color: #16653466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/5 {
  --tw-shadow-color: #1665340d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/50 {
  --tw-shadow-color: #16653480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/60 {
  --tw-shadow-color: #16653499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/70 {
  --tw-shadow-color: #166534b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/75 {
  --tw-shadow-color: #166534bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/80 {
  --tw-shadow-color: #166534cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/90 {
  --tw-shadow-color: #166534e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-800\/95 {
  --tw-shadow-color: #166534f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900 {
  --tw-shadow-color: #14532d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/0 {
  --tw-shadow-color: #14532d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/10 {
  --tw-shadow-color: #14532d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/100 {
  --tw-shadow-color: #14532d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/20 {
  --tw-shadow-color: #14532d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/25 {
  --tw-shadow-color: #14532d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/30 {
  --tw-shadow-color: #14532d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/40 {
  --tw-shadow-color: #14532d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/5 {
  --tw-shadow-color: #14532d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/50 {
  --tw-shadow-color: #14532d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/60 {
  --tw-shadow-color: #14532d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/70 {
  --tw-shadow-color: #14532db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/75 {
  --tw-shadow-color: #14532dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/80 {
  --tw-shadow-color: #14532dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/90 {
  --tw-shadow-color: #14532de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-900\/95 {
  --tw-shadow-color: #14532df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950 {
  --tw-shadow-color: #052e16;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/0 {
  --tw-shadow-color: #052e1600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/10 {
  --tw-shadow-color: #052e161a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/100 {
  --tw-shadow-color: #052e16;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/20 {
  --tw-shadow-color: #052e1633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/25 {
  --tw-shadow-color: #052e1640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/30 {
  --tw-shadow-color: #052e164d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/40 {
  --tw-shadow-color: #052e1666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/5 {
  --tw-shadow-color: #052e160d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/50 {
  --tw-shadow-color: #052e1680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/60 {
  --tw-shadow-color: #052e1699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/70 {
  --tw-shadow-color: #052e16b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/75 {
  --tw-shadow-color: #052e16bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/80 {
  --tw-shadow-color: #052e16cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/90 {
  --tw-shadow-color: #052e16e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-950\/95 {
  --tw-shadow-color: #052e16f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100 {
  --tw-shadow-color: #e0e7ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/0 {
  --tw-shadow-color: #e0e7ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/10 {
  --tw-shadow-color: #e0e7ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/100 {
  --tw-shadow-color: #e0e7ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/20 {
  --tw-shadow-color: #e0e7ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/25 {
  --tw-shadow-color: #e0e7ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/30 {
  --tw-shadow-color: #e0e7ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/40 {
  --tw-shadow-color: #e0e7ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/5 {
  --tw-shadow-color: #e0e7ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/50 {
  --tw-shadow-color: #e0e7ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/60 {
  --tw-shadow-color: #e0e7ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/70 {
  --tw-shadow-color: #e0e7ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/75 {
  --tw-shadow-color: #e0e7ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/80 {
  --tw-shadow-color: #e0e7ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/90 {
  --tw-shadow-color: #e0e7ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-100\/95 {
  --tw-shadow-color: #e0e7fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200 {
  --tw-shadow-color: #c7d2fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/0 {
  --tw-shadow-color: #c7d2fe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/10 {
  --tw-shadow-color: #c7d2fe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/100 {
  --tw-shadow-color: #c7d2fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/20 {
  --tw-shadow-color: #c7d2fe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/25 {
  --tw-shadow-color: #c7d2fe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/30 {
  --tw-shadow-color: #c7d2fe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/40 {
  --tw-shadow-color: #c7d2fe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/5 {
  --tw-shadow-color: #c7d2fe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/50 {
  --tw-shadow-color: #c7d2fe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/60 {
  --tw-shadow-color: #c7d2fe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/70 {
  --tw-shadow-color: #c7d2feb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/75 {
  --tw-shadow-color: #c7d2febf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/80 {
  --tw-shadow-color: #c7d2fecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/90 {
  --tw-shadow-color: #c7d2fee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-200\/95 {
  --tw-shadow-color: #c7d2fef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300 {
  --tw-shadow-color: #a5b4fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/0 {
  --tw-shadow-color: #a5b4fc00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/10 {
  --tw-shadow-color: #a5b4fc1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/100 {
  --tw-shadow-color: #a5b4fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/20 {
  --tw-shadow-color: #a5b4fc33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/25 {
  --tw-shadow-color: #a5b4fc40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/30 {
  --tw-shadow-color: #a5b4fc4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/40 {
  --tw-shadow-color: #a5b4fc66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/5 {
  --tw-shadow-color: #a5b4fc0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/50 {
  --tw-shadow-color: #a5b4fc80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/60 {
  --tw-shadow-color: #a5b4fc99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/70 {
  --tw-shadow-color: #a5b4fcb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/75 {
  --tw-shadow-color: #a5b4fcbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/80 {
  --tw-shadow-color: #a5b4fccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/90 {
  --tw-shadow-color: #a5b4fce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-300\/95 {
  --tw-shadow-color: #a5b4fcf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400 {
  --tw-shadow-color: #818cf8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/0 {
  --tw-shadow-color: #818cf800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/10 {
  --tw-shadow-color: #818cf81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/100 {
  --tw-shadow-color: #818cf8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/20 {
  --tw-shadow-color: #818cf833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/25 {
  --tw-shadow-color: #818cf840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/30 {
  --tw-shadow-color: #818cf84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/40 {
  --tw-shadow-color: #818cf866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/5 {
  --tw-shadow-color: #818cf80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/50 {
  --tw-shadow-color: #818cf880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/60 {
  --tw-shadow-color: #818cf899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/70 {
  --tw-shadow-color: #818cf8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/75 {
  --tw-shadow-color: #818cf8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/80 {
  --tw-shadow-color: #818cf8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/90 {
  --tw-shadow-color: #818cf8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-400\/95 {
  --tw-shadow-color: #818cf8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50 {
  --tw-shadow-color: #eef2ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/0 {
  --tw-shadow-color: #eef2ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/10 {
  --tw-shadow-color: #eef2ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/100 {
  --tw-shadow-color: #eef2ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/20 {
  --tw-shadow-color: #eef2ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/25 {
  --tw-shadow-color: #eef2ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/30 {
  --tw-shadow-color: #eef2ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/40 {
  --tw-shadow-color: #eef2ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/5 {
  --tw-shadow-color: #eef2ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/50 {
  --tw-shadow-color: #eef2ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/60 {
  --tw-shadow-color: #eef2ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/70 {
  --tw-shadow-color: #eef2ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/75 {
  --tw-shadow-color: #eef2ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/80 {
  --tw-shadow-color: #eef2ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/90 {
  --tw-shadow-color: #eef2ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-50\/95 {
  --tw-shadow-color: #eef2fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500 {
  --tw-shadow-color: #6366f1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/0 {
  --tw-shadow-color: #6366f100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/10 {
  --tw-shadow-color: #6366f11a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/100 {
  --tw-shadow-color: #6366f1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/20 {
  --tw-shadow-color: #6366f133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/25 {
  --tw-shadow-color: #6366f140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/30 {
  --tw-shadow-color: #6366f14d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/40 {
  --tw-shadow-color: #6366f166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/5 {
  --tw-shadow-color: #6366f10d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/50 {
  --tw-shadow-color: #6366f180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/60 {
  --tw-shadow-color: #6366f199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/70 {
  --tw-shadow-color: #6366f1b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/75 {
  --tw-shadow-color: #6366f1bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/80 {
  --tw-shadow-color: #6366f1cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/90 {
  --tw-shadow-color: #6366f1e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/95 {
  --tw-shadow-color: #6366f1f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600 {
  --tw-shadow-color: #4f46e5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/0 {
  --tw-shadow-color: #4f46e500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/10 {
  --tw-shadow-color: #4f46e51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/100 {
  --tw-shadow-color: #4f46e5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/20 {
  --tw-shadow-color: #4f46e533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/25 {
  --tw-shadow-color: #4f46e540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/30 {
  --tw-shadow-color: #4f46e54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/40 {
  --tw-shadow-color: #4f46e566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/5 {
  --tw-shadow-color: #4f46e50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/50 {
  --tw-shadow-color: #4f46e580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/60 {
  --tw-shadow-color: #4f46e599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/70 {
  --tw-shadow-color: #4f46e5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/75 {
  --tw-shadow-color: #4f46e5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/80 {
  --tw-shadow-color: #4f46e5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/90 {
  --tw-shadow-color: #4f46e5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-600\/95 {
  --tw-shadow-color: #4f46e5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700 {
  --tw-shadow-color: #4338ca;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/0 {
  --tw-shadow-color: #4338ca00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/10 {
  --tw-shadow-color: #4338ca1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/100 {
  --tw-shadow-color: #4338ca;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/20 {
  --tw-shadow-color: #4338ca33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/25 {
  --tw-shadow-color: #4338ca40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/30 {
  --tw-shadow-color: #4338ca4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/40 {
  --tw-shadow-color: #4338ca66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/5 {
  --tw-shadow-color: #4338ca0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/50 {
  --tw-shadow-color: #4338ca80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/60 {
  --tw-shadow-color: #4338ca99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/70 {
  --tw-shadow-color: #4338cab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/75 {
  --tw-shadow-color: #4338cabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/80 {
  --tw-shadow-color: #4338cacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/90 {
  --tw-shadow-color: #4338cae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-700\/95 {
  --tw-shadow-color: #4338caf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800 {
  --tw-shadow-color: #3730a3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/0 {
  --tw-shadow-color: #3730a300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/10 {
  --tw-shadow-color: #3730a31a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/100 {
  --tw-shadow-color: #3730a3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/20 {
  --tw-shadow-color: #3730a333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/25 {
  --tw-shadow-color: #3730a340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/30 {
  --tw-shadow-color: #3730a34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/40 {
  --tw-shadow-color: #3730a366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/5 {
  --tw-shadow-color: #3730a30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/50 {
  --tw-shadow-color: #3730a380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/60 {
  --tw-shadow-color: #3730a399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/70 {
  --tw-shadow-color: #3730a3b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/75 {
  --tw-shadow-color: #3730a3bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/80 {
  --tw-shadow-color: #3730a3cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/90 {
  --tw-shadow-color: #3730a3e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-800\/95 {
  --tw-shadow-color: #3730a3f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900 {
  --tw-shadow-color: #312e81;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/0 {
  --tw-shadow-color: #312e8100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/10 {
  --tw-shadow-color: #312e811a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/100 {
  --tw-shadow-color: #312e81;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/20 {
  --tw-shadow-color: #312e8133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/25 {
  --tw-shadow-color: #312e8140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/30 {
  --tw-shadow-color: #312e814d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/40 {
  --tw-shadow-color: #312e8166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/5 {
  --tw-shadow-color: #312e810d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/50 {
  --tw-shadow-color: #312e8180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/60 {
  --tw-shadow-color: #312e8199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/70 {
  --tw-shadow-color: #312e81b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/75 {
  --tw-shadow-color: #312e81bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/80 {
  --tw-shadow-color: #312e81cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/90 {
  --tw-shadow-color: #312e81e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-900\/95 {
  --tw-shadow-color: #312e81f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950 {
  --tw-shadow-color: #1e1b4b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/0 {
  --tw-shadow-color: #1e1b4b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/10 {
  --tw-shadow-color: #1e1b4b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/100 {
  --tw-shadow-color: #1e1b4b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/20 {
  --tw-shadow-color: #1e1b4b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/25 {
  --tw-shadow-color: #1e1b4b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/30 {
  --tw-shadow-color: #1e1b4b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/40 {
  --tw-shadow-color: #1e1b4b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/5 {
  --tw-shadow-color: #1e1b4b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/50 {
  --tw-shadow-color: #1e1b4b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/60 {
  --tw-shadow-color: #1e1b4b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/70 {
  --tw-shadow-color: #1e1b4bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/75 {
  --tw-shadow-color: #1e1b4bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/80 {
  --tw-shadow-color: #1e1b4bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/90 {
  --tw-shadow-color: #1e1b4be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-950\/95 {
  --tw-shadow-color: #1e1b4bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-inherit {
  --tw-shadow-color: inherit;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100 {
  --tw-shadow-color: #ecfccb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/0 {
  --tw-shadow-color: #ecfccb00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/10 {
  --tw-shadow-color: #ecfccb1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/100 {
  --tw-shadow-color: #ecfccb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/20 {
  --tw-shadow-color: #ecfccb33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/25 {
  --tw-shadow-color: #ecfccb40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/30 {
  --tw-shadow-color: #ecfccb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/40 {
  --tw-shadow-color: #ecfccb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/5 {
  --tw-shadow-color: #ecfccb0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/50 {
  --tw-shadow-color: #ecfccb80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/60 {
  --tw-shadow-color: #ecfccb99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/70 {
  --tw-shadow-color: #ecfccbb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/75 {
  --tw-shadow-color: #ecfccbbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/80 {
  --tw-shadow-color: #ecfccbcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/90 {
  --tw-shadow-color: #ecfccbe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/95 {
  --tw-shadow-color: #ecfccbf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200 {
  --tw-shadow-color: #d9f99d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/0 {
  --tw-shadow-color: #d9f99d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/10 {
  --tw-shadow-color: #d9f99d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/100 {
  --tw-shadow-color: #d9f99d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/20 {
  --tw-shadow-color: #d9f99d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/25 {
  --tw-shadow-color: #d9f99d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/30 {
  --tw-shadow-color: #d9f99d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/40 {
  --tw-shadow-color: #d9f99d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/5 {
  --tw-shadow-color: #d9f99d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/50 {
  --tw-shadow-color: #d9f99d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/60 {
  --tw-shadow-color: #d9f99d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/70 {
  --tw-shadow-color: #d9f99db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/75 {
  --tw-shadow-color: #d9f99dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/80 {
  --tw-shadow-color: #d9f99dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/90 {
  --tw-shadow-color: #d9f99de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/95 {
  --tw-shadow-color: #d9f99df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300 {
  --tw-shadow-color: #bef264;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/0 {
  --tw-shadow-color: #bef26400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/10 {
  --tw-shadow-color: #bef2641a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/100 {
  --tw-shadow-color: #bef264;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/20 {
  --tw-shadow-color: #bef26433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/25 {
  --tw-shadow-color: #bef26440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/30 {
  --tw-shadow-color: #bef2644d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/40 {
  --tw-shadow-color: #bef26466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/5 {
  --tw-shadow-color: #bef2640d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/50 {
  --tw-shadow-color: #bef26480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/60 {
  --tw-shadow-color: #bef26499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/70 {
  --tw-shadow-color: #bef264b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/75 {
  --tw-shadow-color: #bef264bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/80 {
  --tw-shadow-color: #bef264cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/90 {
  --tw-shadow-color: #bef264e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/95 {
  --tw-shadow-color: #bef264f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400 {
  --tw-shadow-color: #a3e635;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/0 {
  --tw-shadow-color: #a3e63500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/10 {
  --tw-shadow-color: #a3e6351a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/100 {
  --tw-shadow-color: #a3e635;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/20 {
  --tw-shadow-color: #a3e63533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/25 {
  --tw-shadow-color: #a3e63540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/30 {
  --tw-shadow-color: #a3e6354d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/40 {
  --tw-shadow-color: #a3e63566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/5 {
  --tw-shadow-color: #a3e6350d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/50 {
  --tw-shadow-color: #a3e63580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/60 {
  --tw-shadow-color: #a3e63599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/70 {
  --tw-shadow-color: #a3e635b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/75 {
  --tw-shadow-color: #a3e635bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/80 {
  --tw-shadow-color: #a3e635cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/90 {
  --tw-shadow-color: #a3e635e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/95 {
  --tw-shadow-color: #a3e635f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50 {
  --tw-shadow-color: #f7fee7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/0 {
  --tw-shadow-color: #f7fee700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/10 {
  --tw-shadow-color: #f7fee71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/100 {
  --tw-shadow-color: #f7fee7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/20 {
  --tw-shadow-color: #f7fee733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/25 {
  --tw-shadow-color: #f7fee740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/30 {
  --tw-shadow-color: #f7fee74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/40 {
  --tw-shadow-color: #f7fee766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/5 {
  --tw-shadow-color: #f7fee70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/50 {
  --tw-shadow-color: #f7fee780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/60 {
  --tw-shadow-color: #f7fee799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/70 {
  --tw-shadow-color: #f7fee7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/75 {
  --tw-shadow-color: #f7fee7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/80 {
  --tw-shadow-color: #f7fee7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/90 {
  --tw-shadow-color: #f7fee7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/95 {
  --tw-shadow-color: #f7fee7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500 {
  --tw-shadow-color: #84cc16;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/0 {
  --tw-shadow-color: #84cc1600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/10 {
  --tw-shadow-color: #84cc161a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/100 {
  --tw-shadow-color: #84cc16;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/20 {
  --tw-shadow-color: #84cc1633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/25 {
  --tw-shadow-color: #84cc1640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/30 {
  --tw-shadow-color: #84cc164d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/40 {
  --tw-shadow-color: #84cc1666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/5 {
  --tw-shadow-color: #84cc160d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/50 {
  --tw-shadow-color: #84cc1680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/60 {
  --tw-shadow-color: #84cc1699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/70 {
  --tw-shadow-color: #84cc16b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/75 {
  --tw-shadow-color: #84cc16bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/80 {
  --tw-shadow-color: #84cc16cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/90 {
  --tw-shadow-color: #84cc16e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/95 {
  --tw-shadow-color: #84cc16f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600 {
  --tw-shadow-color: #65a30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/0 {
  --tw-shadow-color: #65a30d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/10 {
  --tw-shadow-color: #65a30d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/100 {
  --tw-shadow-color: #65a30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/20 {
  --tw-shadow-color: #65a30d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/25 {
  --tw-shadow-color: #65a30d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/30 {
  --tw-shadow-color: #65a30d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/40 {
  --tw-shadow-color: #65a30d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/5 {
  --tw-shadow-color: #65a30d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/50 {
  --tw-shadow-color: #65a30d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/60 {
  --tw-shadow-color: #65a30d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/70 {
  --tw-shadow-color: #65a30db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/75 {
  --tw-shadow-color: #65a30dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/80 {
  --tw-shadow-color: #65a30dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/90 {
  --tw-shadow-color: #65a30de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/95 {
  --tw-shadow-color: #65a30df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700 {
  --tw-shadow-color: #4d7c0f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/0 {
  --tw-shadow-color: #4d7c0f00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/10 {
  --tw-shadow-color: #4d7c0f1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/100 {
  --tw-shadow-color: #4d7c0f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/20 {
  --tw-shadow-color: #4d7c0f33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/25 {
  --tw-shadow-color: #4d7c0f40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/30 {
  --tw-shadow-color: #4d7c0f4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/40 {
  --tw-shadow-color: #4d7c0f66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/5 {
  --tw-shadow-color: #4d7c0f0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/50 {
  --tw-shadow-color: #4d7c0f80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/60 {
  --tw-shadow-color: #4d7c0f99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/70 {
  --tw-shadow-color: #4d7c0fb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/75 {
  --tw-shadow-color: #4d7c0fbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/80 {
  --tw-shadow-color: #4d7c0fcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/90 {
  --tw-shadow-color: #4d7c0fe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/95 {
  --tw-shadow-color: #4d7c0ff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800 {
  --tw-shadow-color: #3f6212;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/0 {
  --tw-shadow-color: #3f621200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/10 {
  --tw-shadow-color: #3f62121a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/100 {
  --tw-shadow-color: #3f6212;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/20 {
  --tw-shadow-color: #3f621233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/25 {
  --tw-shadow-color: #3f621240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/30 {
  --tw-shadow-color: #3f62124d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/40 {
  --tw-shadow-color: #3f621266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/5 {
  --tw-shadow-color: #3f62120d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/50 {
  --tw-shadow-color: #3f621280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/60 {
  --tw-shadow-color: #3f621299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/70 {
  --tw-shadow-color: #3f6212b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/75 {
  --tw-shadow-color: #3f6212bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/80 {
  --tw-shadow-color: #3f6212cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/90 {
  --tw-shadow-color: #3f6212e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/95 {
  --tw-shadow-color: #3f6212f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900 {
  --tw-shadow-color: #365314;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/0 {
  --tw-shadow-color: #36531400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/10 {
  --tw-shadow-color: #3653141a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/100 {
  --tw-shadow-color: #365314;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/20 {
  --tw-shadow-color: #36531433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/25 {
  --tw-shadow-color: #36531440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/30 {
  --tw-shadow-color: #3653144d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/40 {
  --tw-shadow-color: #36531466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/5 {
  --tw-shadow-color: #3653140d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/50 {
  --tw-shadow-color: #36531480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/60 {
  --tw-shadow-color: #36531499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/70 {
  --tw-shadow-color: #365314b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/75 {
  --tw-shadow-color: #365314bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/80 {
  --tw-shadow-color: #365314cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/90 {
  --tw-shadow-color: #365314e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/95 {
  --tw-shadow-color: #365314f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950 {
  --tw-shadow-color: #1a2e05;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/0 {
  --tw-shadow-color: #1a2e0500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/10 {
  --tw-shadow-color: #1a2e051a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/100 {
  --tw-shadow-color: #1a2e05;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/20 {
  --tw-shadow-color: #1a2e0533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/25 {
  --tw-shadow-color: #1a2e0540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/30 {
  --tw-shadow-color: #1a2e054d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/40 {
  --tw-shadow-color: #1a2e0566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/5 {
  --tw-shadow-color: #1a2e050d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/50 {
  --tw-shadow-color: #1a2e0580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/60 {
  --tw-shadow-color: #1a2e0599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/70 {
  --tw-shadow-color: #1a2e05b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/75 {
  --tw-shadow-color: #1a2e05bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/80 {
  --tw-shadow-color: #1a2e05cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/90 {
  --tw-shadow-color: #1a2e05e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/95 {
  --tw-shadow-color: #1a2e05f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100 {
  --tw-shadow-color: #f5f5f5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/0 {
  --tw-shadow-color: #f5f5f500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/10 {
  --tw-shadow-color: #f5f5f51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/100 {
  --tw-shadow-color: #f5f5f5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/20 {
  --tw-shadow-color: #f5f5f533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/25 {
  --tw-shadow-color: #f5f5f540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/30 {
  --tw-shadow-color: #f5f5f54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/40 {
  --tw-shadow-color: #f5f5f566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/5 {
  --tw-shadow-color: #f5f5f50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/50 {
  --tw-shadow-color: #f5f5f580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/60 {
  --tw-shadow-color: #f5f5f599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/70 {
  --tw-shadow-color: #f5f5f5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/75 {
  --tw-shadow-color: #f5f5f5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/80 {
  --tw-shadow-color: #f5f5f5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/90 {
  --tw-shadow-color: #f5f5f5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-100\/95 {
  --tw-shadow-color: #f5f5f5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200 {
  --tw-shadow-color: #e5e5e5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/0 {
  --tw-shadow-color: #e5e5e500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/10 {
  --tw-shadow-color: #e5e5e51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/100 {
  --tw-shadow-color: #e5e5e5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/20 {
  --tw-shadow-color: #e5e5e533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/25 {
  --tw-shadow-color: #e5e5e540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/30 {
  --tw-shadow-color: #e5e5e54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/40 {
  --tw-shadow-color: #e5e5e566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/5 {
  --tw-shadow-color: #e5e5e50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/50 {
  --tw-shadow-color: #e5e5e580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/60 {
  --tw-shadow-color: #e5e5e599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/70 {
  --tw-shadow-color: #e5e5e5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/75 {
  --tw-shadow-color: #e5e5e5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/80 {
  --tw-shadow-color: #e5e5e5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/90 {
  --tw-shadow-color: #e5e5e5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-200\/95 {
  --tw-shadow-color: #e5e5e5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300 {
  --tw-shadow-color: #d4d4d4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/0 {
  --tw-shadow-color: #d4d4d400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/10 {
  --tw-shadow-color: #d4d4d41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/100 {
  --tw-shadow-color: #d4d4d4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/20 {
  --tw-shadow-color: #d4d4d433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/25 {
  --tw-shadow-color: #d4d4d440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/30 {
  --tw-shadow-color: #d4d4d44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/40 {
  --tw-shadow-color: #d4d4d466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/5 {
  --tw-shadow-color: #d4d4d40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/50 {
  --tw-shadow-color: #d4d4d480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/60 {
  --tw-shadow-color: #d4d4d499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/70 {
  --tw-shadow-color: #d4d4d4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/75 {
  --tw-shadow-color: #d4d4d4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/80 {
  --tw-shadow-color: #d4d4d4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/90 {
  --tw-shadow-color: #d4d4d4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-300\/95 {
  --tw-shadow-color: #d4d4d4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400 {
  --tw-shadow-color: #a3a3a3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/0 {
  --tw-shadow-color: #a3a3a300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/10 {
  --tw-shadow-color: #a3a3a31a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/100 {
  --tw-shadow-color: #a3a3a3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/20 {
  --tw-shadow-color: #a3a3a333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/25 {
  --tw-shadow-color: #a3a3a340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/30 {
  --tw-shadow-color: #a3a3a34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/40 {
  --tw-shadow-color: #a3a3a366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/5 {
  --tw-shadow-color: #a3a3a30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/50 {
  --tw-shadow-color: #a3a3a380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/60 {
  --tw-shadow-color: #a3a3a399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/70 {
  --tw-shadow-color: #a3a3a3b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/75 {
  --tw-shadow-color: #a3a3a3bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/80 {
  --tw-shadow-color: #a3a3a3cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/90 {
  --tw-shadow-color: #a3a3a3e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-400\/95 {
  --tw-shadow-color: #a3a3a3f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50 {
  --tw-shadow-color: #fafafa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/0 {
  --tw-shadow-color: #fafafa00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/10 {
  --tw-shadow-color: #fafafa1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/100 {
  --tw-shadow-color: #fafafa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/20 {
  --tw-shadow-color: #fafafa33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/25 {
  --tw-shadow-color: #fafafa40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/30 {
  --tw-shadow-color: #fafafa4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/40 {
  --tw-shadow-color: #fafafa66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/5 {
  --tw-shadow-color: #fafafa0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/50 {
  --tw-shadow-color: #fafafa80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/60 {
  --tw-shadow-color: #fafafa99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/70 {
  --tw-shadow-color: #fafafab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/75 {
  --tw-shadow-color: #fafafabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/80 {
  --tw-shadow-color: #fafafacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/90 {
  --tw-shadow-color: #fafafae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-50\/95 {
  --tw-shadow-color: #fafafaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500 {
  --tw-shadow-color: #737373;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/0 {
  --tw-shadow-color: #73737300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/10 {
  --tw-shadow-color: #7373731a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/100 {
  --tw-shadow-color: #737373;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/20 {
  --tw-shadow-color: #73737333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/25 {
  --tw-shadow-color: #73737340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/30 {
  --tw-shadow-color: #7373734d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/40 {
  --tw-shadow-color: #73737366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/5 {
  --tw-shadow-color: #7373730d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/50 {
  --tw-shadow-color: #73737380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/60 {
  --tw-shadow-color: #73737399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/70 {
  --tw-shadow-color: #737373b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/75 {
  --tw-shadow-color: #737373bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/80 {
  --tw-shadow-color: #737373cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/90 {
  --tw-shadow-color: #737373e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-500\/95 {
  --tw-shadow-color: #737373f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600 {
  --tw-shadow-color: #525252;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/0 {
  --tw-shadow-color: #52525200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/10 {
  --tw-shadow-color: #5252521a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/100 {
  --tw-shadow-color: #525252;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/20 {
  --tw-shadow-color: #52525233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/25 {
  --tw-shadow-color: #52525240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/30 {
  --tw-shadow-color: #5252524d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/40 {
  --tw-shadow-color: #52525266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/5 {
  --tw-shadow-color: #5252520d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/50 {
  --tw-shadow-color: #52525280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/60 {
  --tw-shadow-color: #52525299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/70 {
  --tw-shadow-color: #525252b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/75 {
  --tw-shadow-color: #525252bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/80 {
  --tw-shadow-color: #525252cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/90 {
  --tw-shadow-color: #525252e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-600\/95 {
  --tw-shadow-color: #525252f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700 {
  --tw-shadow-color: #404040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/0 {
  --tw-shadow-color: #40404000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/10 {
  --tw-shadow-color: #4040401a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/100 {
  --tw-shadow-color: #404040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/20 {
  --tw-shadow-color: #40404033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/25 {
  --tw-shadow-color: #40404040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/30 {
  --tw-shadow-color: #4040404d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/40 {
  --tw-shadow-color: #40404066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/5 {
  --tw-shadow-color: #4040400d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/50 {
  --tw-shadow-color: #40404080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/60 {
  --tw-shadow-color: #40404099;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/70 {
  --tw-shadow-color: #404040b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/75 {
  --tw-shadow-color: #404040bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/80 {
  --tw-shadow-color: #404040cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/90 {
  --tw-shadow-color: #404040e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-700\/95 {
  --tw-shadow-color: #404040f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800 {
  --tw-shadow-color: #262626;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/0 {
  --tw-shadow-color: #26262600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/10 {
  --tw-shadow-color: #2626261a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/100 {
  --tw-shadow-color: #262626;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/20 {
  --tw-shadow-color: #26262633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/25 {
  --tw-shadow-color: #26262640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/30 {
  --tw-shadow-color: #2626264d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/40 {
  --tw-shadow-color: #26262666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/5 {
  --tw-shadow-color: #2626260d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/50 {
  --tw-shadow-color: #26262680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/60 {
  --tw-shadow-color: #26262699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/70 {
  --tw-shadow-color: #262626b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/75 {
  --tw-shadow-color: #262626bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/80 {
  --tw-shadow-color: #262626cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/90 {
  --tw-shadow-color: #262626e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-800\/95 {
  --tw-shadow-color: #262626f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900 {
  --tw-shadow-color: #171717;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/0 {
  --tw-shadow-color: #17171700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/10 {
  --tw-shadow-color: #1717171a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/100 {
  --tw-shadow-color: #171717;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/20 {
  --tw-shadow-color: #17171733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/25 {
  --tw-shadow-color: #17171740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/30 {
  --tw-shadow-color: #1717174d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/40 {
  --tw-shadow-color: #17171766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/5 {
  --tw-shadow-color: #1717170d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/50 {
  --tw-shadow-color: #17171780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/60 {
  --tw-shadow-color: #17171799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/70 {
  --tw-shadow-color: #171717b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/75 {
  --tw-shadow-color: #171717bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/80 {
  --tw-shadow-color: #171717cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/90 {
  --tw-shadow-color: #171717e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-900\/95 {
  --tw-shadow-color: #171717f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950 {
  --tw-shadow-color: #0a0a0a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/0 {
  --tw-shadow-color: #0a0a0a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/10 {
  --tw-shadow-color: #0a0a0a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/100 {
  --tw-shadow-color: #0a0a0a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/20 {
  --tw-shadow-color: #0a0a0a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/25 {
  --tw-shadow-color: #0a0a0a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/30 {
  --tw-shadow-color: #0a0a0a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/40 {
  --tw-shadow-color: #0a0a0a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/5 {
  --tw-shadow-color: #0a0a0a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/50 {
  --tw-shadow-color: #0a0a0a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/60 {
  --tw-shadow-color: #0a0a0a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/70 {
  --tw-shadow-color: #0a0a0ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/75 {
  --tw-shadow-color: #0a0a0abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/80 {
  --tw-shadow-color: #0a0a0acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/90 {
  --tw-shadow-color: #0a0a0ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-neutral-950\/95 {
  --tw-shadow-color: #0a0a0af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100 {
  --tw-shadow-color: #ffedd5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/0 {
  --tw-shadow-color: #ffedd500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/10 {
  --tw-shadow-color: #ffedd51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/100 {
  --tw-shadow-color: #ffedd5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/20 {
  --tw-shadow-color: #ffedd533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/25 {
  --tw-shadow-color: #ffedd540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/30 {
  --tw-shadow-color: #ffedd54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/40 {
  --tw-shadow-color: #ffedd566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/5 {
  --tw-shadow-color: #ffedd50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/50 {
  --tw-shadow-color: #ffedd580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/60 {
  --tw-shadow-color: #ffedd599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/70 {
  --tw-shadow-color: #ffedd5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/75 {
  --tw-shadow-color: #ffedd5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/80 {
  --tw-shadow-color: #ffedd5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/90 {
  --tw-shadow-color: #ffedd5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-100\/95 {
  --tw-shadow-color: #ffedd5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200 {
  --tw-shadow-color: #fed7aa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/0 {
  --tw-shadow-color: #fed7aa00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/10 {
  --tw-shadow-color: #fed7aa1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/100 {
  --tw-shadow-color: #fed7aa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/20 {
  --tw-shadow-color: #fed7aa33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/25 {
  --tw-shadow-color: #fed7aa40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/30 {
  --tw-shadow-color: #fed7aa4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/40 {
  --tw-shadow-color: #fed7aa66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/5 {
  --tw-shadow-color: #fed7aa0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/50 {
  --tw-shadow-color: #fed7aa80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/60 {
  --tw-shadow-color: #fed7aa99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/70 {
  --tw-shadow-color: #fed7aab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/75 {
  --tw-shadow-color: #fed7aabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/80 {
  --tw-shadow-color: #fed7aacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/90 {
  --tw-shadow-color: #fed7aae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-200\/95 {
  --tw-shadow-color: #fed7aaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300 {
  --tw-shadow-color: #fdba74;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/0 {
  --tw-shadow-color: #fdba7400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/10 {
  --tw-shadow-color: #fdba741a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/100 {
  --tw-shadow-color: #fdba74;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/20 {
  --tw-shadow-color: #fdba7433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/25 {
  --tw-shadow-color: #fdba7440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/30 {
  --tw-shadow-color: #fdba744d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/40 {
  --tw-shadow-color: #fdba7466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/5 {
  --tw-shadow-color: #fdba740d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/50 {
  --tw-shadow-color: #fdba7480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/60 {
  --tw-shadow-color: #fdba7499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/70 {
  --tw-shadow-color: #fdba74b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/75 {
  --tw-shadow-color: #fdba74bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/80 {
  --tw-shadow-color: #fdba74cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/90 {
  --tw-shadow-color: #fdba74e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-300\/95 {
  --tw-shadow-color: #fdba74f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400 {
  --tw-shadow-color: #fb923c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/0 {
  --tw-shadow-color: #fb923c00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/10 {
  --tw-shadow-color: #fb923c1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/100 {
  --tw-shadow-color: #fb923c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/20 {
  --tw-shadow-color: #fb923c33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/25 {
  --tw-shadow-color: #fb923c40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/30 {
  --tw-shadow-color: #fb923c4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/40 {
  --tw-shadow-color: #fb923c66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/5 {
  --tw-shadow-color: #fb923c0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/50 {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/60 {
  --tw-shadow-color: #fb923c99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/70 {
  --tw-shadow-color: #fb923cb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/75 {
  --tw-shadow-color: #fb923cbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/80 {
  --tw-shadow-color: #fb923ccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/90 {
  --tw-shadow-color: #fb923ce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-400\/95 {
  --tw-shadow-color: #fb923cf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50 {
  --tw-shadow-color: #fff7ed;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/0 {
  --tw-shadow-color: #fff7ed00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/10 {
  --tw-shadow-color: #fff7ed1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/100 {
  --tw-shadow-color: #fff7ed;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/20 {
  --tw-shadow-color: #fff7ed33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/25 {
  --tw-shadow-color: #fff7ed40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/30 {
  --tw-shadow-color: #fff7ed4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/40 {
  --tw-shadow-color: #fff7ed66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/5 {
  --tw-shadow-color: #fff7ed0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/50 {
  --tw-shadow-color: #fff7ed80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/60 {
  --tw-shadow-color: #fff7ed99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/70 {
  --tw-shadow-color: #fff7edb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/75 {
  --tw-shadow-color: #fff7edbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/80 {
  --tw-shadow-color: #fff7edcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/90 {
  --tw-shadow-color: #fff7ede6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-50\/95 {
  --tw-shadow-color: #fff7edf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500 {
  --tw-shadow-color: #f97316;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/0 {
  --tw-shadow-color: #f9731600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/10 {
  --tw-shadow-color: #f973161a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/100 {
  --tw-shadow-color: #f97316;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/20 {
  --tw-shadow-color: #f9731633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/25 {
  --tw-shadow-color: #f9731640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/30 {
  --tw-shadow-color: #f973164d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/40 {
  --tw-shadow-color: #f9731666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/5 {
  --tw-shadow-color: #f973160d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/50 {
  --tw-shadow-color: #f9731680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/60 {
  --tw-shadow-color: #f9731699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/70 {
  --tw-shadow-color: #f97316b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/75 {
  --tw-shadow-color: #f97316bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/80 {
  --tw-shadow-color: #f97316cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/90 {
  --tw-shadow-color: #f97316e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/95 {
  --tw-shadow-color: #f97316f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600 {
  --tw-shadow-color: #ea580c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/0 {
  --tw-shadow-color: #ea580c00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/10 {
  --tw-shadow-color: #ea580c1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/100 {
  --tw-shadow-color: #ea580c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/20 {
  --tw-shadow-color: #ea580c33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/25 {
  --tw-shadow-color: #ea580c40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/30 {
  --tw-shadow-color: #ea580c4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/40 {
  --tw-shadow-color: #ea580c66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/5 {
  --tw-shadow-color: #ea580c0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/50 {
  --tw-shadow-color: #ea580c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/60 {
  --tw-shadow-color: #ea580c99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/70 {
  --tw-shadow-color: #ea580cb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/75 {
  --tw-shadow-color: #ea580cbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/80 {
  --tw-shadow-color: #ea580ccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/90 {
  --tw-shadow-color: #ea580ce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-600\/95 {
  --tw-shadow-color: #ea580cf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700 {
  --tw-shadow-color: #c2410c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/0 {
  --tw-shadow-color: #c2410c00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/10 {
  --tw-shadow-color: #c2410c1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/100 {
  --tw-shadow-color: #c2410c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/20 {
  --tw-shadow-color: #c2410c33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/25 {
  --tw-shadow-color: #c2410c40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/30 {
  --tw-shadow-color: #c2410c4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/40 {
  --tw-shadow-color: #c2410c66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/5 {
  --tw-shadow-color: #c2410c0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/50 {
  --tw-shadow-color: #c2410c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/60 {
  --tw-shadow-color: #c2410c99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/70 {
  --tw-shadow-color: #c2410cb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/75 {
  --tw-shadow-color: #c2410cbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/80 {
  --tw-shadow-color: #c2410ccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/90 {
  --tw-shadow-color: #c2410ce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-700\/95 {
  --tw-shadow-color: #c2410cf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800 {
  --tw-shadow-color: #9a3412;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/0 {
  --tw-shadow-color: #9a341200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/10 {
  --tw-shadow-color: #9a34121a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/100 {
  --tw-shadow-color: #9a3412;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/20 {
  --tw-shadow-color: #9a341233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/25 {
  --tw-shadow-color: #9a341240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/30 {
  --tw-shadow-color: #9a34124d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/40 {
  --tw-shadow-color: #9a341266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/5 {
  --tw-shadow-color: #9a34120d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/50 {
  --tw-shadow-color: #9a341280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/60 {
  --tw-shadow-color: #9a341299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/70 {
  --tw-shadow-color: #9a3412b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/75 {
  --tw-shadow-color: #9a3412bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/80 {
  --tw-shadow-color: #9a3412cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/90 {
  --tw-shadow-color: #9a3412e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-800\/95 {
  --tw-shadow-color: #9a3412f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900 {
  --tw-shadow-color: #7c2d12;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/0 {
  --tw-shadow-color: #7c2d1200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/10 {
  --tw-shadow-color: #7c2d121a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/100 {
  --tw-shadow-color: #7c2d12;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/20 {
  --tw-shadow-color: #7c2d1233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/25 {
  --tw-shadow-color: #7c2d1240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/30 {
  --tw-shadow-color: #7c2d124d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/40 {
  --tw-shadow-color: #7c2d1266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/5 {
  --tw-shadow-color: #7c2d120d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/50 {
  --tw-shadow-color: #7c2d1280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/60 {
  --tw-shadow-color: #7c2d1299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/70 {
  --tw-shadow-color: #7c2d12b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/75 {
  --tw-shadow-color: #7c2d12bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/80 {
  --tw-shadow-color: #7c2d12cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/90 {
  --tw-shadow-color: #7c2d12e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-900\/95 {
  --tw-shadow-color: #7c2d12f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950 {
  --tw-shadow-color: #431407;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/0 {
  --tw-shadow-color: #43140700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/10 {
  --tw-shadow-color: #4314071a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/100 {
  --tw-shadow-color: #431407;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/20 {
  --tw-shadow-color: #43140733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/25 {
  --tw-shadow-color: #43140740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/30 {
  --tw-shadow-color: #4314074d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/40 {
  --tw-shadow-color: #43140766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/5 {
  --tw-shadow-color: #4314070d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/50 {
  --tw-shadow-color: #43140780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/60 {
  --tw-shadow-color: #43140799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/70 {
  --tw-shadow-color: #431407b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/75 {
  --tw-shadow-color: #431407bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/80 {
  --tw-shadow-color: #431407cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/90 {
  --tw-shadow-color: #431407e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-950\/95 {
  --tw-shadow-color: #431407f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100 {
  --tw-shadow-color: #fce7f3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/0 {
  --tw-shadow-color: #fce7f300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/10 {
  --tw-shadow-color: #fce7f31a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/100 {
  --tw-shadow-color: #fce7f3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/20 {
  --tw-shadow-color: #fce7f333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/25 {
  --tw-shadow-color: #fce7f340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/30 {
  --tw-shadow-color: #fce7f34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/40 {
  --tw-shadow-color: #fce7f366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/5 {
  --tw-shadow-color: #fce7f30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/50 {
  --tw-shadow-color: #fce7f380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/60 {
  --tw-shadow-color: #fce7f399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/70 {
  --tw-shadow-color: #fce7f3b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/75 {
  --tw-shadow-color: #fce7f3bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/80 {
  --tw-shadow-color: #fce7f3cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/90 {
  --tw-shadow-color: #fce7f3e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/95 {
  --tw-shadow-color: #fce7f3f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200 {
  --tw-shadow-color: #fbcfe8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/0 {
  --tw-shadow-color: #fbcfe800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/10 {
  --tw-shadow-color: #fbcfe81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/100 {
  --tw-shadow-color: #fbcfe8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/20 {
  --tw-shadow-color: #fbcfe833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/25 {
  --tw-shadow-color: #fbcfe840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/30 {
  --tw-shadow-color: #fbcfe84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/40 {
  --tw-shadow-color: #fbcfe866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/5 {
  --tw-shadow-color: #fbcfe80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/50 {
  --tw-shadow-color: #fbcfe880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/60 {
  --tw-shadow-color: #fbcfe899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/70 {
  --tw-shadow-color: #fbcfe8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/75 {
  --tw-shadow-color: #fbcfe8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/80 {
  --tw-shadow-color: #fbcfe8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/90 {
  --tw-shadow-color: #fbcfe8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/95 {
  --tw-shadow-color: #fbcfe8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300 {
  --tw-shadow-color: #f9a8d4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/0 {
  --tw-shadow-color: #f9a8d400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/10 {
  --tw-shadow-color: #f9a8d41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/100 {
  --tw-shadow-color: #f9a8d4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/20 {
  --tw-shadow-color: #f9a8d433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/25 {
  --tw-shadow-color: #f9a8d440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/30 {
  --tw-shadow-color: #f9a8d44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/40 {
  --tw-shadow-color: #f9a8d466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/5 {
  --tw-shadow-color: #f9a8d40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/50 {
  --tw-shadow-color: #f9a8d480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/60 {
  --tw-shadow-color: #f9a8d499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/70 {
  --tw-shadow-color: #f9a8d4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/75 {
  --tw-shadow-color: #f9a8d4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/80 {
  --tw-shadow-color: #f9a8d4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/90 {
  --tw-shadow-color: #f9a8d4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/95 {
  --tw-shadow-color: #f9a8d4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400 {
  --tw-shadow-color: #f472b6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/0 {
  --tw-shadow-color: #f472b600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/10 {
  --tw-shadow-color: #f472b61a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/100 {
  --tw-shadow-color: #f472b6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/20 {
  --tw-shadow-color: #f472b633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/25 {
  --tw-shadow-color: #f472b640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/30 {
  --tw-shadow-color: #f472b64d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/40 {
  --tw-shadow-color: #f472b666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/5 {
  --tw-shadow-color: #f472b60d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/50 {
  --tw-shadow-color: #f472b680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/60 {
  --tw-shadow-color: #f472b699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/70 {
  --tw-shadow-color: #f472b6b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/75 {
  --tw-shadow-color: #f472b6bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/80 {
  --tw-shadow-color: #f472b6cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/90 {
  --tw-shadow-color: #f472b6e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/95 {
  --tw-shadow-color: #f472b6f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50 {
  --tw-shadow-color: #fdf2f8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/0 {
  --tw-shadow-color: #fdf2f800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/10 {
  --tw-shadow-color: #fdf2f81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/100 {
  --tw-shadow-color: #fdf2f8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/20 {
  --tw-shadow-color: #fdf2f833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/25 {
  --tw-shadow-color: #fdf2f840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/30 {
  --tw-shadow-color: #fdf2f84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/40 {
  --tw-shadow-color: #fdf2f866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/5 {
  --tw-shadow-color: #fdf2f80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/50 {
  --tw-shadow-color: #fdf2f880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/60 {
  --tw-shadow-color: #fdf2f899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/70 {
  --tw-shadow-color: #fdf2f8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/75 {
  --tw-shadow-color: #fdf2f8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/80 {
  --tw-shadow-color: #fdf2f8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/90 {
  --tw-shadow-color: #fdf2f8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/95 {
  --tw-shadow-color: #fdf2f8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500 {
  --tw-shadow-color: #ec4899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/0 {
  --tw-shadow-color: #ec489900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/10 {
  --tw-shadow-color: #ec48991a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/100 {
  --tw-shadow-color: #ec4899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/20 {
  --tw-shadow-color: #ec489933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/25 {
  --tw-shadow-color: #ec489940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/30 {
  --tw-shadow-color: #ec48994d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/40 {
  --tw-shadow-color: #ec489966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/5 {
  --tw-shadow-color: #ec48990d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/50 {
  --tw-shadow-color: #ec489980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/60 {
  --tw-shadow-color: #ec489999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/70 {
  --tw-shadow-color: #ec4899b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/75 {
  --tw-shadow-color: #ec4899bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/80 {
  --tw-shadow-color: #ec4899cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/90 {
  --tw-shadow-color: #ec4899e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/95 {
  --tw-shadow-color: #ec4899f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600 {
  --tw-shadow-color: #db2777;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/0 {
  --tw-shadow-color: #db277700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/10 {
  --tw-shadow-color: #db27771a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/100 {
  --tw-shadow-color: #db2777;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/20 {
  --tw-shadow-color: #db277733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/25 {
  --tw-shadow-color: #db277740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/30 {
  --tw-shadow-color: #db27774d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/40 {
  --tw-shadow-color: #db277766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/5 {
  --tw-shadow-color: #db27770d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/50 {
  --tw-shadow-color: #db277780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/60 {
  --tw-shadow-color: #db277799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/70 {
  --tw-shadow-color: #db2777b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/75 {
  --tw-shadow-color: #db2777bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/80 {
  --tw-shadow-color: #db2777cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/90 {
  --tw-shadow-color: #db2777e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/95 {
  --tw-shadow-color: #db2777f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700 {
  --tw-shadow-color: #be185d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/0 {
  --tw-shadow-color: #be185d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/10 {
  --tw-shadow-color: #be185d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/100 {
  --tw-shadow-color: #be185d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/20 {
  --tw-shadow-color: #be185d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/25 {
  --tw-shadow-color: #be185d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/30 {
  --tw-shadow-color: #be185d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/40 {
  --tw-shadow-color: #be185d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/5 {
  --tw-shadow-color: #be185d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/50 {
  --tw-shadow-color: #be185d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/60 {
  --tw-shadow-color: #be185d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/70 {
  --tw-shadow-color: #be185db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/75 {
  --tw-shadow-color: #be185dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/80 {
  --tw-shadow-color: #be185dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/90 {
  --tw-shadow-color: #be185de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/95 {
  --tw-shadow-color: #be185df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800 {
  --tw-shadow-color: #9d174d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/0 {
  --tw-shadow-color: #9d174d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/10 {
  --tw-shadow-color: #9d174d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/100 {
  --tw-shadow-color: #9d174d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/20 {
  --tw-shadow-color: #9d174d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/25 {
  --tw-shadow-color: #9d174d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/30 {
  --tw-shadow-color: #9d174d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/40 {
  --tw-shadow-color: #9d174d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/5 {
  --tw-shadow-color: #9d174d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/50 {
  --tw-shadow-color: #9d174d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/60 {
  --tw-shadow-color: #9d174d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/70 {
  --tw-shadow-color: #9d174db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/75 {
  --tw-shadow-color: #9d174dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/80 {
  --tw-shadow-color: #9d174dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/90 {
  --tw-shadow-color: #9d174de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/95 {
  --tw-shadow-color: #9d174df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900 {
  --tw-shadow-color: #831843;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/0 {
  --tw-shadow-color: #83184300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/10 {
  --tw-shadow-color: #8318431a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/100 {
  --tw-shadow-color: #831843;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/20 {
  --tw-shadow-color: #83184333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/25 {
  --tw-shadow-color: #83184340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/30 {
  --tw-shadow-color: #8318434d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/40 {
  --tw-shadow-color: #83184366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/5 {
  --tw-shadow-color: #8318430d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/50 {
  --tw-shadow-color: #83184380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/60 {
  --tw-shadow-color: #83184399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/70 {
  --tw-shadow-color: #831843b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/75 {
  --tw-shadow-color: #831843bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/80 {
  --tw-shadow-color: #831843cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/90 {
  --tw-shadow-color: #831843e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/95 {
  --tw-shadow-color: #831843f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950 {
  --tw-shadow-color: #500724;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/0 {
  --tw-shadow-color: #50072400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/10 {
  --tw-shadow-color: #5007241a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/100 {
  --tw-shadow-color: #500724;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/20 {
  --tw-shadow-color: #50072433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/25 {
  --tw-shadow-color: #50072440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/30 {
  --tw-shadow-color: #5007244d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/40 {
  --tw-shadow-color: #50072466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/5 {
  --tw-shadow-color: #5007240d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/50 {
  --tw-shadow-color: #50072480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/60 {
  --tw-shadow-color: #50072499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/70 {
  --tw-shadow-color: #500724b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/75 {
  --tw-shadow-color: #500724bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/80 {
  --tw-shadow-color: #500724cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/90 {
  --tw-shadow-color: #500724e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/95 {
  --tw-shadow-color: #500724f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100 {
  --tw-shadow-color: #f3e8ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/0 {
  --tw-shadow-color: #f3e8ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/10 {
  --tw-shadow-color: #f3e8ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/100 {
  --tw-shadow-color: #f3e8ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/20 {
  --tw-shadow-color: #f3e8ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/25 {
  --tw-shadow-color: #f3e8ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/30 {
  --tw-shadow-color: #f3e8ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/40 {
  --tw-shadow-color: #f3e8ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/5 {
  --tw-shadow-color: #f3e8ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/50 {
  --tw-shadow-color: #f3e8ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/60 {
  --tw-shadow-color: #f3e8ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/70 {
  --tw-shadow-color: #f3e8ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/75 {
  --tw-shadow-color: #f3e8ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/80 {
  --tw-shadow-color: #f3e8ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/90 {
  --tw-shadow-color: #f3e8ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/95 {
  --tw-shadow-color: #f3e8fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200 {
  --tw-shadow-color: #e9d5ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/0 {
  --tw-shadow-color: #e9d5ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/10 {
  --tw-shadow-color: #e9d5ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/100 {
  --tw-shadow-color: #e9d5ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/20 {
  --tw-shadow-color: #e9d5ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/25 {
  --tw-shadow-color: #e9d5ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/30 {
  --tw-shadow-color: #e9d5ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/40 {
  --tw-shadow-color: #e9d5ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/5 {
  --tw-shadow-color: #e9d5ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/50 {
  --tw-shadow-color: #e9d5ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/60 {
  --tw-shadow-color: #e9d5ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/70 {
  --tw-shadow-color: #e9d5ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/75 {
  --tw-shadow-color: #e9d5ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/80 {
  --tw-shadow-color: #e9d5ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/90 {
  --tw-shadow-color: #e9d5ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/95 {
  --tw-shadow-color: #e9d5fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300 {
  --tw-shadow-color: #d8b4fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/0 {
  --tw-shadow-color: #d8b4fe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/10 {
  --tw-shadow-color: #d8b4fe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/100 {
  --tw-shadow-color: #d8b4fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/20 {
  --tw-shadow-color: #d8b4fe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/25 {
  --tw-shadow-color: #d8b4fe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/30 {
  --tw-shadow-color: #d8b4fe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/40 {
  --tw-shadow-color: #d8b4fe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/5 {
  --tw-shadow-color: #d8b4fe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/50 {
  --tw-shadow-color: #d8b4fe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/60 {
  --tw-shadow-color: #d8b4fe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/70 {
  --tw-shadow-color: #d8b4feb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/75 {
  --tw-shadow-color: #d8b4febf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/80 {
  --tw-shadow-color: #d8b4fecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/90 {
  --tw-shadow-color: #d8b4fee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/95 {
  --tw-shadow-color: #d8b4fef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400 {
  --tw-shadow-color: #c084fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/0 {
  --tw-shadow-color: #c084fc00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/10 {
  --tw-shadow-color: #c084fc1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/100 {
  --tw-shadow-color: #c084fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/20 {
  --tw-shadow-color: #c084fc33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/25 {
  --tw-shadow-color: #c084fc40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/30 {
  --tw-shadow-color: #c084fc4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/40 {
  --tw-shadow-color: #c084fc66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/5 {
  --tw-shadow-color: #c084fc0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/50 {
  --tw-shadow-color: #c084fc80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/60 {
  --tw-shadow-color: #c084fc99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/70 {
  --tw-shadow-color: #c084fcb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/75 {
  --tw-shadow-color: #c084fcbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/80 {
  --tw-shadow-color: #c084fccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/90 {
  --tw-shadow-color: #c084fce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/95 {
  --tw-shadow-color: #c084fcf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50 {
  --tw-shadow-color: #faf5ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/0 {
  --tw-shadow-color: #faf5ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/10 {
  --tw-shadow-color: #faf5ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/100 {
  --tw-shadow-color: #faf5ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/20 {
  --tw-shadow-color: #faf5ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/25 {
  --tw-shadow-color: #faf5ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/30 {
  --tw-shadow-color: #faf5ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/40 {
  --tw-shadow-color: #faf5ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/5 {
  --tw-shadow-color: #faf5ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/50 {
  --tw-shadow-color: #faf5ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/60 {
  --tw-shadow-color: #faf5ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/70 {
  --tw-shadow-color: #faf5ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/75 {
  --tw-shadow-color: #faf5ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/80 {
  --tw-shadow-color: #faf5ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/90 {
  --tw-shadow-color: #faf5ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/95 {
  --tw-shadow-color: #faf5fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500 {
  --tw-shadow-color: #a855f7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/0 {
  --tw-shadow-color: #a855f700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/10 {
  --tw-shadow-color: #a855f71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/100 {
  --tw-shadow-color: #a855f7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/20 {
  --tw-shadow-color: #a855f733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/25 {
  --tw-shadow-color: #a855f740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/30 {
  --tw-shadow-color: #a855f74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/40 {
  --tw-shadow-color: #a855f766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/5 {
  --tw-shadow-color: #a855f70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/50 {
  --tw-shadow-color: #a855f780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/60 {
  --tw-shadow-color: #a855f799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/70 {
  --tw-shadow-color: #a855f7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/75 {
  --tw-shadow-color: #a855f7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/80 {
  --tw-shadow-color: #a855f7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/90 {
  --tw-shadow-color: #a855f7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/95 {
  --tw-shadow-color: #a855f7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600 {
  --tw-shadow-color: #9333ea;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/0 {
  --tw-shadow-color: #9333ea00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/10 {
  --tw-shadow-color: #9333ea1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/100 {
  --tw-shadow-color: #9333ea;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/20 {
  --tw-shadow-color: #9333ea33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/25 {
  --tw-shadow-color: #9333ea40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/30 {
  --tw-shadow-color: #9333ea4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/40 {
  --tw-shadow-color: #9333ea66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/5 {
  --tw-shadow-color: #9333ea0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/50 {
  --tw-shadow-color: #9333ea80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/60 {
  --tw-shadow-color: #9333ea99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/70 {
  --tw-shadow-color: #9333eab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/75 {
  --tw-shadow-color: #9333eabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/80 {
  --tw-shadow-color: #9333eacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/90 {
  --tw-shadow-color: #9333eae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/95 {
  --tw-shadow-color: #9333eaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700 {
  --tw-shadow-color: #7e22ce;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/0 {
  --tw-shadow-color: #7e22ce00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/10 {
  --tw-shadow-color: #7e22ce1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/100 {
  --tw-shadow-color: #7e22ce;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/20 {
  --tw-shadow-color: #7e22ce33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/25 {
  --tw-shadow-color: #7e22ce40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/30 {
  --tw-shadow-color: #7e22ce4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/40 {
  --tw-shadow-color: #7e22ce66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/5 {
  --tw-shadow-color: #7e22ce0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/50 {
  --tw-shadow-color: #7e22ce80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/60 {
  --tw-shadow-color: #7e22ce99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/70 {
  --tw-shadow-color: #7e22ceb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/75 {
  --tw-shadow-color: #7e22cebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/80 {
  --tw-shadow-color: #7e22cecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/90 {
  --tw-shadow-color: #7e22cee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/95 {
  --tw-shadow-color: #7e22cef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800 {
  --tw-shadow-color: #6b21a8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/0 {
  --tw-shadow-color: #6b21a800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/10 {
  --tw-shadow-color: #6b21a81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/100 {
  --tw-shadow-color: #6b21a8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/20 {
  --tw-shadow-color: #6b21a833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/25 {
  --tw-shadow-color: #6b21a840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/30 {
  --tw-shadow-color: #6b21a84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/40 {
  --tw-shadow-color: #6b21a866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/5 {
  --tw-shadow-color: #6b21a80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/50 {
  --tw-shadow-color: #6b21a880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/60 {
  --tw-shadow-color: #6b21a899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/70 {
  --tw-shadow-color: #6b21a8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/75 {
  --tw-shadow-color: #6b21a8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/80 {
  --tw-shadow-color: #6b21a8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/90 {
  --tw-shadow-color: #6b21a8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/95 {
  --tw-shadow-color: #6b21a8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900 {
  --tw-shadow-color: #581c87;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/0 {
  --tw-shadow-color: #581c8700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/10 {
  --tw-shadow-color: #581c871a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/100 {
  --tw-shadow-color: #581c87;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/20 {
  --tw-shadow-color: #581c8733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/25 {
  --tw-shadow-color: #581c8740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/30 {
  --tw-shadow-color: #581c874d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/40 {
  --tw-shadow-color: #581c8766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/5 {
  --tw-shadow-color: #581c870d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/50 {
  --tw-shadow-color: #581c8780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/60 {
  --tw-shadow-color: #581c8799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/70 {
  --tw-shadow-color: #581c87b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/75 {
  --tw-shadow-color: #581c87bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/80 {
  --tw-shadow-color: #581c87cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/90 {
  --tw-shadow-color: #581c87e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/95 {
  --tw-shadow-color: #581c87f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950 {
  --tw-shadow-color: #3b0764;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/0 {
  --tw-shadow-color: #3b076400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/10 {
  --tw-shadow-color: #3b07641a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/100 {
  --tw-shadow-color: #3b0764;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/20 {
  --tw-shadow-color: #3b076433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/25 {
  --tw-shadow-color: #3b076440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/30 {
  --tw-shadow-color: #3b07644d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/40 {
  --tw-shadow-color: #3b076466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/5 {
  --tw-shadow-color: #3b07640d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/50 {
  --tw-shadow-color: #3b076480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/60 {
  --tw-shadow-color: #3b076499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/70 {
  --tw-shadow-color: #3b0764b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/75 {
  --tw-shadow-color: #3b0764bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/80 {
  --tw-shadow-color: #3b0764cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/90 {
  --tw-shadow-color: #3b0764e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/95 {
  --tw-shadow-color: #3b0764f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100 {
  --tw-shadow-color: #fee2e2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/0 {
  --tw-shadow-color: #fee2e200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/10 {
  --tw-shadow-color: #fee2e21a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/100 {
  --tw-shadow-color: #fee2e2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/20 {
  --tw-shadow-color: #fee2e233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/25 {
  --tw-shadow-color: #fee2e240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/30 {
  --tw-shadow-color: #fee2e24d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/40 {
  --tw-shadow-color: #fee2e266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/5 {
  --tw-shadow-color: #fee2e20d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/50 {
  --tw-shadow-color: #fee2e280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/60 {
  --tw-shadow-color: #fee2e299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/70 {
  --tw-shadow-color: #fee2e2b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/75 {
  --tw-shadow-color: #fee2e2bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/80 {
  --tw-shadow-color: #fee2e2cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/90 {
  --tw-shadow-color: #fee2e2e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-100\/95 {
  --tw-shadow-color: #fee2e2f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200 {
  --tw-shadow-color: #fecaca;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/0 {
  --tw-shadow-color: #fecaca00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/10 {
  --tw-shadow-color: #fecaca1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/100 {
  --tw-shadow-color: #fecaca;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/20 {
  --tw-shadow-color: #fecaca33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/25 {
  --tw-shadow-color: #fecaca40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/30 {
  --tw-shadow-color: #fecaca4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/40 {
  --tw-shadow-color: #fecaca66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/5 {
  --tw-shadow-color: #fecaca0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/50 {
  --tw-shadow-color: #fecaca80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/60 {
  --tw-shadow-color: #fecaca99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/70 {
  --tw-shadow-color: #fecacab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/75 {
  --tw-shadow-color: #fecacabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/80 {
  --tw-shadow-color: #fecacacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/90 {
  --tw-shadow-color: #fecacae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-200\/95 {
  --tw-shadow-color: #fecacaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300 {
  --tw-shadow-color: #fca5a5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/0 {
  --tw-shadow-color: #fca5a500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/10 {
  --tw-shadow-color: #fca5a51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/100 {
  --tw-shadow-color: #fca5a5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/20 {
  --tw-shadow-color: #fca5a533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/25 {
  --tw-shadow-color: #fca5a540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/30 {
  --tw-shadow-color: #fca5a54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/40 {
  --tw-shadow-color: #fca5a566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/5 {
  --tw-shadow-color: #fca5a50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/50 {
  --tw-shadow-color: #fca5a580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/60 {
  --tw-shadow-color: #fca5a599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/70 {
  --tw-shadow-color: #fca5a5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/75 {
  --tw-shadow-color: #fca5a5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/80 {
  --tw-shadow-color: #fca5a5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/90 {
  --tw-shadow-color: #fca5a5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-300\/95 {
  --tw-shadow-color: #fca5a5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400 {
  --tw-shadow-color: #f87171;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/0 {
  --tw-shadow-color: #f8717100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/10 {
  --tw-shadow-color: #f871711a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/100 {
  --tw-shadow-color: #f87171;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/20 {
  --tw-shadow-color: #f8717133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/25 {
  --tw-shadow-color: #f8717140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/30 {
  --tw-shadow-color: #f871714d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/40 {
  --tw-shadow-color: #f8717166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/5 {
  --tw-shadow-color: #f871710d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/50 {
  --tw-shadow-color: #f8717180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/60 {
  --tw-shadow-color: #f8717199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/70 {
  --tw-shadow-color: #f87171b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/75 {
  --tw-shadow-color: #f87171bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/80 {
  --tw-shadow-color: #f87171cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/90 {
  --tw-shadow-color: #f87171e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-400\/95 {
  --tw-shadow-color: #f87171f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50 {
  --tw-shadow-color: #fef2f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/0 {
  --tw-shadow-color: #fef2f200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/10 {
  --tw-shadow-color: #fef2f21a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/100 {
  --tw-shadow-color: #fef2f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/20 {
  --tw-shadow-color: #fef2f233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/25 {
  --tw-shadow-color: #fef2f240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/30 {
  --tw-shadow-color: #fef2f24d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/40 {
  --tw-shadow-color: #fef2f266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/5 {
  --tw-shadow-color: #fef2f20d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/50 {
  --tw-shadow-color: #fef2f280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/60 {
  --tw-shadow-color: #fef2f299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/70 {
  --tw-shadow-color: #fef2f2b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/75 {
  --tw-shadow-color: #fef2f2bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/80 {
  --tw-shadow-color: #fef2f2cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/90 {
  --tw-shadow-color: #fef2f2e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-50\/95 {
  --tw-shadow-color: #fef2f2f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500 {
  --tw-shadow-color: #ef4444;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/0 {
  --tw-shadow-color: #ef444400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/10 {
  --tw-shadow-color: #ef44441a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/100 {
  --tw-shadow-color: #ef4444;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/20 {
  --tw-shadow-color: #ef444433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/25 {
  --tw-shadow-color: #ef444440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/30 {
  --tw-shadow-color: #ef44444d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/40 {
  --tw-shadow-color: #ef444466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/5 {
  --tw-shadow-color: #ef44440d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/50 {
  --tw-shadow-color: #ef444480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/60 {
  --tw-shadow-color: #ef444499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/70 {
  --tw-shadow-color: #ef4444b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/75 {
  --tw-shadow-color: #ef4444bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/80 {
  --tw-shadow-color: #ef4444cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/90 {
  --tw-shadow-color: #ef4444e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/95 {
  --tw-shadow-color: #ef4444f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600 {
  --tw-shadow-color: #dc2626;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/0 {
  --tw-shadow-color: #dc262600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/10 {
  --tw-shadow-color: #dc26261a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/100 {
  --tw-shadow-color: #dc2626;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/20 {
  --tw-shadow-color: #dc262633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/25 {
  --tw-shadow-color: #dc262640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/30 {
  --tw-shadow-color: #dc26264d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/40 {
  --tw-shadow-color: #dc262666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/5 {
  --tw-shadow-color: #dc26260d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/50 {
  --tw-shadow-color: #dc262680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/60 {
  --tw-shadow-color: #dc262699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/70 {
  --tw-shadow-color: #dc2626b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/75 {
  --tw-shadow-color: #dc2626bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/80 {
  --tw-shadow-color: #dc2626cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/90 {
  --tw-shadow-color: #dc2626e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-600\/95 {
  --tw-shadow-color: #dc2626f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700 {
  --tw-shadow-color: #b91c1c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/0 {
  --tw-shadow-color: #b91c1c00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/10 {
  --tw-shadow-color: #b91c1c1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/100 {
  --tw-shadow-color: #b91c1c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/20 {
  --tw-shadow-color: #b91c1c33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/25 {
  --tw-shadow-color: #b91c1c40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/30 {
  --tw-shadow-color: #b91c1c4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/40 {
  --tw-shadow-color: #b91c1c66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/5 {
  --tw-shadow-color: #b91c1c0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/50 {
  --tw-shadow-color: #b91c1c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/60 {
  --tw-shadow-color: #b91c1c99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/70 {
  --tw-shadow-color: #b91c1cb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/75 {
  --tw-shadow-color: #b91c1cbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/80 {
  --tw-shadow-color: #b91c1ccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/90 {
  --tw-shadow-color: #b91c1ce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-700\/95 {
  --tw-shadow-color: #b91c1cf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800 {
  --tw-shadow-color: #991b1b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/0 {
  --tw-shadow-color: #991b1b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/10 {
  --tw-shadow-color: #991b1b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/100 {
  --tw-shadow-color: #991b1b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/20 {
  --tw-shadow-color: #991b1b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/25 {
  --tw-shadow-color: #991b1b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/30 {
  --tw-shadow-color: #991b1b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/40 {
  --tw-shadow-color: #991b1b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/5 {
  --tw-shadow-color: #991b1b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/50 {
  --tw-shadow-color: #991b1b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/60 {
  --tw-shadow-color: #991b1b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/70 {
  --tw-shadow-color: #991b1bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/75 {
  --tw-shadow-color: #991b1bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/80 {
  --tw-shadow-color: #991b1bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/90 {
  --tw-shadow-color: #991b1be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-800\/95 {
  --tw-shadow-color: #991b1bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900 {
  --tw-shadow-color: #7f1d1d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/0 {
  --tw-shadow-color: #7f1d1d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/10 {
  --tw-shadow-color: #7f1d1d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/100 {
  --tw-shadow-color: #7f1d1d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/20 {
  --tw-shadow-color: #7f1d1d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/25 {
  --tw-shadow-color: #7f1d1d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/30 {
  --tw-shadow-color: #7f1d1d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/40 {
  --tw-shadow-color: #7f1d1d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/5 {
  --tw-shadow-color: #7f1d1d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/50 {
  --tw-shadow-color: #7f1d1d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/60 {
  --tw-shadow-color: #7f1d1d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/70 {
  --tw-shadow-color: #7f1d1db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/75 {
  --tw-shadow-color: #7f1d1dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/80 {
  --tw-shadow-color: #7f1d1dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/90 {
  --tw-shadow-color: #7f1d1de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-900\/95 {
  --tw-shadow-color: #7f1d1df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950 {
  --tw-shadow-color: #450a0a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/0 {
  --tw-shadow-color: #450a0a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/10 {
  --tw-shadow-color: #450a0a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/100 {
  --tw-shadow-color: #450a0a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/20 {
  --tw-shadow-color: #450a0a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/25 {
  --tw-shadow-color: #450a0a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/30 {
  --tw-shadow-color: #450a0a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/40 {
  --tw-shadow-color: #450a0a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/5 {
  --tw-shadow-color: #450a0a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/50 {
  --tw-shadow-color: #450a0a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/60 {
  --tw-shadow-color: #450a0a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/70 {
  --tw-shadow-color: #450a0ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/75 {
  --tw-shadow-color: #450a0abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/80 {
  --tw-shadow-color: #450a0acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/90 {
  --tw-shadow-color: #450a0ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-950\/95 {
  --tw-shadow-color: #450a0af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100 {
  --tw-shadow-color: #ffe4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/0 {
  --tw-shadow-color: #ffe4e600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/10 {
  --tw-shadow-color: #ffe4e61a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/100 {
  --tw-shadow-color: #ffe4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/20 {
  --tw-shadow-color: #ffe4e633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/25 {
  --tw-shadow-color: #ffe4e640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/30 {
  --tw-shadow-color: #ffe4e64d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/40 {
  --tw-shadow-color: #ffe4e666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/5 {
  --tw-shadow-color: #ffe4e60d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/50 {
  --tw-shadow-color: #ffe4e680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/60 {
  --tw-shadow-color: #ffe4e699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/70 {
  --tw-shadow-color: #ffe4e6b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/75 {
  --tw-shadow-color: #ffe4e6bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/80 {
  --tw-shadow-color: #ffe4e6cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/90 {
  --tw-shadow-color: #ffe4e6e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-100\/95 {
  --tw-shadow-color: #ffe4e6f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200 {
  --tw-shadow-color: #fecdd3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/0 {
  --tw-shadow-color: #fecdd300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/10 {
  --tw-shadow-color: #fecdd31a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/100 {
  --tw-shadow-color: #fecdd3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/20 {
  --tw-shadow-color: #fecdd333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/25 {
  --tw-shadow-color: #fecdd340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/30 {
  --tw-shadow-color: #fecdd34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/40 {
  --tw-shadow-color: #fecdd366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/5 {
  --tw-shadow-color: #fecdd30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/50 {
  --tw-shadow-color: #fecdd380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/60 {
  --tw-shadow-color: #fecdd399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/70 {
  --tw-shadow-color: #fecdd3b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/75 {
  --tw-shadow-color: #fecdd3bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/80 {
  --tw-shadow-color: #fecdd3cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/90 {
  --tw-shadow-color: #fecdd3e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-200\/95 {
  --tw-shadow-color: #fecdd3f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300 {
  --tw-shadow-color: #fda4af;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/0 {
  --tw-shadow-color: #fda4af00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/10 {
  --tw-shadow-color: #fda4af1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/100 {
  --tw-shadow-color: #fda4af;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/20 {
  --tw-shadow-color: #fda4af33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/25 {
  --tw-shadow-color: #fda4af40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/30 {
  --tw-shadow-color: #fda4af4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/40 {
  --tw-shadow-color: #fda4af66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/5 {
  --tw-shadow-color: #fda4af0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/50 {
  --tw-shadow-color: #fda4af80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/60 {
  --tw-shadow-color: #fda4af99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/70 {
  --tw-shadow-color: #fda4afb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/75 {
  --tw-shadow-color: #fda4afbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/80 {
  --tw-shadow-color: #fda4afcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/90 {
  --tw-shadow-color: #fda4afe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-300\/95 {
  --tw-shadow-color: #fda4aff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400 {
  --tw-shadow-color: #fb7185;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/0 {
  --tw-shadow-color: #fb718500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/10 {
  --tw-shadow-color: #fb71851a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/100 {
  --tw-shadow-color: #fb7185;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/20 {
  --tw-shadow-color: #fb718533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/25 {
  --tw-shadow-color: #fb718540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/30 {
  --tw-shadow-color: #fb71854d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/40 {
  --tw-shadow-color: #fb718566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/5 {
  --tw-shadow-color: #fb71850d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/50 {
  --tw-shadow-color: #fb718580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/60 {
  --tw-shadow-color: #fb718599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/70 {
  --tw-shadow-color: #fb7185b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/75 {
  --tw-shadow-color: #fb7185bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/80 {
  --tw-shadow-color: #fb7185cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/90 {
  --tw-shadow-color: #fb7185e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-400\/95 {
  --tw-shadow-color: #fb7185f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50 {
  --tw-shadow-color: #fff1f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/0 {
  --tw-shadow-color: #fff1f200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/10 {
  --tw-shadow-color: #fff1f21a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/100 {
  --tw-shadow-color: #fff1f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/20 {
  --tw-shadow-color: #fff1f233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/25 {
  --tw-shadow-color: #fff1f240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/30 {
  --tw-shadow-color: #fff1f24d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/40 {
  --tw-shadow-color: #fff1f266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/5 {
  --tw-shadow-color: #fff1f20d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/50 {
  --tw-shadow-color: #fff1f280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/60 {
  --tw-shadow-color: #fff1f299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/70 {
  --tw-shadow-color: #fff1f2b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/75 {
  --tw-shadow-color: #fff1f2bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/80 {
  --tw-shadow-color: #fff1f2cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/90 {
  --tw-shadow-color: #fff1f2e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-50\/95 {
  --tw-shadow-color: #fff1f2f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500 {
  --tw-shadow-color: #f43f5e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/0 {
  --tw-shadow-color: #f43f5e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/10 {
  --tw-shadow-color: #f43f5e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/100 {
  --tw-shadow-color: #f43f5e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/20 {
  --tw-shadow-color: #f43f5e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/25 {
  --tw-shadow-color: #f43f5e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/30 {
  --tw-shadow-color: #f43f5e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/40 {
  --tw-shadow-color: #f43f5e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/5 {
  --tw-shadow-color: #f43f5e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/50 {
  --tw-shadow-color: #f43f5e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/60 {
  --tw-shadow-color: #f43f5e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/70 {
  --tw-shadow-color: #f43f5eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/75 {
  --tw-shadow-color: #f43f5ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/80 {
  --tw-shadow-color: #f43f5ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/90 {
  --tw-shadow-color: #f43f5ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-500\/95 {
  --tw-shadow-color: #f43f5ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600 {
  --tw-shadow-color: #e11d48;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/0 {
  --tw-shadow-color: #e11d4800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/10 {
  --tw-shadow-color: #e11d481a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/100 {
  --tw-shadow-color: #e11d48;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/20 {
  --tw-shadow-color: #e11d4833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/25 {
  --tw-shadow-color: #e11d4840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/30 {
  --tw-shadow-color: #e11d484d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/40 {
  --tw-shadow-color: #e11d4866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/5 {
  --tw-shadow-color: #e11d480d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/50 {
  --tw-shadow-color: #e11d4880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/60 {
  --tw-shadow-color: #e11d4899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/70 {
  --tw-shadow-color: #e11d48b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/75 {
  --tw-shadow-color: #e11d48bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/80 {
  --tw-shadow-color: #e11d48cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/90 {
  --tw-shadow-color: #e11d48e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-600\/95 {
  --tw-shadow-color: #e11d48f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700 {
  --tw-shadow-color: #be123c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/0 {
  --tw-shadow-color: #be123c00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/10 {
  --tw-shadow-color: #be123c1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/100 {
  --tw-shadow-color: #be123c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/20 {
  --tw-shadow-color: #be123c33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/25 {
  --tw-shadow-color: #be123c40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/30 {
  --tw-shadow-color: #be123c4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/40 {
  --tw-shadow-color: #be123c66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/5 {
  --tw-shadow-color: #be123c0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/50 {
  --tw-shadow-color: #be123c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/60 {
  --tw-shadow-color: #be123c99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/70 {
  --tw-shadow-color: #be123cb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/75 {
  --tw-shadow-color: #be123cbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/80 {
  --tw-shadow-color: #be123ccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/90 {
  --tw-shadow-color: #be123ce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-700\/95 {
  --tw-shadow-color: #be123cf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800 {
  --tw-shadow-color: #9f1239;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/0 {
  --tw-shadow-color: #9f123900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/10 {
  --tw-shadow-color: #9f12391a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/100 {
  --tw-shadow-color: #9f1239;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/20 {
  --tw-shadow-color: #9f123933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/25 {
  --tw-shadow-color: #9f123940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/30 {
  --tw-shadow-color: #9f12394d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/40 {
  --tw-shadow-color: #9f123966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/5 {
  --tw-shadow-color: #9f12390d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/50 {
  --tw-shadow-color: #9f123980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/60 {
  --tw-shadow-color: #9f123999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/70 {
  --tw-shadow-color: #9f1239b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/75 {
  --tw-shadow-color: #9f1239bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/80 {
  --tw-shadow-color: #9f1239cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/90 {
  --tw-shadow-color: #9f1239e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-800\/95 {
  --tw-shadow-color: #9f1239f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900 {
  --tw-shadow-color: #881337;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/0 {
  --tw-shadow-color: #88133700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/10 {
  --tw-shadow-color: #8813371a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/100 {
  --tw-shadow-color: #881337;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/20 {
  --tw-shadow-color: #88133733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/25 {
  --tw-shadow-color: #88133740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/30 {
  --tw-shadow-color: #8813374d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/40 {
  --tw-shadow-color: #88133766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/5 {
  --tw-shadow-color: #8813370d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/50 {
  --tw-shadow-color: #88133780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/60 {
  --tw-shadow-color: #88133799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/70 {
  --tw-shadow-color: #881337b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/75 {
  --tw-shadow-color: #881337bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/80 {
  --tw-shadow-color: #881337cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/90 {
  --tw-shadow-color: #881337e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-900\/95 {
  --tw-shadow-color: #881337f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950 {
  --tw-shadow-color: #4c0519;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/0 {
  --tw-shadow-color: #4c051900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/10 {
  --tw-shadow-color: #4c05191a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/100 {
  --tw-shadow-color: #4c0519;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/20 {
  --tw-shadow-color: #4c051933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/25 {
  --tw-shadow-color: #4c051940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/30 {
  --tw-shadow-color: #4c05194d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/40 {
  --tw-shadow-color: #4c051966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/5 {
  --tw-shadow-color: #4c05190d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/50 {
  --tw-shadow-color: #4c051980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/60 {
  --tw-shadow-color: #4c051999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/70 {
  --tw-shadow-color: #4c0519b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/75 {
  --tw-shadow-color: #4c0519bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/80 {
  --tw-shadow-color: #4c0519cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/90 {
  --tw-shadow-color: #4c0519e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-rose-950\/95 {
  --tw-shadow-color: #4c0519f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100 {
  --tw-shadow-color: #e0f2fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/0 {
  --tw-shadow-color: #e0f2fe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/10 {
  --tw-shadow-color: #e0f2fe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/100 {
  --tw-shadow-color: #e0f2fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/20 {
  --tw-shadow-color: #e0f2fe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/25 {
  --tw-shadow-color: #e0f2fe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/30 {
  --tw-shadow-color: #e0f2fe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/40 {
  --tw-shadow-color: #e0f2fe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/5 {
  --tw-shadow-color: #e0f2fe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/50 {
  --tw-shadow-color: #e0f2fe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/60 {
  --tw-shadow-color: #e0f2fe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/70 {
  --tw-shadow-color: #e0f2feb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/75 {
  --tw-shadow-color: #e0f2febf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/80 {
  --tw-shadow-color: #e0f2fecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/90 {
  --tw-shadow-color: #e0f2fee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-100\/95 {
  --tw-shadow-color: #e0f2fef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200 {
  --tw-shadow-color: #bae6fd;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/0 {
  --tw-shadow-color: #bae6fd00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/10 {
  --tw-shadow-color: #bae6fd1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/100 {
  --tw-shadow-color: #bae6fd;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/20 {
  --tw-shadow-color: #bae6fd33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/25 {
  --tw-shadow-color: #bae6fd40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/30 {
  --tw-shadow-color: #bae6fd4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/40 {
  --tw-shadow-color: #bae6fd66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/5 {
  --tw-shadow-color: #bae6fd0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/50 {
  --tw-shadow-color: #bae6fd80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/60 {
  --tw-shadow-color: #bae6fd99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/70 {
  --tw-shadow-color: #bae6fdb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/75 {
  --tw-shadow-color: #bae6fdbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/80 {
  --tw-shadow-color: #bae6fdcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/90 {
  --tw-shadow-color: #bae6fde6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-200\/95 {
  --tw-shadow-color: #bae6fdf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300 {
  --tw-shadow-color: #7dd3fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/0 {
  --tw-shadow-color: #7dd3fc00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/10 {
  --tw-shadow-color: #7dd3fc1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/100 {
  --tw-shadow-color: #7dd3fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/20 {
  --tw-shadow-color: #7dd3fc33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/25 {
  --tw-shadow-color: #7dd3fc40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/30 {
  --tw-shadow-color: #7dd3fc4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/40 {
  --tw-shadow-color: #7dd3fc66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/5 {
  --tw-shadow-color: #7dd3fc0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/50 {
  --tw-shadow-color: #7dd3fc80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/60 {
  --tw-shadow-color: #7dd3fc99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/70 {
  --tw-shadow-color: #7dd3fcb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/75 {
  --tw-shadow-color: #7dd3fcbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/80 {
  --tw-shadow-color: #7dd3fccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/90 {
  --tw-shadow-color: #7dd3fce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-300\/95 {
  --tw-shadow-color: #7dd3fcf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400 {
  --tw-shadow-color: #38bdf8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/0 {
  --tw-shadow-color: #38bdf800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/10 {
  --tw-shadow-color: #38bdf81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/100 {
  --tw-shadow-color: #38bdf8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/20 {
  --tw-shadow-color: #38bdf833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/25 {
  --tw-shadow-color: #38bdf840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/30 {
  --tw-shadow-color: #38bdf84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/40 {
  --tw-shadow-color: #38bdf866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/5 {
  --tw-shadow-color: #38bdf80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/50 {
  --tw-shadow-color: #38bdf880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/60 {
  --tw-shadow-color: #38bdf899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/70 {
  --tw-shadow-color: #38bdf8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/75 {
  --tw-shadow-color: #38bdf8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/80 {
  --tw-shadow-color: #38bdf8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/90 {
  --tw-shadow-color: #38bdf8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-400\/95 {
  --tw-shadow-color: #38bdf8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50 {
  --tw-shadow-color: #f0f9ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/0 {
  --tw-shadow-color: #f0f9ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/10 {
  --tw-shadow-color: #f0f9ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/100 {
  --tw-shadow-color: #f0f9ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/20 {
  --tw-shadow-color: #f0f9ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/25 {
  --tw-shadow-color: #f0f9ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/30 {
  --tw-shadow-color: #f0f9ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/40 {
  --tw-shadow-color: #f0f9ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/5 {
  --tw-shadow-color: #f0f9ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/50 {
  --tw-shadow-color: #f0f9ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/60 {
  --tw-shadow-color: #f0f9ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/70 {
  --tw-shadow-color: #f0f9ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/75 {
  --tw-shadow-color: #f0f9ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/80 {
  --tw-shadow-color: #f0f9ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/90 {
  --tw-shadow-color: #f0f9ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-50\/95 {
  --tw-shadow-color: #f0f9fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500 {
  --tw-shadow-color: #0ea5e9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/0 {
  --tw-shadow-color: #0ea5e900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/10 {
  --tw-shadow-color: #0ea5e91a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/100 {
  --tw-shadow-color: #0ea5e9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/20 {
  --tw-shadow-color: #0ea5e933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/25 {
  --tw-shadow-color: #0ea5e940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/30 {
  --tw-shadow-color: #0ea5e94d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/40 {
  --tw-shadow-color: #0ea5e966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/5 {
  --tw-shadow-color: #0ea5e90d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/50 {
  --tw-shadow-color: #0ea5e980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/60 {
  --tw-shadow-color: #0ea5e999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/70 {
  --tw-shadow-color: #0ea5e9b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/75 {
  --tw-shadow-color: #0ea5e9bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/80 {
  --tw-shadow-color: #0ea5e9cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/90 {
  --tw-shadow-color: #0ea5e9e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-500\/95 {
  --tw-shadow-color: #0ea5e9f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600 {
  --tw-shadow-color: #0284c7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/0 {
  --tw-shadow-color: #0284c700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/10 {
  --tw-shadow-color: #0284c71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/100 {
  --tw-shadow-color: #0284c7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/20 {
  --tw-shadow-color: #0284c733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/25 {
  --tw-shadow-color: #0284c740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/30 {
  --tw-shadow-color: #0284c74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/40 {
  --tw-shadow-color: #0284c766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/5 {
  --tw-shadow-color: #0284c70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/50 {
  --tw-shadow-color: #0284c780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/60 {
  --tw-shadow-color: #0284c799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/70 {
  --tw-shadow-color: #0284c7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/75 {
  --tw-shadow-color: #0284c7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/80 {
  --tw-shadow-color: #0284c7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/90 {
  --tw-shadow-color: #0284c7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-600\/95 {
  --tw-shadow-color: #0284c7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700 {
  --tw-shadow-color: #0369a1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/0 {
  --tw-shadow-color: #0369a100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/10 {
  --tw-shadow-color: #0369a11a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/100 {
  --tw-shadow-color: #0369a1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/20 {
  --tw-shadow-color: #0369a133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/25 {
  --tw-shadow-color: #0369a140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/30 {
  --tw-shadow-color: #0369a14d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/40 {
  --tw-shadow-color: #0369a166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/5 {
  --tw-shadow-color: #0369a10d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/50 {
  --tw-shadow-color: #0369a180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/60 {
  --tw-shadow-color: #0369a199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/70 {
  --tw-shadow-color: #0369a1b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/75 {
  --tw-shadow-color: #0369a1bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/80 {
  --tw-shadow-color: #0369a1cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/90 {
  --tw-shadow-color: #0369a1e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-700\/95 {
  --tw-shadow-color: #0369a1f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800 {
  --tw-shadow-color: #075985;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/0 {
  --tw-shadow-color: #07598500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/10 {
  --tw-shadow-color: #0759851a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/100 {
  --tw-shadow-color: #075985;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/20 {
  --tw-shadow-color: #07598533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/25 {
  --tw-shadow-color: #07598540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/30 {
  --tw-shadow-color: #0759854d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/40 {
  --tw-shadow-color: #07598566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/5 {
  --tw-shadow-color: #0759850d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/50 {
  --tw-shadow-color: #07598580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/60 {
  --tw-shadow-color: #07598599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/70 {
  --tw-shadow-color: #075985b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/75 {
  --tw-shadow-color: #075985bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/80 {
  --tw-shadow-color: #075985cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/90 {
  --tw-shadow-color: #075985e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-800\/95 {
  --tw-shadow-color: #075985f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900 {
  --tw-shadow-color: #0c4a6e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/0 {
  --tw-shadow-color: #0c4a6e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/10 {
  --tw-shadow-color: #0c4a6e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/100 {
  --tw-shadow-color: #0c4a6e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/20 {
  --tw-shadow-color: #0c4a6e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/25 {
  --tw-shadow-color: #0c4a6e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/30 {
  --tw-shadow-color: #0c4a6e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/40 {
  --tw-shadow-color: #0c4a6e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/5 {
  --tw-shadow-color: #0c4a6e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/50 {
  --tw-shadow-color: #0c4a6e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/60 {
  --tw-shadow-color: #0c4a6e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/70 {
  --tw-shadow-color: #0c4a6eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/75 {
  --tw-shadow-color: #0c4a6ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/80 {
  --tw-shadow-color: #0c4a6ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/90 {
  --tw-shadow-color: #0c4a6ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-900\/95 {
  --tw-shadow-color: #0c4a6ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950 {
  --tw-shadow-color: #082f49;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/0 {
  --tw-shadow-color: #082f4900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/10 {
  --tw-shadow-color: #082f491a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/100 {
  --tw-shadow-color: #082f49;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/20 {
  --tw-shadow-color: #082f4933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/25 {
  --tw-shadow-color: #082f4940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/30 {
  --tw-shadow-color: #082f494d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/40 {
  --tw-shadow-color: #082f4966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/5 {
  --tw-shadow-color: #082f490d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/50 {
  --tw-shadow-color: #082f4980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/60 {
  --tw-shadow-color: #082f4999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/70 {
  --tw-shadow-color: #082f49b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/75 {
  --tw-shadow-color: #082f49bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/80 {
  --tw-shadow-color: #082f49cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/90 {
  --tw-shadow-color: #082f49e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-sky-950\/95 {
  --tw-shadow-color: #082f49f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100 {
  --tw-shadow-color: #f1f5f9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/0 {
  --tw-shadow-color: #f1f5f900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/10 {
  --tw-shadow-color: #f1f5f91a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/100 {
  --tw-shadow-color: #f1f5f9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/20 {
  --tw-shadow-color: #f1f5f933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/25 {
  --tw-shadow-color: #f1f5f940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/30 {
  --tw-shadow-color: #f1f5f94d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/40 {
  --tw-shadow-color: #f1f5f966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/5 {
  --tw-shadow-color: #f1f5f90d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/50 {
  --tw-shadow-color: #f1f5f980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/60 {
  --tw-shadow-color: #f1f5f999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/70 {
  --tw-shadow-color: #f1f5f9b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/75 {
  --tw-shadow-color: #f1f5f9bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/80 {
  --tw-shadow-color: #f1f5f9cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/90 {
  --tw-shadow-color: #f1f5f9e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-100\/95 {
  --tw-shadow-color: #f1f5f9f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200 {
  --tw-shadow-color: #e2e8f0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/0 {
  --tw-shadow-color: #e2e8f000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/10 {
  --tw-shadow-color: #e2e8f01a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/100 {
  --tw-shadow-color: #e2e8f0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/20 {
  --tw-shadow-color: #e2e8f033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/25 {
  --tw-shadow-color: #e2e8f040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/30 {
  --tw-shadow-color: #e2e8f04d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/40 {
  --tw-shadow-color: #e2e8f066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/5 {
  --tw-shadow-color: #e2e8f00d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/50 {
  --tw-shadow-color: #e2e8f080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/60 {
  --tw-shadow-color: #e2e8f099;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/70 {
  --tw-shadow-color: #e2e8f0b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/75 {
  --tw-shadow-color: #e2e8f0bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/80 {
  --tw-shadow-color: #e2e8f0cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/90 {
  --tw-shadow-color: #e2e8f0e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-200\/95 {
  --tw-shadow-color: #e2e8f0f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300 {
  --tw-shadow-color: #cbd5e1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/0 {
  --tw-shadow-color: #cbd5e100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/10 {
  --tw-shadow-color: #cbd5e11a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/100 {
  --tw-shadow-color: #cbd5e1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/20 {
  --tw-shadow-color: #cbd5e133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/25 {
  --tw-shadow-color: #cbd5e140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/30 {
  --tw-shadow-color: #cbd5e14d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/40 {
  --tw-shadow-color: #cbd5e166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/5 {
  --tw-shadow-color: #cbd5e10d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/50 {
  --tw-shadow-color: #cbd5e180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/60 {
  --tw-shadow-color: #cbd5e199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/70 {
  --tw-shadow-color: #cbd5e1b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/75 {
  --tw-shadow-color: #cbd5e1bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/80 {
  --tw-shadow-color: #cbd5e1cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/90 {
  --tw-shadow-color: #cbd5e1e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-300\/95 {
  --tw-shadow-color: #cbd5e1f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400 {
  --tw-shadow-color: #94a3b8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/0 {
  --tw-shadow-color: #94a3b800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/10 {
  --tw-shadow-color: #94a3b81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/100 {
  --tw-shadow-color: #94a3b8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/20 {
  --tw-shadow-color: #94a3b833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/25 {
  --tw-shadow-color: #94a3b840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/30 {
  --tw-shadow-color: #94a3b84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/40 {
  --tw-shadow-color: #94a3b866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/5 {
  --tw-shadow-color: #94a3b80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/50 {
  --tw-shadow-color: #94a3b880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/60 {
  --tw-shadow-color: #94a3b899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/70 {
  --tw-shadow-color: #94a3b8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/75 {
  --tw-shadow-color: #94a3b8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/80 {
  --tw-shadow-color: #94a3b8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/90 {
  --tw-shadow-color: #94a3b8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-400\/95 {
  --tw-shadow-color: #94a3b8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50 {
  --tw-shadow-color: #f8fafc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/0 {
  --tw-shadow-color: #f8fafc00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/10 {
  --tw-shadow-color: #f8fafc1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/100 {
  --tw-shadow-color: #f8fafc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/20 {
  --tw-shadow-color: #f8fafc33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/25 {
  --tw-shadow-color: #f8fafc40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/30 {
  --tw-shadow-color: #f8fafc4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/40 {
  --tw-shadow-color: #f8fafc66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/5 {
  --tw-shadow-color: #f8fafc0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/50 {
  --tw-shadow-color: #f8fafc80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/60 {
  --tw-shadow-color: #f8fafc99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/70 {
  --tw-shadow-color: #f8fafcb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/75 {
  --tw-shadow-color: #f8fafcbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/80 {
  --tw-shadow-color: #f8fafccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/90 {
  --tw-shadow-color: #f8fafce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-50\/95 {
  --tw-shadow-color: #f8fafcf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500 {
  --tw-shadow-color: #64748b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/0 {
  --tw-shadow-color: #64748b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/10 {
  --tw-shadow-color: #64748b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/100 {
  --tw-shadow-color: #64748b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/20 {
  --tw-shadow-color: #64748b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/25 {
  --tw-shadow-color: #64748b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/30 {
  --tw-shadow-color: #64748b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/40 {
  --tw-shadow-color: #64748b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/5 {
  --tw-shadow-color: #64748b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/50 {
  --tw-shadow-color: #64748b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/60 {
  --tw-shadow-color: #64748b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/70 {
  --tw-shadow-color: #64748bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/75 {
  --tw-shadow-color: #64748bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/80 {
  --tw-shadow-color: #64748bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/90 {
  --tw-shadow-color: #64748be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-500\/95 {
  --tw-shadow-color: #64748bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600 {
  --tw-shadow-color: #475569;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/0 {
  --tw-shadow-color: #47556900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/10 {
  --tw-shadow-color: #4755691a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/100 {
  --tw-shadow-color: #475569;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/20 {
  --tw-shadow-color: #47556933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/25 {
  --tw-shadow-color: #47556940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/30 {
  --tw-shadow-color: #4755694d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/40 {
  --tw-shadow-color: #47556966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/5 {
  --tw-shadow-color: #4755690d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/50 {
  --tw-shadow-color: #47556980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/60 {
  --tw-shadow-color: #47556999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/70 {
  --tw-shadow-color: #475569b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/75 {
  --tw-shadow-color: #475569bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/80 {
  --tw-shadow-color: #475569cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/90 {
  --tw-shadow-color: #475569e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-600\/95 {
  --tw-shadow-color: #475569f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700 {
  --tw-shadow-color: #334155;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/0 {
  --tw-shadow-color: #33415500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/10 {
  --tw-shadow-color: #3341551a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/100 {
  --tw-shadow-color: #334155;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/20 {
  --tw-shadow-color: #33415533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/25 {
  --tw-shadow-color: #33415540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/30 {
  --tw-shadow-color: #3341554d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/40 {
  --tw-shadow-color: #33415566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/5 {
  --tw-shadow-color: #3341550d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/50 {
  --tw-shadow-color: #33415580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/60 {
  --tw-shadow-color: #33415599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/70 {
  --tw-shadow-color: #334155b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/75 {
  --tw-shadow-color: #334155bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/80 {
  --tw-shadow-color: #334155cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/90 {
  --tw-shadow-color: #334155e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-700\/95 {
  --tw-shadow-color: #334155f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800 {
  --tw-shadow-color: #1e293b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/0 {
  --tw-shadow-color: #1e293b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/10 {
  --tw-shadow-color: #1e293b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/100 {
  --tw-shadow-color: #1e293b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/20 {
  --tw-shadow-color: #1e293b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/25 {
  --tw-shadow-color: #1e293b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/30 {
  --tw-shadow-color: #1e293b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/40 {
  --tw-shadow-color: #1e293b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/5 {
  --tw-shadow-color: #1e293b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/50 {
  --tw-shadow-color: #1e293b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/60 {
  --tw-shadow-color: #1e293b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/70 {
  --tw-shadow-color: #1e293bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/75 {
  --tw-shadow-color: #1e293bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/80 {
  --tw-shadow-color: #1e293bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/90 {
  --tw-shadow-color: #1e293be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-800\/95 {
  --tw-shadow-color: #1e293bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900 {
  --tw-shadow-color: #0f172a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/0 {
  --tw-shadow-color: #0f172a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/10 {
  --tw-shadow-color: #0f172a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/100 {
  --tw-shadow-color: #0f172a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/20 {
  --tw-shadow-color: #0f172a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/25 {
  --tw-shadow-color: #0f172a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/30 {
  --tw-shadow-color: #0f172a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/40 {
  --tw-shadow-color: #0f172a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/5 {
  --tw-shadow-color: #0f172a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/50 {
  --tw-shadow-color: #0f172a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/60 {
  --tw-shadow-color: #0f172a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/70 {
  --tw-shadow-color: #0f172ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/75 {
  --tw-shadow-color: #0f172abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/80 {
  --tw-shadow-color: #0f172acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/90 {
  --tw-shadow-color: #0f172ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-900\/95 {
  --tw-shadow-color: #0f172af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950 {
  --tw-shadow-color: #020617;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/0 {
  --tw-shadow-color: #02061700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/10 {
  --tw-shadow-color: #0206171a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/100 {
  --tw-shadow-color: #020617;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/20 {
  --tw-shadow-color: #02061733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/25 {
  --tw-shadow-color: #02061740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/30 {
  --tw-shadow-color: #0206174d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/40 {
  --tw-shadow-color: #02061766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/5 {
  --tw-shadow-color: #0206170d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/50 {
  --tw-shadow-color: #02061780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/60 {
  --tw-shadow-color: #02061799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/70 {
  --tw-shadow-color: #020617b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/75 {
  --tw-shadow-color: #020617bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/80 {
  --tw-shadow-color: #020617cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/90 {
  --tw-shadow-color: #020617e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-slate-950\/95 {
  --tw-shadow-color: #020617f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100 {
  --tw-shadow-color: #f5f5f4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/0 {
  --tw-shadow-color: #f5f5f400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/10 {
  --tw-shadow-color: #f5f5f41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/100 {
  --tw-shadow-color: #f5f5f4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/20 {
  --tw-shadow-color: #f5f5f433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/25 {
  --tw-shadow-color: #f5f5f440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/30 {
  --tw-shadow-color: #f5f5f44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/40 {
  --tw-shadow-color: #f5f5f466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/5 {
  --tw-shadow-color: #f5f5f40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/50 {
  --tw-shadow-color: #f5f5f480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/60 {
  --tw-shadow-color: #f5f5f499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/70 {
  --tw-shadow-color: #f5f5f4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/75 {
  --tw-shadow-color: #f5f5f4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/80 {
  --tw-shadow-color: #f5f5f4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/90 {
  --tw-shadow-color: #f5f5f4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-100\/95 {
  --tw-shadow-color: #f5f5f4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200 {
  --tw-shadow-color: #e7e5e4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/0 {
  --tw-shadow-color: #e7e5e400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/10 {
  --tw-shadow-color: #e7e5e41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/100 {
  --tw-shadow-color: #e7e5e4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/20 {
  --tw-shadow-color: #e7e5e433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/25 {
  --tw-shadow-color: #e7e5e440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/30 {
  --tw-shadow-color: #e7e5e44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/40 {
  --tw-shadow-color: #e7e5e466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/5 {
  --tw-shadow-color: #e7e5e40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/50 {
  --tw-shadow-color: #e7e5e480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/60 {
  --tw-shadow-color: #e7e5e499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/70 {
  --tw-shadow-color: #e7e5e4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/75 {
  --tw-shadow-color: #e7e5e4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/80 {
  --tw-shadow-color: #e7e5e4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/90 {
  --tw-shadow-color: #e7e5e4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-200\/95 {
  --tw-shadow-color: #e7e5e4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300 {
  --tw-shadow-color: #d6d3d1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/0 {
  --tw-shadow-color: #d6d3d100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/10 {
  --tw-shadow-color: #d6d3d11a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/100 {
  --tw-shadow-color: #d6d3d1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/20 {
  --tw-shadow-color: #d6d3d133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/25 {
  --tw-shadow-color: #d6d3d140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/30 {
  --tw-shadow-color: #d6d3d14d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/40 {
  --tw-shadow-color: #d6d3d166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/5 {
  --tw-shadow-color: #d6d3d10d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/50 {
  --tw-shadow-color: #d6d3d180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/60 {
  --tw-shadow-color: #d6d3d199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/70 {
  --tw-shadow-color: #d6d3d1b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/75 {
  --tw-shadow-color: #d6d3d1bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/80 {
  --tw-shadow-color: #d6d3d1cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/90 {
  --tw-shadow-color: #d6d3d1e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-300\/95 {
  --tw-shadow-color: #d6d3d1f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400 {
  --tw-shadow-color: #a8a29e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/0 {
  --tw-shadow-color: #a8a29e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/10 {
  --tw-shadow-color: #a8a29e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/100 {
  --tw-shadow-color: #a8a29e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/20 {
  --tw-shadow-color: #a8a29e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/25 {
  --tw-shadow-color: #a8a29e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/30 {
  --tw-shadow-color: #a8a29e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/40 {
  --tw-shadow-color: #a8a29e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/5 {
  --tw-shadow-color: #a8a29e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/50 {
  --tw-shadow-color: #a8a29e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/60 {
  --tw-shadow-color: #a8a29e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/70 {
  --tw-shadow-color: #a8a29eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/75 {
  --tw-shadow-color: #a8a29ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/80 {
  --tw-shadow-color: #a8a29ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/90 {
  --tw-shadow-color: #a8a29ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-400\/95 {
  --tw-shadow-color: #a8a29ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50 {
  --tw-shadow-color: #fafaf9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/0 {
  --tw-shadow-color: #fafaf900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/10 {
  --tw-shadow-color: #fafaf91a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/100 {
  --tw-shadow-color: #fafaf9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/20 {
  --tw-shadow-color: #fafaf933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/25 {
  --tw-shadow-color: #fafaf940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/30 {
  --tw-shadow-color: #fafaf94d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/40 {
  --tw-shadow-color: #fafaf966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/5 {
  --tw-shadow-color: #fafaf90d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/50 {
  --tw-shadow-color: #fafaf980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/60 {
  --tw-shadow-color: #fafaf999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/70 {
  --tw-shadow-color: #fafaf9b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/75 {
  --tw-shadow-color: #fafaf9bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/80 {
  --tw-shadow-color: #fafaf9cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/90 {
  --tw-shadow-color: #fafaf9e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-50\/95 {
  --tw-shadow-color: #fafaf9f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500 {
  --tw-shadow-color: #78716c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/0 {
  --tw-shadow-color: #78716c00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/10 {
  --tw-shadow-color: #78716c1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/100 {
  --tw-shadow-color: #78716c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/20 {
  --tw-shadow-color: #78716c33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/25 {
  --tw-shadow-color: #78716c40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/30 {
  --tw-shadow-color: #78716c4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/40 {
  --tw-shadow-color: #78716c66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/5 {
  --tw-shadow-color: #78716c0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/50 {
  --tw-shadow-color: #78716c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/60 {
  --tw-shadow-color: #78716c99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/70 {
  --tw-shadow-color: #78716cb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/75 {
  --tw-shadow-color: #78716cbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/80 {
  --tw-shadow-color: #78716ccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/90 {
  --tw-shadow-color: #78716ce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-500\/95 {
  --tw-shadow-color: #78716cf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600 {
  --tw-shadow-color: #57534e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/0 {
  --tw-shadow-color: #57534e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/10 {
  --tw-shadow-color: #57534e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/100 {
  --tw-shadow-color: #57534e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/20 {
  --tw-shadow-color: #57534e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/25 {
  --tw-shadow-color: #57534e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/30 {
  --tw-shadow-color: #57534e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/40 {
  --tw-shadow-color: #57534e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/5 {
  --tw-shadow-color: #57534e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/50 {
  --tw-shadow-color: #57534e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/60 {
  --tw-shadow-color: #57534e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/70 {
  --tw-shadow-color: #57534eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/75 {
  --tw-shadow-color: #57534ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/80 {
  --tw-shadow-color: #57534ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/90 {
  --tw-shadow-color: #57534ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-600\/95 {
  --tw-shadow-color: #57534ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700 {
  --tw-shadow-color: #44403c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/0 {
  --tw-shadow-color: #44403c00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/10 {
  --tw-shadow-color: #44403c1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/100 {
  --tw-shadow-color: #44403c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/20 {
  --tw-shadow-color: #44403c33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/25 {
  --tw-shadow-color: #44403c40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/30 {
  --tw-shadow-color: #44403c4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/40 {
  --tw-shadow-color: #44403c66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/5 {
  --tw-shadow-color: #44403c0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/50 {
  --tw-shadow-color: #44403c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/60 {
  --tw-shadow-color: #44403c99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/70 {
  --tw-shadow-color: #44403cb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/75 {
  --tw-shadow-color: #44403cbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/80 {
  --tw-shadow-color: #44403ccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/90 {
  --tw-shadow-color: #44403ce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-700\/95 {
  --tw-shadow-color: #44403cf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800 {
  --tw-shadow-color: #292524;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/0 {
  --tw-shadow-color: #29252400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/10 {
  --tw-shadow-color: #2925241a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/100 {
  --tw-shadow-color: #292524;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/20 {
  --tw-shadow-color: #29252433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/25 {
  --tw-shadow-color: #29252440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/30 {
  --tw-shadow-color: #2925244d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/40 {
  --tw-shadow-color: #29252466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/5 {
  --tw-shadow-color: #2925240d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/50 {
  --tw-shadow-color: #29252480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/60 {
  --tw-shadow-color: #29252499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/70 {
  --tw-shadow-color: #292524b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/75 {
  --tw-shadow-color: #292524bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/80 {
  --tw-shadow-color: #292524cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/90 {
  --tw-shadow-color: #292524e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-800\/95 {
  --tw-shadow-color: #292524f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900 {
  --tw-shadow-color: #1c1917;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/0 {
  --tw-shadow-color: #1c191700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/10 {
  --tw-shadow-color: #1c19171a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/100 {
  --tw-shadow-color: #1c1917;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/20 {
  --tw-shadow-color: #1c191733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/25 {
  --tw-shadow-color: #1c191740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/30 {
  --tw-shadow-color: #1c19174d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/40 {
  --tw-shadow-color: #1c191766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/5 {
  --tw-shadow-color: #1c19170d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/50 {
  --tw-shadow-color: #1c191780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/60 {
  --tw-shadow-color: #1c191799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/70 {
  --tw-shadow-color: #1c1917b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/75 {
  --tw-shadow-color: #1c1917bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/80 {
  --tw-shadow-color: #1c1917cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/90 {
  --tw-shadow-color: #1c1917e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-900\/95 {
  --tw-shadow-color: #1c1917f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950 {
  --tw-shadow-color: #0c0a09;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/0 {
  --tw-shadow-color: #0c0a0900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/10 {
  --tw-shadow-color: #0c0a091a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/100 {
  --tw-shadow-color: #0c0a09;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/20 {
  --tw-shadow-color: #0c0a0933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/25 {
  --tw-shadow-color: #0c0a0940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/30 {
  --tw-shadow-color: #0c0a094d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/40 {
  --tw-shadow-color: #0c0a0966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/5 {
  --tw-shadow-color: #0c0a090d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/50 {
  --tw-shadow-color: #0c0a0980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/60 {
  --tw-shadow-color: #0c0a0999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/70 {
  --tw-shadow-color: #0c0a09b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/75 {
  --tw-shadow-color: #0c0a09bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/80 {
  --tw-shadow-color: #0c0a09cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/90 {
  --tw-shadow-color: #0c0a09e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-stone-950\/95 {
  --tw-shadow-color: #0c0a09f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100 {
  --tw-shadow-color: #ccfbf1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/0 {
  --tw-shadow-color: #ccfbf100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/10 {
  --tw-shadow-color: #ccfbf11a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/100 {
  --tw-shadow-color: #ccfbf1;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/20 {
  --tw-shadow-color: #ccfbf133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/25 {
  --tw-shadow-color: #ccfbf140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/30 {
  --tw-shadow-color: #ccfbf14d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/40 {
  --tw-shadow-color: #ccfbf166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/5 {
  --tw-shadow-color: #ccfbf10d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/50 {
  --tw-shadow-color: #ccfbf180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/60 {
  --tw-shadow-color: #ccfbf199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/70 {
  --tw-shadow-color: #ccfbf1b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/75 {
  --tw-shadow-color: #ccfbf1bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/80 {
  --tw-shadow-color: #ccfbf1cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/90 {
  --tw-shadow-color: #ccfbf1e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100\/95 {
  --tw-shadow-color: #ccfbf1f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200 {
  --tw-shadow-color: #99f6e4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/0 {
  --tw-shadow-color: #99f6e400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/10 {
  --tw-shadow-color: #99f6e41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/100 {
  --tw-shadow-color: #99f6e4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/20 {
  --tw-shadow-color: #99f6e433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/25 {
  --tw-shadow-color: #99f6e440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/30 {
  --tw-shadow-color: #99f6e44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/40 {
  --tw-shadow-color: #99f6e466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/5 {
  --tw-shadow-color: #99f6e40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/50 {
  --tw-shadow-color: #99f6e480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/60 {
  --tw-shadow-color: #99f6e499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/70 {
  --tw-shadow-color: #99f6e4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/75 {
  --tw-shadow-color: #99f6e4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/80 {
  --tw-shadow-color: #99f6e4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/90 {
  --tw-shadow-color: #99f6e4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-200\/95 {
  --tw-shadow-color: #99f6e4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300 {
  --tw-shadow-color: #5eead4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/0 {
  --tw-shadow-color: #5eead400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/10 {
  --tw-shadow-color: #5eead41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/100 {
  --tw-shadow-color: #5eead4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/20 {
  --tw-shadow-color: #5eead433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/25 {
  --tw-shadow-color: #5eead440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/30 {
  --tw-shadow-color: #5eead44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/40 {
  --tw-shadow-color: #5eead466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/5 {
  --tw-shadow-color: #5eead40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/50 {
  --tw-shadow-color: #5eead480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/60 {
  --tw-shadow-color: #5eead499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/70 {
  --tw-shadow-color: #5eead4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/75 {
  --tw-shadow-color: #5eead4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/80 {
  --tw-shadow-color: #5eead4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/90 {
  --tw-shadow-color: #5eead4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-300\/95 {
  --tw-shadow-color: #5eead4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400 {
  --tw-shadow-color: #2dd4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/0 {
  --tw-shadow-color: #2dd4bf00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/10 {
  --tw-shadow-color: #2dd4bf1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/100 {
  --tw-shadow-color: #2dd4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/20 {
  --tw-shadow-color: #2dd4bf33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/25 {
  --tw-shadow-color: #2dd4bf40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/30 {
  --tw-shadow-color: #2dd4bf4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/40 {
  --tw-shadow-color: #2dd4bf66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/5 {
  --tw-shadow-color: #2dd4bf0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/50 {
  --tw-shadow-color: #2dd4bf80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/60 {
  --tw-shadow-color: #2dd4bf99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/70 {
  --tw-shadow-color: #2dd4bfb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/75 {
  --tw-shadow-color: #2dd4bfbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/80 {
  --tw-shadow-color: #2dd4bfcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/90 {
  --tw-shadow-color: #2dd4bfe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-400\/95 {
  --tw-shadow-color: #2dd4bff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50 {
  --tw-shadow-color: #f0fdfa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/0 {
  --tw-shadow-color: #f0fdfa00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/10 {
  --tw-shadow-color: #f0fdfa1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/100 {
  --tw-shadow-color: #f0fdfa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/20 {
  --tw-shadow-color: #f0fdfa33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/25 {
  --tw-shadow-color: #f0fdfa40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/30 {
  --tw-shadow-color: #f0fdfa4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/40 {
  --tw-shadow-color: #f0fdfa66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/5 {
  --tw-shadow-color: #f0fdfa0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/50 {
  --tw-shadow-color: #f0fdfa80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/60 {
  --tw-shadow-color: #f0fdfa99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/70 {
  --tw-shadow-color: #f0fdfab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/75 {
  --tw-shadow-color: #f0fdfabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/80 {
  --tw-shadow-color: #f0fdfacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/90 {
  --tw-shadow-color: #f0fdfae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-50\/95 {
  --tw-shadow-color: #f0fdfaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500 {
  --tw-shadow-color: #14b8a6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/0 {
  --tw-shadow-color: #14b8a600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/10 {
  --tw-shadow-color: #14b8a61a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/100 {
  --tw-shadow-color: #14b8a6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/20 {
  --tw-shadow-color: #14b8a633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/25 {
  --tw-shadow-color: #14b8a640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/30 {
  --tw-shadow-color: #14b8a64d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/40 {
  --tw-shadow-color: #14b8a666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/5 {
  --tw-shadow-color: #14b8a60d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/50 {
  --tw-shadow-color: #14b8a680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/60 {
  --tw-shadow-color: #14b8a699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/70 {
  --tw-shadow-color: #14b8a6b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/75 {
  --tw-shadow-color: #14b8a6bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/80 {
  --tw-shadow-color: #14b8a6cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/90 {
  --tw-shadow-color: #14b8a6e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/95 {
  --tw-shadow-color: #14b8a6f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600 {
  --tw-shadow-color: #0d9488;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/0 {
  --tw-shadow-color: #0d948800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/10 {
  --tw-shadow-color: #0d94881a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/100 {
  --tw-shadow-color: #0d9488;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/20 {
  --tw-shadow-color: #0d948833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/25 {
  --tw-shadow-color: #0d948840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/30 {
  --tw-shadow-color: #0d94884d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/40 {
  --tw-shadow-color: #0d948866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/5 {
  --tw-shadow-color: #0d94880d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/50 {
  --tw-shadow-color: #0d948880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/60 {
  --tw-shadow-color: #0d948899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/70 {
  --tw-shadow-color: #0d9488b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/75 {
  --tw-shadow-color: #0d9488bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/80 {
  --tw-shadow-color: #0d9488cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/90 {
  --tw-shadow-color: #0d9488e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-600\/95 {
  --tw-shadow-color: #0d9488f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700 {
  --tw-shadow-color: #0f766e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/0 {
  --tw-shadow-color: #0f766e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/10 {
  --tw-shadow-color: #0f766e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/100 {
  --tw-shadow-color: #0f766e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/20 {
  --tw-shadow-color: #0f766e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/25 {
  --tw-shadow-color: #0f766e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/30 {
  --tw-shadow-color: #0f766e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/40 {
  --tw-shadow-color: #0f766e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/5 {
  --tw-shadow-color: #0f766e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/50 {
  --tw-shadow-color: #0f766e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/60 {
  --tw-shadow-color: #0f766e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/70 {
  --tw-shadow-color: #0f766eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/75 {
  --tw-shadow-color: #0f766ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/80 {
  --tw-shadow-color: #0f766ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/90 {
  --tw-shadow-color: #0f766ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-700\/95 {
  --tw-shadow-color: #0f766ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800 {
  --tw-shadow-color: #115e59;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/0 {
  --tw-shadow-color: #115e5900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/10 {
  --tw-shadow-color: #115e591a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/100 {
  --tw-shadow-color: #115e59;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/20 {
  --tw-shadow-color: #115e5933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/25 {
  --tw-shadow-color: #115e5940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/30 {
  --tw-shadow-color: #115e594d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/40 {
  --tw-shadow-color: #115e5966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/5 {
  --tw-shadow-color: #115e590d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/50 {
  --tw-shadow-color: #115e5980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/60 {
  --tw-shadow-color: #115e5999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/70 {
  --tw-shadow-color: #115e59b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/75 {
  --tw-shadow-color: #115e59bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/80 {
  --tw-shadow-color: #115e59cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/90 {
  --tw-shadow-color: #115e59e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-800\/95 {
  --tw-shadow-color: #115e59f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900 {
  --tw-shadow-color: #134e4a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/0 {
  --tw-shadow-color: #134e4a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/10 {
  --tw-shadow-color: #134e4a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/100 {
  --tw-shadow-color: #134e4a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/20 {
  --tw-shadow-color: #134e4a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/25 {
  --tw-shadow-color: #134e4a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/30 {
  --tw-shadow-color: #134e4a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/40 {
  --tw-shadow-color: #134e4a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/5 {
  --tw-shadow-color: #134e4a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/50 {
  --tw-shadow-color: #134e4a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/60 {
  --tw-shadow-color: #134e4a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/70 {
  --tw-shadow-color: #134e4ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/75 {
  --tw-shadow-color: #134e4abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/80 {
  --tw-shadow-color: #134e4acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/90 {
  --tw-shadow-color: #134e4ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900\/95 {
  --tw-shadow-color: #134e4af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950 {
  --tw-shadow-color: #042f2e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/0 {
  --tw-shadow-color: #042f2e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/10 {
  --tw-shadow-color: #042f2e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/100 {
  --tw-shadow-color: #042f2e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/20 {
  --tw-shadow-color: #042f2e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/25 {
  --tw-shadow-color: #042f2e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/30 {
  --tw-shadow-color: #042f2e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/40 {
  --tw-shadow-color: #042f2e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/5 {
  --tw-shadow-color: #042f2e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/50 {
  --tw-shadow-color: #042f2e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/60 {
  --tw-shadow-color: #042f2e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/70 {
  --tw-shadow-color: #042f2eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/75 {
  --tw-shadow-color: #042f2ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/80 {
  --tw-shadow-color: #042f2ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/90 {
  --tw-shadow-color: #042f2ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-950\/95 {
  --tw-shadow-color: #042f2ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent {
  --tw-shadow-color: transparent;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100 {
  --tw-shadow-color: #ede9fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/0 {
  --tw-shadow-color: #ede9fe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/10 {
  --tw-shadow-color: #ede9fe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/100 {
  --tw-shadow-color: #ede9fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/20 {
  --tw-shadow-color: #ede9fe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/25 {
  --tw-shadow-color: #ede9fe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/30 {
  --tw-shadow-color: #ede9fe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/40 {
  --tw-shadow-color: #ede9fe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/5 {
  --tw-shadow-color: #ede9fe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/50 {
  --tw-shadow-color: #ede9fe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/60 {
  --tw-shadow-color: #ede9fe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/70 {
  --tw-shadow-color: #ede9feb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/75 {
  --tw-shadow-color: #ede9febf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/80 {
  --tw-shadow-color: #ede9fecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/90 {
  --tw-shadow-color: #ede9fee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-100\/95 {
  --tw-shadow-color: #ede9fef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200 {
  --tw-shadow-color: #ddd6fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/0 {
  --tw-shadow-color: #ddd6fe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/10 {
  --tw-shadow-color: #ddd6fe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/100 {
  --tw-shadow-color: #ddd6fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/20 {
  --tw-shadow-color: #ddd6fe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/25 {
  --tw-shadow-color: #ddd6fe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/30 {
  --tw-shadow-color: #ddd6fe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/40 {
  --tw-shadow-color: #ddd6fe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/5 {
  --tw-shadow-color: #ddd6fe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/50 {
  --tw-shadow-color: #ddd6fe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/60 {
  --tw-shadow-color: #ddd6fe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/70 {
  --tw-shadow-color: #ddd6feb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/75 {
  --tw-shadow-color: #ddd6febf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/80 {
  --tw-shadow-color: #ddd6fecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/90 {
  --tw-shadow-color: #ddd6fee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-200\/95 {
  --tw-shadow-color: #ddd6fef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300 {
  --tw-shadow-color: #c4b5fd;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/0 {
  --tw-shadow-color: #c4b5fd00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/10 {
  --tw-shadow-color: #c4b5fd1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/100 {
  --tw-shadow-color: #c4b5fd;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/20 {
  --tw-shadow-color: #c4b5fd33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/25 {
  --tw-shadow-color: #c4b5fd40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/30 {
  --tw-shadow-color: #c4b5fd4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/40 {
  --tw-shadow-color: #c4b5fd66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/5 {
  --tw-shadow-color: #c4b5fd0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/50 {
  --tw-shadow-color: #c4b5fd80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/60 {
  --tw-shadow-color: #c4b5fd99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/70 {
  --tw-shadow-color: #c4b5fdb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/75 {
  --tw-shadow-color: #c4b5fdbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/80 {
  --tw-shadow-color: #c4b5fdcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/90 {
  --tw-shadow-color: #c4b5fde6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-300\/95 {
  --tw-shadow-color: #c4b5fdf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400 {
  --tw-shadow-color: #a78bfa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/0 {
  --tw-shadow-color: #a78bfa00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/10 {
  --tw-shadow-color: #a78bfa1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/100 {
  --tw-shadow-color: #a78bfa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/20 {
  --tw-shadow-color: #a78bfa33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/25 {
  --tw-shadow-color: #a78bfa40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/30 {
  --tw-shadow-color: #a78bfa4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/40 {
  --tw-shadow-color: #a78bfa66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/5 {
  --tw-shadow-color: #a78bfa0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/50 {
  --tw-shadow-color: #a78bfa80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/60 {
  --tw-shadow-color: #a78bfa99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/70 {
  --tw-shadow-color: #a78bfab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/75 {
  --tw-shadow-color: #a78bfabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/80 {
  --tw-shadow-color: #a78bfacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/90 {
  --tw-shadow-color: #a78bfae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-400\/95 {
  --tw-shadow-color: #a78bfaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50 {
  --tw-shadow-color: #f5f3ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/0 {
  --tw-shadow-color: #f5f3ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/10 {
  --tw-shadow-color: #f5f3ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/100 {
  --tw-shadow-color: #f5f3ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/20 {
  --tw-shadow-color: #f5f3ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/25 {
  --tw-shadow-color: #f5f3ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/30 {
  --tw-shadow-color: #f5f3ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/40 {
  --tw-shadow-color: #f5f3ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/5 {
  --tw-shadow-color: #f5f3ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/50 {
  --tw-shadow-color: #f5f3ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/60 {
  --tw-shadow-color: #f5f3ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/70 {
  --tw-shadow-color: #f5f3ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/75 {
  --tw-shadow-color: #f5f3ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/80 {
  --tw-shadow-color: #f5f3ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/90 {
  --tw-shadow-color: #f5f3ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-50\/95 {
  --tw-shadow-color: #f5f3fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500 {
  --tw-shadow-color: #8b5cf6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/0 {
  --tw-shadow-color: #8b5cf600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/10 {
  --tw-shadow-color: #8b5cf61a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/100 {
  --tw-shadow-color: #8b5cf6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/20 {
  --tw-shadow-color: #8b5cf633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/25 {
  --tw-shadow-color: #8b5cf640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/30 {
  --tw-shadow-color: #8b5cf64d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/40 {
  --tw-shadow-color: #8b5cf666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/5 {
  --tw-shadow-color: #8b5cf60d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/50 {
  --tw-shadow-color: #8b5cf680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/60 {
  --tw-shadow-color: #8b5cf699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/70 {
  --tw-shadow-color: #8b5cf6b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/75 {
  --tw-shadow-color: #8b5cf6bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/80 {
  --tw-shadow-color: #8b5cf6cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/90 {
  --tw-shadow-color: #8b5cf6e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-500\/95 {
  --tw-shadow-color: #8b5cf6f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600 {
  --tw-shadow-color: #7c3aed;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/0 {
  --tw-shadow-color: #7c3aed00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/10 {
  --tw-shadow-color: #7c3aed1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/100 {
  --tw-shadow-color: #7c3aed;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/20 {
  --tw-shadow-color: #7c3aed33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/25 {
  --tw-shadow-color: #7c3aed40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/30 {
  --tw-shadow-color: #7c3aed4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/40 {
  --tw-shadow-color: #7c3aed66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/5 {
  --tw-shadow-color: #7c3aed0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/50 {
  --tw-shadow-color: #7c3aed80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/60 {
  --tw-shadow-color: #7c3aed99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/70 {
  --tw-shadow-color: #7c3aedb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/75 {
  --tw-shadow-color: #7c3aedbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/80 {
  --tw-shadow-color: #7c3aedcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/90 {
  --tw-shadow-color: #7c3aede6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-600\/95 {
  --tw-shadow-color: #7c3aedf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700 {
  --tw-shadow-color: #6d28d9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/0 {
  --tw-shadow-color: #6d28d900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/10 {
  --tw-shadow-color: #6d28d91a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/100 {
  --tw-shadow-color: #6d28d9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/20 {
  --tw-shadow-color: #6d28d933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/25 {
  --tw-shadow-color: #6d28d940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/30 {
  --tw-shadow-color: #6d28d94d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/40 {
  --tw-shadow-color: #6d28d966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/5 {
  --tw-shadow-color: #6d28d90d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/50 {
  --tw-shadow-color: #6d28d980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/60 {
  --tw-shadow-color: #6d28d999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/70 {
  --tw-shadow-color: #6d28d9b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/75 {
  --tw-shadow-color: #6d28d9bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/80 {
  --tw-shadow-color: #6d28d9cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/90 {
  --tw-shadow-color: #6d28d9e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-700\/95 {
  --tw-shadow-color: #6d28d9f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800 {
  --tw-shadow-color: #5b21b6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/0 {
  --tw-shadow-color: #5b21b600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/10 {
  --tw-shadow-color: #5b21b61a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/100 {
  --tw-shadow-color: #5b21b6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/20 {
  --tw-shadow-color: #5b21b633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/25 {
  --tw-shadow-color: #5b21b640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/30 {
  --tw-shadow-color: #5b21b64d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/40 {
  --tw-shadow-color: #5b21b666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/5 {
  --tw-shadow-color: #5b21b60d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/50 {
  --tw-shadow-color: #5b21b680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/60 {
  --tw-shadow-color: #5b21b699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/70 {
  --tw-shadow-color: #5b21b6b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/75 {
  --tw-shadow-color: #5b21b6bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/80 {
  --tw-shadow-color: #5b21b6cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/90 {
  --tw-shadow-color: #5b21b6e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-800\/95 {
  --tw-shadow-color: #5b21b6f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900 {
  --tw-shadow-color: #4c1d95;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/0 {
  --tw-shadow-color: #4c1d9500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/10 {
  --tw-shadow-color: #4c1d951a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/100 {
  --tw-shadow-color: #4c1d95;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/20 {
  --tw-shadow-color: #4c1d9533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/25 {
  --tw-shadow-color: #4c1d9540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/30 {
  --tw-shadow-color: #4c1d954d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/40 {
  --tw-shadow-color: #4c1d9566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/5 {
  --tw-shadow-color: #4c1d950d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/50 {
  --tw-shadow-color: #4c1d9580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/60 {
  --tw-shadow-color: #4c1d9599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/70 {
  --tw-shadow-color: #4c1d95b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/75 {
  --tw-shadow-color: #4c1d95bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/80 {
  --tw-shadow-color: #4c1d95cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/90 {
  --tw-shadow-color: #4c1d95e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-900\/95 {
  --tw-shadow-color: #4c1d95f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950 {
  --tw-shadow-color: #2e1065;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/0 {
  --tw-shadow-color: #2e106500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/10 {
  --tw-shadow-color: #2e10651a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/100 {
  --tw-shadow-color: #2e1065;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/20 {
  --tw-shadow-color: #2e106533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/25 {
  --tw-shadow-color: #2e106540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/30 {
  --tw-shadow-color: #2e10654d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/40 {
  --tw-shadow-color: #2e106566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/5 {
  --tw-shadow-color: #2e10650d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/50 {
  --tw-shadow-color: #2e106580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/60 {
  --tw-shadow-color: #2e106599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/70 {
  --tw-shadow-color: #2e1065b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/75 {
  --tw-shadow-color: #2e1065bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/80 {
  --tw-shadow-color: #2e1065cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/90 {
  --tw-shadow-color: #2e1065e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-violet-950\/95 {
  --tw-shadow-color: #2e1065f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/0 {
  --tw-shadow-color: #fff0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/10 {
  --tw-shadow-color: #ffffff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/100 {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/20 {
  --tw-shadow-color: #fff3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/25 {
  --tw-shadow-color: #ffffff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/30 {
  --tw-shadow-color: #ffffff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/40 {
  --tw-shadow-color: #fff6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/5 {
  --tw-shadow-color: #ffffff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/50 {
  --tw-shadow-color: #ffffff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/60 {
  --tw-shadow-color: #fff9;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/70 {
  --tw-shadow-color: #ffffffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/75 {
  --tw-shadow-color: #ffffffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/80 {
  --tw-shadow-color: #fffc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/90 {
  --tw-shadow-color: #ffffffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white\/95 {
  --tw-shadow-color: #fffffff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100 {
  --tw-shadow-color: #fef9c3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/0 {
  --tw-shadow-color: #fef9c300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/10 {
  --tw-shadow-color: #fef9c31a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/100 {
  --tw-shadow-color: #fef9c3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/20 {
  --tw-shadow-color: #fef9c333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/25 {
  --tw-shadow-color: #fef9c340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/30 {
  --tw-shadow-color: #fef9c34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/40 {
  --tw-shadow-color: #fef9c366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/5 {
  --tw-shadow-color: #fef9c30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/50 {
  --tw-shadow-color: #fef9c380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/60 {
  --tw-shadow-color: #fef9c399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/70 {
  --tw-shadow-color: #fef9c3b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/75 {
  --tw-shadow-color: #fef9c3bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/80 {
  --tw-shadow-color: #fef9c3cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/90 {
  --tw-shadow-color: #fef9c3e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-100\/95 {
  --tw-shadow-color: #fef9c3f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200 {
  --tw-shadow-color: #fef08a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/0 {
  --tw-shadow-color: #fef08a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/10 {
  --tw-shadow-color: #fef08a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/100 {
  --tw-shadow-color: #fef08a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/20 {
  --tw-shadow-color: #fef08a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/25 {
  --tw-shadow-color: #fef08a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/30 {
  --tw-shadow-color: #fef08a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/40 {
  --tw-shadow-color: #fef08a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/5 {
  --tw-shadow-color: #fef08a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/50 {
  --tw-shadow-color: #fef08a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/60 {
  --tw-shadow-color: #fef08a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/70 {
  --tw-shadow-color: #fef08ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/75 {
  --tw-shadow-color: #fef08abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/80 {
  --tw-shadow-color: #fef08acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/90 {
  --tw-shadow-color: #fef08ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-200\/95 {
  --tw-shadow-color: #fef08af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300 {
  --tw-shadow-color: #fde047;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/0 {
  --tw-shadow-color: #fde04700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/10 {
  --tw-shadow-color: #fde0471a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/100 {
  --tw-shadow-color: #fde047;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/20 {
  --tw-shadow-color: #fde04733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/25 {
  --tw-shadow-color: #fde04740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/30 {
  --tw-shadow-color: #fde0474d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/40 {
  --tw-shadow-color: #fde04766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/5 {
  --tw-shadow-color: #fde0470d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/50 {
  --tw-shadow-color: #fde04780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/60 {
  --tw-shadow-color: #fde04799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/70 {
  --tw-shadow-color: #fde047b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/75 {
  --tw-shadow-color: #fde047bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/80 {
  --tw-shadow-color: #fde047cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/90 {
  --tw-shadow-color: #fde047e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-300\/95 {
  --tw-shadow-color: #fde047f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400 {
  --tw-shadow-color: #facc15;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/0 {
  --tw-shadow-color: #facc1500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/10 {
  --tw-shadow-color: #facc151a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/100 {
  --tw-shadow-color: #facc15;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/20 {
  --tw-shadow-color: #facc1533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/25 {
  --tw-shadow-color: #facc1540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/30 {
  --tw-shadow-color: #facc154d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/40 {
  --tw-shadow-color: #facc1566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/5 {
  --tw-shadow-color: #facc150d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/50 {
  --tw-shadow-color: #facc1580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/60 {
  --tw-shadow-color: #facc1599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/70 {
  --tw-shadow-color: #facc15b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/75 {
  --tw-shadow-color: #facc15bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/80 {
  --tw-shadow-color: #facc15cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/90 {
  --tw-shadow-color: #facc15e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-400\/95 {
  --tw-shadow-color: #facc15f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50 {
  --tw-shadow-color: #fefce8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/0 {
  --tw-shadow-color: #fefce800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/10 {
  --tw-shadow-color: #fefce81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/100 {
  --tw-shadow-color: #fefce8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/20 {
  --tw-shadow-color: #fefce833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/25 {
  --tw-shadow-color: #fefce840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/30 {
  --tw-shadow-color: #fefce84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/40 {
  --tw-shadow-color: #fefce866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/5 {
  --tw-shadow-color: #fefce80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/50 {
  --tw-shadow-color: #fefce880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/60 {
  --tw-shadow-color: #fefce899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/70 {
  --tw-shadow-color: #fefce8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/75 {
  --tw-shadow-color: #fefce8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/80 {
  --tw-shadow-color: #fefce8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/90 {
  --tw-shadow-color: #fefce8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-50\/95 {
  --tw-shadow-color: #fefce8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500 {
  --tw-shadow-color: #eab308;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/0 {
  --tw-shadow-color: #eab30800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/10 {
  --tw-shadow-color: #eab3081a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/100 {
  --tw-shadow-color: #eab308;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/20 {
  --tw-shadow-color: #eab30833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/25 {
  --tw-shadow-color: #eab30840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/30 {
  --tw-shadow-color: #eab3084d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/40 {
  --tw-shadow-color: #eab30866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/5 {
  --tw-shadow-color: #eab3080d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/50 {
  --tw-shadow-color: #eab30880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/60 {
  --tw-shadow-color: #eab30899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/70 {
  --tw-shadow-color: #eab308b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/75 {
  --tw-shadow-color: #eab308bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/80 {
  --tw-shadow-color: #eab308cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/90 {
  --tw-shadow-color: #eab308e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/95 {
  --tw-shadow-color: #eab308f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600 {
  --tw-shadow-color: #ca8a04;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/0 {
  --tw-shadow-color: #ca8a0400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/10 {
  --tw-shadow-color: #ca8a041a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/100 {
  --tw-shadow-color: #ca8a04;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/20 {
  --tw-shadow-color: #ca8a0433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/25 {
  --tw-shadow-color: #ca8a0440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/30 {
  --tw-shadow-color: #ca8a044d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/40 {
  --tw-shadow-color: #ca8a0466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/5 {
  --tw-shadow-color: #ca8a040d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/50 {
  --tw-shadow-color: #ca8a0480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/60 {
  --tw-shadow-color: #ca8a0499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/70 {
  --tw-shadow-color: #ca8a04b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/75 {
  --tw-shadow-color: #ca8a04bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/80 {
  --tw-shadow-color: #ca8a04cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/90 {
  --tw-shadow-color: #ca8a04e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-600\/95 {
  --tw-shadow-color: #ca8a04f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700 {
  --tw-shadow-color: #a16207;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/0 {
  --tw-shadow-color: #a1620700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/10 {
  --tw-shadow-color: #a162071a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/100 {
  --tw-shadow-color: #a16207;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/20 {
  --tw-shadow-color: #a1620733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/25 {
  --tw-shadow-color: #a1620740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/30 {
  --tw-shadow-color: #a162074d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/40 {
  --tw-shadow-color: #a1620766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/5 {
  --tw-shadow-color: #a162070d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/50 {
  --tw-shadow-color: #a1620780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/60 {
  --tw-shadow-color: #a1620799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/70 {
  --tw-shadow-color: #a16207b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/75 {
  --tw-shadow-color: #a16207bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/80 {
  --tw-shadow-color: #a16207cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/90 {
  --tw-shadow-color: #a16207e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-700\/95 {
  --tw-shadow-color: #a16207f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800 {
  --tw-shadow-color: #854d0e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/0 {
  --tw-shadow-color: #854d0e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/10 {
  --tw-shadow-color: #854d0e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/100 {
  --tw-shadow-color: #854d0e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/20 {
  --tw-shadow-color: #854d0e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/25 {
  --tw-shadow-color: #854d0e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/30 {
  --tw-shadow-color: #854d0e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/40 {
  --tw-shadow-color: #854d0e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/5 {
  --tw-shadow-color: #854d0e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/50 {
  --tw-shadow-color: #854d0e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/60 {
  --tw-shadow-color: #854d0e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/70 {
  --tw-shadow-color: #854d0eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/75 {
  --tw-shadow-color: #854d0ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/80 {
  --tw-shadow-color: #854d0ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/90 {
  --tw-shadow-color: #854d0ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-800\/95 {
  --tw-shadow-color: #854d0ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900 {
  --tw-shadow-color: #713f12;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/0 {
  --tw-shadow-color: #713f1200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/10 {
  --tw-shadow-color: #713f121a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/100 {
  --tw-shadow-color: #713f12;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/20 {
  --tw-shadow-color: #713f1233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/25 {
  --tw-shadow-color: #713f1240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/30 {
  --tw-shadow-color: #713f124d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/40 {
  --tw-shadow-color: #713f1266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/5 {
  --tw-shadow-color: #713f120d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/50 {
  --tw-shadow-color: #713f1280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/60 {
  --tw-shadow-color: #713f1299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/70 {
  --tw-shadow-color: #713f12b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/75 {
  --tw-shadow-color: #713f12bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/80 {
  --tw-shadow-color: #713f12cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/90 {
  --tw-shadow-color: #713f12e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-900\/95 {
  --tw-shadow-color: #713f12f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950 {
  --tw-shadow-color: #422006;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/0 {
  --tw-shadow-color: #42200600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/10 {
  --tw-shadow-color: #4220061a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/100 {
  --tw-shadow-color: #422006;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/20 {
  --tw-shadow-color: #42200633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/25 {
  --tw-shadow-color: #42200640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/30 {
  --tw-shadow-color: #4220064d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/40 {
  --tw-shadow-color: #42200666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/5 {
  --tw-shadow-color: #4220060d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/50 {
  --tw-shadow-color: #42200680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/60 {
  --tw-shadow-color: #42200699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/70 {
  --tw-shadow-color: #422006b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/75 {
  --tw-shadow-color: #422006bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/80 {
  --tw-shadow-color: #422006cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/90 {
  --tw-shadow-color: #422006e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-950\/95 {
  --tw-shadow-color: #422006f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100 {
  --tw-shadow-color: #f4f4f5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/0 {
  --tw-shadow-color: #f4f4f500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/10 {
  --tw-shadow-color: #f4f4f51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/100 {
  --tw-shadow-color: #f4f4f5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/20 {
  --tw-shadow-color: #f4f4f533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/25 {
  --tw-shadow-color: #f4f4f540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/30 {
  --tw-shadow-color: #f4f4f54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/40 {
  --tw-shadow-color: #f4f4f566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/5 {
  --tw-shadow-color: #f4f4f50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/50 {
  --tw-shadow-color: #f4f4f580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/60 {
  --tw-shadow-color: #f4f4f599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/70 {
  --tw-shadow-color: #f4f4f5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/75 {
  --tw-shadow-color: #f4f4f5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/80 {
  --tw-shadow-color: #f4f4f5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/90 {
  --tw-shadow-color: #f4f4f5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-100\/95 {
  --tw-shadow-color: #f4f4f5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200 {
  --tw-shadow-color: #e4e4e7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/0 {
  --tw-shadow-color: #e4e4e700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/10 {
  --tw-shadow-color: #e4e4e71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/100 {
  --tw-shadow-color: #e4e4e7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/20 {
  --tw-shadow-color: #e4e4e733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/25 {
  --tw-shadow-color: #e4e4e740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/30 {
  --tw-shadow-color: #e4e4e74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/40 {
  --tw-shadow-color: #e4e4e766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/5 {
  --tw-shadow-color: #e4e4e70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/50 {
  --tw-shadow-color: #e4e4e780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/60 {
  --tw-shadow-color: #e4e4e799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/70 {
  --tw-shadow-color: #e4e4e7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/75 {
  --tw-shadow-color: #e4e4e7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/80 {
  --tw-shadow-color: #e4e4e7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/90 {
  --tw-shadow-color: #e4e4e7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-200\/95 {
  --tw-shadow-color: #e4e4e7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300 {
  --tw-shadow-color: #d4d4d8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/0 {
  --tw-shadow-color: #d4d4d800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/10 {
  --tw-shadow-color: #d4d4d81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/100 {
  --tw-shadow-color: #d4d4d8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/20 {
  --tw-shadow-color: #d4d4d833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/25 {
  --tw-shadow-color: #d4d4d840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/30 {
  --tw-shadow-color: #d4d4d84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/40 {
  --tw-shadow-color: #d4d4d866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/5 {
  --tw-shadow-color: #d4d4d80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/50 {
  --tw-shadow-color: #d4d4d880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/60 {
  --tw-shadow-color: #d4d4d899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/70 {
  --tw-shadow-color: #d4d4d8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/75 {
  --tw-shadow-color: #d4d4d8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/80 {
  --tw-shadow-color: #d4d4d8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/90 {
  --tw-shadow-color: #d4d4d8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-300\/95 {
  --tw-shadow-color: #d4d4d8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400 {
  --tw-shadow-color: #a1a1aa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/0 {
  --tw-shadow-color: #a1a1aa00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/10 {
  --tw-shadow-color: #a1a1aa1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/100 {
  --tw-shadow-color: #a1a1aa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/20 {
  --tw-shadow-color: #a1a1aa33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/25 {
  --tw-shadow-color: #a1a1aa40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/30 {
  --tw-shadow-color: #a1a1aa4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/40 {
  --tw-shadow-color: #a1a1aa66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/5 {
  --tw-shadow-color: #a1a1aa0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/50 {
  --tw-shadow-color: #a1a1aa80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/60 {
  --tw-shadow-color: #a1a1aa99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/70 {
  --tw-shadow-color: #a1a1aab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/75 {
  --tw-shadow-color: #a1a1aabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/80 {
  --tw-shadow-color: #a1a1aacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/90 {
  --tw-shadow-color: #a1a1aae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-400\/95 {
  --tw-shadow-color: #a1a1aaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50 {
  --tw-shadow-color: #fafafa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/0 {
  --tw-shadow-color: #fafafa00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/10 {
  --tw-shadow-color: #fafafa1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/100 {
  --tw-shadow-color: #fafafa;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/20 {
  --tw-shadow-color: #fafafa33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/25 {
  --tw-shadow-color: #fafafa40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/30 {
  --tw-shadow-color: #fafafa4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/40 {
  --tw-shadow-color: #fafafa66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/5 {
  --tw-shadow-color: #fafafa0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/50 {
  --tw-shadow-color: #fafafa80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/60 {
  --tw-shadow-color: #fafafa99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/70 {
  --tw-shadow-color: #fafafab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/75 {
  --tw-shadow-color: #fafafabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/80 {
  --tw-shadow-color: #fafafacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/90 {
  --tw-shadow-color: #fafafae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-50\/95 {
  --tw-shadow-color: #fafafaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500 {
  --tw-shadow-color: #71717a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/0 {
  --tw-shadow-color: #71717a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/10 {
  --tw-shadow-color: #71717a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/100 {
  --tw-shadow-color: #71717a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/20 {
  --tw-shadow-color: #71717a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/25 {
  --tw-shadow-color: #71717a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/30 {
  --tw-shadow-color: #71717a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/40 {
  --tw-shadow-color: #71717a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/5 {
  --tw-shadow-color: #71717a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/50 {
  --tw-shadow-color: #71717a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/60 {
  --tw-shadow-color: #71717a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/70 {
  --tw-shadow-color: #71717ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/75 {
  --tw-shadow-color: #71717abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/80 {
  --tw-shadow-color: #71717acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/90 {
  --tw-shadow-color: #71717ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-500\/95 {
  --tw-shadow-color: #71717af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600 {
  --tw-shadow-color: #52525b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/0 {
  --tw-shadow-color: #52525b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/10 {
  --tw-shadow-color: #52525b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/100 {
  --tw-shadow-color: #52525b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/20 {
  --tw-shadow-color: #52525b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/25 {
  --tw-shadow-color: #52525b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/30 {
  --tw-shadow-color: #52525b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/40 {
  --tw-shadow-color: #52525b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/5 {
  --tw-shadow-color: #52525b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/50 {
  --tw-shadow-color: #52525b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/60 {
  --tw-shadow-color: #52525b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/70 {
  --tw-shadow-color: #52525bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/75 {
  --tw-shadow-color: #52525bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/80 {
  --tw-shadow-color: #52525bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/90 {
  --tw-shadow-color: #52525be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-600\/95 {
  --tw-shadow-color: #52525bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700 {
  --tw-shadow-color: #3f3f46;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/0 {
  --tw-shadow-color: #3f3f4600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/10 {
  --tw-shadow-color: #3f3f461a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/100 {
  --tw-shadow-color: #3f3f46;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/20 {
  --tw-shadow-color: #3f3f4633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/25 {
  --tw-shadow-color: #3f3f4640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/30 {
  --tw-shadow-color: #3f3f464d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/40 {
  --tw-shadow-color: #3f3f4666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/5 {
  --tw-shadow-color: #3f3f460d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/50 {
  --tw-shadow-color: #3f3f4680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/60 {
  --tw-shadow-color: #3f3f4699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/70 {
  --tw-shadow-color: #3f3f46b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/75 {
  --tw-shadow-color: #3f3f46bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/80 {
  --tw-shadow-color: #3f3f46cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/90 {
  --tw-shadow-color: #3f3f46e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-700\/95 {
  --tw-shadow-color: #3f3f46f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800 {
  --tw-shadow-color: #27272a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/0 {
  --tw-shadow-color: #27272a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/10 {
  --tw-shadow-color: #27272a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/100 {
  --tw-shadow-color: #27272a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/20 {
  --tw-shadow-color: #27272a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/25 {
  --tw-shadow-color: #27272a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/30 {
  --tw-shadow-color: #27272a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/40 {
  --tw-shadow-color: #27272a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/5 {
  --tw-shadow-color: #27272a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/50 {
  --tw-shadow-color: #27272a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/60 {
  --tw-shadow-color: #27272a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/70 {
  --tw-shadow-color: #27272ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/75 {
  --tw-shadow-color: #27272abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/80 {
  --tw-shadow-color: #27272acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/90 {
  --tw-shadow-color: #27272ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-800\/95 {
  --tw-shadow-color: #27272af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900 {
  --tw-shadow-color: #18181b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/0 {
  --tw-shadow-color: #18181b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/10 {
  --tw-shadow-color: #18181b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/100 {
  --tw-shadow-color: #18181b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/20 {
  --tw-shadow-color: #18181b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/25 {
  --tw-shadow-color: #18181b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/30 {
  --tw-shadow-color: #18181b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/40 {
  --tw-shadow-color: #18181b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/5 {
  --tw-shadow-color: #18181b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/50 {
  --tw-shadow-color: #18181b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/60 {
  --tw-shadow-color: #18181b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/70 {
  --tw-shadow-color: #18181bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/75 {
  --tw-shadow-color: #18181bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/80 {
  --tw-shadow-color: #18181bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/90 {
  --tw-shadow-color: #18181be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-900\/95 {
  --tw-shadow-color: #18181bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950 {
  --tw-shadow-color: #09090b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/0 {
  --tw-shadow-color: #09090b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/10 {
  --tw-shadow-color: #09090b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/100 {
  --tw-shadow-color: #09090b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/20 {
  --tw-shadow-color: #09090b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/25 {
  --tw-shadow-color: #09090b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/30 {
  --tw-shadow-color: #09090b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/40 {
  --tw-shadow-color: #09090b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/5 {
  --tw-shadow-color: #09090b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/50 {
  --tw-shadow-color: #09090b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/60 {
  --tw-shadow-color: #09090b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/70 {
  --tw-shadow-color: #09090bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/75 {
  --tw-shadow-color: #09090bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/80 {
  --tw-shadow-color: #09090bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/90 {
  --tw-shadow-color: #09090be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-zinc-950\/95 {
  --tw-shadow-color: #09090bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[2px\] {
  --tw-backdrop-blur: blur(2px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.duration-100 {
  transition-duration: .1s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.placeholder\:text-blue-100\/80::placeholder {
  color: #dbeafecc;
}

.hover\:translate-x-\[0\.2rem\]:hover {
  --tw-translate-x: .2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/*# sourceMappingURL=index.b541289d.css.map */
